import React from 'react';
import { Trans, TransProps } from 'react-i18next';

import { useAppTranslation } from '@src/common';

const Translate: React.FC<TransProps<string>> = (props) => {
  const { i18n } = useAppTranslation();

  return (
    <Trans
      i18n={i18n}
      {...props}
    />
  );
};

export { Translate };
