import React from 'react';
import { Toast } from 'react-hot-toast/dist/core/types';
import toast from 'react-hot-toast';

import cn from 'classnames';
import { Icon } from '@kit/components/Icon';
import { Loader } from '@kit/components/Loader';

import stylesLoading from './ToastLoading.module.scss';
import styles from '../Toast.module.scss';
import { ToastTitle } from '../ToastTitle';
import { ToastText } from '../ToastText';

type Props = {
  text?: string;
  titleText?: string;
  hasClose?: boolean;
  toastItem?: Toast;
  size?: 'md' | 'lg';
};

const ToastLoading: React.FC<Props> = ({ text, titleText, hasClose, size = 'md', toastItem }) => {
  const onClose = () => toast.dismiss(toastItem?.id);
  const cls = cn(styles.toastContainer, styles[size], stylesLoading.toastLoadingContainer);
  return (
    <div className={cls}>
      <Loader
        size="lg"
        type="spinner"
        className={stylesLoading.colorLoader}
      />
      {(titleText || text) && (
        <div className={styles.toastContainerText}>
          {titleText && <ToastTitle title={titleText} />}
          <ToastText text={text} />
        </div>
      )}
      {hasClose && (
        <Icon
          size="md"
          type="close"
          onClick={onClose}
          data-testid="toastLoadingCloseIcon"
        />
      )}
    </div>
  );
};

export { ToastLoading };
export type { Props as ToastLoadingProps };
