import React from 'react';

import cn from 'classnames';

import styles from './Card.module.scss';

type Props = {
  className?: string;
};

const CardText: React.FC<Props> = ({ className, children }) => {
  return <p className={cn(styles.text, className)}>{children}</p>;
};

export { CardText };
