import React from 'react';

import cn from 'classnames/bind';
import { Icon } from '@kit/components';

import styles from './Badge.module.scss';

type BadgeVariant = 'red' | 'yellow' | 'green' | 'blue' | 'teal' | 'purple' | 'gray';

type Props = {
  variant?: BadgeVariant;
  size?: 'sm' | 'md' | 'lg';
  containerClassName?: string;
  textClassName?: string;
  iconClassName?: string;
  onDelete?: () => void;
};

type BadgeMapperType = {
  checkCondition: (value: number) => boolean;
  variant: BadgeVariant;
  getValue: (value: number) => string | number;
};

const Badge: React.FC<Props> = ({
  variant = 'gray',
  size = 'md',
  children,
  containerClassName,
  iconClassName,
  textClassName,
  onDelete,
}) => {
  const cx = cn.bind(styles);

  const containerClasses = cx('badge', containerClassName, variant, size);

  const textClasses = cx('text', textClassName, variant);

  return (
    <div
      className={containerClasses}
      data-testid="badgeContainer"
    >
      <div className={textClasses}>{children}</div>
      {onDelete && (
        <div onClick={onDelete}>
          <Icon
            className={cx('icon', iconClassName)}
            size={size}
            type="close"
          />
        </div>
      )}
    </div>
  );
};

export { Badge };
export type { BadgeMapperType, BadgeVariant };
