import React, { useMemo } from 'react';

import { SideModal, SideModalProps } from '@kit/components';

import { useAppToolbarContext } from '@src/common';
import { isShowTechNotification } from '@src/utils';

const HeaderSideModal: React.FC<SideModalProps> = (props) => {
  const { techNotification, system } = useAppToolbarContext();

  const showTechNotification = useMemo(
    () => isShowTechNotification(techNotification, system),
    [techNotification, system],
  );

  const headerHeight = showTechNotification ? '80px' : '48px';

  return (
    <SideModal
      {...props}
      contentStyle={{
        top: 0,
        borderTop: '1px solid #f6f8f9',
        height: `calc(100% - ${headerHeight})`,
      }}
      overlayStyle={{ top: headerHeight, alignItems: 'flex-start' }}
      hiddenOverlay
    />
  );
};

export { HeaderSideModal };
