import React from 'react';
import { animated, config, useTransition } from 'react-spring';

import { Badge, H2 } from '@kit/components';
import { SystemModule } from '@kit/components/SystemModulesBlock/@types/types';

import { bindStyles } from '@src/utils';

import styles from './SystemModulesBlock.module.scss';

const cx = bindStyles(styles);

type Props = {
  title: string;
  subtitle: string;
  blocks: Partial<SystemModule>[];
  onClick: (item: Partial<SystemModule>) => void;
};

const SystemModulesBlock: React.FC<Props> = ({ blocks, title, onClick }) => {
  const transitions = useTransition(blocks, {
    key: (item: SystemModule) => item.key,
    from: (item, index) => ({
      y: 30 * (index + 1),
      opacity: 0,
    }),
    enter: {
      y: 0,
      opacity: 1,
    },
    leave: { opacity: 0 },
    config: config.molasses,
    delay: 200,
  });

  const onSelect = (item: Partial<SystemModule>) => () => onClick(item);

  return (
    <div className={cx('modules-container')}>
      <H2>{title}</H2>

      <ul className={cx('modules-list')}>
        {transitions((style, item) => (
          <animated.div
            style={style}
            className={cx('modules-list__system-animated')}
          >
            <li
              className={cx('modules-list__system', { 'modules-list__system_demo': item.demo })}
              data-testid={item.key}
              onClick={onSelect(item)}
            >
              <article className={cx('system')}>
                <div className={cx('system__img')}>{item.image}</div>
                <div className={cx('system__info')}>
                  <div className={cx('system__info-label')}>{item.label}</div>
                  <span className={cx('system__info-desc')}>{item.description}</span>
                  <div className={cx('system__info-badge')}>
                    <Badge
                      size="sm"
                      variant={item.badgeVariant}
                    >
                      {item.badgeText}
                    </Badge>
                  </div>
                </div>
              </article>
            </li>
          </animated.div>
        ))}
      </ul>
    </div>
  );
};

export { SystemModulesBlock };
