import { FC, ReactNode, useMemo, useState } from 'react';

import { Bookmarks } from '@common/components/AppToolbar/components/Bookmarks';
import { Notifications } from '@common/components/AppToolbar/components/Notifications';
import { Profile } from '@common/components/AppToolbar/components/Profile';
import { CloudConnect } from '@common/components/AppToolbar/components/CloudConnect';
import { EmptyUserInfoModal } from '@common/components/AppToolbar/components/EmptyUserInfoModal/EmptyUserInfoModal';
import { TaskId } from '@common/api/Notification/models';

import {
  EventDetail,
  useAppToolbarContext,
  useAppTranslation,
  useAuth,
  useCloudConnectContext,
  usePopupControls,
} from '@src/common';
import { isShowTechNotification, bindStyles, insertZero } from '@src/utils';

import styles from './AppToolbar.module.scss';
import { Logo } from './components/Logo';

const cx = bindStyles(styles);

type Props = {
  userManual?: ReactNode;
  onNavigate?: (to: 'users' | 'settings') => void;
};

const AppToolbar: FC<Props> = ({ userManual }) => {
  const { t } = useAppTranslation();
  const { user, techNotification, brand } = useAppToolbarContext();
  const { system } = useAppToolbarContext();

  const { logout } = useAuth();

  const { open } = useCloudConnectContext();

  const [eventDetailId, setEventDetailId] = useState<TaskId>();

  const {
    openPopup: openEventDetail,
    closePopup: closeEventDetail,
    isOpened: isOpenedEventDetail,
  } = usePopupControls();

  const handleEventDetail = (id: TaskId) => {
    setEventDetailId(id);
    openEventDetail();
  };

  const showTechNotification = useMemo(
    () => isShowTechNotification(techNotification, system),
    [techNotification, system],
  );

  const techNotificationDate = useMemo(() => {
    if (techNotification) {
      const date = new Date(techNotification.end);
      return `${insertZero(date.getHours())}:${insertZero(date.getMinutes())} ${insertZero(
        date.getDate(),
      )}.${insertZero(date.getMonth() + 1)}.${date.getFullYear()}`;
    }
  }, [techNotification]);

  const emptyUser = user ? !user.firstName && !user.lastName : false;

  return (
    <div className={cx('wrapper')}>
      {showTechNotification && (
        <div className={cx('tech-notification')}>
          {t('cos.tech.notification', {
            time: techNotificationDate,
          })}
        </div>
      )}
      <header className={cx('header')}>
        <div className={cx('headerLeft')}>
          <Logo brand={brand} />
        </div>

        <nav className={cx('nav')}>
          <Bookmarks currentSystem={system} />
        </nav>

        <div
          id="header-right"
          className={cx('headerRight')}
        >
          <div className={cx('icons')}>
            {userManual}
            <Notifications
              className={cx('iconButton')}
              activeClassName={cx('isOpened')}
              handleEventDetail={handleEventDetail}
              isOpenedEventDetail={isOpenedEventDetail}
            />
            <Profile
              user={user}
              logout={logout}
              showTechNotification={showTechNotification}
            />
          </div>
        </div>
      </header>
      {open && <CloudConnect />}
      {emptyUser && user && <EmptyUserInfoModal user={user} />}
      {eventDetailId && isOpenedEventDetail && (
        <EventDetail
          onClose={closeEventDetail}
          eventDetailId={eventDetailId}
        />
      )}
    </div>
  );
};

export { AppToolbar };
