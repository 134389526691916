import { FC, useState } from 'react';

import { useAppTranslation } from '@src/common';
import { Button, ButtonGroup, Icon, Input } from '@src/kit';
import { bindStyles } from '@src/utils';

import styles from './CreationEntity.module.scss';

const cx = bindStyles(styles);

type CreationEntityProps = {
  title: string;
  placeholder?: string;
  onAdd: (value: string) => void;
  onClose?: () => void;
};

const CreationEntity: FC<CreationEntityProps> = ({ title, onAdd, placeholder, onClose }) => {
  const { t } = useAppTranslation();
  const [creationValue, setCreationValue] = useState('');

  const handleClick = () => {
    onAdd(creationValue);
    setCreationValue('');
    onClose?.();
  };

  return (
    <div className={cx('creation')}>
      <div className={cx('creationTitle')}>{title}</div>
      <ButtonGroup gap="0">
        <Input
          placeholder={placeholder || t('cos.shared.inputName')}
          size="md"
          value={creationValue}
          onChange={(e) => setCreationValue(e.target.value)}
        />
        <Button
          size="md"
          onClick={handleClick}
        >
          <Icon type="check" />
        </Button>
      </ButtonGroup>
    </div>
  );
};

export { CreationEntity };
export type { CreationEntityProps };
