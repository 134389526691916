import { RestService } from '@src/common';

import { Tariff } from './models';

const restService = RestService.getInstance();

export const fetchTariffsAndPrices = async (cloudId: string) => {
  const url = `/settings/api/v2/tariff/${cloudId}/tariffprice`;
  return restService.GET<void, Tariff[]>(url);
};

export const fetchSaveTariffsAndPrices = async (dto: Tariff[], cloudId: string) => {
  const url = `/settings/api/v2/tariff/${cloudId}/tariffprice`;
  return restService.POST<Tariff[], Tariff[]>(url, { data: dto });
};
