import { IRestService, RestService } from '@common/services';

import { VSphereDTO } from './models';
class VSphereApi {
  static restService: IRestService = RestService.getInstance();

  static connected(data: VSphereDTO) {
    const url = '/settings/integration/api/connection/vsphere';
    return this.restService.POST<VSphereDTO, void>(url, { data: data });
  }
}

export { VSphereApi };
