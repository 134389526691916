import React from 'react';

import cn from 'classnames/bind';
import { ColorPulsator } from '@kit/components/ColorPulsator';

import styles from './TableLoaderFrame.module.scss';

const cx = cn.bind(styles);

type Props = {
  count?: number;
};

const TableLoaderFrame: React.FC<Props> = ({ count = 5 }) => {
  const skeletons = new Array(count).fill('');

  return (
    <div
      className={cx('body')}
      data-testid="loader"
    >
      {skeletons.map((_, i) => (
        <div
          key={i}
          className={cx('pair')}
        >
          <div className={cx('bar', 'bar_long')}>
            <ColorPulsator />
          </div>
          <div className={cx('bar', 'bar_short')}>
            <ColorPulsator />
          </div>
        </div>
      ))}
    </div>
  );
};

export default React.memo(TableLoaderFrame);
