import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { CmTableResponse, FetchDataParams } from '@common/components/CMTable/types';

import { useCMTablePagination, UseCMTableSelectionReturn, UseCMTableSortReturn } from '@src/common';

type SearchInResult<D> = {
  value: string;
  comparator: (item: D, value: string) => boolean;
};

type UseCMTableReturn<D> = {
  data: D[];
  loading: boolean;
  pagination?: ReturnType<typeof useCMTablePagination>;
  sort?: UseCMTableSortReturn<D>;
  selection?: UseCMTableSelectionReturn<D>;
  fetchData: () => Promise<void>;
  setFixedData: (value: D[]) => void;
};

const useCMTable = <D>(
  fetchFn?: (params: FetchDataParams<D>) => Promise<CmTableResponse<D>>,
  pagination?: ReturnType<typeof useCMTablePagination>,
  sort?: UseCMTableSortReturn<D>,
  selection?: UseCMTableSelectionReturn<D>,
  search?: SearchInResult<D>,
): UseCMTableReturn<D> => {
  const [tableData, setTableData] = useState<D[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [isLoaderVisible, setIsLoaderVisible] = useState<boolean>(false);

  const { size, page, setTotal } = pagination || {};
  const { sort: currentSort } = sort || {};

  const fetchData = useCallback(async () => {
    if (!fetchFn) {
      return;
    }

    try {
      setLoading(true);

      const params: FetchDataParams<D> = {
        size,
        page,
        sort: currentSort ?? undefined,
      };

      const response = await fetchFn(params);

      setTableData(response?.result || []);
      setTotal?.(response?.totalItems || 0);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [fetchFn, page, size, currentSort, setTotal]);

  const timeout = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (loading && !isLoaderVisible) {
      timeout.current = setTimeout(() => {
        setIsLoaderVisible(true);
      }, 300);
    }
    if (!loading) {
      timeout.current && clearTimeout(timeout.current);
      setIsLoaderVisible(false);
    }
    return () => {
      timeout.current && clearTimeout(timeout.current);
    };
  }, [isLoaderVisible, loading]);

  const data = useMemo(() => {
    if (!search?.value) {
      return tableData;
    }
    return tableData.filter((item) => search.comparator(item, search.value));
  }, [search, tableData]);

  return {
    data,
    loading: loading && isLoaderVisible,
    pagination,
    sort,
    selection,
    fetchData,
    setFixedData: setTableData,
  };
};

export { useCMTable };
export type { UseCMTableReturn };
