import React from 'react';

type Props = {
  className?: string;
};

const ModalBody: React.FC<Props> = ({ children, className }) => {
  return <div className={className}>{children}</div>;
};

export { ModalBody };
