import { FC, PropsWithChildren, useMemo } from 'react';
import { CalendarContainer } from 'react-datepicker';

import * as dateFns from 'date-fns';

import { bindStyles } from '@src/utils';
import { Button, CalendarValue, Icon, SelectOption, Tab, TabContext, TabList } from '@src/kit';
import { useAppTranslation } from '@src/common';

import styles from './Container.module.scss';

type PeriodType = [Date | null, Date | null];

enum IntervalVariants {
  MONTH = 'MONTH',
  DAY = 'DAY',
}

type IntervalProps = {
  withInterval?: boolean;
  interval?: IntervalVariants;
  intervals?: SelectOption<IntervalVariants>[];
  onChangeInterval?: (value: IntervalVariants) => void;
};

type FastDatesProps = {
  withFastDates?: boolean;
  activeFastDate?: SelectOption<CalendarValue, string>;
  fastDates?: SelectOption<CalendarValue, string>[];
  onClickFastDate?: (value: SelectOption<CalendarValue, string>) => void;
};

type Props = PropsWithChildren<
  IntervalProps &
    FastDatesProps & {
      className?: string;
      onConfirm: () => void;
      onReset: () => void;
      currentDate: PeriodType | Date | null;
      noNeedPeriodLabel?: boolean;
    }
>;

const cx = bindStyles(styles);

const Container: FC<Props> = ({
  children,
  className,
  intervals,
  onChangeInterval = () => {},
  interval = '',
  withInterval,
  withFastDates,
  onClickFastDate,
  fastDates,
  activeFastDate,
  onConfirm,
  onReset,
  currentDate,
  noNeedPeriodLabel,
}) => {
  const { t } = useAppTranslation();

  const period = useMemo(() => {
    if (Array.isArray(currentDate) && currentDate) {
      if (interval === 'MONTH') {
        return `${
          currentDate[0] &&
          t(`month.${currentDate[0].getMonth() + 1}`) + ' ' + currentDate[0].getFullYear()
        }${
          currentDate[1]
            ? ' - ' +
              t(`month.${currentDate[1].getMonth() + 1}`) +
              ' ' +
              currentDate[1].getFullYear()
            : ''
        }`;
      } else {
        return `${currentDate[0]?.toLocaleDateString()}${
          currentDate[1] ? ' - ' + currentDate[1]?.toLocaleDateString() : ''
        }`;
      }
    } else if (currentDate) {
      return dateFns.format(currentDate, 'dd.MM.yy');
    }
  }, [currentDate]);

  return (
    <CalendarContainer className={cx(className, 'container')}>
      {withInterval && (
        <div className={cx('headerInterval')}>
          <div className={cx('headerIntervalTitleContainer')}>
            <Icon
              type="bar-chart"
              className={cx('headerIntervalTitleIcon')}
            />
            <div className={cx('headerIntervalTitle')}>{t('cos.calendar.interval.title')}</div>
          </div>
          <TabContext value={interval}>
            <TabList
              tabListClassName={cx('tab-list')}
              variant="underlined"
              size="lg"
              onChange={(value) => onChangeInterval(value as IntervalVariants)}
            >
              {intervals?.map((it) => (
                <Tab
                  key={it.value}
                  label={t(String(it.label))}
                  value={it.value}
                />
              ))}
            </TabList>
          </TabContext>
        </div>
      )}
      <div className={cx('content')}>
        {withFastDates && fastDates && (
          <div className={cx('fastDates')}>
            <div className={cx('fastDatesHeader')}>
              <Icon
                type="empty-calendar"
                className={cx('fastDatesHeaderIcon')}
              />
              <div className={cx('fastDatesHeaderTitle')}>{t('cos.calendar.fastDates.title')}</div>
            </div>
            <div className={cx('fastDatesVariants')}>
              {fastDates.map((item) => (
                <Button
                  className={cx('fastDate')}
                  variant={item.meta === activeFastDate?.meta ? 'filled' : 'text'}
                  key={item.meta}
                  size="sm"
                  text={t(String(item.label))}
                  onClick={() => onClickFastDate?.(item)}
                />
              ))}
            </div>
          </div>
        )}
        {children}
      </div>
      <div className={cx('footer')}>
        {!noNeedPeriodLabel && <span className={cx('periodLabel')}>{period}</span>}
        <Button
          text={t('Clear')}
          variant="text"
          size="md"
          onClick={onReset}
        />
        <Button
          text={t('DialogConfirm')}
          size="md"
          onClick={onConfirm}
        />
      </div>
    </CalendarContainer>
  );
};

export { Container, IntervalVariants };
export type { IntervalProps, FastDatesProps, PeriodType };
