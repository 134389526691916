import { FC } from 'react';

import { bindStyles } from '@src/utils';
import { Icon, IconTypes } from '@src/kit';
import { useTranslationPrefix } from '@src/common';

import styles from './SettingItem.module.scss';

const cx = bindStyles(styles);

type SettingsItemProps = {
  type: string;
  icon: IconTypes;
  isActive?: boolean;
  onClick: () => void;
};

const SettingItem: FC<SettingsItemProps> = ({ type, isActive = false, icon, onClick }) => {
  const { t, tPrefix } = useTranslationPrefix('cos.activeDirectory.form.');

  return (
    <div
      className={cx(isActive ? 'settingItemActive' : 'settingItem')}
      onClick={onClick}
    >
      <div className={cx('settingItemWrapper')}>
        <Icon
          type={icon}
          className={cx('activeDirectoryIcon')}
        />
        <p className={cx('settingItemTitle')}>{t(tPrefix(`tab.${type}`))}</p>
        <Icon
          type="chevron-left"
          className={cx('iconChevron')}
          size="md"
        />
      </div>
    </div>
  );
};

export { SettingItem };
