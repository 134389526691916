import { FC, useEffect, useMemo } from 'react';
import { FieldArrayWithId, Path, useFormContext } from 'react-hook-form';

import {
  TariffPriceResourceQuotaDTO,
  TariffResourceTypes,
} from '@common/components/TariffAndPricesComponent/api/models';
import { ConfigurationTariffPlanModal } from '@common/components/TariffAndPricesComponent/components/ConfigurationTariffPlanModal';
import { MixedTariffBadge } from '@common/components/TariffAndPricesComponent/components/MIxedTariffBadge';

import { bindStyles } from '@src/utils';
import { Button, Icon, TextShorter, Tooltip } from '@src/kit';
import {
  FormDTO,
  FormInput,
  useAppFormContext,
  useAppTranslation,
  usePopupControls,
} from '@src/common';

import styles from './TableItem.module.scss';

type Props = {
  i: number;
  resource: FieldArrayWithId<FormDTO, 'resources', 'id'>;
  tariffType: TariffResourceTypes;
  vdcName: string;
  isEdit?: boolean;
};

type ProfileItemName = Path<FormDTO>;

const cx = bindStyles(styles);

const TableItem: FC<Props> = ({ resource, i, isEdit, tariffType, vdcName }) => {
  const { t } = useAppTranslation();
  const { isOpened, closePopup, openPopup } = usePopupControls();
  const { watch, formState, clearErrors } = useAppFormContext();
  const quotas = watch(`resources.${resource.index}.quotas`);

  const articleNameKey = `resources.${resource.index}.skuName` as ProfileItemName;
  const priceKey = `resources.${resource.index}.price` as ProfileItemName;

  const isMixed = tariffType === TariffResourceTypes.MIXED;

  const tariffTypeLabel = useMemo(() => {
    if (!isMixed) {
      return t(`cos.settings.tariffprices.input.tariff.${tariffType}`);
    }
  }, [t, tariffType]);

  const { setValue } = useFormContext();

  const saveQuotas = (quotas: TariffPriceResourceQuotaDTO[]) => {
    setValue(`resources.${resource.index}.quotas`, quotas);
  };

  useEffect(() => {
    if (isEdit && resource.price === 0) {
      return setValue(`resources.${resource.index}.price`, '0');
    }
  }, [isEdit, resource]);

  const hasError = useMemo(() => {
    return formState.errors.resources?.[resource.index]?.quotas;
  }, [formState, resource.index]);

  const renderedMixed = useMemo(() => {
    if (quotas?.length) {
      if (hasError) {
        clearErrors(`resources.${resource.index}.quotas`);
      }

      return (
        <div className={cx('quotasWrapper')}>
          <div className={cx('quotas')}>
            {quotas.map((it: TariffPriceResourceQuotaDTO) => (
              <MixedTariffBadge
                key={String(it.quotaFrom) + String(it.quotaTo)}
                from={it.quotaFrom}
                to={it.quotaTo}
                priceType={it.billingType}
                price={it.rate}
              />
            ))}
          </div>
          <Button
            variant="text"
            onClick={openPopup}
          >
            <Icon type="edit" />
          </Button>
        </div>
      );
    }

    return (
      <div className={cx('quotas', 'button')}>
        <Button
          size="sm"
          variant="outline"
          className={cx('quotaButton')}
          onClick={openPopup}
          text={t('cos.settings.tariffprices.quota.setting.button')}
        />
        {hasError && (
          <Tooltip text={hasError.message}>
            <Icon
              type="circle-warning"
              size="md"
              className={cx('errorIcon')}
            />
          </Tooltip>
        )}
      </div>
    );
  }, [t, quotas, hasError]);

  if (isEdit) {
    return (
      <div className={cx('wrapper', { odd: i % 2 !== 0 })}>
        <div className={cx('info')}>
          <TextShorter tooltip>{resource.resourceType}</TextShorter>
        </div>
        <div className={cx('info')}>
          <FormInput
            size="md"
            name={articleNameKey}
            placeholder={t('cos.settings.tariffprices.input.articleName')}
          />
        </div>
        <div className={cx('info', { quotas: !!quotas?.length && isMixed })}>
          {isMixed ? (
            renderedMixed
          ) : (
            <FormInput
              type="number"
              size="md"
              name={priceKey}
              isError={Boolean(hasError)}
              decimals={2}
              placeholder={t('cos.settings.tariffprices.input.placeholder.price')}
            />
          )}
        </div>
        {!isMixed && <div className={cx('info')}>{tariffTypeLabel}</div>}
        {isOpened && (
          <ConfigurationTariffPlanModal
            onClose={closePopup}
            vdcName={vdcName}
            resourceType={resource.resourceType}
            onReady={saveQuotas}
            initValues={quotas}
          />
        )}
      </div>
    );
  }

  return (
    <div className={cx('wrapper', { odd: i % 2 !== 0 })}>
      <div className={cx('info')}>
        <TextShorter tooltip>{resource.resourceType}</TextShorter>
      </div>
      <div className={cx('info', { placeholder: !resource.skuName })}>
        {resource.skuName || t('cos.settings.tariffprices.input.articleName')}
      </div>
      <div className={cx('info', 'price', { placeholder: resource.price === null })}>
        {resource.price === null ? t('cos.settings.tariffprices.input.price') : resource.price}
      </div>
      <div className={cx('info')}>{tariffTypeLabel}</div>
    </div>
  );
};

export { TableItem };
