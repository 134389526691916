import React, { useCallback, useMemo } from 'react';
import {
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
  Path,
  UnpackNestedValue,
  PathValue,
  Validate,
} from 'react-hook-form';

import { VCloudDirectorStorageProfile } from '@common/components/AppToolbar/components/CloudConnect/types';

import { useCurrencyContext, useTranslationPrefix } from '@src/common';
import { bindStyles } from '@src/utils';
import { Icon, Input, InputNumber } from '@src/kit';

import styles from './ProfileItem.module.scss';

type StorageForm<F> = F & {
  storageProfiles?: VCloudDirectorStorageProfile[];
};

type ProfileItemName<F> = Path<StorageForm<F>>;
type ProfileItemValue<F> = UnpackNestedValue<PathValue<StorageForm<F>, ProfileItemName<F>>>;
type ProfileItemValidate<F> = Validate<PathValue<StorageForm<F>, Path<StorageForm<F>>>>;

type Props<F> = {
  onDelete: () => void;
  position: number;
  register: UseFormRegister<StorageForm<F>>;
  watch: UseFormWatch<StorageForm<F>>;
  setValue: UseFormSetValue<StorageForm<F>>;
  errors: FieldErrors<StorageForm<F>>;
  clearErrors: (name: ProfileItemName<F>) => void;
  otherValues?: VCloudDirectorStorageProfile[];
  disableMaxSize?: boolean;
  disableName?: boolean;
};

const cx = bindStyles(styles);

const MIN_VALUE = 0.01;

const ProfileItem = <F,>({
  position,
  register,
  onDelete,
  watch,
  errors,
  otherValues,
  setValue,
  clearErrors,
  disableMaxSize,
  disableName,
}: Props<F>) => {
  const { tPrefix, t } = useTranslationPrefix('cos.connect.clouds.vCloudDirector.profileItem.');
  const { customerCurrency, currenciesList } = useCurrencyContext();

  const suffix = currenciesList.find((it) => it.code === customerCurrency)?.name;

  const keyName = `storageProfiles.${position}.key` as ProfileItemName<F>;
  const valueName = `storageProfiles.${position}.value` as ProfileItemName<F>;
  const maxSizeName = `storageProfiles.${position}.maxSize` as ProfileItemName<F>;

  const value = watch(valueName) as string;
  const key = watch(keyName) as string;
  const maxSize = watch(maxSizeName) as string;

  const validateKey = useCallback(
    (key: string) => {
      const items = otherValues?.filter((it) => it.key === key);
      if (items?.length && items.length > 1) {
        return t('SameKey');
      }
    },
    [otherValues, t],
  );

  const registerMaxSize = useMemo(() => {
    return register(maxSizeName);
  }, [maxSizeName]);

  const onChangeMaxSize = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearErrors(maxSizeName);
    setValue(maxSizeName, e.target.value as ProfileItemValue<F>);
  };

  const isFirst = position === 0;
  return (
    <div className={cx('profileItemContainer')}>
      <div className={cx('profileItemContent', { isFirst })}>
        <Input
          {...register(keyName, {
            required: t('FieldRequired') as string,
            validate: validateKey as ProfileItemValidate<F>,
          })}
          value={key}
          caption={isFirst ? tPrefix('key.caption') : undefined}
          placeholder={tPrefix('key.placeholder.long')}
          isError={Boolean(errors.storageProfiles?.[position]?.key?.message)}
          errorText={errors.storageProfiles?.[position]?.key?.message}
          hint={tPrefix('key.hint')}
          size="md"
          disabled={disableName}
        />
        <InputNumber
          {...register(valueName, {
            required: t('FieldRequired') as string,
            min: {
              value: MIN_VALUE,
              message: t('cos.price.min.value.error', { value: MIN_VALUE }),
            },
          })}
          type="number"
          className={cx('price')}
          value={value}
          decimals={2}
          caption={isFirst ? tPrefix('value.caption') : undefined}
          placeholder={tPrefix('value.placeholder')}
          suffix={suffix}
          isError={Boolean(errors.storageProfiles?.[position]?.value?.message)}
          errorText={errors.storageProfiles?.[position]?.value?.message}
          hint={tPrefix('value.hint')}
          size="md"
        />
        <InputNumber
          {...registerMaxSize}
          onChange={onChangeMaxSize}
          value={maxSize}
          classes={{ error: cx('inputError') }}
          name={`storageProfiles.${position}.maxSize`}
          placeholder={tPrefix('maxSize.placeholder')}
          units={t('UnitGb')}
          withControls={!disableMaxSize}
          caption={isFirst ? t('cld.HyperV.modal.editable.size') : undefined}
          hint={t('cld.HyperV.modal.editable.hint.size')}
          isError={Boolean(errors.storageProfiles?.[position]?.maxSize?.message)}
          errorText={errors.storageProfiles?.[position]?.maxSize?.message}
          tooltipClass={cx('tootlipSizeContainer')}
          size="md"
          disabled={disableMaxSize}
        />
        <Icon
          type="close"
          className={cx('deleteIcon', { isFirst })}
          onClick={onDelete}
        />
      </div>
    </div>
  );
};

export type { StorageForm };
export { ProfileItem };
