import { SystemModule } from '@kit/components/SystemModulesBlock/@types/types';

import ArchitectModule from '@assets/images/ArchitectModule.svg';
import ArchitectModuleLabel from '@assets/images/ArchitectModuleLabel.svg';
import ClouderModule from '@assets/images/ClouderModule.svg';
import ClouderModuleLabel from '@assets/images/ClouderModuleLabel.svg';
import CosterImage from '@assets/images/CosterModule.svg';
import CosterModuleLabel from '@assets/images/CosterModuleLabel.svg';

const COSTER_MODULE: Partial<SystemModule> = {
  key: 'coster',
  image: <CosterImage />,
  label: <CosterModuleLabel />,
};

const MSP_MODULE: Partial<SystemModule> = {
  key: 'msp',
  image: <ClouderModule />,
  label: <ClouderModuleLabel />,
};

const ARCHITECT_MODULE: Partial<SystemModule> = {
  key: 'architect',
  image: <ArchitectModule />,
  label: <ArchitectModuleLabel />,
};

export { COSTER_MODULE, MSP_MODULE, ARCHITECT_MODULE };
