import React, { Children } from 'react';
import { components, MenuListProps } from 'react-select';

import { useLocalSearchInSelect } from '@common/hooks/useLocalSearchInSelect';

import styles from './Select.module.scss';

type Props = MenuListProps;

const SearchMenuList: React.FC<Props> = ({ children, ...props }) => {
  const { input, searchedItems } = useLocalSearchInSelect({
    items: Children.toArray(children),
  });

  return (
    <>
      <div className={styles.search}>{input}</div>
      <components.MenuList {...props}>{searchedItems}</components.MenuList>
    </>
  );
};

export { SearchMenuList };
