import React from 'react';
import { components, ValueContainerProps } from 'react-select';

import cn from 'classnames/bind';

import { Size } from '@src/@types';

import styles from './Select.module.scss';

type Props = ValueContainerProps & {
  size?: Extract<Size, 'sm' | 'md' | 'lg'>;
  adaptiveOff?: boolean;
};

const cx = cn.bind(styles);

const ValueContainer: React.FC<Props> = ({ children, isDisabled, size, adaptiveOff, ...props }) => (
  <components.ValueContainer
    {...props}
    isDisabled={isDisabled}
    className={cx('valueContainer', [size], { isDisabled, adaptiveOn: !adaptiveOff })}
  >
    {children}
  </components.ValueContainer>
);

export { ValueContainer };
