import { useEffect, useMemo } from 'react';

import { CurrentRateDTO, useCurrencyContext } from '@common/components';

import { usePreviousState } from '@src/common';

const wasChangedInRates = (prev: CurrentRateDTO[], current: CurrentRateDTO[], currency: string) => {
  const currencies = ['USD', 'RUB', 'INR'];
  const currencyIndex = currencies.indexOf(currency);

  currencies.splice(currencyIndex, 1);

  const previousCurrencyToFirst = prev.find(
    (it) => it.source === currency && it.receiver === currencies[0],
  );
  const previousCurrencyToSecond = prev.find(
    (it) => it.source === currency && it.receiver === currencies[1],
  );
  const currentCurrencyToFirst = current.find(
    (it) => it.source === currency && it.receiver === currencies[0],
  );
  const currentCurrencyToSecond = current.find(
    (it) => it.source === currency && it.receiver === currencies[1],
  );

  return (
    previousCurrencyToFirst?.rate !== currentCurrencyToFirst?.rate ||
    previousCurrencyToSecond?.rate !== currentCurrencyToSecond?.rate
  );
};

const useCallbackAfterCurrencyChanged = (...fetchFn: Array<() => unknown>) => {
  const { currency, currentRates } = useCurrencyContext();

  const previousCurrency = usePreviousState(currency);
  const previousRates = usePreviousState(currentRates);

  const changedRates = useMemo(() => {
    if (previousRates.length && currentRates.length) {
      const usdChanges = wasChangedInRates(previousRates, currentRates, 'USD');
      const rubChanges = wasChangedInRates(previousRates, currentRates, 'RUB');
      const inrChanges = wasChangedInRates(previousRates, currentRates, 'INR');

      return usdChanges || rubChanges || inrChanges;
    }
  }, [previousRates, currentRates]);

  useEffect(() => {
    if (previousCurrency === currency && !changedRates) {
      return;
    }

    fetchFn.forEach((fn) => fn());
  }, [currency, changedRates]);
};

export { useCallbackAfterCurrencyChanged };
