import React from 'react';

import { Icon } from '@kit/components';
import { ContactData } from '@kit/components/ReferencePopup';

import { bindStyles } from '@src/utils';

import styles from '../ReferencePopup.module.scss';

const cx = bindStyles(styles);

type Props = {
  title: string;
  contacts: Partial<ContactData>;
};

const Support: React.FC<Props> = ({ contacts, title }) => (
  <div className={cx('support')}>
    <div className={cx('supportInner')}>
      <p className={cx('subtitle')}>{title}:</p>
      <div className={cx('supportLinks')}>
        {contacts.phone && (
          <a
            href={`tel:${contacts.phone}`}
            className={cx('supportLink')}
          >
            <Icon type="call" />
            <span className={cx('supportLinkText')}>{contacts.phone}</span>
          </a>
        )}
        {contacts.email && (
          <a
            href={`mailto:${contacts.email}`}
            className={cx('supportLink')}
          >
            <Icon type="email" />
            <span className={cx('supportLinkText')}>{contacts.email}</span>
          </a>
        )}
      </div>
    </div>
  </div>
);

export { Support };
