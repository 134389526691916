import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import cn from 'classnames/bind';
import { VSphereDTO } from '@common/api/VSphere/models';
import { AWSConnectionFormDTO, Stages } from "@common/components/AppToolbar/components/CloudConnect/types";
import { connectVSphere } from '@common/components/AppToolbar/api/cloud-connections';

import {
  Form,
  StorageProfile,
  useAnalytics,
  useAppTranslation,
  useCloudConnectContext,
  useToast,
} from '@src/common';
import { CLOUD_NAME_DICTIONARY } from '@src/constants';
import { Gb } from '@src/@types';

import { ErrorUtils } from '@utils/ErrorUtils';
import { gbToMb } from '@utils/common';

import { StorageSettingsStep } from './StorageSettingsStep';
import { ConnectionStep } from './ConnectionStep';
import styles from './FormVSphere.module.scss';

type VSphereRequestDto = VSphereDTO;

const cx = cn.bind(styles);

enum VSphereSteps {
  CONNECTION = 'connection',
  PROFILES = 'profiles',
}

const FormVSphere: React.FC = () => {
  const { t } = useAppTranslation();
  const toast = useToast();
  const form = useForm<VSphereRequestDto>({
    defaultValues: {
      apiVersion: { label: '6.0', value: '6.0' },
    },
  });
  const { selectStage, changeAgreeButtonSettings, changeDisagreeButtonSettings } =
    useCloudConnectContext();
  const { logSuccessEvent, logErrorEvent } = useAnalytics();

  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(VSphereSteps.CONNECTION);

  const createNewConnect = async (DTO: VSphereRequestDto) => {
    try {
      setIsLoading(true);
      const storageProfiles: StorageProfile[] =
        DTO.storageProfiles?.map((item) => ({
          name: item.key,
          price: item.value,
          maxSize: item.maxSize.length ? gbToMb(Number(item.maxSize) as Gb) : undefined,
        })) || [];
      const apiVersion = DTO.apiVersion.value;
      await connectVSphere({ ...DTO, apiVersion, storageProfiles });
      selectStage({
        stage: Stages.connectResult,
      });
      toast.success({
        text: `${t('CloudConnectedSuccessfully', { cloud: CLOUD_NAME_DICTIONARY.vSphere })}`,
      });
      logSuccessEvent('Created cloud connection', { cloud: CLOUD_NAME_DICTIONARY.vSphere });
    } catch (error) {
      const errorMsg = ErrorUtils.handleApiError(error);
      toast.error({ text: errorMsg });
      logErrorEvent('Created cloud connection', {
        cloud: CLOUD_NAME_DICTIONARY.vSphere,
        message: errorMsg,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onBackHandler = useCallback(() => {
    if (step === VSphereSteps.CONNECTION) {
      selectStage({
        stage: Stages.providerChoice,
      });
      changeAgreeButtonSettings({
        text: t('Connect'),
      });
    } else {
      setStep(VSphereSteps.CONNECTION);
    }
  }, [step, t]);

  const onSubmit = useCallback(() => {
    if (step === VSphereSteps.CONNECTION) {
      form.handleSubmit(() => {
        setStep(VSphereSteps.PROFILES);
      })();
    } else {
      form.handleSubmit(createNewConnect)();
    }
  }, [step]);

  const agreeButtonText = useMemo(() => {
    return step === VSphereSteps.CONNECTION ? t('Next') : t('Connect');
  }, [step]);

  useEffect(() => {
    changeDisagreeButtonSettings({
      onClick: onBackHandler,
    });
    changeAgreeButtonSettings({
      onClick: onSubmit,
      text: agreeButtonText,
      isLoading,
    });
  }, [onBackHandler, onSubmit, agreeButtonText, isLoading]);

  return (
    <Form
      formMethods={form}
      onSubmit={createNewConnect}
    >
      <div className={cx('modalContainer')}>
        {step === VSphereSteps.CONNECTION ? <ConnectionStep /> : <StorageSettingsStep />}
      </div>
    </Form>
  );
};

export { FormVSphere };
