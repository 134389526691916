import React, { useEffect, useMemo, useState } from 'react';

import cn from 'classnames/bind';
import { Stages } from '@common/components/AppToolbar/components/CloudConnect/types';
import { ProviderSelectionHeader } from '@common/components/AppToolbar/components/CloudConnect/ProviderSelection/ProviderSelectionHeader';

import { useAppTranslation, useCloudConnectContext } from '@src/common';
import { CMModal, Modal } from '@src/kit';

import styles from './CloudConnect.module.scss';
import { ConnectedCloudsHeader } from './ConnectedClouds/ConnectedCloudsHeader';
import { ConnectedCloudsFooter } from './ConnectedClouds/ConnectedCloudsFooter';
import { ProviderSelectionFooter } from './ProviderSelection/ProviderSelectionFooter';

const headers: { [key: string]: JSX.Element } = {
  [Stages.connectedClouds]: <ConnectedCloudsHeader />,
  [Stages.providerChoice]: <ProviderSelectionHeader />,
};

const footers: { [key: string]: JSX.Element } = {
  [Stages.connectedClouds]: <ConnectedCloudsFooter />,
  [Stages.providerChoice]: <ProviderSelectionFooter />,
};

export const CloudConnectView: React.FC = ({ children }) => {
  const cx = cn.bind(styles);
  const {
    open,
    stage: activeStage,
    selectedProvider,
    title,
    agreeButtonSettings: submitBtn,
    disagreeButtonSettings: backBtn,
    onClose,
  } = useCloudConnectContext();
  const { t } = useAppTranslation();
  const [isInitLoading, setIsInitLoading] = useState(false);

  useEffect(() => {
    if (selectedProvider && ['HyperV', 'OpenStack', 'vSphere'].includes(selectedProvider)) {
      setIsInitLoading(true);
      setTimeout(() => setIsInitLoading(false), 100);
    }
  }, [selectedProvider]);

  const wideWindow = useMemo(() => {
    return [Stages.connectedClouds, Stages.providerChoice].includes(activeStage as Stages);
  }, [activeStage]);

  const agreeButtonSettings = useMemo(
    () => ({
      ...submitBtn,
      text: submitBtn.text || t('cos.connect.text'),
      isLoading: isInitLoading || submitBtn.isLoading,
      disabled: isInitLoading || submitBtn.isLoading,
    }),
    [t, submitBtn, isInitLoading],
  );

  const disagreeButtonSettings = useMemo(
    () => ({
      ...backBtn,
      text: backBtn.text || t('back'),
    }),
    [t, backBtn],
  );

  if (activeStage === Stages.connectResult) {
    return (
      <Modal
        open={open}
        onClose={onClose}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        className={cx('successContainer', { wideWindow })}
      >
        {children}
      </Modal>
    );
  }

  return (
    <CMModal
      contentClassName={cx(selectedProvider)}
      open={open}
      onClose={onClose}
      closeOnDocumentClick={false}
      closeOnEscape={false}
      className={cx('container', { wideWindow }, `container_${activeStage}`)}
      headerJSX={headers[activeStage as Stages]}
      footerJSX={footers[activeStage as Stages]}
      title={title}
      agreeButtonSettings={agreeButtonSettings}
      disagreeButtonSettings={disagreeButtonSettings}
      lockBodyScroll
    >
      {children}
    </CMModal>
  );
};
