import { FC } from 'react';

import { useAppToolbarContext, useAppTranslation } from '@src/common';
import { bindStyles } from '@src/utils';
import { CMModalFooter } from '@src/kit';

import styles from './ProviderSelectionFooter.module.scss';

const cx = bindStyles(styles);

const ProviderSelectionFooter: FC = () => {
  const { t } = useAppTranslation();

  const { supportEmail } = useAppToolbarContext();

  return (
    <CMModalFooter>
      <p className={cx('text')}>
        {t('noProviderInTheList')}
        <a href={`mailto:${supportEmail}`}>&nbsp;{t('ContactUs')}</a>.
      </p>
    </CMModalFooter>
  );
};

export { ProviderSelectionFooter };
