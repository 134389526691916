import React, { useCallback, useMemo, useRef, useState } from 'react';

import { AboutProductSection, Icon, ReferenceScreenProps } from '@kit/components';
import {
  AppSection,
  ContactData,
  CurrencyData,
  ReferencePopupLocalization,
} from '@kit/components/ReferencePopup/@types/types';
import { MainScreen } from '@kit/components/ReferencePopup/components/MainScreen';
import { ModalHeader } from '@kit/components/ReferencePopup/components/ModalHeader';
import { TourWidgetProps } from '@kit/components/ReferencePopup/components/TourWidget';
import { HeaderSideModal } from '@kit/components/HeaderSideModal';

import { bindStyles } from '@src/utils';

import styles from './ReferencePopup.module.scss';

type Classes = {
  wrapper: string;
  modal: string;
  header: string;
  content: string;
  inner: string;
};

const cx = bindStyles(styles);

const ABOUT_KEY = '_about';

type ReferencePopupProps = {
  currencies?: CurrencyData[];
  localization: ReferencePopupLocalization;
  contacts?: Partial<ContactData>;
  triggerClassNames?: string;
  sections?: AppSection[];
  aboutSection: AboutProductSection;
  tourWidgetSettings?: TourWidgetProps;
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
  onOnboardingStart: () => void;
  contentStyle?: React.CSSProperties;
  overlayStyle?: React.CSSProperties;
  classes?: Partial<Classes>;
  docsPortal?: React.ReactNode;
};

const ReferencePopup: React.FC<ReferencePopupProps> = ({
  aboutSection,
  sections,
  localization,
  contacts,
  tourWidgetSettings,
  triggerClassNames,
  open,
  onOpen,
  classes,
  onClose,
  currencies,
  onOnboardingStart,
  overlayStyle,
  contentStyle,
  docsPortal,
}) => {
  const [activeScreenName, setActiveScreenName] = useState<string | null>(null);

  const refParentContent = useRef<HTMLDivElement | null>(null);

  const handleClose = () => {
    onClose();
    setActiveScreenName(null);
  };

  const onNavigate = (name: string) => {
    setActiveScreenName(name);
    refParentContent.current?.scrollTo(0, 0);
  };

  const onAboutNavigate = () => {
    setActiveScreenName(ABOUT_KEY);
  };

  const activeScreen = useMemo(() => {
    if (activeScreenName === ABOUT_KEY) {
      return aboutSection?.detailComponent;
    }

    return sections?.find((item) => item.name === activeScreenName)?.component;
  }, [sections, activeScreenName]);

  const nonActiveScreens = useMemo(
    () => sections?.filter((item) => item.name !== activeScreenName),
    [sections, activeScreenName],
  );

  const toMainScreen = () => setActiveScreenName(null);

  const initRef = useCallback((element: HTMLDivElement | null) => {
    refParentContent.current = (element?.parentElement as HTMLDivElement) ?? null;
  }, []);

  return (
    <>
      <HeaderSideModal
        modalHeader={
          <ModalHeader
            title={localization.title}
            backVisible={Boolean(activeScreen)}
            onClose={handleClose}
            onBack={toMainScreen}
          />
        }
        trigger={
          <div>
            <Icon
              type="circle-help"
              className={triggerClassNames}
            />
          </div>
        }
        open={open}
        onClose={handleClose}
        onOpen={onOpen}
        classes={classes}
        overlayStyle={overlayStyle}
        contentStyle={contentStyle}
        closeOnEscape={false}
      >
        <div
          className={cx('modal')}
          ref={initRef}
        >
          {activeScreen ? (
            React.createElement<ReferenceScreenProps>(activeScreen, {
              title: localization.readAlsoTitle,
              sections: nonActiveScreens || [],
              onNavigate,
              onOnboardingStart,
            })
          ) : (
            <MainScreen
              docsPortal={docsPortal}
              localization={localization}
              contacts={contacts}
              onAbout={onAboutNavigate}
              tourWidgetSettings={tourWidgetSettings}
              currencies={currencies}
            >
              {React.createElement(aboutSection.enterComponent)}
            </MainScreen>
          )}
        </div>
      </HeaderSideModal>
    </>
  );
};

export { ReferencePopup };
export type { ReferencePopupProps };
