import { FC } from 'react';

import { useCloudsContext } from '@common/providers/CloudsProvider';

import { FormInput, required, urlPattern, useAppTranslation } from '@src/common';
import { Disclaimer } from '@src/kit';
import { bindStyles } from '@src/utils';
import { DOMAIN, KEYSTONE_AUTH_URL, NAME, PASSWORD_FIELD, USERNAME } from '@src/constants';

import styles from '../OpenStackConnection.module.scss';
import { SECRET_FIELD_EDIT_PLACEHOLDER } from '@common/components/Clouds/components';

const cx = bindStyles(styles);

const ConnectionStep: FC = () => {
  const { t } = useAppTranslation();
  const { cloudEdit } = useCloudsContext();
  return (
    <>
      <div>
        <div className={cx('connectionBlock')}>
          <div className={cx('info')}>
            <Disclaimer text={t('cos.cloud.connect.openstack.disclaimer')} />
          </div>
          <div className={cx('blockContent')}>
            <FormInput
              placeholder={t('OpenStackNamePC')}
              name={NAME}
              rules={{ ...required('FieldRequired') }}
              caption={t('OpenStackName')}
              hint={t('OpenStackNameHint')}
            />
            <FormInput
              placeholder={cloudEdit ? SECRET_FIELD_EDIT_PLACEHOLDER : t('OpenStackURLPC')}
              name={KEYSTONE_AUTH_URL}
              rules={{ ...urlPattern('InputFormatError') }}
              caption={t('OpenStackURL')}
              hint={t('OpenStackURLHint')}
              disabled={cloudEdit}
            />
          </div>
        </div>
        <div className={cx('connectionBlock')}>
          <div className={cx('blockContent')}>
            <FormInput
              placeholder={cloudEdit ? SECRET_FIELD_EDIT_PLACEHOLDER : t('OpenStackDomainPC')}
              name={DOMAIN}
              caption={t('OpenStackDomain')}
              disabled={cloudEdit}
            />
          </div>
        </div>
        <div className={cx('connectionBlock')}>
          <div className={cx('blockContent')}>
            <FormInput
              hint={t('OpenStackUserNameHint')}
              placeholder={cloudEdit ? SECRET_FIELD_EDIT_PLACEHOLDER : t('OpenStackUserNamePC')}
              name={USERNAME}
              caption={t('OpenStackUserName')}
              disabled={cloudEdit}
            />
            <FormInput
              type="password"
              hint={t('OpenStackUserPassHint')}
              placeholder={cloudEdit ? SECRET_FIELD_EDIT_PLACEHOLDER : t('OpenStackUserPassPC')}
              name={PASSWORD_FIELD}
              caption={t('OpenStackUserPass')}
              autoComplete="new-password"
              disabled={cloudEdit}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export { ConnectionStep };
