import React, { useEffect, useRef } from 'react';

import cn from 'classnames/bind';

import styles from './ButtonGroup.module.scss';

type Props = {
  gap?: string;
  className?: string;
};

const ButtonGroup: React.FC<Props> = ({ gap = '2', className, children }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const cx = cn.bind(styles);
  useEffect(() => {
    if (ref.current) ref.current.style.setProperty('--buttons-gap', `${gap}rem`);
  }, [gap]);
  return (
    <div
      className={cx(className, 'body')}
      ref={ref}
    >
      {children}
    </div>
  );
};

export { ButtonGroup };
