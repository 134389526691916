import { FC } from 'react';

import { Button, Icon } from '@src/kit';
import { useAppTranslation } from '@src/common';
import { bindStyles } from '@src/utils';

import styles from './AddRowButton.module.scss';

type Props = {
  text?: string;
  onAdd: () => void;
};

const cx = bindStyles(styles);

const AddRowButton: FC<Props> = ({ text, onAdd }) => {
  const { t } = useAppTranslation();

  return (
    <Button
      className={cx('button')}
      variant="text"
      onClick={onAdd}
      text={text || t('cos.add_row_button')}
    >
      <Icon type="add" />
    </Button>
  );
};

export { AddRowButton };
