import React, { useEffect, useMemo, useRef, useState } from 'react';

import cn from 'classnames/bind';

import styles from './Row.module.scss';

type Props = {
  className?: string;
  minChildWidth: number;
  maxChildWidth?: number;
  gap?: number;
};

export const Row: React.FC<Props> = ({
  children,
  className,
  minChildWidth,
  maxChildWidth,
  gap = 0,
}) => {
  const childsCount = React.Children.count(children);
  const [currentCount, setCurrentCount] = useState(1);
  const [countCanHold, setCountCanHold] = useState(0);

  const ref = useRef<HTMLDivElement | null>(null);

  const cx = cn.bind(styles);

  const steps = useMemo(() => {
    let i = 0;
    const arr = [childsCount || 1];
    while (arr[i] > 1) {
      arr.push(Math.ceil(arr[i] / 2));
      i = i + 1;
    }
    return arr;
  }, [childsCount]);

  const updateCanHold = () => {
    if (ref.current) {
      setCountCanHold(Math.floor((ref.current.offsetWidth + gap) / (minChildWidth + gap)));
    }
  };

  useEffect(() => {
    updateCanHold();
  }, [minChildWidth, gap, children, ref.current]);

  useEffect(() => {
    window.addEventListener('resize', updateCanHold);
    return () => {
      window.removeEventListener('resize', updateCanHold);
    };
  }, []);

  useEffect(() => {
    const newValue = steps.find((item) => item <= countCanHold) || 1;
    if (countCanHold >= steps[steps.indexOf(currentCount) - 1] && steps.indexOf(currentCount) > 0) {
      setCurrentCount(newValue);
    }
    if (countCanHold < currentCount) setCurrentCount(newValue);
  }, [countCanHold, children]);

  useEffect(() => {
    if (ref.current) {
      const maxWidth = maxChildWidth ? `${maxChildWidth}px` : '1fr';

      ref.current.style.setProperty('--cols-count', `${currentCount}`);
      ref.current.style.setProperty('--max-width', maxWidth);
    }
  }, [currentCount, maxChildWidth]);

  return (
    <div
      className={cx(className, 'body')}
      ref={ref}
      data-count={currentCount}
    >
      {children}
    </div>
  );
};
