import { FC, useCallback, useMemo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import {
  OpenstackFetchProfilesDTO,
  VCloudDirectorStorageProfile,
} from '@common/components/AppToolbar/components/CloudConnect/types';
import { fetchOpenstackProfiles } from '@common/components/AppToolbar/api/cloud-connections';
import { ProfileItem } from '@common/components/AppToolbar/components/CloudConnect/ProfileItem';
import { useCloudsContext } from '@common/providers/CloudsProvider';

import { bindStyles, ErrorUtils } from '@src/utils';
import {
  FormInput,
  moreThanZero,
  required,
  useAnalytics,
  useAppTranslation,
  useCurrencyContext,
  useToast,
} from '@src/common';
import { Button, Icon } from '@src/kit';
import { CLOUD_NAME_DICTIONARY, CORE_COST, HDD_COST, RAM_COST } from '@src/constants';

import styles from '../OpenStackConnection.module.scss';

const cx = bindStyles(styles);

const StorageSettingsStep: FC = () => {
  const { t } = useAppTranslation();
  const toast = useToast();
  const { cloudEdit } = useCloudsContext();
  const { customerCurrency, currenciesList } = useCurrencyContext();
  const suffix = currenciesList.find((it) => it.code === customerCurrency)?.name;
  const { logEvent } = useAnalytics();

  const { control, formState, watch, setValue, register, clearErrors, setError } = useFormContext();
  const [isLoading, setIsLoading] = useState(false);

  const formValues = watch();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'storageProfiles',
  });

  const addProfile = useCallback(() => {
    append(
      {
        key: '',
        value: '',
        maxSize: '',
      },
      {
        shouldFocus: false,
      },
    );
  }, []);

  const requiredError = useMemo(() => t('FieldRequired'), [t]);

  const loadProfiles = useCallback(async () => {
    try {
      setIsLoading(true);
      logEvent('Click Load Profiles', { cloudType: CLOUD_NAME_DICTIONARY.OpenStack });

      !formValues.username && setError('username', { message: requiredError });
      !formValues.nativeRegionName && setError('nativeRegionName', { message: requiredError });
      !formValues.keystoneAuthUrl && setError('keystoneAuthUrl', { message: requiredError });
      !formValues.password && setError('password', { message: requiredError });

      if (
        !formValues.username ||
        !formValues.nativeRegionName ||
        !formValues.keystoneAuthUrl ||
        !formValues.password
      ) {
        return;
      }

      const dto: OpenstackFetchProfilesDTO = {
        password: formValues.password,
        keystoneAuthUrl: formValues.keystoneAuthUrl,
        username: formValues.username,
        nativeRegionName: formValues.nativeRegionName,
      };
      const res = await fetchOpenstackProfiles(dto);
      res.data.storageProfiles.forEach((it) => {
        if (
          !formValues.storageProfiles?.some(
            (field: VCloudDirectorStorageProfile) => field.key === it,
          )
        ) {
          append({
            key: it,
            value: '',
            maxSize: '',
          });
        }
      });
    } catch (e) {
      const errorMsg = ErrorUtils.handleApiError(e);
      toast.error({ text: errorMsg });
    } finally {
      setIsLoading(false);
    }
  }, [formValues, requiredError, fields]);

  const renderedProfiles = useMemo(() => {
    return fields.map((it, index) => (
      <ProfileItem
        key={it.id}
        setValue={setValue}
        onDelete={() => remove(index)}
        position={index}
        register={register}
        watch={watch}
        errors={formState.errors}
        otherValues={formValues.storageProfiles}
        clearErrors={clearErrors}
        disableMaxSize={cloudEdit}
        disableName={cloudEdit}
      />
    ));
  }, [formState.errors, fields, formValues, cloudEdit]);

  const costValidation = moreThanZero(t('ValueMustBeMoreThan', { value: 0 }));

  return (
    <>
      <div className={cx('connectionBlock')}>
        <div className={cx('blockContent', 'three-small')}>
          <FormInput
            type="number"
            decimals={2}
            suffix={suffix}
            name={CORE_COST}
            rules={{
              ...required('FieldRequired'),
              ...costValidation,
            }}
            caption={t('OpenStackCPU')}
            hint={t('OpenStackCPUHint')}
            placeholder={t('CostInCurrency', { currency: suffix })}
          />
          <FormInput
            type="number"
            decimals={2}
            suffix={suffix}
            name={RAM_COST}
            rules={{
              ...required('FieldRequired'),
              ...costValidation,
            }}
            caption={t('OpenStackRAM')}
            hint={t('OpenStackRAMHint')}
            placeholder={t('CostInCurrency', { currency: suffix })}
          />
          <FormInput
            type="number"
            decimals={2}
            suffix={suffix}
            name={HDD_COST}
            rules={{
              ...required('FieldRequired'),
              ...costValidation,
            }}
            caption={t('OpenStackDisk')}
            hint={t('OpenStackDiskHint')}
            placeholder={t('CostInCurrency', { currency: suffix })}
          />
        </div>
      </div>

      <div className={cx('blockTitle', { withBorder: !fields.length })}>
        {t('cos.cloud.connect.openstack.ProfilesTitle')}
      </div>
      {Boolean(fields.length) && <div className={cx('items')}>{renderedProfiles}</div>}

      <div className={cx('buttonsContainer')}>
        <Button
          variant="text"
          onClick={addProfile}
          textClassName={cx('appendButtonText')}
          className={cx('addButton')}
          text={t('cos.cloud.connect.cloudDirector.add.profile')}
          disabled={cloudEdit}
        >
          <div className={cx('buttonContent')}>
            <Icon
              type="close"
              className={cx('plusIcon')}
            />
          </div>
        </Button>
        <Button
          variant="outline"
          text={t('cos.cloud.connect.cloudDirector.load.profiles')}
          onClick={loadProfiles}
          isLoading={isLoading}
          disabled={cloudEdit}
        />
      </div>
    </>
  );
};

export { StorageSettingsStep };
