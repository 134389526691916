import React from 'react';

import cn from 'classnames/bind';

import styles from './Menu.module.scss';

const cx = cn.bind(styles);

type Props = React.ComponentPropsWithRef<'li'> & {
  danger?: boolean;
  disabled?: boolean;
  className?: string;
  active?: boolean;
};

const MenuItem: React.FC<Props> = ({
  children,
  danger,
  disabled,
  className,
  active,
  ...restProps
}) => {
  const itemClassNames = cx('menuItem', { danger, disabled, active }, className);

  return (
    <li
      className={itemClassNames}
      {...restProps}
    >
      {children}
    </li>
  );
};

export { MenuItem };
