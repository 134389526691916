import React from 'react';

import { useAppTranslation } from '@hooks/useAppTranslation';

import MobileDeviceBig from '@assets/images/MobileDeviceBig.svg';
import MobileDeviceSmall from '@assets/images/MobileDeviceSmall.svg';

import styles from './MobilePlug.module.scss';

const MobilePlugView: React.FC = () => {
  const { t } = useAppTranslation();

  return (
    <div className={styles.loadingPageContainer}>
      <div className={styles.contentContainer}>
        <div className={styles.mobileBig}>
          <MobileDeviceBig />
        </div>
        <div className={styles.mobileSmall}>
          <MobileDeviceSmall />
        </div>
        <div className={styles.loadingText}>{t('NoMobileVersion')}</div>
      </div>
    </div>
  );
};

export { MobilePlugView };
