import React, { useMemo } from 'react';

import cn from 'classnames/bind';
import { AVATAR_COLORS_FILLED, AVATAR_COLORS_SUBTLE } from '@kit/components/Avatar/AvatarColors';

import { getNameAvatar, getRadixTransformation } from '@src/utils';
import { AvatarSize } from '@src/@types';

import styles from './Avatar.module.scss';

type AvatarStyle = {
  background: string;
  color: string;
};

type Props = {
  src?: string;
  alt?: string;
  className?: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  figure?: 'circle' | 'square';
  type?: 'filled' | 'subtle';
  userId?: string;
  size?: AvatarSize;
  onClick?: () => void;
};

const avatarSizesDictionary: Record<AvatarSize, string> = {
  md: styles.avatar_md,
  lg: styles.avatar_lg,
  xl: styles.avatar_xl,
};

const cx = cn.bind(styles);

const Avatar: React.FC<Props> = ({
  type = 'subtle',
  figure = 'circle',
  size = 'xl',
  src,
  alt = src,
  className,
  firstName,
  lastName,
  fullName,
  userId = 'a',
  onClick,
}) => {
  const classNameBySize = avatarSizesDictionary[size];

  const currentName: string = useMemo(() => {
    if (fullName) {
      const splitFullName = fullName.trim().split(' ');
      if (splitFullName.length > 1) {
        const firstName = splitFullName[0];
        const lastName = splitFullName[1];
        return getNameAvatar(firstName, lastName, size !== 'xl');
      } else {
        return fullName[0];
      }
    }
    if (firstName && lastName) {
      return getNameAvatar(firstName, lastName, size !== 'xl');
    }
    if (firstName && !lastName) {
      return firstName[0];
    }
    if (!firstName && lastName) {
      return lastName[0];
    }
    return '?';
  }, [firstName, lastName, size, fullName]);

  if (src) {
    return (
      <div className={cx('avatar', className, classNameBySize)}>
        <img
          className={cx('image', figure === 'circle' ? 'circle' : 'square')}
          src={src}
          alt={alt}
        />
      </div>
    );
  }

  return (
    <div
      className={cx(
        'avatar',
        'text',
        figure === 'circle' ? 'circle' : 'square',
        className,
        classNameBySize,
      )}
      data-testid="avatar"
      style={
        type === 'subtle'
          ? AVATAR_COLORS_SUBTLE[
              getRadixTransformation(userId.slice(-1), AVATAR_COLORS_SUBTLE.length)
            ]
          : AVATAR_COLORS_FILLED[
              getRadixTransformation(userId.slice(-1), AVATAR_COLORS_FILLED.length)
            ]
      }
    >
      {currentName.toUpperCase()}
    </div>
  );
};

export { Avatar };
export type { AvatarStyle };
