import { FC } from 'react';
import { FieldArrayWithId } from 'react-hook-form';

import { Tariff } from '@common/components/SettingsProject/api/models';
import { TableItem } from '@common/components/SettingsProject/contents/TariffAndPrices/components/TableItem';
import { FormDTO } from '@common/components/SettingsProject/contents/TariffAndPrices';

import { bindStyles } from '@src/utils';
import { useAppTranslation } from '@src/common';
import { Disclaimer, Icon } from '@src/kit';

import styles from './TariffItem.module.scss';

type Props = {
  tariff: Tariff;
  resources: FieldArrayWithId<FormDTO, 'resources', 'id'>[];
  isEdit?: boolean;
};

const cx = bindStyles(styles);

const TariffItem: FC<Props> = ({ tariff, resources, isEdit }) => {
  const { t } = useAppTranslation();

  const isOrganization = tariff.vdcName === 'Organization';

  return (
    <div className={cx('wrapper')}>
      <div className={cx('tariffBlock')}>
        <div className={cx('title')}>
          {isOrganization && (
            <div className={cx('icon')}>
              <Icon
                type="star-filled"
                size="lg"
              />
            </div>
          )}
          {isOrganization ? t(tariff.vdcName) : tariff.vdcName}
        </div>
        {isOrganization && <Disclaimer text={t('cos.settings.tariffprices.disclaimer')} />}
      </div>
      <div className={cx('resources')}>
        {resources.map((it, i) => (
          <TableItem
            key={it.id}
            i={i}
            resource={it}
            isEdit={isEdit}
          />
        ))}
      </div>
    </div>
  );
};

export { TariffItem };
