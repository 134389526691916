import { MultiValueProps } from 'react-select';

import cn from 'classnames/bind';
import { MultiValueBlock } from '@kit/components';
import { SelectOption } from 'src/kit/components/Select/Select';

import styles from './Select.module.scss';

type Props = MultiValueProps<unknown> & {
  getIconTemplate: (data: SelectOption<unknown>[]) => React.ReactNode;
};

const cx = cn.bind(styles);

const MultiValueWithIcons: React.FC<Props> = ({ index, getIconTemplate, ...props }) => {
  const { value = [] } = props.selectProps;
  const values = (value as SelectOption<unknown>[]).map(
    ({ label }: SelectOption<unknown>) => label || '-',
  );

  const isFirst = index === 0;

  return isFirst ? (
    <MultiValueBlock values={values}>
      <div className={cx('containerBlockIcon')}>
        {getIconTemplate(value as SelectOption<unknown>[])}
      </div>
    </MultiValueBlock>
  ) : null;
};

export { MultiValueWithIcons };
