import { FC, useCallback, useEffect, useMemo } from 'react';

import { useManageClouds } from '@common/components/AppToolbar/components/CloudConnect/ConnectedClouds/hooks/useManageClouds';
import { CloudCard } from '@common/components/AppToolbar/components/CloudConnect/ConnectedClouds/CloudCard';
import { useDeleteCloud } from '@common/components/AppToolbar/components/CloudConnect/ConnectedClouds/hooks/useDeleteCloud';
import { EmptyCloudsPlug } from '@common/components/Clouds/components/EmptyCloudsPlug';
import {
  ConnectedCloudsDataDTO,
  ConnectedCloudStatus,
} from '@common/components/AppToolbar/components/CloudConnect/types';
import { useCloudsContext } from '@common/providers/CloudsProvider';

import { bindStyles } from '@src/utils';
import { useAppTranslation } from '@src/common';

import styles from './ConnectedClouds.module.scss';

const cx = bindStyles(styles);

const EDITABLE_CLOUDS = ['Yandex', 'Azure', 'AWS', 'OpenStack', 'vCloudDirector', 'vSphere'];

const ConnectedClouds: FC = () => {
  const { t } = useAppTranslation();
  const { onChangeStatus, clouds, fetch } = useManageClouds();
  const { changeEditableCloud, setCloudEdit, openConnection } = useCloudsContext();

  const {
    loading,
    onDeleteCloud,
    deletableCloud,
    openDeleteModal,
    closeDeleteModal,
    SuspendDialog,
  } = useDeleteCloud({ refetchData: fetch });

  const onEdit = useCallback((cloud: ConnectedCloudsDataDTO) => {
    changeEditableCloud(cloud);
    setCloudEdit(true);
    openConnection();
  }, []);

  const changeStatus = async (id: string, status: ConnectedCloudStatus) => {
    await onChangeStatus(id, status);
    await fetch();
  };

  const renderedClouds = useMemo(() => {
    return clouds ? (
      clouds.map((cloud) => (
        <CloudCard
          key={cloud.id}
          cloud={cloud}
          onPause={(id) => changeStatus(id, 'STOPPED')}
          onStart={(id) => changeStatus(id, 'ACTIVE')}
          isUpdateActionButton={cloud.cloudType === 'vCloudDirector'}
          onDelete={openDeleteModal}
          onEdit={EDITABLE_CLOUDS.includes(cloud.cloudType) ? onEdit : undefined}
        />
      ))
    ) : (
      <EmptyCloudsPlug />
    );
  }, [clouds]);

  useEffect(() => {
    fetch();
  }, []);

  const actions = {
    cancel: {
      text: t('DialogCancel'),
    },
    submit: {
      text: t('Delete'),
      negative: true,
    },
  };

  return (
    <div className={cx('wrapper')}>
      {clouds?.length ? (
        <>
          <div className={cx('wrapper-content')}>
            {renderedClouds}
            <SuspendDialog
              loading={loading}
              title={t('CloudRemoval')}
              type="warning"
              actions={actions}
              onConfirm={onDeleteCloud}
              onCancel={closeDeleteModal}
            >
              <span>{t('CloudRemove', { name: deletableCloud?.name })}</span>
              <span className={styles.dateText}> {deletableCloud?.createdAt}</span>
            </SuspendDialog>
          </div>
        </>
      ) : (
        <EmptyCloudsPlug />
      )}
    </div>
  );
};

export { ConnectedClouds };
