import React, { useCallback, useMemo } from 'react';

import { Checkbox } from '@kit/components';

import { useCMTableContext } from '@src/common';

const HeadCheckbox: React.FC = () => {
  const { selection, data } = useCMTableContext();

  const checked = useMemo(() => {
    return selection?.selectedMultiRows.length === data.length;
  }, [selection?.selectedMultiRows, data]);

  const onChange = useCallback(() => {
    const _checked = !checked;
    selection?.onSelectAllRows?.(_checked ? data : []);
  }, [checked, selection?.onSelectAllRows]);

  return (
    <Checkbox
      size="md"
      checked={checked}
      onChange={onChange}
    />
  );
};

export { HeadCheckbox };
