import React, { createContext } from 'react';

type Props = {
  value: string;
};

const CurrentTabContext = createContext('');
const TabContext: React.FC<Props> = ({ value, children }) => {
  return (
    <CurrentTabContext.Provider value={value}>
      {children ? children : null}
    </CurrentTabContext.Provider>
  );
};

export { TabContext, CurrentTabContext };
