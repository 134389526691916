import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Form, FormInput, FormTextarea, required, uuidPattern } from '@common/components/Forms';
import {
  AzureEditDTO,
  AzureInfo,
  Stages,
} from '@common/components/AppToolbar/components/CloudConnect/types';
import {
  getAzureInfo,
  updateAzure,
  updateEnterpriseAzure,
} from '@common/components/AppToolbar/api/cloud-connections';
import { AxiosError } from 'axios';
import { BlockTitle } from '@common/components/AppToolbar/components/CloudConnect/BlockTitle';

import {
  useAnalytics,
  useAppTranslation,
  useCloudConnectContext,
  useFormApiError,
  useToast,
} from '@src/common';
import { bindStyles } from '@src/utils';
import { CLIENTID, CLOUD_NAME_DICTIONARY, SECRETKEY, TENANTID } from '@src/constants';
import { ColorPulsator } from '@src/kit';

import styles from './AzureEdit.module.scss';
import { CloudEditLoading } from '@common/components/AppToolbar/components/CloudConnect/CloudEdit/CloudEditLoading';

const cx = bindStyles(styles);

const AzureEdit: FC = () => {
  const { t } = useAppTranslation();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [isInitLoading, setIsInitLoading] = useState(true);
  const { apiErrors, handleError } = useFormApiError();
  const { logSuccessEvent, logErrorEvent } = useAnalytics();
  const [cloudData, setCloudData] = useState<AzureInfo>();

  const {
    editableCloud,
    selectStage,
    onChangeTitle,
    changeDisagreeButtonSettings,
    changeAgreeButtonSettings,
  } = useCloudConnectContext();

  const form = useForm<AzureEditDTO>({
    defaultValues: {
      cloudName: editableCloud?.name,
    },
  });

  const loadAzureInfo = async () => {
    if (editableCloud?.id) {
      try {
        setIsInitLoading(true);
        const res = await getAzureInfo(editableCloud.id);
        setCloudData(res.data);
      } catch (e) {
        toast.error({
          text: `${t(`FailedEditCloud`)}`,
          hasClose: true,
        });
      } finally {
        setIsInitLoading(false);
      }
    }
  };

  const onSubmit = useCallback(
    async (dto: AzureEditDTO) => {
      try {
        if (editableCloud?.id) {
          setIsLoading(true);
          if (cloudData && 'tenantId' in cloudData) {
            await updateAzure(editableCloud.id, {
              cloudName: dto.cloudName,
              clientSecret: dto.clientSecret,
              clientId: dto.clientId,
              tenantId: dto.tenantId,
            });
          } else {
            await updateEnterpriseAzure(editableCloud.id, {
              cloudName: dto.cloudName,
              enrolmentId: dto.enrolmentId,
              bearer: dto.bearer,
            });
          }
          selectStage({
            stage: Stages.connectedClouds,
          });
          toast.success({
            text: t('cos.connect.cloud.edit.success'),
          });
          logSuccessEvent('Edited cloud connection', {
            id: editableCloud.id,
            cloudType: CLOUD_NAME_DICTIONARY.Azure,
          });
        }
      } catch (error) {
        handleError(error as AxiosError);
        toast.error({
          text: `${t(`FailedEditCloud`)}`,
          hasClose: true,
        });
        logErrorEvent('Edited cloud connection', {
          id: editableCloud?.id,
          cloudType: CLOUD_NAME_DICTIONARY.Azure,
        });
      } finally {
        setIsLoading(false);
      }
    },
    [cloudData, editableCloud, t],
  );

  const onBack = useCallback(() => {
    selectStage({
      stage: Stages.connectedClouds,
    });
  }, []);

  useEffect(() => {
    onChangeTitle(t('cos.cloudConnect.edit.azure.title'));
    changeDisagreeButtonSettings({
      onClick: onBack,
    });
    loadAzureInfo();
  }, []);

  useEffect(() => {
    changeAgreeButtonSettings({
      onClick: form.handleSubmit(onSubmit),
      text: t('cos.cloudConnect.edit.save'),
      isLoading: isLoading || isInitLoading,
    });
  }, [isLoading, isInitLoading, onSubmit]);

  useEffect(() => {
    if (cloudData) {
      form.setValue('tenantId', cloudData.tenantId);
      form.setValue('cloudId', cloudData.cloudId);
      form.setValue('clientId', cloudData.clientId);
      form.setValue('cloudName', cloudData.cloudName);
      form.setValue('enrolmentId', cloudData.enrolmentId);
    }
  }, [cloudData]);

  const renderedContent = useMemo(() => {
    if (cloudData) {
      if ('tenantId' in cloudData) {
        return (
          <>
            <div className={cx('connectionBlock')}>
              <FormInput
                name="cloudName"
                rules={{ ...required('FieldRequired') }}
                caption={t('cloudName')}
                placeholder={t('createNameCloud')}
              />
            </div>
            <BlockTitle
              hintKey="AzureInstruction.creatApplication"
              titleKey="creatApplication"
            />
            <div className={cx('connectionBlock')}>
              <FormInput
                name={TENANTID}
                rules={{
                  ...required('FieldRequired'),
                  ...uuidPattern('InputFormatError'),
                }}
                caption={t('directoryID')}
                placeholder={t('tenantID')}
              />
            </div>
            <div className={cx('connectionBlock')}>
              <FormInput
                name={CLIENTID}
                rules={{
                  ...required('FieldRequired'),
                  ...uuidPattern('InputFormatError'),
                }}
                caption={t('applicationID')}
                placeholder={t('clientID')}
              />
            </div>
            <div className={cx('connectionBlock')}>
              <BlockTitle
                hintKey="AzureInstruction.createKey"
                titleKey="createKey"
              />
              <FormInput
                name={SECRETKEY}
                caption={t('secretKey')}
                placeholder={t('SecretValue')}
              />
            </div>
          </>
        );
      } else {
        return (
          <>
            <div className={cx('connectionBlock')}>
              <FormInput
                name="cloudName"
                rules={{ ...required('FieldRequired') }}
                caption={t('cloudName')}
                placeholder={t('createNameCloud')}
              />
            </div>
            <div className={cx('connectionBlock')}>
              <FormInput
                name="enrolmentId"
                caption={t('cos.enrolmentId.caption')}
                placeholder={t('cos.enrolmentId.placeholder')}
              />
            </div>
            <div className={cx('connectionBlock')}>
              <FormTextarea
                name="bearer"
                caption={t('cos.bearer.caption')}
                placeholder={t('cos.bearer.placeholder')}
              />
            </div>
          </>
        );
      }
    }
  }, [cloudData]);

  if (isInitLoading && !cloudData) {
    return <CloudEditLoading />;
  }

  return (
    <Form
      formMethods={form}
      apiErrors={apiErrors}
    >
      {renderedContent}
    </Form>
  );
};

export { AzureEdit };
