import React from 'react';

import cn from 'classnames/bind';

import styles from './ColorPulsator.module.scss';

const ColorPulsator: React.FC = () => {
  const cx = cn.bind(styles);

  return <div className={cx('colorPulsator')} />;
};

export { ColorPulsator };
