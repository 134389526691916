import React, { lazy } from 'react';

import { CloudToaster, useMobilePlug } from '@cm/ui-common';

const Layout = lazy(() => import('@src/components/Layout/Layout'));

const App: React.FC = () => {
  const mobilePlug = useMobilePlug();

  return (
    <>
      <CloudToaster containerClassName="app-toast" />
      <Layout />
      {mobilePlug}
    </>
  );
};

export { App };
