import React from 'react';
import { MultiValueProps } from 'react-select';

import { MultiValueBlock } from '@kit/components/MultiValueBlock';
import { SelectOption } from '@kit/components/Select/Select';

type Props = MultiValueProps<unknown>;

const MultiValue: React.FC<Props> = ({ index, ...props }) => {
  const { value = [] } = props.selectProps;
  const values = (value as SelectOption<unknown>[]).map(
    ({ label }: SelectOption<unknown>) => label || '-',
  );

  const isFirst = index === 0;

  return isFirst ? <MultiValueBlock values={values} /> : null;
};

export { MultiValue };
