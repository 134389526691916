import React from 'react';

import { ButtonViewProps } from '@kit/components/Button/Button.view';
import { Dialog } from '@kit/components/Dialog';
import { Modal } from '@kit/components/Modal';

import { PopupControls, usePopupControls } from '@hooks/usePopupControls';

type DialogTypes = 'answer' | 'notification' | 'warning' | 'info';
type ActionType = React.ButtonHTMLAttributes<HTMLButtonElement> & ButtonViewProps;

type ActionsTypes = {
  ok?: ActionType;
  cancel?: ActionType;
  submit?: ActionType;
};

type DialogProps = {
  loading?: boolean;
  classNamePrefix?: string;
  className?: string;
  title?: string;
  type?: DialogTypes;
  onConfirm?: () => void;
  onCancel?: () => void;
  onClose?: () => void;
  onOk?: () => void;
  actions?: ActionsTypes;
  closeOnDocumentClick?: boolean;
  closeOnEscape?: boolean;
};

type ReturnValue = PopupControls & {
  Dialog: React.FC<DialogProps>;
};

const useDialog = (): ReturnValue => {
  const { isOpened, openPopup, closePopup, togglePopup } = usePopupControls();

  const Wrapper = (props: DialogProps) => {
    const onClose = () => {
      props.onClose?.();
      closePopup();
    };

    return (
      <Modal
        classNamePrefix={props.classNamePrefix}
        open={isOpened}
        onClose={onClose}
        closeOnEscape={!props.loading || props.closeOnEscape}
        closeOnDocumentClick={!props.loading || props.closeOnDocumentClick}
      >
        <Dialog
          closeHandler={onClose}
          {...props}
        />
      </Modal>
    );
  };

  return {
    isOpened,
    openPopup,
    closePopup,
    togglePopup,
    Dialog: Wrapper,
  };
};

export { useDialog };
export type { ActionsTypes, DialogTypes, DialogProps };
