import React from 'react';

type Props = {
  condition?: boolean;
  children?: React.ReactNode | null | undefined;
};

const DashValue: React.FC<Props> = ({ children, condition }) => {
  if (typeof condition === 'boolean') {
    return <>{condition ? children : '-'}</>;
  }

  return <>{children || '–'}</>;
};

export { DashValue };
