import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { AxiosError } from 'axios';
import {
  VCloudDirectorDTOFormNew,
  VCloudDirectorFetchProfilesDTO,
} from '@common/components/AppToolbar/components/CloudConnect/types';
import {
  checkCredentialVCloud,
  connectNewVcloud,
  editNewVCloudInfo,
  getNewVCloudInfo,
} from '@common/components/AppToolbar/api/cloud-connections';
import { useNewConnectionContext } from '@common/components/Clouds/components/NewConnection/NewConnectionContext';
import { CloudInfoVariant } from '@common/components/Clouds/components/NewConnection/CloudInfo';
import { ConnectionSteps } from '@common/components/Clouds/components';
import {
  StatusInfo,
  StatusInfoVariant,
} from '@common/components/Clouds/components/NewConnection/StatusInfo';
import { ResourcesStep } from '@common/components/Clouds/components/NewConnection/Forms/VCloudDirectorConnection/ResourcesStep';
import { useCloudsContext } from '@common/providers/CloudsProvider';
import { CloudEditLoading } from '@common/components/AppToolbar/components/CloudConnect/CloudEdit/CloudEditLoading';

import {
  Form,
  useAnalytics,
  useFormApiError,
  useToast,
  useTranslationPrefix,
  VCloudEditNewDTO,
} from '@src/common';
import { bindStyles } from '@src/utils';
import { Button, IFetchBarStatus } from '@src/kit';
import { CloudTypes } from '@src/@types';
import { CLOUD_NAME_DICTIONARY } from '@src/constants';

import { ConnectionStep } from './ConnectionStep';
import styles from './VCloudDirectorConnection.module.scss';

const cx = bindStyles(styles);

const VCloudDirectorConnection: FC = () => {
  const {
    onBack,
    changeCloudInfoSettings,
    currentStep,
    onNext,
    statusInfoSettings,
    selectedProvider,
    changeStatusInfoSettings,
  } = useNewConnectionContext();
  const { t } = useTranslationPrefix('cos.cloud.connect.cloudDirector.');
  const toast = useToast();
  const { cloudEdit, editableCloud } = useCloudsContext();
  const [initLoading, setInitLoading] = useState(true);
  const [statusFetch, setStatusFetch] = useState<IFetchBarStatus>();
  const { logSuccessEvent, logErrorEvent } = useAnalytics();
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const form = useForm<VCloudDirectorDTOFormNew>({
    mode: 'onBlur',
  });
  const [cloudId, setCloudId] = useState<string | null>(null);

  const { apiErrors, handleError } = useFormApiError();

  const createNewConnect = useCallback(async (dto: VCloudDirectorDTOFormNew) => {
    try {
      setIsSubmitLoading(true);
      changeCloudInfoSettings({
        variant: CloudInfoVariant.LOADING,
        cloudName: dto.name,
      });

      const res = await connectNewVcloud(dto);
      setCloudId(res.data.payload.data as string);
      changeCloudInfoSettings({
        variant: CloudInfoVariant.SUCCESS,
        cloudName: dto.name,
      });
      changeStatusInfoSettings({
        variant: StatusInfoVariant.VCLOUD_RESOURCES,
      });
      logSuccessEvent('Created cloud connection', {
        cloudType: CLOUD_NAME_DICTIONARY.vCloudDirector,
      });
    } catch (e) {
      changeStatusInfoSettings({
        variant: StatusInfoVariant.ERROR,
        cloudName: dto.name,
      });
      changeCloudInfoSettings({
        variant: CloudInfoVariant.INFO,
        cloudName: dto.name,
      });
      handleError(e as AxiosError);
      logErrorEvent('Created cloud connection', {
        cloudType: CLOUD_NAME_DICTIONARY.vCloudDirector,
      });
    } finally {
      setIsSubmitLoading(false);
    }
  }, []);

  const checkCredential = async (firstFormValues: VCloudDirectorDTOFormNew) => {
    try {
      setStatusFetch('pending');
      const dtoCheck: VCloudDirectorFetchProfilesDTO = {
        password: firstFormValues.password,
        vcloudUrl: firstFormValues.vcloudUrl,
        tenantAlias: firstFormValues.tenantAlias,
        username: firstFormValues.username,
      };

      const dtoConnect: VCloudDirectorDTOFormNew = {
        name: firstFormValues.name,
        password: firstFormValues.password,
        vcloudUrl: firstFormValues.vcloudUrl,
        tenantAlias: firstFormValues.tenantAlias,
        username: firstFormValues.username,
      };

      await checkCredentialVCloud(dtoCheck);
      onNext();
      await createNewConnect(dtoConnect);
      await setStatusFetch('resolved');
    } catch (e) {
      handleError(e as AxiosError);
      setStatusFetch('rejected');
    }
  };

  const onEditSubmit = useCallback(
    async (dto: VCloudDirectorDTOFormNew) => {
      try {
        setIsSubmitLoading(true);
        setCloudId(editableCloud?.id as string);
        changeCloudInfoSettings({
          variant: CloudInfoVariant.LOADING,
          cloudName: dto.name,
        });
        let data = {} as VCloudEditNewDTO;

        if (editableCloud?.id) {
          data = {
            cloudId: editableCloud.id,
            vcloudUrl: dto.vcloudUrl,
            username: dto.username,
            name: dto.name,
            tenantAlias: dto.tenantAlias,
          };

          if (dto.password) {
            data.password = dto.password;
          }
        }
        await editNewVCloudInfo(data);

        toast.success({ text: t('cos.connect.cloud.edit.success') });
        changeCloudInfoSettings({
          variant: CloudInfoVariant.SUCCESS,
          cloudName: dto.name,
        });
        changeStatusInfoSettings({
          variant: StatusInfoVariant.VCLOUD_RESOURCES,
        });
        logSuccessEvent('Edited cloud connection', {
          id: editableCloud?.id,
          cloudType: CLOUD_NAME_DICTIONARY.vCloudDirector,
        });
      } catch (e) {
        changeStatusInfoSettings({
          variant: StatusInfoVariant.ERROR,
          cloudName: dto.name,
        });
        changeCloudInfoSettings({
          variant: CloudInfoVariant.INFO,
          cloudName: dto.name,
        });
        handleError(e as AxiosError);
        logErrorEvent('Edited cloud connection', {
          id: editableCloud?.id,
          cloudType: CLOUD_NAME_DICTIONARY.vCloudDirector,
        });
      } finally {
        setIsSubmitLoading(false);
      }
    },
    [editableCloud],
  );

  const values = form.watch();

  const isDirty = useMemo(() => {
    const { username, password, vcloudUrl, tenantAlias, name } = values;
    return !username || !password || !vcloudUrl || !tenantAlias || !name;
  }, [values]);

  const onSubmit = useCallback(() => {
    if (currentStep === ConnectionSteps.SETTINGS) {
      if (cloudEdit) {
        onNext();
        form.handleSubmit(onEditSubmit)();
      } else {
        form.handleSubmit(checkCredential)();
      }
    }
  }, [currentStep, cloudEdit]);

  const buttonLoading = () => {
    if (cloudEdit) {
      return isSubmitLoading || initLoading;
    } else {
      return isSubmitLoading;
    }
  };

  const fetchVCloudInfo = useCallback(async () => {
    if (editableCloud?.id) {
      try {
        setInitLoading(true);
        const response = await getNewVCloudInfo(editableCloud.id);
        form.setValue('name', response.data.name);
        form.setValue('vcloudUrl', response.data.vcloudUrl);
        form.setValue('username', response.data.username);
        form.setValue('tenantAlias', response.data.tenantAlias);
      } catch (e) {
        handleError(e as AxiosError);
      } finally {
        setInitLoading(false);
      }
    }
  }, [editableCloud?.id]);

  useEffect(() => {
    if (cloudEdit) {
      fetchVCloudInfo();
    }
  }, [cloudEdit]);

  return (
    <Form<VCloudDirectorDTOFormNew>
      formMethods={form}
      onSubmit={onSubmit}
      apiErrors={apiErrors}
      className={cx('form')}
    >
      {currentStep === ConnectionSteps.STATUS ? (
        <StatusInfo
          cloudType={selectedProvider as CloudTypes}
          variant={statusInfoSettings.variant}
          cloudName={statusInfoSettings.cloudName}
        >
          <ResourcesStep cloudId={(cloudId as string) || ''} />
        </StatusInfo>
      ) : (
        <>
          {initLoading && cloudEdit ? (
            <CloudEditLoading />
          ) : (
            <div className={cx('inputs')}>
              <ConnectionStep status={statusFetch} />
            </div>
          )}
          <div className={cx('buttons')}>
            <Button
              text={t('clouds.connection.button.back')}
              variant="outline"
              onClick={onBack}
            />
            <Button
              text={
                cloudEdit ? t('cos.cloudConnect.edit.save') : t('clouds.connection.button.connect')
              }
              type="submit"
              isLoading={buttonLoading()}
              disabled={!cloudEdit && isDirty}
            />
          </div>
        </>
      )}
    </Form>
  );
};

export { VCloudDirectorConnection };
