import { FC } from 'react';

import { CloudConnectContent } from '@common/components/AppToolbar/components/CloudConnect/CloudConnectContent';

import { FormInput, required, urlPattern, useAppTranslation } from '@src/common';
import { DOMAIN, KEYSTONE_AUTH_URL, NAME, PASSWORD_FIELD, USERNAME } from '@src/constants';
import { bindStyles } from '@src/utils';
import { Disclaimer } from '@src/kit';

import styles from '../FormOpenStack.module.scss';

const cx = bindStyles(styles);

const ConnectionStep: FC = () => {
  const { t } = useAppTranslation();

  return (
    <CloudConnectContent>
      <div className={cx('info')}>
        <Disclaimer text={t('cos.cloud.connect.openstack.disclaimer')} />
      </div>
      <div className={cx('blockContent')}>
        <FormInput
          placeholder={t('OpenStackNamePC')}
          name={NAME}
          rules={{ ...required('FieldRequired') }}
          caption={t('OpenStackName')}
          hint={t('OpenStackNameHint')}
        />
      </div>
      <div className={cx('blockContent')}>
        <FormInput
          placeholder={t('OpenStackURLPC')}
          name={KEYSTONE_AUTH_URL}
          rules={{
            ...required('FieldRequired'),
            ...urlPattern('InputFormatError'),
          }}
          caption={t('OpenStackURL')}
          hint={t('OpenStackURLHint')}
        />
      </div>
      <div className={cx('blockContent')}>
        <FormInput
          placeholder={t('OpenStackDomainPC')}
          name={DOMAIN}
          caption={t('OpenStackDomain')}
        />
      </div>
      <div className={cx('blockContentLargeIndent')}>
        <div className={cx('blockContainer')}>
          <FormInput
            hint={t('OpenStackUserNameHint')}
            placeholder={t('OpenStackUserNamePC')}
            name={USERNAME}
            rules={{ ...required('FieldRequired') }}
            caption={t('OpenStackUserName')}
          />
          <FormInput
            type="password"
            hint={t('OpenStackUserPassHint')}
            placeholder={t('OpenStackUserPassPC')}
            name={PASSWORD_FIELD}
            rules={{ ...required('FieldRequired') }}
            caption={t('OpenStackUserPass')}
            autoComplete="new-password"
          />
        </div>
      </div>
    </CloudConnectContent>
  );
};

export { ConnectionStep };
