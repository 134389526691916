import { PropsWithChildren } from 'react';

import { CMTableContext } from '@common/components/CMTable/context/CMTable.context';
import { UseCMTableReturn } from '@common/components/CMTable/hooks/useCMTable';

type Props<D> = {
  value: UseCMTableReturn<D>;
};

const CMTableProvider = <D,>({ value, children }: PropsWithChildren<Props<D>>) => (
  <CMTableContext.Provider value={value}>{children}</CMTableContext.Provider>
);
export { CMTableProvider };
