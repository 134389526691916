import { ProviderItemType } from '@common/components/AppToolbar/components/CloudConnect/types';

import { CloudTypes } from '@src/@types';

const PROVIDER_LIST: ProviderItemType[] = [
  {
    provider: 'vCloudDirector',
    title: 'cos.cloud.connect.cloudDirector.title',
    version: 'cos.cloud.connect.cloudDirector.version',
  },
  {
    provider: 'vSphere',
    title: 'ConnectVSphere',
    version: 'ConnectVSphere.version',
  },
  {
    provider: 'OpenStack',
    title: 'ConnectionOpenStack',
    version: 'ConnectionOpenStack.version',
  },
  {
    provider: 'Yandex',
    title: 'cos.yandexConnectCloud.form.title',
  },
  { provider: 'AWS', title: 'AWSTitle' },
  { provider: 'Azure', title: 'ConnectAzure' },
  {
    provider: 'HyperV',
    title: 'ConnectionHyperV',
  },
];

const TEXT_DICTIONARY: Partial<Record<CloudTypes, string>> = {
  Azure: 'ModalConnectTextAzure',
  vSphere: 'ModalConnectTextVSphere',
  AWS: 'ModalConnectTextAWS',
  HyperV: 'ModalConnectTextHyperV',
};

export { PROVIDER_LIST, TEXT_DICTIONARY };
