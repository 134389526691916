import React from 'react';

import cn from 'classnames/bind';
import { Loader } from '@kit/components/Loader';

import { ButtonIconPosition, ButtonSize, ButtonType } from '@src/@types';

import styles from './Button.module.scss';

type Props = {
  count?: number | undefined;
  text?: string;
  variant?: ButtonType;
  size?: ButtonSize;
  full?: boolean;
  isLoading?: boolean;
  loadProgress?: number;
  iconPosition?: ButtonIconPosition;
  active?: boolean;
  textClassName?: string;
  negative?: boolean;
  isNegativeIcon?: boolean;
};

const ButtonView: React.FC<Props> = ({
  variant = 'filled',
  size = 'lg',
  iconPosition = 'left',
  isNegativeIcon,
  text,
  active,
  isLoading,
  loadProgress,
  textClassName,
  count,
  children,
}) => {
  const cx = cn.bind(styles);

  const iconClasses = cx(
    [`${iconPosition}Icon`],
    {
      noIcon: !children,
    },
    isNegativeIcon ? 'negativeIcon' : 'icon',
  );

  const classes = cx('wrapper', size, variant, {
    active,
    isLoading,
    iconOnly: !text,
  });

  const icon = <div className={iconClasses}>{children}</div>;
  return (
    <div className={classes}>
      <div className={cx('body')}>
        {isLoading && (
          <div className={cx('loadingOverlay')}>
            {loadProgress ? (
              <div
                className={cx('progressBar')}
                data-testid="progressBar"
              >
                <div
                  className={cx('track')}
                  style={{ width: `${loadProgress}%` }}
                />
              </div>
            ) : (
              <div className={cx('spinner')}>
                <Loader
                  size="md"
                  className={cx('icon')}
                  data-testid="loader"
                />
              </div>
            )}
          </div>
        )}
        <div className={cx('content', textClassName)}>
          {iconPosition === 'left' && children && (
            <div className={cx('leftImage', !text && 'onlyImg')}>{icon}</div>
          )}
          {text && <div className={cx('caption')}>{text}</div>}
          {iconPosition === 'right' && children && (
            <div className={cx('rightImage', !text && 'onlyImg')}>{icon}</div>
          )}
          {typeof count !== 'undefined' && <div className={cx('count')}>{count}</div>}
        </div>
      </div>
    </div>
  );
};

export { ButtonView };
export type { Props as ButtonViewProps };
