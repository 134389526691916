import { FC } from 'react';

import { IFetchBarStatus, StatusFetchBar } from '@kit/components/StatusFetchBar/StatusFetchBar';
import { SECRET_FIELD_EDIT_PLACEHOLDER } from '@common/components/Clouds/components';
import { useCloudsContext } from '@common/providers/CloudsProvider';

import { FormInput, required, urlPattern, useTranslationPrefix } from '@src/common';
import { Disclaimer } from '@src/kit';
import { bindStyles } from '@src/utils';

import styles from '../VCloudDirectorConnection.module.scss';

const cx = bindStyles(styles);

type IConnectStepProps = {
  status?: IFetchBarStatus;
};

const ConnectionStep: FC<IConnectStepProps> = ({ status }) => {
  const { cloudEdit } = useCloudsContext();
  const { tPrefix } = useTranslationPrefix('cos.cloud.connect.cloudDirector.');

  return (
    <>
      <div>
        <div className={cx('connectionBlock')}>
          <div className={cx('info')}>
            <Disclaimer text={tPrefix('disclaimer')} />
          </div>
          <div className={cx('blockContent')}>
            <FormInput
              name="name"
              rules={{ ...required('FieldRequired') }}
              caption={tPrefix('name.caption')}
              placeholder={tPrefix('name.placeholder')}
              hint={tPrefix('name.hint')}
            />
            <FormInput
              name="vcloudUrl"
              caption={tPrefix('vcloudUrl.caption')}
              rules={{ ...required('FieldRequired'), ...urlPattern('InputFormatError') }}
              placeholder={tPrefix('vcloudUrl.placeholder')}
              hint={tPrefix('vcloudUrl.hint')}
            />
          </div>
        </div>
        <div className={cx('connectionBlock')}>
          <FormInput
            name="tenantAlias"
            caption={tPrefix('tenantAlias.caption')}
            rules={{ ...required('FieldRequired') }}
            placeholder={tPrefix('tenantAlias.placeholder')}
            hint={tPrefix('tenantAlias.hint')}
          />
        </div>
        <div className={cx('connectionBlock')}>
          <div className={cx('blockContent')}>
            <FormInput
              name="username"
              caption={tPrefix('username.caption')}
              rules={{ ...required('FieldRequired') }}
              placeholder={tPrefix('username.placeholder')}
              hint={tPrefix('username.hint')}
            />
            <FormInput
              name="password"
              type="password"
              caption={tPrefix('password.caption')}
              placeholder={
                cloudEdit ? SECRET_FIELD_EDIT_PLACEHOLDER : tPrefix('password.placeholder')
              }
              autoComplete="new-password"
              hint={tPrefix('password.hint')}
            />
          </div>
        </div>
        {status && (
          <StatusFetchBar
            status={status}
            text={tPrefix(`fetchStatus.${status}`)}
          />
        )}
      </div>
    </>
  );
};

export { ConnectionStep };
