import { SyntheticEvent, useCallback, useMemo } from 'react';

import { Checkbox } from '@kit/components';

import { useCMTableContext, UseCMTableSelectionReturn } from '@src/common';

type Props<D> = {
  record: D;
};

const CheckboxCell = <D,>({ record }: Props<D>) => {
  const { selection } = useCMTableContext();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { selectedMultiRows, rowKey, multiSelection } = selection as UseCMTableSelectionReturn<any>;

  const checked = useMemo(() => {
    const isEqual = (value1: D, value2: D) => rowKey?.(value1) === rowKey?.(value2);

    return selectedMultiRows.some((item) => isEqual(item, record));
  }, [selectedMultiRows, rowKey, record]);

  const onChange = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();
      multiSelection(record);
    },
    [multiSelection, record],
  );

  return (
    <div onClick={onChange}>
      <Checkbox
        size="md"
        checked={checked}
      />
    </div>
  );
};

export { CheckboxCell };
