import React, { ChangeEvent, useMemo } from 'react';

import { Icon, Input } from '@src/kit';

type Props = {
  placeholder?: string;
  className?: string;
  value: string;
  onChange: (value: string) => void;
  onSearch: (reset?: boolean) => void;
};

export const SearchFilterField: React.FC<Props> = ({
  className,
  placeholder,
  onSearch,
  value,
  onChange,
}) => {
  const onResetSearch = () => {
    onSearch(true);
  };

  const onSubmitSearch = (event: React.SyntheticEvent) => {
    event.preventDefault();
    onSearch();
  };

  const actions = useMemo(() => [{ icon: <Icon type="search" />, alignLeft: true }], []);

  const change = (event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value);

  return (
    <form
      className={className}
      onSubmit={onSubmitSearch}
    >
      <Input
        value={value}
        placeholder={placeholder}
        actions={actions}
        onChange={change}
        onClear={onResetSearch}
      />
    </form>
  );
};
