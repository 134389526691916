import { FC, useMemo } from 'react';

import { Button, Icon, Select, SelectOption } from '@src/kit';
import { bindStyles, mapToOption } from '@src/utils';
import { FormInput, useCurrencyContext, useTranslationPrefix } from '@src/common';

import styles from './CustomCurrencyRow.module.scss';

type Props = {
  position: number;
  onDelete: () => void;
};

const cx = bindStyles(styles);

const CustomCurrencyRow: FC<Props> = ({ position, onDelete }) => {
  const { form, currenciesList } = useCurrencyContext();
  const { tPrefix } = useTranslationPrefix('cos.currencyModal.');

  const currencies = form.watch('currencies');

  const sourceName = `currencies.${position}.source` as `currencies.${number}.source`;
  const receiverName = `currencies.${position}.receiver` as `currencies.${number}.receiver`;
  const rateName = `currencies.${position}.rate` as `currencies.${number}.rate`;

  const source = form.watch(sourceName);
  const receiver = form.watch(receiverName);
  const rate = form.watch(rateName);

  const handleSourceOnChange = (value: SelectOption<string>) => {
    form.setValue(sourceName, value.value);
  };

  const handleReceiverOnChange = (value: SelectOption<string>) => {
    form.setValue(receiverName, value.value);
  };

  const values = form.getValues();

  const notAvailableReceivers = useMemo(() => {
    const pairs = currencies.map((it) => [it.source, it.receiver]);
    const all = [...currencies.map((it) => it.source), ...currencies.map((it) => it.receiver)];
    const res = all.filter((it) => all.indexOf(it) !== all.lastIndexOf(it));

    pairs.forEach((it, index) => {
      if (index !== position) {
        const sourceIndex = it.indexOf(source);
        if (sourceIndex !== -1) {
          res.push(it[sourceIndex ? 0 : 1]);
        }
      }
    });

    return res;
  }, [values, receiver, source]);

  const notAvailableSources = useMemo(() => {
    const pairs = currencies.map((it) => [it.source, it.receiver]);
    const all = [...currencies.map((it) => it.source), ...currencies.map((it) => it.receiver)];
    const res = all.filter((it) => all.indexOf(it) !== all.lastIndexOf(it));

    pairs.forEach((it, index) => {
      if (index !== position) {
        const receiverIndex = it.indexOf(receiver);

        if (receiverIndex !== -1) {
          res.push(it[receiverIndex ? 0 : 1]);
        }
      }
    });

    return res;
  }, [values, source, receiver]);

  const sourceOptions = useMemo(() => {
    return currenciesList
      .filter(
        (it) =>
          it.code !== source && it.code !== receiver && !notAvailableSources.includes(it.code),
      )
      .map((it) => mapToOption(it.code, it.code));
  }, [currenciesList, source, receiver, notAvailableSources]);

  const receiverOptions = useMemo(() => {
    return currenciesList
      .filter(
        (it) =>
          it.code !== source && it.code !== receiver && !notAvailableReceivers.includes(it.code),
      )
      .map((it) => mapToOption(it.code, it.code));
  }, [currenciesList, source, receiver, notAvailableReceivers]);

  return (
    <div className={cx('currencyBlock')}>
      <div className={cx('count')}>1</div>

      <Select
        noOptionsText={tPrefix('noOptions')}
        closeMenuOnScroll
        name={sourceName}
        value={mapToOption(source, source)}
        options={sourceOptions}
        onChange={handleSourceOnChange}
        isSearchable={false}
        size="md"
        className={styles.select2}
        withoutBorder
        classNamePrefix="selectSource"
      />

      <div className={cx('equals')}>=</div>
      <div className={cx('inputSelect')}>
        <FormInput
          type="number"
          name={rateName}
          className={cx('inputWithSelect')}
          size="md"
          defaultValue={rate}
          decimals={5}
        />
        <Select
          noOptionsText={tPrefix('noOptions')}
          closeMenuOnScroll
          name={receiverName}
          value={mapToOption(receiver, receiver)}
          options={receiverOptions}
          onChange={handleReceiverOnChange}
          isSearchable={false}
          size="md"
          className={styles.select2}
          withoutBorder
          classNamePrefix="select"
        />
      </div>
      <Button
        size="sm"
        variant="text"
        onClick={onDelete}
      >
        <div className={cx('iconContainer')}>
          <Icon
            type="close"
            size="sm"
          />
        </div>
      </Button>
    </div>
  );
};

export { CustomCurrencyRow };
