import { FC, useEffect, useMemo, useState } from 'react';

import { getVCloudResources, VDCResourcesDTO } from '@common/components/Clouds/api/vCloudDirector';
import { EmptyPlug } from '@common/components/Clouds/components/NewConnection/Forms/VCloudDirectorConnection/EmptyPlug';

import {
  CMTable,
  CmTableColumn,
  CMTableProvider,
  useAppTranslation,
  useCMTable,
  useToast,
} from '@src/common';
import { bindStyles } from '@src/utils';
import { CLOUD_ICON_DICTIONARY } from '@src/constants';
import { Plug } from '@src/kit';

import styles from './ResourcesStep.module.scss';

const cx = bindStyles(styles);

const HEAD_KEY = 'clouds.connection.vdc.head.name';

type Props = {
  cloudId: string;
};

const ResourcesStep: FC<Props> = ({ cloudId }) => {
  const { t } = useAppTranslation();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const cmColumns: CmTableColumn<VDCResourcesDTO>[] = useMemo(
    () => [
      {
        key: 'vdcName',
        title: t('clouds.connection.vdc.table.name'),
        width: 340,
        fixed: false,
        render: (value) => {
          if (value === HEAD_KEY) {
            return t(HEAD_KEY);
          } else {
            return (
              <div className={cx('tableName')}>
                <div className={cx('tableIcon')}>{CLOUD_ICON_DICTIONARY['vCloudDirector']}</div>
                <div className={cx('tableText')}>{value}</div>
              </div>
            );
          }
        },
      },
      {
        key: 'cpu',
        title: (
          <div>
            {t('CPU')}, <span className={cx('subText')}>{t('cos.limits.countType.vCPU')}</span>
          </div>
        ),
        width: 236,
        align: 'center',
        fixed: false,
        render: (value, data) => {
          if (data.vdcName === HEAD_KEY) {
            return <div>{t('clouds.connection.vdc.resources.table.subHeader.text')}</div>;
          } else {
            return (
              <div>
                {data.cpu.allocatedQuantity} / {data.cpu.usedQuantity}
              </div>
            );
          }
        },
      },
      {
        key: 'ram',
        title: (
          <div>
            {t('RAM')}, <span className={cx('subText')}>{t('UnitGb')}</span>
          </div>
        ),
        width: 236,
        fixed: false,
        align: 'center',
        render: (value, data) => {
          if (data.vdcName === HEAD_KEY) {
            return <div>{t('clouds.connection.vdc.resources.table.subHeader.text')}</div>;
          } else {
            return (
              <div>
                {data.ram.allocatedQuantity} / {data.ram.usedQuantity}
              </div>
            );
          }
        },
      },
      {
        key: 'storageProfile',
        title: (
          <div>
            {t('storageProfile')}, <span className={cx('subText')}>{t('UnitGb')}</span>
          </div>
        ),
        width: 236,
        align: 'center',
        fixed: false,
        render: (value, data) => {
          if (data.vdcName === HEAD_KEY) {
            return <div>{t('clouds.connection.vdc.resources.table.subHeader.text')}</div>;
          } else {
            return (
              <div>
                {data.storageProfile.allocatedQuantity} / {data.storageProfile.usedQuantity}
              </div>
            );
          }
        },
      },
    ],
    [t],
  );

  const fetchTableData = async () => {
    try {
      setIsLoading(true);
      const res = await getVCloudResources(cloudId);

      const newData = [
        {
          vdcName: HEAD_KEY,
          cpu: { allocatedQuantity: 0, usedQuantity: 0 },
          ram: { allocatedQuantity: 0, usedQuantity: 0 },
          storageProfile: { allocatedQuantity: 0, usedQuantity: 0 },
        },
        ...res.data,
      ];

      setIsLoading(false);
      return {
        result: newData,
        totalItems: res.data.length,
      };
    } catch (e) {
      toast.error({ text: 'Error' });
      setIsLoading(false);
      return {
        result: [
          {
            vdcName: HEAD_KEY,
            cpu: { allocatedQuantity: 0, usedQuantity: 0 },
            ram: { allocatedQuantity: 0, usedQuantity: 0 },
            storageProfile: { allocatedQuantity: 0, usedQuantity: 0 },
          },
        ],
        totalItems: 1,
      };
    }
  };

  const table = useCMTable<VDCResourcesDTO>(fetchTableData);

  const rowKey = (data: VDCResourcesDTO) =>
    data.vdcName +
    data.cpu.allocatedQuantity +
    data.cpu.usedQuantity +
    data.ram.usedQuantity +
    data.ram.allocatedQuantity +
    data.storageProfile.allocatedQuantity +
    data.storageProfile.usedQuantity;

  useEffect(() => {
    table.fetchData();
  }, []);

  const renderedData = useMemo(() => {
    if (isLoading) {
      return (
        <Plug
          className={cx('plug-loading')}
          imageType="rocket"
          text={t('Plug.widget.loading.text')}
        />
      );
    } else if (table.data.length === 1) {
      return <EmptyPlug />;
    } else {
      return (
        <>
          <div className={cx('title')}>{t('clouds.connection.vdc.resources.title')}</div>
          <div className={cx('table-wrapper')}>
            <CMTable
              columns={cmColumns}
              rowKey={rowKey}
            />
          </div>
        </>
      );
    }
  }, [cmColumns, table.data.length, t, isLoading]);

  return (
    <div className={cx('wrapper')}>
      <CMTableProvider value={table}>{renderedData}</CMTableProvider>
    </div>
  );
};

export { ResourcesStep };
