import { MultiValueProps, OptionProps, SingleValueProps } from 'react-select';
import React from 'react';

import {
  CheckboxOptionsWithIcon,
  MultiValueWithIcons,
  NoInputSearchMenuList,
  OptionWithIcon,
  SearchMenuList,
  SelectOption,
  SingleValueWithIcon,
} from '@src/kit';
import { FormSelect, FormSelectProps } from '@src/common';
import { bindStyles } from '@src/utils';
import { CLOUD_ICON_DICTIONARY } from '@src/constants';
import { CloudTypes } from '@src/@types';

import styles from './SelectTemplates.module.scss';

type Props<T> = FormSelectProps<T>;

const cx = bindStyles(styles);

const MultiOptions = ({ children, ...props }: OptionProps) => (
  <CheckboxOptionsWithIcon
    {...props}
    tooltip
    getIconTemplate={(data) => (
      <div className={cx('checkbox-icon')}>{CLOUD_ICON_DICTIONARY[data.meta as CloudTypes]}</div>
    )}
    isDisabled={props.isDisabled}
  >
    {children}
  </CheckboxOptionsWithIcon>
);

const singleOptions = ({ children, ...props }: OptionProps) => (
  <OptionWithIcon
    {...props}
    tooltip
    isShortened
    getIconTemplate={(data) => (
      <div className={cx('checkbox-icon')}>{CLOUD_ICON_DICTIONARY[data.meta as CloudTypes]}</div>
    )}
  >
    {children}
  </OptionWithIcon>
);

const singleValue = ({ children, ...props }: SingleValueProps) => (
  <SingleValueWithIcon
    {...props}
    getIconTemplate={(data) => CLOUD_ICON_DICTIONARY[data.meta as CloudTypes]}
  >
    {children}
  </SingleValueWithIcon>
);

const CloudSelect = React.memo(<T,>(props: Props<T>) => {
  const {
    noSearchInput,
    descriptionOnTop = true,
    isMulti = true,
    size = 'md',
    isSearchable = false,
    components,
    isDisabled,
  } = props;

  const componentsData = {
    Option: isMulti
      ? (props: OptionProps) => (
          <MultiOptions
            {...props}
            isDisabled={isDisabled || false}
          />
        )
      : singleOptions,
    MultiValue: ({ ...props }: MultiValueProps) => (
      <MultiValueWithIcons
        getIconTemplate={(values) => {
          const icons = (values as unknown as SelectOption<unknown>[]).map(
            ({ meta }: SelectOption<unknown>) => meta,
          );
          return (
            <div className={cx('containerIcons')}>
              <div className={cx('containerBlockIcon')}>
                {CLOUD_ICON_DICTIONARY[icons[0] as CloudTypes]}
              </div>
            </div>
          );
        }}
        {...props}
      />
    ),
    SingleValue: singleValue,
    MenuList: noSearchInput ? NoInputSearchMenuList : SearchMenuList,
    ...components,
  };

  return (
    <FormSelect
      {...props}
      isDisabled={isDisabled}
      isSearchable={isSearchable}
      size={size}
      descriptionOnTop={descriptionOnTop}
      isMulti={isMulti}
      components={componentsData}
      closeMenuOnSelect={!isMulti}
    />
  );
});

CloudSelect.displayName = 'CloudSelect';

export { CloudSelect };
