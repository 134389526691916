import React, { useCallback, useMemo, useState } from 'react';

import {
  Badge,
  Button,
  Checkbox,
  DownloadReportProps,
  Icon,
  Loader,
  SelectOption,
} from '@kit/components';
import { Select } from '@kit/components/Select';

import { FileFormats, SocialMedia } from '@src/@types';
import { shareLink, bindStyles } from '@src/utils';

import ExcelSvg from '@assets/images/excel.svg';
import PdfSvg from '@assets/images/pdf.svg';

import styles from './DownloadReport.module.scss';

const cx = bindStyles(styles);

const MAX_VISIBLE_EMAILS_COUNT = 4;

const KEY_LAST_BADGE = 'LAST_1';

const EMAIL_REG = new RegExp(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/);

const DownloadReport: React.FC<DownloadReportProps> = ({
  pdf = true,
  xls = true,
  actionButtonAlign = 'left',
  contentOrder = 'row',
  loading,
  localization,
  emails,
  onDownloadPdf,
  onDownloadXls,
  onSendMail,
  onShareFile,
}) => {
  const [formats, setFormats] = useState<Set<FileFormats>>(new Set());

  const [value, setValue] = useState<string>('');

  const [selectedEmails, setSelectedEmails] = useState<SelectOption<string>[]>([]);

  const emailsVariant = useMemo(() => {
    return emails?.map(
      (mail) =>
        ({
          label: mail,
          value: mail,
        } as SelectOption<string>),
    );
  }, [emails]);

  const filteredVariants = useMemo(() => {
    return emailsVariant?.filter((variant) => {
      return !selectedEmails.some((mail) => mail?.value == variant?.value);
    });
  }, [emailsVariant, selectedEmails]);

  const badges = useMemo(() => {
    if (selectedEmails.length <= MAX_VISIBLE_EMAILS_COUNT) {
      return selectedEmails;
    }

    const othersCount = selectedEmails.length - MAX_VISIBLE_EMAILS_COUNT;

    const lastBadge: SelectOption<string> = {
      label: `+ ${othersCount}`,
      value: KEY_LAST_BADGE,
      meta: othersCount.toString(),
    };

    return [...selectedEmails.slice(0, MAX_VISIBLE_EMAILS_COUNT), lastBadge];
  }, [selectedEmails]);

  const onShare = (sm: SocialMedia) => async () => {
    if (!onShareFile) {
      return;
    }

    try {
      const response = await onShareFile('pdf');
      if (!response) {
        return;
      }

      shareLink(sm, response.url);
    } catch (err) {
      console.error(err);
    }
  };

  const onToggleFormat = (format: FileFormats) => () => {
    if (formats.has(format)) {
      formats.delete(format);
    } else {
      formats.add(format);
    }

    setFormats(new Set(formats));
  };

  const onAddEmail = useCallback(
    (item: SelectOption<string>) => {
      setSelectedEmails((prev) => {
        const exist = prev.some((option) => option.value === item.value);
        return exist ? prev : [...prev, item];
      });
    },
    [setSelectedEmails],
  );

  const onDeleteEmail = useCallback(
    (item: SelectOption<string>) => () => {
      setSelectedEmails((prev) => {
        if (item.value === KEY_LAST_BADGE) {
          return prev.slice(0, MAX_VISIBLE_EMAILS_COUNT);
        }

        return prev.filter((prevItem) => prevItem.value !== item.value);
      });
    },
    [setSelectedEmails],
  );

  const onDownload = useCallback(
    (format: FileFormats) => async () => {
      const callback = format === 'pdf' ? onDownloadPdf : onDownloadXls;

      if (!callback) {
        return;
      }

      callback();
    },
    [onDownloadPdf, onDownloadXls],
  );

  const disabledApplyBtn = useMemo(
    () => selectedEmails.length === 0 || formats.size === 0,
    [selectedEmails, formats],
  );

  const onApply = async () => {
    if (!onSendMail) {
      return;
    }

    onSendMail(
      [...formats],
      selectedEmails.map((item) => item.value),
    );
  };

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.code !== 'Enter') {
      return;
    }

    if (EMAIL_REG.test(value)) {
      onAddEmail({ value, label: value });
      setValue('');
    }
  };

  const selectRef = React.useCallback(
    (node) => {
      if (!node) {
        return;
      }

      if (value.length > 0 || selectedEmails.length > 0) {
        node.focus();
      }

      if (value === '') {
        node.blur();
      }
    },
    [value, selectedEmails],
  );

  return (
    <div className={cx('report')}>
      {loading && (
        <div className={cx('loader')}>
          <Loader size="xxl" />
        </div>
      )}

      <div className={cx('reportInner')}>
        <div className={cx('reportRow')}>
          <span className={cx('reportRowTitle')}>{localization.docTitle}</span>
          <div className={cx('reportRowContent', `reportRowContent_${contentOrder}`)}>
            {pdf && (
              <div
                className={cx('file')}
                onClick={onDownload('pdf')}
              >
                <PdfSvg />
                <span>{localization.pdf}</span>
              </div>
            )}
            {xls && (
              <div
                className={cx('file')}
                onClick={onDownload('xls')}
              >
                <ExcelSvg />
                <span>{localization.xls}</span>
              </div>
            )}
          </div>
        </div>
        <div className={cx('reportRow')}>
          <span className={cx('reportRowTitle')}>{localization.share}:</span>
          <div className={cx('reportRowContent', 'reportRowContentSmall')}>
            <Button
              variant="outline"
              size="md"
              onClick={onShare('telegram')}
            >
              <Icon
                className={cx('icon')}
                type="telegram"
              />
            </Button>
            <Button
              variant="outline"
              size="md"
              onClick={onShare('vk')}
            >
              <Icon
                className={cx('icon')}
                type="vk"
              />
            </Button>
          </div>
        </div>
        <div className={cx('reportRow')}>
          <span className={cx('reportRowTitle')}>{localization.email}:</span>
          <div className={cx('reportRowContent', 'reportRowContentCol')}>
            <div className={cx('selectWrap')}>
              <Select
                ref={selectRef}
                value={null}
                inputValue={value}
                noOptionsText={localization.noOptionsText}
                placeholder={localization.emailPlaceholder}
                options={filteredVariants || []}
                onKeyDown={onKeyDown}
                onInputChange={setValue}
                onChange={onAddEmail}
                isClearable
              />

              <div className={cx('selectBadges')}>
                {badges.map((email) => (
                  <Badge
                    key={email.label}
                    iconClassName={cx('selectBadgeIcon')}
                    textClassName={cx('selectBadge')}
                    onDelete={onDeleteEmail(email)}
                  >
                    {email.label}
                  </Badge>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className={cx('formats')}>
          {pdf && (
            <Checkbox
              checked={formats.has('pdf')}
              onChange={onToggleFormat('pdf')}
            >
              {localization.formatPdf}
            </Checkbox>
          )}
          {xls && (
            <Checkbox
              checked={formats.has('xls')}
              onChange={onToggleFormat('xls')}
            >
              {localization.formatXls}
            </Checkbox>
          )}
        </div>
        <div className={cx('footer', `footer_${actionButtonAlign}`)}>
          <Button
            size="md"
            text={localization.submitBtn}
            disabled={disabledApplyBtn}
            onClick={onApply}
          />
        </div>
      </div>
    </div>
  );
};

export { DownloadReport };
