import { useEffect, useState } from 'react';

import {
  CustomerSettingsDTO,
  CustomerSettingsModule,
  CustomerSettingsType,
  UserApi,
} from '@common/api';

const useCustomerSettings = (customerUuid?: string) => {
  const [settings, setSettings] = useState<CustomerSettingsDTO[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getSettings();
  }, [customerUuid]);

  const getSettings = async () => {
    try {
      setIsLoading(true);

      const res = await UserApi.getCustomerSettings(customerUuid);

      setSettings(res.data);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const hasActiveSetting = (
    module: CustomerSettingsModule,
    section: string,
    type = CustomerSettingsType.UI,
  ) => {
    if (isLoading) {
      return false;
    }

    const setting = settings.find(
      (it) => it.module === module && it.section === section && it.type === type,
    );

    if (!setting) {
      return false;
    }

    return setting.available;
  };

  return {
    isLoading,
    hasActiveSetting,
    settings,
  };
};

export { useCustomerSettings };
