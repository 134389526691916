import { useEffect, useState } from 'react';

import { Brand, Language } from '@src/@types';
import { ACCEPT_LANGUAGE } from '@src/constants/headers';
import { DEFAULT_LANGUAGE, LANG_ASTROKITE_KEY, LANG_CLOUDMASTER_KEY } from '@src/constants';
import { I18nService, LocaleApi, RestService, StorageService } from '@src/common';
import { Resource } from 'i18next';

type ReturnValue = {
  currentLanguage: Language;
  onChangeLanguage: (value: Language) => void;
};

const DEFAULT_NAMESPACE = 'translation';

const storageService = StorageService.getInstance();
const i18nService = I18nService.getInstance();
const restService = RestService.getInstance();

const getLsKey = (brand: Brand) =>
  brand === 'cloudmaster' ? LANG_CLOUDMASTER_KEY : LANG_ASTROKITE_KEY;

const initLang = (brand: Brand) => {
  const key = getLsKey(brand);
  const defaultLang = brand === 'cloudmaster' ? DEFAULT_LANGUAGE : 'en-US';
  return (storageService.getItem(key) ?? defaultLang) as Language;
};

const useI18n = (brand: Brand): ReturnValue => {
  const [currentLanguage, setCurrentLanguage] = useState<Language>(() => initLang(brand));

  useEffect(() => {
    const data = StorageService.getInstance().getItem(
      DEFAULT_NAMESPACE.concat(currentLanguage),
    ) as Resource;
    if (data) {
      i18nService.addResource(currentLanguage, DEFAULT_NAMESPACE, data);
    }
  }, []);

  useEffect(() => {
    loadResource(currentLanguage);
  }, [currentLanguage]);

  const loadResource = async (newLanguage: Language) => {
    restService.addDefaultHeader(ACCEPT_LANGUAGE, newLanguage);
    try {
      const { data } = await LocaleApi.fetchLocale();
      storageService.setItem(DEFAULT_NAMESPACE.concat(currentLanguage), data);
      storageService.setItem(getLsKey(brand), newLanguage);
      i18nService.addResource(newLanguage, DEFAULT_NAMESPACE, data);
      i18nService.changeLanguage(newLanguage);
    } catch (error) {
      console.error('LOAD_LOCALE_ERROR', error);
    }
  };

  const onChangeLanguage = (value: Language) => {
    setCurrentLanguage(value);
    storageService.setItem(getLsKey(brand), value);
  };

  return { currentLanguage, onChangeLanguage };
};

export { useI18n, DEFAULT_NAMESPACE };
