import React, { useCallback } from 'react';

import { Stages } from '@common/components/AppToolbar/components/CloudConnect/types';

import { useAnalytics, useAppTranslation, useCloudConnectContext } from '@src/common';
import { bindStyles } from '@src/utils';
import { Button, CMModalHeader, Icon } from '@src/kit';

import styles from './ConnectedCloudsHeader.module.scss';

const cx = bindStyles(styles);

const ConnectedCloudsHeader: React.FC = () => {
  const { t } = useAppTranslation();
  const { selectStage, onClose } = useCloudConnectContext();
  const { logEvent } = useAnalytics();

  const onButtonClick = useCallback(() => {
    selectStage({
      stage: Stages.providerChoice,
    });
    logEvent('Requested cloud connection');
  }, []);

  return (
    <CMModalHeader className={cx('wrapper')}>
      <div className={cx('titleWrapper')}>
        <div className={cx('title')}>{t('CloudConnectHeader')}</div>
        <Icon
          className={cx('close')}
          type="close"
          onClick={onClose}
        />
      </div>
      <div className={cx('container')}>
        <p className={cx('header')}>{t('cos.active.connections.header.text')}</p>
        <Button
          size="lg"
          variant="filled"
          text={t('NewConnection')}
          className={cx('buttonContainer')}
          onClick={onButtonClick}
        />
      </div>
    </CMModalHeader>
  );
};

export { ConnectedCloudsHeader };
