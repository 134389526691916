import React from 'react';

import { bindStyles } from '@src/utils';
import { Checkbox, TextShorter } from '@src/kit';

import styles from './CheckboxOptionContainer.module.scss';

const cx = bindStyles(styles);

type Props = {
  selected: boolean;
  label: React.ReactNode;
  order?: 'checkbox-label' | 'label-checkbox';
};

const CheckboxOptionContainer: React.FC<Props> = ({
  label,
  selected,
  order = 'checkbox-label',
}) => (
  <div className={cx('container', order)}>
    <div className={cx('cb')}>
      <Checkbox
        size="md"
        checked={selected}
      />
    </div>
    <TextShorter
      selectTooltip
      tooltip
      className={cx('label')}
    >
      <span>{label}</span>
    </TextShorter>
  </div>
);

export { CheckboxOptionContainer };
