import { FC, ReactNode, useState } from 'react';

import { Icon } from '@kit/components';

import { bindStyles } from '@src/utils';

import styles from './Disclaimer.module.scss';

type DisclaimerVariant = 'info' | 'danger' | 'warning' | 'success';
type DisclaimerIconPosition = 'top' | 'center';

type InternalLink = {
  text: string;
  href: string;
  position: 'top' | 'content';
};

type DisclaimerProps = {
  text?: ReactNode;
  title?: string;
  isExtend?: boolean;
  internalLink?: InternalLink;
  variant?: DisclaimerVariant;
  iconPosition?: DisclaimerIconPosition;
  onClose?: () => void;
  isVisible?: boolean;
  className?: string;
};

const cx = bindStyles(styles);

type IconVariant = {
  [key in DisclaimerVariant]: JSX.Element;
};

const ICONS: IconVariant = {
  info: <Icon type="informationCircleFill" />,
  danger: <Icon type="exclamationFill" />,
  warning: <Icon type="exclamationFill" />,
  success: <Icon type="checkCircleFill" />,
};

const Disclaimer: FC<DisclaimerProps> = ({
  text,
  variant = 'info',
  isVisible = true,
  iconPosition = 'top',
  onClose,
  isExtend,
  className,
  title,
  internalLink,
}) => {
  const canClose = Boolean(onClose);
  const [isOpenContent, setOpenContent] = useState<boolean>(false);

  const toggleContent = () => {
    setOpenContent(!isOpenContent);
  };

  if (isVisible) {
    return (
      <div className={cx(className, 'wrapper', variant, iconPosition, { cannotClose: !canClose })}>
        <div className={cx('icon')}>{ICONS[variant]}</div>
        <div className={cx('contentWrap')}>
          {title && (
            <div className={cx('title')}>
              {title}
              {internalLink && internalLink.position === 'top' && (
                <a
                  className={cx('internalLink')}
                  href={internalLink.href}
                >
                  {internalLink.text}
                </a>
              )}
            </div>
          )}
          <div className={cx(isOpenContent || !isExtend ? 'openContent' : 'closeContent')}>
            {text}
            {internalLink && internalLink.position === 'content' && (
              <a
                className={cx('internalLink')}
                href={internalLink.href}
              >
                {internalLink.text}
              </a>
            )}
          </div>
        </div>
        {canClose && !isExtend && (
          <div
            onClick={onClose}
            data-testid="button-close"
            className={cx('close')}
          >
            <Icon
              type="close"
              size="xl"
            />
          </div>
        )}
        {isExtend && (
          <div
            onClick={toggleContent}
            data-testid="button-extend"
            className={cx('close', 'chevron', isOpenContent && 'chevronUp')}
          >
            <Icon
              type="chevron-down"
              size="xl"
            />
          </div>
        )}
      </div>
    );
  }

  return null;
};

export { Disclaimer };
export type { DisclaimerVariant, InternalLink, DisclaimerProps };
