import React, { useContext } from 'react';

type NotificationsContextParams = {
  countCommon: number;
  countBgTasks: number;
  refreshCountCommonUnread: (all?: boolean, markAsRead?: boolean) => void;
  reFetchCounters: () => void;
};

const NotificationsContext: React.Context<NotificationsContextParams> =
  React.createContext<NotificationsContextParams>({} as NotificationsContextParams);

const useNotificationsContext = () => useContext<NotificationsContextParams>(NotificationsContext);

export { NotificationsContext, useNotificationsContext };
export type { NotificationsContextParams };
