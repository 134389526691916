import React from 'react';

import cn from 'classnames/bind';
import { Button } from '@kit/components/Button';
import { Icon } from '@kit/components/Icon';
import { Modal } from '@kit/components/Modal';
import { ContentContainer } from '@kit/components/Modal/ContentContainer';
import { ModalBody } from '@kit/components/Modal/ModalBody';
import { ModalFooter } from '@kit/components/Modal/ModalFooter';
import { ModalHeader } from '@kit/components/Modal/ModalHeader';
import { ModalTitle } from '@kit/components/Modal/ModalTitle';

import styles from './TableModal.module.scss';

type Reason = 'close' | 'cancel';

type Props = {
  open: boolean;
  onClose: (reason: Reason) => void;
  title?: string;
  applyButtonText?: string;
  cancelButtonText?: string;
  isLoading?: boolean;
  formId?: string;
  className?: string;
  classNameBody?: string;
  headerBorder?: boolean;
};

const cx = cn.bind(styles);

const TableModal: React.FC<Props> = ({
  onClose,
  open,
  children,
  formId,
  title,
  isLoading = false,
  applyButtonText,
  cancelButtonText,
  className,
  classNameBody,
  headerBorder = false,
}) => {
  const handleAnimationEnd = () => {
    const input = document.querySelector<HTMLInputElement>('input[data-focus]');
    input?.focus();
  };

  const closeWithReason = (reason: Reason) => () => onClose(reason);

  return (
    <Modal
      modal
      open={open}
      overlayStyle={{ background: 'transparent' }}
      onClose={closeWithReason('close')}
      closeOnDocumentClick={false}
      className={cx('modal', 'modalContent', className)}
      classNamePrefix="table-modal"
      onAnimationEnd={handleAnimationEnd}
    >
      <ModalHeader withoutBorder={!headerBorder}>
        <ContentContainer>
          <ModalTitle title={title} />
          <Icon
            type="close"
            onClick={closeWithReason('close')}
            className={cx('close')}
          />
        </ContentContainer>
      </ModalHeader>

      <ModalBody className={cx('modalBody', classNameBody)}>{children}</ModalBody>

      {(applyButtonText || cancelButtonText) && (
        <ModalFooter>
          <ContentContainer>
            <Button
              className={cx('leftButton')}
              onClick={closeWithReason('cancel')}
              variant="outline"
              text={cancelButtonText}
              full
              disabled={isLoading}
            />
            <Button
              type="submit"
              variant="filled"
              text={applyButtonText}
              full
              isLoading={isLoading}
              form={formId}
            />
          </ContentContainer>
        </ModalFooter>
      )}
    </Modal>
  );
};

export { TableModal };
export type { Reason };
