import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useDropdownResize } from '@kit/components/CmSelect/hooks/useDropdownResize';

import { bindStyles } from '@src/utils';

import styles from './Dropdown.module.scss';

const cx = bindStyles(styles);

type Props = {
  portal?: boolean;
  maxMenuHeight?: number;
  customTop?: number;
  right?: boolean;
  selectionContainer: HTMLDivElement;
  children: React.ReactNode;
  topContent?: React.ReactNode;
  bottomContent?: React.ReactNode;
  onClose: () => void;
};

export const TOOLTIP_SELECT_ID = 'tooltip-dropdown';

const Dropdown: React.FC<Props> = ({
  portal,
  selectionContainer,
  customTop,
  children,
  maxMenuHeight,
  topContent,
  bottomContent,
  right,
  onClose,
}) => {
  const refContainer = useRef<HTMLDivElement | null>(null);
  const [position, setPosition] = useState<'top' | 'bottom' | undefined>(undefined);

  const getPositions = (pos?: string) => {
    const currentPos = pos || position;
    const contentHeight = refContainer.current?.clientHeight || 0;
    const { clientHeight, clientWidth } = selectionContainer;

    if (portal) {
      const { bottom, left, top } = selectionContainer.getBoundingClientRect();

      return {
        width: clientWidth,
        top: currentPos === 'bottom' ? bottom + 5 : top - contentHeight,
        left,
        opacity: currentPos ? 1 : 0,
      };
    }

    return {
      top:
        typeof customTop !== 'undefined'
          ? customTop
          : currentPos === 'bottom'
          ? clientHeight
          : -contentHeight,
      opacity: currentPos ? 1 : 0,
      left: right ? clientWidth : undefined,
    };
  };

  const [styles, setStyles] = useState(() => {
    return getPositions();
  });

  const { resized, onInitResize } = useDropdownResize();

  useEffect(() => {
    setStyles(getPositions());
  }, [selectionContainer, position, resized]);

  const calcPosition = (ref: HTMLDivElement) => {
    const isOut =
      selectionContainer.getBoundingClientRect().bottom + ref.clientHeight > window.innerHeight;

    if (isOut) {
      setPosition('top');
      return 'top';
    } else {
      setPosition('bottom');
      return 'bottom';
    }
  };

  const initRefContainer = useCallback((ref: HTMLDivElement | null) => {
    if (!ref) {
      return;
    }
    refContainer.current = ref;

    calcPosition(ref);
    onInitResize(ref);
  }, []);

  useEffect(() => {
    const onWindowClick = (e: MouseEvent | TouchEvent) => {
      const tooltip = document.querySelector(`#${TOOLTIP_SELECT_ID}`);

      if (
        !refContainer.current?.contains(e.target as Node) &&
        !tooltip?.contains(e.target as Node)
      ) {
        onClose();
      }
    };

    const onWindowScroll = () => {
      if (refContainer.current) {
        const pos = calcPosition(refContainer.current);
        setStyles(getPositions(pos));
      }
    };

    document.addEventListener('mousedown', onWindowClick);
    document.addEventListener('touchstart', onWindowClick);
    document.addEventListener('scroll', onWindowScroll);

    return () => {
      document.removeEventListener('mousedown', onWindowClick);
      document.removeEventListener('touchstart', onWindowClick);
      document.removeEventListener('scroll', onWindowScroll);
    };
  }, []);

  return (
    <div
      ref={initRefContainer}
      style={styles}
      className={cx('container', { noPortal: !portal })}
    >
      <div className={cx('inner')}>
        {topContent && <div className={cx('top')}>{topContent}</div>}
        <div
          className={cx('content')}
          style={{ maxHeight: maxMenuHeight }}
        >
          {children}
        </div>
        {bottomContent && <div className={cx('bottom')}>{bottomContent}</div>}
      </div>
    </div>
  );
};

export { Dropdown };
