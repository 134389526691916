import { FC } from 'react';

import { FormDataHyperV } from '@common/components/AppToolbar/components/CloudConnect/types';

import { FormInput, required, useAppFormContext, useAppTranslation } from '@src/common';
import { Disclaimer, RadioButtonGroup, RadioButtonGroupOption } from '@src/kit';
import { bindStyles } from '@src/utils';
import { HTTPS, NAME, PASSWORD_FIELD, PORT, SERVER, USERNAME } from '@src/constants';

import styles from '../HyperVConnection.module.scss';

const cx = bindStyles(styles);

const ConnectionStep: FC = () => {
  const { t } = useAppTranslation();

  const { control } = useAppFormContext<FormDataHyperV>();

  const options: RadioButtonGroupOption[] = [
    { label: t('Used'), value: 'used' },
    { label: t('NotUsed'), value: 'notUsed' },
  ];

  return (
    <>
      <div>
        <div className={cx('connectionBlock')}>
          <div className={cx('info')}>
            <Disclaimer text={t('cld.HyperV.modal.connectionHint')} />
          </div>
          <div className={cx('blockContent')}>
            <FormInput
              placeholder={t('HyperVNamePH')}
              name={NAME}
              rules={required('FieldRequired')}
              caption={t('HyperVName')}
            />
            <FormInput
              placeholder={t('HyperVDomainPH')}
              name={SERVER}
              rules={required('FieldRequired')}
              caption={t('HyperVDomain')}
            />
          </div>
        </div>
        <div className={cx('connectionBlock')}>
          <div className={cx('blockContent')}>
            <FormInput
              placeholder={t('HyperVUserNamePH')}
              name={USERNAME}
              rules={{
                ...required('FieldRequired'),
              }}
              caption={t('HyperVUserName')}
            />
            <FormInput
              placeholder={t('HyperVUserPassPH')}
              name={PASSWORD_FIELD}
              rules={required('FieldRequired')}
              caption={t('HyperVUserPass')}
            />
          </div>
        </div>
        <div className={cx('connectionBlock')}>
          <div className={cx('blockContent')}>
            <div className={cx('radio')}>
              <span>{t('Protocol')}</span>
              <RadioButtonGroup
                name={HTTPS}
                control={control}
                classes={{
                  group: cx('radio-group'),
                  groupItem: cx('radio-groupItem'),
                }}
                options={options}
              />
            </div>
          </div>
        </div>
        <div className={cx('connectionBlock')}>
          <div className={cx('blockContent')}>
            <FormInput
              className={cx('port')}
              placeholder="5986"
              name={PORT}
              rules={required('FieldRequired')}
              caption={t('HyperVPort')}
            />
          </div>
        </div>
        <div className={cx('info')}>
          <Disclaimer text={t('cld.HyperV.modal.settingsHint')} />
        </div>
      </div>
    </>
  );
};

export { ConnectionStep };
