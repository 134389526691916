import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { useCloudsContext } from '@common/providers/CloudsProvider';

import { FormCmSelect, FormInput, urlPattern, useAppTranslation } from '@src/common';
import { Disclaimer, SelectOption } from '@src/kit';
import { bindStyles } from '@src/utils';
import { API_VERSION, NAME, PASSWORD_FIELD, USERNAME, VCENTER } from '@src/constants';

import styles from '../VSphereConnection.module.scss';
import { SECRET_FIELD_EDIT_PLACEHOLDER } from '@common/components/Clouds/components';

const cx = bindStyles(styles);

const ConnectionStep: FC = () => {
  const { t } = useAppTranslation();
  const { cloudEdit } = useCloudsContext();
  const form = useFormContext();

  const apiVersion = form.watch('apiVersion');

  const apiVersionOptions: SelectOption<string>[] = [
    {
      label: '6.0',
      value: '6.0',
    },
    {
      label: '7.0',
      value: '7.0',
    },
  ];

  return (
    <>
      <div>
        <div className={cx('connectionBlock')}>
          <div className={cx('info')}>
            <Disclaimer text={t('VSphereInfoText')} />
          </div>
          <div className={cx('blockContent', 'three-big')}>
            <FormInput
              name={NAME}
              caption={t('cloudName')}
              placeholder={t('createNameCloud')}
              hint={t('VCenterHintName')}
            />
            <FormInput
              name={VCENTER}
              caption={t('VCenter')}
              rules={{ ...urlPattern('InputFormatError') }}
              placeholder={cloudEdit ? SECRET_FIELD_EDIT_PLACEHOLDER : t('EnterValue')}
              hint={t('VCenterApiHint')}
              disabled={cloudEdit}
            />
            <FormCmSelect
              name={API_VERSION}
              caption={t('apiVersion')}
              options={apiVersionOptions}
              value={apiVersion}
              disabled={cloudEdit}
            />
          </div>
        </div>
        <div className={cx('connectionBlock')}>
          <div className={cx('blockContent', 'three-big')}>
            <FormInput
              name={USERNAME}
              caption={t('Login')}
              placeholder={cloudEdit ? SECRET_FIELD_EDIT_PLACEHOLDER : t('EnterLogin')}
              hint={t('VCenterLoginHint')}
              disabled={cloudEdit}
            />
            <FormInput
              name={PASSWORD_FIELD}
              type="password"
              caption={t('Password')}
              placeholder={cloudEdit ? SECRET_FIELD_EDIT_PLACEHOLDER : t('EnterPassword')}
              autoComplete="new-password"
              disabled={cloudEdit}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export { ConnectionStep };
