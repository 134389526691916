import React, { useCallback } from 'react';

import { useManageClouds } from '@common/components/AppToolbar/components/CloudConnect/ConnectedClouds/hooks/useManageClouds';
import { useDeleteCloud } from '@common/components/AppToolbar/components/CloudConnect/ConnectedClouds/hooks/useDeleteCloud';
import {
  ConnectedCloudsDataDTO,
  Stages,
} from '@common/components/AppToolbar/components/CloudConnect/types';
import { CLOUD_FORM_EDIT_DICTIONARY } from '@common/components/AppToolbar/components/CloudConnect/CloudEdit';

import { useAppTranslation, useCloudConnectContext } from '@src/common';
import { bindStyles } from '@src/utils';

import styles from './Connections.module.scss';
import { CloudCard } from '../CloudCard';

const cx = bindStyles(styles);

const EDITABLE_CLOUDS = Object.keys(CLOUD_FORM_EDIT_DICTIONARY);

const Connections: React.FC = () => {
  const { t } = useAppTranslation();
  const { selectStage, setEditableCloud } = useCloudConnectContext();
  const { onChangeStatus, clouds, fetch } = useManageClouds();
  const {
    loading,
    onDeleteCloud,
    deletableCloud,
    openDeleteModal,
    closeDeleteModal,
    SuspendDialog,
  } = useDeleteCloud({ refetchData: fetch });

  const onEdit = useCallback((cloud: ConnectedCloudsDataDTO) => {
    selectStage({
      stage: Stages.cloudEdit,
      title: t('cos.cloudConnect.edit.title'),
    });
    setEditableCloud(cloud);
  }, []);

  const actions = {
    cancel: {
      text: t('DialogCancel'),
    },
    submit: {
      text: t('Delete'),
      negative: true,
    },
  };
  return (
    <div className={cx('wrapper')}>
      <div className={cx('container')}>
        {clouds?.map((cloud) => (
          <CloudCard
            key={cloud.id}
            cloud={cloud}
            onPause={(id) => onChangeStatus(id, 'STOPPED')}
            onStart={(id) => onChangeStatus(id, 'ACTIVE')}
            onDelete={openDeleteModal}
            onEdit={EDITABLE_CLOUDS.includes(cloud.cloudType) ? onEdit : undefined}
          />
        ))}
        <SuspendDialog
          loading={loading}
          title={t('CloudRemoval')}
          type="warning"
          actions={actions}
          onConfirm={onDeleteCloud}
          onCancel={closeDeleteModal}
        >
          <span>{t('CloudRemove', { name: deletableCloud?.name })}</span>
          <span className={styles.dateText}> {deletableCloud?.createdAt}</span>
        </SuspendDialog>
      </div>
    </div>
  );
};

export { Connections };
