import { FC, PropsWithChildren } from 'react';

import { useCustomerSettings } from '@src/common';

import { CustomerSettingsContext } from './CustomerSettingsContext';

const CustomerSettingsProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { hasActiveSetting, isLoading } = useCustomerSettings();

  return (
    <CustomerSettingsContext.Provider
      value={{
        hasActiveSetting,
        isLoading,
      }}
    >
      {children}
    </CustomerSettingsContext.Provider>
  );
};

export { CustomerSettingsProvider };
