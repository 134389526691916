import { FC } from 'react';

import { SearchField } from '@kit/components/CmSelect/SearchField';
import { SelectAllCheckbox } from '@kit/components/CmSelect/SelectAllCheckbox';

import { bindStyles } from '@src/utils';

import styles from './Header.module.scss';

const cx = bindStyles(styles);

type Props = {
  optionsCount: number;
  searchValue: string;
  onChangeSearchValue: (value: string) => void;
  onSelectAll: () => void;
  isMultiLevel?: boolean;
  searchable?: boolean;
  multi?: boolean;
  selectAllType: boolean | 'mixed';
  selectAllAlign?: 'left' | 'right';
};

const Header: FC<Props> = ({
  isMultiLevel,
  multi,
  searchable,
  optionsCount,
  searchValue,
  onChangeSearchValue,
  onSelectAll,
  selectAllType,
  selectAllAlign,
}) => {
  return (
    <>
      {searchable && (
        <div className={cx('inner-element', 'search')}>
          <SearchField
            searchValue={searchValue}
            onChangeSearchValue={onChangeSearchValue}
          />
        </div>
      )}
      {multi && optionsCount > 5 && !isMultiLevel && (
        <div className={cx('inner-element', 'select-all', `select-all_${selectAllAlign}`)}>
          <SelectAllCheckbox
            type={selectAllType}
            align={selectAllAlign}
            onClick={onSelectAll}
          />
        </div>
      )}
    </>
  );
};

const renderHeader = ({
  isMultiLevel,
  multi,
  searchable,
  selectAllAlign,
  onSelectAll,
  selectAllType,
  optionsCount,
  searchValue,
  onChangeSearchValue,
}: Props) => {
  if (isMultiLevel || (!searchable && !(multi && optionsCount > 5))) {
    return null;
  }

  return (
    <Header
      searchValue={searchValue}
      onChangeSearchValue={onChangeSearchValue}
      optionsCount={optionsCount}
      selectAllType={selectAllType}
      onSelectAll={onSelectAll}
      isMultiLevel={isMultiLevel}
      searchable={searchable}
      selectAllAlign={selectAllAlign}
      multi={multi}
    />
  );
};

export { Header, renderHeader };
