import { FC, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { AxiosError } from 'axios';
import {
  addServiceAccount,
  getServiceAccountInfo,
} from '@common/components/AppToolbar/api/cloud-connections';

import {
  Form,
  FormInput,
  FormTextarea,
  useAppTranslation,
  useCloudConnectContext,
  useFormApiError,
  useToast,
  YandexCloudServiceAccountDTO,
  YaSubCloudDTO,
} from '@src/common';
import { bindStyles } from '@src/utils';

import styles from './YandexEdit.module.scss';

type Props = {
  cloudId: string;
  subCloud: YaSubCloudDTO;
  onBack: () => void;
  fetchServiceAccounts: () => void;
};

const cx = bindStyles(styles);

const YandexCloudServiceAccountEdit: FC<Props> = ({
  subCloud,
  cloudId,
  onBack,
  fetchServiceAccounts,
}) => {
  const { t } = useAppTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const form = useForm<YandexCloudServiceAccountDTO>({
    defaultValues: {
      yandexCloudUuid: subCloud.uuid,
      privateAuthKey: '',
      serviceAccountId: '',
      authKeyId: '',
      keyAuthAlgorithm: 'RSA_2048',
      publicAuthKey: '',
    },
  });
  const { apiErrors, handleError } = useFormApiError();
  const toast = useToast();
  const { changeAgreeButtonSettings } = useCloudConnectContext();

  const onSubmit = useCallback(
    async (values: YandexCloudServiceAccountDTO) => {
      try {
        const res = await addServiceAccount(cloudId, values);
        toast.success({ text: t('cos.cloud.edit.yandex.service.account.success') });
        await fetchServiceAccounts();
        onBack();
        return res;
      } catch (e) {
        handleError(e as AxiosError);
      }
    },
    [cloudId],
  );

  const fetchInfo = async () => {
    try {
      setIsLoading(true);
      const res = await getServiceAccountInfo(subCloud.uuid);
      form.setValue('serviceAccountId', res.data.serviceAccountId);
      form.setValue('authKeyId', res.data.serviceAccountKeyId);
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (subCloud.hasServiceAccount) {
      fetchInfo();
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    changeAgreeButtonSettings({
      onClick: form.handleSubmit(onSubmit),
      text: t('cos.cloudConnect.edit.save'),
      isLoading,
    });
  }, [t, isLoading]);

  return (
    <Form<YandexCloudServiceAccountDTO>
      formMethods={form}
      onSubmit={onSubmit}
      apiErrors={apiErrors}
    >
      <div className={cx('column', 'form')}>
        <FormInput
          name="serviceAccountId"
          placeholder={t('cos.connectCloud.edit.serviceAccountId.placeholder')}
          caption={t('cos.connectCloud.edit.serviceAccountId.caption')}
          disabled={isLoading}
        />
        <FormInput
          name="authKeyId"
          placeholder={t('cos.connectCloud.edit.authKeyId.placeholder')}
          caption={t('cos.connectCloud.edit.authKeyId.caption')}
          disabled={isLoading}
        />
        <FormTextarea
          name="publicAuthKey"
          placeholder={t('cos.connectCloud.edit.publicAuthKey.placeholder')}
          caption={t('cos.connectCloud.edit.publicAuthKey.caption')}
        />
        <FormTextarea
          name="privateAuthKey"
          placeholder={t('cos.connectCloud.edit.privateAuthKey.placeholder')}
          caption={t('cos.connectCloud.edit.privateAuthKey.caption')}
        />
      </div>
    </Form>
  );
};

export { YandexCloudServiceAccountEdit };
