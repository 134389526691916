import React from 'react';
import { components, OptionProps } from 'react-select';

import cn from 'classnames/bind';
import { Checkbox, Tooltip, SelectOption } from '@kit/components';

import { Size } from '@src/@types';

import styles from './Select.module.scss';

type Props = OptionProps & {
  checkboxSize?: Extract<Size, 'lg' | 'md' | 'sm'>;
  hasTooltip?: boolean;
  onChange?: (value: Omit<OptionProps, 'children'>) => void;
};

const cx = cn.bind(styles);

const CheckboxOption: React.FC<Props> = ({
  children,
  className,
  checkboxSize,
  hasTooltip,
  onChange,
  ...props
}) => {
  const { isSelected, isFocused, data } = props;
  const value = (data as SelectOption<unknown>).value as string;

  const handleChange = () => {
    if (!props.isDisabled) {
      onChange?.(props);
    }
  };

  const node = hasTooltip ? (
    <Tooltip
      place="right"
      text={value}
    >
      {children}
    </Tooltip>
  ) : (
    children
  );

  return (
    <div className={cx('optionContainer')}>
      <components.Option
        {...props}
        className={cx(className, 'option', { isFocused })}
      >
        <Checkbox
          disabled={props.isDisabled}
          checked={isSelected}
          onChange={handleChange}
          size={checkboxSize}
        >
          {node}
        </Checkbox>
      </components.Option>
    </div>
  );
};

export { CheckboxOption };
