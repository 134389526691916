import React from 'react';

import { UserRole } from '@common/components/UserRoles/types';

import { Avatar } from '@src/kit';
import { useAppTranslation } from '@src/common';
import { bindStyles } from '@src/utils';

import CosterImage from '@assets/images/CosterModule.svg';
import ClouderModule from '@assets/images/ClouderModule.svg';

import styles from './UsersInfoCell.module.scss';

type Props = {
  lastName: string;
  firstName: string;
  roles: UserRole[];
  isActive?: boolean;
  uuid?: string;
};

const cx = bindStyles(styles);

const UsersInfoCell: React.FC<Props> = ({ lastName, firstName, roles = [], uuid }) => {
  const { t } = useAppTranslation();

  const clouderRole = roles[0];
  const costerRole = roles[1];

  const name = `${lastName ? lastName + ' ' : ''} ${firstName}`;

  return (
    <div className={cx('container')}>
      <Avatar
        lastName={lastName}
        firstName={firstName}
        userId={uuid}
        className={cx('avatar')}
      />
      <span className={cx('name')}>{name}</span>
      <div className={cx('roles')}>
        {costerRole && (
          <div className={cx('role')}>
            <div className="roleImage coster">
              <CosterImage />
            </div>
            <span>{t(costerRole.name.replace(' ', ''))}</span>
          </div>
        )}
        {clouderRole && (
          <div className={cx('role')}>
            <div className="roleImage clouder">
              <ClouderModule />
            </div>
            <span>{t(clouderRole.name)}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export { UsersInfoCell };
