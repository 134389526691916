import { useMemo } from 'react';

import { useAppTranslation } from '@src/common';

const useToolbarLocalization = () => {
  const { t } = useAppTranslation();

  return useMemo(
    () => ({
      profile: {
        user: t('User'),
        logout: t('LogOut'),
      },
      infoStage: {
        company: t('Company'),
        mail: t('Mail'),
        phone: t('Phone'),
        role: t('Role'),
      },
    }),
    [t],
  );
};

export { useToolbarLocalization };
