import React from 'react';

import cn from 'classnames/bind';

import styles from './Button.module.scss';
import { ButtonView, ButtonViewProps } from './Button.view';

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & ButtonViewProps;

const cx = cn.bind(styles);

const Button: React.FC<Props> = ({
  disabled,
  onClick,
  className,
  full,
  type = 'button',
  text,
  variant,
  size,
  isLoading,
  iconPosition,
  loadProgress,
  active,
  textClassName,
  isNegativeIcon,
  children,
  negative,
  count,
  ...restProps
}) => (
  <button
    className={cx(className, 'container', { disabled, full, negative, size })}
    disabled={disabled || isLoading}
    onClick={onClick}
    type={type}
    {...restProps}
  >
    <ButtonView
      {...{
        text,
        count,
        variant,
        size,
        isLoading,
        loadProgress,
        iconPosition,
        active,
        textClassName,
        isNegativeIcon,
      }}
    >
      {children}
    </ButtonView>
  </button>
);

export { Button };
export type { Props as ButtonProps };
