import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import { AxiosError } from 'axios';
import {
  deleteUserAvatar,
  updateProfile,
  updateUserAvatar,
} from '@common/components/AppToolbar/api/requests';
import { InfoStage } from '@common/components/AppToolbar/components/Profile/InfoStage';
import { UpdateStage } from '@common/components/AppToolbar/components/Profile/UpdateStage';
import { HeaderSideModal } from '@kit/components/HeaderSideModal';

import {
  Form,
  ProfileStage,
  RoleDto,
  useAppToolbarContext,
  useAppTranslation,
  useFormApiError,
  UserDto,
  UserUpdateDTO,
  useToast,
} from '@src/common';
import { encrypt, bindStyles } from '@src/utils';
import { Button, H3, Icon, ModalFooter } from '@src/kit';

import styles from './SidePanelProfile.module.scss';

const cx = bindStyles(styles);

type FormUserUpdate = {
  firstName: string;
  lastName: string;
  login?: string;
  newPassword: string;
  oldPassword: string;
  newPassConfirm: string;
  company?: string;
  tel?: string;
  avatar?: string;
  passwordVisible: false;
};

type Props = {
  user: UserDto;
  onClose: () => void;
  onLogout: () => void;
};

const FORM_ID = 'profile';

const SidePanelProfile: React.FC<Props> = ({ onClose, user, onLogout }) => {
  const { t } = useAppTranslation();
  const [stage, setStage] = useState<ProfileStage>('info');
  const [loading, setLoading] = useState<boolean>(false);
  const toast = useToast();
  const { handleError } = useFormApiError();
  const refContentFields = useRef<HTMLDivElement | null>(null);

  const { onChangeUser } = useAppToolbarContext();

  const { firstName, lastName, login, tel, company, avatar } = user;

  const defaultValues: FormUserUpdate = {
    firstName,
    lastName,
    login,
    newPassword: '',
    oldPassword: '',
    newPassConfirm: '',
    company: company || '',
    tel,
    avatar: avatar || '',
    passwordVisible: false,
  };

  const formMethods = useForm<FormUserUpdate>({ defaultValues, mode: 'onSubmit' });

  useEffect(() => {
    const sub = formMethods.watch((value, { name, type }) => {
      if (type !== 'change') {
        return;
      }
      if (name === 'passwordVisible') {
        formMethods.resetField('oldPassword');
        formMethods.resetField('newPassword');
        formMethods.resetField('newPassConfirm');
      }
    });

    return sub.unsubscribe;
  }, [formMethods.watch]);

  const onSubmit = async (value: FormUserUpdate) => {
    setLoading(true);

    try {
      if (value.avatar !== avatar && value.avatar) {
        await updateUserAvatar({ data: value.avatar });
        onChangeUser({ avatar: value.avatar });
      } else if (avatar && !value.avatar) {
        await deleteUserAvatar();
        onChangeUser({ avatar: undefined });
      }

      const { firstName, lastName, tel, company, oldPassword, newPassword, passwordVisible } =
        value;

      const user: UserUpdateDTO = {
        company,
        tel,
        firstName,
        lastName,
        oldPassword: encrypt(oldPassword),
        newPassword: encrypt(newPassword),
        updatePassword: passwordVisible,
      };

      await updateProfile(user);

      onChangeUser({
        company,
        tel,
        firstName,
        lastName,
      });

      toast.success({ text: t('cos.editUserInfo.statusPopup.success') });

      if (passwordVisible) {
        toast.success({ text: t('cos.editUserInfo.passwordUpdate.success') });
      }

      onClose();
    } catch (error) {
      const response = (error as AxiosError).response;

      if (response?.status === 423) {
        return;
      }

      if (response?.status === 400) {
        const data = response?.data as { message: string };

        if (data.message === 'Not valid old password') {
          formMethods.setError('oldPassword', {
            type: 'Wrong',
            message: t('cld.editUserInfo.oldPasswordWrong'),
          });
        }
        return;
      }

      handleError(error as AxiosError, t('cos.editUserInfo.statusPopup.error'));
    } finally {
      setLoading(false);
    }
  };

  const onChangeStage = (stage: ProfileStage) => () => setStage(stage);

  return (
    <HeaderSideModal
      open
      closeOnDocumentClick
      className={cx('modalContainer')}
      classes={{
        inner: cx('modalInner'),
        wrapper: cx('modalWrapper'),
        header: cx('modalHeader'),
        content: cx('modalContent'),
      }}
      onClose={onClose}
      modalHeader={
        <>
          <div className={cx('modalTitle')}>
            <div>
              <H3>{t('User')}</H3>
            </div>
            <div>
              <Icon
                type="close"
                className={cx('icon')}
                onClick={onClose}
              />
            </div>
          </div>
        </>
      }
    >
      <div
        className={cx('modalContainer')}
        ref={refContentFields}
      >
        {stage === 'info' ? (
          <InfoStage user={user} />
        ) : (
          <Form
            id={FORM_ID}
            formMethods={formMethods}
            onSubmit={onSubmit}
          >
            <UpdateStage
              clouderRole={user?.roles?.[0] || ({} as RoleDto)}
              costerRole={user?.roles?.[1] || ({} as RoleDto)}
              scrollToEnd={() => {
                setTimeout(() => refContentFields.current?.scrollTo(0, 1000), 200);
              }}
            />
          </Form>
        )}
      </div>

      <ModalFooter className={cx('footer')}>
        {stage === 'info' ? (
          <Button
            onClick={onLogout}
            isNegativeIcon
            variant="outline"
            text={t('LogOut')}
            full
            negative
          >
            <Icon type="logout" />
          </Button>
        ) : (
          <Button
            onClick={onChangeStage('info')}
            variant="outline"
            text={t('Cancel')}
            full
          />
        )}
        {stage === 'info' ? (
          <Button
            key="edit"
            onClick={onChangeStage('update')}
            variant="outline"
            text={t('edit')}
            full
          >
            <Icon type="edit" />
          </Button>
        ) : (
          <Button
            isLoading={loading}
            key="save"
            form={FORM_ID}
            type="submit"
            text={t('Save')}
            full
          />
        )}
      </ModalFooter>
    </HeaderSideModal>
  );
};

export { SidePanelProfile };
export type { FormUserUpdate };
