import { FC } from 'react';

import { Translate, useAppTranslation, usePopupControls } from '@src/common';
import { bindStyles } from '@src/utils';
import { H3, Icon } from '@src/kit';

import styles from './BlockTitle.module.scss';

type Props = {
  titleKey: string;
  hintKey: string;
};

const cx = bindStyles(styles);

const BlockTitle: FC<Props> = ({ titleKey, hintKey }) => {
  const hint = usePopupControls();
  const { t } = useAppTranslation();

  return (
    <div className={cx('container')}>
      <H3
        className={cx('title')}
        size="sm"
      >
        {t(titleKey)}
        <Icon
          type="question-mark-circle"
          className={cx('icon', { active: hint.isOpened })}
          onClick={hint.togglePopup}
        />
      </H3>
      <div className={cx('hintContent', { active: hint.isOpened })}>
        <div className={cx('rect')} />
        <Translate
          i18nKey={hintKey}
          components={{
            b: <strong />,
            a: <a />,
            h4: <h4 />,
            p: <p />,
          }}
        />
      </div>
    </div>
  );
};

export { BlockTitle };
