import { IntervalProps, IntervalVariants } from '@kit/components/Calendar/Container';

import { mapToOption } from '@src/utils';

const useCalendarIntervalOptions = () => {
  const intervals: IntervalProps['intervals'] = [
    mapToOption(IntervalVariants.DAY, 'cos.INTERVAL_DAILY.type'),
    mapToOption(IntervalVariants.MONTH, 'cos.INTERVAL_MONTHLY.type'),
  ];

  return { intervals };
};

export { useCalendarIntervalOptions };
