import { FC, Fragment, useMemo } from 'react';

import { useNewConnectionContext } from '@common/components/Clouds/components/NewConnection/NewConnectionContext';
import { StatusInfoVariant } from '@common/components/Clouds/components/NewConnection/StatusInfo';
import { useCloudsContext } from '@common/providers/CloudsProvider';

import { bindStyles } from '@src/utils';
import { useAppTranslation } from '@src/common';
import { Icon } from '@src/kit';
import { CLOUD_ICON_DICTIONARY } from '@src/constants';
import { CloudTypes } from '@src/@types';

import styles from './Stepper.module.scss';

enum ConnectionSteps {
  PROVIDER = 'provider',
  SETTINGS = 'settings',
  POLICY = 'policy',
  STATUS = 'status',
}

const cx = bindStyles(styles);

const TITLES = {
  [ConnectionSteps.PROVIDER]: 'clouds.connection.stepper.providerChoice',
  [ConnectionSteps.SETTINGS]: 'clouds.connection.stepper.connectionSettings',
  [ConnectionSteps.POLICY]: 'clouds.connection.stepper.connectionPolicy',
  [ConnectionSteps.STATUS]: 'clouds.connection.stepper.connectionStatus',
};

const Stepper: FC = () => {
  const { t } = useAppTranslation();
  const { currentStep, selectedProvider, noNeedPolicy, statusInfoSettings } =
    useNewConnectionContext();
  const { cloudEdit, editableCloud } = useCloudsContext();

  const noNeedPolicyProvider = noNeedPolicy.includes(selectedProvider as string);

  const availableSteps = useMemo(() => {
    if (noNeedPolicyProvider) {
      return [ConnectionSteps.PROVIDER, ConnectionSteps.SETTINGS, ConnectionSteps.STATUS];
    } else {
      return [
        ConnectionSteps.PROVIDER,
        ConnectionSteps.SETTINGS,
        ConnectionSteps.POLICY,
        ConnectionSteps.STATUS,
      ];
    }
  }, [selectedProvider]);

  const renderedSteps = useMemo(() => {
    return availableSteps.map((it, i) => {
      const activeIndex = availableSteps.findIndex((step) => step === currentStep);
      const isReady = activeIndex >= i;
      const isLineActive = activeIndex >= i + 1;
      const prevReady = isReady && currentStep !== it;
      const errorStatus =
        statusInfoSettings.variant === StatusInfoVariant.ERROR &&
        currentStep === ConnectionSteps.STATUS;
      const renderedTitle = () => {
        if (it === ConnectionSteps.POLICY && selectedProvider === 'HyperV') {
          return t('ConfiguringStorageClassification');
        } else if (it === ConnectionSteps.PROVIDER && cloudEdit) {
          return (
            <div className={cx('edit-wrapper')}>
              <div className={cx('edit-cloudIcon')}>
                {CLOUD_ICON_DICTIONARY[editableCloud?.cloudType as CloudTypes]}
              </div>
              <div className={cx('edit-cloudName')}>{editableCloud?.name}</div>
              <div className={cx('edit-cloudId')}>(ID:{editableCloud?.id})</div>
            </div>
          );
        } else {
          return t(TITLES[it]);
        }
      };

      return (
        <Fragment key={it}>
          <div className={cx('container')}>
            {errorStatus && currentStep === it ? null : (
              <div className={cx('dot', { ready: isReady, prev: prevReady })}>
                {prevReady && (
                  <Icon
                    style={{ width: '22px', height: '22px' }}
                    type="check"
                    className={cx('icon')}
                  />
                )}
              </div>
            )}
            {errorStatus
              ? currentStep === it && (
                  <div className={cx('dot-error')}>
                    <Icon
                      style={{ width: '24px', height: '24px' }}
                      type="close"
                      className={cx('icon')}
                    />
                  </div>
                )
              : currentStep === it && <div className={cx('dot-circle')} />}
            <div className={cx('dot-text')}>{renderedTitle()}</div>
          </div>
          {i !== availableSteps.length - 1 && (
            <div
              className={cx('line', { active: isLineActive, noPolicy: !noNeedPolicyProvider })}
            />
          )}
        </Fragment>
      );
    });
  }, [currentStep, t, statusInfoSettings]);

  return <div className={cx('wrapper')}>{renderedSteps}</div>;
};

export { Stepper };
