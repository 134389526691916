import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  editYandexCloud,
  getYandexCloudInfo,
  getYandexSubList,
} from '@common/components/AppToolbar/api/cloud-connections';
import { BlockTitle } from '@common/components/AppToolbar/components/CloudConnect/BlockTitle';
import { Stages } from '@common/components/AppToolbar/components/CloudConnect/types';
import { AxiosError } from 'axios';

import {
  CmTableResponse,
  Form,
  FormInput,
  required,
  useAnalytics,
  useCloudConnectContext,
  useCMTable,
  useFormApiError,
  useToast,
  useTranslationPrefix,
  YaCloudEditDTO,
  YaSubCloudDTO,
} from '@src/common';
import { Button } from '@src/kit';
import { bindStyles } from '@src/utils';
import { CLOUD_NAME_DICTIONARY } from '@src/constants';

import { YandexCloudServiceAccountEdit } from './YandexCloudServiceAccountEdit';
import styles from './YandexEdit.module.scss';
import { CloudEditLoading } from '@common/components/AppToolbar/components/CloudConnect/CloudEdit/CloudEditLoading';

const cx = bindStyles(styles);

const YandexEdit: FC = () => {
  const { t, tPrefix } = useTranslationPrefix('cos.yandexConnectCloud.form.');
  const [selectedSubCloud, setSelectedSubCloud] = useState<YaSubCloudDTO>();
  const [isInitLoading, setIsInitLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const [savedAccessKey, setSavedAccessKey] = useState('');
  const { logSuccessEvent, logErrorEvent } = useAnalytics();

  const {
    editableCloud,
    selectStage,
    onChangeTitle,
    changeDisagreeButtonSettings,
    changeAgreeButtonSettings,
  } = useCloudConnectContext();

  const { apiErrors, handleError } = useFormApiError();
  const form = useForm<YaCloudEditDTO>({
    defaultValues: {
      accessKey: '',
    },
  });

  const accessKey = form.watch('accessKey');

  const wasChangedAccessKey = accessKey !== savedAccessKey;

  const fetchSubList = useCallback(async (): Promise<CmTableResponse<YaSubCloudDTO>> => {
    if (editableCloud?.id) {
      try {
        const response = await getYandexSubList(editableCloud.id);
        return {
          result: response.data,
          totalItems: response.data.length,
        };
      } catch (e) {
        handleError(e as AxiosError);
        return {
          result: [] as YaSubCloudDTO[],
          totalItems: 0,
        };
      }
    }
    return {
      result: [] as YaSubCloudDTO[],
      totalItems: 0,
    };
  }, [editableCloud?.id]);

  const fetchCloudInfo = useCallback(async () => {
    if (editableCloud?.id) {
      try {
        setIsInitLoading(true);
        const response = await getYandexCloudInfo(editableCloud.id);
        form.setValue('cloudName', response.data.name);
        form.setValue('accessKey', response.data.staticKey);
        form.setValue('bucketName', response.data.bucket);
        form.setValue('bucketCatalogName', response.data.prefix);

        setSavedAccessKey(response.data.staticKey);
      } catch (e) {
        handleError(e as AxiosError);
        return {
          result: [] as YaSubCloudDTO[],
          totalItems: 0,
        };
      } finally {
        setIsInitLoading(false);
      }
    }
  }, []);

  const columns = [
    {
      key: 'name',
      title: 'Yandex-cloud',
      width: 100,
    },
    {
      key: '',
      title: 'Сервисный аккаунт',
      width: 100,
      render: (data: YaSubCloudDTO) => {
        return data.hasServiceAccount ? 'Подключен' : 'Не подключен';
      },
    },
    {
      key: '',
      width: 100,
      render: (data: YaSubCloudDTO) => {
        return (
          <Button
            className={cx('tableButton')}
            text={data.hasServiceAccount ? 'Редактировать' : 'Добавить'}
            size="sm"
            onClick={() => setSelectedSubCloud(data)}
          />
        );
      },
    },
  ];

  const table = useCMTable<YaSubCloudDTO>(fetchSubList);

  const onSubmit = useCallback(
    async (values: YaCloudEditDTO) => {
      if (editableCloud?.id) {
        try {
          setIsLoading(true);
          if (wasChangedAccessKey && !values.secretKey) {
            form.setError('secretKey', { message: t('FieldRequired') });
            return;
          }
          const dto: YaCloudEditDTO = {
            ...values,
            cloudId: editableCloud.id,
          };

          await editYandexCloud(dto);

          toast.success({ text: t('cos.connect.cloud.edit.success') });
          selectStage({
            stage: Stages.connectedClouds,
          });
          logSuccessEvent('Edited cloud connection', {
            id: editableCloud.id,
            cloudType: CLOUD_NAME_DICTIONARY.Yandex,
          });
        } catch (e) {
          handleError(e as AxiosError);
          logErrorEvent('Edited cloud connection', {
            id: editableCloud.id,
            cloudType: CLOUD_NAME_DICTIONARY.Yandex,
          });
        } finally {
          setIsLoading(false);
        }
      }
      return values;
    },
    [t, editableCloud?.id, wasChangedAccessKey],
  );

  const onBack = useCallback(() => {
    selectStage({
      stage: Stages.connectedClouds,
    });
  }, []);

  const rowKey = useCallback((value: YaSubCloudDTO) => value.uuid, []);

  const onBackService = useCallback(() => {
    setSelectedSubCloud(undefined);
  }, []);

  useEffect(() => {
    table.fetchData();
    fetchCloudInfo();
  }, []);

  const renderedContent = useMemo(() => {
    if (selectedSubCloud && editableCloud?.id) {
      return (
        <YandexCloudServiceAccountEdit
          cloudId={editableCloud.id}
          subCloud={selectedSubCloud}
          fetchServiceAccounts={() => table.fetchData()}
          onBack={onBackService}
        />
      );
    } else {
      return (
        <Form<YaCloudEditDTO>
          formMethods={form}
          onSubmit={onSubmit}
          apiErrors={apiErrors}
        >
          <div className={cx('column')}>
            <FormInput
              name="cloudName"
              caption={tPrefix('cloudName.label')}
              placeholder={tPrefix('cloudName.placeholder')}
              hint={tPrefix('cloudName.hint')}
              disabled={isLoading}
              rules={required('FieldRequired')}
            />
            <FormInput
              name="accessKey"
              caption={tPrefix('accessKey.label')}
              placeholder={tPrefix('accessKey.placeholder')}
              hint={tPrefix('accessKey.hint')}
              disabled={isLoading}
              rules={required('FieldRequired')}
            />
            <div className={cx('row')}>
              <FormInput
                name="bucketName"
                caption={tPrefix('bucketName.label')}
                placeholder={tPrefix('bucketName.placeholder')}
                hint={tPrefix('bucketName.hint')}
                disabled={isLoading}
                rules={required('FieldRequired')}
              />
              <FormInput
                name="bucketCatalogName"
                caption={tPrefix('bucketCatalogName.label')}
                placeholder={tPrefix('bucketCatalogName.placeholder')}
                hint={tPrefix('bucketCatalogName.hint')}
                disabled={isLoading}
                rules={required('FieldRequired')}
              />
            </div>

            <div className={cx('row')}>
              <FormInput
                name="secretKey"
                caption={tPrefix('secretKey.label')}
                placeholder={tPrefix('secretKey.placeholder')}
                hint={tPrefix('secretKey.hint')}
              />
            </div>
            <div>
              <BlockTitle
                titleKey="cos.yandexConnectCloud.form.oauthToken.caption"
                hintKey="cos.yandexConnectCloud.form.oauthToken.caption.info"
              />
              <div className={cx('rowContainer', 'full')}>
                <FormInput
                  className={cx('form__input')}
                  name="oauthToken"
                  placeholder="Yandex OAuth-token"
                />
              </div>
            </div>
            {/*<CMTableProvider value={table}>*/}
            {/*  <CMTable<YaSubCloudDTO>*/}
            {/*    columns={columns}*/}
            {/*    rowKey={rowKey}*/}
            {/*    sticky*/}
            {/*  />*/}
            {/*</CMTableProvider>*/}
          </div>
        </Form>
      );
    }
  }, [selectedSubCloud, editableCloud?.id, form, apiErrors, onBack, rowKey, columns, table, t]);

  useEffect(() => {
    if (selectedSubCloud) {
      onChangeTitle(t('cos.cloudConnect.edit.service.title'));
      changeDisagreeButtonSettings({
        onClick: onBackService,
      });
    } else {
      changeDisagreeButtonSettings({
        onClick: onBack,
      });
      onChangeTitle(t('cos.cloudConnect.edit.title'));
      changeAgreeButtonSettings({
        onClick: form.handleSubmit(onSubmit),
        text: t('cos.cloudConnect.edit.save'),
        isLoading: isLoading,
      });
    }
  }, [selectedSubCloud, t, isLoading]);

  if (isInitLoading) {
    return <CloudEditLoading />;
  }

  return (
    <>
      <div>{renderedContent}</div>
    </>
  );
};

export { YandexEdit };
