import React, { cloneElement, forwardRef, useState } from 'react';

import { Tooltip, TooltipPositions } from '@kit/components';
import { Icon } from '@kit/components/Icon';
import { Loader } from '@kit/components/Loader';
import cn from 'classnames/bind';

import styles from './Input.module.scss';

type Sizes = 'sm' | 'md' | 'lg';

type Classes = {
  root?: string;
  wrapper?: string;
  controller?: string;
  input?: string;
  error?: string;
};

type IconObject = {
  icon: JSX.Element;
  alignLeft?: boolean;
};

type OwnProps = {
  classes?: Partial<Classes>;
  caption?: string;
  description?: string;
  size?: Sizes;
  descriptionOnTop?: boolean;
  errorText?: string;
  prefix?: string;
  suffix?: string;
  isError?: boolean;
  loading?: boolean;
  actions?: Array<IconObject>;
  onClear?: () => void;
  hint?: string;
  tooltipClass?: string;
  tooltipTextClass?: string;
  hintPosition?: TooltipPositions;
  controls?: JSX.Element;
};

export type Props = Omit<React.ComponentPropsWithRef<'input'>, keyof OwnProps> & OwnProps;

const Input: React.FC<Props> = forwardRef<HTMLInputElement, Props>(
  (
    {
      classes,
      hintPosition = 'top',
      value,
      size = 'lg',
      placeholder,
      caption,
      description,
      descriptionOnTop,
      isError,
      errorText,
      disabled,
      loading,
      actions,
      className,
      onFocus,
      onBlur,
      onClear,
      prefix,
      suffix,
      hint,
      tooltipClass,
      tooltipTextClass,
      children,
      ...rest
    },
    ref,
  ) => {
    const [isInFocus, setIsInFocus] = useState(false);
    const cx = cn.bind(styles);

    const descriptionNode = description && <div className={cx('description')}>{description}</div>;
    const actionsLeft: Array<JSX.Element> = [];
    const actionsRight: Array<JSX.Element> = [];
    if (actions) {
      actions.forEach((item) => {
        const icon = cloneElement(item.icon, {
          key: item.icon.type,
          ...item.icon.props,
          className: cx(item.icon.props.className, 'icon'),
        });
        item.alignLeft ? actionsLeft.push(icon) : actionsRight.push(icon);
      });
    }

    const onInputFocus = (event: React.FocusEvent<HTMLInputElement>) => {
      setIsInFocus(true);
      onFocus && onFocus(event);
    };

    const onInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      setIsInFocus(false);
      onBlur && onBlur(event);
    };

    return (
      <label
        className={cx(className, 'body', { disabled }, classes?.root)}
        data-testid="input-label"
      >
        {caption && (
          <div className={cx('caption')}>
            {caption}
            {hint && (
              <Tooltip
                text={hint}
                place={hintPosition}
                classes={{
                  tooltip: tooltipClass,
                  tooltipTextItem: tooltipTextClass,
                }}
              >
                <Icon
                  type="question-mark-circle"
                  size="md"
                  className={cx('icon', 'hint')}
                />
              </Tooltip>
            )}
          </div>
        )}
        {descriptionOnTop && descriptionNode}
        <div className={cx('wrapper', { isError, loading, isInFocus }, classes?.wrapper)}>
          {prefix && <div className={cx('prefix', `size_${size}`)}>{prefix}</div>}

          <div
            className={cx('controller', `size_${size}`, classes?.controller)}
            data-testid="input-container"
          >
            {actionsLeft}
            <input
              ref={ref}
              className={cx('field', classes?.input)}
              type="text"
              value={value}
              placeholder={placeholder}
              disabled={disabled}
              onFocus={onInputFocus}
              onBlur={onInputBlur}
              {...rest}
            />
            {actionsRight}
            {loading && <Loader className={cx('icon')} />}
            {onClear && value && (
              <Icon
                type="close"
                onClick={onClear}
                className={cx('icon', 'clear')}
                data-testid="close-icon"
              />
            )}
          </div>

          {suffix && <div className={cx('suffix', `size_${size}`)}>{suffix}</div>}
          {children && <div className={cx('extra')}>{children}</div>}
        </div>
        {isError && errorText && <div className={cx('error', classes?.error)}>{errorText}</div>}
        {!descriptionOnTop && descriptionNode}
      </label>
    );
  },
);

Input.displayName = 'Input';

export { Input };
export type { Props as InputProps, Classes as InputClasses, IconObject as InputIconObject };
