import React, { useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { AWSRegion } from '@common/components/AppToolbar/components/CloudConnect/types';
import { CloudConnectContent } from '@common/components/AppToolbar/components/CloudConnect/CloudConnectContent';

import { ACCESS_KEY, CLOUDNAME, KEYSECRET, REGION } from '@src/constants/formConstans';
import { FormInput, FormSelect, required, useAppTranslation } from '@src/common';
import { Disclaimer, SearchMenuList, SelectOption, ValueWithLabel } from '@src/kit';

import { mapToOption } from '@utils/common';

import styles from './FormAWS.module.scss';

type Props = {
  regions: AWSRegion[];
  isRegionsLoading?: boolean;
};

const FormAWSView: React.FC<Props> = ({ regions, isRegionsLoading }) => {
  const { t } = useAppTranslation();

  const listRegions: SelectOption<string>[] = useMemo(
    () => regions.map(({ name, code }) => mapToOption(code, name)),
    [regions],
  );

  const form = useFormContext();

  const region = form.watch('region');

  const handleOnChange = (e: SelectOption<string>) => {
    form.setValue('region', e);
  };

  const onMenuOpen = useCallback(() => {
    const target = document.querySelector('#aws-region > div:nth-child(3)');
    target?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <div className={styles.modal}>
      <div className={styles.container}>
        <CloudConnectContent>
          <>
            <div className={styles.info}>
              <Disclaimer text={t('infoAWS')} />
            </div>

            <div className={styles.blockInput}>
              <FormInput
                placeholder={t('nameCloud')}
                name={CLOUDNAME}
                caption={t('cloudName')}
                hint={t('AWSHintCloudName')}
                rules={{ ...required('FieldRequired') }}
              />
            </div>
            <div className={styles.blockInput}>
              <FormInput
                placeholder={t('AWSKeyIDPlaceholder')}
                name={ACCESS_KEY}
                caption={t('AccessKey')}
                hint={t('AWSHintAccessKey')}
                rules={{ ...required('FieldRequired') }}
              />
            </div>
            <div className={styles.blockInput}>
              <FormInput
                placeholder={t('AWSSecretKeyPlaceholder')}
                name={KEYSECRET}
                caption={t('SecretKey')}
                hint={t('AWSHintSecretKey')}
                rules={{ ...required('FieldRequired') }}
              />
            </div>
            <div className={styles.blockInput}>
              <FormSelect
                id="aws-region"
                value={region}
                placeholder={t('AWSRegionPlaceholder')}
                currentSelection={region?.value}
                name={REGION}
                hint={t('AWSHintRegion')}
                className={styles.formSelect}
                caption={t('Region')}
                size="lg"
                onChange={handleOnChange}
                isSearchable={false}
                options={listRegions}
                rules={{ ...required('FieldRequired') }}
                menuPlacement="bottom"
                descriptionOnTop={false}
                isLoading={isRegionsLoading}
                components={{
                  MenuList: (props: any) => {
                    useEffect(() => {
                      onMenuOpen();
                    }, []);

                    return <SearchMenuList {...props} />;
                  },
                  Option: ValueWithLabel,
                }}
              />
            </div>
          </>
        </CloudConnectContent>
      </div>
    </div>
  );
};

export { FormAWSView };
