import { useCloudsContext } from '@common/providers/CloudsProvider';
import { ConnectedClouds, NewConnection } from '@common/components/Clouds/components';

import { Button, DocsManual, H1 } from '@src/kit';
import { bindStyles, getDocsLink } from '@src/utils';
import { useAppToolbarContext, useAppTranslation } from '@src/common';

import styles from './Clouds.module.scss';

const cx = bindStyles(styles);

const Clouds = () => {
  const { t } = useAppTranslation();
  const { isNewConnection, openConnection } = useCloudsContext();
  const { brand } = useAppToolbarContext();

  return (
    <div className={cx('wrapper')}>
      <div className={cx('header')}>
        <div className={cx('header-content')}>
          <H1 size="lg">{t('CloudsConnections')}</H1>
          <DocsManual linkToManual={getDocsLink(brand, '/link-clouds/')} />
        </div>
        {!isNewConnection && (
          <Button
            text={t('settings.conClouds.newConnection.text')}
            onClick={openConnection}
            disabled={isNewConnection}
          />
        )}
      </div>
      {isNewConnection ? <NewConnection /> : <ConnectedClouds />}
    </div>
  );
};

export { Clouds };
