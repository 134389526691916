import { useForm } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types';
import { DefaultValues } from 'react-hook-form/dist/types/form';
import { useEffect } from 'react';

import { useToggleButton } from '@common/components/FilterPanel/hooks/useToggleButton';
import { useSearchField } from '@common/components/FilterPanel/hooks/useSearchField';

type Params<F extends FieldValues = FieldValues> = {
  emptyForm: F;
  searchFieldKey?: keyof F;
  onSearch: (formData: F) => Promise<void>;
  toParams: (formData: F) => Partial<Record<keyof F, string>>;
  fromParams: (rawParams: Partial<Record<keyof F, string>>) => F;
};

type UseFilterPanelReturn = ReturnType<typeof useFilterPanel>;

const useFilterPanel = <F extends FieldValues = FieldValues>(params: Params<F>) => {
  const { emptyForm, searchFieldKey, onSearch, fromParams, toParams } = params;

  const formMethods = useForm<F>();

  const { button: ToggleButton, isOpened } = useToggleButton();

  const onSubmitSearch = (value: string) => {
    if (value) {
      const form = formMethods.getValues() as F;
      onSubmit(form);
    }
  };

  const {
    SearchField,
    reset: resetSearch,
    value: valueSearchField,
  } = useSearchField({ onSubmitSearch });

  const readQueryParams = () => {
    const raw: Record<keyof F, string> = {} as Record<keyof F, string>;
    const locationSearch = new window.URLSearchParams(window.location.search);

    locationSearch.forEach((value, key) => (raw[key as keyof F] = value));

    if (raw) {
      const form = fromParams(raw);
      formMethods.reset(form as DefaultValues<F>);

      if (searchFieldKey && raw[searchFieldKey]) {
        resetSearch(raw[searchFieldKey]);
      }
    }

    const formData = createFormValue(
      formMethods.getValues() as F,
      searchFieldKey ? raw[searchFieldKey] : '',
    );

    onSearch(formData);
  };

  useEffect(() => readQueryParams(), []);

  const setQueryParams = (form: Partial<Record<keyof F, string>>) => {
    const params = new window.URLSearchParams(window.location.search);
    Object.keys(form).forEach((key) => {
      const value = form[key as keyof F];
      if (value) {
        params.append(key, value);
      }
    });
    window.history.pushState(null, '', params.toString());
  };

  const createFormValue = (form: F, searchValue: string) => {
    if (searchFieldKey) {
      return {
        ...form,
        [searchFieldKey]: searchValue,
      };
    }

    return form;
  };

  const onReset = () => {
    formMethods.reset(emptyForm as DefaultValues<F>);

    if (searchFieldKey) {
      resetSearch();
    }

    const form = formMethods.getValues() as F;
    const value = createFormValue(form, '');
    setQueryParams(toParams(value));
    onSearch(value);
  };

  const onSubmit = (form: F) => {
    const value = createFormValue(form, valueSearchField);
    setQueryParams(toParams(value));
    onSearch(value);
  };

  return {
    formMethods,
    isOpenPanel: isOpened,
    ToggleButton,
    SearchField,
    onReset,
    onSubmit,
  };
};

export { useFilterPanel };
export type { Params as UseFilterPanelParams, UseFilterPanelReturn };
