import { FC } from 'react';

import { useCloudsContext } from '@common/providers/CloudsProvider';

import { bindStyles } from '@src/utils';
import { useAppTranslation } from '@src/common';
import { Button } from '@src/kit';

import BigCloud from './BigCloud.svg';
import styles from './EmptyCloudsPlug.module.scss';

const cx = bindStyles(styles);

const EmptyCloudsPlug: FC = () => {
  const { t } = useAppTranslation();
  const { openConnection } = useCloudsContext();
  return (
    <div className={cx('wrapper', 'fade-in-3')}>
      <div className={cx('wrapper-icon')}>
        <BigCloud />
      </div>
      <div className={cx('content')}>
        <div
          className={cx('content-text')}
          dangerouslySetInnerHTML={{ __html: t('settings.conClouds.emptyPlug.text.new') }}
        />
        <Button
          onClick={openConnection}
          text={t('settings.conClouds.newConnection.text')}
        />
      </div>
    </div>
  );
};

export { EmptyCloudsPlug };
