import React from 'react';

import { CurrencyTypes, Language } from '@src/@types';

type CurrencyProps = {
  value: number;
  currency: CurrencyTypes;
  locale: Language;
  className?: string;
  valueClassName?: string;
  zeros?: boolean;
  fixed?: CurrencyTypes;
};

const Currency: React.FC<CurrencyProps> = ({
  value,
  currency,
  locale,
  className,
  valueClassName,
  zeros,
  fixed,
}) => {
  const dataArray = Intl.NumberFormat(locale, {
    style: 'currency',
    currency: fixed || currency,
    minimumFractionDigits: zeros ? 2 : 0,
    currencyDisplay: 'narrowSymbol',
  }).formatToParts(value);
  const currencyPosition = dataArray.findIndex((item) => item.type === 'currency');
  const [currencySymbol] = dataArray.splice(currencyPosition, 1);

  return (
    <div className={className}>
      {currencyPosition === 0 && <>{currencySymbol.value}&nbsp;</>}
      <span className={valueClassName}>{dataArray.map((item) => item.value).join('')}</span>
      {currencyPosition !== 0 && currencySymbol.value}
    </div>
  );
};

export type { CurrencyProps };
export { Currency };
