import React, { AnimationEventHandler, useMemo } from 'react';
import { PopupProps } from 'reactjs-popup/dist/types';

import cn from 'classnames/bind';

import { Modal, ModalProps } from '@src/kit';

import { CMHeaderProps, Header, Footer, CMModalFooterProps } from './components';
import styles from './CMModal.module.scss';

type Props = ModalProps &
  Partial<CMHeaderProps> &
  Partial<CMModalFooterProps> & {
    onAnimationEnd?: AnimationEventHandler<HTMLDivElement>;
    lockBodyScroll?: boolean;
    headerJSX?: JSX.Element;
    footerJSX?: JSX.Element;
    headerCloseVisible?: boolean;
    contentClassName?: string;
  };

const cx = cn.bind(styles);

const CMModal: React.FC<Props> = ({
  id,
  children,
  className,
  onAnimationEnd,
  lockBodyScroll = true,
  headerCloseVisible = true,
  open,
  title,
  agreeButtonSettings,
  disagreeButtonSettings,
  headerJSX,
  contentClassName,
  footerClassName,
  headerClassName,
  footerJSX,
  onClose,
  closeOnEscape = true,
  closeOnDocumentClick = true,
  ...rest
}) => {
  const showFooter = useMemo(() => {
    return (
      !footerJSX &&
      ((agreeButtonSettings?.text && agreeButtonSettings.onClick) ||
        (disagreeButtonSettings?.text && disagreeButtonSettings.onClick))
    );
  }, [agreeButtonSettings, disagreeButtonSettings, footerJSX]);

  return (
    <Modal
      {...rest}
      id={id}
      className={cx(className, 'wrapper')}
      onClose={onClose}
      open={open}
      closeOnEscape={closeOnEscape}
      closeOnDocumentClick={closeOnDocumentClick}
      onAnimationEnd={onAnimationEnd}
      lockBodyScroll={lockBodyScroll}
    >
      {!headerJSX && title && (
        <Header
          title={title}
          headerClassName={headerClassName}
          onClose={headerCloseVisible ? onClose : undefined}
        />
      )}
      {headerJSX}

      <div className={cx(contentClassName, 'content')}>{children}</div>

      {showFooter && (
        <Footer
          agreeButtonSettings={agreeButtonSettings || {}}
          disagreeButtonSettings={disagreeButtonSettings || {}}
          footerClassName={footerClassName}
        />
      )}
      {footerJSX}
    </Modal>
  );
};

export { CMModal };
export type { Props as CMModalProps };
