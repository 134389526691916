import { createContext, useContext } from 'react';

import { CloudInfoVariant } from '@common/components/Clouds/components/NewConnection/CloudInfo';
import { ConnectionSteps } from '@common/components/Clouds/components';
import { StatusInfoVariant } from '@common/components/Clouds/components/NewConnection/StatusInfo';

import { CloudTypes } from '@src/@types';

export type CloudInfoSettings = {
  cloudName?: string;
  variant: CloudInfoVariant;
};

export type StatusInfoSettings = {
  cloudName?: string;
  variant: StatusInfoVariant;
};

type NewConnectionProviderState = {
  onBack: () => void;
  onNext: () => void;
  changeCloudInfoSettings: (settings: CloudInfoSettings) => void;
  changeStatusInfoSettings: (settings: StatusInfoSettings) => void;
  currentStep: ConnectionSteps;
  setCurrentStep: (step: ConnectionSteps) => void;
  selectedProvider: CloudTypes | null;
  noNeedPolicy: string[];
  setSelectedProvider: (provider: CloudTypes | null) => void;
  statusInfoSettings: StatusInfoSettings;
};

const NewConnectionContext = createContext({} as NewConnectionProviderState);

const useNewConnectionContext = () => useContext(NewConnectionContext);

export { NewConnectionContext, useNewConnectionContext };
