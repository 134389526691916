import { IRestService, RestService } from '@common/services';

import { NotificationDTO, NotificationId } from '@src/common';
import { ApiResultResponse } from '@src/@types';
import { CustomError } from '@src/utils';

import { EventDetailDTO, TaskId } from './models';

class NotificationApi {
  static restService: IRestService = RestService.getInstance();

  static fetchList(page: number) {
    const url = `/notifier/notification/log/${page}`;
    return this.restService.GET<NotificationDTO[]>(url);
  }

  static markAsRead(ids: NotificationId[]) {
    const url = '/notifier/notification';
    return this.restService.POST<NotificationId[], void>(url, { data: ids });
  }

  static receivingNotifications() {
    const url = '/notifier/notification/unread';
    return this.restService.GET<number>(url);
  }

  static async getTaskDetail(taskId: TaskId) {
    const url = `msp/events/linkId/${taskId}`;
    const res = await this.restService.GET<ApiResultResponse<EventDetailDTO>>(url);
    if (!res.data.success) {
      throw new CustomError(res.data.errorCode, res.data.errorMessage);
    }
    return res;
  }
}

export { NotificationApi };
