import React from 'react';

import { Button } from '@kit/components';
import { useCMTableContext } from '@common/components/CMTable/context/CMTable.context';

import { bindStyles } from '@src/utils';
import { CmTableOperation } from '@src/common';

import styles from './Operations.module.scss';

const cx = bindStyles(styles);

const Operations: React.FC = () => {
  const { selection, fetchData } = useCMTableContext();

  return (
    <div className={cx('operations')}>
      {(selection?.operations || []).map(
        ({
          id,
          action,
          text,
          alignRight,
          disabled,
          icon,
          clearSelected,
          reFetch = true,
        }: CmTableOperation) => {
          const onClick = async () => {
            await action();
            reFetch && void fetchData();
            clearSelected && selection?.dropSelection();
          };
          return (
            <Button
              className={cx('operationItem', { right: alignRight })}
              key={id}
              variant="text"
              size="md"
              text={text}
              onClick={onClick}
              disabled={disabled}
            >
              {icon ?? null}
            </Button>
          );
        },
      )}
    </div>
  );
};

export { Operations };
