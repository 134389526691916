import React from 'react';

import cn from 'classnames/bind';

import styles from './ModalContent.module.scss';

type Props = {
  className?: string;
};

const ModalContent: React.FC<Props> = ({ className, children }) => {
  const cx = cn.bind(styles);
  return <div className={cx('modalContent', className)}>{children}</div>;
};

export { ModalContent };
