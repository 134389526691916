import React from 'react';

type LinkProps = {
  className?: string;
  underline?: boolean;
  mailto?: string;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  to?: string;
};

const Link: React.FC<LinkProps> = ({
  disabled,
  children,
  underline,
  mailto,
  onClick,
  to,
  ...restProps
}) => {
  const onLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (mailto) {
      window.location = mailto as unknown as Location;
      event.preventDefault();
    }
    onClick && onClick(event);
  };

  return (
    <a
      href={to}
      {...restProps}
      onClick={onLinkClick}
    >
      {children}
    </a>
  );
};

export { Link };
export type { LinkProps };
