import React, { forwardRef, useEffect, useRef } from 'react';

import { Icon } from '@kit/components';
import { Input } from '@kit/components/Input';

import { bindStyles } from '@src/utils';

import { floatNumberSpacing } from '@utils/common';

import styles from './InputNumber.module.scss';
import { InputProps } from '../Input';

const NOT_AVAILABLE_SYMBOLS_REGEXP = /[^0-9.]/g;
const INT_FORMAT_REGEXP = /\d+/g;
// const DECIMAL_FORMAT_REGEXP = /\d+(?:\.\d{0,2})?/g;
const DECIMAL_FORMAT_REGEXP = (n: number) => new RegExp(`\\d+(?:\\.\\d{0,${n}})?`, 'g');

type OwnProps = InputProps & {
  decimals?: number;
  withControls?: boolean;
  units?: string;
};

type Props = Omit<React.ComponentPropsWithRef<'input'>, keyof OwnProps> & OwnProps;

const cx = bindStyles(styles);

const InputNumber: React.FC<Props> = forwardRef<HTMLInputElement, Props>(
  (
    {
      value = '',
      onChange,
      onBlur,
      decimals = 0,
      withControls,
      units,
      size = 'lg',
      placeholder,
      ...restOptions
    },
    ref,
  ) => {
    const numberFormat = decimals ? DECIMAL_FORMAT_REGEXP(decimals) : INT_FORMAT_REGEXP;

    const timer = useRef<ReturnType<typeof setTimeout>>();

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value.replace(NOT_AVAILABLE_SYMBOLS_REGEXP, '');
      event.target.value = value.match(numberFormat)?.[0] ?? '';

      onChange?.(event);
    };

    const onInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      if (value) {
        event.target.value = parseFloat((value || '') as string).toString();
        onChange?.(event);
      }

      onBlur?.(event);
    };

    const inc = (value: number) => value + 1;

    const dec = (value: number) => Math.max(value - 1, 0);

    const recurseUpdate = (
      event: React.MouseEvent,
      value: string,
      delay: number,
      callback: (value: number) => number,
    ) => {
      const newValue = callback(parseFloat(value || '0')).toString();
      const newEvent = { ...event, target: { ...event.target, value: newValue } };
      onChange?.(newEvent as unknown as React.ChangeEvent<HTMLInputElement>);
      if (newValue === '0') return;
      timer.current = setTimeout(() => recurseUpdate(event, newValue, 100, callback), delay);
    };

    const onInc = (event: React.MouseEvent) => {
      recurseUpdate(event, value as string, 500, inc);
    };

    const onDec = (event: React.MouseEvent) => {
      recurseUpdate(event, value as string, 500, dec);
    };

    const onStop = () => {
      timer.current && clearTimeout(timer.current);
    };

    useEffect(() => {
      return () => {
        onStop();
      };
    }, []);

    const valueAsString = floatNumberSpacing(
      (typeof value === 'undefined' || value === null || Number.isNaN(value)
        ? ''
        : value) as string,
    );

    const inputPlaceholder = placeholder ?? (decimals && `0.${'0'.repeat(decimals)}`);

    return (
      <Input
        data-testid="inputNumber"
        ref={ref}
        value={valueAsString}
        onChange={onInputChange}
        onBlur={onInputBlur}
        size={size}
        placeholder={inputPlaceholder as string}
        {...restOptions}
        type="text"
      >
        {withControls && (
          <div className={cx('container')}>
            {units && <div className={cx('units')}>{units}</div>}
            <div className={cx('controls', size)}>
              <button
                type="button"
                className={cx('button')}
                onMouseDown={onInc}
                onMouseUp={onStop}
                onMouseLeave={onStop}
              >
                <Icon
                  className={cx('icon', 'rotate')}
                  type="chevron-down"
                  size="xs"
                />
              </button>
              <button
                type="button"
                className={cx('button')}
                onMouseDown={onDec}
                onMouseUp={onStop}
                onMouseLeave={onStop}
              >
                <Icon
                  className={cx('icon')}
                  type="chevron-down"
                  size="xs"
                />
              </button>
            </div>
          </div>
        )}
      </Input>
    );
  },
);

InputNumber.displayName = 'InputNumber';

export { InputNumber };
export type { Props as InputNumberProps };
