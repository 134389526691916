import React, { PropsWithChildren } from 'react';

import { ErrorBoundary } from '@src/common';
import { Brand } from '@src/@types';

type Props = {
  authUrl?: string;
  authStorageKey?: string;
  excludeErrorUrls?: string[];
  brand?: Brand;
};

const ErrorBoundaryProvider: React.FC<PropsWithChildren<Props>> = ({
  children,
  authUrl,
  authStorageKey,
  excludeErrorUrls,
}) => {
  return (
    <ErrorBoundary
      authUrl={authUrl}
      authStorageKey={authStorageKey}
      excludeErrorUrls={excludeErrorUrls}
    >
      {children}
    </ErrorBoundary>
  );
};

export { ErrorBoundaryProvider };
