import { FC } from 'react';

import { SettingsWrapper } from '@common/components/SettingsWrapper';
import { LanguageSelect } from '@common/components/CurrenciesLanguageComponent/LanguageSelect';
import { CurrencySelect } from '@common/components/CurrenciesLanguageComponent/CurrencySelect';

import { bindStyles } from '@src/utils';
import { Brand } from '@src/@types';

import styles from './CurrenciesLanguage.module.scss';

const cx = bindStyles(styles);

type Props = {
  brand: Brand;
};

const CurrenciesLanguage: FC<Props> = ({ brand }) => {
  return (
    <SettingsWrapper settingsPage="currencyLanguage">
      <div className={cx('wrapper')}>
        <div className={cx('wrapper-content')}>
          {brand === 'cloudmaster' && <LanguageSelect brand={brand} />}
          <div className={cx('hr')} />
          <CurrencySelect />
        </div>
      </div>
    </SettingsWrapper>
  );
};

export { CurrenciesLanguage };
