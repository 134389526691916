import { FC, ReactNode, useEffect, useMemo, useState } from 'react';

import { PROVIDER_LIST } from '@common/components/AppToolbar/components/CloudConnect/constants';
import {
  AWSConnection,
  VCloudDirectorConnection,
} from '@common/components/Clouds/components/NewConnection/Forms';
import { useCloudsContext } from '@common/providers/CloudsProvider';
import {
  CloudInfo,
  CloudInfoVariant,
} from '@common/components/Clouds/components/NewConnection/CloudInfo';
import { YandexConnection } from '@common/components/Clouds/components/NewConnection/Forms/YandexConnection';
import { VSphereConnection } from '@common/components/Clouds/components/NewConnection/Forms/VSphereConnection';
import { OpenStackConnection } from '@common/components/Clouds/components/NewConnection/Forms/OpenStackConnection';
import { HyperVConnection } from '@common/components/Clouds/components/NewConnection/Forms/HyperVConnection';
import { AzureConnection } from '@common/components/Clouds/components/NewConnection/Forms/AzureConnection';
import { Stepper } from '@common/components/Clouds/components/NewConnection/Stepper';
import { StatusInfoVariant } from '@common/components/Clouds/components/NewConnection/StatusInfo';

import { bindStyles, getDocsLink } from '@src/utils';
import { CloudTypes } from '@src/@types';
import { Button, Icon, ProviderMiniCard } from '@src/kit';
import { CLOUD_ICON_DICTIONARY, CLOUD_NAME_DICTIONARY } from '@src/constants';
import { useAnalytics, useAppToolbarContext, useAppTranslation } from '@src/common';

import styles from './NewConnection.module.scss';
import Astro from './assets/Astro.svg';
import AstroAstrokite from './assets/AstroAstrokite.svg';
import CloudSmall from './assets/Cloud0.svg';
import CloudLeft from './assets/Cloud1.svg';
import CloudRight from './assets/Cloud9.svg';
import {
  CloudInfoSettings,
  NewConnectionContext,
  StatusInfoSettings,
} from './NewConnectionContext';

export enum ConnectionSteps {
  PROVIDER = 'provider',
  SETTINGS = 'settings',
  POLICY = 'policy',
  STATUS = 'status',
}

const cx = bindStyles(styles);

const PROVIDERS_FORMS: Record<typeof PROVIDER_LIST[0]['provider'], ReactNode> = {
  vCloudDirector: <VCloudDirectorConnection />,
  AWS: <AWSConnection />,
  Azure: <AzureConnection />,
  GCP: null,
  NONCLOUD: null,
  HyperV: <HyperVConnection />,
  Nutanix: null,
  OpenStack: <OpenStackConnection />,
  vkCloudSolutions: null,
  vSphere: <VSphereConnection />,
  Yandex: <YandexConnection />,
};

const SECRET_FIELD_EDIT_PLACEHOLDER = '*****************';

const NewConnection: FC = () => {
  const { t } = useAppTranslation();
  const { closeConnection, editableCloud, cloudEdit, changeEditableCloud, setCloudEdit } =
    useCloudsContext();
  const [currentStep, setCurrentStep] = useState(ConnectionSteps.PROVIDER);
  const [cloudInfoSettings, setCloudInfoSettings] = useState<CloudInfoSettings>({
    variant: CloudInfoVariant.INFO,
  });
  const [statusInfoSettings, setStatusInfoSettings] = useState<StatusInfoSettings>({
    variant: StatusInfoVariant.LOADING,
  });
  const [selectedProvider, setSelectedProvider] = useState<CloudTypes | null>(null);
  const { logEvent } = useAnalytics();
  const { brand } = useAppToolbarContext();

  const onCardClick = (provider: CloudTypes) => {
    setSelectedProvider(provider);
    setCurrentStep(ConnectionSteps.SETTINGS);
    logEvent('Selected cloud connection', { cloudType: provider });
  };

  useEffect(() => {
    if (cloudEdit) {
      setSelectedProvider(editableCloud?.cloudType as CloudTypes);
      setCurrentStep(ConnectionSteps.SETTINGS);
    }
  }, [cloudEdit, editableCloud]);

  const renderedProviders = useMemo(() => {
    return PROVIDER_LIST.filter(
      ({ provider }) => !(brand === 'astrokite' && provider === 'Yandex'),
    ).map(({ provider, disabled, version }) => (
      <div
        key={provider}
        className={cx('providers-block')}
      >
        <ProviderMiniCard
          selected={provider === selectedProvider}
          disabled={disabled}
          onClick={() => onCardClick(provider)}
        >
          {CLOUD_ICON_DICTIONARY[provider] && (
            <div className={cx('iconContainer')}>{CLOUD_ICON_DICTIONARY[provider]}</div>
          )}
          <div className={cx('texts')}>
            <div className={cx('minicardContent')}>{CLOUD_NAME_DICTIONARY[provider]}</div>
            {version && <div className={cx('version')}>{t(version)}</div>}
          </div>
        </ProviderMiniCard>
      </div>
    ));
  }, [t]);

  const renderedProviderForm = useMemo(() => {
    if (selectedProvider) {
      return PROVIDERS_FORMS[selectedProvider];
    }
  }, [selectedProvider]);

  const noNeedPolicy = ['Azure', 'AWS', 'Yandex', 'vCloudDirector'];

  const onBack = () => {
    switch (currentStep) {
      case ConnectionSteps.PROVIDER:
        closeConnection();
        setCloudInfoSettings({ variant: CloudInfoVariant.INFO });
        break;
      case ConnectionSteps.SETTINGS:
        cloudEdit ? closeConnection() : setCurrentStep(ConnectionSteps.PROVIDER);
        cloudEdit && setCloudEdit(false);
        cloudEdit && changeEditableCloud(null);
        setSelectedProvider(null);
        setCloudInfoSettings({ variant: CloudInfoVariant.INFO });
        break;
      case ConnectionSteps.POLICY:
        setCurrentStep(ConnectionSteps.SETTINGS);
        setCloudInfoSettings({ variant: CloudInfoVariant.INFO });
        break;
      case ConnectionSteps.STATUS:
        setCurrentStep(
          noNeedPolicy.includes(selectedProvider as string)
            ? ConnectionSteps.SETTINGS
            : ConnectionSteps.POLICY,
        );
        break;
    }
  };

  const onNext = () => {
    switch (currentStep) {
      case ConnectionSteps.SETTINGS:
        setCurrentStep(
          noNeedPolicy.includes(selectedProvider as string)
            ? ConnectionSteps.STATUS
            : ConnectionSteps.POLICY,
        );
        setCloudInfoSettings({ variant: CloudInfoVariant.INFO });
        break;
      case ConnectionSteps.POLICY:
        setCurrentStep(ConnectionSteps.STATUS);
        setCloudInfoSettings({ variant: CloudInfoVariant.INFO });
        break;
      case ConnectionSteps.STATUS:
        closeConnection();
        cloudEdit && setCloudEdit(false);
        cloudEdit && changeEditableCloud(null);
        setSelectedProvider(null);
        setCloudInfoSettings({ variant: CloudInfoVariant.INFO });
        break;
    }
  };

  const onButtonClick = () => {
    window.open(getDocsLink(brand, '/link-clouds/'), '_blank');
  };
  const onHeaderClose = () => {
    closeConnection();
    changeEditableCloud(null);
    setCloudEdit(false);
  };
  return (
    <NewConnectionContext.Provider
      value={{
        onBack,
        onNext,
        changeCloudInfoSettings: setCloudInfoSettings,
        changeStatusInfoSettings: setStatusInfoSettings,
        currentStep,
        setCurrentStep,
        selectedProvider,
        setSelectedProvider,
        noNeedPolicy,
        statusInfoSettings,
      }}
    >
      <div className={cx('container')}>
        <div className={cx('wrapper', { nonSelected: !selectedProvider })}>
          <div className={cx('header')}>
            <div className={cx('headerTopContent', { selected: selectedProvider })}>
              <div className={cx('headerTitle')}>
                <div className={cx('headerBackTitle')}>
                  <Button
                    className={cx('button')}
                    variant="text"
                    onClick={onHeaderClose}
                  >
                    <Icon type="chevron-left" />
                  </Button>
                  {t('settings.conClouds.newConnection.header.title')}
                </div>
                {cloudEdit && (
                  <div className={cx('headerEditTitle')}>
                    {t('settings.conClouds.newConnection.header.editTitle')}
                  </div>
                )}
              </div>
              <Button
                className={cx('button')}
                variant="text"
                onClick={onHeaderClose}
              >
                <Icon type="close" />
              </Button>
            </div>
            {selectedProvider && (
              <div className={cx('stepper')}>
                <Stepper />
              </div>
            )}
          </div>
          <div className={cx('content-wrapper')}>
            {!selectedProvider && (
              <div className={cx('contentTitle')}>
                {t('settings.conClouds.newConnection.providers.title')}
              </div>
            )}
            <div className={cx('content', { providers: !selectedProvider })}>
              {selectedProvider ? renderedProviderForm : renderedProviders}
            </div>
            {!selectedProvider && (
              <div className={cx('content-manual')}>
                <div className={cx('content-manual-block-blue')} />
                <div className={cx('content-manual-block-white')} />
                <div className={cx('content-manual-cloud-left')} />
                <div className={cx('content-manual-cloud-right')} />
                <div className={cx('content-manual-cloud-center')} />
                <div className={cx('content-manual-cloud-centerRight')} />
                <div className={cx('content-manual-astro')}>
                  {brand === 'cloudmaster' ? <Astro /> : <AstroAstrokite />}
                </div>
                <div className={cx('content-manual-svg-small')}>
                  <CloudSmall />
                </div>
                <div className={cx('content-manual-svg-left')}>
                  <CloudLeft />
                </div>
                <div className={cx('content-manual-svg-right')}>
                  <CloudRight />
                </div>
                <div className={cx('content-manual-welcome')}>
                  <div className={cx('content-manual-welcome-text')}>
                    {t('clouds.connection.manual.header')}
                  </div>
                  <Button
                    text={t('clouds.connection.manual.button')}
                    className={cx('content-manual-welcome-button')}
                    onClick={onButtonClick}
                    variant="outline"
                  >
                    <Icon
                      type="book"
                      className={cx('content-manual-welcome-button-img')}
                    />
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
        {selectedProvider && (
          <CloudInfo
            variant={cloudInfoSettings.variant}
            cloudName={cloudInfoSettings.cloudName}
            cloudType={selectedProvider || 'vCloudDirector'}
          />
        )}
      </div>
    </NewConnectionContext.Provider>
  );
};

export { NewConnection, SECRET_FIELD_EDIT_PLACEHOLDER };
