import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

import {
  Stages,
  VCloudDirectorFetchProfilesDTO,
  VCloudDirectorFormDTO,
} from '@common/components/AppToolbar/components/CloudConnect/types';
import {
  editVCloudInfo,
  fetchProfiles,
  getUsedResources,
  getVCloudInfo,
} from '@common/components/AppToolbar/api/cloud-connections';
import { AxiosError } from 'axios';
import { ProfileItem } from '@common/components/AppToolbar/components/CloudConnect/ProfileItem';
import { CloudConnectContent } from '@common/components/AppToolbar/components/CloudConnect/CloudConnectContent';
import { CloudEditLoading } from '@common/components/AppToolbar/components/CloudConnect/CloudEdit/CloudEditLoading';

import { gbToMb, mbToGb, bindStyles } from '@src/utils';
import {
  Form,
  FormInput,
  required,
  ResourceUsedSizeDto,
  StorageProfileEditDTO,
  urlPattern,
  useAnalytics,
  useCloudConnectContext,
  useCurrencyContext,
  useFormApiError,
  useToast,
  useTranslationPrefix,
  VCloudEditDTO,
} from '@src/common';
import { Button, Disclaimer, Icon } from '@src/kit';
import { Gb } from '@src/@types';
import { CLOUD_NAME_DICTIONARY } from '@src/constants';

import styles from './VCloudEdit.module.scss';

const cx = bindStyles(styles);

const MIN_VALUE = 0.01;

const VCloudEdit: FC = () => {
  const [isLoadProfileLoading, setLoadProfileLoading] = useState(false);
  const [isFormLoading, setFormLoading] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [resourceUsed, setResourceUsed] = useState<ResourceUsedSizeDto[]>();
  const [isQuotaError, setQuotaError] = useState<boolean>();
  const { logSuccessEvent, logErrorEvent } = useAnalytics();
  const toast = useToast();

  const form = useForm<VCloudDirectorFormDTO>({
    mode: 'onBlur',
    defaultValues: {
      storageProfiles: [],
    },
  });
  const formValues = form.watch();
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'storageProfiles',
  });
  const {
    selectStage,
    changeAgreeButtonSettings,
    changeDisagreeButtonSettings,
    onChangeTitle,
    editableCloud,
  } = useCloudConnectContext();

  const onBackHandler = () => {
    selectStage({
      stage: Stages.connectedClouds,
    });
  };
  const { t, tPrefix } = useTranslationPrefix('cos.cloud.connect.cloudDirector.');
  const { customerCurrency, currenciesList } = useCurrencyContext();
  const suffix = currenciesList.find((it) => it.code === customerCurrency)?.name;
  const { apiErrors, handleError } = useFormApiError();

  const requiredError = useMemo(() => t('FieldRequired'), [t]);

  const loadProfiles = useCallback(async () => {
    try {
      setLoadProfileLoading(true);

      !formValues.username && form.setError('username', { message: requiredError });
      !formValues.vcloudUrl && form.setError('vcloudUrl', { message: requiredError });
      !formValues.tenantAlias && form.setError('tenantAlias', { message: requiredError });
      !formValues.password && form.setError('password', { message: requiredError });

      if (
        !formValues.username ||
        !formValues.vcloudUrl ||
        !formValues.tenantAlias ||
        !formValues.password
      ) {
        return;
      }

      const dto: VCloudDirectorFetchProfilesDTO = {
        password: formValues.password,
        vcloudUrl: formValues.vcloudUrl,
        tenantAlias: formValues.tenantAlias,
        username: formValues.username,
      };
      const res = await fetchProfiles(dto);
      res.data.storageProfiles.forEach((it) => {
        if (!formValues.storageProfiles?.some((field) => field.key === it)) {
          append(
            {
              key: it,
              value: '',
            },
            {
              shouldFocus: false,
            },
          );
        }
      });
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setLoadProfileLoading(false);
    }
  }, [formValues, requiredError, fields]);

  const checkQuotaError = (storageProfiles: StorageProfileEditDTO[]): boolean => {
    const checkQuota = storageProfiles.some((profile) => {
      const res = resourceUsed?.find((item) => item.name === profile.name);
      if (res && profile?.maxSize && res?.usedSize > mbToGb(profile?.maxSize)) {
        return true;
      }
    });
    setQuotaError(checkQuota);
    return checkQuota;
  };

  const onSubmit = useCallback(
    async (dto: VCloudDirectorFormDTO) => {
      try {
        setIsSubmitLoading(true);
        const storageProfiles: StorageProfileEditDTO[] =
          dto.storageProfiles?.map((item) => {
            return {
              name: item.key,
              price: Number(item.value),
              maxSize: item.maxSize.length ? gbToMb(Number(item.maxSize) as Gb) : undefined,
            };
          }) || [];

        let data = {} as VCloudEditDTO;

        if (editableCloud?.id) {
          data = {
            cloudId: editableCloud.id,
            vcloudUrl: dto.vcloudUrl,
            username: dto.username,
            name: dto.name,
            diskGbPrice: Number(dto.diskGbPrice),
            cpuPrice: Number(dto.cpuPrice),
            memoryGbPrice: Number(dto.memoryGbPrice),
            tenantAlias: dto.tenantAlias,
            storageProfiles: storageProfiles,
          };

          if (dto.password) {
            data.password = dto.password;
          }
        }
        if (!checkQuotaError(data?.storageProfiles || [])) {
          await editVCloudInfo(data);

          toast.success({ text: t('cos.connect.cloud.edit.success') });
          selectStage({
            stage: Stages.connectedClouds,
          });
          logSuccessEvent('Edited cloud connection', {
            id: editableCloud?.id,
            cloudType: CLOUD_NAME_DICTIONARY.vCloudDirector,
          });
        }
      } catch (e) {
        handleError(e as AxiosError);
        logErrorEvent('Edited cloud connection', {
          id: editableCloud?.id,
          cloudType: CLOUD_NAME_DICTIONARY.vCloudDirector,
        });
      } finally {
        setIsSubmitLoading(false);
      }
    },
    [editableCloud, isQuotaError, resourceUsed],
  );

  const addProfile = useCallback(() => {
    append(
      {
        key: '',
        value: '',
        maxSize: '',
      },
      {
        shouldFocus: false,
      },
    );
  }, []);

  const fetchVCloudInfo = useCallback(async () => {
    if (editableCloud?.id) {
      try {
        await getUsedResources(editableCloud?.id).then((usedResources) => {
          setResourceUsed(
            usedResources.data.result.map((item) => {
              return { name: item.name, usedSize: Math.ceil(mbToGb(item.usedSize)) };
            }),
          );
        });
        setFormLoading(true);
        const response = await getVCloudInfo(editableCloud.id);
        form.setValue('name', response.data.name);
        form.setValue('vcloudUrl', response.data.vcloudUrl);
        form.setValue('username', response.data.username);
        form.setValue('tenantAlias', response.data.tenantAlias);
        form.setValue('cpuPrice', String(response.data.cpuPrice));
        form.setValue('memoryGbPrice', String(response.data.memoryGbPrice));
        form.setValue('diskGbPrice', String(response.data.diskGbPrice));
        if (response.data.storageProfiles) {
          response.data.storageProfiles.forEach((item) => {
            if (item) {
              append(
                {
                  value: String(item.price),
                  key: item.name,
                  maxSize: item.maxSize ? String(mbToGb(item.maxSize)) : undefined,
                },
                {
                  shouldFocus: false,
                },
              );
            }
          });
        }
      } catch (e) {
        handleError(e as AxiosError);
      } finally {
        setFormLoading(false);
      }
    }
  }, []);

  useEffect(() => {
    fetchVCloudInfo();
  }, []);

  const renderedProfiles = useMemo(() => {
    return fields.map((it, index) => (
      <ProfileItem
        key={it.id}
        setValue={form.setValue}
        onDelete={() => remove(index)}
        position={index}
        register={form.register}
        watch={form.watch}
        errors={form.formState.errors}
        clearErrors={form.clearErrors}
        otherValues={formValues.storageProfiles}
      />
    ));
  }, [form.formState.errors, fields, formValues]);

  useEffect(() => {
    changeDisagreeButtonSettings({
      onClick: onBackHandler,
    });
    changeAgreeButtonSettings({
      onClick: form.handleSubmit(onSubmit),
      isLoading: isSubmitLoading,
      text: tPrefix('button.edit'),
    });
    onChangeTitle(t('cos.cloudConnect.edit.azure.title'));
  }, [isSubmitLoading, onSubmit]);

  const minValidation = useMemo(
    () => ({
      value: MIN_VALUE,
      message: t('cos.price.min.value.error', { value: MIN_VALUE }),
    }),
    [t],
  );

  if (isFormLoading) {
    return <CloudEditLoading />;
  }

  return (
    <Form<VCloudDirectorFormDTO>
      formMethods={form}
      onSubmit={onSubmit}
      apiErrors={apiErrors}
    >
      <div className={cx('modalContainer')}>
        <CloudConnectContent>
          <div>
            <div className={cx('connectionBlock')}>
              <FormInput
                name="name"
                rules={{ ...required('FieldRequired') }}
                caption={tPrefix('name.caption')}
                placeholder={tPrefix('name.placeholder')}
                hint={tPrefix('name.hint')}
                disabled={isFormLoading}
              />
            </div>
            <div className={cx('connectionBlock')}>
              <FormInput
                name="vcloudUrl"
                caption={tPrefix('vcloudUrl.caption')}
                rules={{ ...required('FieldRequired'), ...urlPattern('InputFormatError') }}
                placeholder={tPrefix('vcloudUrl.placeholder')}
                hint={tPrefix('vcloudUrl.hint')}
                disabled={isFormLoading}
              />
            </div>
            <div className={cx('connectionBlock')}>
              <FormInput
                name="tenantAlias"
                caption={tPrefix('tenantAlias.caption')}
                rules={{ ...required('FieldRequired') }}
                placeholder={tPrefix('tenantAlias.placeholder')}
                hint={tPrefix('tenantAlias.hint')}
                disabled={isFormLoading}
              />
            </div>
            <div className={cx('connectionBlock')}>
              <div className={cx('blockContent')}>
                <FormInput
                  name="username"
                  caption={tPrefix('username.caption')}
                  rules={{ ...required('FieldRequired') }}
                  placeholder={tPrefix('username.placeholder')}
                  hint={tPrefix('username.hint')}
                  disabled={isFormLoading}
                />
                <FormInput
                  name="password"
                  type="password"
                  caption={tPrefix('password.caption')}
                  placeholder={tPrefix('password.placeholder')}
                  autoComplete="new-password"
                  hint={tPrefix('password.hint')}
                  disabled={isFormLoading}
                />
              </div>
            </div>
            <div className={cx('connectionBlock')}>
              <div className={cx('blockTitle')}>{tPrefix('PricingPolicySetup')}</div>
              <div className={cx('blockContent')}>
                <FormInput
                  type="number"
                  decimals={2}
                  name="cpuPrice"
                  caption={tPrefix('cpuPrice.caption')}
                  rules={{ ...required('FieldRequired'), min: minValidation }}
                  placeholder={tPrefix('cpuPrice.placeholder', { suffix })}
                  suffix={suffix}
                  hint={t('CpuCostHint')}
                  disabled={isFormLoading}
                />

                <FormInput
                  type="number"
                  decimals={2}
                  name="memoryGbPrice"
                  caption={tPrefix('memoryGbPrice.caption')}
                  rules={{ ...required('FieldRequired'), min: minValidation }}
                  placeholder={tPrefix('memoryGbPrice.placeholder', { suffix })}
                  suffix={suffix}
                  hint={t('RamCostHint')}
                  disabled={isFormLoading}
                />

                <FormInput
                  type="number"
                  decimals={2}
                  name="diskGbPrice"
                  caption={tPrefix('diskGbPrice.caption')}
                  rules={{ ...required('FieldRequired'), min: minValidation }}
                  placeholder={tPrefix('diskGbPrice.placeholder', { suffix })}
                  suffix={suffix}
                  hint={t('HddCostHint')}
                  disabled={isFormLoading}
                />
              </div>
            </div>

            <div className={cx('blockTitle', { withBorder: !fields.length })}>
              {tPrefix('ProfilesTitle')}
            </div>
            {Boolean(fields.length) && <div className={cx('items')}>{renderedProfiles}</div>}
            {isQuotaError && (
              <Disclaimer
                variant="danger"
                text={
                  <>
                    <div>{tPrefix('error.diskSize.quota')}</div>
                    <div>{tPrefix('error.diskSize.allocate')}</div>
                    {resourceUsed?.map((item, index) => {
                      return <div key={index}>{`${item.name} - ${item.usedSize}`}</div>;
                    })}
                  </>
                }
              />
            )}
            <div className={cx('buttonsContainer')}>
              <Button
                variant="text"
                textClassName={cx('appendButtonText')}
                text={tPrefix('add.profile')}
                className={cx('addButton')}
                onClick={addProfile}
                disabled={isFormLoading}
              >
                <div className={cx('buttonContent')}>
                  <Icon
                    type="close"
                    className={cx('plusIcon')}
                  />
                </div>
              </Button>
              <Button
                variant="outline"
                text={tPrefix('load.profiles')}
                onClick={loadProfiles}
                isLoading={isLoadProfileLoading}
                disabled={isFormLoading}
              />
            </div>
          </div>
        </CloudConnectContent>
      </div>
    </Form>
  );
};

export { VCloudEdit };
