import React from 'react';

import { Button } from '@kit/components/Button';
import { Icon } from '@kit/components/Icon';
import { Select } from '@kit/components/Select';
import { SelectOption } from '@kit/components/Select/Select';

import { bindStyles } from '@src/utils';

import { mapToOption } from '@utils/common';

import styles from './Pagination.module.scss';

const cx = bindStyles(styles);

type PageSizeConfig = {
  canChange?: boolean;
  value: number;
  onChange?: (pageSize: number) => void;
  availableValues?: SelectOption<number>[];
};

type Props = {
  totalItems: number;
  page: number;
  pageSizeConfig: PageSizeConfig;
  onChangePage: (page: number) => void;
  pageFromText: string;
  pageFromTextElement?: string;
  pageFromTextLast?: string;
  itemsPerPageText?: string;
};

const DEFAULT_PAGE_SIZE_OPTIONS = [
  { label: 15, value: 15 },
  { label: 25, value: 25 },
  { label: 50, value: 50 },
];

const Pagination: React.FC<Props> = ({
  totalItems,
  pageSizeConfig,
  page,
  onChangePage: changePage,
  pageFromText,
  pageFromTextElement,
  pageFromTextLast,
  itemsPerPageText,
}) => {
  const {
    value: pageSize,
    availableValues = DEFAULT_PAGE_SIZE_OPTIONS,
    canChange,
    onChange: changePageSize,
  } = pageSizeConfig;

  const totalPages = Math.ceil(totalItems / pageSize);

  const pageForView = page + 1;
  const isFirstPage = page === 0;
  const isLastPage = page === totalPages - 1;
  const canChangePage = totalPages > 1;

  const startRange = pageSize * page + 1;
  const endRange = isLastPage ? totalItems : pageSize * (page + 1);

  const defaultPageSize: SelectOption<number> = {
    label: pageSize,
    value: pageSize,
  };
  const defaultPage: SelectOption<number> = { label: pageForView, value: page };
  const pageOptions = new Array(totalPages)
    .fill('')
    .map((_, index) => mapToOption(index, index + 1));

  const onChangePage = (option: SelectOption<number>) => {
    changePage(option.value);
  };

  const onChangePageSize = (option: SelectOption<number>) => {
    changePageSize && changePageSize(option.value);
  };

  const onNextButtonClick = () => {
    if (!isLastPage) {
      changePage(page + 1);
    }
  };

  const onPreviousButtonClick = () => {
    if (!isFirstPage) {
      changePage(page - 1);
    }
  };

  return (
    <div
      className={cx('pagination')}
      data-testid="pagination"
    >
      {canChange && (
        <div className={styles.pageSize}>
          <Select<number>
            size="sm"
            isSearchable={false}
            options={availableValues}
            onChange={onChangePageSize}
            value={defaultPageSize}
            className={styles.control}
            noOptionsText=""
          />
          <span className={styles.text}>{itemsPerPageText}</span>
        </div>
      )}

      <div className={styles.totalItems}>
        <span>
          {`${startRange}–${endRange} ${pageFromText} ${totalItems} `}
          {pageFromTextElement}
        </span>
      </div>
      <div className={styles.controls}>
        <div className={styles.page}>
          <Select<number>
            size="sm"
            options={pageOptions}
            isSearchable={false}
            onChange={onChangePage}
            value={defaultPage}
            className={styles.control}
            noOptionsText=""
            hover
          />
          <span className={styles.text}>
            {`${pageFromText} ${totalPages} `}
            {pageFromTextLast}
          </span>
        </div>

        <Button
          variant="outline"
          size="sm"
          onClick={onPreviousButtonClick}
          disabled={!canChangePage || isFirstPage}
          className={styles.button}
        >
          <div className={styles.icon}>
            <Icon
              type="arrow-left"
              size="md"
            />
          </div>
        </Button>

        <Button
          variant="outline"
          size="sm"
          onClick={onNextButtonClick}
          disabled={!canChangePage || isLastPage}
          className={styles.button}
        >
          <div className={styles.icon}>
            <Icon
              type="arrow-right"
              size="md"
            />
          </div>
        </Button>
      </div>
    </div>
  );
};

export { Pagination };
export type { Props as PaginationProps };
