import React, { FC } from 'react';

import { bindStyles } from '@src/utils';
import { useAppTranslation, useI18n } from '@src/common';
import { Button, ButtonGroup } from '@src/kit';
import { Brand, Language } from '@src/@types';

import RuSvg from '@assets/images/LangRu.svg';
import UsSvg from '@assets/images/LangUs.svg';

import styles from '../CurrenciesLanguage.module.scss';

const cx = bindStyles(styles);

const LANGUAGE_VALUES: { label: string; value: Language }[] = [
  {
    label: 'Русский',
    value: 'ru-RU',
  },
  {
    label: 'English',
    value: 'en-US',
  },
];

const iconDictionary: Record<Language, React.ReactNode> = {
  'ru-RU': <RuSvg />,
  'en-US': <UsSvg />,
};

type Props = {
  brand: Brand;
};

const LanguageSelect: FC<Props> = ({ brand }) => {
  const { t } = useAppTranslation();
  const { currentLanguage, onChangeLanguage } = useI18n(brand);

  const onChangeLang = (lang: Language) => {
    if (lang !== currentLanguage) {
      onChangeLanguage(lang);
    }
  };

  return (
    <div className={cx('wrapper-language')}>
      <div className={cx('language-label')}>{t('settings.currencyLanguage.languageLabel')}</div>
      <div className={cx('language-content')}>
        <ButtonGroup gap="0">
          {LANGUAGE_VALUES.map((it) => {
            return (
              <Button
                key={it.value}
                value={it.value}
                variant="outline"
                text={it.label}
                size="md"
                active={currentLanguage === it.value}
                className={cx('language-content-button')}
                onClick={() => onChangeLang(it.value)}
                textClassName={cx({ active: currentLanguage === it.value })}
              >
                <div className={cx('language-content-icon')}>{iconDictionary[it.value]}</div>
              </Button>
            );
          })}
        </ButtonGroup>
      </div>
    </div>
  );
};

export { LanguageSelect };
