import { FC, useMemo } from 'react';

import {
  TariffBillingTypeVariants,
  TariffPriceResourceQuotaDTO,
} from '@common/components/TariffAndPricesComponent/api/models';

import { useAppTranslation, useCurrencyContext } from '@src/common';
import { bindStyles } from '@src/utils';

import styles from './MixedTariffBadge.module.scss';
import { Tooltip } from '@src/kit';

enum MixedTariffPriceType {
  FIXED = 'FIXED',
  PAYG = 'PAYG',
}

type Props = {
  from: number | null;
  to: number | null;
  priceType: TariffPriceResourceQuotaDTO['billingType'];
  price: number | null;
};

const cx = bindStyles(styles);

const MixedTariffBadge: FC<Props> = ({ priceType, price, to, from }) => {
  const { t } = useAppTranslation();
  const { customerCurrency, currenciesList } = useCurrencyContext();

  const currencySign = useMemo(() => {
    return currenciesList.find((it) => it.code === customerCurrency)?.name;
  }, [customerCurrency, currenciesList]);

  return (
    <Tooltip text={t(priceType) as string}>
      <div className={cx('wrapper', priceType)}>
        <div className={cx('infoText')}>{t('from.size')}</div>
        <div className={cx('valueText')}>{from}</div>
        <div className={cx('infoText')}>{t('to')}</div>
        <div className={cx('valueText')}>{to || '∞'}</div>
        <div className={cx('infoText')}>{t('UnitGb')}</div>
        <div className={cx('priceWrapper')}>
          <div className={cx('price')}>{price}</div>
          <div className={cx('valueText')}>{currencySign}</div>
          <div className={cx('infoText')}>/{t('month.text.short')}</div>
        </div>
      </div>
    </Tooltip>
  );
};

export { MixedTariffBadge, MixedTariffPriceType };
