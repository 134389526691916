import { FC, useCallback, useMemo } from 'react';
import ReactDatePicker from 'react-datepicker';

import './Calendar.module.scss';

import { useAppTranslation } from '@src/common';

import {
  Container,
  FastDatesProps,
  IntervalProps,
  IntervalVariants,
  PeriodType,
} from './Container';
import { Header } from './Header';

type CalendarValue = PeriodType | Date | null;

type Props = IntervalProps &
  FastDatesProps & {
    value: CalendarValue;
    currentValue: CalendarValue;
    onChangeValue: (value: CalendarValue) => void;
    setCurrentValue: (value: CalendarValue) => void;
    onReset: () => void;
    maxDate?: Date;
    minDate?: Date;
    noNeedPeriodLabel?: boolean;
  };

const Calendar: FC<Props> = ({
  value,
  intervals,
  interval,
  onChangeInterval,
  withInterval,
  activeFastDate,
  onChangeValue,
  withFastDates,
  fastDates,
  onClickFastDate,
  maxDate,
  minDate,
  onReset,
  setCurrentValue,
  currentValue,
  noNeedPeriodLabel,
}) => {
  const { i18n } = useAppTranslation();

  const period: PeriodType = useMemo(() => {
    if (Array.isArray(currentValue)) {
      return currentValue;
    }

    return [currentValue, null];
  }, [currentValue]);

  const onChange = useCallback((value: Date | null | [Date | null, Date | null]) => {
    if (Array.isArray(value)) {
      const start = value[0] || null;
      const end = value[1] || null;

      setCurrentValue([start, end]);
    } else {
      setCurrentValue(value);
    }
  }, []);

  return (
    <ReactDatePicker
      selected={interval === IntervalVariants.MONTH && period[1] ? undefined : period[0]}
      selectsRange={Array.isArray(value)}
      startDate={period[0]}
      endDate={period[1]}
      onChange={onChange}
      disabledKeyboardNavigation
      showDisabledMonthNavigation={false}
      inline
      locale={i18n.language}
      calendarContainer={({ children }) => (
        <Container
          intervals={intervals}
          onChangeInterval={onChangeInterval}
          withInterval={withInterval}
          interval={interval}
          onClickFastDate={onClickFastDate}
          withFastDates={withFastDates}
          fastDates={fastDates}
          activeFastDate={activeFastDate}
          onConfirm={() => onChangeValue(currentValue)}
          onReset={onReset}
          currentDate={currentValue}
          noNeedPeriodLabel={noNeedPeriodLabel}
        >
          {children}
        </Container>
      )}
      renderCustomHeader={(props) => (
        <Header
          {...props}
          interval={interval}
        />
      )}
      monthsShown={interval === IntervalVariants.DAY ? 2 : 1}
      dateFormat={interval === IntervalVariants.MONTH ? 'MM/yyyy' : undefined}
      showMonthYearPicker={interval === IntervalVariants.MONTH}
      showFullMonthYearPicker={interval === IntervalVariants.MONTH}
      maxDate={maxDate}
      minDate={minDate}
      peekNextMonth
    />
  );
};

export { Calendar };
export type { CalendarValue };
