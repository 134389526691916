import React from 'react';

import { H3, Icon } from '@kit/components';

import { bindStyles } from '@src/utils';

import styles from '../ReferencePopup.module.scss';

const cx = bindStyles(styles);

type Props = {
  title: string;
  backVisible: boolean;
  onClose: () => void;
  onBack: () => void;
};

const ModalHeader: React.FC<Props> = ({ title, backVisible, onBack, onClose }) => (
  <div className={cx('modalHeaderWrap', { modalHeaderWrapBordered: backVisible })}>
    <div className={cx('modalHeader')}>
      <div className={cx('modalHeaderTitle')}>
        {backVisible && (
          <Icon
            type="chevron-down"
            className={cx('icon', 'iconBack')}
            onClick={onBack}
          />
        )}
        <H3>{title}</H3>
      </div>
      <div>
        <Icon
          type="close"
          className={cx('icon')}
          onClick={onClose}
        />
      </div>
    </div>
  </div>
);

export { ModalHeader };
