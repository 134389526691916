import React, { SyntheticEvent } from 'react';

import cn from 'classnames/bind';

import { Size } from '@src/@types';

import CheckmarkL from '@assets/images/CheckmarkL.svg';
import CheckmarkM from '@assets/images/CheckmarkM.svg';
import CheckmarkS from '@assets/images/CheckmarkS.svg';
import MixedL from '@assets/images/MixedL.svg';
import MixedM from '@assets/images/MixedM.svg';
import MixedS from '@assets/images/MixedS.svg';

import styles from './Checkbox.module.scss';

type CheckboxSize = Extract<Size, 'lg' | 'md' | 'sm'>;

type CheckboxType = 'mark' | 'mixed';

type CheckboxProps = {
  errorText?: string;
  isError?: boolean;
  size?: CheckboxSize;
  containerReverse?: boolean;
  type?: CheckboxType;
};

type Props = Omit<React.ComponentPropsWithRef<'input'>, keyof CheckboxProps> & CheckboxProps;

const cx = cn.bind(styles);

const CHECKMARK_ICON_DICTIONARY: Record<CheckboxSize, Record<CheckboxType, JSX.Element>> = {
  lg: {
    mark: <CheckmarkL />,
    mixed: <MixedL />,
  },
  md: {
    mark: <CheckmarkM />,
    mixed: <MixedM />,
  },
  sm: {
    mark: <CheckmarkS />,
    mixed: <MixedS />,
  },
};

const Checkbox: React.FC<Props> = ({
  isError,
  errorText,
  checked,
  disabled,
  children,
  containerReverse,
  onClick,
  size = 'lg',
  type = 'mark',
  ...restProps
}) => {
  //INFO: нужно для того чтобы не было двойного всплытия с компонента
  const onInputClick = (e: SyntheticEvent) => e.stopPropagation();

  return (
    <label className={cx('container', size, { containerReverse, disabled, isError, checked })}>
      <div className={cx('checkbox', size, { disabled, isError, checked })}>
        <div className={cx('checkmark')}>{CHECKMARK_ICON_DICTIONARY[size][type]}</div>
        <input
          type="checkbox"
          disabled={disabled}
          checked={checked}
          onClick={onInputClick}
          {...restProps}
          className={cx('input')}
        />
      </div>

      {children && (
        <div className={cx('textContainer', size)}>
          <p className={cx('caption')}>{children}</p>
          {isError && <p className={cx('error')}>{errorText}</p>}
        </div>
      )}
    </label>
  );
};

export { Checkbox };
export type { Props };
