import React, { FC, useCallback } from 'react';

import { useAppTranslation } from '@src/common';
import { Icon, Input } from '@src/kit';

type Props = {
  value: string | null;
  onChange: (v: string) => void;
  onSearch: () => void;
  onReset: () => void;
  disabled?: boolean;
};

const Search: FC<Props> = ({ onSearch, value, onChange, disabled, onReset }) => {
  const { t } = useAppTranslation();

  const onSubmitSearch = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        onSearch();
      }
    },
    [onSearch],
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
    },
    [onChange],
  );

  return (
    <Input
      size="md"
      value={value || ''}
      placeholder={t('search')}
      onKeyPress={onSubmitSearch}
      onChange={handleChange}
      actions={[{ icon: <Icon type="search" />, alignLeft: true }]}
      onClear={onReset}
      disabled={disabled}
    />
  );
};

export { Search };
