import React, { FC } from 'react';

import { CmSelectComponents, CmSelectValue } from '@kit/components/CmSelect';
import { TOOLTIP_SELECT_ID } from '@kit/components/CmSelect/Dropdown';

import { bindStyles } from '@src/utils';
import { Avatar, Icon, OptionCustomize, TextShorter, Tooltip } from '@src/kit';
import { CLOUD_ICON_DICTIONARY } from '@src/constants';

import styles from './ValueContainer.module.scss';

const cx = bindStyles(styles);

type Props = {
  value: CmSelectValue;
  disabled?: boolean;
  placeholder?: string;
  component?: CmSelectComponents['selectedValue'];
};

const Content: FC<
  OptionCustomize & {
    label: string | number;
    value: unknown;
    tooltip?: boolean;
  }
> = ({ label, value, tooltip = true, cloudType, avatar, icon }) => {
  return (
    <>
      {cloudType && <div className={cx('cloudIcon')}>{CLOUD_ICON_DICTIONARY[cloudType]}</div>}
      {icon && (
        <Icon
          className={cx('icon')}
          type={icon}
          size="xl"
        />
      )}
      {avatar && (
        <Avatar
          fullName={avatar}
          userId={value as string}
        />
      )}
      <TextShorter
        tooltipId={TOOLTIP_SELECT_ID}
        tooltip={tooltip}
      >
        <span>{label}</span>
      </TextShorter>
    </>
  );
};

const ValueContainer: React.FC<Props> = ({ value, placeholder, component, disabled }) => {
  if (!value || (Array.isArray(value) && value.length === 0)) {
    return (
      <TextShorter>
        <span className={cx('placeholder')}>{placeholder}</span>
      </TextShorter>
    );
  }
  if (component) {
    return <>{component(value)}</>;
  }
  if (Array.isArray(value)) {
    if (value.length === 0) {
      return <span className={cx('placeholder')}>{placeholder}</span>;
    } else {
      const [first, ...other] = value;
      return (
        <div className={cx('multi', 'default', { disabled })}>
          <div className={cx('content')}>
            <Content
              {...(first.customize || {})}
              value={first.value}
              label={first.label}
            />
            {other.length > 0 && (
              <Tooltip
                id={TOOLTIP_SELECT_ID}
                text={other.map((it) => (
                  <div
                    className={cx('contentTooltip')}
                    key={it.value as string}
                  >
                    <Content
                      {...(it.customize || {})}
                      value={it.value}
                      label={it.label}
                      tooltip={false}
                    />
                  </div>
                ))}
                classes={{ tooltip: cx('counter') }}
              >
                <span className={cx('counter')}>+{other.length}</span>
              </Tooltip>
            )}
          </div>
        </div>
      );
    }
  } else {
    return (
      <div className={cx('default', { disabled })}>
        <Content
          {...(value.customize || {})}
          value={value.value}
          label={value.label}
        />
      </div>
    );
  }
};

export { ValueContainer };
