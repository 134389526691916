import { DateTime, Settings } from 'luxon';

Settings.defaultLocale = DateTime.now().resolvedLocaleOptions().locale;

const DEFAULT_FORMAT = 'dd.MM.yyyy';

const toDefaultDate = (time: DateTime | string, format?: string): string => {
  if (time instanceof DateTime) {
    return time.toFormat(format || DEFAULT_FORMAT);
  }

  return DateTime.fromISO(time).toFormat(format || DEFAULT_FORMAT);
};

const getNowDate = (): DateTime => DateTime.now();

export { toDefaultDate, getNowDate };
