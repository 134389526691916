import React, { useMemo } from 'react';

import { UserViewDTO } from '@common/components/UserRoles/types';

import { toDefaultDate, useAppTranslation, useUserRolesContext } from '@src/common';
import { bindStyles } from '@src/utils';
import { DashValue } from '@src/kit';

import AvatarDefault from '@assets/images/AvatarDefault.svg';
import AvatarDefaultAstrokite from '@assets/images/AvatarDefaultAstrokite.svg';

import styles from './UserDetails.module.scss';

type Props = {
  userData: UserViewDTO;
};

const cx = bindStyles(styles);

const UserDetailsView: React.FC<Props> = ({ userData }) => {
  const { t } = useAppTranslation();
  const { brand } = useUserRolesContext();

  const clouderRole = userData.roles[0];
  const costerRole = userData.roles[1];

  const name =
    userData.lastName || userData.firstName
      ? (userData.lastName ? userData.lastName + ' ' : '') +
        (userData.firstName && userData.firstName)
      : '';

  const renderedAvatar = useMemo(() => {
    switch (brand) {
      case 'astrokite':
        return <AvatarDefaultAstrokite />;
      case 'cloudmaster':
        return <AvatarDefault />;
    }
  }, [brand]);

  return (
    <>
      <div className={cx('container')}>
        <div className={cx('avatar')}>{renderedAvatar}</div>

        <div className={cx('informationBlock')} />

        <div className={cx('nameBlock')}>
          <div className={cx('name')}>{name}</div>
        </div>

        <div className={cx('datesBlock')}>
          <div className={cx('dateValueBlock')}>
            <div className={cx('dateLabel')}>{t('cos.user.roles.coster')}</div>
            <div className={cx('dateValue')}>
              <DashValue condition={Boolean(costerRole)}>
                {t(costerRole?.name.replace(' ', ''))}
              </DashValue>
            </div>
          </div>
          <div className={cx('dateValueBlock')}>
            <div className={cx('dateLabel')}>{t('cos.user.roles.clouder')}</div>
            <div className={cx('dateValue')}>
              <DashValue condition={Boolean(clouderRole)}>
                {t(clouderRole?.name.replace(' ', ''))}
              </DashValue>
            </div>
          </div>
          <div className={cx('separator')} />
          <div className={cx('dateValueBlock')}>
            <div className={cx('dateLabel')}>{t('CreationDate')}</div>
            <div className={cx('dateValue')}>
              <DashValue condition={Boolean(userData.createdAt)}>
                {toDefaultDate(userData.createdAt || '', 'dd.MM.yyyy / HH:mm')}
              </DashValue>
            </div>
          </div>
          <div className={cx('dateValueBlock')}>
            <div className={cx('dateLabel')}>{t('LastLogin')}</div>
            <div className={cx('dateValue')}>
              <DashValue condition={Boolean(userData.lastLoginAt)}>
                {toDefaultDate(userData.lastLoginAt || '', 'dd.MM.yyyy / HH:mm')}
              </DashValue>
            </div>
          </div>
        </div>

        <div>
          <div className={cx('commentBlock')}>
            <div className={cx('commentLabel')}>
              {t('Mail')} / {t('Login')}
            </div>
            <div className={cx('commentText')}> {userData.login} </div>
          </div>
          <div className={cx('separator')} />
        </div>

        {userData.comment && (
          <div>
            <div className={cx('commentBlock')}>
              <div className={cx('commentLabel')}> {t('Comment')} </div>
              <div className={cx('commentText')}>{userData.comment} </div>
            </div>

            <div className={cx('separator')} />
          </div>
        )}
      </div>
    </>
  );
};

export { UserDetailsView };
