import { FC, SyntheticEvent, useMemo } from 'react';
import { NavigateOptions } from 'react-router-dom';

import { useAppTranslation, usePopupControls, MenuItemType } from '@src/common';
import { Icon, Popup, TextShorter, Tooltip } from '@src/kit';
import { bindStyles } from '@src/utils';

import styles from './SidebarItem.module.scss';

type Props = {
  path?: string;
  disabled?: boolean;
  caption?: string;
  onClick?: () => void;
  subItems?: MenuItemType[];
  isMinified?: boolean | null;
  isSubItem?: boolean;
  onNavigate: (to: string, options: NavigateOptions) => void;
  isActive?: boolean;
  pathname?: string;
  id?: string;
};

const cx = bindStyles(styles);

export const SidebarItem: FC<Props> = ({
  path,
  disabled,
  caption = '',
  onClick,
  subItems,
  children,
  isMinified,
  isSubItem,
  onNavigate,
  isActive,
  pathname,
  id,
}) => {
  const { t } = useAppTranslation();
  const { openPopup, closePopup, isOpened } = usePopupControls();

  const isHoldsPopup = !!subItems && isMinified;

  const onLinkClick = (e: SyntheticEvent) => {
    if (path && !isHoldsPopup) {
      const subItemPath = subItems
        ? subItems.find((item) => item.link === '')?.link ?? subItems[0].link
        : '';
      const itemPath = path + subItemPath;
      onNavigate(itemPath, { replace: true });
    }
    if (!isHoldsPopup) {
      return;
    }
    e.preventDefault();
    openPopup();
  };

  const onSubitemClick = () => {
    isHoldsPopup && closePopup();
  };

  const bodyClasses = cx('body', { disabled, isSubItem, isHoldsPopup, isOpened, isMinified });

  const contentNode = (
    <div className={cx('wrapper')}>
      {children && <div className={cx('icon')}>{children}</div>}
      <TextShorter className={cx('text')}>{caption}</TextShorter>
    </div>
  );

  const itemNode = (
    <div
      id={id}
      onClick={onLinkClick}
      className={cx(bodyClasses, { isActive: isActive || isOpened })}
    >
      {contentNode}
      {subItems && !isMinified && (
        <Icon
          type="chevron-down"
          size="md"
          className={cx('icon', 'chevron')}
        />
      )}
    </div>
  );

  const subitemsNode = useMemo(
    () =>
      subItems?.map(({ disabled, link, caption }) => {
        const subItemPath = `${path}${link}`;
        const subItemsIsActive = pathname === subItemPath;
        return (
          <SidebarItem
            key={link}
            isActive={subItemsIsActive}
            path={subItemPath}
            caption={t(caption)}
            disabled={disabled}
            isSubItem
            onNavigate={onNavigate}
            onClick={onSubitemClick}
          />
        );
      }),
    [subItems, pathname, path, t],
  );

  return path ? (
    <Tooltip
      text={caption}
      place="top"
      disabled={disabled || !isMinified}
    >
      {subItems && isMinified ? (
        <Popup
          keepTooltipInside
          closeOnDocumentClick
          position="bottom left"
          trigger={itemNode}
          open={isOpened}
          onClose={closePopup}
          size="md"
          className={cx('popup', 'subItems')}
        >
          <div
            className={cx('subItems')}
            onClick={onSubitemClick}
          >
            {subitemsNode}
          </div>
        </Popup>
      ) : (
        <>
          {itemNode}
          {subItems && <div className={cx('subItems', 'subWrapper')}>{subitemsNode}</div>}
        </>
      )}
    </Tooltip>
  ) : (
    <button
      id={id}
      className={cx(bodyClasses, 'button')}
      onClick={onClick}
      type="button"
      disabled={disabled}
    >
      {contentNode}
    </button>
  );
};
