import React, { useContext } from 'react';

import { CurrentTabContext } from '@kit/components/Tab';

type Props = {
  value: string;
};

const TabPanel: React.FC<Props> = ({ value, children }) => {
  const currentValue = useContext(CurrentTabContext);
  return currentValue === value ? <>{children}</> : null;
};

export { TabPanel };
