import {
  ComponentPropsWithRef,
  ForwardedRef,
  forwardRef,
  PropsWithChildren,
  useEffect,
} from 'react';
import {
  DefaultValues,
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm,
  UseFormReturn,
  ValidationMode,
  Path,
} from 'react-hook-form';

import { ApiValidationError } from '@src/@types';

type FormProps<T extends FieldValues> = {
  onSubmit?: SubmitHandler<T>;
  defaultValues?: DefaultValues<T>;
  mode?: keyof ValidationMode;
  formMethods?: UseFormReturn<T>;
  apiErrors?: ApiValidationError[];
};
type Props<T extends FieldValues> = Omit<ComponentPropsWithRef<'form'>, keyof FormProps<T>> &
  PropsWithChildren<FormProps<T>>;

const FormInner = <T extends FieldValues>(props: Props<T>, ref?: ForwardedRef<HTMLFormElement>) => {
  const { onSubmit, children, defaultValues, formMethods, mode = 'onBlur', ...restProps } = props;
  const methods = formMethods ?? useForm<T>({ defaultValues, mode });

  useEffect(() => {
    if (props.apiErrors?.length) {
      props.apiErrors.forEach(({ field, message }) => {
        methods.setError(field as Path<T>, { message });
      });
    }
  }, [props.apiErrors]);

  return (
    <FormProvider {...methods}>
      <form
        {...restProps}
        onSubmit={onSubmit ? methods.handleSubmit(onSubmit) : undefined}
        ref={ref}
      >
        {children}
      </form>
    </FormProvider>
  );
};

const Form = forwardRef(FormInner) as <T extends FieldValues>(
  props: Props<T> & { ref?: ForwardedRef<HTMLFormElement> },
) => ReturnType<typeof FormInner>;

export { Form };
export type { FormProps };
