import { FC, SyntheticEvent, useMemo } from 'react';

import { Dropdown, TOOLTIP_SELECT_ID } from '@kit/components/CmSelect/Dropdown';
import { useSearch } from '@kit/components/CmSelect/hooks/useSearch';
import { Options } from '@kit/components/CmSelect/Options';
import { renderHeader } from '@kit/components/CmSelect/Header';

import {
  Avatar,
  Checkbox,
  CmSelectComponents,
  CmSelectValue,
  Icon,
  SelectOption,
  TextShorter,
} from '@src/kit';
import { bindStyles } from '@src/utils';
import { CLOUD_ICON_DICTIONARY } from '@src/constants';
import { usePopupControls } from '@src/common';

import styles from './OptionContainer.module.scss';

const cx = bindStyles(styles);

enum CheckPosition {
  RIGHT = 'right',
  LEFT = 'left',
}

type Props = {
  selectedValue?: CmSelectValue;
  option: SelectOption<unknown>;
  isSelected: (option: SelectOption<unknown>) => boolean;
  onChange: (values: CmSelectValue, event?: SyntheticEvent) => void;
  multi?: boolean;
  searchable?: boolean;
  noOptionsText?: string;
  onClose: () => void;
  selectionContainer?: HTMLDivElement;
  selectAllAlign?: 'left' | 'right';
  withoutCheckmark?: boolean;
  maxMenuHeight?: number;
  component?: CmSelectComponents['optionContainer'];
};

const OptionContainer: FC<Props> = ({
  option,
  multi,
  selectionContainer,
  searchable,
  selectAllAlign,
  noOptionsText,
  isSelected,
  onChange,
  onClose,
  withoutCheckmark,
  selectedValue,
  maxMenuHeight,
  component,
}) => {
  const selected = isSelected(option);
  const { value, label } = option;
  const { isOpened, closePopup, openPopup } = usePopupControls();
  const { avatar, icon, cloudType, childrenOptions, disabled } = useMemo(() => {
    if (option.customize) {
      return option.customize;
    }
    return {};
  }, [option]);

  const { filteredOptions, ...searchProps } = useSearch(Boolean(searchable), childrenOptions || []);

  const hasChildren = useMemo(() => {
    return Boolean(childrenOptions && childrenOptions.length);
  }, [childrenOptions]);

  const checkPosition = useMemo(() => {
    if (hasChildren) {
      return CheckPosition.RIGHT;
    }

    if (multi) {
      return cloudType || icon || avatar ? CheckPosition.RIGHT : CheckPosition.LEFT;
    }

    return CheckPosition.RIGHT;
  }, [multi, cloudType, icon, avatar, hasChildren]);

  const selectAllType = useMemo(() => {
    if (!Array.isArray(selectedValue)) {
      return false;
    }

    if (selectedValue?.length === 0) {
      return false;
    }

    return childrenOptions?.length === selectedValue.length ? true : 'mixed';
  }, [selectedValue, childrenOptions]);

  const onSelect = (option: SelectOption<unknown>) => (event: SyntheticEvent) => {
    if (disabled) {
      return;
    }

    if (hasChildren) {
      openPopup();
    } else if (multi) {
      onSelectMulti(option, event);
    } else {
      onChange(option, event);
      onClose();
    }
  };

  const onSelectMulti = (option: SelectOption<unknown>, event: SyntheticEvent) => {
    if (!Array.isArray(selectedValue)) {
      onChange([option], event);
      return;
    }
    const exist = selectedValue.some((item) => item.value === option.value);

    let selected = [...selectedValue];

    if (exist) {
      selected = selected.filter((item) => item.value !== option.value);
    } else {
      selected.push(option);
    }

    onChange(selected, event);
  };

  const renderedCheck = useMemo(() => {
    if (hasChildren) {
      return (
        <Icon
          className={cx('icon', 'chevron')}
          type="chevron-left"
          size="md"
        />
      );
    }

    if (multi) {
      return (
        <div className={cx('cb')}>
          <Checkbox
            size="md"
            checked={selected}
          />
        </div>
      );
    }

    return selected ? (
      <Icon
        className={cx('icon', 'check')}
        type="check-bold"
        size="xl"
      />
    ) : null;
  }, [selected, multi, hasChildren]);

  const onSelectAll = () => {
    if (childrenOptions) {
      if (selectAllType === 'mixed' || !selectAllType) {
        onChange([...childrenOptions]);
      } else {
        onChange([]);
      }
    }
  };

  return (
    <li
      className={cx('item', {
        selected: isSelected(option),
        disabled,
      })}
      onClick={onSelect(option)}
    >
      {component ? (
        component(option, isSelected(option))
      ) : (
        <div className={cx('container')}>
          <div
            className={cx('content', {
              withoutCheckmark:
                withoutCheckmark ||
                (!multi && !selected && !hasChildren && checkPosition === CheckPosition.RIGHT),
            })}
          >
            {!hasChildren && checkPosition === CheckPosition.LEFT && renderedCheck}
            {!hasChildren && cloudType && (
              <div className={cx('icon', 'cloudIcon')}>{CLOUD_ICON_DICTIONARY[cloudType]}</div>
            )}
            {!hasChildren && icon && (
              <Icon
                className={cx('icon', 'optionIcon')}
                type={icon}
                size="xl"
              />
            )}
            {!hasChildren && avatar && (
              <Avatar
                fullName={avatar}
                userId={value as string}
              />
            )}
            <TextShorter
              tooltipId={TOOLTIP_SELECT_ID}
              tooltip
              selectTooltip
            >
              {label}
            </TextShorter>
          </div>

          {checkPosition === CheckPosition.RIGHT && !withoutCheckmark && renderedCheck}
          {hasChildren && selectionContainer && isOpened && (
            <Dropdown
              selectionContainer={selectionContainer}
              onClose={closePopup}
              right
              customTop={0}
              maxMenuHeight={maxMenuHeight}
              topContent={renderHeader({
                ...searchProps,
                multi,
                selectAllAlign,
                searchable,
                selectAllType,
                optionsCount: childrenOptions?.length || 0,
                onSelectAll,
              })}
            >
              <Options
                options={filteredOptions}
                isSelected={isSelected}
                onChange={onChange}
                onClose={onClose}
                withoutCheckmark={withoutCheckmark}
                multi={multi}
                noOptionsText={noOptionsText}
                value={selectedValue}
                refSelection={selectionContainer}
                maxMenuHeight={maxMenuHeight}
              />
            </Dropdown>
          )}
        </div>
      )}
    </li>
  );
};

export { OptionContainer, CheckPosition };
