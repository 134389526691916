import React from 'react';

import cn from 'classnames';

import { Size } from '@src/@types';

import styles from './Icon.module.scss';
import { IconTypes, dictionary } from './IconDictionary';

type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  type: IconTypes;
  size?: Size;
};

const iconSizesDictionary: Record<Size, string> = {
  xs: styles.icon_xs,
  sm: styles.icon_sm,
  md: styles.icon_md,
  lg: styles.icon_lg,
  xl: styles.icon_xl,
  xxl: styles.icon_xxl,
  xxxl: styles.icon_xxxl,
};

const Icon: React.FC<Props> = ({ type, size = 'xl', className, ...restProps }) => {
  const Icon = dictionary[type];

  if (!Icon) {
    return null;
  }

  const classNameBySize = iconSizesDictionary[size];
  const classnames = cn(styles.icon, classNameBySize, className);

  return (
    <div
      className={classnames}
      {...restProps}
    >
      <Icon />
    </div>
  );
};

export { Icon };
export type { IconTypes };
