import { useCallback, useState } from 'react';

import { ConnectedCloudsDataDTO } from '@common/components/AppToolbar/components/CloudConnect/types';
import { deleteCloud } from '@common/components/AppToolbar/api/requests';

import { useAnalytics, useAppTranslation, useDialog, useToast } from '@src/common';

type Props = {
  refetchData: () => void;
};

const useDeleteCloud = ({ refetchData }: Props) => {
  const toast = useToast();
  const { t } = useAppTranslation();
  const { Dialog: SuspendDialog, openPopup, closePopup } = useDialog();
  const [deletableCloud, setDeletableCloud] = useState<ConnectedCloudsDataDTO | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { logSuccessEvent, logErrorEvent } = useAnalytics();

  const openDeleteModal = (cloud: ConnectedCloudsDataDTO) => {
    setDeletableCloud(cloud);
    openPopup();
  };

  const closeDeleteModal = () => {
    setDeletableCloud(null);
    closePopup();
  };

  const onDeleteCloud = useCallback(async () => {
    if (!deletableCloud?.id) {
      return;
    }

    try {
      setLoading(true);
      await deleteCloud(deletableCloud.id);
      closeDeleteModal();
      await refetchData();
      toast.success({ text: t('DeleteCloudSuccess') });
      logSuccessEvent('Deleted cloud connection', { id: deletableCloud.id });
    } catch (err) {
      toast.error({ text: t('DeleteError') });
      logErrorEvent('Deleted cloud connection', { id: deletableCloud.id });
    } finally {
      setLoading(false);
    }
  }, [toast, refetchData]);

  return {
    loading,
    SuspendDialog,
    deletableCloud,
    openDeleteModal,
    closeDeleteModal,
    onDeleteCloud,
  };
};

export { useDeleteCloud };
