import { Language } from '@src/@types';

import GeneralErrorIcon from '@assets/images/plugs/GeneralError.svg';
import NGINXErrorIcon from '@assets/images/plugs/NGINXError.svg';

enum ErrorType {
  GENERAL = 'general',
  NGINGX = 'NGINGX',
}

const ICON_VARIANTS = {
  [ErrorType.NGINGX]: <NGINXErrorIcon />,
  [ErrorType.GENERAL]: <GeneralErrorIcon />,
};

type LangVariant = {
  [key in ErrorType]: {
    [key in Language]: string;
  };
};

const TITLE_VARIANTS: LangVariant = {
  [ErrorType.NGINGX]: {
    'en-US': 'Oops, Error 502',
    'ru-RU': 'Упс, Ошибка 502',
  },
  [ErrorType.GENERAL]: {
    'en-US': 'Unexpected Error',
    'ru-RU': 'Непредвиденная ошибка',
  },
};

const COMMENT_VARIANTS: LangVariant = {
  [ErrorType.NGINGX]: {
    'en-US': 'Sorry, we are down for maintenance. We will be back up shortly.',
    'ru-RU':
      'Приносим свои извенения в данный момент ведутся технические работы. Скоро всё вернется в нормальный вид',
  },
  [ErrorType.GENERAL]: {
    'en-US': 'Unexpected error. Try again',
    'ru-RU': 'Произошла не предвиденная ошибка. Попробуйте снова',
  },
};

const BUTTON_VARIANTS: LangVariant = {
  [ErrorType.NGINGX]: {
    'en-US': 'Retry',
    'ru-RU': 'Повторить',
  },
  [ErrorType.GENERAL]: {
    'en-US': 'Retry',
    'ru-RU': 'Повторить',
  },
};

export { ErrorType, ICON_VARIANTS, TITLE_VARIANTS, COMMENT_VARIANTS, BUTTON_VARIANTS };
