export const AUTH_HEADER = 'x-amz-security-token';
export const AUTH_LOGIN_HEADER = 'Authorization';
export const ADMIN_AUTH_KEY = 'admin-secret-key';
export const USER_INFO_KEY = 'user-info-key';
export const ACCEPT_LANGUAGE = 'Accept-Language';

export const RANGE = 'X-Range';
export const COUNT = 'X-Count';
export const SORT = 'X-Sort';
export const FILTER = 'X-Filter';

export const SEARCH = 'search';
