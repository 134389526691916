import React, { useContext, useEffect, useRef } from 'react';

import cn from 'classnames/bind';
import { Loader } from '@kit/components';

import { makeId } from '@utils/common';

import styles from './Tab.module.scss';
import { CurrentTabContext } from './TabContext';
import { TabListProps } from './TabList';

type Props = Partial<TabListProps> & {
  value: string;
  label: string;
  count?: number;
  disabled?: boolean;
  iconPosition?: 'right' | 'left';
  countClassName?: string;
  isLoading?: boolean;
};

const Tab: React.FC<Props> = ({
  value,
  label,
  size,
  variant,
  count,
  iconPosition = 'left',
  children,
  tabClassName,
  iconClasses,
  disabled,
  countClassName,
  isLoading,
}) => {
  const cx = cn.bind(styles);
  const idPrefix = makeId(5);
  const currentValue = useContext(CurrentTabContext);
  const active = currentValue === value;
  const classes = cx('body', 'tab', size, variant, { active, disabled }, tabClassName);
  const icon = <div className={cx('icon', iconClasses, { active })}>{children}</div>;
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (ref) {
      ref.current?.setAttribute('text-content', ref.current.innerHTML);
    }
  }, [label]);

  return (
    <button
      type="button"
      className={classes}
      id={`${idPrefix}-${value}`}
    >
      {iconPosition === 'left' && children && icon}
      {label && (
        <div
          ref={ref}
          className={cx('label')}
        >
          {label}
        </div>
      )}
      {iconPosition === 'right' && children && icon}
      {(isLoading || count) &&
        (isLoading ? (
          <div className={cx('loader')}>
            <Loader type="spinner-progress" />
          </div>
        ) : (
          <div className={cx('count', countClassName)}>{count}</div>
        ))}
    </button>
  );
};

export { Tab };
