import React from 'react';

import cn from 'classnames/bind';
import { ConnectedCloudStatus } from '@common/components/AppToolbar/components/CloudConnect/types';

import { useAppTranslation } from '@src/common';
import { Icon, IconTypes } from '@src/kit';

import styles from './StatusBadge.module.scss';

type Props = {
  status: ConnectedCloudStatus;
};

const statusParser = {
  ACTIVE: 'Active',
  STOPPED: 'Stopped',
  UPDATE_ERROR: 'UpdateError',
  INVALID_CREDENTIAL: 'InvalidCredentials',
  WAITING_REPORT: 'WaitingReport',
};

const statusIcon = {
  ACTIVE: 'circle-check',
  STOPPED: 'circle-information',
  UPDATE_ERROR: 'refresh',
  INVALID_CREDENTIAL: 'circle-error',
  WAITING_REPORT: 'clock',
};

const StatusBadge: React.FC<Props> = ({ status }) => {
  const cx = cn.bind(styles);
  const { t } = useAppTranslation();
  const statusParsed = statusParser[status];
  const containerClasses = cx(styles.badge, [`container_${statusParsed}`]);

  return (
    <div className={containerClasses}>
      <div className={styles.iconContainer}>
        <Icon
          type={statusIcon[status] as IconTypes}
          size="xs"
        />
      </div>
      {statusParsed && t(statusParsed)}
    </div>
  );
};

export { StatusBadge };
