import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  AzureConnectionDTO,
  AzureConnectionFormDTO,
  AzureRequestSubscriptionDTO,
  AzureSubscription,
  Stages,
} from '@common/components/AppToolbar/components/CloudConnect/types';
import {
  connectAzure,
  connectEnterpriseAzure,
  fetchAzureSubscriptionsByParams,
} from '@common/components/AppToolbar/api/cloud-connections';
import { AxiosError } from 'axios';

import {
  Form,
  useAnalytics,
  useAppTranslation,
  useCloudConnectContext,
  useFormApiError,
  useToast,
} from '@src/common';
import { CLOUD_NAME_DICTIONARY } from '@src/constants';

import { ErrorUtils } from '@utils/ErrorUtils';

import { FormAzureView } from './FormAzure.view';

enum AzureCreationType {
  CREATE_APPLICATION = 'create_application',
  ENTERPRISE_AGREEMENT = 'enterprise_agreement',
}

const FormAzure: React.FC = () => {
  const toast = useToast();
  const { t } = useAppTranslation();
  const { selectStage, changeDisagreeButtonSettings, changeAgreeButtonSettings } =
    useCloudConnectContext();
  const { apiErrors, handleError } = useFormApiError();

  const [loading, setLoading] = useState(false);
  const [loadingSubscriptions, setLoadingSubscriptions] = useState(false);
  const [subscriptions, setSubscriptions] = useState<AzureSubscription[]>([]);
  const [activeTab, setActiveTab] = useState(AzureCreationType.CREATE_APPLICATION);
  const form = useForm<AzureConnectionFormDTO>();
  const { logSuccessEvent, logErrorEvent } = useAnalytics();

  const onChangeTab = useCallback((value: string) => {
    setActiveTab(value as AzureCreationType);
  }, []);

  const onBackHandler = () => {
    selectStage({
      stage: Stages.providerChoice,
    });
  };

  const createNewConnect = async ({
    subscriptions: subscriptionsFromForm,
    ...formData
  }: AzureConnectionFormDTO) => {
    try {
      setLoading(true);
      if (activeTab === AzureCreationType.CREATE_APPLICATION) {
        const subscriptions: AzureSubscription[] = subscriptionsFromForm.map(
          ({ label, value }) => ({
            name: label.toString(),
            uuid: value,
          }),
        );
        const dto: AzureConnectionDTO = {
          cloudName: formData.cloudName,
          clientId: formData.clientId,
          clientSecret: formData.clientSecret,
          tenantId: formData.tenantId,
          subscriptions,
        };
        await connectAzure(dto);
      } else {
        const dto = {
          cloudName: formData.cloudName,
          enrolmentId: formData.enrolmentId,
          bearer: formData.bearer,
        };
        await connectEnterpriseAzure(dto);
      }
      selectStage({
        stage: Stages.connectResult,
      });
      toast.success({
        text: `${t('CloudConnectedSuccessfully', { cloud: CLOUD_NAME_DICTIONARY.Azure })}`,
      });
      logSuccessEvent('Created cloud connection', {
        cloudType: CLOUD_NAME_DICTIONARY.Azure,
        creationType: activeTab,
      });
    } catch (error) {
      handleError(error as AxiosError);
      toast.error({
        text: `${t(`FailedConnectCloud`, { cloud: CLOUD_NAME_DICTIONARY.Azure })}`,
        hasClose: true,
      });
      logErrorEvent('Created cloud connection', {
        cloudType: CLOUD_NAME_DICTIONARY.Azure,
        creationType: activeTab,
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchListSubscriptions = async (data: AzureRequestSubscriptionDTO) => {
    try {
      setLoadingSubscriptions(true);
      const response = await fetchAzureSubscriptionsByParams(data);
      setSubscriptions(response.data.subscriptions);
    } catch (error) {
      const errorMsg = ErrorUtils.handleApiError(error);
      toast.error({ text: errorMsg });
    } finally {
      setLoadingSubscriptions(false);
    }
  };

  useEffect(() => {
    changeDisagreeButtonSettings({
      onClick: onBackHandler,
    });
    changeAgreeButtonSettings({
      onClick: form.handleSubmit(createNewConnect),
      isLoading: loading,
    });
  }, [loading, activeTab]);

  useEffect(() => {
    return () => {
      setSubscriptions([]);
    };
  }, []);

  return (
    <Form
      formMethods={form}
      onSubmit={createNewConnect}
      apiErrors={apiErrors}
    >
      <FormAzureView
        fetchListSubscriptions={fetchListSubscriptions}
        list={subscriptions}
        isLoading={loadingSubscriptions}
        activeTab={activeTab}
        onChangeTab={onChangeTab}
      />
    </Form>
  );
};

export { FormAzure, AzureCreationType };
