import React from 'react';

import { bindStyles } from '@src/utils';
import { Icon, TextShorter } from '@src/kit';

import styles from './ProfilePopupInner.module.scss';

const cx = bindStyles(styles);

type Props = {
  localization: {
    user: string;
    logout: string;
  };
  name: string;
  company?: string;
  login: string;
  openProfile: () => void;
  logout: () => void;
};

const ProfilePopupInner: React.FC<Props> = ({
  name,
  login,
  openProfile,
  localization,
  logout,
  company,
}) => (
  <div className={cx('popupContent')}>
    <div className={cx('role')}>{localization.user}</div>
    <div
      className={cx('userDataWrapper')}
      onClick={openProfile}
    >
      <div className={cx('userData')}>
        <div className={cx('name')}>{name}</div>
        <div className={cx('email')}>{login}</div>
      </div>
    </div>

    {company && (
      <div className={cx('companyData')}>
        <Icon type="officeBuilding" />
        <TextShorter tooltip>{company}</TextShorter>
      </div>
    )}

    <div className={cx('divider')} />

    <div className={cx('logoutWrapper')}>
      <div
        onClick={logout}
        className={cx('link')}
      >
        {localization.logout}
      </div>
    </div>
  </div>
);

export { ProfilePopupInner };
