import React from 'react';
import { useFieldArray } from 'react-hook-form';

import { defaultClassificationValues } from '@common/components/AppToolbar/components/CloudConnect/Forms/FormHyperV';
import { FormDataHyperV } from '@common/components/AppToolbar/components/CloudConnect/types';

import { FormInput, required, useAppFormContext, useAppTranslation } from '@src/common';
import { Button, H3, Icon, WarningMessageBlock } from '@src/kit';
import { bindStyles } from '@src/utils';

import styles from './StorageSettingsStep.module.scss';

const cx = bindStyles(styles);

const StorageSettingsStep: React.FC = () => {
  const { t } = useAppTranslation();

  const { control } = useAppFormContext<FormDataHyperV>();

  const nameControl = 'diskClassifications';

  const { fields, append, remove } = useFieldArray<FormDataHyperV>({
    control,
    name: nameControl,
  });

  const onAppend = () => {
    append(defaultClassificationValues, { shouldFocus: false });
  };

  const onDelete = (index: number) => () => {
    remove(index);
  };

  return (
    <div className={cx('form')}>
      <H3>{t('ConfiguringStorageClassification')}</H3>

      {fields.length > 0 && (
        <WarningMessageBlock>{t('StorageClassificationDescription')}</WarningMessageBlock>
      )}

      <div>
        {fields.map((field, index) => (
          <div
            className={cx('row')}
            key={field.id}
          >
            <FormInput
              classes={{ error: cx('inputError') }}
              size="md"
              name={`${nameControl}.${index}.name`}
              rules={required('FieldRequired')}
              placeholder={t('cld.hyperV.placeholder.name')}
              caption={index === 0 ? t('cld.HyperV.modal.editable.name') : ''}
            />
            <FormInput
              classes={{ error: cx('inputError') }}
              size="md"
              name={`${nameControl}.${index}.maxSize`}
              type="number"
              withControls
              decimals={3}
              units={t('UnitGb')}
              caption={index === 0 ? t('cld.HyperV.modal.editable.size') : ''}
              hint={t('cld.HyperV.modal.editable.hint.size')}
              tooltipClass={cx('tootlipSizeContainer')}
            />
            <div
              onClick={onDelete(index)}
              className={cx('delete')}
            >
              <Icon type="close" />
            </div>
          </div>
        ))}
      </div>

      <div>
        <Button
          variant="text"
          onClick={onAppend}
          size="md"
          text={t('cld.hyperV.btn.text')}
          textClassName={cx('appendButtonText')}
        >
          <div className={cx('buttonContent')}>
            <Icon
              type="close"
              className={cx('plusIcon')}
            />
          </div>
        </Button>
      </div>
    </div>
  );
};

export { StorageSettingsStep };
