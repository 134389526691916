import { FC } from 'react';

import { CreationEntity, CreationEntityProps } from '@kit/components/CmSelect/CreationEntity';

import { bindStyles } from '@src/utils';

import styles from './Footer.module.scss';

const cx = bindStyles(styles);

type Props = {
  isMultiLevel?: boolean;
  creationSettings?: CreationEntityProps;
  selectDescription?: string;
  onClose?: () => void;
};

const Footer: FC<Props> = ({ creationSettings, selectDescription, onClose }) => {
  return (
    <>
      {creationSettings && (
        <CreationEntity
          {...creationSettings}
          onClose={onClose}
        />
      )}
      {selectDescription && <div className={cx('description')}>{selectDescription}</div>}
    </>
  );
};

const renderFooter = ({ isMultiLevel, creationSettings, selectDescription, onClose }: Props) => {
  if (isMultiLevel || (!selectDescription && !creationSettings)) {
    return undefined;
  }

  return (
    <Footer
      isMultiLevel={isMultiLevel}
      selectDescription={selectDescription}
      creationSettings={creationSettings}
      onClose={onClose}
    />
  );
};

export { Footer, renderFooter };
