import React, { useMemo } from 'react';

import { Icon, TextShorter, Tooltip } from '@kit/components';
import { useCMTableContext } from '@common/components/CMTable/context/CMTable.context';

import { bindStyles } from '@src/utils';

import styles from './HeadCell.module.scss';

const cx = bindStyles(styles);

type Props = {
  id: string;
  sortable?: boolean;
  tooltip?: string;
  children?: React.ReactNode;
};

const HeadCell: React.FC<Props> = ({ sortable, tooltip, children, id }) => {
  const { sort } = useCMTableContext();

  const iconType = useMemo(() => {
    if (sort?.sort?.column === id) {
      return sort?.sort?.order === 'asc' ? 'arrow-up' : 'arrow-down';
    }

    return 'arrow-up';
  }, [sort]);

  const onSort = () => sort?.onSort(id);

  return (
    <div
      className={cx('cell', { sortable })}
      onClick={sortable ? onSort : undefined}
    >
      <div className={cx('cell__item')}>
        <TextShorter tooltip>{children}</TextShorter>
      </div>

      {tooltip && (
        <div className={cx('cell__item')}>
          <Tooltip
            place="top"
            text={tooltip}
          >
            <Icon
              type="circle-information-filled"
              size="md"
            />
          </Tooltip>
        </div>
      )}

      {sortable && (
        <div
          className={cx('cell__item', 'cell__sort', {
            cell__sort_active: sort?.sort?.column === id,
          })}
        >
          <Icon
            className={cx('sortIndicator')}
            type={iconType}
            size="md"
          />
        </div>
      )}
    </div>
  );
};

export { HeadCell };
