import { FC } from 'react';

import { CloudsProvider } from '@common/providers/CloudsProvider';
import { Clouds } from '@common/components/Clouds/Clouds';

type Props = {
  onNavigate?: (to: 'tariffs') => void;
};

const CloudsContainer: FC<Props> = ({ onNavigate }) => {
  return (
    <CloudsProvider onNavigate={onNavigate}>
      <Clouds />
    </CloudsProvider>
  );
};

export { CloudsContainer };
