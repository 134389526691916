import {
  AWSFormDTO,
  AWSRegion,
  AWSRequestDTO,
  AWSUpdateRequestDTO,
  AzureConnectionDTO,
  AzureEnterpriseConnectionDTO,
  AzureRequestSubscriptionDTO,
  AzureSubscription,
  AzureSubscriptionsDTO,
  AzureUpdateDTO,
  AzureInfo,
  ConnectedCloudsDataDTO,
  ConnectedCloudStatus,
  FetchProfilesResponse,
  HyperVDTO,
  OpenStackDTO,
  OpenstackFetchProfilesDTO,
  VCloudDirectorFetchProfilesDTO,
  VSphereDTO,
  VSphereFetchProfilesDTO,
  VSphereInfo,
  OpenStackInfo,
} from '@common/components/AppToolbar/components/CloudConnect/types';

import {
  IRestService,
  ResourceUsedSizeDto,
  RestService,
  ServiceAccountInfoDTO,
  VCloudDirectorCreateDTO,
  VCloudDirectorCreateDTONew,
  VCloudEditDTO,
  VCloudEditNewDTO,
  YaCloudCreateDTO,
  YaCloudEditDTO,
  YandexCloudInfoDTO,
  YandexCloudServiceAccountDTO,
  YaSubCloudDTO,
} from '@src/common';
import { ApiResultResponse, UUID } from '@src/@types';

const restService: IRestService = RestService.getInstance();

const fetchAWSRegions = () => {
  const url = `/settings/api/v2/aws/region`;
  return restService.GET<AWSRegion[]>(url);
};

const createdAWSConnection = (data: AWSRequestDTO) => {
  const url = `/settings/api/v2/aws/create_cloud`;
  return restService.POST<AWSRequestDTO, void>(url, {
    data,
    timeout: 60 * 3 * 1000,
  });
};

const fetchAzureSubscriptions = () => {
  const url = `/settings/api/v2/azure/subscription`;
  return restService.GET<AzureSubscription[]>(url);
};

const fetchAzureSubscriptionsByParams = (data: AzureRequestSubscriptionDTO) => {
  const url = `/settings/api/v2/azure/subscription/all`;
  return restService.POST<AzureRequestSubscriptionDTO, AzureSubscriptionsDTO>(url, {
    data,
  });
};

const connectAzure = (data: AzureConnectionDTO) => {
  const url = `/settings/api/v2/azure/create_cloud`;
  return restService.POST<AzureConnectionDTO, void>(url, { data });
};

const connectEnterpriseAzure = (data: AzureEnterpriseConnectionDTO) => {
  const url = `/settings/api/v2/connection/azure_ea`;
  return restService.POST<AzureEnterpriseConnectionDTO, void>(url, { data });
};

const updateEnterpriseAzure = (uuid: string, data: AzureEnterpriseConnectionDTO) => {
  const url = `/settings/api/v2/connection/azure_ea/${uuid}`;
  return restService.POST<AzureEnterpriseConnectionDTO, void>(url, { data });
};

const updateAWS = (uuid: string, data: AWSUpdateRequestDTO) => {
  const url = `/settings/api/v2/connection/aws/${uuid}`;
  return restService.POST<AWSUpdateRequestDTO, void>(url, { data });
};

const updateAzure = (uuid: string, data: AzureUpdateDTO) => {
  const url = `/settings/api/v2/connection/azure/${uuid}`;
  return restService.POST<AzureUpdateDTO, void>(url, { data });
};

const getAzureInfo = (uuid: string) => {
  const url = `/settings/api/v2/cloud/azure/${uuid}`;
  return restService.GET<AzureInfo>(url);
};

const getVSphereInfo = (uuid: string) => {
  const url = `/settings/api/v2/cloud/vsphere/${uuid}`;
  return restService.GET<VSphereInfo>(url);
};

const updateVSphere = (uuid: string, data: VSphereInfo) => {
  const url = `/settings/api/v2/connection/vsphere/${uuid}`;
  return restService.POST<VSphereInfo, void>(url, { data });
};

const getOpenStack = (uuid: string) => {
  const url = `/settings/api/v2/connection/openstack/${uuid}`;
  return restService.GET<OpenStackInfo>(url);
};

const updateOpenStack = (uuid: string, data: OpenStackInfo) => {
  const url = `/settings/api/v2/connection/openstack/${uuid}`;
  return restService.POST<OpenStackInfo, void>(url, { data });
};

const getAWSCloud = (uuid: string) => {
  const url = `/settings/api/v2/cloud/aws/${uuid}`;
  return restService.GET<AWSFormDTO>(url);
};
const connectVSphere = (data: VSphereDTO) => {
  const url = `/settings/api/v2/connection/vsphere`;
  return restService.POST<VSphereDTO, void>(url, { data });
};

const connectOpenStack = (data: OpenStackDTO) => {
  const url = `/settings/api/v2/connection/openstack`;
  return restService.POST<OpenStackDTO, void>(url, { data });
};

const connectHyperV = (data: HyperVDTO) => {
  const url = `/msp/hyperv/connect`;
  return restService.POST<HyperVDTO, ApiResultResponse<void>>(url, { data });
};

const connectYandexCloud = (data: YaCloudCreateDTO) => {
  const url = `/settings/api/v2/connection/yandex`;
  return restService.POST<YaCloudCreateDTO, void>(url, {
    data,
  });
};

const getYandexSubList = (cloudId: string) => {
  const url = `/settings/api/v2/connection/yandex/${cloudId}/yandex_cloud/list`;
  return restService.GET<YaSubCloudDTO[]>(url);
};

const getYandexCloudInfo = (cloudId: string) => {
  const url = `/settings/api/v2/connection/yandex/${cloudId}/yandex_cloud/connection_params`;
  return restService.GET<YandexCloudInfoDTO>(url);
};

const getVCloudInfo = (cloudId: string) => {
  const url = `/settings/api/v2/connection/vcloud/${cloudId}`;
  return restService.GET<VCloudEditDTO>(url);
};

const getNewVCloudInfo = (cloudId: string) => {
  const url = `/settings/api/v2/connection/vcloud/${cloudId}`;
  return restService.GET<VCloudEditNewDTO>(url);
};

const editVCloudInfo = (data: VCloudEditDTO) => {
  const url = `/settings/api/v2/connection/vcloud`;
  return restService.POST<VCloudEditDTO, void>(url, {
    data,
  });
};

const editNewVCloudInfo = (data: VCloudEditNewDTO) => {
  const url = `/settings/api/v2/connection/vcloud`;
  return restService.POST<VCloudEditNewDTO, void>(url, {
    data,
  });
};

const editYandexCloud = (data: YaCloudEditDTO) => {
  const url = `/settings/api/v2/connection/yandex`;
  return restService.POST<YaCloudEditDTO, void>(url, {
    data,
  });
};

const connectVcloud = (data: VCloudDirectorCreateDTO) => {
  const url = `/settings/api/v2/connection/vcloud`;
  return restService.POST<VCloudDirectorCreateDTO, { payload: { data: string } }>(url, {
    data: data,
  });
};

const connectNewVcloud = (data: VCloudDirectorCreateDTONew) => {
  const url = `/settings/api/v2/connection/vcloud`;
  return restService.POST<VCloudDirectorCreateDTONew, { payload: { data: string } }>(url, {
    data: data,
  });
};

const addServiceAccount = (cloudId: string, data: YandexCloudServiceAccountDTO) => {
  const url = `/settings/api/v2/connection/yandex/${cloudId}/service_account`;
  return restService.POST(url, { data });
};

const getServiceAccountInfo = (yandexCloudUuid: UUID) => {
  const url = `/settings/api/v2/connection/yandex/service_account/${yandexCloudUuid}`;
  return restService.GET<ServiceAccountInfoDTO>(url);
};

const fetchProfiles = (dto: VCloudDirectorFetchProfilesDTO) => {
  const url = `/settings/api/v2/vcloud/storage_profiles`;
  return restService.POST<VCloudDirectorFetchProfilesDTO, FetchProfilesResponse>(url, {
    data: dto,
  });
};

const fetchOpenstackProfiles = (dto: OpenstackFetchProfilesDTO) => {
  const url = `/settings/api/v2/openstack/storage_profiles`;
  return restService.POST<OpenstackFetchProfilesDTO, FetchProfilesResponse>(url, { data: dto });
};

const fetchVSphereProfiles = (dto: VSphereFetchProfilesDTO) => {
  const url = `/settings/api/v2/vsphere/storage_profiles`;
  return restService.POST<VSphereFetchProfilesDTO, FetchProfilesResponse>(url, { data: dto });
};

const fetchConnectedClouds = () => {
  const url = '/settings/api/v2/cloud_active_connection';
  return restService.GET<ConnectedCloudsDataDTO[]>(url);
};

const fetchDeleteCloud = (cloudId: UUID) => {
  const url = `/settings/api/v2/cloud/${cloudId}`;
  return restService.DELETE(url, {
    timeout: 60000,
  });
};

const fetchChangeStatus = (cloudId: UUID, status: ConnectedCloudStatus) => {
  const url = `/settings/api/v2/cloud/${cloudId}/${status === 'ACTIVE' ? 'active' : 'stopped'}`;
  return restService.POST(url);
};

const checkCredentialVCloud = (dto: VCloudDirectorFetchProfilesDTO) => {
  const url = `/settings/api/v2/vcloud/auth`;
  return restService.POST<VCloudDirectorFetchProfilesDTO, void>(url, {
    data: dto,
  });
};

const getUsedResources = (cloudId: UUID) => {
  const url = `/msp/cloud/${cloudId}/usedResources`;
  return restService.GET<ApiResultResponse<ResourceUsedSizeDto[]>>(url);
};

export {
  fetchAWSRegions,
  updateAzure,
  createdAWSConnection,
  connectAzure,
  fetchAzureSubscriptions,
  fetchAzureSubscriptionsByParams,
  connectVSphere,
  connectOpenStack,
  connectHyperV,
  connectYandexCloud,
  getYandexSubList,
  getYandexCloudInfo,
  editYandexCloud,
  connectVcloud,
  addServiceAccount,
  getServiceAccountInfo,
  fetchProfiles,
  fetchConnectedClouds,
  fetchDeleteCloud,
  fetchChangeStatus,
  fetchOpenstackProfiles,
  checkCredentialVCloud,
  getVCloudInfo,
  updateVSphere,
  fetchVSphereProfiles,
  editVCloudInfo,
  connectEnterpriseAzure,
  updateEnterpriseAzure,
  updateAWS,
  getAWSCloud,
  getAzureInfo,
  getVSphereInfo,
  updateOpenStack,
  getOpenStack,
  getUsedResources,
  connectNewVcloud,
  getNewVCloudInfo,
  editNewVCloudInfo,
};
