import { FC, Suspense } from 'react';
import { I18nextProvider } from 'react-i18next';

import i18next from 'i18next';

type Props = {
  loadComponent?: JSX.Element;
};

const TranslateProvider: FC<Props> = ({ loadComponent, children }) => {
  return (
    <I18nextProvider i18n={i18next}>
      <Suspense fallback={loadComponent || true}>{children}</Suspense>
    </I18nextProvider>
  );
};

TranslateProvider.displayName = 'TranslateProvider';

export { TranslateProvider };
