import { Search } from '@common/components/UserRoles/components';
import { UserCreate } from '@common/components/UserRoles/components/UserCreate/UserCreate';
import { UsersTable } from '@common/components/UserRoles/components/UserTable';
import { useUsersTable } from '@common/components/UserRoles/hooks';
import { SettingsWrapper } from '@common/components/SettingsWrapper';

import { CMTableProvider, useAppTranslation, usePopupControls } from '@src/common';
import { Button, Plug } from '@src/kit';
import { bindStyles } from '@src/utils';

import EmptyUsersPlug from '@assets/images/plugs/Operator.svg';

import styles from './UserRoles.module.scss';

const cx = bindStyles(styles);

const UserRoles = () => {
  const { t } = useAppTranslation();
  const { table, emptyTable, search, onSearch, setSearch, onReset } = useUsersTable();
  const { isOpened, openPopup, closePopup } = usePopupControls();

  return (
    <SettingsWrapper settingsPage="users">
      <div className={cx('wrapper')}>
        <div className={cx('header')}>
          <div className={cx('controls')}>
            <Search
              value={search}
              onReset={onReset}
              onChange={setSearch}
              onSearch={onSearch}
            />
            <Button
              size="md"
              text={t('Add')}
              onClick={openPopup}
            />
          </div>
        </div>
        <div className={cx('block')}>
          {emptyTable ? (
            <Plug
              customImage={<EmptyUsersPlug />}
              className={cx('plug')}
              text={
                <>
                  <span>{t('cld.users.emptyPlug.text')}</span>&nbsp;
                  <Button
                    variant="text"
                    className={cx('plug-link')}
                    onClick={openPopup}
                  >
                    {t('Add')}
                  </Button>
                </>
              }
            />
          ) : (
            <CMTableProvider value={table}>
              <UsersTable
                onResetFilters={onReset}
                reFetch={onSearch}
                clearSelection={() => table.selection?.onSelectRow(null)}
              />
            </CMTableProvider>
          )}
        </div>
      </div>
      {isOpened && (
        <UserCreate
          onClose={closePopup}
          reFetch={onSearch}
        />
      )}
    </SettingsWrapper>
  );
};

export { UserRoles };
