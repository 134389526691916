import React, { PropsWithChildren } from 'react';

import { Button, Icon } from '@src/kit';

type Props = {
  count: number;
  active: boolean;
  onToggle: () => void;
};

const ToggleFilterButton: React.FC<PropsWithChildren<Props>> = ({
  count,
  active,
  onToggle,
  children,
}) => (
  <Button
    variant="text"
    onClick={onToggle}
    text={children as string}
    active={active}
    count={count > 0 ? count : undefined}
  >
    <Icon type="filter-alt" />
  </Button>
);

export { ToggleFilterButton };
