import { useEffect, useState } from 'react';

import { fetchUsersList } from '@common/components/UserRoles/api/requests';

import { useAppTranslation } from '@src/common';
import {
  CmTableSort,
  FetchDataParams,
  useCMTable,
  useCMTablePagination,
  useCMTableSelection,
  useCMTableSort,
} from '@src/common';

import { UsersFilterSearchDTO, UserViewDTO } from '../types';

const DEFAULT_FILTERS: CmTableSort<UserViewDTO> = {
  column: 'createdAt',
  order: 'desc',
};

const useUsersTable = () => {
  const { t } = useAppTranslation();
  const [search, setSearch] = useState<string | null>('');

  const [emptyTable, setEmptyTable] = useState<boolean | null>(null);

  const fetchTable = async ({ sort, page, size }: FetchDataParams<UserViewDTO>) => {
    try {
      const params: UsersFilterSearchDTO = {
        sort,
        page: page || 0,
        pageSize: size || 0,
        name: search || '',
      };

      const {
        data: { result, totalItems },
      } = await fetchUsersList(params);

      if (emptyTable === null) {
        setEmptyTable(totalItems === 0);
      }
      if (emptyTable && result.length > 0) {
        setEmptyTable(false);
      }

      return {
        result,
        totalItems,
      };
    } catch (err) {
      console.log(err);
      return {
        result: [],
        totalItems: 0,
      };
    }
  };

  const sort = useCMTableSort<UserViewDTO>(DEFAULT_FILTERS);

  const selection = useCMTableSelection<UserViewDTO>();
  const pagination = useCMTablePagination({
    locale: {
      rowPerPage: t('NewItemsPerPage'),
      from: t('OfPagination'),
      recordsTable: t('RecordsTable'),
      totalPages: t('TotalPages'),
    },
  });
  const table = useCMTable<UserViewDTO>(fetchTable, pagination, sort, selection);

  useEffect(() => {
    table.fetchData();
  }, [sort.sort, pagination.page, pagination.size]);

  useEffect(() => {
    if (search === null) {
      table.fetchData();
    }
  }, [search]);

  return {
    table,
    emptyTable,
    onSearch: table.fetchData,
    onReset: () => setSearch(null),
    search,
    setSearch,
  };
};

export { useUsersTable };
