import { FC } from 'react';

import { Button } from '@kit/components';

import { bindStyles } from '@src/utils';

import styles from './GeneralAppErrorMessage.module.scss';

type Props = {
  title: string;
  comment: string;
  buttonText: string;
  icon: JSX.Element;
  onButtonClick: () => void;
};

const cx = bindStyles(styles);

const GeneralAppErrorMessage: FC<Props> = ({ icon, comment, buttonText, onButtonClick, title }) => {
  return (
    <div className={cx('wrapper')}>
      <div className={cx('icon')}>{icon}</div>
      <div className={cx('textsWrapper')}>
        <div className={cx('title')}>{title}</div>
        <div className={cx('comment')}>{comment}</div>
        <Button
          className={cx('button')}
          text={buttonText}
          onClick={onButtonClick}
        />
      </div>
    </div>
  );
};

export { GeneralAppErrorMessage };
