import { FC, useEffect, useMemo } from 'react';

import { TariffPriceResourceQuotaDTO } from '@common/components/TariffAndPricesComponent/api/models';
import { TARIFF_TYPE_MAP } from '@common/components/TariffAndPricesComponent/components/TariffItem';

import { bindStyles } from '@src/utils';
import { FormInput, useAppFormContext, useAppTranslation, useCurrencyContext } from '@src/common';
import { Button, Icon, Tooltip } from '@src/kit';

import styles from './MixedTariffPriceEdit.module.scss';

type Props = {
  nameKey: 'fixQuotas' | 'factQuotas';
  remove: () => void;
  index: number;
};

const cx = bindStyles(styles);

const MixedTariffPriceEdit: FC<Props> = ({ index, nameKey, remove }) => {
  const { t } = useAppTranslation();
  const { customerCurrency, currenciesList } = useCurrencyContext();
  const { watch, clearErrors, setValue, setError } = useAppFormContext();

  const quota: TariffPriceResourceQuotaDTO = watch(`${nameKey}.${index}`);
  const formValues = watch();

  const currencySign = useMemo(() => {
    return currenciesList.find((it) => it.code === customerCurrency)?.name;
  }, [customerCurrency, currenciesList]);

  const quotaFromKey = `${nameKey}.${index}.quotaFrom`;
  const quotaToKey = `${nameKey}.${index}.quotaTo`;
  const rateKey = `${nameKey}.${index}.rate`;

  const quotaFromValue: TariffPriceResourceQuotaDTO['quotaFrom'] = watch(quotaFromKey);
  const quotaToValue: TariffPriceResourceQuotaDTO['quotaTo'] = watch(quotaToKey);
  const rateValue: TariffPriceResourceQuotaDTO['rate'] = watch(rateKey);

  const fullPrice = useMemo(() => {
    if (!quotaToValue || quotaFromValue === null) {
      return '-';
    }

    return (
      ((quotaToValue - quotaFromValue) * Number(String(rateValue).replaceAll(',', '.'))).toFixed(
        2,
      ) + ` ${currencySign}`
    );
  }, [quotaFromValue, quotaToValue, rateValue]);

  const handleChangeQuotaTo = (value: string) => {
    setValue(quotaToKey, value);
    clearErrors(quotaToKey);
    if (nameKey === 'fixQuotas') {
      const nextFixQuota = formValues.fixQuotas[index + 1];

      if (!nextFixQuota) {
        const nextFactQuota = formValues.factQuotas[0];

        if (nextFactQuota) {
          setValue(`factQuotas.0.quotaFrom`, value);
          if (Number(nextFactQuota.quotaTo) < Number(value)) {
            setError(`factQuotas.0.quotaTo`, { message: t('cos.tariff.mixed.price.error') });
          } else {
            clearErrors(`factQuotas.0.quotaTo`);
          }
        }
      } else {
        setValue(`${nameKey}.${index + 1}.quotaFrom`, value);
        if (Number(nextFixQuota.quotaTo) < Number(value)) {
          setError(`${nameKey}.${index + 1}.quotaTo`, {
            message: t('cos.tariff.mixed.price.error'),
          });
        } else {
          clearErrors(`${nameKey}.${index + 1}.quotaTo`);
        }
      }
    } else {
      const nextFactQuota = formValues.factQuotas[index + 1];

      if (nextFactQuota) {
        setValue(`factQuotas.${index + 1}.quotaFrom`, value);

        if (Number(nextFactQuota.quotaTo) < Number(value)) {
          setError(`factQuotas.${index + 1}.quotaTo`, {
            message: t('cos.tariff.mixed.price.error'),
          });
        } else {
          clearErrors(`factQuotas.${index + 1}.quotaTo`);
        }
      }
    }
  };

  return (
    <div className={cx('wrapper')}>
      <div className={cx('column')}>
        <div className={cx('caption')}>{t('cos.tariff.mixed.quota.caption')}</div>
        <div className={cx('row')}>
          <FormInput
            name={quotaFromKey}
            size="md"
            type="number"
            disabled
            suffix={t('UnitGb')}
            prefix={t('from.size')}
          />
          <FormInput
            name={quotaToKey}
            size="md"
            onChange={handleChangeQuotaTo}
            type="number"
            placeholder="-"
            suffix={t('UnitGb')}
            prefix={t('to')}
          />
        </div>
      </div>
      <div className={cx('column')}>
        <div className={cx('caption')}>
          <Tooltip
            text={t('cos.settings.tariffprices.price.tooltip') as string}
            position={['top center']}
          >
            {t('cos.settings.tariffprices.price')}, {currencySign}
          </Tooltip>
        </div>
        <div className={cx('row')}>
          <FormInput
            name={rateKey}
            size="md"
            type="number"
            decimals={2}
          />
        </div>
      </div>
      <div className={cx('column')}>
        <div className={cx('caption')}>{t('cos.tariff.mixed.fullPrice.caption')}</div>
        <div className={cx('row', 'center')}>
          <div className={cx('fullPrice')}>{fullPrice}</div>
        </div>
      </div>
      <div className={cx('column')}>
        <div className={cx('caption')}>{t('cos.tariff.mixed.period.caption')}</div>
        <div className={cx('row', 'center')}>
          {t(`cos.settings.tariffprices.input.tariff.${TARIFF_TYPE_MAP[quota.billingType]}`)}
        </div>
      </div>
      <div className={cx('column', 'button')}>
        <Button
          variant="text"
          onClick={remove}
        >
          <Icon
            className={cx('deleteIcon')}
            type="delete"
          />
        </Button>
      </div>
    </div>
  );
};

export { MixedTariffPriceEdit };
