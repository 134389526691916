import React, { useEffect, useRef, useState } from 'react';

import TableLoaderFrame from '@kit/components/Table/Loader';

import styles from './Table.module.scss';

type Props = {
  loading?: boolean;
  countSkeletons?: number;
  delay?: number;
};

const withLoader = <T,>(WrappedComponent: React.FC<T>) => {
  const Component = (props: T & Props) => {
    const { loading, countSkeletons, delay = 200 } = props;
    const componentProps = props as unknown as T;

    const [isLoaderVisible, setIsLoaderVisible] = useState(false);

    const timeout = useRef<ReturnType<typeof setTimeout>>();

    useEffect(() => {
      if (loading && !isLoaderVisible) {
        timeout.current = setTimeout(() => {
          setIsLoaderVisible(true);
        }, delay);
      }
      if (!loading) {
        timeout.current && clearTimeout(timeout.current);
        setIsLoaderVisible(false);
      }
      return () => {
        timeout.current && clearTimeout(timeout.current);
      };
    }, [isLoaderVisible, loading]);

    return loading && isLoaderVisible ? (
      <div className={styles.tableLoader}>
        <TableLoaderFrame count={countSkeletons} />
      </div>
    ) : (
      <WrappedComponent {...componentProps} />
    );
  };
  Component.displayName = `WithLoader(${WrappedComponent.displayName})`;

  return Component;
};

export { withLoader };
export type { Props as WithLoaderProps };
