import { useMemo, useState } from 'react';

import {
  FastDatesProps,
  IntervalProps,
  IntervalVariants,
} from '@kit/components/Calendar/Container';

import { CalendarValue } from '@src/kit';

import { useMonthFastDatesOptions } from './useMonthFastDatesOptions';
import { useDayFastDatesOptions } from './useDayFastDatesOptions';
import { useCalendarIntervalOptions } from './useCalendarIntervalOptions';

type Props = {
  initialInterval?: IntervalVariants;
  initialFastDate?: FastDatesProps['activeFastDate'];
  initialPeriod?: CalendarValue;
};

const useCalendar = (props?: Props) => {
  const [currentInterval, setCurrentInterval] = useState<IntervalProps['interval']>(
    props?.initialInterval || IntervalVariants.DAY,
  );
  const [interval, setInterval] = useState<IntervalProps['interval']>(
    props?.initialInterval || IntervalVariants.DAY,
  );
  const { intervals } = useCalendarIntervalOptions();
  const month = useMonthFastDatesOptions();
  const day = useDayFastDatesOptions();

  const [fastDate, setFastDate] = useState<FastDatesProps['activeFastDate']>(
    props?.initialFastDate || day.fastDates[1],
  );

  const [period, setPeriod] = useState<CalendarValue>(
    props?.initialPeriod || day.DATE_RANGES.month,
  );
  const [currentPeriod, setCurrentPeriod] = useState<CalendarValue>(
    props?.initialPeriod || day.DATE_RANGES.month,
  );

  const fastDates = useMemo(() => {
    return currentInterval === IntervalVariants.DAY ? day.fastDates : month.fastDates;
  }, [currentInterval]);

  const onChangeInterval = (value: IntervalVariants, force?: boolean) => {
    if (force && interval !== value) {
      setCurrentInterval(value);
      setInterval(value);
    } else if (currentInterval !== value) {
      setCurrentInterval(value);

      if (value === IntervalVariants.DAY) {
        setCurrentPeriod(day.DATE_RANGES.month);
        setFastDate(day.fastDates[1]);
      } else {
        setFastDate(undefined);
      }
    }
  };

  const onChangeFastDay = (date: FastDatesProps['activeFastDate']) => {
    if (date?.value) {
      setCurrentPeriod(date.value);
      setFastDate(day.fastDates[0]);
    }

    setFastDate(date);
  };

  const onReset = () => {
    setPeriod(day.DATE_RANGES.month);
    setCurrentPeriod(day.DATE_RANGES.month);
    setFastDate(day.fastDates[1]);
  };

  const onChangeValue = (value: CalendarValue) => {
    setPeriod(value);
    setInterval(currentInterval);
  };

  const onChangeCurrentValue = (value: CalendarValue) => {
    setCurrentPeriod(value);
    setFastDate(undefined);
  };

  return {
    interval,
    currentInterval,
    fastDates,
    fastDate,
    onChangeInterval,
    setFastDate,
    onReset,
    onChangeFastDay,
    period,
    currentPeriod,
    onChangeValue,
    onChangeCurrentValue,
    intervals,
  };
};

export { useCalendar };
