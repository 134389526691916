import React, { useState } from 'react';
import { OptionProps } from 'react-select';

import { Icon } from '@kit/components/Icon';
import { Input } from '@kit/components/Input';

type Props = {
  items: React.ReactNode[];
};

type ReturnValue = {
  input: React.ReactNode;
  searchedItems: React.ReactNode[];
};

const useLocalSearchInSelect = ({ items = [] }: Props): ReturnValue => {
  const [search, setSearch] = useState('');

  // don't lose focus on input during interaction
  const stopPropagation = (event: React.MouseEvent | React.KeyboardEvent) =>
    event.stopPropagation();

  const searchedItems = items.filter((item) =>
    (item as React.Component<OptionProps>).props.label
      ?.toLowerCase()
      .includes(search.toLowerCase()),
  );

  const clearSearch = (event: React.MouseEvent) => {
    stopPropagation(event);
    setSearch('');
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => setSearch(event.target.value);

  const input = (
    <Input
      size="md"
      value={search}
      onMouseDown={stopPropagation}
      onKeyDown={stopPropagation}
      onChange={onChange}
      actions={[
        { icon: <Icon type="search" />, alignLeft: true },
        ...(search
          ? [
              {
                icon: (
                  <Icon
                    type="close"
                    onMouseDown={clearSearch}
                  />
                ),
              },
            ]
          : []),
      ]}
    />
  );

  return { input, searchedItems };
};

export { useLocalSearchInSelect };
