import { FC, useEffect, useMemo, useState } from 'react';

import { CustomCurrencyForm } from '@common/components/CurrenciesLanguageComponent/CurrencySelect/CustomCurrencyForm';
import { SourceForm } from '@common/components/CurrenciesLanguageComponent/CurrencySelect/SourceForm';

import { bindStyles, ErrorUtils } from '@src/utils';
import {
  CurrencyBank,
  CurrencyTab,
  useCurrencyContext,
  useToast,
  useTranslationPrefix,
} from '@src/common';
import { Button, ButtonGroup, SelectOption, Tab, TabContext, TabList, TabPanel } from '@src/kit';
import { CurrencyTypes } from '@src/@types';

import styles from '../CurrenciesLanguage.module.scss';

const cx = bindStyles(styles);

const MAX_PAIRS = 3;

const createAllPairs = (arr: string[]) => {
  {
    let result: string[][] = [];
    const n = arr.length;
    for (let i = 0; i < n; i++) {
      for (let j = 0; j < n; j++) {
        if (i !== j) result = [...result, [arr[i], arr[j]]];
      }
    }

    return result;
  }
};

const CurrencySelect: FC = () => {
  const { tPrefix, t } = useTranslationPrefix('cos.currencyModal.');
  const toast = useToast();
  const {
    changeCurrency,
    temporaryProviderCurrencies,
    currenciesList: fetchedCurrenciesList,
    fetchedCurrentCurrency,
    currentBank: storedBank,
    changeCurrentRates,
    initialTab,
    fetchData,
    allCurrencies: usedCurrencies,
    getCurrencyDirectory,
    setCurrentBank,
    addCurrencyPair,
    form,
  } = useCurrencyContext();

  const [currentCurrency, setCurrentCurrency] = useState(fetchedCurrentCurrency);
  const [currentTab, setCurrentTab] = useState<CurrencyTab>(CurrencyTab.CUSTOM);
  const [activeBank, setActiveBank] = useState<CurrencyBank>(storedBank);
  const [isLoading, setIsLoading] = useState(false);

  const currenciesListOptions = useMemo(
    () =>
      fetchedCurrenciesList.map(
        (item) =>
          ({
            label: `${item.name} (${item.code})`,
            value: item.code,
          } as SelectOption<CurrencyTypes>),
      ),
    [fetchedCurrenciesList],
  );

  const customValues = form.getValues();

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      if (currentTab === CurrencyTab.CUSTOM) {
        if (customValues.currencies.length === MAX_PAIRS) {
          await changeCurrentRates(customValues.currencies);
        } else {
          const mixedRates = temporaryProviderCurrencies.map((provider) => {
            const candidate = customValues.currencies.find(
              (custom) =>
                (custom.source === provider.source && custom.receiver === provider.receiver) ||
                (custom.receiver === provider.source && custom.source === provider.receiver),
            );

            return candidate || provider;
          });
          await changeCurrentRates(mixedRates);
        }
        await getCurrencyDirectory();
        await changeCurrency({ code: currentCurrency });
      } else {
        if (temporaryProviderCurrencies.length) {
          await changeCurrentRates(temporaryProviderCurrencies);
          await changeCurrency({ code: currentCurrency });
        }
      }
    } catch (error) {
      const errorMsg = ErrorUtils.handleApiError(error);
      toast.error({ text: errorMsg });
    } finally {
      setIsLoading(false);
    }
    setCurrentBank(activeBank);
  };

  const changeTab = (key: string) => {
    setCurrentTab(key as CurrencyTab);
  };

  const handleButtonInGroupClick = (el: CurrencyTypes) => {
    setCurrentCurrency(el);
  };

  const disableButton = useMemo(() => {
    if (currentTab === CurrencyTab.PROVIDER) {
      return temporaryProviderCurrencies.some((it) => !it.receiver || !it.source);
    } else {
      return customValues.currencies.some((it) => !it.receiver || !it.source || !Number(it.rate));
    }
  }, [customValues, temporaryProviderCurrencies, currentTab]);

  useEffect(() => {
    setCurrentCurrency(fetchedCurrentCurrency);
  }, [fetchedCurrentCurrency]);

  useEffect(() => {
    const allPairs = createAllPairs(usedCurrencies);

    allPairs.forEach((item) => {
      addCurrencyPair(item);
    });
  }, [usedCurrencies]);

  useEffect(() => {
    fetchData();
    getCurrencyDirectory();
  }, []);

  useEffect(() => {
    if (initialTab) {
      setCurrentTab(initialTab);
    }
  }, [initialTab]);

  useEffect(() => {
    setActiveBank(storedBank);
  }, [storedBank]);

  return (
    <div className={cx('wrapper-currency')}>
      <div className={cx('currency-label')}>{t('settings.currencyLanguage.currencyLabel')}</div>
      <div className={cx('currency-content')}>
        <ButtonGroup
          gap="0"
          className={cx('buttonGroup')}
        >
          {currenciesListOptions.map((el, id) => (
            <Button
              onClick={() => handleButtonInGroupClick(el.value)}
              variant="outline"
              size="md"
              key={id}
              text={el.label as string}
              className={cx('buttonInGroup')}
              active={currentCurrency === el.value}
            />
          ))}
        </ButtonGroup>

        <TabContext value={currentTab}>
          <TabList
            tabListClassName={cx('tabList')}
            onChange={changeTab}
            tabClassName={cx('tab')}
            size="lg"
            variant="checkbox"
          >
            <Tab
              iconPosition="left"
              label={tPrefix('trueSource')}
              value={CurrencyTab.PROVIDER}
            >
              <div />
            </Tab>
            <Tab
              iconPosition="left"
              label={tPrefix('customRate')}
              value={CurrencyTab.CUSTOM}
            >
              <div />
            </Tab>
          </TabList>
          <div className={cx('tabPanelContent')}>
            <TabPanel value={CurrencyTab.PROVIDER}>
              <SourceForm
                currentBank={activeBank}
                setCurrentBank={setActiveBank}
                currenciesList={currenciesListOptions}
                currentCurrency={currentCurrency}
              />
            </TabPanel>

            <TabPanel value={CurrencyTab.CUSTOM}>
              <CustomCurrencyForm />
            </TabPanel>
          </div>

          <Button
            disabled={disableButton}
            type="submit"
            text={t('Apply')}
            size="md"
            variant="filled"
            className={cx('button')}
            onClick={handleSubmit}
            isLoading={isLoading}
          />
        </TabContext>
      </div>
    </div>
  );
};

export { CurrencySelect };
