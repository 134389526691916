import React from 'react';

import { TextShorter } from '@kit/components';

import { CLOUD_ICON_DICTIONARY, CLOUD_NAME_DICTIONARY } from '@src/constants';
import { CloudTypes } from '@src/@types';
import { bindStyles } from '@src/utils';

import styles from './CloudItem.module.scss';

const cx = bindStyles(styles);

type Props = {
  cloudType: CloudTypes;
  cloudName?: string;
  className?: string;
};

const CloudItem: React.FC<Props> = ({ cloudType, cloudName, className }) => {
  return (
    <div className={cx('body', className)}>
      <div className={cx('body-inner')}>
        <div className={cx('icon')}>{CLOUD_ICON_DICTIONARY[cloudType]}</div>
        <TextShorter
          tooltip
          className={cx('textShorter')}
        >
          {cloudName ?? CLOUD_NAME_DICTIONARY[cloudType]}
        </TextShorter>
      </div>
    </div>
  );
};

export { CloudItem };
