import React from 'react';

import { Stages } from '@common/components/AppToolbar/components/CloudConnect/types';
import { PROVIDER_LIST } from '@common/components/AppToolbar/components/CloudConnect/constants';

import { useAnalytics, useAppToolbarContext, useAppTranslation, useCloudConnectContext } from '@src/common';
import { CloudTypes } from '@src/@types';
import { ProviderMiniCard } from '@src/kit';
import { CLOUD_ICON_DICTIONARY, CLOUD_NAME_DICTIONARY } from '@src/constants';

import styles from './ProvidersTable.module.scss';

type Props = {
  cardsToShow: string;
  selectedProvider?: CloudTypes;
};

const ProvidersTable: React.FC<Props> = ({ selectedProvider }) => {
  const { t } = useAppTranslation();
  const { onSelectProvider, selectStage } = useCloudConnectContext();
  const { brand } = useAppToolbarContext();
  const { system } = useAppToolbarContext();
  const { logEvent } = useAnalytics();

  const onCardClick = (provider: CloudTypes, title: string) => {
    onSelectProvider(provider);
    selectStage({
      stage: Stages.providerForm,
      title: t(title),
    });
    logEvent('Selected cloud connection', { cloudType: provider });
  };

  const providers = PROVIDER_LIST.filter((provider) => {
    if (provider.provider === 'Yandex') {
      return brand === 'cloudmaster';
    }

    if (provider.provider === 'HyperV' && system) {
      return system === 'clouder';
    }

    return true;
  });

  return (
    <div className={styles.grid}>
      {providers.map(({ provider, disabled, title, version }) => (
        <ProviderMiniCard
          key={provider}
          selected={provider === selectedProvider}
          disabled={disabled}
          onClick={() => onCardClick(provider, title)}
        >
          {CLOUD_ICON_DICTIONARY[provider] && (
            <div className={styles.iconContainer}>{CLOUD_ICON_DICTIONARY[provider]}</div>
          )}
          <div className={styles.texts}>
            <div className={styles.minicardContent}>{CLOUD_NAME_DICTIONARY[provider]}</div>
            {version && <div className={styles.version}>{t(version)}</div>}
          </div>
        </ProviderMiniCard>
      ))}
    </div>
  );
};

export { ProvidersTable };
