import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

import { AxiosError } from 'axios';
import {
  connectOpenStack,
  getOpenStack,
  updateOpenStack,
} from '@common/components/AppToolbar/api/cloud-connections';
import { useNewConnectionContext } from '@common/components/Clouds/components/NewConnection/NewConnectionContext';
import {
  OpenStackFormDTO,
  OpenStackInfo,
} from '@common/components/AppToolbar/components/CloudConnect/types';
import { StorageSettingsStep } from '@common/components/Clouds/components/NewConnection/Forms/OpenStackConnection/StorageSettingsStep';
import { ConnectionSteps } from '@common/components/Clouds/components';
import {
  StatusInfo,
  StatusInfoVariant,
} from '@common/components/Clouds/components/NewConnection/StatusInfo';
import { useCloudsContext } from '@common/providers/CloudsProvider';

import {
  Form,
  StorageProfile,
  useAnalytics,
  useAppTranslation,
  useFormApiError,
  useToast,
} from '@src/common';
import { bindStyles, gbToMb, mbToGb } from '@src/utils';
import { Button } from '@src/kit';
import { CloudTypes, Gb } from '@src/@types';
import { CLOUD_NAME_DICTIONARY } from '@src/constants';

import { ConnectionStep } from './ConnectionStep';
import styles from './OpenStackConnection.module.scss';

const cx = bindStyles(styles);

const OpenStackConnection: FC = () => {
  const {
    onBack,
    currentStep,
    onNext,
    changeStatusInfoSettings,
    statusInfoSettings,
    selectedProvider,
  } = useNewConnectionContext();
  const { t } = useAppTranslation();
  const { cloudEdit, editableCloud } = useCloudsContext();
  const [initLoading, setInitLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { logSuccessEvent, logErrorEvent } = useAnalytics();
  const toast = useToast();

  const form = useForm<OpenStackFormDTO>({
    mode: 'onBlur',
    defaultValues: {
      nativeRegionName: 'RegionOne',
      storageProfiles: [],
    },
  });
  const { apiErrors, handleError } = useFormApiError();
  const { append } = useFieldArray({
    control: form.control,
    name: 'storageProfiles',
  });
  const createNewConnect = async (dto: OpenStackFormDTO) => {
    try {
      setIsLoading(true);
      changeStatusInfoSettings({
        variant: StatusInfoVariant.LOADING,
        cloudName: dto.name,
      });
      const storageProfiles: StorageProfile[] =
        dto.storageProfiles?.map((item) => {
          return {
            name: item.key,
            price: item.value,
            maxSize: item.maxSize.length ? gbToMb(Number(item.maxSize) as Gb) : undefined,
          };
        }) || [];
      await connectOpenStack({ ...dto, storageProfiles });
      logSuccessEvent('Created cloud connection', { cloudType: CLOUD_NAME_DICTIONARY.OpenStack });
      changeStatusInfoSettings({
        variant: StatusInfoVariant.SUCCESS,
        cloudName: dto.name,
      });
    } catch (error) {
      changeStatusInfoSettings({
        variant: StatusInfoVariant.ERROR,
        cloudName: dto.name,
      });
      handleError(error as AxiosError);
      logErrorEvent('Created cloud connection', { cloudType: CLOUD_NAME_DICTIONARY.OpenStack });
    } finally {
      setIsLoading(false);
    }
  };

  const onEditSubmit = useCallback(
    async (dto: OpenStackFormDTO) => {
      try {
        if (editableCloud?.id) {
          setIsLoading(true);
          changeStatusInfoSettings({
            variant: StatusInfoVariant.LOADING,
            cloudName: dto.name,
          });
          const storageProfiles: StorageProfile[] =
            dto.storageProfiles?.map((item) => {
              return {
                name: item.key,
                price: String(item.value),
              };
            }) || [];

          const data: OpenStackInfo = {
            name: dto.name,
            storageProfiles,
            cpuPrice: dto.cpuPrice,
            diskGbPrice: dto.diskGbPrice,
            memoryGbPrice: dto.memoryGbPrice,
          };
          await updateOpenStack(editableCloud.id, data);
          toast.success({ text: t('cos.connect.cloud.edit.success') });
          logSuccessEvent('Edited cloud connection', {
            id: editableCloud?.id,
            cloudType: CLOUD_NAME_DICTIONARY.OpenStack,
          });
          changeStatusInfoSettings({
            variant: StatusInfoVariant.SUCCESS,
            cloudName: dto.name,
          });
        }
      } catch (e) {
        changeStatusInfoSettings({
          variant: StatusInfoVariant.ERROR,
          cloudName: dto.name,
        });
        handleError(e as AxiosError);
        logErrorEvent('Edited cloud connection', {
          id: editableCloud?.id,
          cloudType: CLOUD_NAME_DICTIONARY.OpenStack,
        });
      } finally {
        setIsLoading(false);
      }
    },
    [editableCloud],
  );

  const onSubmit = useCallback(() => {
    if (currentStep === ConnectionSteps.SETTINGS) {
      form.handleSubmit(() => {
        onNext();
      })();
    } else if (currentStep === ConnectionSteps.POLICY) {
      if (cloudEdit) {
        onNext();
        form.handleSubmit(onEditSubmit)();
      } else {
        onNext();
        form.handleSubmit(createNewConnect)();
      }
    }
  }, [currentStep, cloudEdit]);

  const values = form.watch();

  const isDirty = useMemo(() => {
    const { username, password, keystoneAuthUrl, name, domain } = values;
    return !username || !password || !name || !keystoneAuthUrl || !domain;
  }, [values]);

  const renderedSteps = useMemo(() => {
    switch (currentStep) {
      case ConnectionSteps.SETTINGS:
        return <ConnectionStep />;
      case ConnectionSteps.POLICY:
        return <StorageSettingsStep />;
    }
  }, [currentStep]);

  const fetchCloudInfo = useCallback(async () => {
    if (editableCloud?.id) {
      try {
        setInitLoading(true);
        const response = await getOpenStack(editableCloud.id);
        form.setValue('name', response.data.name);
        form.setValue('cpuPrice', response.data.cpuPrice);
        form.setValue('memoryGbPrice', response.data.memoryGbPrice);
        form.setValue('diskGbPrice', response.data.diskGbPrice);
        if (response.data.storageProfiles) {
          response.data.storageProfiles.forEach((item) => {
            if (item) {
              append(
                {
                  value: String(item.price),
                  key: item.name,
                  maxSize: item.maxSize ? String(mbToGb(item.maxSize)) : undefined,
                },
                {
                  shouldFocus: false,
                },
              );
            }
          });
        }
      } catch (e) {
        handleError(e as AxiosError);
      } finally {
        setInitLoading(false);
      }
    }
  }, [editableCloud]);

  useEffect(() => {
    if (cloudEdit) {
      fetchCloudInfo();
    }
  }, [cloudEdit]);

  const buttonLoading = () => {
    if (cloudEdit) {
      return isLoading || initLoading;
    } else {
      return isLoading;
    }
  };

  const buttonText = useMemo(() => {
    if (currentStep === ConnectionSteps.SETTINGS) {
      return t('clouds.connection.button.next');
    } else if (currentStep === ConnectionSteps.POLICY && cloudEdit) {
      return t('cos.cloudConnect.edit.save');
    } else {
      return t('clouds.connection.button.connect');
    }
  }, [cloudEdit, currentStep]);

  return (
    <Form<OpenStackFormDTO>
      formMethods={form}
      onSubmit={onSubmit}
      apiErrors={apiErrors}
      className={cx('form')}
    >
      {currentStep === ConnectionSteps.STATUS ? (
        <StatusInfo
          cloudType={selectedProvider as CloudTypes}
          variant={statusInfoSettings.variant}
          cloudName={statusInfoSettings.cloudName}
        />
      ) : (
        <>
          <div className={cx('inputs')}>{renderedSteps}</div>
          <div className={cx('buttons')}>
            <Button
              text={t('clouds.connection.button.back')}
              variant="outline"
              onClick={onBack}
            />
            <Button
              text={buttonText}
              type="submit"
              isLoading={buttonLoading()}
              disabled={!cloudEdit && isDirty}
            />
          </div>
        </>
      )}
    </Form>
  );
};

export { OpenStackConnection };
