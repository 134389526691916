import { FC } from 'react';
import { useFieldArray } from 'react-hook-form';

import { Button } from '@src/kit';
import { useCurrencyContext, useTranslationPrefix, Form } from '@src/common';
import { bindStyles } from '@src/utils';

import { CustomCurrencyRow } from './CustomCurrencyRow';
import styles from './CustomCurrencyForm.module.scss';

const cx = bindStyles(styles);

const CustomCurrencyForm: FC = () => {
  const { tPrefix } = useTranslationPrefix('cos.currencyModal.');
  const { form } = useCurrencyContext();
  const { fields, append, remove } = useFieldArray({ name: 'currencies', control: form.control });

  const addNewPair = () => {
    append({
      rate: 1,
      service: 'MANUAL',
    });
  };

  const disableButton = form.getValues().currencies.some((it) => !it.source || !it.receiver);

  return (
    <div>
      <Form formMethods={form}>
        <div>
          {fields.map((it, index) => (
            <CustomCurrencyRow
              key={it.id}
              position={index}
              onDelete={() => remove(index)}
            />
          ))}
        </div>
        {fields.length < 3 && (
          <div className={cx('buttonContainer')}>
            <Button
              text={tPrefix('addCurrency')}
              variant="text"
              textClassName={cx('buttonText')}
              size="sm"
              onClick={addNewPair}
              disabled={disableButton}
            />
          </div>
        )}
      </Form>
    </div>
  );
};

export { CustomCurrencyForm };
