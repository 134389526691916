import { SocialMedia } from '@src/@types';

const telegramUrl = (url: string, text: string) => `https://t.me/share/url?url=${url}&text=${text}`;
const vkUrl = (url: string, text: string) => `https://vk.com/share.php?url=${url}&title=${text}`;

const shareLink = (sm: SocialMedia, url: string, text = '') => {
  const href = sm === 'telegram' ? telegramUrl(url, text) : vkUrl(url, text);

  const link = document.createElement('a');
  link.setAttribute('href', href);
  link.setAttribute('target', '_blank');
  document.body.appendChild(link);

  link.click();
  document.body.removeChild(link);
};

export { shareLink };
