import { FC } from 'react';

import { useCloudsContext } from '@common/providers/CloudsProvider';

import { bindStyles } from '@src/utils';
import { useAppTranslation } from '@src/common';

import Cloud from '@assets/images/plugs/Cloud.svg';

import styles from './EmptyPlug.module.scss';

const cx = bindStyles(styles);

const EmptyPlug: FC = () => {
  const { onNavigate } = useCloudsContext();
  const { t } = useAppTranslation();

  return (
    <div className={cx('wrapper')}>
      <div className={cx('image')}>
        <Cloud />
      </div>
      <div className={cx('text')}>
        <div>{t('clouds.connection.vdc.resources.emptyPlug.upperText')}</div>
        <div>
          {t('clouds.connection.vdc.resources.emptyPlug.bottomText')}
          <span
            className={cx('text-special')}
            onClick={() => {
              onNavigate?.('tariffs');
            }}
          >
            {' '}
            {t('cos.settings.tariffAndPrices')}
          </span>
        </div>
      </div>
    </div>
  );
};

export { EmptyPlug };
