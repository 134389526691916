import { IRestService, RestService } from '@common/services';

import { AUTH_LOGIN_HEADER } from '@src/constants';

import { AuthResponse, AuthDTO, AuthAdCheck } from './models';

const AUTH_URL = '/idm/auth/user';
const AUTH_URL_ADMIN = '/idm/auth/manager';
const AUTH_AD_CODE = '/idm/auth/code';

class AuthApi {
  static restService: IRestService = RestService.getInstance();

  static authorization(data: AuthDTO) {
    return this.restService.POST<AuthDTO, AuthResponse>(AUTH_URL, {
      headers: {
        [AUTH_LOGIN_HEADER]: `Basic ${data.code}`,
      },
    });
  }

  static adminAuthorization(data: AuthDTO) {
    return this.restService.POST<AuthDTO, AuthResponse>(AUTH_URL_ADMIN, {
      headers: {
        [AUTH_LOGIN_HEADER]: `Basic ${data.code}`,
      },
    });
  }

  static checkAdCode(data: AuthAdCheck) {
    return this.restService.POST<AuthAdCheck, AuthResponse>(AUTH_AD_CODE, {
      data,
    });
  }

  static logout() {
    const url = '/idm/auth/logout';
    return this.restService.GET<string>(url).then((res) => res.data);
  }
}

export { AuthApi, AUTH_URL, AUTH_URL_ADMIN };
