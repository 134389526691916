import React from 'react';

import { Button } from '@kit/components';
import { CommonDatePickerProps, CommonScreenProps } from '@kit/components/DatePicker/types';

import { bindStyles } from '@src/utils';

import styles from './DpScreens.module.scss';

type Props = CommonScreenProps & CommonDatePickerProps;

const cx = bindStyles(styles);

const DpMonthSelectionComponents: React.FC<Props> = ({ date, monthsList, onSelect }) => {
  const onClick = (i: number) => () => onSelect(i);

  return (
    <div className={cx('list', 'list_month')}>
      {monthsList.map((month, i) => (
        <div
          key={month}
          className={cx('button-month')}
        >
          <Button
            variant={i === date.getMonth() ? 'filled' : 'text'}
            size="sm"
            text={month}
            onClick={onClick(i)}
          />
        </div>
      ))}
    </div>
  );
};

const DpMonthSelection = React.memo(DpMonthSelectionComponents);

export { DpMonthSelection };
