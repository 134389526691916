import React from 'react';

import { bindStyles } from '@src/utils';

import Cloud from '@assets/images/plugs/Cloud.svg';
import Rocket from '@assets/images/plugs/Rocket.svg';
import Satellite from '@assets/images/plugs/Satellite.svg';
import SpaceShip from '@assets/images/plugs/SpaceShip.svg';
import Spin from '@assets/images/plugs/Spin.svg';
import Constellation from '@assets/images/plugs/Сonstellation.svg';
import СonstellationSmall from '@assets/images/plugs/СonstellationSmall.svg';
import Atom from '@assets/images/plugs/Atom.svg';
import FullMoon from '@assets/images/plugs/FullMoon.svg';
import Operator from '@assets/images/plugs/Operator.svg';
import Star from '@assets/images/plugs/Star.svg';
import FallingStar from '@assets/images/plugs/FallingStar.svg';

import styles from './Plug.module.scss';

const cx = bindStyles(styles);

const IMAGES = {
  satellite: <Satellite />,
  cloud: <Cloud data-testid="cloud" />,
  spin: <Spin />,
  rocket: <Rocket />,
  constellation: <Constellation />,
  constellationSmall: <СonstellationSmall />,
  spaceShip: <SpaceShip />,
  atom: <Atom />,
  fullMoon: <FullMoon />,
  operator: <Operator />,
  star: <Star />,
  fallingStar: <FallingStar />,
};

type Props = {
  customImage?: React.ReactNode;
  imageType?: keyof typeof IMAGES;
  text?: string | React.ReactNode;
  className?: string;
  isStatic?: boolean;
};

const Plug: React.FC<Props> = ({ imageType, text, className, customImage, isStatic }) => (
  <div className={cx('plug', className, imageType, { isStatic })}>
    <div className={cx('image')}>{imageType ? IMAGES[imageType] : customImage}</div>
    <div className={cx('text-wrapper', 'fade-in-3')}>
      {typeof text === 'string' ? (
        <span
          className={cx('text')}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      ) : (
        text
      )}
    </div>
  </div>
);

export { Plug };
