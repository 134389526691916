import React, { PropsWithChildren } from 'react';

import { bindStyles } from '@src/utils';

import styles from './PageEnter.module.scss';

const cx = bindStyles(styles);

type Props = {
  className?: string;
};

const PageEnter: React.FC<PropsWithChildren<Props>> = ({ children, className }) => (
  <div className={cx('page', className)}>{children}</div>
);

export { PageEnter };
