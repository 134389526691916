import { useState } from 'react';

import { Screens } from '@kit/components/DatePicker/types';

const useDpNavigation = () => {
  const [screen, setScreen] = useState<Screens>('dp');

  return {
    screen,
    toNext: setScreen,
  };
};

export { useDpNavigation };
