import { ErrorUtils } from '@utils/ErrorUtils';

const REGEXP_DICTIONARY = {
  uuid: /[a-z0-9-]{36}$/gi,
  url: /[^\/\s]+(\/.*)?$/,
  email: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
  password:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$%*?\-()+^_{|}~.:;/\\\[\]])[A-Za-z\d@$%*?\-()+^_{|}~.:;/\\\[\]]{8,}$/,
  phone: /^[0-9\-\+\(\)]{9,18}$/,
  phoneSymbols: /[^0-9\-\+\(\)]/,
  digits: /^\d+$/,
  yandexCloudFormat: /^[a-z0-9.-]*$/,
  firstSymbol: /^[a-zа-я0-9](.*)?$/gim,
  ip: /(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]).){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]).){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))|((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]).){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])/,
  ipv4: /^((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])(\.(?!$)|$)){4}$/,
};

const createPattern = (value: RegExp, message: string) => ({
  pattern: { value, message },
});

const required = (...keys: string[]) => ({ required: keys.join(ErrorUtils.FORM_KEY_SEPARATOR) });
const uuidPattern = (...keys: string[]) =>
  createPattern(REGEXP_DICTIONARY.uuid, keys.join(ErrorUtils.FORM_KEY_SEPARATOR));
const emailPattern = (...keys: string[]) =>
  createPattern(REGEXP_DICTIONARY.email, keys.join(ErrorUtils.FORM_KEY_SEPARATOR));
const urlPattern = (...keys: string[]) =>
  createPattern(REGEXP_DICTIONARY.url, keys.join(ErrorUtils.FORM_KEY_SEPARATOR));
const passwordPattern = (...keys: string[]) =>
  createPattern(REGEXP_DICTIONARY.password, keys.join(ErrorUtils.FORM_KEY_SEPARATOR));
const ipPattern = (...keys: string[]) =>
  createPattern(REGEXP_DICTIONARY.ip, keys.join(ErrorUtils.FORM_KEY_SEPARATOR));
const ipv4Pattern = (...keys: string[]) =>
  createPattern(REGEXP_DICTIONARY.ipv4, keys.join(ErrorUtils.FORM_KEY_SEPARATOR));
const moreThanZero = (...keys: string[]) => ({
  validate: (value: number) => value > 0 || keys.join(ErrorUtils.FORM_KEY_SEPARATOR),
});

export {
  createPattern,
  uuidPattern,
  emailPattern,
  urlPattern,
  passwordPattern,
  required,
  moreThanZero,
  ipPattern,
  ipv4Pattern,
  REGEXP_DICTIONARY,
};
