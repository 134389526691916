import React, { useCallback, useMemo, useState } from 'react';
import { SelectComponents } from 'react-select/dist/declarations/src/components';
import { GroupBase } from 'react-select';

import { Badge, Icon, Select, SelectOption, SelectProps, Tooltip } from '@kit/components';

import { bindStyles } from '@src/utils';

import styles from './SelectWithBadges.modules.scss';

const KEY_LAST_BADGE = 'LAST_1';

const EMAIL_REG = new RegExp(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/);

const cx = bindStyles(styles);

type Props = {
  max?: number;
  options: SelectOption<string | number>[];
  selected: SelectOption<string | number>[];
  onChange: (value: SelectOption<string | number>[]) => void;
} & SelectProps;

const SelectWithBadges: React.FC<Props> = (props) => {
  const { max = 4, options, selected, onChange, ...selectProps } = props;

  const [value, setValue] = useState<string>('');

  const filteredOptions = useMemo(() => {
    return options?.filter((variant) => {
      return !selected.some((mail) => mail?.value == variant?.value);
    });
  }, [options, selected]);

  const badges = useMemo(() => {
    if (selected.length <= max) {
      return selected;
    }

    const othersCount = selected.length - max;
    const otherEmails = selected.slice(max);

    const lastBadge: SelectOption<string> = {
      label: `+ ${othersCount}`,
      value: KEY_LAST_BADGE,
      meta: otherEmails.map((it) => it.label).join(', '),
    };

    return [...selected.slice(0, max), lastBadge];
  }, [selected]);

  const onAdd = (item: SelectOption<string | number>) => {
    const existed = selected.some((option) => option.value === item.value);
    onChange(existed ? selected : [...selected, item]);
    setValue('');
  };

  const onDelete = (item: SelectOption<string | number>) => () => {
    if (item.value === KEY_LAST_BADGE) {
      onChange(selected.slice(0, max));
      return;
    }

    const value = selected.filter((prevItem) => prevItem.value !== item.value);
    onChange(value);
  };

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.code !== 'Enter') {
      return;
    }

    event.preventDefault();

    if (EMAIL_REG.test(value)) {
      onAdd({ value, label: value });
      setValue('');
    }
  };

  const onInputChange = (value: string) => {
    setValue(value);
  };

  const selectRef = React.useCallback(
    (node) => {
      if (!node) {
        return;
      }

      if (value.length > 0 || selected.length > 0) {
        node.focus();
      }
    },
    [value, selected],
  );

  const noOptions = useCallback(() => null, []);

  const onClear = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    setValue('');
  };

  const components: Partial<SelectComponents<unknown, boolean, GroupBase<unknown>>> = useMemo(
    () => ({
      DropdownIndicator: ({ selectProps }) =>
        selectProps.inputValue.length > 0 ? (
          <div onClick={onClear}>
            <Icon
              type="close"
              size="xl"
              className={cx('close__icon')}
            />
          </div>
        ) : null,
    }),
    [],
  );

  const renderedBadges = useMemo(() => {
    return badges.map((email, i) => {
      if (i === badges.length - 1 && badges.length > max) {
        return (
          <Tooltip
            key={email.label}
            text={String(email.meta)}
          >
            <Badge
              iconClassName={cx('emails-badges__icon')}
              textClassName={cx('emails-badges__text')}
              onDelete={onDelete(email)}
            >
              {email.label}
            </Badge>
          </Tooltip>
        );
      }

      return (
        <Badge
          key={email.label}
          iconClassName={cx('emails-badges__icon')}
          textClassName={cx('emails-badges__text')}
          onDelete={onDelete(email)}
        >
          {email.label}
        </Badge>
      );
    });
  }, [badges]);

  return (
    <div className={cx('emails')}>
      <div className={cx('emails-select')}>
        <Select
          {...selectProps}
          isClearable
          closeMenuOnScroll
          ref={selectRef}
          value={null}
          options={filteredOptions}
          inputValue={value}
          onInputChange={onInputChange}
          onChange={onAdd}
          onKeyDown={onKeyDown}
          noOptionsMessage={noOptions}
          hideFocusedPlaceholder
          components={components}
        />
      </div>
      <div className={cx('emails-badges')}>{renderedBadges}</div>
    </div>
  );
};

export { SelectWithBadges };
