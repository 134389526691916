import { FC, useMemo } from 'react';

import { Button, ButtonProps } from '@kit/components';

import { bindStyles } from '@src/utils';

import { CMModalFooter } from '../CMModalFooter';
import styles from './Footer.module.scss';

type CMModalButtonSettings = ButtonProps & {
  onClick?: () => void;
  disabled?: boolean;
  text?: string;
  agreeLoading?: boolean;
  isLoading?: boolean;
};

type CMModalFooterProps = {
  agreeButtonSettings: CMModalButtonSettings;
  disagreeButtonSettings: CMModalButtonSettings;
  footerClassName?: string;
};

const cx = bindStyles(styles);

const Footer: FC<CMModalFooterProps> = ({
  footerClassName,
  agreeButtonSettings,
  disagreeButtonSettings,
}) => {
  const showAgree = useMemo(
    () => agreeButtonSettings.text && agreeButtonSettings.onClick,
    [agreeButtonSettings],
  );
  const showDisagree = useMemo(
    () => disagreeButtonSettings.text && disagreeButtonSettings.onClick,
    [disagreeButtonSettings],
  );

  return (
    <CMModalFooter className={cx(footerClassName, 'wrapper')}>
      {showDisagree && (
        <Button
          variant="outline"
          className={cx('button')}
          {...disagreeButtonSettings}
        />
      )}
      {showAgree && (
        <Button
          className={cx('button')}
          {...agreeButtonSettings}
        />
      )}
    </CMModalFooter>
  );
};

export { Footer };
export type { CMModalFooterProps, CMModalButtonSettings };
