import { FC } from 'react';
import ReactCheckboxTree, { CheckboxProps, OnCheckNode, Node } from 'react-checkbox-tree';

import { Icon } from '@kit/components/Icon';

type OnCheckNodeExtend = OnCheckNode & {
  checkState: number;
};

type Props = Omit<CheckboxProps, 'onCheck'> & {
  onCheck: (values: string[], node: OnCheckNodeExtend) => void;
};

import { bindStyles } from '@src/utils';

import styles from './CheckboxTree.module.scss';

const cx = bindStyles(styles);

const CheckboxTree: FC<Props> = (props) => {
  const Icons = {
    check: (
      <Icon
        type="checkbox"
        className={cx('icon')}
      />
    ),
    uncheck: (
      <Icon
        type="checkbox-uncheck"
        className={cx('icon')}
      />
    ),
    halfCheck: (
      <Icon
        type="indeterminate-checkbox"
        className={cx('icon')}
      />
    ),
    expandClose: (
      <Icon
        type="caret"
        className={cx('expand-btn', 'caretRight')}
      />
    ),
    expandOpen: (
      <Icon
        type="caret"
        className={cx('expand-btn')}
      />
    ),
  };

  return (
    <div className={styles.checkboxTree}>
      <ReactCheckboxTree
        icons={Icons}
        {...props}
        onCheck={props.onCheck as CheckboxProps['onCheck']}
      />
    </div>
  );
};

export { CheckboxTree };
export type { Node as CheckboxTreeNode, OnCheckNodeExtend };
