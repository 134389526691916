import { ABORT_REQUEST_KEY } from '@src/constants';

class RequestControllers {
  private controllers: AbortController[] = [];

  create() {
    const controller = new AbortController();

    this.controllers.push(controller);

    return controller;
  }

  clear() {
    this.controllers.forEach((item) => item.abort(ABORT_REQUEST_KEY));
    this.controllers = [];
  }
}

export { RequestControllers };
