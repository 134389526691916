import { MenuItemType } from '..';

//Relative links
const HOME_LINK = '/';
const COSTER_LINK = '/coster';
const MSP_LINK = '/msp';

const COMMON_LINKS: MenuItemType[] = [
  {
    link: '/connections',
    iconType: 'cloud',
    caption: 'CloudsConnections',
    id: 'connections',
    subItems: [
      { link: '', caption: 'CloudsConnections', access: 'cloud_connection' },
      { link: '/tariffs', caption: 'TariffsAndPrices', access: 'prices' },
    ],
  },
  {
    link: '/settings',
    iconType: 'settings',
    caption: 'Settings',
    id: 'settings',
    subItems: [
      { link: '/users', caption: 'popupUsers', access: 'user' },
      {
        link: '/active-directory',
        caption: 'Active directory',
        access: 'ad',
        accessAction: 'read',
      },
      { link: '/currency-language', caption: 'currencyAndLanguage' },
    ],
  },
];

export { HOME_LINK, COSTER_LINK, MSP_LINK, COMMON_LINKS };
