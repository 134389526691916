import React, { useEffect, useState } from 'react';

import { BOOKMARKS_KEY, SYSTEMS } from '@common/components/AppToolbar/constants/common';
import { BookmarkItem } from '@common/components/AppToolbar/components/Bookmarks/BookmarkItem';

import {
  AppSystem,
  Bookmark,
  PermissionOperations,
  StorageService,
  useAccessControl,
} from '@src/common';
import { bindStyles } from '@src/utils';

import styles from './Bookmarks.module.scss';

const cx = bindStyles(styles);
const storage = StorageService.getInstance();

type Props = {
  currentSystem?: AppSystem;
};

const saveToStorage = (value: AppSystem[]) => storage.setItem(BOOKMARKS_KEY, value);

const Bookmarks: React.FC<Props> = ({ currentSystem }) => {
  const [list, setList] = useState<Bookmark[]>([]);
  const check = useAccessControl();

  useEffect(() => {
    const allowedSystems = SYSTEMS.filter((it) => {
      return check({
        action: 'modules',
        operation: it.system.toUpperCase() as PermissionOperations,
      })();
    });

    setList(allowedSystems);
  }, []);

  return (
    <div className={cx('list')}>
      {list.map((item) => (
        <BookmarkItem
          key={item.system}
          item={item}
          currentSystem={currentSystem}
        />
      ))}
    </div>
  );
};

export { Bookmarks, saveToStorage };
