import React, { useCallback, useEffect, useMemo } from 'react';

import { UserViewDTO } from '@common/components/UserRoles/types';
import { UsersInfoCell } from '@common/components/UserRoles/components/UsersInfoCell/UsersInfoCell';

import {
  CMTable,
  CmTableColumn,
  useAccessControl,
  useAppTranslation,
  useCMTableContext,
  useUserRolesContext,
} from '@src/common';
import { bindStyles } from '@src/utils';
import { Button, Card, DashValue, Plug } from '@src/kit';

import styles from './UserTable.module.scss';
import { UserDetails } from '../UserDetails';

type Props = {
  onResetFilters: () => void;
  reFetch: () => void;
  clearSelection: () => void;
  disabled?: boolean;
};

const cx = bindStyles(styles);

const UsersTable: React.FC<Props> = ({ onResetFilters, reFetch, disabled, clearSelection }) => {
  const { t } = useAppTranslation();

  const { selection } = useCMTableContext();
  const { openUserModal, getUserData, userData, cardId } = useUserRolesContext();

  const checkAccess = useAccessControl();

  const openUserDetail = ({ uuid }: UserViewDTO) => {
    openUserModal(uuid);
    getUserData(uuid);
  };

  const handleRowClick = (row: UserViewDTO) =>
    checkAccess({ action: 'read', subject: 'user' })(() => !disabled && openUserDetail(row));

  useEffect(() => {
    if (selection?.selectedRow) {
      handleRowClick(selection.selectedRow);
    }
  }, [selection?.selectedRow]);

  const { formattedCosterRoles, formattedClouderRoles } = useUserRolesContext();

  const columns: CmTableColumn<UserViewDTO>[] = useMemo(
    () => [
      {
        title: t('Name'),
        sortable: true,
        key: 'lastName',
        width: 300,
        render: (value: string, row: UserViewDTO) => (
          <UsersInfoCell
            lastName={value}
            firstName={row.firstName}
            roles={row.roles}
            uuid={row.uuid}
            isActive={cardId === row.uuid}
          />
        ),
      },
      {
        title: t('Mail'),
        sortable: true,
        key: 'login',
        width: 294,
        render: (value: string | null) => <DashValue condition={Boolean(value)}>{value}</DashValue>,
      },
      {
        title: t('Comment'),
        sortable: true,
        key: 'comment',
        width: 420,
        render: (value: string | null) => <DashValue condition={Boolean(value)}>{value}</DashValue>,
      },
    ],
    [t, formattedCosterRoles, formattedClouderRoles, cardId],
  );

  const rowKey = useCallback((item: UserViewDTO) => item.uuid, []);

  return (
    <>
      <Card className={cx('table')}>
        <CMTable<UserViewDTO>
          plugs={{
            emptyData: (
              <Plug
                imageType="spin"
                text={
                  <>
                    <span className={cx('text', 'title')}>{t('NothingFound')}</span> <br />
                    <Button
                      variant="text"
                      onClick={onResetFilters}
                    >
                      {t('cld.common.reset')}
                    </Button>
                    &nbsp;
                    <span className={cx('text')}>{t('cld.common.changeParams')}</span>
                  </>
                }
              />
            ),
          }}
          sticky
          columns={columns}
          rowKey={rowKey}
        />
      </Card>
      {userData && (
        <UserDetails
          userData={userData}
          reFetch={reFetch}
          clearSelection={clearSelection}
        />
      )}
    </>
  );
};

export { UsersTable };
