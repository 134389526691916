import React from 'react';

import { Size } from '@src/@types';
import { bindStyles } from '@src/utils';

import styles from './Toggler.module.scss';

const cx = bindStyles(styles);

type Props = {
  size?: Size;
  disabled?: boolean;
  value?: boolean;
  error?: boolean;
  errorText?: string;
  children?: React.ReactNode;
  onChange?: (value: boolean) => void;
  descriptionClassName?: string;
};

const Toggler: React.FC<Props> = ({
  size = 'md',
  value,
  error,
  errorText,
  disabled,
  descriptionClassName,
  children,
  onChange,
}) => {
  const classListRoot = {
    toggler: true,
    toggler_lg: size === 'lg',
    toggler_md: size === 'md',
    toggler_sm: size === 'sm',
    toggler_active: Boolean(value),
    toggler_error: Boolean(error),
    toggler_disabled: Boolean(disabled),
  };

  const classListCircle = {
    toggler__circle: true,
    toggler__circle_active: value,
  };

  const onClick = () => onChange?.(!value);

  return (
    <div className={cx(classListRoot)}>
      <div
        tabIndex={1}
        className={cx('toggler-inner')}
        onClick={disabled ? undefined : onClick}
      >
        <div className={cx(classListCircle)} />
      </div>
      {children && (
        <div className={cx('toggler__description', descriptionClassName)}>
          {children}

          {errorText && <span className={cx('toggler__description__error')}>{errorText}</span>}
        </div>
      )}
    </div>
  );
};

export { Toggler };

export type { Props as TogglerProps };
