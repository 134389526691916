import { FC, useMemo } from 'react';
import { FieldArrayWithId, FieldValues } from 'react-hook-form';

import { bindStyles } from '@src/utils';
import { FormInput, required, useCurrencyContext, useTranslationPrefix } from '@src/common';
import { Button, Icon } from '@src/kit';

import styles from '../FormVCloudDirectror.module.scss';

const cx = bindStyles(styles);

const MIN_VALUE = 0.01;

type IStorageSettingsStep = {
  isLoading: boolean;
  addProfile: () => void;
  renderedProfiles: JSX.Element[];
  loadProfiles: () => void;
  fields: FieldArrayWithId<FieldValues, string, 'id'>[];
};

const StorageSettingsStep: FC<IStorageSettingsStep> = ({
  isLoading,
  addProfile,
  renderedProfiles,
  loadProfiles,
  fields,
}) => {
  const { t, tPrefix } = useTranslationPrefix('cos.cloud.connect.cloudDirector.');
  const { customerCurrency, currenciesList } = useCurrencyContext();

  const suffix = currenciesList.find((it) => it.code === customerCurrency)?.name;

  const minValidation = useMemo(
    () => ({
      value: MIN_VALUE,
      message: t('cos.price.min.value.error', { value: MIN_VALUE }),
    }),
    [t],
  );

  return (
    <>
      <div className={cx('connectionBlock')}>
        <div className={cx('blockTitle')}>{tPrefix('PricingPolicySetup')}</div>
        <div className={cx('blockContent')}>
          <FormInput
            type="number"
            decimals={2}
            name="cpuPrice"
            caption={tPrefix('cpuPrice.caption')}
            rules={{ ...required('FieldRequired'), min: minValidation }}
            placeholder={tPrefix('cpuPrice.placeholder', { suffix })}
            suffix={suffix}
            hint={t('CpuCostHint')}
          />

          <FormInput
            type="number"
            decimals={2}
            name="memoryGbPrice"
            caption={tPrefix('memoryGbPrice.caption')}
            rules={{ ...required('FieldRequired'), min: minValidation }}
            placeholder={tPrefix('memoryGbPrice.placeholder', { suffix })}
            suffix={suffix}
            hint={t('RamCostHint')}
          />

          <FormInput
            type="number"
            decimals={2}
            name="diskGbPrice"
            caption={tPrefix('diskGbPrice.caption')}
            rules={{ ...required('FieldRequired'), min: minValidation }}
            placeholder={tPrefix('diskGbPrice.placeholder', { suffix })}
            suffix={suffix}
            hint={t('HddCostHint')}
          />
        </div>
      </div>

      <div className={cx('blockTitle', { withBorder: !fields.length })}>
        {tPrefix('ProfilesTitle')}
      </div>
      {Boolean(fields.length) && <div className={cx('items')}>{renderedProfiles}</div>}

      <div className={cx('buttonsContainer')}>
        <Button
          variant="text"
          textClassName={cx('appendButtonText')}
          text={tPrefix('add.profile')}
          className={cx('addButton')}
          onClick={addProfile}
        >
          <div className={cx('buttonContent')}>
            <Icon
              type="close"
              className={cx('plusIcon')}
            />
          </div>
        </Button>
        <Button
          variant="outline"
          text={tPrefix('load.profiles')}
          onClick={loadProfiles}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export { StorageSettingsStep };
