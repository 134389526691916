import React from 'react';

import cn from 'classnames/bind';

import { TitleProps } from './H1';
import styles from './Title.module.scss';

export const H4: React.FC<TitleProps> = ({ className, size = 'sm', children }) => {
  const cx = cn.bind(styles);
  return <h4 className={cx(className, 'heading', size)}>{children}</h4>;
};
