import { FC, useCallback } from 'react';

import { Stages } from '@common/components/AppToolbar/components/CloudConnect/types';

import { useAppTranslation, useCloudConnectContext } from '@src/common';
import { Button, CMModalHeader, Icon } from '@src/kit';
import { bindStyles } from '@src/utils';

import styles from './ProviderSelectionHeader.module.scss';

const cx = bindStyles(styles);

const ProviderSelectionHeader: FC = () => {
  const { t } = useAppTranslation();
  const { selectStage, onClose, clouds } = useCloudConnectContext();
  const visibleBackButton = clouds?.length !== 0;
  const onBack = useCallback(() => {
    selectStage({
      stage: Stages.connectedClouds,
    });
  }, []);

  return (
    <CMModalHeader className={cx('wrapper')}>
      <div className={cx('headerTitleContainer')}>
        {visibleBackButton && (
          <Button
            onClick={onBack}
            className={cx('headerBackButton')}
            variant="text"
          >
            <Icon
              className={cx('headerTitleIcon')}
              type="chevron-left"
              size="xl"
            />
          </Button>
        )}
        <div className={cx('title')}>{t('CloudConnectHeader')}</div>
      </div>
      <Icon
        className={cx('close')}
        type="close"
        onClick={onClose}
      />
    </CMModalHeader>
  );
};

export { ProviderSelectionHeader };
