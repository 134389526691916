import React from 'react';
import { Toaster } from 'react-hot-toast';
import { DefaultToastOptions, ToastPosition } from 'react-hot-toast/dist/core/types';

type Props = {
  position?: ToastPosition;
  containerClassName?: string;
};

const CloudToaster: React.FC<Props> = ({ position = 'top-center', containerClassName }) => {
  const duration = 2500;

  const defaultToastOption: DefaultToastOptions = {
    duration,
  };

  return (
    <Toaster
      containerClassName={containerClassName}
      position={position}
      containerStyle={{ top: '32px' }}
      gutter={16}
      toastOptions={defaultToastOption}
    />
  );
};

export { CloudToaster };
