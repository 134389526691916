import { createContext, useContext } from 'react';

import { UsersRolesDTO, UserViewDTO } from '@common/components/UserRoles/types';

import { DisclaimerVariant, SelectOption } from '@src/kit';
import { Brand } from '@src/@types';

type UserRolesContextState = {
  cardId?: string;
  openUserModal: (cardId: string) => void;
  closeUserModal: () => void;
  isRolesLoading?: boolean;
  clouderRoleList: UsersRolesDTO[];
  costerRoleList: UsersRolesDTO[];
  formattedCosterRoles: SelectOption<number, any>[];
  formattedClouderRoles: SelectOption<number, any>[];
  setStepDisclaimer: (step: DisclaimerVariant) => void;
  stepDisclaimer: DisclaimerVariant;
  userData?: UserViewDTO;
  getUserData: (uuid: string) => void;
  brand: Brand;
};

const UserRolesContext = createContext({} as UserRolesContextState);

const useUserRolesContext = () => useContext(UserRolesContext);

export { useUserRolesContext, UserRolesContext };
