import React from 'react';

import { FormDataHyperV } from '@common/components/AppToolbar/components/CloudConnect/types';

import { HTTPS, NAME, PASSWORD_FIELD, PORT, SERVER, USERNAME } from '@src/constants';
import {
  emailPattern,
  FormInput,
  required,
  useAppFormContext,
  useAppTranslation,
} from '@src/common';
import { bindStyles } from '@src/utils';
import { Disclaimer, RadioButtonGroup, RadioButtonGroupOption } from '@src/kit';

import styles from './ConnectionStep.module.scss';

const cx = bindStyles(styles);

const ConnectionStep: React.FC = () => {
  const { t } = useAppTranslation();

  const { control } = useAppFormContext<FormDataHyperV>();

  const options: RadioButtonGroupOption[] = [
    { label: t('Used'), value: 'used' },
    { label: t('NotUsed'), value: 'notUsed' },
  ];

  return (
    <div className={cx('form')}>
      <div className={cx('full')}>
        <Disclaimer text={t('cld.HyperV.modal.connectionHint')} />
      </div>

      <FormInput
        className={cx('full')}
        placeholder={t('HyperVNamePH')}
        name={NAME}
        rules={required('FieldRequired')}
        caption={t('HyperVName')}
      />
      <FormInput
        className={cx('full')}
        placeholder={t('HyperVDomainPH')}
        name={SERVER}
        rules={required('FieldRequired')}
        caption={t('HyperVDomain')}
      />
      <FormInput
        placeholder={t('HyperVUserNamePH')}
        name={USERNAME}
        rules={{
          ...required('FieldRequired'),
        }}
        caption={t('HyperVUserName')}
      />
      <FormInput
        placeholder={t('HyperVUserPassPH')}
        name={PASSWORD_FIELD}
        rules={required('FieldRequired')}
        caption={t('HyperVUserPass')}
      />
      <div className={cx('radio')}>
        <span>{t('Protocol')}</span>
        <RadioButtonGroup
          name={HTTPS}
          control={control}
          classes={{
            group: cx('radio-group'),
            groupItem: cx('radio-groupItem'),
          }}
          options={options}
        />
      </div>
      <FormInput
        className={cx('port')}
        placeholder="5986"
        name={PORT}
        rules={required('FieldRequired')}
        caption={t('HyperVPort')}
      />

      <div className={cx('full')}>
        <Disclaimer text={t('cld.HyperV.modal.settingsHint')} />
      </div>
    </div>
  );
};

export { ConnectionStep };
