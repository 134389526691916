import { useEffect, useState } from 'react';

import { MIN_AVAILABLE_SCREEN_WIDTH, MIN_AVAILABLE_SCREEN_HEIGHT } from '@src/constants/common';

type ReturnValue = { showPlug: boolean; setShowPlug: (value: boolean) => void };

export const useViewport = (crop: boolean): ReturnValue => {
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < MIN_AVAILABLE_SCREEN_WIDTH ||
      window.innerHeight < MIN_AVAILABLE_SCREEN_HEIGHT,
  );
  const [showPlug, setShowPlug] = useState(false);

  const handleResize = () => {
    setIsMobile(
      window.innerWidth < MIN_AVAILABLE_SCREEN_WIDTH ||
        window.innerHeight < MIN_AVAILABLE_SCREEN_HEIGHT,
    );
  };

  useEffect(() => {
    setShowPlug(isMobile);
  }, [isMobile]);

  useEffect(() => {
    const root = document?.getElementById('root');
    if (root) {
      root.style.overflow = showPlug || crop ? 'hidden' : 'visible';
      root.style.height = showPlug || crop ? '100vh' : 'auto';
      root.style.width = showPlug || crop ? '100vw' : 'auto';
    }
  }, [showPlug, crop]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { showPlug, setShowPlug };
};
