import * as dateFns from 'date-fns';
import { FastDatesProps } from '@kit/components/Calendar/Container';

import { mapToOption } from '@src/utils';

const now = dateFns.endOfDay(new Date());

const DATE_RANGES: Record<string, [Date, Date]> = {
  threeMonth: [dateFns.subMonths(now, 2), now],
  sixMonth: [dateFns.subMonths(now, 5), now],
  twelveMonth: [dateFns.subMonths(now, 11), now],
};

const useMonthFastDatesOptions = () => {
  const fastDates: FastDatesProps['fastDates'] = [
    mapToOption(DATE_RANGES.threeMonth, 'cos.reports.filter.period.threeMonth', 'threeMonth'),
    mapToOption(DATE_RANGES.sixMonth, 'cos.reports.filter.period.sixMonth', 'sixMonth'),
    mapToOption(DATE_RANGES.twelveMonth, 'cos.reports.filter.period.twelveMonth', 'twelveMonth'),
  ];

  return { fastDates, DATE_RANGES };
};

export { useMonthFastDatesOptions };
