import { FC, useEffect } from 'react';
import { FieldArrayWithId, Path, useFormContext } from 'react-hook-form';

import { TariffResourceTypes } from '@common/components/TariffAndPricesComponent/api/models';
import { Button, Icon } from '@kit/components';

import { bindStyles } from '@src/utils';
import { FormDTO, FormInput, required, useAppTranslation } from '@src/common';

import styles from './FixRateTableItem.module.scss';

type Props = {
  i: number;
  rate: FieldArrayWithId<FormDTO, 'rates', 'id'>;
  onRemove: () => void;
  isEdit?: boolean;
};

type ProfileItemName = Path<FormDTO>;

const cx = bindStyles(styles);

const FixRateTableItem: FC<Props> = ({ rate, i, isEdit, onRemove }) => {
  const { t } = useAppTranslation();

  const nameKey = `rates.${i}.itemName` as ProfileItemName;
  const priceKey = `rates.${i}.price` as ProfileItemName;
  const quantityKey = `rates.${i}.quantity` as ProfileItemName;

  const { setValue } = useFormContext();

  useEffect(() => {
    if (isEdit && rate.price === 0) {
      return setValue(`rates.${i}.price`, '0');
    }
  }, [isEdit, rate]);

  if (isEdit) {
    return (
      <div className={cx('wrapper', { odd: i % 2 !== 0 })}>
        <div className={cx('info')}>
          <FormInput
            size="sm"
            name={nameKey}
            rules={required('FieldRequired')}
            placeholder={t('cos.settings.tariffprices.input.name')}
          />
        </div>
        <div className={cx('info')}>
          <FormInput
            type="number"
            size="sm"
            rules={required('FieldRequired')}
            name={quantityKey}
            placeholder={t('cos.settings.tariffprices.input.placeholder.quantity')}
          />
        </div>
        <div className={cx('info')}>
          <FormInput
            type="number"
            size="sm"
            name={priceKey}
            decimals={2}
            rules={required('FieldRequired')}
            placeholder={t('cos.settings.tariffprices.input.placeholder.price')}
          />
        </div>
        <div className={cx('info', 'placeholder')}>
          {t(`cos.settings.tariffprices.input.tariff.${rate.tariff || TariffResourceTypes.MONTH}`)}
        </div>
        <Button
          variant="text"
          onClick={onRemove}
        >
          <Icon
            className={cx('deleteIcon')}
            type="delete"
          />
        </Button>
      </div>
    );
  }

  return (
    <div className={cx('wrapper', { odd: i % 2 !== 0 })}>
      <div className={cx('info', { placeholder: !rate.itemName })}>
        {rate.itemName || t('cos.settings.tariffprices.input.itemName')}
      </div>
      <div className={cx('info')}>{rate.quantity || '-'}</div>
      <div className={cx('info', 'price', { placeholder: rate.price === null })}>
        {rate.price === null ? t('cos.settings.tariffprices.input.price') : rate.price}
      </div>
      <div className={cx('info')}>
        {t(`cos.settings.tariffprices.input.tariff.${rate.tariff || TariffResourceTypes.MONTH}`)}
      </div>
    </div>
  );
};

export { FixRateTableItem };
