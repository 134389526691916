import React, { AnimationEventHandler, useEffect, useMemo, useRef, useState } from 'react';
import { PopupProps } from 'reactjs-popup/dist/types';

import cn from 'classnames/bind';
import { useBodyOverflow } from '@common/hooks/useBodyOverflow';
import { InputStub } from '@kit/components/Popup/InputStub';
import { PortalContainer } from '@kit/components/CmSelect/PortalContainer';

import styles from './Modal.module.scss';

type ModalProps = PopupProps & {
  id?: string;
  classNamePrefix?: string;
  overlayClassName?: string;
  onAnimationEnd?: AnimationEventHandler<HTMLDivElement>;
  lockBodyScroll?: boolean;
  hiddenOverlay?: boolean;
};

const cx = cn.bind(styles);

const Modal: React.FC<ModalProps> = ({
  id,
  children,
  className,
  onAnimationEnd,
  lockBodyScroll = true,
  contentStyle,
  open,
  closeOnDocumentClick = true,
  overlayStyle,
  trigger,
  closeOnEscape = true,
  hiddenOverlay,
  overlayClassName,
  onClose,
  onOpen,
}) => {
  const [isOpen, setIsOpen] = useState(open);
  const triggerRef = useRef<HTMLElement>(null);
  const refContainer = useRef<HTMLDivElement | null>(null);

  const isOpened = useMemo(() => {
    if (trigger) {
      return isOpen && open;
    }

    return open;
  }, [open, trigger, isOpen]);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  useBodyOverflow(Boolean(isOpened), lockBodyScroll);

  const toggleIsOpen = () => {
    setIsOpen((prev) => !prev);
    if (isOpen) {
      onClose?.();
    } else {
      onOpen?.();
    }
  };

  const handleClickOverlay = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!refContainer.current?.contains(e.target as Node) && closeOnDocumentClick) {
      if (trigger) {
        setIsOpen(false);
      }
      onClose?.();
    }
  };

  useEffect(() => {
    if (closeOnDocumentClick) {
      const listener = (e: MouseEvent | TouchEvent) => {
        if (refContainer.current && !refContainer.current.contains(e.target as Node)) {
          if (isOpen) {
            e.stopPropagation();
          }
          if (trigger) {
            setIsOpen(false);
          }
          onClose?.();
        }
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    }
  }, [closeOnDocumentClick, onClose, isOpen]);

  useEffect(() => {
    if (closeOnEscape) {
      const onKeyUp = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          if (trigger) {
            setIsOpen(false);
          }
          onClose?.();
        }
      };
      window.addEventListener('keyup', onKeyUp);

      return () => {
        window.removeEventListener('keyup', onKeyUp);
      };
    }
  }, []);

  const renderTrigger = () => {
    if (!trigger) {
      return null;
    }

    const triggerProps = {
      key: 'T',
      ref: triggerRef,
      onMouseDown: toggleIsOpen,
      onTouchStart: toggleIsOpen,
    };

    if (typeof trigger === 'function') {
      const comp = trigger(isOpened || false);
      return React.cloneElement(comp, triggerProps);
    }

    return React.cloneElement(trigger, triggerProps);
  };

  return (
    <>
      {renderTrigger()}
      {isOpened && (
        <PortalContainer id={id}>
          <div
            className={cx(overlayClassName, 'overlay', { hidden: hiddenOverlay })}
            onClick={handleClickOverlay}
            style={overlayStyle}
            data-testid="overlay"
          >
            <InputStub />
            <div
              className={cx(className, 'wrapper')}
              onAnimationEnd={onAnimationEnd}
              ref={refContainer}
              style={contentStyle}
            >
              {children}
            </div>
          </div>
        </PortalContainer>
      )}
    </>
  );
};

export { Modal };
export type { ModalProps };
