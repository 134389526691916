import { FC, useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { useCloudsContext } from '@common/providers/CloudsProvider';
import { SECRET_FIELD_EDIT_PLACEHOLDER } from '@common/components/Clouds/components';

import { bindStyles } from '@src/utils';
import { Disclaimer, SearchMenuList, SelectOption, ValueWithLabel } from '@src/kit';
import { FormInput, FormSelect, required, useAppTranslation } from '@src/common';
import { ACCESS_KEY, CLOUDNAME, KEYSECRET, REGION } from '@src/constants';

import styles from '../AWSConnection.module.scss';

const cx = bindStyles(styles);

type Props = {
  listRegions: SelectOption<string>[];
  isRegionsLoading?: boolean;
};

const ConnectionStep: FC<Props> = ({ listRegions, isRegionsLoading }) => {
  const { t } = useAppTranslation();
  const { cloudEdit } = useCloudsContext();

  const form = useFormContext();

  const region = form.watch('region');

  const handleOnChange = (e: SelectOption<string>) => {
    form.setValue('region', e);
  };

  const onMenuOpen = useCallback(() => {
    const target = document.querySelector('#aws-region > div:nth-child(3)');
    target?.scrollIntoView({ behavior: 'smooth' });
  }, []);
  return (
    <div>
      <div className={cx('info')}>
        <Disclaimer text={t('infoAWS')} />
      </div>
      <div className={cx('connectionBlock')}>
        <FormInput
          placeholder={t('nameCloud')}
          name={CLOUDNAME}
          caption={t('cloudName')}
          hint={t('AWSHintCloudName')}
          rules={{ ...required('FieldRequired') }}
        />
      </div>
      <div className={cx('connectionBlock')}>
        <div className={cx('blockContent')}>
          <FormInput
            placeholder={t('AWSKeyIDPlaceholder')}
            name={ACCESS_KEY}
            caption={t('AccessKey')}
            hint={t('AWSHintAccessKey')}
            rules={{ ...required('FieldRequired') }}
          />
          <FormInput
            placeholder={cloudEdit ? SECRET_FIELD_EDIT_PLACEHOLDER : t('AWSSecretKeyPlaceholder')}
            name={KEYSECRET}
            caption={t('SecretKey')}
            hint={t('AWSHintSecretKey')}
          />
        </div>
      </div>
      <div className={cx('connectionBlock')}>
        <div className={cx('blockContent')}>
          <FormSelect
            id="aws-region"
            value={region}
            placeholder={t('AWSRegionPlaceholder')}
            currentSelection={region?.value}
            name={REGION}
            hint={t('AWSHintRegion')}
            className={styles.formSelect}
            caption={t('Region')}
            size="lg"
            onChange={handleOnChange}
            isSearchable={false}
            options={listRegions}
            rules={{ ...required('FieldRequired') }}
            menuPlacement="bottom"
            descriptionOnTop={false}
            isLoading={isRegionsLoading}
            components={{
              MenuList: (props: any) => {
                useEffect(() => {
                  onMenuOpen();
                }, []);

                return <SearchMenuList {...props} />;
              },
              Option: ValueWithLabel,
            }}
            isDisabled={cloudEdit}
          />
        </div>
      </div>
    </div>
  );
};

export { ConnectionStep };
