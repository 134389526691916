import { useState } from 'react';

import { AxiosError } from 'axios';

import { useToast } from '@src/common';
import { ApiValidationError } from '@src/@types';

import { ErrorUtils } from '@utils/ErrorUtils';
import { isApiValidationError } from '@utils/common';

const useFormApiError = () => {
  const toast = useToast();

  const [apiErrors, setApiErrors] = useState<ApiValidationError[]>([]);

  const handleError = (error: AxiosError, defaultMsg?: string) => {
    if (isApiValidationError(error)) {
      const errors = error?.response?.data?.validationErrors ?? [];
      setApiErrors(errors);
    } else {
      const text = ErrorUtils.handleApiError(error, defaultMsg);
      toast.error({ text });
    }
  };

  return {
    toast,
    apiErrors,
    handleError,
  };
};

export { useFormApiError };
