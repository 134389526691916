import { FC, ReactNode, SyntheticEvent, useEffect, useMemo, useState } from 'react';

import { CreationEntityProps } from '@kit/components/CmSelect/CreationEntity/CreationEntity';
import { Dropdown } from '@kit/components/CmSelect/Dropdown';
import { renderFooter } from '@kit/components/CmSelect/Footer';
import { renderHeader } from '@kit/components/CmSelect/Header';
import { useSearch } from '@kit/components/CmSelect/hooks/useSearch';
import { Options } from '@kit/components/CmSelect/Options';
import { PortalContainer } from '@kit/components/CmSelect/PortalContainer';
import { Selection } from '@kit/components/CmSelect/Selection';

import { SelectOption, Size } from '@src/@types';
import { bindStyles } from '@src/utils';

import styles from './CmSelect.module.scss';

type CmSelectSize = Exclude<Size, 'xs' | 'xl' | 'xxl' | 'xxxl'>;
type CmSelectValue = SelectOption<unknown> | SelectOption<unknown>[] | undefined;

type Components = {
  selectedValue: (selected: CmSelectValue) => ReactNode;
  optionContainer: (option: SelectOption<unknown>, selected: boolean) => ReactNode;
};
type Props = {
  portal?: boolean;
  searchable?: boolean;
  className?: string;
  multi?: boolean;
  selectAllAlign?: 'left' | 'right';
  disabled?: boolean;
  size?: CmSelectSize;
  placeholder?: string;
  withoutCheckmark?: boolean;
  caption?: string;
  description?: string;
  noOptionsText?: string;
  loading?: boolean;
  error?: boolean;
  autoClearValue?: boolean;
  errorText?: string;
  maxMenuHeight?: number;
  selectDescription?: string;
  replaceLabel?: boolean;
  creationSettings?: CreationEntityProps;
  value?: CmSelectValue;
  onChange?: (values: CmSelectValue, event?: SyntheticEvent) => void;
  options: SelectOption<unknown>[];
  components?: Partial<Components>;
};

const cx = bindStyles(styles);

const CmSelect: FC<Props> = ({
  portal = false,
  size = 'lg',
  value,
  onChange = () => {},
  className,
  placeholder,
  options,
  noOptionsText,
  multi,
  searchable,
  components,
  caption,
  description,
  selectDescription,
  error,
  errorText,
  disabled,
  selectAllAlign = 'left',
  maxMenuHeight = 225,
  creationSettings,
  loading,
  withoutCheckmark,
  autoClearValue,
  replaceLabel = true,
}) => {
  const [refSelection, setRefSelection] = useState<HTMLDivElement | null>(null);

  const [open, setOpen] = useState<boolean>(false);

  const { filteredOptions, ...searchProps } = useSearch(Boolean(searchable), options);

  const selectAllType = useMemo(() => {
    if (!Array.isArray(value)) {
      return false;
    }

    if (value.length === 0) {
      return false;
    }
    return options.length === value.length ? true : 'mixed';
  }, [value, options]);

  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  const isSelected = (option: SelectOption<unknown>) => {
    if (!value) {
      return false;
    }

    if (Array.isArray(value)) {
      return value.some((item) => item.value === option.value);
    }

    return option.value === (value as SelectOption<unknown>).value;
  };

  const onSelectAll = () => {
    if (selectAllType === 'mixed' || selectAllType === false) {
      onChange([...options]);
    } else {
      onChange([]);
    }
  };

  const isMultiLevel = useMemo(() => {
    return options.some((it) => Boolean(it.customize?.childrenOptions));
  }, [options]);

  const allOptions = useMemo(() => {
    return options.reduce((res, option) => {
      if (option.customize && option.customize.childrenOptions) {
        return [...res, option, ...option.customize.childrenOptions];
      }

      return [...res, option];
    }, [] as SelectOption<unknown>[]);
  }, [options]);

  useEffect(() => {
    if (autoClearValue) {
      if (Array.isArray(value)) {
        const founded = allOptions.filter((option) =>
          value.some((selectedOption) => option.value === selectedOption.value),
        );
        const values = value.map((it) => it.value);

        if (founded.length !== value.length || !founded.every((it) => values.includes(it.value))) {
          onChange(founded);
        }
      } else {
        const founded = allOptions.find((option) => value?.value === option.value);

        if (founded?.value !== value?.value) {
          onChange(founded);
        }
      }
    }
  }, [allOptions, autoClearValue]);

  const selectedValue = useMemo(() => {
    if (replaceLabel && value) {
      if (Array.isArray(value)) {
        return value.map((it) => ({
          ...it,
          label: allOptions.find((option) => it.value === option.value)?.label || it.label,
        }));
      } else {
        const foundedValue = allOptions.find((it) => it.value === value.value);
        if (foundedValue) {
          return { ...value, label: foundedValue.label };
        }
      }
    }

    return value;
  }, [allOptions, value, replaceLabel]);

  const renderedDropdown = useMemo(() => {
    if (refSelection) {
      return (
        <Dropdown
          portal={portal}
          maxMenuHeight={maxMenuHeight}
          selectionContainer={refSelection}
          onClose={onClose}
          topContent={renderHeader({
            ...searchProps,
            multi,
            selectAllAlign,
            isMultiLevel,
            searchable,
            selectAllType,
            optionsCount: filteredOptions.length,
            onSelectAll,
          })}
          bottomContent={renderFooter({
            selectDescription,
            isMultiLevel,
            creationSettings,
            onClose,
          })}
        >
          <Options
            options={filteredOptions}
            isSelected={isSelected}
            onChange={onChange}
            searchable={searchable}
            onClose={onClose}
            value={value}
            noOptionsText={noOptionsText}
            multi={multi}
            component={components?.optionContainer}
            withoutCheckmark={withoutCheckmark}
            refSelection={refSelection}
            maxMenuHeight={maxMenuHeight}
          />
        </Dropdown>
      );
    }

    return null;
  }, [
    portal,
    maxMenuHeight,
    filteredOptions,
    selectDescription,
    components,
    noOptionsText,
    multi,
    searchable,
    refSelection,
    searchProps,
    selectAllType,
    selectAllAlign,
    withoutCheckmark,
    isMultiLevel,
  ]);

  return (
    <div className={cx(className, 'container', size, { disabled })}>
      {caption && <div className={cx('caption')}>{caption}</div>}
      <Selection
        ref={setRefSelection}
        loading={loading}
        value={selectedValue as CmSelectValue}
        error={error}
        disabled={disabled}
        placeholder={placeholder}
        component={components?.selectedValue}
        size={size}
        onClick={disabled ? undefined : onOpen}
      >
        {open &&
          (portal ? <PortalContainer>{renderedDropdown}</PortalContainer> : renderedDropdown)}
      </Selection>
      {((error && errorText) || description) && (
        <div className={cx('bottom-content')}>
          {error && errorText && <div className={cx('error-text')}>{errorText}</div>}
          {description && <div className={cx('description')}>{description}</div>}
        </div>
      )}
    </div>
  );
};

export { CmSelect };
export type {
  CmSelectSize,
  CmSelectValue,
  Components as CmSelectComponents,
  Props as CmSelectProps,
};
