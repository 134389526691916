import React from 'react';

import { Stages } from '@common/components/AppToolbar/components/CloudConnect/types';

import { useCloudConnectContext } from '@src/common';

import { CloudConnectView } from './CloudConnect.view';
import { ConnectedClouds } from './ConnectedClouds';
import { ConnectionSuccess } from './ConnectionSuccess';
import { ProviderForm } from './Forms/ProviderForm';
import { ProviderSelection } from './ProviderSelection';
import { CloudEdit } from './CloudEdit';

type Stage = {
  name: Stages;
  element: JSX.Element;
};

const stages: Stage[] = [
  {
    name: Stages.connectedClouds,
    element: <ConnectedClouds />,
  },
  {
    name: Stages.providerChoice,
    element: <ProviderSelection />,
  },
  {
    name: Stages.providerForm,
    element: <ProviderForm />,
  },
  {
    name: Stages.connectResult,
    element: <ConnectionSuccess />,
  },
  {
    name: Stages.cloudEdit,
    element: <CloudEdit />,
  },
];

export const CloudConnect: React.FC = () => {
  const { stage: activeStage, open } = useCloudConnectContext();

  const currentStage = stages.find((stage) => stage.name === activeStage);

  return open ? <CloudConnectView>{currentStage?.element}</CloudConnectView> : null;
};
