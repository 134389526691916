import { FC } from 'react';

import { NotificationsLoading } from '@common/components/AppToolbar/components/Notifications/NotificationsLoading';
import { SpinnerBlock } from '@common/components/AppToolbar/components/Notifications/SpinnerBlock';
import { NotificationsEasy } from '@common/components/AppToolbar/components/Notifications/NotificationsEasy';
import { TaskId } from '@common/api/Notification/models';

import { useNotificationsContext } from '@src/common';
import { bindStyles } from '@src/utils';
import { Counter, Icon } from '@src/kit';

import { usePopupAppToolbar } from '@hooks/usePopupAppToolbar';

import styles from './Notifications.module.scss';

const cx = bindStyles(styles);

type Props = {
  className: string;
  activeClassName: string;
  handleEventDetail: (id: TaskId) => void;
  isOpenedEventDetail: boolean;
};

const Notifications: FC<Props> = ({
  className,
  activeClassName,
  handleEventDetail,
  isOpenedEventDetail,
}) => {
  const { countBgTasks, countCommon, refreshCountCommonUnread, reFetchCounters } =
    useNotificationsContext();

  const bgTasksPopup = usePopupAppToolbar();
  const commonPopup = usePopupAppToolbar();

  const isVisibleLoading = countBgTasks > 0;

  const onOpen = (fn: () => unknown) => () => {
    fn();
    reFetchCounters();
  };

  return (
    <>
      <div className={cx('wrapper', { isVisibleLoading })}>
        <div className={cx('notificationBlock')}>
          <NotificationsLoading
            onOpen={onOpen(bgTasksPopup.openPopup)}
            onClose={bgTasksPopup.closePopup}
            isOpen={bgTasksPopup.isOpened}
            trigger={
              isVisibleLoading ? (
                <div>
                  <SpinnerBlock count={countBgTasks} />
                </div>
              ) : (
                <></>
              )
            }
            handleEventDetail={handleEventDetail}
          />
          <NotificationsEasy
            onOpen={onOpen(commonPopup.openPopup)}
            onClose={commonPopup.closePopup}
            isOpen={commonPopup.isOpened}
            refreshCount={refreshCountCommonUnread}
            trigger={
              <div>
                <Counter
                  className={cx(className, {
                    [activeClassName]: commonPopup.isOpened,
                  })}
                  value={countCommon}
                >
                  <Icon type="notification" />
                </Counter>
              </div>
            }
            handleEventDetail={handleEventDetail}
            isOpenedEventDetail={isOpenedEventDetail}
          />
        </div>
      </div>
    </>
  );
};

export { Notifications };
