import React, { PropsWithChildren } from 'react';

import { useAccessControl, PermissionParams } from '@src/common';

type Props = PermissionParams;

const AccessControl: React.FC<PropsWithChildren<Props>> = (props) => {
  const { subject, operation } = props;
  const checkAccess = useAccessControl();
  const skipCheck = !(subject || operation);
  const allowed = skipCheck || checkAccess(props)();
  return allowed ? <>{props.children}</> : null;
};

export { AccessControl };
export type { Props as AccessControlProps };
