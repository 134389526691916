import { FC, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { StorageService } from '@common/services';
import { CURRENCY_KEY } from '@common/components/AppToolbar/constants/common';
import {
  fetchCurrencies,
  fetchCurrencyDirectory,
  fetchCurrentCurrency,
  fetchCurrentRates,
  fetchCustomerCurrency,
  fetchSetCurrency,
  fetchSetCurrentRates,
} from '@common/components/AppToolbar/api/requests';

import {
  BanksTypesDTO,
  CurrenciesListItem,
  CurrenciesSource,
  CurrencyBank,
  CurrencyDTO,
  CurrencyPairDTO,
  CurrencyTab,
  CurrentRateDTO,
  CustomCurrencyForm,
  useAppToolbarContext,
} from '@src/common';
import { CurrencyTypes } from '@src/@types';

import { CurrencyContext, RatesFromDictionary } from './CurrencyContext';

const storage = StorageService.getInstance();

const DEFAULT_CURRENCY: CurrencyTypes = storage.getItem(CURRENCY_KEY) || 'USD';
const ALL_CURRENCIES: CurrencyTypes[] = ['RUB', 'INR', 'USD'];

const dictionaryToRates = (dictionary: CurrenciesSource) => {
  const dontNeedFields = ['local_name', 'updateAt'];
  const cbrRates: CurrentRateDTO[] = [];
  const manualRates: CurrentRateDTO[] = [];
  const oandaRates: CurrentRateDTO[] = [];

  for (const provider in dictionary) {
    const providerInfo = dictionary[provider as BanksTypesDTO];
    let currentRates: CurrentRateDTO[] = [];

    switch (provider) {
      case 'OANDA':
        currentRates = oandaRates;
        break;
      case 'MANUAL':
        currentRates = manualRates;
        break;
      case 'CBR':
        currentRates = cbrRates;
        break;
    }

    if (providerInfo) {
      for (const source in providerInfo) {
        if (!dontNeedFields.includes(source)) {
          const sourceInfo = providerInfo[source as CurrencyTypes];
          if (sourceInfo) {
            for (const receiver in sourceInfo) {
              const rate = (sourceInfo as any)[receiver].cost;
              const alreadyHas = currentRates.some(
                (it) =>
                  (it.source === source && it.receiver === receiver) ||
                  (it.source === receiver && it.receiver === source),
              );
              if (typeof rate !== 'undefined' && !alreadyHas) {
                if (provider === 'MANUAL') {
                  if ((sourceInfo as any)[receiver].using) {
                    currentRates.push({
                      source,
                      receiver,
                      rate,
                      service: provider,
                    });
                  }
                } else {
                  currentRates.push({
                    source,
                    receiver,
                    rate,
                    service: provider,
                  });
                }
              }
            }
          }
        }
      }
    }
  }

  return {
    CBR: cbrRates,
    MANUAL: manualRates,
    OANDA: oandaRates,
  };
};

const CurrencyProvider: FC = ({ children }) => {
  const { brand } = useAppToolbarContext();
  const [currency, setCurrency] = useState<CurrencyTypes>(DEFAULT_CURRENCY);
  const [initialTab, setInitialTab] = useState<CurrencyTab>(CurrencyTab.PROVIDER);
  const [fetchedCurrentCurrency, setFetchedCurrentCurrency] =
    useState<CurrencyTypes>(DEFAULT_CURRENCY);
  const [currentRates, setCurrentRates] = useState<CurrentRateDTO[]>([]);
  const [currentBank, setCurrentBank] = useState<CurrencyBank>(() => {
    return brand === 'astrokite' ? CurrencyBank.OANDA : CurrencyBank.CBR;
  });
  const [currenciesPairsSetted, setCurrenciesPairsSetted] = useState<CurrencyPairDTO[]>([]);
  const [currenciesList, setCurrenciesList] = useState<CurrenciesListItem[]>([]);
  const [currencyDictionary, setCurrencyDictionary] = useState<CurrenciesSource>({});
  const [customerCurrency, setCustomerCurrency] = useState<CurrencyTypes>(DEFAULT_CURRENCY);
  const [temporaryCustomCurrencies, setTemporaryCustomCurrencies] = useState<CurrentRateDTO[]>([]);
  const [ratesFromDictionary, setRatesFromDictionary] = useState<RatesFromDictionary>({
    CBR: [],
    OANDA: [],
    MANUAL: [],
  });
  const form = useForm<CustomCurrencyForm>({
    defaultValues: {
      currencies: [],
    },
  });
  const [temporaryProviderCurrencies, setTemporaryProviderCurrencies] = useState<CurrentRateDTO[]>(
    [],
  );
  const [currenciesPairs, setCurrenciePairs] = useState<CurrencyPairDTO[]>([]);

  const fetchData = async () => {
    const [fetchedCurrencyRes, currentRatesRes, currenciesRes, customerCurrencyRes] =
      await Promise.all([
        fetchCurrentCurrency(),
        fetchCurrentRates(),
        fetchCurrencies(),
        fetchCustomerCurrency(),
      ]);

    const manualRates = currentRatesRes.data.filter((item) => item.service === 'MANUAL');
    const isManual = currentRatesRes.data.some((it) => it.service === 'MANUAL');

    setFetchedCurrentCurrency(fetchedCurrencyRes.data as CurrencyTypes);
    setCurrency(fetchedCurrentCurrency);
    setInitialTab(isManual ? CurrencyTab.CUSTOM : CurrencyTab.PROVIDER);

    if (!isManual) {
      setCurrentBank(
        currentRatesRes.data.some((it) => it.service === 'OANDA')
          ? CurrencyBank.OANDA
          : CurrencyBank.CBR,
      );
    }

    setCurrentRates(currentRatesRes.data);
    setCurrenciesPairsSetted(manualRates);
    setCurrenciesList(currenciesRes.data);
    setCustomerCurrency(customerCurrencyRes.data as CurrencyTypes);

    if (manualRates.length) {
      setTemporaryCustomCurrencies(manualRates);
      form.setValue('currencies', manualRates);
    }
  };

  const changeCurrentRates = useCallback(async (rates: CurrentRateDTO[]) => {
    const res = await fetchSetCurrentRates(rates);
    setCurrentRates(res.data);
  }, []);

  const resetSubscriptions = useCallback(() => {
    setCurrentRates([]);
  }, []);

  const getCurrencyDirectory = useCallback(async () => {
    const res = await fetchCurrencyDirectory();
    const ratesFromDictionary = dictionaryToRates(res.data);
    const customFromSettings = currentRates.filter((it) => it.service === 'MANUAL');

    setCurrencyDictionary(res.data);
    setRatesFromDictionary(ratesFromDictionary);
    if (!customFromSettings.length) {
      setTemporaryCustomCurrencies(ratesFromDictionary.MANUAL);
    }
    setTemporaryProviderCurrencies(ratesFromDictionary[currentBank as BanksTypesDTO]);
  }, [currentBank, currentRates]);

  const changeCurrency = useCallback(async (dto: CurrencyDTO) => {
    await fetchSetCurrency(dto);
    setCurrency(dto.code);
    setFetchedCurrentCurrency(dto.code);
  }, []);

  const addCurrencyPair = useCallback((data: string[]) => {
    setCurrenciePairs((prev) => [...prev, { source: data[0], receiver: data[1] }]);
  }, []);

  const deleteCurrencyPair = useCallback((data: string[]) => {
    setCurrenciePairs((prev) =>
      prev.filter((item) => item.source !== data[0] || item.receiver !== data[1]),
    );
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <CurrencyContext.Provider
      value={{
        customerCurrency,
        currency,
        fetchData,
        fetchedCurrentCurrency,
        currentRates,
        currentBank,
        currenciesList,
        changeCurrentRates,
        resetSubscriptions,
        changeCurrency,
        initialTab,
        currenciesPairsSetted,
        temporaryCustomCurrencies,
        temporaryProviderCurrencies,
        setTemporaryProviderCurrencies,
        setTemporaryCustomCurrencies,
        allCurrencies: ALL_CURRENCIES,
        currencyDictionary,
        ratesFromDictionary,
        getCurrencyDirectory,
        setCurrentBank,
        currenciesPairs,
        addCurrencyPair,
        deleteCurrencyPair,
        form,
      }}
    >
      {children}
    </CurrencyContext.Provider>
  );
};

export { CurrencyProvider };
