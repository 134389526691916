import React from 'react';

import cn from 'classnames/bind';

import { TitleProps } from './H1';
import styles from './Title.module.scss';

export const H3: React.FC<TitleProps> = ({ className, size = 'md', children }) => {
  const cx = cn.bind(styles);
  return <h3 className={cx(className, 'heading', size)}>{children}</h3>;
};
