import { createContext, useContext } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { CurrencyTypes } from '@src/@types';

import {
  CurrenciesListItem,
  CurrenciesSource,
  CurrencyBank,
  CurrencyDTO,
  CurrencyPairDTO,
  CurrencyTab,
  CurrentRateDTO,
} from '../../types';

type RatesFromDictionary = {
  CBR: CurrentRateDTO[];
  MANUAL: CurrentRateDTO[];
  OANDA: CurrentRateDTO[];
};

type CustomCurrencyForm = {
  currencies: CurrencyPairDTO[];
};

type CurrencyContextState = {
  fetchedCurrentCurrency: CurrencyTypes;
  allCurrencies: CurrencyTypes[];
  currency: CurrencyTypes;
  currentRates: CurrentRateDTO[];
  currentBank: CurrencyBank;
  currenciesList: CurrenciesListItem[];
  customerCurrency: CurrencyTypes;
  fetchData: () => void;
  resetSubscriptions: () => void;
  changeCurrentRates: (data: CurrentRateDTO[]) => void;
  initialTab: CurrencyTab;
  currenciesPairsSetted: CurrencyPairDTO[];
  temporaryCustomCurrencies: CurrencyPairDTO[];
  temporaryProviderCurrencies: CurrencyPairDTO[];
  currenciesPairs: CurrencyPairDTO[];
  setTemporaryProviderCurrencies: (data: CurrencyPairDTO[]) => void;
  setTemporaryCustomCurrencies: (data: CurrencyPairDTO[]) => void;
  addCurrencyPair: (data: string[]) => void;
  deleteCurrencyPair: (data: string[]) => void;
  ratesFromDictionary: RatesFromDictionary;
  currencyDictionary: CurrenciesSource;
  getCurrencyDirectory: () => void;
  changeCurrency: (dto: CurrencyDTO) => void;
  setCurrentBank: (bank: CurrencyBank) => void;
  form: UseFormReturn<CustomCurrencyForm>;
};

const CurrencyContext = createContext({
  currency: 'RUB',
  fetchedCurrentCurrency: 'RUB',
  currenciesList: [],
  allCurrencies: [],
  currentBank: CurrencyBank.CBR,
  currentRates: [],
  currenciesPairsSetted: [],
  temporaryCustomCurrencies: [],
  temporaryProviderCurrencies: [],
  currenciesPairs: [],
  customerCurrency: 'RUB',
  initialTab: CurrencyTab.PROVIDER,
  ratesFromDictionary: {
    CBR: [],
    MANUAL: [],
    OANDA: [],
  },
  currencyDictionary: {},
  fetchData: () => {},
  resetSubscriptions: () => {},
  changeCurrentRates: () => {},
  setTemporaryProviderCurrencies: () => {},
  setTemporaryCustomCurrencies: () => {},
  getCurrencyDirectory: () => {},
  changeCurrency: () => {},
  setCurrentBank: () => {},
  addCurrencyPair: () => {},
  deleteCurrencyPair: () => {},
} as unknown as CurrencyContextState);

const useCurrencyContext = () => useContext(CurrencyContext);

export { CurrencyContext, useCurrencyContext };

export type { RatesFromDictionary, CustomCurrencyForm };
