import cn from 'classnames/bind';
import { Icon } from '@kit/components';
import { IconTypes } from '@kit/components';

import { Size } from '@src/@types';

import styles from './ModalInfoTooltip.module.scss';

type Props = {
  containerClassName?: string;
  iconContainerClassName?: string;
  iconType?: IconTypes;
  iconSize?: Size;
  text: string;
};

const cx = cn.bind(styles);

const ModalInfoTooltip: React.FC<Props> = ({
  containerClassName,
  iconContainerClassName,
  iconType = 'circle-information-filled',
  iconSize = 'md',
  text,
}) => {
  return (
    <div className={cx('container', containerClassName)}>
      <div className={cx('iconContainer', iconContainerClassName)}>
        <Icon
          className={cx('infoIcon')}
          type={iconType}
          size={iconSize}
        />
      </div>
      <div className={cx('tooltip')}>{text}</div>
    </div>
  );
};

export { ModalInfoTooltip };
