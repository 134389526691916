import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { AxiosError } from 'axios';
import { Stages } from '@common/components/AppToolbar/components/CloudConnect/types';
import { connectYandexCloud } from '@common/components/AppToolbar/api/cloud-connections';

import {
  Form,
  useAnalytics,
  useCloudConnectContext,
  useFormApiError,
  useToast,
  useTranslationPrefix,
  YaCloudCreateDTO,
} from '@src/common';
import { bindStyles } from '@src/utils';
import { CLOUD_NAME_DICTIONARY } from '@src/constants';
import { Disclaimer } from '@src/kit';

import styles from './YandexCloudForm.module.scss';
import { CreateYaCloudStep } from './components/Steps';

const cx = bindStyles(styles);

const YandexCloudForm: React.FC = () => {
  const toast = useToast();
  const { t, tPrefix } = useTranslationPrefix('cos.yandexConnectCloud.form.');

  const { apiErrors, handleError } = useFormApiError();
  const { selectStage, changeAgreeButtonSettings, changeDisagreeButtonSettings } =
    useCloudConnectContext();
  const [loading, setLoading] = useState(false);
  const { logSuccessEvent, logErrorEvent } = useAnalytics();

  const createForm = useForm<YaCloudCreateDTO>({
    mode: 'onBlur',
  });

  useEffect(() => {
    return () => {
      createForm.reset();
    };
  }, []);

  const onBack = () => {
    selectStage({
      stage: Stages.providerChoice,
    });
  };

  const onSubmit = async (form: YaCloudCreateDTO) => {
    try {
      setLoading(true);
      await connectYandexCloud(form as YaCloudCreateDTO);
      selectStage({
        stage: Stages.connectResult,
      });
      toast.success({
        text: `${t('CloudConnectedSuccessfully', { cloud: CLOUD_NAME_DICTIONARY.Yandex })}`,
      });
      logSuccessEvent('Created cloud connection', { cloudType: CLOUD_NAME_DICTIONARY.Yandex });
    } catch (error) {
      toast.error({
        text: `${t(`FailedConnectCloud`, { cloud: CLOUD_NAME_DICTIONARY.Yandex })}`,
        hasClose: true,
        btnText: t('LearnMore'),
      });
      handleError(error as AxiosError);
      logErrorEvent('Created cloud connection', { cloudType: CLOUD_NAME_DICTIONARY.Yandex });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    changeDisagreeButtonSettings({
      onClick: onBack,
    });
    changeAgreeButtonSettings({
      onClick: createForm.handleSubmit(onSubmit),
      isLoading: loading,
    });
  }, [loading]);

  return (
    <div className={cx('ya')}>
      <div className={cx('ya-warning')}>
        <Disclaimer text={t(tPrefix('warning'))} />
      </div>
      <div className={cx('ya-form')}>
        <Form
          apiErrors={apiErrors}
          formMethods={createForm}
          onSubmit={onSubmit}
        >
          <CreateYaCloudStep
            disable={loading}
            tKey={tPrefix}
          />
        </Form>
      </div>
    </div>
  );
};

export { YandexCloudForm };
