import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  AWSConnectionFormDTO,
  AWSRegion,
  Stages,
} from '@common/components/AppToolbar/components/CloudConnect/types';
import {
  createdAWSConnection,
  fetchAWSRegions,
} from '@common/components/AppToolbar/api/cloud-connections';

import {
  Form,
  useAnalytics,
  useAppTranslation,
  useCloudConnectContext,
  useToast,
} from '@src/common';
import { CLOUD_NAME_DICTIONARY } from '@src/constants';

import { FormAWSView } from './FormAWS.view';

const FormAWS: React.FC = () => {
  const toast = useToast();
  const form = useForm<AWSConnectionFormDTO>();
  const { t } = useAppTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isRegionsLoading, setIsRegionsLoading] = useState(false);
  const { logSuccessEvent, logErrorEvent } = useAnalytics();

  const [regions, setRegions] = useState<AWSRegion[]>([]);

  const { changeAgreeButtonSettings, changeDisagreeButtonSettings, selectStage } =
    useCloudConnectContext();

  const fetchRegions = async () => {
    try {
      setIsRegionsLoading(true);
      const response = await fetchAWSRegions();
      setRegions(response.data);
    } catch (err) {
      console.error(err);
    } finally {
      setIsRegionsLoading(false);
    }
  };

  const onBackHandler = () => {
    selectStage({
      stage: Stages.providerChoice,
    });
  };

  const onSubmit = async ({ region, ...formData }: AWSConnectionFormDTO) => {
    const dto = { ...formData, region: region.value };

    try {
      setIsLoading(true);
      await createdAWSConnection(dto);
      selectStage({
        stage: Stages.connectResult,
      });
      toast.success({
        text: `${t('CloudConnectedSuccessfully', { cloud: CLOUD_NAME_DICTIONARY.AWS })}`,
      });
      logSuccessEvent('Created cloud connection', { cloudType: CLOUD_NAME_DICTIONARY.AWS });
    } catch (error) {
      toast.error({
        text: `${t(`FailedConnectCloud`, { cloud: CLOUD_NAME_DICTIONARY.AWS })}`,
        hasClose: true,
      });
      logErrorEvent('Created cloud connection', { cloudType: CLOUD_NAME_DICTIONARY.AWS });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchRegions();
  }, []);

  useEffect(() => {
    changeDisagreeButtonSettings({
      onClick: onBackHandler,
    });
    changeAgreeButtonSettings({
      onClick: form.handleSubmit(onSubmit),
      isLoading: isLoading,
    });
  }, [isLoading]);

  return (
    <Form
      formMethods={form}
      onSubmit={onSubmit}
    >
      <FormAWSView
        regions={regions}
        isRegionsLoading={isRegionsLoading}
      />
    </Form>
  );
};

export { FormAWS };
