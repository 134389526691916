import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useNewConnectionContext } from '@common/components/Clouds/components/NewConnection/NewConnectionContext';
import {
  AzureConnectionDTO,
  AzureConnectionFormDTO,
  AzureRequestSubscriptionDTO,
  AzureSubscription,
} from '@common/components/AppToolbar/components/CloudConnect/types';
import {
  connectAzure,
  connectEnterpriseAzure,
  fetchAzureSubscriptionsByParams,
  getAzureInfo,
  updateAzure,
  updateEnterpriseAzure,
} from '@common/components/AppToolbar/api/cloud-connections';
import { AxiosError } from 'axios';
import { ConnectionStep } from '@common/components/Clouds/components/NewConnection/Forms/AzureConnection/ConnectionStep';
import {
  StatusInfo,
  StatusInfoVariant,
} from '@common/components/Clouds/components/NewConnection/StatusInfo';
import { ConnectionSteps } from '@common/components/Clouds/components';
import { useCloudsContext } from '@common/providers/CloudsProvider';
import { CloudEditLoading } from '@common/components/AppToolbar/components/CloudConnect/CloudEdit/CloudEditLoading';

import { CLOUD_NAME_DICTIONARY } from '@src/constants';
import { Form, useAnalytics, useFormApiError, useToast, useTranslationPrefix } from '@src/common';
import { Button } from '@src/kit';
import { bindStyles, ErrorUtils } from '@src/utils';
import { CloudTypes } from '@src/@types';

import styles from './AzureConnection.module.scss';

const cx = bindStyles(styles);

enum AzureCreationType {
  CREATE_APPLICATION = 'create_application',
  ENTERPRISE_AGREEMENT = 'enterprise_agreement',
}

const AzureConnection: FC = () => {
  const toast = useToast();
  const {
    onBack,
    onNext,
    changeStatusInfoSettings,
    currentStep,
    statusInfoSettings,
    selectedProvider,
  } = useNewConnectionContext();
  const { cloudEdit, editableCloud } = useCloudsContext();
  const { t } = useTranslationPrefix('cos.cloud.connect.cloudDirector.');
  const { logSuccessEvent, logErrorEvent } = useAnalytics();
  const [isInitLoading, setIsInitLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingSubscriptions, setLoadingSubscriptions] = useState(false);
  const [subscriptions, setSubscriptions] = useState<AzureSubscription[]>([]);
  const [activeTab, setActiveTab] = useState(AzureCreationType.CREATE_APPLICATION);
  const form = useForm<AzureConnectionFormDTO>({
    mode: 'onBlur',
    defaultValues: {
      cloudName: editableCloud?.name,
    },
  });
  const { apiErrors, handleError } = useFormApiError();

  const onChangeTab = useCallback((value: string) => {
    setActiveTab(value as AzureCreationType);
  }, []);

  const createNewConnect = async ({
    subscriptions: subscriptionsFromForm,
    ...formData
  }: AzureConnectionFormDTO) => {
    try {
      setIsLoading(true);
      changeStatusInfoSettings({
        variant: StatusInfoVariant.LOADING,
        cloudName: formData.cloudName,
      });
      if (activeTab === AzureCreationType.CREATE_APPLICATION) {
        const subscriptions: AzureSubscription[] = subscriptionsFromForm.map(
          ({ label, value }) => ({
            name: label.toString(),
            uuid: value,
          }),
        );
        const dto: AzureConnectionDTO = {
          cloudName: formData.cloudName,
          clientId: formData.clientId,
          clientSecret: formData.clientSecret,
          tenantId: formData.tenantId,
          subscriptions,
        };
        await connectAzure(dto);
      } else {
        const dto = {
          cloudName: formData.cloudName,
          enrolmentId: formData.enrolmentId,
          bearer: formData.bearer,
        };
        await connectEnterpriseAzure(dto);
      }
      toast.success({
        text: `${t('CloudConnectedSuccessfully', { cloud: CLOUD_NAME_DICTIONARY.Azure })}`,
      });
      logSuccessEvent('Created cloud connection', {
        cloudType: CLOUD_NAME_DICTIONARY.Azure,
        creationType: activeTab,
      });
      changeStatusInfoSettings({
        variant: StatusInfoVariant.SUCCESS,
        cloudName: formData.cloudName,
      });
    } catch (error) {
      changeStatusInfoSettings({
        variant: StatusInfoVariant.ERROR,
        cloudName: formData.cloudName,
      });
      handleError(error as AxiosError);
      toast.error({
        text: `${t(`FailedConnectCloud`, { cloud: CLOUD_NAME_DICTIONARY.Azure })}`,
        hasClose: true,
      });
      logErrorEvent('Created cloud connection', {
        cloudType: CLOUD_NAME_DICTIONARY.Azure,
        creationType: activeTab,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchListSubscriptions = async (data: AzureRequestSubscriptionDTO) => {
    try {
      setLoadingSubscriptions(true);
      const response = await fetchAzureSubscriptionsByParams(data);
      setSubscriptions(response.data.subscriptions);
    } catch (error) {
      const errorMsg = ErrorUtils.handleApiError(error);
      toast.error({ text: errorMsg });
    } finally {
      setLoadingSubscriptions(false);
    }
  };

  const values = form.watch();

  const isDirty = useMemo(() => {
    const { cloudName, clientId, tenantId, clientSecret, subscriptions, enrolmentId, bearer } =
      values;
    if (activeTab === AzureCreationType.CREATE_APPLICATION) {
      return !cloudName || !clientId || !tenantId || !clientSecret || !subscriptions?.length;
    } else {
      return !cloudName || !enrolmentId || !bearer;
    }
  }, [activeTab, values]);

  const buttonLoading = () => {
    if (cloudEdit) {
      return isLoading || isInitLoading;
    } else {
      return isLoading;
    }
  };

  const loadAzureInfo = async () => {
    if (editableCloud?.id) {
      try {
        setIsInitLoading(true);
        const res = await getAzureInfo(editableCloud.id);
        form.setValue('tenantId', res.data.tenantId);
        form.setValue('clientId', res.data.clientId);
        form.setValue('cloudName', res.data.cloudName);
        form.setValue('enrolmentId', res.data.enrolmentId);
      } catch (e) {
        toast.error({
          text: `${t(`FailedEditCloud`)}`,
          hasClose: true,
        });
      } finally {
        setIsInitLoading(false);
      }
    }
  };

  useEffect(() => {
    if (cloudEdit) {
      loadAzureInfo();
    }
  }, [cloudEdit]);

  useEffect(() => {
    if (cloudEdit) {
      if (values.tenantId) {
        return setActiveTab(AzureCreationType.CREATE_APPLICATION);
      } else {
        return setActiveTab(AzureCreationType.ENTERPRISE_AGREEMENT);
      }
    }
  }, [cloudEdit, values]);

  const onEditSubmit = async (dto: AzureConnectionFormDTO) => {
    try {
      if (editableCloud?.id) {
        setIsLoading(true);
        changeStatusInfoSettings({
          variant: StatusInfoVariant.LOADING,
          cloudName: dto.cloudName,
        });
        if (values.tenantId) {
          await updateAzure(editableCloud.id, {
            cloudName: dto.cloudName,
            clientSecret: dto.clientSecret,
            clientId: dto.clientId,
            tenantId: dto.tenantId,
          });
        } else {
          await updateEnterpriseAzure(editableCloud.id, {
            cloudName: dto.cloudName,
            enrolmentId: dto.enrolmentId,
            bearer: dto.bearer,
          });
        }
        changeStatusInfoSettings({
          variant: StatusInfoVariant.SUCCESS,
          cloudName: dto.cloudName,
        });
        toast.success({
          text: t('cos.connect.cloud.edit.success'),
        });
        logSuccessEvent('Edited cloud connection', {
          id: editableCloud.id,
          cloudType: CLOUD_NAME_DICTIONARY.Azure,
        });
      }
    } catch (error) {
      handleError(error as AxiosError);
      changeStatusInfoSettings({
        variant: StatusInfoVariant.ERROR,
        cloudName: dto.cloudName,
      });
      toast.error({
        text: `${t(`FailedEditCloud`)}`,
        hasClose: true,
      });
      logErrorEvent('Edited cloud connection', {
        id: editableCloud?.id,
        cloudType: CLOUD_NAME_DICTIONARY.Azure,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = useCallback(() => {
    if (cloudEdit) {
      onNext();
      form.handleSubmit(onEditSubmit)();
    } else {
      onNext();
      form.handleSubmit(createNewConnect)();
    }
  }, [cloudEdit, values]);

  return (
    <Form<AzureConnectionFormDTO>
      formMethods={form}
      onSubmit={onSubmit}
      apiErrors={apiErrors}
      className={cx('form')}
    >
      {currentStep === ConnectionSteps.STATUS ? (
        <StatusInfo
          cloudType={selectedProvider as CloudTypes}
          variant={statusInfoSettings.variant}
          cloudName={statusInfoSettings.cloudName}
        />
      ) : (
        <>
          {isInitLoading && cloudEdit ? (
            <CloudEditLoading />
          ) : (
            <div className={cx('inputs')}>
              <ConnectionStep
                fetchListSubscriptions={fetchListSubscriptions}
                list={subscriptions}
                isLoading={loadingSubscriptions}
                activeTab={activeTab}
                onChangeTab={onChangeTab}
              />
            </div>
          )}
          <div className={cx('buttons')}>
            <Button
              text={t('clouds.connection.button.back')}
              variant="outline"
              onClick={onBack}
            />
            <Button
              text={
                cloudEdit ? t('cos.cloudConnect.edit.save') : t('clouds.connection.button.connect')
              }
              type="submit"
              isLoading={buttonLoading()}
              disabled={!cloudEdit && isDirty}
            />
          </div>
        </>
      )}
    </Form>
  );
};

export { AzureConnection, AzureCreationType };
