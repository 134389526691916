import { useEffect, useRef, useState } from 'react';

import { useResize } from '@src/common';

const useDropdownResize = () => {
  const [resized, setResized] = useState<symbol>();
  const screenWidth = useResize(false);
  const prevHeight = useRef<number>(0);

  const resizeObserver = useRef<ResizeObserver>();

  useEffect(() => () => resizeObserver.current?.disconnect(), []);

  useEffect(() => {
    setResized(Symbol());
  }, [screenWidth]);

  const onInitResize = (ref: HTMLDivElement) => {
    resizeObserver.current = new ResizeObserver((entities) => {
      const [container] = entities;
      if (container) {
        const { height } = container.contentRect;
        if (prevHeight.current !== 0 && prevHeight.current !== height) {
          setResized(Symbol());
        }
        prevHeight.current = height;
      }
    });

    resizeObserver.current?.observe(ref);
  };

  return {
    resized,
    onInitResize,
  };
};

export { useDropdownResize };
