import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { UserUpdateDTO, UserViewDTO } from '@common/components/UserRoles/types';
import { fetchDeleteUser, fetchUpdateUser } from '@common/components/UserRoles/api/requests';

import {
  AccessControl,
  Form,
  useAppTranslation,
  useDialog,
  useToast,
  useUserRolesContext,
} from '@src/common';
import { bindStyles } from '@src/utils';
import { Button, H2, Icon, SideModal } from '@src/kit';
import { SelectValue } from '@src/@types';

import { UserDetailsView } from './UserDetails.view';
import styles from './UserDetails.module.scss';
import { UserDetailCardEdit } from './UserDetailCardEdit';

type Props = {
  userData: UserViewDTO;
  reFetch: () => void;
  clearSelection: () => void;
};

type EditFormData = {
  firstName: string;
  lastName: string;
  comment?: string;
  login: string;
  clouderRole: SelectValue<number>;
  costerRole: SelectValue<number>;
};

const cx = bindStyles(styles);

const UserDetails: React.FC<Props> = ({ userData, reFetch, clearSelection }) => {
  const toast = useToast();
  const { t } = useAppTranslation();
  const [edit, setEdit] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const onCloseDialog = () => {
    setIsDialogOpen(false);
  };
  const { firstName, lastName, comment, login, uuid } = userData;

  const { cardId, closeUserModal } = useUserRolesContext();

  const formId = edit ? 'UserDetailCardEdit' : 'UserDetailCard';

  const editForm = useForm<EditFormData>({
    defaultValues: {
      firstName,
      lastName,
      comment,
      login: login,
      clouderRole: null,
      costerRole: null,
    },
  });

  useEffect(() => {
    editForm.reset({
      firstName,
      lastName,
      comment,
      login: login,
      clouderRole: null,
      costerRole: null,
    });
  }, [userData]);

  const onSubmit = async ({
    comment,
    firstName,
    lastName,
    login,
    clouderRole,
    costerRole,
  }: EditFormData) => {
    if (uuid) {
      const payload: UserUpdateDTO = {
        uuid,
        comment,
        firstName,
        lastName,
        login,
        roleIds: [clouderRole?.value as number, costerRole?.value as number],
      };

      try {
        await fetchUpdateUser(payload);
        reFetch();
        handleCloseCard();
        toast.success({ text: t('UserEditSuccess', { name: `${lastName} ${firstName}` }) });
      } catch (e) {
        toast.error({
          text: t('UserEditFailed', { name: `${lastName} ${firstName}` }),
          hasClose: true,
          btnText: t('LearnMore'),
        });
      }
    }
  };

  useEffect(() => () => handleCloseEdit(), [cardId]);
  useEffect(() => () => handleCloseCard(), []);

  const handleOpenEdit = () => {
    setEdit(true);
  };

  const handleCloseEdit = () => {
    setEdit(false);
  };

  const handleCloseCard = () => {
    closeUserModal();
    clearSelection();
  };
  const { Dialog, openPopup: openDialog, closePopup: closeDialog } = useDialog();

  const actions = {
    cancel: {
      text: t('Cancel'),
    },
    submit: {
      text: t('Delete'),
      negative: true,
    },
  };
  const name = lastName || firstName ? (lastName && lastName + ' ') + (firstName && firstName) : '';
  const onDelete = async () => {
    try {
      await fetchDeleteUser(userData.uuid);
      closeUserModal();
      closeDialog();
      toast.success({ text: t('UserDeleteSuccess', { name: name }) });
      reFetch();
    } catch (error) {
      toast.error({
        text: t('UserDeleteFailed', { name: name }),
        hasClose: true,
        btnText: t('LearnMore'),
      });
    }
  };
  const handleOpenDialogDeleteUser = () => {
    setIsDialogOpen(true);
    openDialog();
  };

  return (
    <SideModal
      size="md"
      open={!!cardId}
      onClose={handleCloseCard}
      titleText={t('User')}
      classes={{
        inner: cx('modal-inner'),
        content: cx('modal-content'),
        wrapper: cx('modal-wrapper'),
      }}
      closeOnDocumentClick={!edit && !isDialogOpen}
      modalFooter={
        edit ? (
          <div className={cx('footer')}>
            <Button
              key="cancel"
              className={cx('leftButton')}
              onClick={handleCloseEdit}
              variant="outline"
              text={t('Cancel')}
              full
            />
            <Button
              key="submit"
              type="submit"
              variant="filled"
              text={t('Create')}
              onClick={editForm.handleSubmit(onSubmit)}
              full
              form={formId}
            />
          </div>
        ) : (
          <div className={cx('footer')}>
            <div>
              <AccessControl
                action="delete"
                subject="user"
              >
                <Button
                  key="delete"
                  className={cx('leftButton')}
                  onClick={handleOpenDialogDeleteUser}
                  variant="outline"
                  text={t('Delete')}
                  isNegativeIcon
                  full
                  negative
                >
                  <Icon type="delete" />
                </Button>
              </AccessControl>
            </div>
            <div>
              <AccessControl
                action="update"
                subject="user"
              >
                <Button
                  key="edit"
                  variant="outline"
                  text={t('edit')}
                  full
                  onClick={handleOpenEdit}
                >
                  <Icon type="edit" />
                </Button>
              </AccessControl>
            </div>
          </div>
        )
      }
    >
      {edit ? (
        <Form
          id={formId}
          onSubmit={onSubmit}
          formMethods={editForm}
        >
          <UserDetailCardEdit userData={userData} />
        </Form>
      ) : (
        <UserDetailsView userData={userData} />
      )}
      <Dialog
        onClose={onCloseDialog}
        closeOnDocumentClick={false}
        actions={actions}
        type="warning"
        onCancel={closeDialog}
        onConfirm={onDelete}
      >
        <H2 className={cx('headerDialog')}>
          {t('cld.user.dialog.title')}
          <br />
          {' “' + name + '”'}
        </H2>
        <p className={cx('textDialog')}>
          {t('cld.user.dialog.text1')}
          <br />
          {t('cld.user.dialog.text2')}
        </p>
      </Dialog>
    </SideModal>
  );
};

export { UserDetails };
