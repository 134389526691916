import { FC, useEffect, useMemo, useState } from 'react';

import { SettingItem } from '@kit/components/SettingItem/SettingItem';
import { ActiveDirectory } from '@common/components/SettingsProject/contents/ActiveDirectory';
import { TariffAndPrices } from '@common/components/SettingsProject/contents/TariffAndPrices';
import { Skeleton } from '@common/components/SettingsProject/contents/Skeleton';
import { fetchConnectedClouds } from '@common/components/AppToolbar/api/cloud-connections';

import { Card, H1, IconTypes } from '@src/kit';
import { bindStyles } from '@src/utils';
import { useTranslationPrefix } from '@src/common';

import styles from './SettingsProject.module.scss';

const cx = bindStyles(styles);

export enum SettingsPageItems {
  TARIFF_AND_PRICES = 'tariffAndPrices',
  DOMAIN_AD = 'domainAD',
}

const PANEL_ITEMS = [
  {
    type: SettingsPageItems.TARIFF_AND_PRICES,
    icon: 'coins',
  },
  {
    type: SettingsPageItems.DOMAIN_AD,
    icon: 'active-directory',
  },
];

const CONTENTS: Record<SettingsPageItems, JSX.Element> = {
  [SettingsPageItems.DOMAIN_AD]: <ActiveDirectory />,
  [SettingsPageItems.TARIFF_AND_PRICES]: <TariffAndPrices />,
};

const SettingsProject: FC = () => {
  const { t } = useTranslationPrefix('cos.activeDirectory.form.');
  const [showTariffs, setShowTariffs] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const showPanelItems = useMemo(() => {
    if (showTariffs) {
      return PANEL_ITEMS;
    }

    return PANEL_ITEMS.filter((it) => it.type !== SettingsPageItems.TARIFF_AND_PRICES);
  }, [showTariffs]);

  const [currentTab, setCurrentTab] = useState<SettingsPageItems>(showPanelItems[0].type);

  const getClouds = async () => {
    try {
      setIsLoading(true);
      const response = await fetchConnectedClouds();
      const vdcClouds = response.data.filter(
        (it) => it.cloudType === 'vCloudDirector' && it.status === 'ACTIVE',
      );
      setShowTariffs(Boolean(vdcClouds.length));
      if (vdcClouds.length) {
        setCurrentTab(PANEL_ITEMS[0].type);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getClouds();
  }, []);

  return (
    <>
      <div className={cx('wrapper')}>
        <div className={cx('header')}>
          <H1 size="lg">{t('Settings')}</H1>
        </div>
        <div className={cx('wrapperCards')}>
          {isLoading ? (
            <Skeleton />
          ) : (
            <>
              <Card className={cx('settingsMenu')}>
                {PANEL_ITEMS.map((it) => (
                  <SettingItem
                    key={it.type}
                    type={it.type}
                    isActive={it.type === currentTab}
                    icon={it.icon as IconTypes}
                    onClick={() => setCurrentTab(it.type)}
                  />
                ))}
              </Card>
              <Card className={cx('settingsContent')}>{CONTENTS[currentTab]}</Card>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export { SettingsProject };
