import React from 'react';

import { BlockTitle } from '@common/components/AppToolbar/components/CloudConnect/BlockTitle';

import { bindStyles } from '@src/utils';
import { FormInput, required } from '@src/common';

import styles from './Steps.module.scss';

const cx = bindStyles(styles);

type Props = {
  disable: boolean;
  tKey: (postfix: string) => string;
};

const CreateYaCloudStep: React.FC<Props> = ({ tKey, disable, children }) => (
  <>
    <div className={cx('form')}>
      <FormInput
        className={cx('form__input', 'form__input', 'fade-in-1')}
        name="cloudName"
        disabled={disable}
        rules={{ ...required('FieldRequired') }}
        caption={tKey('cloudName.label')}
        placeholder={tKey('cloudName.placeholder')}
        hint={tKey('cloudName.hint')}
      />
      <div className={cx('rowContainer')}>
        <FormInput
          className={cx('form__input', 'form__input_half', 'fade-in-2')}
          name="bucketName"
          disabled={disable}
          rules={required('FieldRequired')}
          caption={tKey('bucketName.label')}
          placeholder={tKey('bucketName.placeholder')}
          hint={tKey('bucketName.hint')}
        />
        <FormInput
          className={cx('form__input', 'form__input_half', 'fade-in-5')}
          name="bucketCatalogName"
          disabled={disable}
          rules={required('FieldRequired')}
          caption={tKey('bucketCatalogName.label')}
          placeholder={tKey('bucketCatalogName.placeholder')}
          hint={tKey('bucketCatalogName.hint')}
        />
      </div>
      <div className={cx('rowContainer', 'full')}>
        <FormInput
          className={cx('form__input', 'fade-in-3')}
          name="accessKey"
          disabled={disable}
          rules={required('FieldRequired')}
          caption={tKey('accessKey.label')}
          placeholder={tKey('accessKey.placeholder')}
          hint={tKey('accessKey.hint')}
        />
      </div>
      <div className={cx('rowContainer', 'full')}>
        <FormInput
          className={cx('form__input', 'form__input_half', 'fade-in-3', 'text-area')}
          name="secretKey"
          disabled={disable}
          rules={required('FieldRequired')}
          caption={tKey('secretKey.label')}
          placeholder={tKey('secretKey.placeholder')}
          hint={tKey('secretKey.hint')}
        />
      </div>
      <div>
        <BlockTitle
          titleKey="cos.yandexConnectCloud.form.oauthToken.caption"
          hintKey="cos.yandexConnectCloud.form.oauthToken.caption.info"
        />
        <div className={cx('rowContainer', 'full')}>
          <FormInput
            className={cx('form__input', 'fade-in-3')}
            name="oauthToken"
            disabled={disable}
            placeholder="Yandex OAuth-token"
          />
        </div>
      </div>
    </div>
    {children}
  </>
);

export { CreateYaCloudStep };
