import { FC } from 'react';

import { ColorPulsator } from '@src/kit';
import { bindStyles } from '@src/utils';

import styles from './CloudEditLoading.module.scss';

const cx = bindStyles(styles);

const CloudEditLoading: FC = () => {
  return (
    <div className={cx('loaderContainer')}>
      <ColorPulsator />
      <ColorPulsator />
      <ColorPulsator />
    </div>
  );
};

export { CloudEditLoading };
