import * as dateFns from 'date-fns';

const start = (date: Date) => dateFns.startOfDay(date);
const end = (date: Date) => dateFns.endOfDay(date);
const equals = (date1: Date, date2: Date) => dateFns.isEqual(end(date1), end(date2));

const fromIso = (value: string | null | undefined) => (value ? dateFns.parseISO(value) : null);
const toIso = (value: Date | null | undefined) => (value ? dateFns.formatISO(value) : null);

export { fromIso, toIso, equals, start, end };
