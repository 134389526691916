import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { AxiosError } from 'axios';
import {
  OpenStackFormDTO,
  Stages,
} from '@common/components/AppToolbar/components/CloudConnect/types';
import { connectOpenStack } from '@common/components/AppToolbar/api/cloud-connections';

import {
  Form,
  StorageProfile,
  useAnalytics,
  useAppTranslation,
  useCloudConnectContext,
  useFormApiError,
  useToast,
} from '@src/common';
import { CLOUD_NAME_DICTIONARY } from '@src/constants';
import { gbToMb, bindStyles } from '@src/utils';
import { Gb } from '@src/@types';

import styles from './FormOpenStack.module.scss';
import { ConnectionStep } from './ConnectionStep';
import { ProfileSettingsStep } from './ProfilesSettingsStep';

enum OpenStackSteps {
  CONNECTION = 'connection',
  PROFILES = 'profiles',
}

const cx = bindStyles(styles);

const FormOpenStack: React.FC = () => {
  const toast = useToast();
  const { t } = useAppTranslation();

  const { apiErrors, handleError } = useFormApiError();
  const { selectStage, changeDisagreeButtonSettings, changeAgreeButtonSettings } =
    useCloudConnectContext();
  const [step, setStep] = useState(OpenStackSteps.CONNECTION);
  const [isLoading, setLoading] = useState<boolean>(false);
  const { logSuccessEvent, logErrorEvent } = useAnalytics();

  const form = useForm<OpenStackFormDTO>({
    mode: 'onBlur',
    defaultValues: {
      nativeRegionName: 'RegionOne',
      storageProfiles: [],
    },
  });

  const onBackHandler = useCallback(() => {
    if (step === OpenStackSteps.CONNECTION) {
      selectStage({
        stage: Stages.providerChoice,
      });
      changeAgreeButtonSettings({
        text: t('Connect'),
      });
    } else {
      setStep(OpenStackSteps.CONNECTION);
    }
  }, [step, t]);

  const onSubmit = useCallback(() => {
    if (step === OpenStackSteps.CONNECTION) {
      form.handleSubmit(() => {
        setStep(OpenStackSteps.PROFILES);
      })();
    } else {
      form.handleSubmit(createNewConnect)();
    }
  }, [step]);

  const agreeButtonText = useMemo(() => {
    return step === OpenStackSteps.CONNECTION ? t('Next') : t('Connect');
  }, [step]);

  const createNewConnect = async (dto: OpenStackFormDTO) => {
    try {
      setLoading(true);
      const storageProfiles: StorageProfile[] =
        dto.storageProfiles?.map((item) => {
          return {
            name: item.key,
            price: item.value,
            maxSize: item.maxSize.length ? gbToMb(Number(item.maxSize) as Gb) : undefined,
          };
        }) || [];
      await connectOpenStack({ ...dto, storageProfiles });
      selectStage({
        stage: Stages.connectResult,
      });
      toast.success({
        text: t('CloudConnectedSuccessfully', { cloud: CLOUD_NAME_DICTIONARY.OpenStack }),
      });
      logSuccessEvent('Created cloud connection', { cloudType: CLOUD_NAME_DICTIONARY.OpenStack });
    } catch (error) {
      handleError(error as AxiosError);
      logErrorEvent('Created cloud connection', { cloudType: CLOUD_NAME_DICTIONARY.OpenStack });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    changeDisagreeButtonSettings({
      onClick: onBackHandler,
    });
    changeAgreeButtonSettings({
      onClick: onSubmit,
      text: agreeButtonText,
      isLoading: isLoading,
    });
  }, [onBackHandler, onSubmit, agreeButtonText, isLoading]);

  return (
    <Form
      formMethods={form}
      apiErrors={apiErrors}
      onSubmit={createNewConnect}
    >
      <div className={cx('container')}>
        {step === OpenStackSteps.CONNECTION ? <ConnectionStep /> : <ProfileSettingsStep />}
      </div>
    </Form>
  );
};

export { FormOpenStack };
