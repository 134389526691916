import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { UserViewDTO } from '@common/components/UserRoles/types';
import { fetchUserPassGenerate } from '@common/components/UserRoles/api/requests';

import {
  FormInput,
  FormSelect,
  FormTextarea,
  required,
  useAppTranslation,
  useToast,
  useUserRolesContext,
} from '@src/common';
import { bindStyles } from '@src/utils';
import { ErrorUtils } from '@src/utils';
import { Button, Disclaimer } from '@src/kit';

import AvatarDefault from '@assets/images/AvatarDefault.svg';
import AvatarDefaultAstrokite from '@assets/images/AvatarDefaultAstrokite.svg';

import styles from './UserDetailCardEdit.module.scss';

type Props = {
  userData: UserViewDTO;
};

const cx = bindStyles(styles);

const UserDetailCardEdit: React.FC<Props> = ({ userData }) => {
  const { t } = useAppTranslation();
  const toast = useToast();
  const {
    formattedCosterRoles,
    formattedClouderRoles,
    isRolesLoading,
    brand,
    stepDisclaimer,
    setStepDisclaimer,
  } = useUserRolesContext();
  const { setValue } = useFormContext();
  const { roles, uuid } = userData;
  const clouderRoleId = roles.length ? roles[0]?.id : 0;
  const costerRoleId = roles.length ? roles[1]?.id : 0;
  const [pauseFetch, setPauseFetch] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      setStepDisclaimer('info');
      setPauseFetch(false);
    };
  }, []);

  useEffect(() => {
    const selectedClouderRole = formattedClouderRoles.find(({ value }) => value === clouderRoleId);

    if (selectedClouderRole) {
      setValue('clouderRole', selectedClouderRole);
    }
  }, [formattedClouderRoles]);

  useEffect(() => {
    const selectedCosterRole = formattedCosterRoles.find(({ value }) => value === costerRoleId);

    if (selectedCosterRole) {
      setValue('costerRole', selectedCosterRole);
    }
  }, [formattedCosterRoles]);

  const generateNewPass = async () => {
    try {
      await fetchUserPassGenerate(uuid);
      setStepDisclaimer('success');
      setPauseFetch(true);
    } catch (e) {
      const errorMsg = ErrorUtils.handleApiError(e);
      toast.error({ text: errorMsg });
    }
  };

  const renderedAvatar = useMemo(() => {
    switch (brand) {
      case 'astrokite':
        return <AvatarDefaultAstrokite />;
      case 'cloudmaster':
        return <AvatarDefault />;
    }
  }, [brand]);

  return (
    <>
      <div className={cx('container')}>
        <div className={cx('avatar')}>{renderedAvatar}</div>

        <div className={cx('infoBlock')}>
          <FormInput
            name="firstName"
            rules={required('FieldRequired')}
            caption={t('Name')}
          />
          <FormInput
            name="lastName"
            rules={required('FieldRequired')}
            caption={t('Surname')}
          />
        </div>

        <div className={cx('infoBlock', 'resetPass')}>
          <FormInput
            name="login"
            caption={t('EmailLogin')}
            disabled
          />
          <Button
            text={t('GenerateNewPassword')}
            variant="outline"
            disabled={pauseFetch}
            className={cx('btnResetPass')}
            onClick={generateNewPass}
          />
        </div>

        <Disclaimer
          variant={stepDisclaimer}
          text={t(`cld.modal.userEdit.${stepDisclaimer}.text`)}
        />

        <div className={cx('infoBlock')}>
          <FormSelect
            options={formattedClouderRoles}
            name="clouderRole"
            caption={t('cos.user.roles.clouder')}
            placeholder={t('cld.modal.role.placeholder')}
            isLoading={isRolesLoading}
            rules={required('FieldRequired')}
          />
          <FormSelect
            options={formattedCosterRoles}
            name="costerRole"
            caption={t('cos.user.roles.coster')}
            placeholder={t('cld.modal.role.placeholder')}
            isLoading={isRolesLoading}
            rules={required('FieldRequired')}
          />
        </div>

        <FormTextarea
          name="comment"
          caption={t('Comment')}
        />
      </div>
    </>
  );
};

export { UserDetailCardEdit };
