import React from 'react';
import ReactDOM from 'react-dom';

import './index.scss';
import {
  AppToolbarProvider,
  AUTH_HEADER,
  AuthProvider,
  CurrencyProvider,
  ErrorBoundaryProvider,
  NotificationsProvider,
  RestService,
  StorageService,
  TranslateProvider,
  AuthApi,
  LoadingPageProvider,
  AnalyticsProvider,
} from '@cm/ui-common';
import { Brand } from '@cm/ui-common';

import { App } from '@components/App';

import '@cm/ui-common/app.css';

RestService.getInstance().addBaseURL('/data');

const authorize = async () => {
  const res = await AuthApi.authorization({
    code: btoa(
      `${process.env.REACT_APP_CREDENTIALS_LOGIN}:${process.env.REACT_APP_CREDENTIALS_PASSWORD}`,
    ),
  });

  const sessionToken = res.headers[AUTH_HEADER];

  if (sessionToken) {
    StorageService.getInstance().setItem(AUTH_HEADER, sessionToken);
    RestService.getInstance().addDefaultHeader(AUTH_HEADER, sessionToken);
  }
};

const apiUrl = window.location.host || 'dev';

const EXCLUDED_ERROR_URLS = [
  '/notifier/notification/log',
  '/notifier/notification/unread',
  '/notifier/notification/tech',
  '/notifier/task/running/count',
];

const renderApp = async () => {
  if (process.env.NODE_ENV !== 'production') {
    await authorize();
  }

  ReactDOM.render(
    <React.StrictMode>
      <AuthProvider system="coster">
        <TranslateProvider>
          <ErrorBoundaryProvider
            brand={process.env.REACT_APP_BRAND_NAME as Brand}
            excludeErrorUrls={EXCLUDED_ERROR_URLS}
          >
            <AppToolbarProvider
              brand={process.env.REACT_APP_BRAND_NAME as Brand}
              supportEmail={process.env.REACT_APP_SUPPORT_EMAIL as string}
            >
              <AnalyticsProvider
                token={process.env.REACT_APP_AMPLITUDE}
                isDisabled={
                  apiUrl.includes('dev') || apiUrl.includes('stage') || apiUrl.includes('localhost')
                }
              >
                <NotificationsProvider>
                  <CurrencyProvider>
                    <React.Suspense fallback={<LoadingPageProvider />}>
                      <App />
                    </React.Suspense>
                  </CurrencyProvider>
                </NotificationsProvider>
              </AnalyticsProvider>
            </AppToolbarProvider>
          </ErrorBoundaryProvider>
        </TranslateProvider>
      </AuthProvider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
};

renderApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
