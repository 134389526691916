import React, { useState } from 'react';

import { Icon } from '@src/kit';

import { FormInput, FormInputProps } from './FormInput';

type Props = Omit<FormInputProps, 'type'> & {
  showPass?: boolean;
};

const FormPasswordInput: React.FC<Props> = ({ showPass, ...restProps }) => {
  const [showPassword, setShowPassword] = useState(false);
  const toggle = () => {
    setShowPassword((prev) => !prev);
  };

  const showIcon = (
    <Icon
      style={{ cursor: 'pointer' }}
      type={showPassword ? 'eye-off' : 'eye'}
      onClick={toggle}
    />
  );

  return (
    <FormInput
      {...restProps}
      type={showPassword ? 'text' : 'password'}
      actions={showPass ? [{ icon: showIcon }] : undefined}
    />
  );
};

export { FormPasswordInput };
