import React, { PropsWithChildren } from 'react';
import { Cell } from 'react-table';

type Props<T extends Record<string, unknown>> = React.TdHTMLAttributes<HTMLTableDataCellElement> & {
  cell?: Cell<T>;
  className?: string;
};

const TD = <T extends Record<string, unknown>>({
  cell,
  className,
  children,
  ...restProps
}: PropsWithChildren<Props<T>>) => {
  if (!cell) {
    return (
      <td
        className={className}
        {...restProps}
      >
        {children}
      </td>
    );
  }

  const cellProps = cell.getCellProps({
    className,
    style: { maxWidth: cell.column.maxWidth + '%' },
  });

  return (
    <td
      {...cellProps}
      {...restProps}
    >
      {children}
    </td>
  );
};

export { TD };
