import * as dateFns from 'date-fns';
import { FastDatesProps } from '@kit/components/Calendar/Container';

import { mapToOption } from '@src/utils';

const start = (date: Date) => dateFns.startOfDay(date);

const now = dateFns.endOfDay(new Date());

const date = dateFns.endOfDay(new Date());
date.setMonth(date.getMonth() - 1);

const DATE_RANGES: Record<string, [Date, Date]> = {
  week: [start(dateFns.subDays(now, 6)), now],
  month: [start(dateFns.subDays(now, 29)), now],
  twoMonths: [start(dateFns.subDays(now, 59)), now],
  quarter: [start(dateFns.subDays(now, 89)), now],
  currentMonth: [start(dateFns.startOfMonth(now)), now],
  previousMonth: [start(dateFns.startOfMonth(date)), dateFns.endOfMonth(date)],
};

const useDayFastDatesOptions = () => {
  const fastDates: FastDatesProps['fastDates'] = [
    mapToOption(DATE_RANGES.week, 'cos.reports.filter.period.week', 'week'),
    mapToOption(DATE_RANGES.month, 'cos.reports.filter.period.month', 'month'),
    mapToOption(DATE_RANGES.twoMonths, 'cos.reports.filter.period.twoMonths', 'twoMonths'),
    mapToOption(DATE_RANGES.quarter, 'cos.reports.filter.period.quarter', 'quarter'),
    mapToOption(DATE_RANGES.currentMonth, 'cos.reports.filter.period.currentMonth', 'currentMonth'),
    mapToOption(
      DATE_RANGES.previousMonth,
      'cos.reports.filter.period.previousMonth',
      'previousMonth',
    ),
  ];

  return { fastDates, DATE_RANGES };
};

export { useDayFastDatesOptions };
