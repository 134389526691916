import React from 'react';

import { TextShorter } from '@kit/components/TextShorter';

import { bindStyles } from '@src/utils';

import styles from './Select.module.scss';

type Props = {
  label: string;
  isShortened?: boolean;
  tooltip?: boolean;
};

const BaseOptionWithIcon: React.FC<Props> = ({ label, children, isShortened, tooltip }) => {
  const cx = bindStyles(styles);
  return (
    <div className={cx('optionWithIcon')}>
      <div className={cx('icon')}>{children}</div>
      {isShortened ? (
        <div className={cx('textShorterConainer')}>
          <TextShorter tooltip={tooltip}>{label}</TextShorter>
        </div>
      ) : (
        label
      )}
    </div>
  );
};

export { BaseOptionWithIcon };
