import { CustomerSettingsDTO, IRestService, RestService } from '@src/common';

import { UserInfoDTO } from './models';

class UserApi {
  static restService: IRestService = RestService.getInstance();

  static getUserInfo() {
    const url = `/idm/api/user/current`;
    return this.restService.GET<UserInfoDTO>(url);
  }

  static getCustomerSettings(customerUuid?: string) {
    const url = `/idm/interface/customer${customerUuid ? `/${customerUuid}` : ''}/settings`;
    return this.restService.GET<CustomerSettingsDTO[]>(url);
  }
}

export { UserApi };
