import React, { useEffect, useMemo } from 'react';

import { CurrencyBank } from '@common/components/AppToolbar/types';

import { Select, SelectOption } from '@src/kit';
import { toDefaultDate, useCurrencyContext, useTranslationPrefix } from '@src/common';
import { bindStyles } from '@src/utils';
import { CurrencyTypes } from '@src/@types';

import styles from './SourceForm.module.scss';

type Props = {
  currentBank: CurrencyBank;
  currenciesList: SelectOption<CurrencyTypes, string>[];
  currentCurrency: string;
  setCurrentBank: (value: CurrencyBank) => void;
};

const cx = bindStyles(styles);
const SourceForm: React.FC<Props> = ({ currentCurrency, currentBank }) => {
  const { t, tPrefix } = useTranslationPrefix('cos.currencyModal.');
  const {
    currencyDictionary,
    setCurrentBank,
    ratesFromDictionary,
    setTemporaryProviderCurrencies,
    temporaryProviderCurrencies,
  } = useCurrencyContext();

  const banksList = useMemo(() => {
    return Object?.keys(currencyDictionary)
      .filter((item) => {
        return item !== 'MANUAL';
      })
      .map((it) => ({ ...currencyDictionary[it as CurrencyBank], key: it }));
  }, [currencyDictionary]);

  const banksOptions = useMemo(() => {
    return banksList.map((item) => {
      return { label: t(item.locale_name || `cos.${item.key}`), value: item.key };
    });
  }, [banksList]);

  const currentBankRate = useMemo(() => {
    return currencyDictionary[currentBank || banksList?.[1]];
  }, [currentBank]);

  const currentRates = useMemo(() => {
    return currencyDictionary?.[currentBank || banksList?.[1]]?.[currentCurrency as CurrencyTypes];
  }, [currentCurrency, currentBank, currencyDictionary, banksList]);

  const time = useMemo(() => {
    return currencyDictionary?.[currentBank]?.updateAt;
  }, [currencyDictionary, currentBank]);

  const rateValues = useMemo(() => {
    if (currentRates) {
      return Object?.entries(currentRates);
    }
  }, [currentRates]);

  const onChange = (option: SelectOption<CurrencyBank>) => {
    setCurrentBank(option.value);
    setTemporaryProviderCurrencies(ratesFromDictionary[option.value as CurrencyBank]);
  };

  useEffect(() => {
    if (currentBank && !temporaryProviderCurrencies.length) {
      setTemporaryProviderCurrencies(ratesFromDictionary[currentBank as CurrencyBank]);
    }
  }, [currentBank, temporaryProviderCurrencies]);

  const [source, reciever] = rateValues || [];

  const sourceCode = source?.[0];
  const sourceValue = source?.[1]?.cost;

  const recieverCode = reciever?.[0];
  const recieverValue = reciever?.[1]?.cost;

  return (
    <div>
      {currentRates && (
        <>
          <Select
            name="bank"
            isDisabled={banksList?.length <= 1}
            value={{
              label: t(currentBankRate?.locale_name || `cos.${currentBank}`),
              value: currentBank,
            }}
            onChange={onChange}
            options={banksOptions}
            isSearchable={false}
            size="md"
            className={cx('bankSelect')}
            noOptionsText="NoOptions"
            classNamePrefix="bank"
          />
          <div className={cx('currenciesBlock')}>
            {sourceValue && (
              <div className={cx('currencieBlock')}>
                <div className={cx('currencieLabel')}>{sourceCode}</div>
                <div className={cx('currencieValue')}>{Math.round(sourceValue * 100) / 100}</div>
              </div>
            )}
            {recieverValue && (
              <div className={cx('currencieBlock')}>
                <div className={cx('currencieLabel')}>{recieverCode}</div>
                <div className={cx('currencieValue')}>{Math.round(recieverValue * 100) / 100}</div>
              </div>
            )}
          </div>
          {/* последнее обновление курсов */}
          <div className={cx('timeLabel')}>{tPrefix('ratesUpdateDate')}</div>
          <div className={cx('timeValue')}>
            {time && toDefaultDate(time?.toString(), 'dd.MM.yyyy HH:mm')}
          </div>
        </>
      )}
    </div>
  );
};

export { SourceForm };
