import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useNewConnectionContext } from '@common/components/Clouds/components/NewConnection/NewConnectionContext';
import {
  AWSConnectionFormDTO,
  AWSRegion,
} from '@common/components/AppToolbar/components/CloudConnect/types';
import {
  createdAWSConnection,
  fetchAWSRegions,
  getAWSCloud,
  updateAWS,
} from '@common/components/AppToolbar/api/cloud-connections';
import { AxiosError } from 'axios';
import { ConnectionStep } from '@common/components/Clouds/components/NewConnection/Forms/AWSConnection/ConnectionStep';
import {
  StatusInfo,
  StatusInfoVariant,
} from '@common/components/Clouds/components/NewConnection/StatusInfo';
import { ConnectionSteps } from '@common/components/Clouds/components';
import { useCloudsContext } from '@common/providers/CloudsProvider';
import { CloudEditLoading } from '@common/components/AppToolbar/components/CloudConnect/CloudEdit/CloudEditLoading';

import { CLOUD_NAME_DICTIONARY } from '@src/constants';
import { Form, useAnalytics, useFormApiError, useToast, useTranslationPrefix } from '@src/common';
import { Button, SelectOption } from '@src/kit';
import { bindStyles, mapToOption } from '@src/utils';
import { CloudTypes } from '@src/@types';

import styles from './AWSConnection.module.scss';

const cx = bindStyles(styles);

const AWSConnection: FC = () => {
  const toast = useToast();
  const {
    onBack,
    onNext,
    changeStatusInfoSettings,
    currentStep,
    statusInfoSettings,
    selectedProvider,
  } = useNewConnectionContext();
  const { cloudEdit, editableCloud } = useCloudsContext();
  const { t } = useTranslationPrefix('cos.cloud.connect.cloudDirector.');
  const { logSuccessEvent, logErrorEvent } = useAnalytics();
  const [initLoading, setInitLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isRegionsLoading, setIsRegionsLoading] = useState(false);
  const [regions, setRegions] = useState<AWSRegion[]>([]);
  const form = useForm<AWSConnectionFormDTO>({
    mode: 'onBlur',
    defaultValues: {
      cloudName: editableCloud?.name,
    },
  });
  const { apiErrors, handleError } = useFormApiError();
  const fetchRegions = async () => {
    try {
      setIsRegionsLoading(true);
      const response = await fetchAWSRegions();
      setRegions(response.data);
    } catch (err) {
      console.error(err);
    } finally {
      setIsRegionsLoading(false);
    }
  };

  const createNewConnect = async ({ region, ...formData }: AWSConnectionFormDTO) => {
    const dto = { ...formData, region: region.value };
    try {
      setIsLoading(true);
      changeStatusInfoSettings({
        variant: StatusInfoVariant.LOADING,
        cloudName: dto.cloudName,
      });
      await createdAWSConnection(dto);
      logSuccessEvent('Created cloud connection', { cloudType: CLOUD_NAME_DICTIONARY.AWS });
      changeStatusInfoSettings({
        variant: StatusInfoVariant.SUCCESS,
        cloudName: dto.cloudName,
      });
    } catch (error) {
      changeStatusInfoSettings({
        variant: StatusInfoVariant.ERROR,
        cloudName: dto.cloudName,
      });
      handleError(error as AxiosError);
      toast.error({
        text: `${t(`FailedConnectCloud`, { cloud: CLOUD_NAME_DICTIONARY.AWS })}`,
        hasClose: true,
      });
      logErrorEvent('Created cloud connection', { cloudType: CLOUD_NAME_DICTIONARY.AWS });
    } finally {
      setIsLoading(false);
    }
  };

  const onEditSubmit = async (dto: AWSConnectionFormDTO) => {
    try {
      if (editableCloud?.id) {
        setIsLoading(true);
        changeStatusInfoSettings({
          variant: StatusInfoVariant.LOADING,
          cloudName: dto.cloudName,
        });
        await updateAWS(editableCloud.id, {
          region: dto.region.value,
          accessKey: dto.accessKey,
          cloudName: dto.cloudName,
          secretKey: dto.secretKey,
        });
        toast.success({
          text: `${t('CloudConnectedSuccessfully', { cloud: CLOUD_NAME_DICTIONARY.AWS })}`,
        });
        logSuccessEvent('Edited cloud connection', {
          id: editableCloud.id,
          cloudType: CLOUD_NAME_DICTIONARY.AWS,
        });
        changeStatusInfoSettings({
          variant: StatusInfoVariant.SUCCESS,
          cloudName: dto.cloudName,
        });
      }
    } catch (error) {
      handleError(error as AxiosError);
      toast.error({
        text: `${t(`FailedEditCloud`)}`,
        hasClose: true,
      });
      logErrorEvent('Edited cloud connection', {
        id: editableCloud?.id,
        cloudType: CLOUD_NAME_DICTIONARY.AWS,
      });
      changeStatusInfoSettings({
        variant: StatusInfoVariant.ERROR,
        cloudName: dto.cloudName,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const values = form.watch();

  useEffect(() => {
    fetchRegions();
  }, []);

  const isDirty = useMemo(() => {
    const { region, cloudName, secretKey, accessKey } = values;
    return !region || !cloudName || !secretKey || !accessKey;
  }, [values]);

  const onSubmit = useCallback(() => {
    if (cloudEdit) {
      onNext();
      form.handleSubmit(onEditSubmit)();
    } else {
      onNext();
      form.handleSubmit(createNewConnect)();
    }
  }, [cloudEdit]);

  const listRegions: SelectOption<string>[] = useMemo(
    () => regions.map(({ name, code }) => mapToOption(code, name)),
    [regions],
  );

  const fetchFormDTO = useCallback(async () => {
    try {
      if (editableCloud?.id) {
        setInitLoading(true);
        const response = await getAWSCloud(editableCloud?.id);
        const region = listRegions.find((reg) => {
          return reg.value === response.data.region;
        });
        form.setValue('accessKey', response.data.accessKey);
        form.setValue('cloudName', response.data.cloudName);
        if (region) {
          form.setValue('region', region);
        }
      }
    } catch (e) {
      toast.error({
        text: `${t(`FailedEditCloud`)}`,
        hasClose: true,
      });
    } finally {
      setInitLoading(false);
    }
  }, [listRegions, editableCloud?.id]);

  useEffect(() => {
    if (listRegions.length && cloudEdit) {
      fetchFormDTO();
    }
  }, [cloudEdit, listRegions]);

  const buttonLoading = () => {
    if (cloudEdit) {
      return isLoading || initLoading;
    } else {
      return isLoading;
    }
  };

  return (
    <Form<AWSConnectionFormDTO>
      formMethods={form}
      onSubmit={onSubmit}
      apiErrors={apiErrors}
      className={cx('form')}
    >
      {currentStep === ConnectionSteps.STATUS ? (
        <StatusInfo
          cloudType={selectedProvider as CloudTypes}
          variant={statusInfoSettings.variant}
          cloudName={statusInfoSettings.cloudName}
        />
      ) : (
        <>
          {initLoading && cloudEdit ? (
            <CloudEditLoading />
          ) : (
            <div className={cx('inputs')}>
              <ConnectionStep
                listRegions={listRegions}
                isRegionsLoading={isRegionsLoading}
              />
            </div>
          )}
          <div className={cx('buttons')}>
            <Button
              text={t('clouds.connection.button.back')}
              variant="outline"
              onClick={onBack}
            />
            <Button
              text={
                cloudEdit ? t('cos.cloudConnect.edit.save') : t('clouds.connection.button.connect')
              }
              type="submit"
              isLoading={buttonLoading()}
              disabled={!cloudEdit && isDirty}
            />
          </div>
        </>
      )}
    </Form>
  );
};

export { AWSConnection };
