import React from 'react';

import cn from 'classnames/bind';
import { Link, LinkProps } from '@kit/components/Link';

import styles from './Button.module.scss';
import { ButtonView, ButtonViewProps } from './Button.view';

type Props = Omit<LinkProps, 'children'> & ButtonViewProps;

const ButtonLink: React.FC<Props> = ({
  disabled,
  to,
  className,
  full,
  onClick,
  children,
  iconPosition,
  isLoading,
  active,
  loadProgress,
  ...restProps
}) => {
  const cx = cn.bind(styles);
  return (
    <>
      <Link
        data-testid="link"
        className={cx(className, 'container', { disabled, full })}
        disabled={disabled}
        to={to}
        onClick={onClick}
        {...restProps}
      >
        <ButtonView
          active={active}
          loadProgress={loadProgress}
          iconPosition={iconPosition}
          isLoading={isLoading}
          {...restProps}
        >
          {children}
        </ButtonView>
      </Link>
    </>
  );
};

export { ButtonLink };
export type { Props as ButtonLinkProps };
