import { FC } from 'react';

import { BlockTitle } from '@common/components/AppToolbar/components/CloudConnect/BlockTitle';
import { SECRET_FIELD_EDIT_PLACEHOLDER } from '@common/components/Clouds/components';
import { useCloudsContext } from '@common/providers/CloudsProvider';

import { bindStyles } from '@src/utils';
import { Disclaimer } from '@src/kit';
import { FormInput, required } from '@src/common';

import styles from '../YandexConnection.module.scss';

const cx = bindStyles(styles);

type Props = {
  disable: boolean;
  tKey: (postfix: string) => string;
};

const ConnectionStep: FC<Props> = ({ tKey, disable }) => {
  const { cloudEdit } = useCloudsContext();

  return (
    <div>
      <div className={cx('info')}>
        <Disclaimer text={tKey('warning')} />
      </div>
      <div className={cx('connectionBlock')}>
        <FormInput
          className={cx('fade-in-1')}
          name="cloudName"
          disabled={disable}
          rules={{ ...required('FieldRequired') }}
          caption={tKey('cloudName.label')}
          placeholder={tKey('cloudName.placeholder')}
          hint={tKey('cloudName.hint')}
        />
      </div>
      <div className={cx('connectionBlock')}>
        <div className={cx('blockContent')}>
          <FormInput
            className={cx('fade-in-2')}
            name="bucketName"
            disabled={disable}
            rules={required('FieldRequired')}
            caption={tKey('bucketName.label')}
            placeholder={tKey('bucketName.placeholder')}
            hint={tKey('bucketName.hint')}
          />
          <FormInput
            className={cx('fade-in-5')}
            name="bucketCatalogName"
            disabled={disable}
            rules={required('FieldRequired')}
            caption={tKey('bucketCatalogName.label')}
            placeholder={tKey('bucketCatalogName.placeholder')}
            hint={tKey('bucketCatalogName.hint')}
          />
        </div>
      </div>
      <div className={cx('connectionBlock')}>
        <div className={cx('blockContent')}>
          <FormInput
            className={cx('fade-in-3')}
            name="accessKey"
            disabled={disable}
            rules={required('FieldRequired')}
            caption={tKey('accessKey.label')}
            placeholder={tKey('accessKey.placeholder')}
            hint={tKey('accessKey.hint')}
          />
          <FormInput
            className={cx('fade-in-3')}
            name="secretKey"
            disabled={disable}
            caption={tKey('secretKey.label')}
            placeholder={cloudEdit ? SECRET_FIELD_EDIT_PLACEHOLDER : tKey('secretKey.placeholder')}
            hint={tKey('secretKey.hint')}
          />
        </div>
      </div>
      <div className={cx('connectionBlock')}>
        <BlockTitle
          titleKey="cos.yandexConnectCloud.form.oauthToken.caption"
          hintKey="cos.yandexConnectCloud.form.oauthToken.caption.info"
        />
        <FormInput
          className={cx('form__input', 'fade-in-3')}
          name="oauthToken"
          disabled={disable}
          placeholder={cloudEdit ? SECRET_FIELD_EDIT_PLACEHOLDER : 'Yandex OAuth-token'}
        />
      </div>
    </div>
  );
};

export { ConnectionStep };
