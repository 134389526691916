import { useEffect, useRef } from 'react';

type StyleVariable = {
  name: string;
  value: string;
};

const useStyleVariables = (variables: StyleVariable[]) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (ref.current) {
      variables.forEach((item) => {
        ref.current?.style.setProperty(item.name, item.value);
      });
    }
  }, [variables]);

  return ref;
};

export { useStyleVariables };
