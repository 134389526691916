import React, { useEffect, useMemo, useState } from 'react';

import {
  ChangeStageData,
  ConnectedCloudsDataDTO,
  Stages,
} from '@common/components/AppToolbar/components/CloudConnect/types';
import { useManageClouds } from '@common/components/AppToolbar/components/CloudConnect/ConnectedClouds/hooks/useManageClouds';

import { CloudTypes } from '@src/@types';
import { CMModalButtonSettings } from '@src/kit';

import { CloudConnectContext } from './CloudConnectContext';

type Props = {
  children: React.ReactNode;
};

const CloudConnectProvider: React.FC<Props> = ({ children }) => {
  const { clouds } = useManageClouds();

  const defaultStage = useMemo(() => {
    if (clouds?.length === 0) {
      return Stages.providerChoice;
    }
    return Stages.connectedClouds;
  }, [clouds]);

  const [open, setOpen] = useState<boolean>(false);
  const [stage, setStage] = useState<Stages>(defaultStage);
  const [title, setTitle] = useState<string>();
  const [editableCloud, setEditableCloud] = useState<ConnectedCloudsDataDTO | null>(null);
  const [provider, setProvider] = useState<CloudTypes | null>(null);
  const [agreeButtonSettings, setAgreeButtonSettings] = useState<CMModalButtonSettings>({});
  const [disagreeButtonSettings, setDisagreeButtonSettings] = useState<CMModalButtonSettings>({});

  useEffect(() => {
    setStage(defaultStage);
  }, [defaultStage]);

  const onOpen = () => setOpen(true);
  const onClose = () => {
    setOpen(false);
    setEditableCloud(null);
    setProvider(null);
    setStage(defaultStage);
  };

  const selectStage = (value: ChangeStageData) => {
    setStage(value.stage);
    setTitle(value.title);
  };

  const onChangeTitle = (value: string) => {
    setTitle(value);
  };

  const changeAgreeButtonSettings = (settings: CMModalButtonSettings) => {
    setAgreeButtonSettings(settings);
  };

  const changeDisagreeButtonSettings = (settings: CMModalButtonSettings) => {
    setDisagreeButtonSettings(settings);
  };

  return (
    <CloudConnectContext.Provider
      value={{
        open,
        stage,
        editableCloud,
        selectedProvider: provider,
        onOpen,
        onClose,
        selectStage,
        onSelectProvider: setProvider,
        setEditableCloud,
        title,
        changeDisagreeButtonSettings,
        changeAgreeButtonSettings,
        onChangeTitle,
        agreeButtonSettings,
        disagreeButtonSettings,
        clouds,
      }}
    >
      {children}
    </CloudConnectContext.Provider>
  );
};

export { CloudConnectProvider };
