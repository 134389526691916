import { ConnectedCloudStatus } from '@common/components/AppToolbar/components/CloudConnect/types';

import {
  CurrenciesListItem,
  CurrenciesSource,
  CurrencyDTO,
  CurrentRateDTO,
  DeleteCustomRateDTO,
  IRestService,
  NotificationFilterLoadingDTO,
  NotificationFiltersDTO,
  NotificationLoadingDTO,
  NotificationTechData,
  RestService,
  UserAvatarDTO,
  UserDto,
  UserUpdateDTO,
  Permissions,
  ActiveDirectoryDTO,
  NotificationId,
} from '@src/common';
import { COUNT, FILTER, RANGE, SORT } from '@src/constants';
import { serializeObject } from '@src/utils';
import { UUID } from '@src/@types';

import { NotificationDTO } from '../types';

const restService: IRestService = RestService.getInstance();

const fetchLogout = () => {
  const url = `/auth/password/logout`;
  return restService.GET(url);
};

const fetchUserInfo = () => {
  const url = '/idm/api/user/self';
  return restService.GET<UserDto>(url).then((res) => res.data);
};

const fetchUserAvatar = () => {
  const url = `/idm/api/user/image`;
  return restService.GET<{ data: string }>(url).then((res) => res.data?.data);
};

const fetchUserPermissions = () => {
  const url = '/idm/api/user/self/permittedAccesses';
  return restService.GET<Permissions>(url).then((res) => res.data);
};

const fetchCurrencyDirectory = async () => {
  const url = `/reports/api/v2/currency/exchange_rate`;
  return restService.GET<CurrenciesSource>(url);
};

const fetchCustomerCurrency = async () => {
  const url = `/reports/api/v2/currency/customer`;
  return restService.GET<string>(url);
};

const fetchCurrentRates = async () => {
  const url = `/reports/api/v2/currency/settings`;
  return restService.GET<CurrentRateDTO[]>(url);
};

const fetchDeleteCustomRate = async (data: DeleteCustomRateDTO) => {
  const url = `/reports/api/v2/currency/custom_rate/delete`;
  return restService.POST(url, { data });
};

const fetchSetCurrentRates = async (data: CurrentRateDTO[]) => {
  const url = `/reports/api/v2/currency/settings`;
  await restService.POST<CurrentRateDTO[], void>(url, { data });
  return await fetchCurrentRates();
};

const fetchCurrentCurrency = async () => {
  const url = `/reports/api/v2/currency`;
  return restService.GET<string>(url);
};

const fetchCurrencies = async () => {
  const url = `/idm/api/currency/currency_list`;
  return restService.GET<CurrenciesListItem[]>(url);
};

const fetchSetCurrency = async (data: CurrencyDTO) => {
  const url = `/reports/api/v2/currency`;
  return restService.POST<CurrencyDTO, void>(url, { data });
};

const updateUserAvatar = (data: UserAvatarDTO) => {
  const url = `/idm/api/user/image`;
  return restService.POST<UserAvatarDTO, void>(url, { data });
};

const deleteUserAvatar = () => {
  const url = `/idm/api/user/image`;
  return restService.DELETE(url);
};

const updateProfile = (payload: UserUpdateDTO) => {
  const url = '/idm/api/user/self/update';
  return restService.POST(url, { data: payload });
};

const fetchUnreadNotifications = () => {
  const url = '/notifier/notification/unread';
  return restService.GET<number>(url);
};

const fetchTechNotification = () => {
  const url = '/notifier/notification/tech';
  return restService.GET<NotificationTechData>(url);
};

const fetchLoadingNotifications = () => {
  const url = '/reports/api/v2/task/running/count';
  return restService.GET<number>(url);
};

const fetchNotificationsList = (payload: NotificationFiltersDTO) => {
  const url = '/notifier/notification/log';
  const headers = {
    [FILTER]: {
      isViewed: Boolean(payload.isViewed),
    },
    [RANGE]: payload.page ?? 0,
    [COUNT]: payload.pageSize ?? 10,
  };
  return restService.GET<NotificationDTO[]>(url, {
    headers: serializeObject(headers),
  });
};

const fetchLoadingNotificationsList = (payload: NotificationFilterLoadingDTO) => {
  const url = '/reports/api/v2/task/running';
  const headers = {
    [RANGE]: payload.page ?? 0,
    [COUNT]: payload.pageSize ?? 10,
    [SORT]: {
      column: 'startDateTime',
      order: 'desc',
    },
  };
  return restService.GET<NotificationLoadingDTO[]>(url, {
    headers: serializeObject(headers),
  });
};

const markAllAsRead = () => {
  const url = '/notifier/notification/mark_read';
  return restService.GET<NotificationDTO>(url);
};

const markNotificationAsRead = (ids: NotificationId[]) => {
  const url = '/notifier/notification';
  return restService.POST<NotificationId[], void>(url, { data: ids });
};

const logoutUser = () => {
  const url = `/idm/auth/password/logout`;
  return restService.GET(url);
};

const deleteCloud = (cloudId: UUID) => {
  const url = `/settings/api/v2/cloud/${cloudId}`;
  return restService.DELETE(url, {
    timeout: 60000,
  });
};

const changeCloudStatus = (cloudId: UUID, status: ConnectedCloudStatus) => {
  const url = `/settings/api/v2/cloud/${cloudId}/${status === 'ACTIVE' ? 'active' : 'stopped'}`;
  return restService.POST(url);
};

const addActiveDirectorySettings = (payload: ActiveDirectoryDTO) => {
  const url = `/idm/api/active_directory/save_ad`;
  return restService.POST<ActiveDirectoryDTO, void>(url, { data: payload });
};

const changeActiveDirectorySettings = (payload: ActiveDirectoryDTO) => {
  const url = `/idm/api/active_directory/update_ad`;
  return restService.POST<ActiveDirectoryDTO, void>(url, { data: payload });
};

const fetchActiveDirectorySettings = () => {
  const url = `/idm/api/active_directory`;
  return restService.GET<ActiveDirectoryDTO>(url);
};

export {
  fetchUserInfo,
  fetchUserAvatar,
  fetchUserPermissions,
  fetchCurrencyDirectory,
  fetchCustomerCurrency,
  fetchCurrentRates,
  fetchDeleteCustomRate,
  fetchSetCurrentRates,
  fetchCurrentCurrency,
  fetchCurrencies,
  fetchSetCurrency,
  updateUserAvatar,
  updateProfile,
  fetchUnreadNotifications,
  fetchLoadingNotifications,
  fetchNotificationsList,
  markAllAsRead,
  fetchLoadingNotificationsList,
  logoutUser,
  markNotificationAsRead,
  deleteCloud,
  changeCloudStatus,
  fetchTechNotification,
  fetchLogout,
  addActiveDirectorySettings,
  fetchActiveDirectorySettings,
  changeActiveDirectorySettings,
  deleteUserAvatar,
};
