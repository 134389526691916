import { SelectOption } from '@src/kit';
import { NotificationOptionType } from '@src/common';

const OPTIONS: SelectOption<NotificationOptionType>[] = [
  {
    value: 'ALL',
    label: 'AllNotifications',
    meta: 'AllNotifications',
  },
  {
    value: 'READ',
    label: 'Read',
    meta: 'Read',
  },
  {
    value: 'NEW',
    label: 'New',
    meta: 'New',
  },
];

const COLORS_NOTIFICATIONS_CARD: Record<NotificationOptionType, string> = {
  NEW: 'white',
  ALL: 'white',
  IN_PROGRESS: 'purple',
  ERROR: 'red',
  SUCCESS: 'green',
  WARNING: 'yellow',
  READ: 'white',
};

export { OPTIONS, COLORS_NOTIFICATIONS_CARD };
