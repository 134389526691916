import { FC, useEffect } from 'react';
import { FieldArrayWithId, Path, useFormContext } from 'react-hook-form';

import { FormDTO } from '@common/components/SettingsProject/contents/TariffAndPrices';

import { bindStyles } from '@src/utils';
import { TextShorter } from '@src/kit';
import { FormInput, useAppTranslation } from '@src/common';

import styles from './TableItem.module.scss';

type Props = {
  i: number;
  resource: FieldArrayWithId<FormDTO, 'resources', 'id'>;
  isEdit?: boolean;
};

type ProfileItemName = Path<FormDTO>;

const cx = bindStyles(styles);

const TableItem: FC<Props> = ({ resource, i, isEdit }) => {
  const { t } = useAppTranslation();

  const articleKey = `resources.${resource.index}.article` as ProfileItemName;
  const articleNameKey = `resources.${resource.index}.articleName` as ProfileItemName;
  const priceKey = `resources.${resource.index}.price` as ProfileItemName;
  const commentKey = `resources.${resource.index}.comment` as ProfileItemName;

  const { setValue } = useFormContext();

  useEffect(() => {
    if (isEdit && resource.price === 0) {
      return setValue(`resources.${resource.index}.price`, '0');
    }
  }, [isEdit, resource]);

  if (isEdit) {
    return (
      <div className={cx('wrapper', { odd: i % 2 !== 0 })}>
        <div className={cx('info')}>
          <FormInput
            size="sm"
            name={articleKey}
            placeholder={t('cos.settings.tariffprices.input.article')}
          />
        </div>
        <div className={cx('info')}>
          <FormInput
            size="sm"
            name={articleNameKey}
            placeholder={t('cos.settings.tariffprices.input.articleName')}
          />
        </div>
        <div className={cx('info')}>
          <TextShorter tooltip>{resource.resourceType}</TextShorter>
        </div>
        <div className={cx('info')}>
          <FormInput
            type="number"
            size="sm"
            name={priceKey}
            decimals={2}
            placeholder={t('cos.settings.tariffprices.input.placeholder.price')}
          />
        </div>
        <div className={cx('info')}>
          <FormInput
            size="sm"
            name={commentKey}
            placeholder="-"
          />
        </div>
      </div>
    );
  }

  return (
    <div className={cx('wrapper', { odd: i % 2 !== 0 })}>
      <div className={cx('info', { placeholder: !resource.article })}>
        {resource.article || t('cos.settings.tariffprices.input.article')}
      </div>
      <div className={cx('info', { placeholder: !resource.articleName })}>
        {resource.articleName || t('cos.settings.tariffprices.input.articleName')}
      </div>
      <div className={cx('info')}>
        <TextShorter tooltip>{resource.resourceType}</TextShorter>
      </div>
      <div className={cx('info', 'price', { placeholder: resource.price === null })}>
        {resource.price === null ? t('cos.settings.tariffprices.input.price') : resource.price}
      </div>
      <div className={cx('info')}>{resource.comment}</div>
    </div>
  );
};

export { TableItem };
