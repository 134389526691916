import React from 'react';
import { components, MenuProps } from 'react-select';

import cn from 'classnames/bind';
import { Button } from '@kit/components/Button';

import styles from './Select.module.scss';

type Props = MenuProps & {
  selectAllText: string;
  clearText: string;
  submitText?: string;
  onSubmit?: () => void;
};

const cx = cn.bind(styles);

const MenuWithControls: React.FC<Props> = ({
  children,
  setValue,
  options,
  selectAllText,
  clearText,
  getValue,
  submitText,
  onSubmit,
  ...props
}) => {
  const onSelectAll = () => setValue(options, 'select-option');
  const onClear = () => setValue(undefined, 'select-option');

  const isAllSelected = getValue().length === options.length;

  return (
    <components.Menu
      setValue={setValue}
      options={options}
      getValue={getValue}
      className={cx('menuControlsContainer', onSubmit && 'withSubmit')}
      {...props}
    >
      {children}
      <div className={cx('menuControls', onSubmit && 'withSubmit')}>
        <span
          className={cx('controlItem', { selected: isAllSelected })}
          onClick={onSelectAll}
        >
          {selectAllText}
        </span>
        <span
          className={cx('controlItem')}
          onClick={onClear}
        >
          {clearText}
        </span>
      </div>
      {onSubmit && (
        <div className={cx('submitContainer')}>
          <Button
            size="md"
            text={submitText}
            onClick={onSubmit}
          />
        </div>
      )}
    </components.Menu>
  );
};

export { MenuWithControls };
