import React, { Children } from 'react';
import { components, MenuListProps } from 'react-select';

import { useLocalSearchInSelect } from '@common/hooks/useLocalSearchInSelect';

type Props = MenuListProps;

const NoInputSearchMenuList: React.FC<Props> = ({ children, ...props }) => {
  const { searchedItems } = useLocalSearchInSelect({
    items: Children.toArray(children),
  });

  return <components.MenuList {...props}>{searchedItems}</components.MenuList>;
};

export { NoInputSearchMenuList };
