import React, { useMemo } from 'react';

import { CmSelectComponents, CmSelectValue } from '@kit/components/CmSelect';
import { CheckboxOptionContainer } from '@kit/components/CmSelect/CheckboxOptionContainer';

import { FormCmSelect, FormCmSelectProps } from '@src/common';
import { Avatar, TextShorter } from '@src/kit';
import { bindStyles } from '@src/utils';

import styles from './SelectTemplates.module.scss';

const cx = bindStyles(styles);

const multiOption: CmSelectComponents['optionContainer'] = (option, selected) => (
  <CheckboxOptionContainer
    selected={selected}
    label={
      <div className={cx('row')}>
        <Avatar
          fullName={option.label.toString()}
          userId={option.value as string}
        />
        <TextShorter>
          <span>{option.label}</span>
        </TextShorter>
      </div>
    }
    order="label-checkbox"
  />
);
const singleOption: CmSelectComponents['optionContainer'] = (option) => (
  <div className={cx('row')}>
    <Avatar
      fullName={option.label.toString()}
      userId={option.value as string}
    />
    <TextShorter>
      <span>{option.label}</span>
    </TextShorter>
  </div>
);

const selectedValue: CmSelectComponents['selectedValue'] = (value: CmSelectValue) => {
  if (!value || (Array.isArray(value) && value.length === 0)) {
    return null;
  }

  if (Array.isArray(value)) {
    const [first, ...other] = value;
    return (
      <div className={cx('row')}>
        <div className={cx('row-inner')}>
          <Avatar
            key={first.value as string}
            fullName={first.label.toString()}
            userId={first.value as string}
          />
          <TextShorter tooltip>
            <span>{first.label}</span>
          </TextShorter>
        </div>
        {other.length > 0 && <span className={cx('counter')}>+{other.length}</span>}
      </div>
    );
  }

  return (
    <div className={cx('row')}>
      <Avatar
        fullName={value.label.toString()}
        userId={value.value as string}
      />
      <TextShorter>
        <span>{value.label}</span>
      </TextShorter>
    </div>
  );
};

const UserCMSelect: React.FC<FormCmSelectProps> = (props) => {
  const components: Partial<CmSelectComponents> = useMemo(
    () => ({
      optionContainer: props.multi ? multiOption : singleOption,
      selectedValue: selectedValue,
    }),
    [props.multi],
  );

  return (
    <FormCmSelect
      {...props}
      components={components}
    />
  );
};

export { UserCMSelect };
