import React from 'react';
import { PopupPosition } from 'reactjs-popup/dist/types';

import { Button, Icon, IconTypes, Popup } from '@kit/components';
import { DownloadReport, DownloadReportProps } from '@kit/components/DownloadReport/index';

import { ButtonSize, FileFormats } from '@src/@types';
import { usePopupControls } from '@src/common/hooks';
import { bindStyles } from '@src/utils';

import styles from './DownloadReport.module.scss';

const cx = bindStyles(styles);

type DownloadReportPopupProps = DownloadReportProps & {
  buttonName: string;
  buttonSize?: ButtonSize;
  popupTitle?: string;
  buttonIconType?: IconTypes;
  popupPosition?: PopupPosition;
};

const DownloadReportPopup: React.FC<DownloadReportPopupProps> = ({
  loading,
  buttonName,
  buttonSize,
  popupTitle,
  buttonIconType = 'load-pdf',
  popupPosition = 'bottom right',
  onDownloadXls,
  onDownloadPdf,
  onShareFile,
  onSendMail,
  ...props
}) => {
  const { openPopup, closePopup, isOpened } = usePopupControls();

  const closeBeforeAction =
    (action: ((...params: any) => any) | undefined) =>
    (...params: any) => {
      closePopup();
      action?.(...params);
    };

  const shareFile = (format: FileFormats) => {
    if (!onShareFile) {
      return;
    }

    closePopup();
    return onShareFile(format);
  };

  return (
    <div className={cx('popup')}>
      <Popup
        trigger={
          <Button
            isLoading={loading}
            variant="text"
            text={buttonName}
            size={buttonSize || 'lg'}
          >
            <Icon type={buttonIconType} />
          </Button>
        }
        className={cx('popup-window')}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        open={isOpened}
        onOpen={openPopup}
        position={popupPosition}
      >
        <div className={cx('popup-wrap')}>
          <div className={cx('popup-header')}>
            <span className={cx('popup-header__title')}>{popupTitle || buttonName}</span>
            <Icon
              className={cx('popup-header__icon')}
              type="close"
              onClick={closePopup}
            />
          </div>
          <div className={cx('popup-content')}>
            <DownloadReport
              {...props}
              loading={loading}
              contentOrder="column"
              onDownloadXls={closeBeforeAction(onDownloadXls)}
              onDownloadPdf={closeBeforeAction(onDownloadPdf)}
              onSendMail={closeBeforeAction(onSendMail)}
              onShareFile={shareFile}
            />
          </div>
        </div>
      </Popup>
    </div>
  );
};

export { DownloadReportPopup };
export type { DownloadReportPopupProps };
