import { FC, useMemo } from 'react';

import { useCloudsContext } from '@common/providers/CloudsProvider';
import { useNewConnectionContext } from '@common/components/Clouds/components/NewConnection/NewConnectionContext';

import { CloudTypes } from '@src/@types';
import { bindStyles, getDocsLink } from '@src/utils';
import { Button, Icon, Loader } from '@src/kit';
import { useAppToolbarContext, useAppTranslation } from '@src/common';
import { CLOUD_ICON_DICTIONARY } from '@src/constants';

import styles from './CloudInfo.module.scss';
import AwsAstro from './assets/AwsAstro.svg';
import AzureAstro from './assets/AzureAstro.svg';
import HyperVAstro from './assets/HyperVAstro.svg';
import OpenStackAstro from './assets/OpenStackAstro.svg';
import VCloudAstro from './assets/VCloudAstro.svg';
import VSphereAstro from './assets/VSphereAstro.svg';
import YandexAstro from './assets/YandexAstro.svg';

enum CloudInfoVariant {
  LOADING = 'loading',
  SUCCESS = 'success',
  HIDDEN = 'hidden',
  INFO = 'info',
}

type Props = {
  variant: CloudInfoVariant;
  cloudType: CloudTypes;
  cloudName?: string;
  docLink?: string;
};

const cx = bindStyles(styles);

const CloudInfo: FC<Props> = ({ variant, cloudType, cloudName }) => {
  const { t } = useAppTranslation();

  const { closeConnection, onNavigate, cloudEdit, setCloudEdit, changeEditableCloud } =
    useCloudsContext();
  const { setSelectedProvider } = useNewConnectionContext();
  const { brand } = useAppToolbarContext();

  const onButtonClick = () => {
    switch (cloudType) {
      case 'vCloudDirector':
        window.open(getDocsLink(brand, '/link-cloud-to-module/'), '_blank');
        break;
      case 'AWS':
        window.open(getDocsLink(brand, '/connect-aws/'), '_blank');
        break;
      case 'vSphere':
        window.open(getDocsLink(brand, '/connect-vsphere/'), '_blank');
        break;
      case 'Yandex':
        window.open(getDocsLink(brand, '/link-yandex-cloud/'), '_blank');
        break;
      case 'Azure':
        window.open(getDocsLink(brand, '/connect-azure/'), '_blank');
        break;
      case 'OpenStack':
        window.open(getDocsLink(brand, '/connect-openstack/'), '_blank');
        break;
      case 'HyperV':
        window.open(getDocsLink(brand, '/link-hyper-v/'), '_blank');
        break;
    }
  };

  const astroImage = useMemo(() => {
    switch (cloudType) {
      case 'vCloudDirector':
        return <VCloudAstro />;
      case 'AWS':
        return <AwsAstro />;
      case 'vSphere':
        return <VSphereAstro />;
      case 'Yandex':
        return <YandexAstro />;
      case 'Azure':
        return <AzureAstro />;
      case 'OpenStack':
        return <OpenStackAstro />;
      case 'HyperV':
        return <HyperVAstro />;
    }
  }, [cloudType]);

  const backToClouds = () => {
    closeConnection();
    setSelectedProvider(null);
    if (cloudEdit) {
      setCloudEdit(false);
      changeEditableCloud(null);
    }
  };

  const onTarrifClick = () => {
    onNavigate?.('tariffs');
  };
  const renderedContent = useMemo(() => {
    switch (variant) {
      case CloudInfoVariant.INFO:
        return (
          <div className={cx('wrapper', 'info')}>
            <div className={cx('ellipse115')} />
            <div className={cx('ellipse116')} />
            <div className={cx('ellipse117')} />
            <div className={cx('ellipse120')} />
            <div className={cx('content')}>
              <div className={cx('image')}>{astroImage}</div>
              <div className={cx('infoContent')}>
                <div className={cx('textContent')}>
                  <div className={cx('title')}>
                    {t('clouds.connection.info.cloud.title', {
                      cloud: t(`clouds.connection.info.cloud.${cloudType}`),
                    })}
                  </div>
                  <div className={cx('text')}>{t('clouds.connection.info.cloud.text')}</div>
                </div>
                <Button
                  className={cx('button')}
                  text={t('clouds.connection.info.cloud.button')}
                  onClick={onButtonClick}
                  size="sm"
                  variant="outline"
                />
              </div>
            </div>
          </div>
        );
      case CloudInfoVariant.HIDDEN:
        return (
          <div>
            <div className={cx('loader', { loader_success: true })}>
              <Icon type="circle-check" />
            </div>
          </div>
        );
      case CloudInfoVariant.LOADING:
        return (
          <div className={cx('wrapper', 'success')}>
            <div className={cx('main')}>
              <div className={cx('main-icon', 'loading')}>
                <Loader className={cx('loading')} />
              </div>
              <div className={cx('main-header')}>
                {t('clouds.connection.status.connectionLoading')}
              </div>
              <div className={cx('main-cloud')}>
                <div className={cx('main-cloud-icon')}>{CLOUD_ICON_DICTIONARY[cloudType]}</div>
                <div className={cx('main-cloud-text')}>{cloudName}</div>
              </div>
              <div className={cx('main-text')}>
                {t('clouds.connection.cloudInfo.connection.content.1')}
              </div>
              <div className={cx('main-text')}>
                {t('clouds.connection.cloudInfo.connection.content.2')}
              </div>
              <div className={cx('main-text')}>
                {t('clouds.connection.cloudInfo.connection.content.3')}
              </div>
            </div>
            <div className={cx('footer')}>
              <Button
                text={t('clouds.connection.cloudInfo.connection.connList')}
                variant="outline"
                onClick={backToClouds}
                className={cx('buttonWrapper')}
              />
              <Button
                disabled
                text={t('clouds.connection.cloudInfo.connection.tarriff')}
                onClick={onTarrifClick}
                className={cx('buttonWrapper')}
              />
            </div>
          </div>
        );
      case CloudInfoVariant.SUCCESS:
        return (
          <div className={cx('wrapper', 'success')}>
            <div className={cx('main')}>
              <div className={cx('main-icon', 'success')}>
                <Icon
                  type="circle-check"
                  className={cx('success')}
                />
              </div>
              <div className={cx('main-header')}>
                {t('clouds.connection.status.connectionSuccess')}
              </div>
              <div className={cx('main-cloud')}>
                <div className={cx('main-cloud-icon')}>{CLOUD_ICON_DICTIONARY[cloudType]}</div>
                <div className={cx('main-cloud-text')}>{cloudName}</div>
              </div>
              <div className={cx('main-text')}>
                {t('clouds.connection.cloudInfo.connection.content.1')}
              </div>
              <div className={cx('main-text')}>
                {t('clouds.connection.cloudInfo.connection.content.2')}
              </div>
              <div className={cx('main-text')}>
                {t('clouds.connection.cloudInfo.connection.content.3')}
              </div>
            </div>
            <div className={cx('footer')}>
              <Button
                text={t('clouds.connection.cloudInfo.connection.connList')}
                variant="outline"
                onClick={backToClouds}
                className={cx('buttonWrapper')}
              />
              <Button
                text={t('clouds.connection.cloudInfo.connection.tarriff')}
                onClick={onTarrifClick}
                className={cx('buttonWrapper')}
              />
            </div>
          </div>
        );
    }
  }, [variant, cloudName, cloudType, t]);

  return <>{renderedContent}</>;
};

export { CloudInfo, CloudInfoVariant };
