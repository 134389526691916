import { Context, createContext, useContext } from 'react';

import { UseCMTableReturn } from '@common/components/CMTable/hooks/useCMTable';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ContextType = UseCMTableReturn<any>;

const CMTableContext: Context<ContextType> = createContext<ContextType>({} as ContextType);

const useCMTableContext = () => useContext(CMTableContext);

export { CMTableContext, useCMTableContext };
