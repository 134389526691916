import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { NavigateOptions } from 'react-router-dom';

import { fetchConnectedClouds } from '@common/components/AppToolbar/api/cloud-connections';

import { Icon, IconTypes } from '@src/kit';
import {
  PermissionActions,
  PermissionSubjects,
  StorageService,
  useAccessControl,
  useResize,
} from '@src/common';
import { useAppTranslation } from '@src/common';
import { BODY_SIDE_BAR_OPEN_CLASS, COMMON_LINKS, SIDEBAR_MINIFIED_KEY } from '@src/constants';
import { bindStyles } from '@src/utils';

import styles from './Sidebar.module.scss';
import { SidebarItem } from './SidebarItem';

export type MenuItemType = {
  link: string;
  iconType?: IconTypes;
  caption: string;
  access?: PermissionSubjects;
  accessAction?: PermissionActions;
  subItems?: MenuItemType[];
  disabled?: boolean;
  id?: string;
};

const cx = bindStyles(styles);
const bodyRef = document.body;
const storage = StorageService.getInstance();
const isMinifiedDefault = () => {
  const sidebarValue: boolean | null = storage.getItem(SIDEBAR_MINIFIED_KEY);
  if (sidebarValue === null) {
    return false;
  }
  return sidebarValue;
};

type Props = {
  menuItems: MenuItemType[];
  onNavigate: (to: string, options: NavigateOptions) => void;
  pathname: string;
  withoutCommonLinks?: boolean;
};

const Sidebar: React.FC<Props> = ({ menuItems, onNavigate, pathname, withoutCommonLinks }) => {
  const width = useResize() <= 1279;
  const [isMinified, setIsMinified] = useState<boolean | null>(isMinifiedDefault);
  const [isShowTariffs, setIsShowTariffs] = useState(false);

  const toggleMinified = useCallback(() => {
    storage.setItem(SIDEBAR_MINIFIED_KEY, !isMinified);
    setIsMinified(!isMinified);
  }, [storage, isMinified, setIsMinified, storage.setItem]);

  const checkAccess = useAccessControl();

  const { t } = useAppTranslation();

  const getClouds = async () => {
    try {
      const response = await fetchConnectedClouds();
      const vdcClouds = response.data.filter(
        (it) => it.cloudType === 'vCloudDirector' && it.status === 'ACTIVE',
      );
      setIsShowTariffs(Boolean(vdcClouds.length));
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (width) {
      setIsMinified(true);
    } else if (isMinifiedDefault() !== isMinified) {
      setIsMinified(false);
    }
  }, [width, isMinifiedDefault]);

  const filterMenuAccess = useCallback(
    (menu: MenuItemType[]) => {
      return menu
        .reduce((result, item) => {
          const subItems = item.subItems && filterMenuAccess(item.subItems);
          if (subItems && subItems.length === 0) return result;
          const newItem: MenuItemType = {
            ...item,
            subItems,
          };
          return [...result, newItem];
        }, [] as MenuItemType[])
        .filter((item) => {
          const access = item.link === '/tariffs' ? isShowTariffs : true;
          if (item.access) {
            return (
              access && checkAccess({ action: item.accessAction || 'list', subject: item.access })()
            );
          }

          return access;
        });
    },
    [checkAccess, isShowTariffs],
  );

  const menu = useMemo(() => {
    return filterMenuAccess(menuItems);
  }, [filterMenuAccess, menuItems]);

  const commonMenu = useMemo(() => {
    if (withoutCommonLinks) {
      return [];
    }
    return filterMenuAccess(COMMON_LINKS);
  }, [filterMenuAccess, COMMON_LINKS, withoutCommonLinks, isShowTariffs]);

  useEffect(() => {
    if (isMinified) {
      bodyRef.classList.remove(BODY_SIDE_BAR_OPEN_CLASS);
    } else {
      bodyRef.classList.add(BODY_SIDE_BAR_OPEN_CLASS);
    }
  }, [isMinified]);

  useEffect(() => {
    void getClouds();
  }, []);

  return (
    <aside className={cx('body', { isMinified })}>
      <nav className={cx('menu')}>
        {(withoutCommonLinks ? [menu] : [menu, commonMenu]).map((item, index) => (
          <div
            key={`section_${index}`}
            className={cx('section')}
          >
            {item.map(({ link, disabled, subItems, iconType, caption, id }) => {
              const isActive = pathname.includes(link);
              return (
                <SidebarItem
                  id={id}
                  key={link}
                  path={link}
                  caption={t(caption)}
                  disabled={disabled}
                  subItems={subItems}
                  isMinified={isMinified}
                  onNavigate={onNavigate}
                  isActive={isActive}
                  pathname={pathname}
                >
                  {iconType && <Icon type={iconType} />}
                </SidebarItem>
              );
            })}
          </div>
        ))}
      </nav>
      <SidebarItem
        disabled={width}
        id="hide-menu-button"
        caption={t('HideMenu')}
        onClick={toggleMinified}
        onNavigate={onNavigate}
      >
        <Icon
          type="chevron-down"
          className={cx('chevron')}
        />
      </SidebarItem>
    </aside>
  );
};

export { Sidebar };
