import React, { useEffect, useState } from 'react';

import {
  fetchLoadingNotifications,
  fetchUnreadNotifications,
} from '@common/components/AppToolbar/api/requests';

import { NotificationsContext } from './NotificationsContext';

const INTERVAL = 15_000;

const NotificationsProvider: React.FC = ({ children }) => {
  const [countCommon, setCountCommon] = useState<number>(0);
  const [countBgTasks, setCountBgTasks] = useState<number>(0);

  const fetch = async () => {
    try {
      const [unread, loading] = await Promise.all([
        fetchUnreadNotifications(),
        fetchLoadingNotifications(),
      ]);

      setCountCommon(unread.data);
      setCountBgTasks(loading.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetch();
    const interval = setInterval(fetch, INTERVAL);
    return () => clearInterval(interval);
  }, []);

  const refreshCountCommonUnread = (all?: boolean, markAsRead?: boolean) => {
    if (all) {
      setCountCommon(0);
    } else {
      if (markAsRead) {
        setCountCommon((prev) => prev - 1);
      } else {
        setCountCommon((prev) => prev + 1);
      }
    }
  };

  return (
    <NotificationsContext.Provider
      value={{
        countBgTasks,
        countCommon,
        refreshCountCommonUnread,
        reFetchCounters: fetch,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export { NotificationsProvider };
