import React from 'react';
import { components, NoticeProps } from 'react-select';

import cn from 'classnames/bind';

import styles from './Select.module.scss';

type Props = NoticeProps & {
  text?: string;
  className?: string;
};

const cx = cn.bind(styles);

const NoOptionsMessage: React.FC<Props> = ({ text, className, ...props }) => (
  <components.NoOptionsMessage
    {...props}
    className={cx('noOptionsText', { className })}
  >
    {text}
  </components.NoOptionsMessage>
);

export { NoOptionsMessage };
