import { FC } from 'react';

import { YandexEdit } from '@common/components/AppToolbar/components/CloudConnect/CloudEdit/YandexEdit';
import { VCloudEdit } from '@common/components/AppToolbar/components/CloudConnect/CloudEdit/VCloudEdit';
import { AzureEdit } from '@common/components/AppToolbar/components/CloudConnect/CloudEdit/AzureEdit';
import { AWSEdit } from '@common/components/AppToolbar/components/CloudConnect/CloudEdit/AWSEdit';
// import { OpenStackEdit } from '@common/components/AppToolbar/components/CloudConnect/CloudEdit/OpenStackEdit';
import { VSphereEdit } from '@common/components/AppToolbar/components/CloudConnect/CloudEdit/VSphereEdit';
import { OpenStackEdit } from '@common/components/AppToolbar/components/CloudConnect/CloudEdit/OpenStackEdit';

import { CloudTypes } from '@src/@types';
import { useCloudConnectContext } from '@src/common';

const CLOUD_FORM_EDIT_DICTIONARY: Partial<Record<CloudTypes, React.ReactElement>> = {
  Yandex: <YandexEdit />,
  vCloudDirector: <VCloudEdit />,
  Azure: <AzureEdit />,
  AWS: <AWSEdit />,
  OpenStack: <OpenStackEdit />,
  vSphere: <VSphereEdit />,
};

const CloudEdit: FC = () => {
  const { editableCloud } = useCloudConnectContext();
  return (
    (editableCloud && CLOUD_FORM_EDIT_DICTIONARY[editableCloud.cloudType as CloudTypes]) || null
  );
};

export { CloudEdit, CLOUD_FORM_EDIT_DICTIONARY };
