import React from 'react';
import { useForm } from 'react-hook-form';

import { AxiosError } from 'axios';
import { fetchUserCreate } from '@common/components/UserRoles/api/requests';
import { UserCreateView } from '@common/components/UserRoles/components/UserCreate/UserCreate.view';

import { Button, SelectOption, SideModal } from '@src/kit';
import { Form, useAnalytics, useAppTranslation, useToast } from '@src/common';
import { bindStyles } from '@src/utils';

import styles from './UserCreate.module.scss';

type Props = {
  onClose: () => void;
  reFetch?: () => void;
};

type FormProps = {
  comment: string;
  firstName: string;
  lastName: string;
  login: string;
  clouderRole: SelectOption<number, any>;
  costerRole: SelectOption<number, any>;
};

const formId = 'userCreate';

const cx = bindStyles(styles);

const UserCreate: React.FC<Props> = ({ onClose, reFetch }) => {
  const { t } = useAppTranslation();
  const toast = useToast();
  const { logSuccessEvent, logErrorEvent } = useAnalytics();

  const form = useForm<FormProps>();

  const onSubmit = async ({
    firstName,
    lastName,
    login,
    clouderRole,
    costerRole,
    comment,
  }: FormProps) => {
    const roleIds: number[] = [clouderRole.value, costerRole.value];

    const payload = {
      firstName: firstName?.trim(),
      lastName: lastName?.trim(),
      login: login?.trim(),
      comment: comment?.trim(),
      roleIds,
    };

    try {
      await fetchUserCreate(payload);
      onClose();
      reFetch?.();
      toast.success({ text: t('UserCreateSuccess', { name: `${lastName} ${firstName}` }) });
      logSuccessEvent('Created User', { creation_method: 'native' });
    } catch (err) {
      if ((err as AxiosError).response?.status === 400) {
        const error = {
          message: t('cld.user.create.email.validation'),
        };
        form.setError('login', error);
      } else {
        toast.error({ text: t('UserCreateFailed', { name: `${lastName} ${firstName}` }) });
      }
      logErrorEvent('Created User', { creation_method: 'native' });
    }
  };

  const footer = (
    <div className={cx('footer')}>
      <Button
        className={cx('leftButton')}
        onClick={onClose}
        variant="outline"
        text={t('Cancel')}
        full
      />
      <Button
        type="submit"
        variant="filled"
        text={t('CreateButton')}
        full
        form={formId}
        onClick={form.handleSubmit(onSubmit)}
      />
    </div>
  );

  return (
    <SideModal
      open
      onClose={onClose}
      size="md"
      titleText={t('User')}
      modalFooter={footer}
      closeOnDocumentClick={false}
    >
      <Form
        id={formId}
        formMethods={form}
        onSubmit={onSubmit}
      >
        <UserCreateView />
      </Form>
    </SideModal>
  );
};

export { UserCreate };
