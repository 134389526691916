import { FC, ReactNode } from 'react';

import { Icon } from '@kit/components';

import { bindStyles } from '@src/utils';

import styles from './ChipTag.module.scss';

enum ChipTagVariant {
  FILLED_BLUE = 'filled_blue',
  FILLED_GRAY = 'filled_gray',
  OUTLINED_BLUE = 'outlined_blue',
}

type Props = {
  text: string | ReactNode;
  isActive?: boolean;
  onClick: () => void;
  withIcon?: boolean;
  className?: string;
  disabled?: boolean;
  variant?: ChipTagVariant;
};

const cx = bindStyles(styles);

const ChipTag: FC<Props> = ({
  text,
  withIcon,
  className,
  isActive,
  onClick,
  disabled,
  variant = ChipTagVariant.OUTLINED_BLUE,
}) => {
  return (
    <button
      className={cx(className, 'wrapper', variant, { isActive, withIcon: withIcon && isActive })}
      disabled={disabled}
      onClick={onClick}
    >
      {isActive && withIcon && (
        <Icon
          type="ggCheck"
          className={cx('icon')}
        />
      )}
      {text}
    </button>
  );
};

export { ChipTag, ChipTagVariant };
