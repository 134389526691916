import React from 'react';

import cn from 'classnames';

import styles from './Counter.module.scss';

type Props = {
  className?: string;
  value: number;
  onClick?: () => void;
};

const Counter: React.FC<Props> = ({ className, children, value, onClick }) => {
  const multiClassesMax = cn(styles.counterValue, styles.counterValue__Max);
  const multiClassesMin = cn(styles.counterValue, styles.counterValue__Min);

  return (
    <div
      onClick={onClick}
      className={cn(className, styles.counter)}
    >
      {children}
      {!!value && <span className={value < 1000 ? multiClassesMin : multiClassesMax}>{value}</span>}
    </div>
  );
};

export { Counter };
