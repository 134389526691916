import React from 'react';

import cn from 'classnames/bind';

import { Size } from '@src/@types';

import styles from './ProgressBar.module.scss';

const cx = cn.bind(styles);

type Props = {
  value: number;
  size?: Extract<Size, 'md' | 'lg'>;
  maxValue?: number;
  threshold?: number;
};

const ProgressBar: React.FC<Props> = ({
  value,
  maxValue = 100,
  threshold = Infinity,
  size = 'lg',
}) => {
  const percent = (value / maxValue) * 100;

  return (
    <div className={cx('progress', size)}>
      <div
        className={cx('progress-value', { warning: percent >= threshold })}
        style={{ width: `${percent}%` }}
      />
    </div>
  );
};

export { ProgressBar };
