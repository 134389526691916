const PERMISSION_SUBJECT_TYPES = [
  'hyperv_hosts',
  'cloud',
  'cloudobject',
  'template',
  'order',
  'subscription',
  'cloudobject_without_subscr',
  'network',
  'image',
  'user',
  'report',
  'network_in_subscription',
  'owner_of_subscription',
  'connections',
  'users_and_roles',
  'ad',
  'settings',
  'prices',
  'cloud_connection',
] as const;
type PermissionSubjects = typeof PERMISSION_SUBJECT_TYPES[number];

const PERMISSION_OPERATION_TYPES = [
  'start_vm',
  'stop_vm',
  'reboot_vm',
  'move_vm',
  'reject_order',
  'approve_order',
  'move_vm_with_auto_approve',
  'connection_cloud',
  'switch_access',
  'update_password',
  'add_owner_for_vm',
  'get_users_of_subscription',
  'create_ad_settings',
  'update_ad_settings',
  'read_ad_settings',
] as const;
type PermissionOperations = typeof PERMISSION_OPERATION_TYPES[number];

const PERMISSION_ACTION_TYPES = [
  'create',
  'modules',
  'read',
  'update',
  'delete',
  'list',
  'operation',
  'operation',
] as const;

type PermissionActions = typeof PERMISSION_ACTION_TYPES[number];

type PermissionId = string;

export { PERMISSION_ACTION_TYPES, PERMISSION_OPERATION_TYPES, PERMISSION_SUBJECT_TYPES };
export type { PermissionActions, PermissionOperations, PermissionSubjects, PermissionId };
