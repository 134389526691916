import { useEffect, useState } from 'react';

import {
  ConnectedCloudsDataDTO,
  ConnectedCloudStatus,
} from '@common/components/AppToolbar/components/CloudConnect/types';
import { fetchConnectedClouds } from '@common/components/AppToolbar/api/cloud-connections';
import { changeCloudStatus } from '@common/components/AppToolbar/api/requests';

import { UUID } from '@src/@types';
import { useAnalytics, useAppTranslation, useToast } from '@src/common';

const useManageClouds = () => {
  const toast = useToast();
  const { t } = useAppTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [clouds, setClouds] = useState<ConnectedCloudsDataDTO[]>();
  const { logSuccessEvent, logErrorEvent } = useAnalytics();

  const fetch = async () => {
    try {
      setLoading(true);
      const response = await fetchConnectedClouds();
      setClouds(response.data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  const onChangeStatus = async (id: UUID, status: ConnectedCloudStatus) => {
    try {
      await changeCloudStatus(id, status);
      setClouds((prev) => {
        const cloud = prev?.find((item) => item.id === id);
        if (cloud) {
          cloud.status = status;
        }
        return clouds && [...clouds];
      });
      toast.success({ text: t('ChangeStatusCloudSuccess', { name }) });
      logSuccessEvent('Changed cloud status', { cloudStatus: status, id });
    } catch (err) {
      toast.error({ text: t('ChangeStatusCloudDelete') });
      logErrorEvent('Changed cloud status', { cloudStatus: status, id });
    }
  };

  return {
    loading,
    clouds,
    onChangeStatus,
    fetch,
  };
};

export { useManageClouds };
