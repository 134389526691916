import React, { PropsWithChildren, useEffect, useState } from 'react';

import { bindStyles } from '@src/utils';
import { Loader } from '@src/kit';

import styles from './CloudConnect.module.scss';

const cx = bindStyles(styles);

type Props = {
  loading?: boolean;
};

export const CloudConnectContent: React.FC<PropsWithChildren<Props>> = ({ children, loading }) => {
  const [overlayVisible, setOverlayVisible] = useState<boolean>(Boolean(loading));

  useEffect(() => {
    if (loading) {
      setOverlayVisible(true);
    }
  }, [loading]);

  const onTransitionEnd = () => {
    setOverlayVisible(false);
  };

  return (
    <div className={cx('content')}>
      {children}
      {overlayVisible && (
        <div
          className={cx('overlay', { hidden: !loading })}
          onTransitionEnd={onTransitionEnd}
        >
          <Loader className={cx('spinner')} />
        </div>
      )}
    </div>
  );
};
