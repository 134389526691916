import { FC, useMemo } from 'react';
import { SelectComponents } from 'react-select/dist/declarations/src/components';
import { GroupBase } from 'react-select';
import { useFormContext } from 'react-hook-form';

import { BlockTitle } from '@common/components/AppToolbar/components/CloudConnect/BlockTitle';
import {
  AzureConnectionDTO,
  AzureRequestSubscriptionDTO,
  AzureSubscription,
} from '@common/components/AppToolbar/components/CloudConnect/types';

import { FormInput, FormSelect, required, useAppTranslation, uuidPattern } from '@src/common';
import { CLIENTID, SECRETKEY, SUBSCRIPTIONS, TENANTID } from '@src/constants';
import { Button, CheckboxOption, MenuWithControls, SelectOption } from '@src/kit';
import { mapToOption, bindStyles } from '@src/utils';

import styles from './FormAzure.module.scss';

type Props = {
  fetchListSubscriptions: (data: AzureRequestSubscriptionDTO) => void;
  list: AzureSubscription[];
  isLoading: boolean;
};

const cx = bindStyles(styles);

const FormCreateApplication: FC<Props> = ({ list, fetchListSubscriptions, isLoading }) => {
  const { t } = useAppTranslation();
  const {
    watch,
    formState: { errors },
  } = useFormContext<AzureConnectionDTO>();

  const listSubscriptions: SelectOption<string>[] = useMemo(
    () => list.map(({ name, uuid }) => mapToOption(uuid, name)),
    [list],
  );

  const [tenantId, clientId, clientSecret] = watch([TENANTID, CLIENTID, SECRETKEY]);

  const isDisabled = !tenantId || !clientId || !clientSecret;
  const buttonVariant = listSubscriptions.length > 0 || isDisabled ? 'outline' : 'filled';

  const subscriptionErrorKeys =
    listSubscriptions.length > 0
      ? ['FieldRequired']
      : ['FieldRequired', 'ErrorDownloadSubscription'];

  const subscriptionComponents: Partial<SelectComponents<unknown, boolean, GroupBase<unknown>>> = {
    Menu: ({ children, ...props }) => (
      <MenuWithControls
        {...props}
        selectAllText={t('SelectAll')}
        clearText={t('Clear')}
      >
        {children}
      </MenuWithControls>
    ),
    Option: ({ children, ...props }) => (
      <CheckboxOption
        {...props}
        hasTooltip
      >
        {children}
      </CheckboxOption>
    ),
  };

  const getListSubscriptions = () => fetchListSubscriptions({ tenantId, clientId, clientSecret });

  return (
    <>
      <div className={cx('connectionBlock')}>
        <BlockTitle
          hintKey="AzureInstruction.creatApplication"
          titleKey="creatApplication"
        />
        <div className={cx('blockContainer')}>
          <FormInput
            name={TENANTID}
            rules={{
              ...required('FieldRequired'),
              ...uuidPattern('InputFormatError'),
            }}
            caption={t('directoryID')}
            placeholder={t('tenantID')}
          />
          <FormInput
            name={CLIENTID}
            rules={{
              ...required('FieldRequired'),
              ...uuidPattern('InputFormatError'),
            }}
            caption={t('applicationID')}
            placeholder={t('clientID')}
          />
        </div>
      </div>
      <div className={cx('connectionBlock')}>
        <BlockTitle
          hintKey="AzureInstruction.createKey"
          titleKey="createKey"
        />
        <FormInput
          name={SECRETKEY}
          rules={{ ...required('FieldRequired') }}
          caption={t('secretKey')}
          placeholder={t('SecretValue')}
        />
      </div>
      <div className={cx('connectionBlock')}>
        <BlockTitle
          hintKey="AzureInstruction.assigningAccess"
          titleKey="assigningAcces"
        />
      </div>
      <div className={cx('connectionBlock')}>
        <BlockTitle
          hintKey="AzureInstruction.subscriptionData"
          titleKey="subscriptionData"
        />
        <div
          className={cx('blockContainerSelect', {
            blockContainerSelectError: Boolean(errors.subscriptions),
          })}
        >
          <FormSelect
            name={SUBSCRIPTIONS}
            isMulti
            isDisabled={isDisabled || !listSubscriptions.length}
            closeMenuOnSelect={false}
            className={cx('formSelect')}
            caption={t('subscription')}
            placeholder={t('SubscriptionID')}
            size="lg"
            rules={{ ...required(...subscriptionErrorKeys) }}
            options={listSubscriptions}
            menuPlacement="top"
            components={subscriptionComponents}
          />
          <Button
            text={t('GetSubscriptions')}
            size="lg"
            variant={buttonVariant}
            className={cx('btn')}
            type="button"
            disabled={isDisabled}
            isLoading={isLoading}
            onClick={getListSubscriptions}
          />
        </div>
      </div>
    </>
  );
};

export { FormCreateApplication };
