import { FC } from 'react';

import { ColorPulsator } from '@src/kit';
import { bindStyles } from '@src/utils';

import styles from './Skeleton.module.scss';

const cx = bindStyles(styles);

const Skeleton: FC = () => {
  return (
    <div className={cx('wrapper')}>
      <div className={cx('sidebar')}>
        <ColorPulsator />
      </div>
      <div className={cx('content')}>
        <ColorPulsator />
      </div>
    </div>
  );
};

export { Skeleton };
