import { createContext, useContext } from 'react';

enum EventStatus {
  SUCCESS = 'Success',
  ERROR = 'Failure',
}

type EventData = {
  [key: string]: any;
  status?: EventStatus;
  message?: string;
  pathname?: string;
};

type AnalyticsContextType = {
  logEvent: (event: string, data?: EventData) => void;
  logSuccessEvent: (event: string, data?: EventData) => void;
  logErrorEvent: (event: string, data?: EventData) => void;
};

const AnalyticsContext = createContext({
  logEvent: () => {},
  logErrorEvent: () => {},
  logSuccessEvent: () => {},
} as AnalyticsContextType);

const useAnalytics = () => useContext(AnalyticsContext);

export { useAnalytics, AnalyticsContext, EventStatus };
export type { AnalyticsContextType, EventData };
