import { RegisterOptions } from 'react-hook-form/dist/types/validator';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { CmSelect, CmSelectProps, CmSelectValue } from '@kit/components/CmSelect';

import { useAppTranslation } from '@src/common';

type Props = {
  name: string;
  rules?: RegisterOptions;
  onChange?: (values: CmSelectValue, event?: React.SyntheticEvent) => void;
} & Omit<CmSelectProps, 'onChange'>;

const FormCmSelect: React.FC<Props> = ({ name, rules, ...other }) => {
  const { t } = useAppTranslation();
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field, fieldState }) => (
        <CmSelect
          value={field.value}
          onChange={(value) => {
            field.onChange(value);
          }}
          {...other}
          error={Boolean(fieldState.error)}
          errorText={t(fieldState.error?.message || '')}
        />
      )}
    />
  );
};

export { FormCmSelect };
export type { Props as FormCmSelectProps };
