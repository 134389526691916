import React from 'react';

import { bindStyles } from '@src/utils';

import { Pagination, PaginationProps } from './Pagination';
import styles from './Table.module.scss';

const cx = bindStyles(styles);

const withPagination = <T,>(WrappedComponent: React.FC<T>) => {
  const Component = (props: T & PaginationProps) => {
    const {
      onChangePage,
      page,
      totalItems,
      pageSizeConfig,
      itemsPerPageText,
      pageFromText,
      pageFromTextElement,
      pageFromTextLast,
    } = props;
    const componentProps = props as unknown as T;

    return (
      <div className={cx('operationContainer')}>
        <WrappedComponent
          {...componentProps}
          pageSize={pageSizeConfig.value}
        />
        {Number(totalItems) > 0 && (
          <Pagination
            page={page}
            totalItems={totalItems}
            pageSizeConfig={pageSizeConfig}
            itemsPerPageText={itemsPerPageText}
            pageFromText={pageFromText}
            pageFromTextElement={pageFromTextElement}
            pageFromTextLast={pageFromTextLast}
            onChangePage={onChangePage}
          />
        )}
      </div>
    );
  };
  Component.displayName = `WithPagination(${WrappedComponent.displayName})`;

  return Component;
};

export { withPagination };
