import { useCallback, useState } from 'react';

import { CmTableOperation } from '@common/components/CMTable/types';

import { CmTableProps } from '@src/common';

type UseCMTableSelectionReturn<D> = {
  multi: boolean;
  rowKey?: CmTableProps<D>['rowKey'];
  selectedRow: D | null;
  selectedMultiRows: D[];
  onSelectRow: (row: D | null) => void;
  onSelectAllRows: (row: D[]) => void;
  multiSelection: (row: D) => void;
  operations?: CmTableOperation[];
  dropSelection: () => void;
};

const useCMTableSelection = <D>(
  operations?: CmTableOperation[],
  multi?: boolean,
  rowKey?: CmTableProps<D>['rowKey'],
): UseCMTableSelectionReturn<D> => {
  const [selectedRow, setSelectedRow] = useState<D | null>(null);

  const [selectedMultiRows, setSelectedMultiRows] = useState<D[]>(() => []);

  const multiSelection = useCallback(
    (row: D) => {
      if (!rowKey) {
        // eslint-disable-next-line no-console
        return console.warn('Pass row key fn to useCMTableSelection hook');
      }

      const exist = selectedMultiRows.some((item) => rowKey(item) === rowKey(row));

      if (exist) {
        setSelectedMultiRows((prev) => prev.filter((item) => rowKey(item) !== rowKey(row)));
      } else {
        setSelectedMultiRows((prev) => [...prev, row]);
      }
    },
    [rowKey, selectedMultiRows],
  );

  const dropSelection = useCallback(() => {
    if (multi) {
      setSelectedMultiRows([]);
      return;
    }
    setSelectedRow(null);
  }, [multi]);

  return {
    multi: Boolean(multi),
    rowKey,
    selectedRow,
    onSelectRow: setSelectedRow,
    selectedMultiRows,
    onSelectAllRows: setSelectedMultiRows,
    multiSelection,
    operations,
    dropSelection,
  };
};

export { useCMTableSelection };
export type { UseCMTableSelectionReturn };
