import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { FormUserUpdate } from '@common/components/AppToolbar/components/Profile/SidePanelProfile';
import { FileInput } from '@common/components/AppToolbar/components/Profile/FileInput';
import { updateProfile, updateUserAvatar } from '@common/components/AppToolbar/api/requests';

import { Button, CMModal, CMModalFooter } from '@src/kit';
import {
  FormInput,
  REGEXP_DICTIONARY,
  required,
  useAppToolbarContext,
  useAppTranslation,
  UserDto,
  UserUpdateDTO,
  useToast,
} from '@src/common';
import { Form } from '@src/common/components/Forms';
import { bindStyles } from '@src/utils';

import styles from './EmptyUserInfoModal.module.scss';

const cx = bindStyles(styles);

type Props = {
  user: UserDto;
};

const FORM_ID = 'user-edit-first';

const EmptyUserInfoModal: React.FC<Props> = ({ user }) => {
  const { t } = useAppTranslation();
  const toast = useToast();
  const { onChangeUser } = useAppToolbarContext();
  const [loading, setLoading] = useState<boolean>(false);

  const formMethods = useForm<FormUserUpdate>({
    defaultValues: {
      firstName: user.firstName || '',
      lastName: user.lastName || '',
      company: user.company || '',
      tel: user.tel || '',
    },
  });

  const onSubmit = async (formData: FormUserUpdate) => {
    try {
      setLoading(true);

      const { firstName, lastName, tel, company, avatar } = formData;

      const dto: UserUpdateDTO = {
        company,
        tel,
        firstName: firstName.trim(),
        lastName: lastName.trim(),
      };

      const req: Promise<any>[] = [updateProfile(dto)];

      if (formData.avatar !== user?.avatar && avatar) {
        req.push(updateUserAvatar({ data: avatar }));
      }

      await Promise.all(req);
      toast.success({ text: t('cos.editUserInfo.statusPopup.success') });

      onChangeUser({
        company,
        tel,
        firstName,
        lastName,
      });
    } catch (error) {
      toast.error({ text: t('cos.editUserInfo.statusPopup.error') });
    } finally {
      setLoading(false);
    }
  };

  const onChangePhone = () => formMethods.trigger('tel');

  const handlePhoneKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key.toString().match(REGEXP_DICTIONARY.phoneSymbols)) {
      e.preventDefault();
    }
  };

  const validatePhone = useMemo(
    () => ({
      validate: (value: string | undefined) => {
        if (value) {
          return (
            new RegExp(REGEXP_DICTIONARY.phone).test(value) ||
            (t('cos.editUserInfo.phone.error') as string)
          );
        }

        return true;
      },
    }),
    [t],
  );

  return (
    <CMModal
      open
      title={t('common.emptyUseModal.title')}
      closeOnDocumentClick={false}
      closeOnEscape={false}
      headerCloseVisible={false}
      footerJSX={
        <CMModalFooter className={cx('grid')}>
          <Button
            className={cx('submit')}
            type="submit"
            form={FORM_ID}
            isLoading={loading}
          >
            {t('Save')}
          </Button>
        </CMModalFooter>
      }
    >
      <Form
        id={FORM_ID}
        formMethods={formMethods}
        className={cx('grid')}
        onSubmit={onSubmit}
      >
        <div className={cx('avatar')}>
          <FileInput />
        </div>

        <FormInput
          name="firstName"
          caption={t('Name')}
          rules={required('FieldRequired')}
          autoComplete="given-name"
        />
        <FormInput
          name="lastName"
          caption={t('Surname')}
          rules={required('FieldRequired')}
          autoComplete="family-name"
        />

        <FormInput
          name="company"
          caption={t('Company')}
        />
        <FormInput
          placeholder={t('cos.editUserInfo.phone.placeholder')}
          name="tel"
          id="newTel"
          autoComplete="off"
          rules={validatePhone}
          caption={t('Phone')}
          onKeyPress={handlePhoneKeyPress}
          onChangeCapture={onChangePhone}
        />
      </Form>
    </CMModal>
  );
};

export { EmptyUserInfoModal };
