import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { UsersRolesDTO, UserViewDTO } from '@common/components/UserRoles/types';
import { fetchUserData, fetchUsersRoles } from '@common/components/UserRoles/api/requests';

import { ErrorUtils } from '@src/utils';
import { useAppTranslation, useToast } from '@src/common';
import { Brand } from '@src/@types';
import { DisclaimerVariant } from '@src/kit';

import { UserRolesContext } from './UserRolesContext';

type Props = {
  brand?: Brand;
};

const UserRolesProvider: FC<Props> = ({ children, brand = 'cloudmaster' }) => {
  const { t } = useAppTranslation();
  const [isRolesLoading, setIsRolesLoading] = useState(false);
  const toast = useToast();
  const [clouderRoleList, setClouderRoleList] = useState<UsersRolesDTO[]>([]);
  const [costerRoleList, setCosterRoleList] = useState<UsersRolesDTO[]>([]);
  const [cardId, setCardId] = useState<string>();
  const [userData, setUserData] = useState<UserViewDTO>();
  const [stepDisclaimer, setStepDisclaimer] = useState<DisclaimerVariant>('info');

  const getRoleList = async () => {
    try {
      setIsRolesLoading(true);

      const res = await fetchUsersRoles('CLOUDER');
      const costerRes = await fetchUsersRoles('COSTER');

      setClouderRoleList(res.data);
      setCosterRoleList(costerRes.data);
    } catch (e) {
      const text = ErrorUtils.handleApiError(e);
      toast.error({ text });
    } finally {
      setIsRolesLoading(false);
    }
  };

  useEffect(() => {
    getRoleList();
  }, []);

  const formattedClouderRoles = useMemo(() => {
    return clouderRoleList.map(({ id, name, ...rest }) => ({
      value: id,
      label: t(name.replace(' ', '')),
      meta: rest,
    }));
  }, [clouderRoleList, t]);

  const formattedCosterRoles = useMemo(() => {
    return costerRoleList.map(({ id, name, ...rest }) => ({
      value: id,
      label: t(name.replace(' ', '')),
      meta: rest,
    }));
  }, [costerRoleList, t]);

  const closeUserModal = useCallback(() => {
    setCardId(undefined);
  }, []);

  const getUserData = useCallback(async (uuid: string) => {
    try {
      const res = await fetchUserData(uuid);

      setUserData(res.data);
    } catch (e) {
      const text = ErrorUtils.handleApiError(e);
      toast.error({ text });
    }
  }, []);

  return (
    <UserRolesContext.Provider
      value={{
        cardId,
        closeUserModal,
        openUserModal: setCardId,
        clouderRoleList,
        costerRoleList,
        formattedClouderRoles,
        formattedCosterRoles,
        isRolesLoading,
        userData,
        getUserData,
        brand,
        setStepDisclaimer,
        stepDisclaimer,
      }}
    >
      {children}
    </UserRolesContext.Provider>
  );
};

export { UserRolesProvider };
