import React from 'react';

import cn from 'classnames/bind';

import styles from './ProviderMiniCard.module.scss';

type MiniCardProps = {
  disabled?: boolean;
  selected?: boolean;
};

type Props = Omit<React.ComponentPropsWithRef<'article'>, keyof MiniCardProps> & MiniCardProps;

const cx = cn.bind(styles);

const ProviderMiniCard: React.FC<Props> = ({
  children,
  disabled,
  selected,
  className,
  ...restProps
}) => {
  const classNames = cx('miniCard', { disabled, selected }, className);

  return (
    <article
      className={classNames}
      {...restProps}
      data-testid="provider-mini-card"
    >
      {children}
    </article>
  );
};

export { ProviderMiniCard };
