import { FC } from 'react';

import { TaskId } from '@common/api/Notification/models';

import { useIntersectionObserver } from '@src/common';
import { bindStyles, getDateTimeStringFormat } from '@src/utils';
import { Loader, NotificationCard, NotificationCardPayload } from '@src/kit';

import styles from './NotificationsList.module.scss';

const cx = bindStyles(styles);

type Props = {
  loading?: boolean;
  error?: boolean;
  notificationCards: NotificationCardPayload[];
  loadMore: () => void;
  canLoadMore: boolean;
  handleEventDetail: (id: TaskId) => void;
};

const NotificationsListView: FC<Props> = ({
  notificationCards,
  loading,
  error,
  loadMore,
  canLoadMore,
  handleEventDetail,
}) => {
  const intersectionHandle = ([entry]: IntersectionObserverEntry[]) => {
    if (entry.isIntersecting && !loading && !error && canLoadMore) {
      loadMore();
    }
  };

  const { containerRef } = useIntersectionObserver<HTMLDivElement>(intersectionHandle);

  return (
    <>
      {notificationCards.map((notification, index) => {
        const { time, date } = notification;
        const { dateFormatted, timeFormatted } = getDateTimeStringFormat(time || date);
        return (
          <NotificationCard
            key={index}
            {...notification}
            date={dateFormatted}
            time={timeFormatted}
            handleEventDetail={handleEventDetail}
          />
        );
      })}

      {loading && (
        <div className={cx('loader')}>
          <Loader size="xxl" />
        </div>
      )}

      {canLoadMore && <div ref={containerRef} />}
    </>
  );
};

export { NotificationsListView };
