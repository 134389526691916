import React, { useEffect, useMemo } from 'react';

import { bindStyles } from '@src/utils';
import { Button, Icon, Input, InputIconObject } from '@src/kit';
import { useAppTranslation } from '@src/common';

import styles from './SearchField.module.scss';

const cx = bindStyles(styles);

type Props = {
  searchValue: string;
  onChangeSearchValue: (value: string) => void;
};

const SearchField: React.FC<Props> = ({ searchValue, onChangeSearchValue }) => {
  const { t } = useAppTranslation();
  const onClear = () => onChangeSearchValue('');

  useEffect(() => {
    return onClear;
  }, []);

  const actions: InputIconObject[] = useMemo(() => {
    const res: InputIconObject[] = [
      {
        icon: <Icon type="search" />,
        alignLeft: true,
      },
    ];

    if (searchValue) {
      res.push({
        icon: (
          <Button
            variant="text"
            size="sm"
            onClick={onClear}
          >
            <Icon type="close" />
          </Button>
        ),
      });
    }
    return res;
  }, [searchValue]);

  const onChange = ({ target }: React.SyntheticEvent) =>
    onChangeSearchValue((target as HTMLInputElement).value);

  return (
    <div className={cx('input')}>
      <Input
        size="md"
        placeholder={t('Search')}
        actions={actions}
        value={searchValue}
        onChange={onChange}
      />
    </div>
  );
};

export { SearchField };
