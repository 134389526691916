import React, { useMemo } from 'react';

import { ManageAvatar } from '@common/components/AppToolbar/components/Profile/ManageAvatar';

import { toDefaultDate, useAppTranslation, UserDto } from '@src/common';
import { formFullName, bindStyles } from '@src/utils';
import { DashValue } from '@src/kit';

import styles from './InfoStage.module.scss';

const cx = bindStyles(styles);

type Props = {
  user: UserDto;
};

const InfoStage: React.FC<Props> = ({ user }) => {
  const { t } = useAppTranslation();

  const clouderRole = user?.roles?.[0];
  const costerRole = user?.roles?.[1];

  const name = useMemo(() => {
    if (!user) {
      return '';
    }

    const { firstName, lastName } = user;

    return formFullName(firstName, lastName);
  }, [user]);

  if (!user) {
    return null;
  }

  const { createdAt, lastLoginAt, login, company, tel } = user;

  return (
    <div>
      <div className={cx('header')}>
        <ManageAvatar avatar={user.avatar} />
      </div>

      <div className={cx('container')}>
        <div className={cx('informationBlock')} />

        <div className={cx('nameBlock')}>
          <div className={cx('name')}> {name} </div>
        </div>

        <div className={cx('datesBlock')}>
          <div className={cx('dateValueBlock')}>
            <div className={cx('dateLabel')}>{t('cos.user.roles.coster')}</div>
            <div className={cx('dateValue')}>
              <DashValue condition={Boolean(costerRole)}>
                {t(costerRole?.name.replace(' ', '') || '')}
              </DashValue>
            </div>
          </div>
          <div className={cx('dateValueBlock')}>
            <div className={cx('dateLabel')}>{t('cos.user.roles.clouder')}</div>
            <div className={cx('dateValue')}>
              <DashValue condition={Boolean(clouderRole)}>
                {t(clouderRole?.name.replace(' ', '') || '')}
              </DashValue>
            </div>
          </div>
          <div className={cx('separator')} />
          <div className={cx('dateValueBlock')}>
            <div className={cx('dateLabel')}>{t('CreationDate')}</div>
            <div className={cx('dateValue')}>
              <DashValue condition={Boolean(createdAt)}>
                {toDefaultDate(createdAt || '', 'dd.MM.yyyy / HH:mm')}
              </DashValue>
            </div>
          </div>
          <div className={cx('dateValueBlock')}>
            <div className={cx('dateLabel')}>{t('LastLogin')}</div>
            <div className={cx('dateValue')}>
              <DashValue condition={Boolean(lastLoginAt)}>
                {toDefaultDate(lastLoginAt || '', 'dd.MM.yyyy / HH:mm')}
              </DashValue>
            </div>
          </div>
        </div>

        <div className={cx('commentBlock')}>
          <div className={cx('commentLabel')}>
            {t('Mail')} / {t('Login')}
          </div>
          <div className={cx('commentText')}> {login} </div>
        </div>
        {company && (
          <div className={cx('commentBlock')}>
            <div className={cx('commentLabel')}>{t('Company')}</div>
            <div className={cx('commentText')}> {company} </div>
          </div>
        )}
        {tel && (
          <div className={cx('commentBlock')}>
            <div className={cx('commentLabel')}>{t('Phone')}</div>
            <div className={cx('commentText')}> {tel} </div>
          </div>
        )}
      </div>
      <div className={cx('separator')} />
    </div>
  );
};

export { InfoStage };
