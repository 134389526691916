import React from 'react';

import { useAccessControl, PermissionParams } from '@src/common';

type Props = PermissionParams & {
  Navigate: React.ReactNode;
};

const PageGuard: React.FC<Props> = (props) => {
  const checkAccess = useAccessControl();

  const allowed = checkAccess(props)();

  if (allowed) {
    return <>{props.children}</>;
  }

  return <>{props.Navigate}</>;
};

export { PageGuard };
