import { useRef, MouseEvent, useCallback } from 'react';

type Props<T> = {
  onClick?: (data: T, event?: MouseEvent) => void;
  onDbClick?: (data: T, event?: MouseEvent) => void;
};

type ReturnValue<T> = (data: T, event: MouseEvent) => void;

const DELAY_BETWEEN_CLICKS = 150;

const useHandleClick = <T>({ onClick, onDbClick }: Props<T>): ReturnValue<T> => {
  const clickedTimes = useRef<number>(0);

  const incrementClick = () => clickedTimes.current++;
  const reset = () => (clickedTimes.current = 0);

  const handleClick = (data: T, event: MouseEvent) => {
    incrementClick();

    if (!onClick) {
      onDbClick?.(data, event);
      return;
    }

    if (!onDbClick) {
      onClick?.(data, event);
      return;
    }

    window.setTimeout(() => {
      clickedTimes.current === 1 && onClick(data, event);
      clickedTimes.current >= 2 && onDbClick(data, event);

      reset();
    }, DELAY_BETWEEN_CLICKS);
  };

  return useCallback(handleClick, []);
};

export { useHandleClick };
