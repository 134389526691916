import React from 'react';

import { bindStyles } from '@src/utils';
import { CurrencyTypes } from '@src/@types';

import styles from './CurrencyParser.module.scss';

type CurrencyParserProps = {
  className?: string;
  currencyClassName?: string;
  currency?: CurrencyTypes;
  tooltip?: boolean;
  localCurrency?: CurrencyTypes;
};

const currencyParser = {
  RUB: '₽',
  USD: '$',
  INR: '₹',
};

const CurrencyParser: React.FC<CurrencyParserProps> = ({
  currency,
  className,
  currencyClassName,
  children,
  localCurrency = 'USD',
}) => {
  const cx = bindStyles(styles);
  const currencyToUse = currency || localCurrency;

  return (
    <>
      {currencyToUse === 'RUB' ? (
        <div className={cx('content', className)}>
          {children}
          <p className={cx(currencyClassName)}>{` ${currencyParser[currencyToUse]}`}</p>
        </div>
      ) : (
        <div className={cx('content', className)}>
          <p className={cx(currencyClassName)}>{`${currencyParser[currencyToUse]} `}</p>
          {children}
        </div>
      )}
    </>
  );
};

export { CurrencyParser };
export type { CurrencyParserProps };
