import { Language } from '@src/@types';

const MIN_AVAILABLE_SCREEN_WIDTH = 1024;
const MIN_AVAILABLE_SCREEN_HEIGHT = 700;

const BODY_SIDE_BAR_OPEN_CLASS = 'cld-side-bar-open';

const SIDEBAR_MINIFIED_KEY = 'sidebarMinified';

const DEFAULT_LANGUAGE: Language = navigator.language === 'ru' ? 'ru-RU' : 'en-US';

const ABORT_REQUEST_KEY = 'canceled';

const LANG_CLOUDMASTER_KEY = 'cloudmaster_language';
const LANG_ASTROKITE_KEY = 'astrokite_language';

export {
  MIN_AVAILABLE_SCREEN_WIDTH,
  MIN_AVAILABLE_SCREEN_HEIGHT,
  ABORT_REQUEST_KEY,
  DEFAULT_LANGUAGE,
  LANG_CLOUDMASTER_KEY,
  LANG_ASTROKITE_KEY,
  BODY_SIDE_BAR_OPEN_CLASS,
  SIDEBAR_MINIFIED_KEY,
};
