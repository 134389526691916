import React from 'react';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';

import { Button, Icon } from '@kit/components';
import { CommonDatePickerProps, Screens } from '@kit/components/DatePicker/types';

import { bindStyles } from '@src/utils';

import styles from './DpHeader.module.scss';

const cx = bindStyles(styles);

type Props = ReactDatePickerCustomHeaderProps &
  CommonDatePickerProps & {
    onNavigate: (screen: Screens) => void;
  };

const DpHeader: React.FC<Props> = ({
  date,
  monthsList,
  increaseMonth,
  decreaseMonth,
  onNavigate,
}) => {
  const to = (screen: Screens) => () => onNavigate(screen);

  return (
    <div className={cx('header')}>
      <div className={cx('inner')}>
        <Button
          variant="text"
          size="sm"
          className={cx('button', 'button_prev')}
          onClick={decreaseMonth}
        >
          <Icon type="chevron-down" />
        </Button>

        <div className={cx('date')}>
          <span
            className={cx('date-btn', 'date-btn__year')}
            onClick={to('year')}
          >
            {date.getFullYear()}
          </span>
          <span
            className={cx('date-btn', 'date-btn__month')}
            onClick={to('month')}
          >
            {monthsList[date.getMonth()]}
          </span>
        </div>

        <Button
          variant="text"
          size="sm"
          className={cx('button', 'button_next')}
          onClick={increaseMonth}
        >
          <Icon type="chevron-down" />
        </Button>
      </div>
    </div>
  );
};

export { DpHeader };
