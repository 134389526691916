import { PropsWithChildren } from 'react';
import { CellProps } from 'react-table';

import { TextShorter } from '@kit/components/TextShorter';

type Props<T extends Record<string, unknown>> = PropsWithChildren<CellProps<T>> & {
  className?: string;
};

const Cell = <T extends Record<string, unknown>>({
  value,
  children = value,
  className,
}: Props<T>) => {
  const cellContent = children || '-';

  return (
    <TextShorter
      className={className}
      tooltip
    >
      {cellContent}
    </TextShorter>
  );
};

export { Cell };
