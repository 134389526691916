import { Bookmark } from '@src/common';

import CosterImage from '@assets/images/CosterModule.svg';
import ClouderModule from '@assets/images/ClouderModule.svg';

const BOOKMARKS_KEY = 'cm_bookmarks';
const CURRENCY_KEY = 'cm_currency';
const LANG_CLOUDMASTER_KEY = 'cloudmaster_language';
const LANG_ASTROKITE_KEY = 'astrokite_language';
const LAST_PAGE_LINK_KEY = 'last_page_link';

const SYSTEMS: Bookmark[] = [
  {
    system: 'clouder',
    icon: <ClouderModule />,
    label: 'Clouder',
  },
  {
    system: 'coster',
    icon: <CosterImage />,
    label: 'Coster',
  },
];

export {
  BOOKMARKS_KEY,
  SYSTEMS,
  CURRENCY_KEY,
  LANG_CLOUDMASTER_KEY,
  LANG_ASTROKITE_KEY,
  LAST_PAGE_LINK_KEY,
};
