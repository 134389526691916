import React, { useMemo } from 'react';

import {
  emailPattern,
  FormInput,
  FormSelect,
  FormTextarea,
  required,
  useAppTranslation,
  useUserRolesContext,
} from '@src/common';
import { bindStyles } from '@src/utils';
import { Disclaimer } from '@src/kit';

import AvatarDefault from '@assets/images/AvatarDefault.svg';
import AvatarDefaultAstrokite from '@assets/images/AvatarDefaultAstrokite.svg';

import styles from './UserCreate.module.scss';

const cx = bindStyles(styles);

const UserCreateView: React.FC = () => {
  const { t } = useAppTranslation();

  const { isRolesLoading, formattedClouderRoles, formattedCosterRoles, brand } =
    useUserRolesContext();

  const renderedAvatar = useMemo(() => {
    switch (brand) {
      case 'astrokite':
        return <AvatarDefaultAstrokite />;
      case 'cloudmaster':
        return <AvatarDefault />;
    }
  }, [brand]);

  return (
    <div className={cx('container')}>
      <div className={cx('avatar')}>{renderedAvatar}</div>

      <div className={cx('informationBlock')}>
        <FormInput
          name="firstName"
          caption={t('Name')}
          placeholder={t('cld.modal.user.placeholder')}
          rules={{ ...required('FieldRequired') }}
        />
        <FormInput
          name="lastName"
          caption={t('Surname')}
          placeholder={t('cld.modal.surname.placeholder')}
          rules={{ ...required('FieldRequired') }}
        />
      </div>

      <FormInput
        name="login"
        caption={t('EmailLogin')}
        placeholder={t('cld.modal.email.placeholder')}
        rules={{ ...required('FieldRequired'), ...emailPattern('InvalidLogin') }}
      />

      <Disclaimer text={t('cld.modal.user.text.warning')} />

      <div className={cx('informationBlock')}>
        <FormSelect
          name="clouderRole"
          caption={t('cos.user.roles.clouder')}
          isLoading={isRolesLoading}
          options={formattedClouderRoles}
          placeholder={t('cld.modal.role.placeholder')}
          rules={{ ...required('FieldRequired') }}
        />
        <FormSelect
          name="costerRole"
          caption={t('cos.user.roles.coster')}
          isLoading={isRolesLoading}
          options={formattedCosterRoles}
          placeholder={t('cld.modal.role.placeholder')}
          rules={{ ...required('FieldRequired') }}
        />
      </div>

      <FormTextarea
        name="comment"
        caption={t('Comment')}
        placeholder={t('SubscriptionFormTexArea')}
      />
    </div>
  );
};

export { UserCreateView };
