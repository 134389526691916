import React from 'react';

import { Icon } from '@kit/components';

import { bindStyles } from '@src/utils';

import styles from './WarningMessageBlock.module.scss';

const cx = bindStyles(styles);

type Props = {
  children: React.ReactNode;
};

const WarningMessageBlock: React.FC<Props> = ({ children }) => (
  <div className={cx('warning')}>
    <div className={cx('warning__icon')}>
      <Icon type="circle-information" />
    </div>
    <span>{children}</span>
  </div>
);

export { WarningMessageBlock };
