import React from 'react';

import { Avatar, TextShorter } from '@kit/components';

import { bindStyles } from '@src/utils';

import styles from './User.module.scss';

type Props = {
  image?: string;
  name: string;
  size?: 'sm' | 'md';
  uuid: string;
  className?: string;
  classNameText?: string;
  tooltip?: boolean;
  withBorder?: boolean;
  isUserActive?: boolean;
};

const cx = bindStyles(styles);

const User: React.FC<Props> = ({
  name,
  image,
  size = 'sm',
  uuid,
  className,
  classNameText,
  tooltip = true,
  withBorder = true,
  isUserActive = true,
}) => (
  <div className={cx('user', size, className, { withBorder })}>
    <div>
      <Avatar
        src={image}
        fullName={name}
        className={cx('user-avatar', { disabled: isUserActive === false })}
        userId={uuid}
      />
    </div>
    <div className={cx('user-name')}>
      <span className={cx(classNameText)}>
        <TextShorter tooltip={tooltip}>{name}</TextShorter>
      </span>
    </div>
  </div>
);

export { User };
