import { FC, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';

import { AuthDTO } from '@common/api/Auth/models';

import {
  AppSystem,
  AuthApi,
  AuthContext,
  RestService,
  StorageService,
  UserApi,
  UserInfoDTO,
} from '@src/common';
import { appNavigate, encrypt } from '@src/utils';
import { ADMIN_AUTH_KEY, AUTH_HEADER, LANG_CLOUDMASTER_KEY } from '@src/constants';

type Props = PropsWithChildren<{
  isAuthPage?: boolean;
  system: AppSystem;
}>;

const storageService = StorageService.getInstance();
const restService = RestService.getInstance();

const DEFAULT_NAMESPACE_RU = 'translationru-RU';
const DEFAULT_NAMESPACE_EN = 'translationen-US';

const AuthProvider: FC<Props> = ({ system, children, isAuthPage }) => {
  const [isCheckLoading, setIsCheckLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [userInfo, setUserInfo] = useState<UserInfoDTO>();
  const isAdmin = system === 'admin';

  const fetchLogin = useCallback(
    (dto: AuthDTO) => {
      switch (system) {
        case 'clouder':
        case 'coster':
          return AuthApi.authorization(dto);
        case 'admin':
          return AuthApi.adminAuthorization(dto);
      }
    },
    [system],
  );

  const storageKey = useMemo(() => {
    switch (system) {
      case 'clouder':
      case 'coster':
        return AUTH_HEADER;
      case 'admin':
        return ADMIN_AUTH_KEY;
    }
  }, [system]);

  const loginPageUrl = useMemo(() => {
    switch (system) {
      case 'clouder':
      case 'coster':
        return '/auth';
      case 'admin':
        return '/admin/login';
    }
  }, [system]);

  const toAuth = useCallback(() => appNavigate(loginPageUrl), [loginPageUrl]);

  const login = useCallback(
    async (username: string, password: string) => {
      try {
        setIsLoading(true);
        const code = encrypt(username + ':' + password);

        const res = await fetchLogin({ code });
        const sessionToken = res.headers[AUTH_HEADER];

        if (sessionToken) {
          storageService.setItem(storageKey, sessionToken);
          restService.addDefaultHeader(AUTH_HEADER, sessionToken);
        }

        if (isAdmin) {
          const userInfoRes = await UserApi.getUserInfo();
          setUserInfo(userInfoRes.data);
        } else {
          appNavigate('/');
        }
        setIsAuth(true);
      } catch (e) {
        throw e;
      } finally {
        setIsLoading(false);
      }
    },
    [fetchLogin, storageKey],
  );

  const fakeRedirect = (url: string) => {
    return new Promise((res) => {
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('target', '_blank');
      link.setAttribute('rel', 'noopener noreferrer');
      document.body.appendChild(link);
      setTimeout(() => {
        link.click();
        res(true);
      }, 100);
    });
  };

  const logout = async () => {
    try {
      setIsLoading(true);

      const redirectUrl = await AuthApi.logout();

      storageService.clear({
        excludedKeys: [
          'cloudColors',
          'onboardingWatched',
          LANG_CLOUDMASTER_KEY,
          DEFAULT_NAMESPACE_RU,
          DEFAULT_NAMESPACE_EN,
        ],
      });
      restService.removeDefaultHeader(AUTH_HEADER);

      if (redirectUrl) {
        await fakeRedirect(redirectUrl);
      }

      setIsAuth(false);

      if (isAdmin) {
        setUserInfo(undefined);
      } else {
        toAuth();
      }
    } catch (e) {
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  const checkUserAdAuthorization = async () => {
    const code = new URLSearchParams(window.location.search).get('code');
    const domain = window.location.pathname.split('/').pop();

    if (!domain || !code) {
      throw new Error('No AD cridentials');
    }

    try {
      const response = await AuthApi.checkAdCode({ code, domain });
      const token = response.headers[AUTH_HEADER];

      storageService.setItem(AUTH_HEADER, token);
      restService.addDefaultHeader(AUTH_HEADER, token as string);
    } catch (err) {
      throw err;
    }
  };

  const checkUserAuthStatus = async () => {
    setIsCheckLoading(true);
    try {
      const token = storageService.getItem(storageKey);

      if (!token) {
        restService.removeDefaultHeader(AUTH_HEADER);
        throw new Error('Token not founded');
      }

      restService.addDefaultHeader(AUTH_HEADER, token);

      if (isAuthPage) {
        appNavigate('/');
      }
    } catch (err) {
      try {
        await checkUserAdAuthorization();
        if (isAuthPage) {
          appNavigate('/');
        }
      } catch (err) {
        if (!isAuthPage) {
          toAuth();
        }
      }
    } finally {
      setIsCheckLoading(false);
    }
  };

  const checkToken = useCallback(async () => {
    try {
      setIsCheckLoading(true);
      const token = storageService.getItem(storageKey);

      if (!token) {
        storageService.removeItem(storageKey);
        restService.removeDefaultHeader(AUTH_HEADER);
        setIsAuth(false);
        if (isAdmin) {
          setUserInfo(undefined);
        }
        if (!isAuthPage && !isAdmin) {
          toAuth();
        }
      } else {
        setIsAuth(true);
        restService.addDefaultHeader(AUTH_HEADER, token);

        if (isAdmin) {
          const res = await UserApi.getUserInfo();
          setUserInfo(res.data);
        }

        if (isAuthPage && !isAdmin) {
          appNavigate('/');
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsCheckLoading(false);
    }
  }, [storageKey, isAuthPage]);

  const checkAdminToken = async () => {
    setIsCheckLoading(true);
    const token = storageService.getItem(storageKey);

    if (!token) {
      setIsAuth(false);
      setUserInfo(undefined);
      setIsCheckLoading(false);
      storageService.removeItem(storageKey);
      return;
    }

    try {
      restService.addDefaultHeader(AUTH_HEADER, token);
      const res = await UserApi.getUserInfo();
      setUserInfo(res.data);
      setIsAuth(true);
    } catch (err) {
      setIsAuth(false);
      setUserInfo(undefined);
      restService.removeDefaultHeader(AUTH_HEADER);
    } finally {
      setIsCheckLoading(false);
    }
  };

  useEffect(() => {
    if (system === 'admin') {
      checkAdminToken();
    } else {
      checkUserAuthStatus();
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuth,
        logout,
        toAuth,
        checkToken,
        authStorageKey: storageKey,
        login,
        isLoading,
        userInfo,
      }}
    >
      {!isCheckLoading && children}
    </AuthContext.Provider>
  );
};

export { AuthProvider };
