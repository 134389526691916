import React from 'react';
import { animated, useTransition } from 'react-spring';

import cn from 'classnames/bind';

import { Operations, OperationProps } from './Operations/Operations';
import styles from './Table.module.scss';

const cx = cn.bind(styles);

const withOperations = <T,>(WrappedComponent: React.FC<T>) => {
  const Component = (props: T & OperationProps) => {
    const { operations = [], selectedRowIds = [], operationsClassName } = props;
    const componentProps = props as unknown as T;

    const isOperationVisible = selectedRowIds?.length > 0;

    const transitions = useTransition(isOperationVisible, {
      from: { opacity: 0, height: '0rem' },
      enter: { opacity: 1, height: '10rem' },
      leave: { opacity: 0, height: '0rem' },
    });

    const operationNodes = transitions(
      (styles, item) =>
        item && (
          <animated.div style={styles}>
            <Operations
              operations={operations}
              operationsClassName={cx(operationsClassName, 'operations', {
                operationsHidden: !isOperationVisible,
              })}
            />
          </animated.div>
        ),
    );

    return (
      <div className={cx('operationContainer')}>
        {operationNodes}

        <WrappedComponent
          {...componentProps}
          selectedRowIds={selectedRowIds}
        />
      </div>
    );
  };
  Component.displayName = `WithOperations(${WrappedComponent.displayName})`;

  return Component;
};

export { withOperations };
