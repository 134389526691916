import { MutableRefObject, useEffect, useRef } from 'react';

const DEFAULT_OPTIONS: IntersectionObserverInit = {};

type ReturnValue<T> = {
  containerRef: MutableRefObject<T | null>;
};

const useIntersectionObserver = <T extends HTMLElement>(
  callback: IntersectionObserverCallback,
  options = DEFAULT_OPTIONS,
): ReturnValue<T> => {
  const containerRef = useRef<T | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(callback, options);
    containerRef.current && observer.observe(containerRef.current);

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [containerRef, callback]);

  return { containerRef };
};

export { useIntersectionObserver };
