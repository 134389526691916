import React from 'react';

import styles from './ToastTitle.module.scss';

type Props = {
  title?: string;
};

const ToastTitle: React.FC<Props> = ({ title }) => {
  return <h3 className={styles.toastTitle}>{title}</h3>;
};

export { ToastTitle };
