import { FC, useMemo } from 'react';

import { bindStyles, getDocsLink } from '@src/utils';
import { DocsManual, H1 } from '@src/kit';
import { useAppToolbarContext, useAppTranslation } from '@src/common';

import styles from './SettingsWrapper.module.scss';

const cx = bindStyles(styles);

type Props = {
  settingsPage: 'users' | 'activeDirectory' | 'currencyLanguage';
};

const SettingsWrapper: FC<Props> = ({ children, settingsPage }) => {
  const { brand } = useAppToolbarContext();

  const { t } = useAppTranslation();

  const linkToManual = useMemo(() => {
    const docsLink = getDocsLink(brand);

    switch (settingsPage) {
      case 'users':
        return `${docsLink}add-users-to-cloud/`;
      case 'activeDirectory':
        return `${docsLink}manage-active-directory/`;
      case 'currencyLanguage':
        return `${docsLink}settings/#_3.`;
    }
  }, [settingsPage, brand]);

  const manualTitle = useMemo(() => {
    switch (settingsPage) {
      case 'users':
        return t('popupUsers');
      case 'activeDirectory':
        return t('Active directory');
      case 'currencyLanguage':
        return t('currencyAndLanguage');
    }
  }, [t, settingsPage]);

  return (
    <div className={cx('wrapper')}>
      <div className={cx('title')}>
        <H1
          size="lg"
          className={cx('labelContainer')}
        >
          {manualTitle}
        </H1>
        <DocsManual linkToManual={linkToManual} />
      </div>
      <div className={cx('children')}>{children}</div>
    </div>
  );
};

export { SettingsWrapper };
