import React from 'react';
import { components, SingleValueProps } from 'react-select';

import cn from 'classnames/bind';
import { SelectOption } from '@kit/components';
import { BaseOptionWithIcon } from '@kit/components/Select/BaseOptionWithIcon';

import styles from './Select.module.scss';

type Props = {
  className?: string;
  getIconTemplate: (data: SelectOption<unknown>) => React.ReactNode;
} & SingleValueProps;

const cx = cn.bind(styles);

const SingleValueWithIcon: React.FC<Props> = ({ className, getIconTemplate, ...props }) => {
  const _data = props.data as SelectOption<unknown>;

  const label = _data.label as string;

  return (
    <div className={cx('optionContainer', className)}>
      <components.SingleValue {...props}>
        <BaseOptionWithIcon label={label}>{getIconTemplate(_data)}</BaseOptionWithIcon>
      </components.SingleValue>
    </div>
  );
};

export { SingleValueWithIcon };
