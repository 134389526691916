import React, { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { FormUserUpdate } from '@common/components/AppToolbar/components/Profile/SidePanelProfile';
import { FileInput } from '@common/components/AppToolbar/components/Profile/FileInput';
import { PasswordChangeForm } from '@common/components/AppToolbar/components/Profile/PasswordChangeForm';

import { Checkbox, SelectOption } from '@src/kit';
import { FormInput, REGEXP_DICTIONARY, required, RoleDto, useAppTranslation } from '@src/common';
import { bindStyles } from '@src/utils';

import styles from './UpdateStage.module.scss';

const cx = bindStyles(styles);

type Props = {
  clouderRole: RoleDto;
  costerRole: RoleDto;
  scrollToEnd: () => void;
};

const UpdateStage: React.FC<Props> = ({ clouderRole, costerRole, scrollToEnd }) => {
  const { t } = useAppTranslation();

  const formMethods = useFormContext<FormUserUpdate>();

  const rolesFormat: SelectOption<string>[] = useMemo(
    () => [
      {
        label: t(clouderRole.name) as string,
        value: clouderRole.id,
      },
      {
        label: t(costerRole.name) as string,
        value: costerRole.id,
      },
    ],
    [costerRole, costerRole],
  );

  const validatePhone = useMemo(
    () => ({
      validate: (value: string | undefined) => {
        if (value) {
          return (
            new RegExp(REGEXP_DICTIONARY.phone).test(value) ||
            (t('cos.editUserInfo.phone.error') as string)
          );
        }

        return true;
      },
    }),
    [t],
  );

  const onChangePhone = () => formMethods.trigger('tel');

  const handlePhoneKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key.toString().match(REGEXP_DICTIONARY.phoneSymbols)) {
      e.preventDefault();
    }
  };

  return (
    <div className={cx('form')}>
      <div className={cx('full')}>
        <FileInput />
      </div>

      <FormInput
        name="firstName"
        caption={t('Name')}
        rules={required('FieldRequired')}
        autoComplete="given-name"
      />
      <FormInput
        name="lastName"
        caption={t('Surname')}
        rules={required('FieldRequired')}
        autoComplete="family-name"
      />
      <FormInput
        className={cx('full')}
        name="login"
        caption={t('EmailLogin')}
        autoComplete="username"
        disabled
      />
      <FormInput
        name="company"
        caption={t('Company')}
        disabled
      />
      <FormInput
        placeholder={t('cos.editUserInfo.phone.placeholder')}
        name="tel"
        id="newTel"
        autoComplete="off"
        rules={validatePhone}
        caption={t('Phone')}
        onKeyPress={handlePhoneKeyPress}
        onChangeCapture={onChangePhone}
      />
      <Controller
        control={formMethods.control}
        name="passwordVisible"
        render={({ field }) => {
          const onChange = () => {
            field.onChange(!field.value);
            if (!field.value) {
              scrollToEnd();
            }
          };

          return (
            <Checkbox
              checked={field.value}
              onChange={onChange}
            >
              {t('cld.header.userForm.checkbox.text')}
            </Checkbox>
          );
        }}
      />
      <PasswordChangeForm classNameFull={cx('full')} />
    </div>
  );
};

export { UpdateStage };
