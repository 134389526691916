import React, { useState } from 'react';

import { useCloudConnectContext } from '@src/common';
import { CloudTypes } from '@src/@types';

import { CloudConnectContent } from '../CloudConnectContent';
import styles from './ProviderSelection.module.scss';
import { ProvidersHeader } from './ProvidersHeader';
import { ProvidersTable } from './ProvidersTable';

const ProviderSelection: React.FC = () => {
  const { selectedProvider } = useCloudConnectContext();
  const buttonsOptions = {
    all: 'all',
    private: 'private',
    public: 'public',
  };
  const [buttonSelected, setButtonSelected] = useState(buttonsOptions.all);

  return (
    <>
      <CloudConnectContent>
        <div className={styles.headerContainer}>
          <ProvidersHeader
            buttonsOptions={buttonsOptions}
            buttonSelected={buttonSelected}
            setButtonSelected={setButtonSelected}
          />
        </div>
        <ProvidersTable
          cardsToShow={buttonSelected}
          selectedProvider={selectedProvider as CloudTypes}
        />
      </CloudConnectContent>
    </>
  );
};

export { ProviderSelection };
