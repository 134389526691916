import React, { useEffect, useRef, useState } from 'react';

type Props = {
  startDate: string;
  mode?: 'minutes' | 'seconds';
  render: (time: string) => React.ReactElement<unknown> | null;
};

const Stopwatch: React.FC<Props> = ({ startDate, mode = 'seconds', render }) => {
  const [watch, setWatch] = useState<string>('');

  const intervalId = useRef<ReturnType<typeof setInterval>>();

  const clear = () => intervalId.current && clearInterval(intervalId.current);

  const run = () => {
    const update = () => {
      const updateDate = new Date(Number(new Date()) - Number(new Date(startDate)))
        .toISOString()
        .split(/T|\./)[1];

      const [hh, mm, ss] = updateDate.split(':');

      setWatch(mode === 'minutes' ? `${hh}:${mm}` : `${hh}:${mm}:${ss}`);
    };

    const ms = mode === 'seconds' ? 1000 : 60000;

    update();
    setInterval(update, ms);
  };

  useEffect(() => {
    startDate && run();
  }, [startDate, mode]);

  useEffect(() => {
    return clear;
  }, []);

  return render(watch);
};

export { Stopwatch };
