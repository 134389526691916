import { FC, useMemo } from 'react';

import { bindStyles } from '@src/utils';
import { useAppTranslation, useCurrencyContext } from '@src/common';
import { Tooltip } from '@src/kit';

import styles from './TableHeader.module.scss';

const cx = bindStyles(styles);

type Props = {
  isFixRates?: boolean;
  hidden?: boolean;
};

const TableHeader: FC<Props> = ({ isFixRates, hidden }) => {
  const { t } = useAppTranslation();
  const { customerCurrency, currenciesList } = useCurrencyContext();

  const currencySign = useMemo(() => {
    return currenciesList.find((it) => it.code === customerCurrency)?.name;
  }, [customerCurrency, currenciesList]);

  if (hidden) {
    return null;
  }

  if (isFixRates) {
    return (
      <div className={cx('wrapper')}>
        <div className={cx('info')}>{t('cos.settings.tariffprices.itemName')}</div>
        <div className={cx('info')}>{t('cos.settings.tariffprices.quantity')}</div>
        <div className={cx('info', 'price')}>
          <Tooltip
            text={t('cos.settings.tariffprices.price.tooltip') as string}
            position={['top center']}
          >
            {t('cos.settings.tariffprices.price')}, {currencySign}
          </Tooltip>
        </div>
        <div className={cx('info')}>{t('cos.settings.tariffprices.tariff')}</div>
      </div>
    );
  }

  return (
    <div className={cx('wrapper')}>
      <div className={cx('name')} />
      <div className={cx('info')}>{t('cos.settings.tariffprices.resourceType')}</div>
      <div className={cx('info')}>{t('cos.settings.tariffprices.articleName')}</div>
      <div className={cx('info', 'price')}>
        <Tooltip
          text={t('cos.settings.tariffprices.price.tooltip') as string}
          position={['top center']}
        >
          {t('cos.settings.tariffprices.price')}, {currencySign}
        </Tooltip>
      </div>
      <div className={cx('info')}>{t('cos.settings.tariffprices.tariff')}</div>
    </div>
  );
};

export { TableHeader };
