import { FC, useCallback, useMemo } from 'react';

import { ConnectedCloudsDataDTO } from '@common/components/AppToolbar/components/CloudConnect/types';

import { useAppTranslation, usePopupControls } from '@src/common';
import { bindStyles } from '@src/utils';
import { Button, CloudItem, Icon, Popup } from '@src/kit';
import { CloudTypes } from '@src/@types';

import { StatusBadge } from '../StatusBadge';
import { InfoItem } from './InfoItem';
import styles from './CloudCard.module.scss';

type Props = {
  cloud: ConnectedCloudsDataDTO;
  onStart: (id: string) => void;
  onPause: (id: string) => void;
  onEdit?: (cloud: ConnectedCloudsDataDTO) => void;
  onDelete: (cloud: ConnectedCloudsDataDTO) => void;
  isUpdateActionButton?: boolean;
};

const cx = bindStyles(styles);

const CloudCard: FC<Props> = ({
  cloud,
  onEdit,
  onPause,
  onStart,
  onDelete,
  isUpdateActionButton,
}) => {
  const { openPopup, isOpened, closePopup } = usePopupControls();
  const { t } = useAppTranslation();
  const isActive = cloud.status === 'ACTIVE';

  const startText = useMemo(() => {
    if (isUpdateActionButton) {
      return t('hub.cloud.update');
    }

    if (isActive) {
      return t('hub.cloud.pause');
    }

    return t('hub.cloud.run');
  }, [isUpdateActionButton, isActive, t]);

  const startIcon = useMemo(() => {
    if (isUpdateActionButton) {
      return 'refresh';
    }

    if (isActive) {
      return 'pause';
    }

    return 'play';
  }, [isUpdateActionButton, isActive, t]);

  const handlePause = useCallback(() => {
    closePopup();
    onPause(cloud.id || '');
  }, []);

  const handleStart = useCallback(() => {
    closePopup();
    onStart(cloud.id || '');
  }, []);

  const handleAction = useCallback(() => {
    if (isActive && !isUpdateActionButton) {
      handlePause();
    } else {
      handleStart();
    }
  }, [handlePause, handleStart, isActive, isUpdateActionButton]);

  const handleEdit = useCallback(() => {
    closePopup();
    onEdit && onEdit(cloud);
  }, []);

  const handleDelete = useCallback(() => {
    closePopup();
    onDelete(cloud);
  }, []);

  return (
    <div className={cx('wrapper', { isPopupOpen: isOpened })}>
      <div className={cx('headerContainer')}>
        <div className={cx('status')}>
          <StatusBadge status={cloud.status} />
        </div>
        <Popup
          className={cx('buttons')}
          onClose={closePopup}
          open={isOpened}
          trigger={
            <Icon
              className={cx('trigger', { isActive: isOpened })}
              type="options-vertical"
              onClick={openPopup}
            />
          }
        >
          <Button
            className={cx('button')}
            variant="text"
            text={startText}
            onClick={handleAction}
            size="md"
          >
            <Icon type={startIcon} />
          </Button>
          <Button
            className={cx('button')}
            variant="text"
            text={t('hub.cloud.edit')}
            onClick={handleEdit}
            disabled={!onEdit}
            size="md"
          >
            <Icon type="edit" />
          </Button>
          <Button
            className={cx('button', { isDelete: true })}
            variant="text"
            onClick={handleDelete}
            isNegativeIcon
            text={t('hub.cloud.remove')}
            size="md"
          >
            <Icon type="delete" />
          </Button>
        </Popup>
      </div>
      <CloudItem
        className={cx('cloud')}
        cloudType={cloud.cloudType as CloudTypes}
        cloudName={cloud.name}
      />
      <div className={cx('infoContainer')}>
        <InfoItem
          icon="empty-calendar"
          value={cloud.createdAt}
          tooltipText={t('CreationDate')}
        />
        <InfoItem
          icon="update"
          value={cloud.updatedAt}
          tooltipText={t('cos.update.date')}
        />
        <InfoItem
          icon="coins"
          value={cloud.currency}
        />
        {cloud.id && (
          <InfoItem
            icon="id-tag"
            value={cloud.id}
          />
        )}
      </div>
    </div>
  );
};

export { CloudCard };
