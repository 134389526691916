import { useCallback, useState } from 'react';
import ReactDatePicker, { ReactDatePickerProps, registerLocale } from 'react-datepicker';

import ru from 'date-fns/locale/ru';
import en from 'date-fns/locale/en-GB';
import { DpHeader } from '@kit/components/DatePicker/components/DpHeader';
import { EN_MONTHS, RU_MONTHS } from '@kit/components/DatePicker/utils/constants';
import { useDpNavigation } from '@kit/components/DatePicker/hooks/useDpNavigation';
import { DpMonthSelection, DpYearSelection } from '@kit/components/DatePicker/components/DpScreens';
import * as dateFormats from '@kit/components/DatePicker/utils/date-formats';
import { Screens, ScreenView } from '@kit/components/DatePicker/types';

import { bindStyles } from '@src/utils';
import { Language } from '@src/@types';

import 'react-datepicker/dist/react-datepicker.min.css';
import styles from './Datepicker.module.scss';

registerLocale('en-US', en);
registerLocale('ru-RU', ru);

type DbValue<T> = T extends false | undefined ? string | null : [string | null, string | null];

type DatepickerProps<T extends boolean | undefined = undefined> = {
  period?: boolean;
  lang?: Language;
  currentDate?: string;
  onChangeCurrentDate?: (date: string) => void;
  value: DbValue<T>;
  onChange: (value: DbValue<T>) => void;
} & Pick<ReactDatePickerProps, 'minDate' | 'maxDate'>;

const cx = bindStyles(styles);

const DatePicker = <T extends boolean | undefined = undefined>(props: DatepickerProps<T>) => {
  const {
    lang = 'en-US',
    period = false,
    value,
    onChange,
    currentDate,
    onChangeCurrentDate,
    ...restDpProps
  } = props;

  const { screen, toNext } = useDpNavigation();

  const [localCurrentDate, setDpLocalCurrentDate] = useState(new Date());

  const monthsList = lang === 'en-US' ? EN_MONTHS : RU_MONTHS;
  const calendarStartDay = lang === 'en-US' ? 0 : 1;

  const dpDate = dateFormats.fromIso(currentDate) || localCurrentDate;

  const onSelect = useCallback(
    (screen: Screens) => (value: number) => {
      const newDate = new Date(dpDate);

      if (screen === 'year') {
        newDate.setFullYear(value);
        toNext('month');
      }
      if (screen === 'month') {
        newDate.setMonth(value);
        toNext('dp');
      }

      onChangeCurrentDate
        ? onChangeCurrentDate(dateFormats.toIso(newDate) as string)
        : setDpLocalCurrentDate(newDate);
    },
    [dpDate],
  );

  const onChangeMonth = (date: Date) => onChangeCurrentDate?.(dateFormats.toIso(date) as string);

  const onChangeDp = useCallback((value: Date | null | [Date | null, Date | null]) => {
    if (Array.isArray(value)) {
      const start = value[0] ? dateFormats.toIso(dateFormats.start(value[0] as Date)) : null;
      const end = value[1] ? dateFormats.toIso(dateFormats.end(value[1] as Date)) : null;

      onChange([start, end] as DbValue<T>);
    } else {
      onChange(dateFormats.toIso(dateFormats.start(value as Date)) as DbValue<T>);
    }
  }, []);

  const renderDayContents = useCallback(
    (dayOfMonth: number, date: Date) => {
      const currentValue = Array.isArray(value) ? null : value;

      const className = {
        day_selected: currentValue
          ? dateFormats.equals(dateFormats.fromIso(currentValue as string) as Date, date)
          : false,
      };

      return (
        <div className={cx('day', className)}>
          <span>{date?.getDate()}</span>
        </div>
      );
    },
    [value],
  );

  const View: ScreenView = {
    dp: (
      <ReactDatePicker
        {...restDpProps}
        openToDate={dpDate}
        startDate={dateFormats.fromIso(value ? value[0] : null)}
        endDate={dateFormats.fromIso(value ? value[1] : null)}
        inline
        disabledKeyboardNavigation
        locale={lang}
        calendarStartDay={calendarStartDay}
        selectsRange={period}
        onChange={onChangeDp}
        onMonthChange={onChangeMonth}
        renderDayContents={renderDayContents}
        renderCustomHeader={(props) => (
          <DpHeader
            {...props}
            monthsList={monthsList}
            onNavigate={toNext}
          />
        )}
      />
    ),
    year: (
      <DpYearSelection
        date={dpDate}
        onSelect={onSelect('year')}
      />
    ),
    month: (
      <DpMonthSelection
        date={dpDate}
        monthsList={monthsList}
        onSelect={onSelect('month')}
      />
    ),
  };

  return <div className={cx('dp', { dp_fix: screen !== 'dp' })}>{View[screen]}</div>;
};

export { DatePicker };
export type { DatepickerProps };
