import React, { forwardRef } from 'react';

import cn from 'classnames/bind';

import { Icon, Tooltip, TooltipPositions } from '@src/kit';

import styles from './Textarea.module.scss';

type TextareaProps = {
  caption?: string;
  description?: string;
  descriptionOnTop?: boolean;
  errorText?: string;
  isError?: boolean;
  hint?: string;
  tooltipClass?: string;
  tooltipTextClass?: string;
  hintPosition?: TooltipPositions;
};

type Props = Omit<React.ComponentPropsWithoutRef<'textarea'>, keyof TextareaProps> & TextareaProps;

const Textarea: React.FC<Props> = forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      caption,
      description,
      descriptionOnTop,
      hint,
      isError,
      errorText,
      disabled,
      className,
      hintPosition = 'top',
      tooltipClass,
      tooltipTextClass,
      ...rest
    },
    ref,
  ) => {
    const cx = cn.bind(styles);

    const descriptionNode = description && <div className={cx('description')}>{description}</div>;

    return (
      <label
        className={cx(className, 'body', { disabled })}
        data-testid="textarea"
      >
        <div className={cx('caption')}>
          {caption}
          {hint && (
            <Tooltip
              text={hint}
              place={hintPosition}
              classes={{
                tooltip: tooltipClass,
                tooltipTextItem: tooltipTextClass,
              }}
            >
              <Icon
                type="question-mark-circle"
                size="md"
                className={cx('icon', 'hint')}
              />
            </Tooltip>
          )}
        </div>

        {descriptionOnTop && descriptionNode}

        <textarea
          ref={ref}
          className={cx('field', { isError })}
          disabled={disabled}
          {...rest}
        />

        {isError && errorText && <div className={cx('error')}>{errorText}</div>}
        {!descriptionOnTop && descriptionNode}
      </label>
    );
  },
);

Textarea.displayName = 'Textarea';

export { Textarea };
export type { Props };
