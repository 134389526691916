import React from 'react';

import { Loader } from '@kit/components/Loader';

import { Size } from '@src/@types';
import { bindStyles } from '@src/utils';

import styles from './PageLoaderSpinner.module.scss';

const cx = bindStyles(styles);

type PageLoaderSpinnerProps = {
  size?: Size;
  overlayClassName?: string;
  loaderClassName?: string;
};

const PageLoaderSpinner: React.FC<PageLoaderSpinnerProps> = ({
  size = 'xl',
  overlayClassName,
  loaderClassName,
}) => {
  return (
    <div className={cx('overlay', overlayClassName)}>
      <Loader
        size={size}
        className={loaderClassName}
      />
    </div>
  );
};

export { PageLoaderSpinner };
export type { PageLoaderSpinnerProps };
