import React, { forwardRef, PropsWithChildren, SyntheticEvent } from 'react';

import { CmSelectComponents, CmSelectSize, CmSelectValue } from '@kit/components/CmSelect';
import { ValueContainer } from '@kit/components/CmSelect/ValueContainer';

import { Icon, Loader } from '@src/kit';
import { bindStyles } from '@src/utils';

import styles from './Selection.module.scss';

const cx = bindStyles(styles);

type Props = {
  size?: CmSelectSize;
  component?: CmSelectComponents['selectedValue'];
  placeholder?: string;
  error?: boolean;
  loading?: boolean;
  disabled?: boolean;
  value: CmSelectValue;
  onClick?: (event: SyntheticEvent) => void;
};

const SelectionComponent: React.FC<Props> = ({
  size = 'md',
  value,
  error,
  placeholder,
  component,
  disabled,
  loading,
  onClick,
}) => (
  <div className={cx(size)}>
    <div
      tabIndex={1}
      className={cx('selection', { error, disabled })}
      onClick={onClick}
    >
      <div className={cx('inner')}>
        <div className={cx('value')}>
          <ValueContainer
            value={value}
            disabled={disabled}
            placeholder={placeholder}
            component={component}
          />
        </div>
        <div className={cx('icons')}>
          {loading && (
            <div className={cx('loading')}>
              <Loader size="lg" />
            </div>
          )}
          <div className={cx('arrow')}>
            <Icon
              size="xl"
              type="chevron"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

const SelectionWrapper = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  ({ children, ...props }, ref) => {
    return (
      <div
        className={cx('wrapper')}
        ref={ref}
      >
        <SelectionComponent {...props} />
        {children}
      </div>
    );
  },
);

SelectionWrapper.displayName = 'SelectionWrapper';
const Selection = React.memo(SelectionWrapper);

Selection.displayName = 'Selection';

export { Selection };
