import { AvatarStyle } from '@src/kit';

const AVATAR_COLORS_SUBTLE: AvatarStyle[] = [
  {
    background: '#D7EDFF',
    color: '#0452C8',
  },
  {
    background: '#FED6CD',
    color: '#CC0905',
  },
  {
    background: '#BBFBD0',
    color: '#119C2B',
  },
  {
    background: '#FEF99F',
    color: '#D29404',
  },
  {
    background: '#F5D2FE',
    color: '#7E10E5',
  },
  {
    background: '#BBF6F4',
    color: '#0D9676',
  },
  {
    background: '#E5E9EB',
    color: '#252C32',
  },
];

const AVATAR_COLORS_FILLED: AvatarStyle[] = [
  {
    background: '#4094F7',
    color: '#F6FCFF',
  },
  {
    background: '#F76659',
    color: '#FFFBFA',
  },
  {
    background: '#47D16C',
    color: '#F5FFF8',
  },
  {
    background: '#F8DD4E',
    color: '#835101',
  },
  {
    background: '#C479F3',
    color: '#FEFAFF',
  },
  {
    background: '#38D9B9',
    color: '#F1FDFD',
  },
  {
    background: '#48535B',
    color: '#EEF0F2',
  },
];

export { AVATAR_COLORS_SUBTLE, AVATAR_COLORS_FILLED };
