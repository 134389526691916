import React from 'react';

import { bindStyles } from '@src/utils';
import { Checkbox } from '@src/kit';
import { useAppTranslation } from '@src/common';

import styles from './SelectAllCheckbox.module.scss';

const cx = bindStyles(styles);

type Props = {
  type: boolean | 'mixed';
  align?: 'left' | 'right';
  onClick: () => void;
};

const SelectAllCheckbox: React.FC<Props> = ({ type, align = 'left', onClick }) => {
  const { t } = useAppTranslation();

  return (
    <div
      className={cx('container', align)}
      onClick={onClick}
    >
      <div className={cx('cb')}>
        <Checkbox
          size="md"
          checked={type === true || type === 'mixed'}
          type={typeof type === 'boolean' ? 'mark' : 'mixed'}
        />
      </div>
      <span className={cx('label')}>
        {type === true ? t('cos.select.ResetAll') : t('SelectAll')}
      </span>
    </div>
  );
};

export { SelectAllCheckbox };
