import React from 'react';

import { Button } from '@kit/components';
import { CommonScreenProps } from '@kit/components/DatePicker/types';

import { bindStyles } from '@src/utils';

import styles from './DpScreens.module.scss';

type Props = CommonScreenProps;

const cx = bindStyles(styles);

const currentYear = new Date().getFullYear();
const YEARS = new Array(4)
  .fill(0)
  .map((_, index) => currentYear - index)
  .sort();

const DpYearSelectionComponents: React.FC<Props> = ({ date, onSelect }) => {
  const onClick = (year: number) => () => onSelect(year);

  return (
    <div className={cx('list')}>
      {YEARS.map((year) => (
        <div
          key={year}
          className={cx('button-year')}
        >
          <Button
            key={year}
            variant={year === date.getFullYear() ? 'filled' : 'text'}
            size="sm"
            text={year.toString()}
            onClick={onClick(year)}
          />
        </div>
      ))}
    </div>
  );
};

const DpYearSelection = React.memo(DpYearSelectionComponents);

export { DpYearSelection };
