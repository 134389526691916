import { FC } from 'react';

import { useAppToolbarContext, useAppTranslation } from '@src/common';
import { bindStyles } from '@src/utils';
import { CMModalFooter } from '@src/kit';

import styles from './ConnectedCloudsFooter.module.scss';

const cx = bindStyles(styles);

const ConnectedCloudsFooter: FC = () => {
  const { t } = useAppTranslation();

  const { supportEmail } = useAppToolbarContext();

  return (
    <CMModalFooter>
      <div className={cx('text')}>
        {t('cos.active.connections.footer.text')}{' '}
        <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
      </div>
    </CMModalFooter>
  );
};

export { ConnectedCloudsFooter };
