import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Form, FormInput, FormSelect, required } from '@common/components/Forms';
import {
  AWSConnectionFormDTO,
  AWSRegion,
  Stages,
} from '@common/components/AppToolbar/components/CloudConnect/types';
import {
  fetchAWSRegions,
  getAWSCloud,
  updateAWS,
} from '@common/components/AppToolbar/api/cloud-connections';
import { AxiosError } from 'axios';
import { CloudEditLoading } from '@common/components/AppToolbar/components/CloudConnect/CloudEdit/CloudEditLoading';

import {
  useAnalytics,
  useAppTranslation,
  useCloudConnectContext,
  useFormApiError,
  useToast,
} from '@src/common';
import { bindStyles, mapToOption } from '@src/utils';
import { ACCESS_KEY, CLOUD_NAME_DICTIONARY, CLOUDNAME, KEYSECRET, REGION } from '@src/constants';
import { SearchMenuList, SelectOption, ValueWithLabel } from '@src/kit';

import styles from './AWSEdit.module.scss';

const cx = bindStyles(styles);

const AWSEdit: FC = () => {
  const { t } = useAppTranslation();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [initLoading, setInitLoading] = useState(true);
  const { apiErrors, handleError } = useFormApiError();
  const [isRegionsLoading, setIsRegionsLoading] = useState(false);

  const [regions, setRegions] = useState<AWSRegion[]>([]);
  const { logSuccessEvent, logErrorEvent } = useAnalytics();

  const {
    editableCloud,
    selectStage,
    onChangeTitle,
    changeDisagreeButtonSettings,
    changeAgreeButtonSettings,
  } = useCloudConnectContext();

  const form = useForm<AWSConnectionFormDTO>({
    defaultValues: {
      cloudName: editableCloud?.name,
    },
  });

  const listRegions: SelectOption<string>[] = useMemo(
    () => regions.map(({ name, code }) => mapToOption(code, name)),
    [regions],
  );

  const fetchFormDTO = useCallback(async () => {
    try {
      if (editableCloud?.id) {
        setInitLoading(true);
        const response = await getAWSCloud(editableCloud?.id);
        const region = listRegions.find((reg) => {
          return reg.value === response.data.region;
        });
        form.setValue('accessKey', response.data.accessKey);
        form.setValue('cloudName', response.data.cloudName);
        if (region) {
          form.setValue('region', region);
        }
      }
    } catch (e) {
      toast.error({
        text: `${t(`FailedEditCloud`)}`,
        hasClose: true,
      });
    } finally {
      setInitLoading(false);
    }
  }, [listRegions, editableCloud?.id]);

  const onSubmit = useCallback(
    async (dto: AWSConnectionFormDTO) => {
      try {
        if (editableCloud?.id) {
          setIsLoading(true);
          await updateAWS(editableCloud.id, {
            region: dto.region.value,
            accessKey: dto.accessKey,
            cloudName: dto.cloudName,
            secretKey: dto.secretKey,
          });
          selectStage({
            stage: Stages.connectedClouds,
          });
          toast.success({
            text: `${t('CloudConnectedSuccessfully', { cloud: CLOUD_NAME_DICTIONARY.AWS })}`,
          });
          logSuccessEvent('Edited cloud connection', {
            id: editableCloud.id,
            cloudType: CLOUD_NAME_DICTIONARY.AWS,
          });
        }
      } catch (error) {
        handleError(error as AxiosError);
        toast.error({
          text: `${t(`FailedEditCloud`)}`,
          hasClose: true,
        });
        logErrorEvent('Edited cloud connection', {
          id: editableCloud?.id,
          cloudType: CLOUD_NAME_DICTIONARY.AWS,
        });
      } finally {
        setIsLoading(false);
      }
    },
    [editableCloud, t],
  );

  const onBack = useCallback(() => {
    selectStage({
      stage: Stages.connectedClouds,
    });
  }, []);

  const fetchRegions = async () => {
    try {
      setIsRegionsLoading(true);
      const response = await fetchAWSRegions();
      setRegions(response.data);
    } catch (err) {
      console.error(err);
    } finally {
      setIsRegionsLoading(false);
    }
  };

  const region = form.watch('region');

  const handleOnChange = (e: SelectOption<string>) => {
    form.setValue('region', e);
  };

  const onMenuOpen = useCallback(() => {
    const target = document.querySelector('#aws-region > div:nth-child(3)');
    target?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  useEffect(() => {
    onChangeTitle(t('cos.cloudConnect.edit.azure.title'));
    changeDisagreeButtonSettings({
      onClick: onBack,
    });
  }, []);

  useEffect(() => {
    changeAgreeButtonSettings({
      onClick: form.handleSubmit(onSubmit),
      text: t('cos.cloudConnect.edit.save'),
      isLoading: isLoading || initLoading,
    });
  }, [isLoading, onSubmit, initLoading]);

  useEffect(() => {
    fetchRegions();
  }, []);

  useEffect(() => {
    if (listRegions.length) {
      fetchFormDTO();
    }
  }, [listRegions]);

  if (initLoading) {
    return <CloudEditLoading />;
  }
  return (
    <Form
      formMethods={form}
      apiErrors={apiErrors}
    >
      <div className={cx('connectionBlock')}>
        <FormInput
          placeholder={t('nameCloud')}
          name={CLOUDNAME}
          caption={t('cloudName')}
          hint={t('AWSHintCloudName')}
          rules={{ ...required('FieldRequired') }}
        />
      </div>
      <div className={cx('connectionBlock')}>
        <FormInput
          placeholder={t('AWSKeyIDPlaceholder')}
          name={ACCESS_KEY}
          caption={t('AccessKey')}
          hint={t('AWSHintAccessKey')}
          rules={{ ...required('FieldRequired') }}
        />
      </div>
      <div className={cx('connectionBlock')}>
        <FormInput
          placeholder={t('AWSSecretKeyPlaceholder')}
          name={KEYSECRET}
          caption={t('SecretKey')}
          hint={t('AWSHintSecretKey')}
        />
      </div>
      <div className={cx('connectionBlock')}>
        <FormSelect
          id="aws-region"
          value={region}
          placeholder={t('AWSRegionPlaceholder')}
          currentSelection={region?.value}
          name={REGION}
          hint={t('AWSHintRegion')}
          className={styles.formSelect}
          caption={t('Region')}
          size="lg"
          onChange={handleOnChange}
          isSearchable={false}
          options={listRegions}
          rules={{ ...required('FieldRequired') }}
          menuPlacement="bottom"
          descriptionOnTop={false}
          isLoading={isRegionsLoading}
          components={{
            MenuList: (props: any) => {
              useEffect(() => {
                onMenuOpen();
              }, []);

              return <SearchMenuList {...props} />;
            },
            Option: ValueWithLabel,
          }}
          isDisabled
        />
      </div>
    </Form>
  );
};

export { AWSEdit };
