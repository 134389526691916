import React from 'react';

import cn from 'classnames/bind';
import { DialogProps, DialogTypes } from '@hooks/useDialog';
import { Button } from '@kit/components/Button';
import { Icon } from '@kit/components/Icon';
import { IconTypes } from '@kit/components/Icon/IconDictionary';
import { ModalTitle } from '@kit/components/Modal/ModalTitle';

import styles from './Dialog.module.scss';

const dialogIcons: Record<DialogTypes, IconTypes> = {
  answer: 'circle-warning',
  notification: 'circle-information',
  warning: 'warning',
  info: 'circle-information',
};

type Props = DialogProps & {
  closeHandler: () => void;
};

const cx = cn.bind(styles);

const Dialog: React.FC<Props> = ({
  loading,
  className,
  title,
  type,
  closeHandler,
  onConfirm,
  onCancel,
  onOk,
  actions,
  children,
}) => (
  <div className={cx(className, 'container')}>
    <div
      className={cx('close')}
      onClick={closeHandler}
    >
      <Icon
        type="close"
        data-testid="dialogCloseIcon"
      />
    </div>
    <div className={cx('body')}>
      {type && (
        <div className={cx('icon', type)}>
          <Icon type={dialogIcons[type]} />
        </div>
      )}
      {title && <ModalTitle title={title} />}
      <div className={cx('contentWrapper')}>{children}</div>
    </div>
    <div className={cx('buttons')}>
      {onConfirm ? (
        <>
          <Button
            id="ConfirmDialogCancel"
            className={cx('button')}
            variant="outline"
            onClick={onCancel}
            disabled={loading}
            {...actions?.cancel}
          />
          <Button
            className={cx('button')}
            onClick={onConfirm}
            isLoading={loading}
            disabled={loading}
            {...actions?.submit}
          />
        </>
      ) : (
        <Button
          className={cx('button')}
          onClick={onOk}
          isLoading={loading}
          {...actions?.ok}
        />
      )}
    </div>
  </div>
);

export { Dialog };
