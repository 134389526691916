import React, { useEffect, useState } from 'react';
import { useFieldArray } from 'react-hook-form';

import { Size } from '@src/@types';
import { bindStyles } from '@src/utils';
import { useAppFormContext } from '@src/common';
import { Button, Icon } from '@src/kit';

import styles from './FieldArray.module.scss';
import { FieldArrayItem } from './FieldArrayItem';

const cx = bindStyles(styles);

//TODO: сделать тип для проверки полей формы
// type FormField = JSX.Element;

type Props<T> = {
  name: string;
  renderFields: (index: number, prefix: string, initial?: boolean) => JSX.Element;
  appendValue?: Partial<T>;
  appendText: string;
  size?: Size;
  required?: boolean;
  fixedInitial?: boolean;
  maxFieldsCount?: number;
  disableButton?: boolean;
};

const FieldArray = <T,>(props: React.PropsWithChildren<Props<T>>): JSX.Element => {
  const {
    name,
    renderFields,
    appendValue = {},
    appendText,
    size = 'md',
    required,
    fixedInitial,
    maxFieldsCount,
    disableButton,
  } = props;
  const { control } = useAppFormContext();
  const { fields, append, remove } = useFieldArray({ control, name });
  const [initialList, setInitialList] = useState<string[]>([]);
  const [needUpdateList, setNeedUpdateList] = useState(false);
  const [manual, setManual] = useState(false);

  const checkDelete = (index: number, isInitial: boolean) => {
    if (fields.length === 1 && required) {
      return undefined;
    }
    if (isInitial && fixedInitial) {
      return undefined;
    }
    return () => {
      setManual(true);
      remove(index);
    };
  };

  const manualAppend = () => {
    setManual(true);
    append(appendValue, { shouldFocus: false });
  };

  useEffect(() => {
    if (fields.length === 0 && required) {
      manualAppend();
      return;
    }
    if (manual) {
      setManual(false);
      return;
    }
    setInitialList([]);
    setNeedUpdateList(Boolean(fields.length));
  }, [fields]);

  useEffect(() => {
    if (needUpdateList) {
      setInitialList(fields.map((field) => field.id));
      setNeedUpdateList(false);
    }
  }, [needUpdateList]);

  const appendButtonVisible = maxFieldsCount ? fields.length < maxFieldsCount : true;

  return (
    <div>
      <ul className={cx('selectorsList')}>
        {fields.map((field, index) => {
          const isInitial = initialList.includes(field.id);
          return (
            <FieldArrayItem
              key={field.id}
              onDelete={checkDelete(index, isInitial)}
              size={size}
            >
              {renderFields(index, name, isInitial)}
            </FieldArrayItem>
          );
        })}
      </ul>

      {appendButtonVisible && (
        <div className={cx('appendButton')}>
          <Button
            onClick={manualAppend}
            size="md"
            text={appendText}
            variant="text"
            disabled={disableButton}
            textClassName={cx('appendButtonText')}
          >
            <Icon
              type="add"
              size="md"
              className={cx('addIcon')}
            />
          </Button>
        </div>
      )}
    </div>
  );
};

export { FieldArray };
