import { FC, useState } from 'react';

import { ConnectedCloudsDataDTO } from '@common/components/AppToolbar/components/CloudConnect/types';

import { CloudsContext } from './CloudsContext';

type Props = {
  onNavigate?: (to: 'tariffs') => void;
};

const CloudsProvider: FC<Props> = ({ children, onNavigate }) => {
  const [isNewConnection, setIsNewConnection] = useState(false);
  const [editableCloud, setEditableCloud] = useState<ConnectedCloudsDataDTO | null>(null);
  const [open, setOpen] = useState(false);
  const [cloudEdit, setCloudEdit] = useState(false);

  const openConnection = () => {
    setIsNewConnection(true);
  };

  const closeConnection = () => {
    setIsNewConnection(false);
  };

  return (
    <CloudsContext.Provider
      value={{
        isNewConnection,
        openConnection,
        editableCloud,
        changeEditableCloud: setEditableCloud,
        closeConnection,
        open,
        onOpen: setOpen,
        cloudEdit,
        setCloudEdit,
        onNavigate,
      }}
    >
      {children}
    </CloudsContext.Provider>
  );
};

export { CloudsProvider };
