import React from 'react';

import styles from './ToastText.module.scss';

type Props = {
  text?: string;
};

const ToastText: React.FC<Props> = ({ text }) => {
  return <p className={styles.toastText}>{text}</p>;
};

export { ToastText };
