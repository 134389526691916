import React from 'react';

import { useAppTranslation } from '@src/common';
import { bindStyles } from '@src/utils';
import { Brand } from '@src/@types';
import { Tooltip } from '@src/kit';

import InferitHeaderLogo from '@assets/images/Logo-Inferit.svg';
import AstrokiteSVG from '@assets/images/Astrokite.svg';
import AstrokiteLogoSVG from '@assets/images/LogoAstrokite.svg';

import styles from './Logo.module.scss';

const cx = bindStyles(styles);

type Props = {
  brand?: Brand;
};

const Logo: React.FC<Props> = ({ brand }) => {
  const { t } = useAppTranslation();

  return (
    <Tooltip
      place="bottom"
      text={t('ReturnToMainPanel') as string}
    >
      <a
        href="/"
        className={cx('logo-link')}
      >
        <span className={cx('logo-icon', { astrokite: brand === 'astrokite' })}>
          {brand === 'cloudmaster' ? (
            <InferitHeaderLogo />
          ) : (
            <div className={cx('logo-astrokite')}>
              <AstrokiteLogoSVG />
              <AstrokiteSVG />
            </div>
          )}
        </span>
      </a>
    </Tooltip>
  );
};

export { Logo };
