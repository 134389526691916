import { FC, useMemo } from 'react';

import { useNewConnectionContext } from '@common/components/Clouds/components/NewConnection/NewConnectionContext';
import { useCloudsContext } from '@common/providers/CloudsProvider';
import { ConnectionSteps } from '@common/components/Clouds/components';

import { bindStyles } from '@src/utils';
import { CloudTypes } from '@src/@types';
import { Button, Icon, Loader } from '@src/kit';
import { useAppTranslation } from '@src/common';
import { CLOUD_ICON_DICTIONARY } from '@src/constants';

import styles from './StatusInfo.module.scss';

const cx = bindStyles(styles);

enum StatusInfoVariant {
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
  VCLOUD_RESOURCES = 'vcloud_resources',
}

type Props = {
  variant: StatusInfoVariant;
  cloudType: CloudTypes;
  cloudName?: string;
};

const StatusInfo: FC<Props> = ({ variant, cloudType, cloudName, children }) => {
  const { t } = useAppTranslation();
  const { setSelectedProvider, setCurrentStep, onBack } = useNewConnectionContext();
  const { closeConnection, setCloudEdit, changeEditableCloud } = useCloudsContext();
  const onReadyClick = () => {
    setSelectedProvider(null);
    closeConnection();
    setCloudEdit(false);
    changeEditableCloud(null);
    setCurrentStep(ConnectionSteps.PROVIDER);
  };
  const onMoreClick = () => {
    setSelectedProvider(null);
    setCloudEdit(false);
    changeEditableCloud(null);
    setCurrentStep(ConnectionSteps.PROVIDER);
  };
  const renderedStatus = useMemo(() => {
    switch (variant) {
      case StatusInfoVariant.ERROR:
        return (
          <div className={cx('status-wrapper')}>
            <div className={cx('status-block', 'error')}>
              <Icon
                type="circle-warning"
                className={cx('error')}
              />
            </div>
            <div className={cx('status-header')}>
              {t('clouds.connection.status.connectionError')}
            </div>
            <div className={cx('status-cloud')}>
              <div className={cx('status-cloud-icon')}>{CLOUD_ICON_DICTIONARY[cloudType]}</div>
              <div className={cx('status-cloud-text')}>{cloudName}</div>
            </div>
            <div className={cx('status-description')}>
              {t('clouds.connection.status.connectionError.description')}
            </div>
            <div className={cx('status-buttons', 'one')}>
              <Button
                text={t('clouds.connection.status.connectionError.button')}
                onClick={onBack}
              />
            </div>
          </div>
        );
      case StatusInfoVariant.LOADING:
        return (
          <div className={cx('status-wrapper')}>
            <div className={cx('status-circle', 'loading')}>
              <Loader className={cx('loading')} />
            </div>
            <div className={cx('status-header')}>
              {t('clouds.connection.status.connectionLoading')}
            </div>
            <div className={cx('status-cloud')}>
              <div className={cx('status-cloud-icon')}>{CLOUD_ICON_DICTIONARY[cloudType]}</div>
              <div className={cx('status-cloud-text')}>{cloudName}</div>
            </div>
            <div className={cx('status-description')}>
              {t('clouds.connection.status.connectionLoading.description')}
            </div>
            <div className={cx('status-buttons')}>
              <Button
                text={t('clouds.connection.status.button.more')}
                variant="outline"
                onClick={onMoreClick}
              />
              <Button
                text={t('cos.common.ready')}
                onClick={onReadyClick}
              />
            </div>
          </div>
        );
      case StatusInfoVariant.SUCCESS:
        return (
          <div className={cx('status-wrapper')}>
            <div className={cx('status-circle', 'success')}>
              <Icon
                type="circle-check"
                className={cx('success')}
              />
            </div>
            <div className={cx('status-header')}>
              {t('clouds.connection.status.connectionSuccess')}
            </div>
            <div className={cx('status-cloud')}>
              <div className={cx('status-cloud-icon')}>{CLOUD_ICON_DICTIONARY[cloudType]}</div>
              <div className={cx('status-cloud-text')}>{cloudName}</div>
            </div>
            <div className={cx('status-description')}>
              {t('clouds.connection.status.connectionSuccess.cloudName', { cloudName: cloudName })}
            </div>
            <div className={cx('status-buttons')}>
              <Button
                text={t('clouds.connection.status.button.more')}
                variant="outline"
                onClick={onMoreClick}
              />
              <Button
                text={t('cos.common.ready')}
                onClick={onReadyClick}
              />
            </div>
          </div>
        );
      case StatusInfoVariant.VCLOUD_RESOURCES:
        return children;
    }
  }, [variant, children]);

  return (
    <div className={cx('status', { vCloud: variant === StatusInfoVariant.VCLOUD_RESOURCES })}>
      {renderedStatus}
    </div>
  );
};

export { StatusInfo, StatusInfoVariant };
