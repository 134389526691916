import { AxiosError } from 'axios';

import { ApiError, Gb, Mb, Undefinedable, ValidationError } from '@src/@types';
import { SelectOption } from '@src/kit';
import { AppSystem, NotificationTechData } from '@src/common';

type UnknownObject = Undefinedable<Record<string, unknown>>;

const THOUSAND_SEPARATOR_REGEXP = /\B(?=(\d{3})+(?!\d))/g;

const mapToString = (value: string | number) => value.toString();
const mapToNumber = (value: string | number) => +value;

const mapFromOptions = <T>(options: SelectOption<T>[] = []): T[] => {
  return options.map(({ value }) => value);
};

const numberSpacing = (number: number | string): string => {
  return number.toString().replace(THOUSAND_SEPARATOR_REGEXP, ' ');
};

const floatNumberSpacing = (number: number | string): string => {
  const parts = number.toString().split('.');
  parts[0] = parts[0].replace(THOUSAND_SEPARATOR_REGEXP, ' ');
  return parts.join('.');
};

const percentsOverrun = (number: number): number => {
  return number <= 100 ? number : parseInt(number?.toString()?.slice(-2));
};

const serializeObject = <T extends Record<string, unknown>>(object: T): Record<string, string> => {
  return Object.entries(object).reduce((result, [key, value]) => {
    if (value !== undefined) {
      result[key] = JSON.stringify(value);
    }

    return result;
  }, {} as Record<string, string>);
};

const capitalizeFirstLetter = (value: string): string => {
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
};

const getNameAvatar = (firstName: string, lastName: string, isOnlyLastName: boolean): string => {
  return isOnlyLastName ? lastName.trim()?.[0] : `${lastName.trim()?.[0]}${firstName.trim()?.[0]}`;
};

const getRadixTransformation = (char: string, interval: number) => parseInt(char, 36) % interval;

const insertZero = (value: string | number) => {
  const res = String(value);
  return res.length > 1 ? res : `0${res}`;
};

const gettingFilledObjectsValues = <T>(obj: T) => {
  return Object.values(obj).reduce((count, item) => count + Number(Boolean(item.length)), 0);
};

const formFullName = (
  firstName: string | undefined | null,
  lastName: string | undefined | null,
  shortName = false,
) => {
  const trimmedLastName = lastName?.trim();
  const trimmedFirstName = firstName?.trim();

  const newLastName = trimmedLastName || '';
  const newFirstName = () => {
    if (shortName) {
      return trimmedFirstName ? trimmedFirstName[0] : '';
    }
    return trimmedFirstName;
  };

  return `${newLastName} ${newFirstName()}`;
};

const makeId = (length = 5) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const trimObjectStringValues = <T extends object>(object: T): T => {
  const trimmed: T = {
    ...object,
  };

  Object.keys(object).forEach((key) => {
    if (typeof object[key as keyof T] === 'string') {
      trimmed[key as keyof T] = String(object[key as keyof T]).trim() as unknown as T[keyof T];
    } else {
      console.warn('You tried to trim not string value');
      trimmed[key as keyof T] = object[key as keyof T];
    }
  });

  return trimmed;
};

const checkOnlySpaces = (value: string): boolean => /^\s*$/.test(value);

const mbToGb = (value: Mb | number) => (value / 1024) as Gb;
const mapToGb = (value: Mb | number = 0) => +(+mbToGb(value as Mb)).toFixed(3);
const gbToMb = (value: Gb) => (value * 1024) as Mb;

const getByPath = <T>(object: UnknownObject, path: string): Undefinedable<T> => {
  const initialObject: UnknownObject = object;
  const getPath = (result: UnknownObject, pathItem: string) => result?.[pathItem] as UnknownObject;

  return path.split('.').reduce(getPath, initialObject) as Undefinedable<T>;
};

const isApiValidationError = (error: unknown): error is AxiosError<ValidationError> => {
  return !!(error as AxiosError<ValidationError>)?.response?.data?.validationErrors;
};

const isApiError = (error: unknown): error is AxiosError<ApiError> => {
  return !!(error as AxiosError<ApiError>)?.response?.data;
};

const mapToOption = <T, M = string>(
  value: T,
  label: string | number,
  meta?: M,
): SelectOption<T, M> => {
  return { label, value, meta };
};

const appNavigate = (path: string) => (window.location.href = `${window.location.origin}${path}`);

const groupBy = (array: any[], key: string) => {
  return array.reduce((result, currentItem) => {
    const field = currentItem[key];
    if (!field) {
      return result;
    }

    const values = result[field] ?? [];
    result[field] = values.concat([currentItem]);
    return result;
  }, {});
};

const getYearList = (from: number): number[] => {
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let i = from; i <= currentYear; i++) {
    years.push(i);
  }

  return years;
};

const isShowTechNotification = (techNotification?: NotificationTechData, system?: AppSystem) => {
  if (
    !techNotification ||
    (!(techNotification.coster && techNotification.clouder) &&
      ((techNotification.clouder && system !== 'clouder') ||
        (techNotification.coster && system !== 'coster')))
  ) {
    return false;
  }

  const now = new Date();
  const end = new Date(techNotification.end);

  return now < end;
};

const getDateTimeStringFormat = (dateString: string) => {
  const date = new Date(dateString);
  const dateFormatted = `${insertZero(date.getDate())}.${insertZero(
    date.getMonth() + 1,
  )}.${date.getFullYear()}`;
  const timeFormatted = `${insertZero(date.getHours())}:${insertZero(date.getMinutes())}`;
  return { dateFormatted, timeFormatted };
};

const formatDateZ = (iso: string): string => iso.split('+')[0].concat('Z');

export {
  isApiValidationError,
  appNavigate,
  isApiError,
  numberSpacing,
  percentsOverrun,
  floatNumberSpacing,
  mapToOption,
  mapFromOptions,
  serializeObject,
  getByPath,
  capitalizeFirstLetter,
  getNameAvatar,
  mapToNumber,
  mapToString,
  gettingFilledObjectsValues,
  mbToGb,
  gbToMb,
  mapToGb,
  formFullName,
  trimObjectStringValues,
  insertZero,
  getYearList,
  makeId,
  groupBy,
  checkOnlySpaces,
  getRadixTransformation,
  isShowTechNotification,
  getDateTimeStringFormat,
  formatDateZ,
};
