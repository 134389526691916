import { FC, ReactNode } from 'react';

import cn from 'classnames/bind';
import { ButtonLink, Card, Tooltip, Icon, Button } from '@kit/components';
import { TaskId } from '@common/api/Notification/models';

import { NotificationId, useAppTranslation } from '@src/common';

import styles from './NotificationCard.module.scss';

type NotificationColor = 'red' | 'yellow' | 'white' | 'purple' | 'green';
type NotificationLink = {
  name: string;
  url: string;
};

type NotificationCardPayload = {
  id: NotificationId;
  message: string;
  showed: boolean;
  source: string;
  date: string;
  time: string;
  color?: NotificationColor;
  links?: NotificationLink[];
  author?: string;
  avatar?: string;
  tooltipText?: string;
  linkButtonText?: string;
  WatchElement?: ReactNode;
  onActionClick?: () => void;
  onLinkClick?: () => void;
  taskId?: TaskId;
};

type Props = NotificationCardPayload & {
  handleEventDetail: (id: TaskId) => void;
};

const NotificationCard: FC<Props> = ({
  id,
  color = 'white',
  message = '',
  date = '',
  time = '',
  showed,
  onActionClick,
  onLinkClick,
  links,
  tooltipText,
  linkButtonText,
  WatchElement,
  handleEventDetail,
  taskId,
}) => {
  const cx = cn.bind(styles);
  const { t } = useAppTranslation();

  const [title, text] = message.split('\n');

  return (
    <Card className={cx('container')}>
      <div className={cx('colorMark', [`colorMark_${color}`])} />
      <div className={cx('header')}>
        <div className={cx('id')}>№ #{id}</div>
        <div>
          {WatchElement ? (
            <div>{WatchElement}</div>
          ) : (
            <div className={cx('dateContainer')}>
              <Icon
                type="empty-calendar"
                size="md"
                className={cx('timeIcon')}
              />
              <div className={cx('date')}>{date}</div>
              <Icon
                type="clock"
                size="md"
                className={cx('timeIcon')}
              />
              <div className={cx('time')}>{time}</div>
              <div className={cx('tooltip-wrapper', { showed })}>
                <Tooltip
                  place="left"
                  text={tooltipText || ''}
                >
                  <button
                    type="button"
                    onClick={onActionClick}
                    className={cx('statusIndicator', { showed })}
                  >
                    <div className={cx('statusIndicatorInner')} />
                  </button>
                </Tooltip>
              </div>
            </div>
          )}
        </div>
      </div>
      <div>
        <p className={cx('title')}>{title}</p>
        {text && <p className={cx('text')}>{text}</p>}
      </div>

      <div className={cx('buttons')}>
        {links &&
          links.map(({ url }) => (
            <ButtonLink
              data-testid="goToButton"
              key={url}
              to={url}
              onClick={onLinkClick}
              className={cx('button')}
              size="sm"
              text={linkButtonText}
            />
          ))}
        {taskId && (
          <Button
            text={t('LearnMore')}
            size="sm"
            variant="outline"
            onClick={() => handleEventDetail(taskId)}
          />
        )}
      </div>
    </Card>
  );
};
export { NotificationCard };
export type { NotificationCardPayload };
