import { PopupControls, usePopupControls } from '@src/common';

const usePopupAppToolbar = (value = false): PopupControls => {
  const popupControls = usePopupControls(value);

  const closePopup = () => popupControls.isOpened && popupControls.closePopup();
  const openPopup = () => !popupControls.isOpened && popupControls.openPopup();

  return {
    isOpened: popupControls.isOpened,
    openPopup,
    closePopup,
    togglePopup: popupControls.togglePopup,
  };
};

export { usePopupAppToolbar };
