import { FC } from 'react';

import { Icon } from '@kit/components';

import { bindStyles } from '@src/utils';

import { CMModalHeader } from '../CMModalHeader';
import styles from './Header.module.scss';

type CMHeaderProps = {
  title: string;
  headerClassName?: string;
  onClose?: () => void;
};

const cx = bindStyles(styles);

const Header: FC<CMHeaderProps> = ({ title, headerClassName, onClose }) => {
  return (
    <CMModalHeader className={headerClassName}>
      <div className={cx('title')}>{title}</div>
      {onClose && (
        <Icon
          className={cx('close')}
          type="close"
          onClick={onClose}
        />
      )}
    </CMModalHeader>
  );
};

export { Header };
export type { CMHeaderProps };
