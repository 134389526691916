import { PropsWithChildren } from 'react';
import { HeaderProps } from 'react-table';

import cn from 'classnames/bind';
import { Icon } from '@kit/components/Icon';
import { TextShorter } from '@kit/components/TextShorter';
import { Tooltip } from '@kit/components/Tooltip/Tooltip';

import { SortDirections } from '@src/@types';

import styles from './HeaderCell.module.scss';

type SortBy<T> = {
  key: keyof T;
  direction: SortDirections;
};

type Props<T extends Record<string, unknown>> = PropsWithChildren<HeaderProps<T>> & {
  className?: string;
  tooltipText?: string;
  canSort?: boolean;
  onChangeSort?: (key: keyof T, direction: SortDirections) => void;
  getCurrentSort?: () => SortBy<T> | undefined;
};

const cx = cn.bind(styles);

const HeaderCell = <T extends Record<string, unknown>>({
  column,
  onChangeSort,
  children,
  className,
  canSort,
  getCurrentSort,
  tooltipText,
}: Props<T>) => {
  const DEFAULT_SORT_DIRECTION = 'desc';
  const INITIAL_SORTING_RULE = {
    key: column.id,
    direction: DEFAULT_SORT_DIRECTION,
  };

  const currentSort = getCurrentSort && getCurrentSort();
  // Set sorting rule by column id and set initial for other columns
  const sortingRule = currentSort?.key === column.id ? currentSort : INITIAL_SORTING_RULE;

  const isSortedDesc = sortingRule.direction === 'desc';
  const iconType = isSortedDesc ? 'arrow-down' : 'arrow-up';
  const cellClassName = cx(className, 'container', { canSort });

  const onClickCell = () => {
    if (!canSort || !onChangeSort) return;

    const newDirection = isSortedDesc ? 'asc' : 'desc';
    onChangeSort(column.id, newDirection);
  };

  return (
    <div
      className={cellClassName}
      onClick={onClickCell}
    >
      <TextShorter tooltip>{children}</TextShorter>

      {tooltipText && (
        <Tooltip
          place="top"
          text={tooltipText}
        >
          <Icon
            className={cx('infoIcon')}
            type="circle-information-filled"
            size="md"
          />
        </Tooltip>
      )}

      {canSort && (
        <Icon
          className={cx({ sortIndicator: true })}
          type={iconType}
          size="md"
        />
      )}
    </div>
  );
};

export { HeaderCell };
