import React, {useEffect, useRef} from 'react';

import cn from 'classnames/bind';

import styles from './FilterFormContainer.module.scss';
import {useStyleVariables} from "@hooks/useStyleVariables";

const cx = cn.bind(styles);

type Props = {
  className?: string;
  controls?: React.ReactNode | React.ReactNode[];
  controlsClassName?: string;
  filterItemMinWidth?: number;
};

const FilterFormContainer: React.FC<Props> = ({
  children,
  className,
  controls = [],
  controlsClassName,
  filterItemMinWidth = 231, //INFO: размер стандартных контролов на русском
}) => {
  const ref = useStyleVariables([
    { value: `${filterItemMinWidth}px`, name: '--min-width' }
  ]);

  return (
    <div
      className={cx('container', className)}
      ref={ref}
    >
      <>{children}</>
      <div className={cx('controls', controlsClassName)}>{controls}</div>
    </div>
  );
};

export { FilterFormContainer };
