import React, { useContext } from 'react';

import { Brand, Language } from '@src/@types';
import { AppSystem, NotificationTechData, UserDto } from '@src/common';

type AppToolbarContextParams = {
  user: UserDto | null;
  language: Language;
  onChangeUser: (user: Partial<UserDto>) => void;
  onChangeLang: (lang: Language) => void;
  onLogout: () => void;
  techNotification?: NotificationTechData;
  brand: Brand;
  supportEmail: string;
  system?: AppSystem;
};

const AppToolbarContext: React.Context<AppToolbarContextParams> =
  React.createContext<AppToolbarContextParams>({} as AppToolbarContextParams);

const useAppToolbarContext = () => useContext<AppToolbarContextParams>(AppToolbarContext);

export { AppToolbarContext, useAppToolbarContext };
export type { AppToolbarContextParams };
