import { VDCResourcesDTO } from '@common/components/Clouds/api/vCloudDirector/models';

import { IRestService, RestService } from '@src/common';

const restService: IRestService = RestService.getInstance();

const getVCloudResources = (cloudId: string) => {
  const url = `/settings/api/v2/connection/vcloud/vdc_info/${cloudId}`;
  return restService.GET<VDCResourcesDTO[]>(url);
};

export { getVCloudResources };
