import crypto from 'crypto';

import { Brand } from '@src/@types';

declare global {
  interface Window {
    dclsPxl: (type: string, config: Record<string, string | object | boolean>) => void;
  }
}

interface IDucalisConfig {
  appId: string;
  boardId: string;
  user: {
    email: string;
    userID: string;
    name: string;
  };
  autoSubscribe?: boolean;
  [key: string]: string | object | boolean | undefined;
}

class DucalisService {
  static createHash(email: string, secretKey: string) {
    return crypto.createHmac('sha256', secretKey).update(email).digest('hex');
  }
  static connect(secretKey: string, config: IDucalisConfig, brand: Brand) {
    if (brand === 'cloudmaster') {
      window.dclsPxl('initWidget', {
        width: '700px',
        ...config,
        user: {
          ...config.user,
          hash: DucalisService.createHash(config.user.email, secretKey),
        },
      });
    }
  }
}

export { DucalisService };
