import React, { useMemo } from 'react';

import { bindStyles } from '@src/utils';
import { useAppToolbarContext } from '@src/common';

import AvatarDefaultAstrokite from '@assets/images/AvatarDefaultAstrokite.svg';
import AvatarDefault from '@assets/images/AvatarDefault.svg';

import styles from './ManageAvatar.module.scss';

const cx = bindStyles(styles);

type Props = {
  avatar: string | null | undefined;
};

const ManageAvatar: React.FC<Props> = ({ avatar }) => {
  const { brand } = useAppToolbarContext();

  const renderedAvatar = useMemo(() => {
    switch (brand) {
      case 'astrokite':
        return <AvatarDefaultAstrokite />;
      case 'cloudmaster':
        return <AvatarDefault />;
    }
  }, [brand]);

  return (
    <>
      <div className={cx('imageContainer')}>
        {avatar ? (
          <img
            className={cx('image')}
            src={avatar}
          />
        ) : (
          <div className={cx('avatarPlug')}>{renderedAvatar}</div>
        )}
      </div>
    </>
  );
};

export { ManageAvatar };
