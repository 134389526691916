import { FC } from 'react';

import { ActiveDirectoryFormDTO } from '@common/components/SettingsProject/contents/ActiveDirectory/EditConnection';

import { Button, Icon } from '@src/kit';
import { bindStyles } from '@src/utils';
import { useAppTranslation } from '@src/common';

import styles from './ViewConnectionAD.module.scss';

const cx = bindStyles(styles);

type ViewConnectionADProps = {
  activeDirectoryInfo: ActiveDirectoryFormDTO | null;
  changeMode: () => void;
};

const ViewConnectionAD: FC<ViewConnectionADProps> = ({ activeDirectoryInfo, changeMode }) => {
  const { t } = useAppTranslation();

  return (
    <div className={cx('wrapper')}>
      <div className={cx('row')}>
        <p className={cx('clientId')}>
          {t('cos.activeDirectory.view.clientId')} <span>{activeDirectoryInfo?.clientId}</span>
        </p>
        <Button
          size="md"
          onClick={changeMode}
          variant="outline"
        >
          <div className={cx('buttonContent')}>
            <Icon type="edit" />
            <span>{t('edit')}</span>
          </div>
        </Button>
      </div>
      <div className={cx('row')}>
        <p className={cx('apiUrl')}>
          {t('cos.activeDirectory.view.apiUrl')} <span>{activeDirectoryInfo?.adUrl}</span>
        </p>
      </div>
      <div className={cx('domainWrapper')}>
        <p>{t('cos.activeDirectory.view.domains')}</p>
        <div className={cx('domains')}>
          {activeDirectoryInfo?.domains?.map((item, index) => (
            <div
              key={`${item}-${index}`}
              className={cx('domain')}
            >
              {item?.domain}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export { ViewConnectionAD };
