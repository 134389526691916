import React from 'react';

import { bindStyles } from '@src/utils';

import { CurrencyData } from '../@types';
import styles from '../ReferencePopup.module.scss';

const cx = bindStyles(styles);

type Props = {
  title: string;
  currencies: CurrencyData[];
};

const Currencies: React.FC<Props> = ({ title, currencies }) => (
  <div className={cx('currency')}>
    <div className={cx('currencyHeader')}>{title}</div>
    <div className={cx('currenciesContainer')}>
      {currencies.map((item) => (
        <div
          className={cx('currencyItem')}
          key={item.currency}
        >
          <p className={cx('currencyText')}>
            <span className={cx('currencySymbol')}>{`${item.symbol} `}</span>
            {item.currency}
          </p>
          <p className={cx('currencyValue')}>{item.value}</p>
        </div>
      ))}
    </div>
  </div>
);

export { Currencies };
