import React, { PropsWithChildren } from 'react';
import { HeaderGroup } from 'react-table';

import { SortDirections } from '@src/@types';

type Props<T extends Record<string, unknown>> = React.ThHTMLAttributes<HTMLTableDataCellElement> & {
  column: HeaderGroup<T>;
  className?: string;
  onChangeSort?: (key: string, direction: SortDirections) => void;
};

const TH = <T extends Record<string, unknown>>({
  column,
  className,
  children,
  onChangeSort,
  ...restProps
}: PropsWithChildren<Props<T>>) => {
  const headerProps = column.getHeaderProps({
    style: { maxWidth: column.maxWidth + '%' },
    className,
  });

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { onChangeSort });
    }
    return child;
  });

  return (
    <th
      {...headerProps}
      {...restProps}
    >
      {childrenWithProps}
    </th>
  );
};

export { TH };
