import React from 'react';

import { AppSystem, Bookmark } from '@src/common';
import { Button, Icon } from '@src/kit';
import { bindStyles } from '@src/utils';

import { useMemoPageLink } from './useMemoPageLink';
import styles from './Bookmarks.module.scss';

const cx = bindStyles(styles);

type Props = {
  item: Bookmark;
  currentSystem?: AppSystem;
};

const BookmarkItem: React.FC<Props> = ({ item, currentSystem }) => {
  const { getLastPage } = useMemoPageLink();

  return (
    <div className={cx('link', { isActive: item.system === currentSystem })}>
      <a
        onClick={getLastPage(item.system, currentSystem)}
        href={`/${item.system}`}
        className={cx('linkText')}
      >
        <span className={cx('link-img')}>{item.icon}</span>
        <span>{item.label}</span>
      </a>
    </div>
  );
};

export { BookmarkItem };
