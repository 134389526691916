import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import { bindStyles } from '@src/utils';

import styles from './Portal.module.scss';

type Props = {
  overlay?: boolean;
  children: React.ReactNode;
  className?: string;
};

const cx = bindStyles(styles);

const Portal: React.FC<Props> = ({ children, className, overlay }) => {
  const refContainer = useRef<HTMLDivElement>(document.createElement('div'));
  refContainer.current.setAttribute('data-testid', 'portal');

  useEffect(() => {
    document.body.appendChild(refContainer.current);

    return () => {
      document.body.removeChild(refContainer.current);
    };
  }, []);

  useEffect(() => {
    if (className) {
      refContainer.current.className = className;
    }
  }, [className]);

  useEffect(() => {
    if (overlay) {
      refContainer.current.classList.add(cx('overlay'));
    } else {
      refContainer.current.classList.remove(cx('overlay'));
    }
  }, [overlay]);

  return createPortal(children, refContainer.current);
};

const mPortal = React.memo(Portal);

export { mPortal as Portal };
