import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { FormUserUpdate } from '@common/components/AppToolbar/components/Profile/SidePanelProfile';

import { FormPasswordInput, passwordPattern, required, useAppTranslation } from '@src/common';
import { bindStyles } from '@src/utils';
import { Disclaimer } from '@src/kit';

import styles from './PasswordChangeForm.module.scss';

const cx = bindStyles(styles);

const SYMBOLS = `-$%()*+^_{|}~.:;?@/[]\\`;

type Props = {
  classNameFull: string;
};

const PasswordChangeForm: React.FC<Props> = ({ classNameFull }) => {
  const { t } = useAppTranslation();

  const formMethods = useFormContext<FormUserUpdate>();
  const passwordVisible = formMethods.watch('passwordVisible');

  const rulesNew = useMemo(
    () => ({
      ...required('FieldRequired'),
      minLength: {
        value: 8,
        message: t('cld.editUserInfo.passwordLengthError'),
      },
      validate: {
        samePass: (value: string) =>
          value !== formMethods.getValues('oldPassword') ||
          (t('cld.editUserInfo.password.change.old') as string),
      },
      ...passwordPattern('cld.editUserInfo.passwordRequirementsError'),
    }),
    [t, formMethods.getValues],
  );

  const rulesNewConfirm = useMemo(
    () => ({
      validate: {
        passConfirm: (value: string) =>
          value === formMethods.getValues('newPassword') ||
          (t('cld.editUserInfo.noPasswordMatch') as string),
      },
      ...required('FieldRequired'),
    }),
    [t, formMethods.getValues],
  );

  if (!passwordVisible) {
    return null;
  }

  return (
    <>
      <FormPasswordInput
        className={classNameFull}
        name="oldPassword"
        caption={t('EnterOldPassword')}
        placeholder={t('EnterOldPassword')}
        rules={required('FieldRequired')}
        autoComplete="current-password"
        showPass
      />

      <FormPasswordInput
        name="newPassword"
        caption={t('NewPassword')}
        placeholder={t('CreateNewPassword')}
        rules={rulesNew}
        autoComplete="new-password"
        showPass
      />
      <FormPasswordInput
        name="newPassConfirm"
        caption={t('ConfirmPassword')}
        placeholder={t('ConfirmThePassword')}
        rules={rulesNewConfirm}
        autoComplete="new-password"
        showPass
      />

      <div className={cx(classNameFull)}>
        <Disclaimer
          title={t('cld.editUserInfo.passwordRequirements')}
          isExtend
          text={
            <ul className={cx('list')}>
              <li>{t('cld.editUserInfo.passwordRequirements.length')}</li>
              <li>{t('cld.editUserInfo.passwordRequirements.digit')}</li>
              <li>{t('cld.editUserInfo.passwordRequirements.latin')}</li>
              <li>{t('cld.editUserInfo.passwordRequirements.case')}</li>
              <li>{`${t('cld.editUserInfo.passwordRequirements.special')} ${SYMBOLS.split('').join(
                ' ',
              )}`}</li>
            </ul>
          }
        />
      </div>
    </>
  );
};

export { PasswordChangeForm };
