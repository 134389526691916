import { FC, PropsWithChildren, useEffect } from 'react';

import * as amplitude from '@amplitude/analytics-browser';

import { EventData, EventStatus, useAppToolbarContext } from '@src/common';

import { AnalyticsContext } from './AnalyticsContext';

type Props = PropsWithChildren<{
  token?: string;
  isDisabled?: boolean;
}>;

const AnalyticsProvider: FC<Props> = ({ children, token, isDisabled }) => {
  const { user } = useAppToolbarContext();

  const init = () => {
    if (!isDisabled && token) {
      amplitude.init(token);
      const identify = new amplitude.Identify()
        .set('environment', window.location.origin.replace('https://', ''))
        .set('company_name', user?.company || '')
        .set('role', user?.roles?.map((role) => role.name) || [])
        .set('UserUUID', user?.uuid || '');
      amplitude.identify(identify);
    }
  };

  const logEvent = (event: string, data?: EventData) => {
    if (!isDisabled && token) {
      const properties = {
        ...(data || {}),
      };
      amplitude.logEvent(event, properties);
    }
  };

  const logSuccessEvent = (event: string, data?: EventData) => {
    logEvent(event, { ...(data || {}), status: EventStatus.SUCCESS });
  };

  const logErrorEvent = (event: string, data?: EventData) => {
    logEvent(event, { ...(data || {}), status: EventStatus.ERROR });
  };

  useEffect(() => {
    init();
  }, [user]);

  return (
    <AnalyticsContext.Provider value={{ logEvent, logSuccessEvent, logErrorEvent }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export { AnalyticsProvider };
