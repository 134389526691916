import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useNewConnectionContext } from '@common/components/Clouds/components/NewConnection/NewConnectionContext';
import {
  connectYandexCloud,
  editYandexCloud,
  getYandexCloudInfo,
} from '@common/components/AppToolbar/api/cloud-connections';
import { AxiosError } from 'axios';
import { ConnectionStep } from '@common/components/Clouds/components/NewConnection/Forms/YandexConnection/ConnectionStep';
import {
  StatusInfo,
  StatusInfoVariant,
} from '@common/components/Clouds/components/NewConnection/StatusInfo';
import { ConnectionSteps } from '@common/components/Clouds/components';
import { useCloudsContext } from '@common/providers/CloudsProvider';
import { CloudEditLoading } from '@common/components/AppToolbar/components/CloudConnect/CloudEdit/CloudEditLoading';

import { CLOUD_NAME_DICTIONARY } from '@src/constants';
import {
  Form,
  useAnalytics,
  useFormApiError,
  useToast,
  useTranslationPrefix,
  YaCloudCreateDTO,
  YaCloudEditDTO,
  YaSubCloudDTO,
} from '@src/common';
import { Button } from '@src/kit';
import { bindStyles } from '@src/utils';
import { CloudTypes } from '@src/@types';

import styles from './YandexConnection.module.scss';

const cx = bindStyles(styles);

const YandexConnection: FC = () => {
  const toast = useToast();
  const {
    onBack,
    onNext,
    changeStatusInfoSettings,
    currentStep,
    selectedProvider,
    statusInfoSettings,
  } = useNewConnectionContext();
  const { cloudEdit, editableCloud } = useCloudsContext();
  const [initLoading, setInitLoading] = useState(true);
  const { t, tPrefix } = useTranslationPrefix('cos.yandexConnectCloud.form.');
  const { logSuccessEvent, logErrorEvent } = useAnalytics();
  const [isLoading, setIsLoading] = useState(false);
  const [savedAccessKey, setSavedAccessKey] = useState('');
  const form = useForm<YaCloudCreateDTO>({
    mode: 'onBlur',
    defaultValues: {
      accessKey: '',
    },
  });
  const { apiErrors, handleError } = useFormApiError();

  const accessKey = form.watch('accessKey');

  const wasChangedAccessKey = accessKey !== savedAccessKey;

  const createNewConnect = async (form: YaCloudCreateDTO) => {
    try {
      setIsLoading(true);
      changeStatusInfoSettings({
        variant: StatusInfoVariant.LOADING,
        cloudName: form.cloudName,
      });
      await connectYandexCloud(form as YaCloudCreateDTO);
      logSuccessEvent('Created cloud connection', { cloudType: CLOUD_NAME_DICTIONARY.Yandex });
      changeStatusInfoSettings({
        variant: StatusInfoVariant.SUCCESS,
        cloudName: form.cloudName,
      });
    } catch (error) {
      changeStatusInfoSettings({
        variant: StatusInfoVariant.ERROR,
        cloudName: form.cloudName,
      });
      handleError(error as AxiosError);
      toast.error({
        text: `${t(`FailedConnectCloud`, { cloud: CLOUD_NAME_DICTIONARY.Yandex })}`,
        hasClose: true,
        btnText: t('LearnMore'),
      });
      logErrorEvent('Created cloud connection', { cloudType: CLOUD_NAME_DICTIONARY.Yandex });
    } finally {
      setIsLoading(false);
    }
  };

  const onEditSubmit = useCallback(
    async (values: YaCloudCreateDTO) => {
      if (editableCloud?.id) {
        try {
          setIsLoading(true);
          changeStatusInfoSettings({
            variant: StatusInfoVariant.LOADING,
            cloudName: values.cloudName,
          });
          if (wasChangedAccessKey && !values.secretKey) {
            form.setError('secretKey', { message: t('FieldRequired') });
            return;
          }
          const dto: YaCloudEditDTO = {
            ...values,
            cloudId: editableCloud.id,
          };
          await editYandexCloud(dto);
          toast.success({ text: t('cos.connect.cloud.edit.success') });
          logSuccessEvent('Edited cloud connection', {
            id: editableCloud.id,
            cloudType: CLOUD_NAME_DICTIONARY.Yandex,
          });
          changeStatusInfoSettings({
            variant: StatusInfoVariant.SUCCESS,
            cloudName: values.cloudName,
          });
        } catch (e) {
          changeStatusInfoSettings({
            variant: StatusInfoVariant.ERROR,
            cloudName: values.cloudName,
          });
          handleError(e as AxiosError);
          logErrorEvent('Edited cloud connection', {
            id: editableCloud.id,
            cloudType: CLOUD_NAME_DICTIONARY.Yandex,
          });
        } finally {
          setIsLoading(false);
        }
      }
      return values;
    },
    [t, editableCloud?.id, wasChangedAccessKey],
  );

  const values = form.watch();

  const onSubmit = useCallback(() => {
    if (cloudEdit) {
      onNext();
      form.handleSubmit(onEditSubmit)();
    } else {
      onNext();
      form.handleSubmit(createNewConnect)();
    }
  }, [cloudEdit]);

  const isDirty = useMemo(() => {
    const { bucketName, bucketCatalogName, cloudName, secretKey, accessKey } = values;
    return !bucketName || !bucketCatalogName || !cloudName || !secretKey || !accessKey;
  }, [values]);

  const buttonLoading = () => {
    if (cloudEdit) {
      return isLoading || initLoading;
    } else {
      return isLoading;
    }
  };

  const fetchCloudInfo = useCallback(async () => {
    if (editableCloud?.id) {
      try {
        setInitLoading(true);
        const response = await getYandexCloudInfo(editableCloud.id);
        form.setValue('cloudName', response.data.name);
        form.setValue('accessKey', response.data.staticKey);
        form.setValue('bucketName', response.data.bucket);
        form.setValue('bucketCatalogName', response.data.prefix);

        setSavedAccessKey(response.data.staticKey);
      } catch (e) {
        handleError(e as AxiosError);
        return {
          result: [] as YaSubCloudDTO[],
          totalItems: 0,
        };
      } finally {
        setInitLoading(false);
      }
    }
  }, []);

  useEffect(() => {
    if (cloudEdit) {
      fetchCloudInfo();
    }
  }, [cloudEdit]);

  return (
    <Form<YaCloudCreateDTO>
      formMethods={form}
      onSubmit={onSubmit}
      apiErrors={apiErrors}
      className={cx('form')}
    >
      {currentStep === ConnectionSteps.STATUS ? (
        <StatusInfo
          cloudType={selectedProvider as CloudTypes}
          variant={statusInfoSettings.variant}
          cloudName={statusInfoSettings.cloudName}
        />
      ) : (
        <>
          {initLoading && cloudEdit ? (
            <CloudEditLoading />
          ) : (
            <div className={cx('inputs')}>
              <ConnectionStep
                disable={isLoading}
                tKey={tPrefix}
              />
            </div>
          )}
          <div className={cx('buttons')}>
            <Button
              text={t('clouds.connection.button.back')}
              variant="outline"
              onClick={onBack}
            />
            <Button
              text={
                cloudEdit ? t('cos.cloudConnect.edit.save') : t('clouds.connection.button.connect')
              }
              type="submit"
              isLoading={buttonLoading()}
              disabled={!cloudEdit && isDirty}
            />
          </div>
        </>
      )}
    </Form>
  );
};

export { YandexConnection };
