import { FC, useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { connectHyperV } from '@common/components/AppToolbar/api/cloud-connections';
import { useNewConnectionContext } from '@common/components/Clouds/components/NewConnection/NewConnectionContext';
import {
  FormDataHyperV,
  HyperVDTO,
} from '@common/components/AppToolbar/components/CloudConnect/types';
import { defaultClassificationValues } from '@common/components/AppToolbar/components/CloudConnect/Forms/FormHyperV';
import { StorageSettingsStep } from '@common/components/Clouds/components/NewConnection/Forms/HyperVConnection/StorageSettingsStep';
import { ConnectionSteps } from '@common/components/Clouds/components';
import {
  StatusInfo,
  StatusInfoVariant,
} from '@common/components/Clouds/components/NewConnection/StatusInfo';

import { Form, useAnalytics, useAppTranslation, useToast } from '@src/common';
import { bindStyles, gbToMb } from '@src/utils';
import { Button } from '@src/kit';
import { CloudTypes, Gb } from '@src/@types';
import { CLOUD_NAME_DICTIONARY } from '@src/constants';

import { ConnectionStep } from './ConnectionStep';
import styles from './HyperVConnection.module.scss';

const cx = bindStyles(styles);

const defaultValues: FormDataHyperV = {
  port: 5986,
  useHttps: 'used',
  name: '',
  password: '',
  server: '',
  username: '',
  diskClassifications: [defaultClassificationValues],
};

const HyperVConnection: FC = () => {
  const {
    onBack,
    onNext,
    currentStep,
    changeStatusInfoSettings,
    statusInfoSettings,
    selectedProvider,
  } = useNewConnectionContext();
  const toast = useToast();
  const { t } = useAppTranslation();
  const { logSuccessEvent, logErrorEvent } = useAnalytics();
  const [isLoading, setIsLoading] = useState(false);

  const form = useForm<FormDataHyperV>({
    defaultValues,
    mode: 'onSubmit',
  });

  const createNewConnect = async (dto: FormDataHyperV) => {
    try {
      const { useHttps, diskClassifications, ...other } = dto;
      setIsLoading(true);
      changeStatusInfoSettings({
        variant: StatusInfoVariant.LOADING,
        cloudName: dto.name,
      });
      const form: HyperVDTO = {
        useHttps: useHttps === 'used',
        diskClassifications: diskClassifications.map((disk) => ({
          ...disk,
          maxSize: disk.maxSize ? gbToMb(Number(disk.maxSize) as Gb) : null,
        })),
        ...other,
      };

      const response = await connectHyperV(form);
      if (!response.data.success) {
        throw new Error(response.data.errorMessage);
      }
      logSuccessEvent('Created cloud connection', { cloudType: CLOUD_NAME_DICTIONARY.HyperV });
      changeStatusInfoSettings({
        variant: StatusInfoVariant.SUCCESS,
        cloudName: dto.name,
      });
    } catch (error) {
      changeStatusInfoSettings({
        variant: StatusInfoVariant.ERROR,
        cloudName: dto.name,
      });
      toast.error({
        text: `${t(`FailedConnectCloud`, { cloud: CLOUD_NAME_DICTIONARY.HyperV })}`,
        hasClose: true,
        btnText: t('LearnMore'),
      });
      logErrorEvent('Created cloud connection', { cloudType: CLOUD_NAME_DICTIONARY.HyperV });
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = useCallback(() => {
    if (currentStep === ConnectionSteps.SETTINGS) {
      form.handleSubmit(() => {
        onNext();
      })();
    } else if (currentStep === ConnectionSteps.POLICY) {
      onNext();
      form.handleSubmit(createNewConnect)();
    }
  }, [currentStep]);

  const values = form.watch();

  const isDirty = useMemo(() => {
    const { username, password, server, name } = values;
    return !username || !password || !name || !server;
  }, [values]);

  const renderedSteps = useMemo(() => {
    switch (currentStep) {
      case ConnectionSteps.SETTINGS:
        return <ConnectionStep />;
      case ConnectionSteps.POLICY:
        return <StorageSettingsStep />;
    }
  }, [currentStep]);

  return (
    <Form<FormDataHyperV>
      formMethods={form}
      onSubmit={onSubmit}
      className={cx('form')}
    >
      {currentStep === ConnectionSteps.STATUS ? (
        <StatusInfo
          cloudType={selectedProvider as CloudTypes}
          variant={statusInfoSettings.variant}
          cloudName={statusInfoSettings.cloudName}
        />
      ) : (
        <>
          <div className={cx('inputs')}>{renderedSteps}</div>
          <div className={cx('buttons')}>
            <Button
              text={t('clouds.connection.button.back')}
              variant="outline"
              onClick={onBack}
            />
            <Button
              text={
                currentStep === ConnectionSteps.SETTINGS
                  ? t('clouds.connection.button.next')
                  : t('clouds.connection.button.connect')
              }
              type="submit"
              isLoading={isLoading}
              disabled={isDirty}
            />
          </div>
        </>
      )}
    </Form>
  );
};

export { HyperVConnection };
