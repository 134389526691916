import { useEffect, useRef, useState } from 'react';

import debounce from 'lodash/debounce';

const useResize = (withDelay = true) => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  const resize = () => setWidth(window.innerWidth);

  const refFn = useRef(debounce(resize, 200));

  useEffect(() => {
    window.addEventListener('resize', withDelay ? refFn.current : resize);
    return () => {
      window.removeEventListener('resize', withDelay ? refFn.current : resize);
    };
  }, [withDelay]);

  return width;
};

export { useResize };
