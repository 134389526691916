// Field names
const USERNAME = 'username';
const PASSWORD_FIELD = 'password';
const CLOUDNAME = 'cloudName';
const NAME = 'name';
const TENANTID = 'tenantId';
const CLIENTID = 'clientId';
const SECRETKEY = 'clientSecret';
const SUBSCRIPTIONS = 'subscriptions';
const VCENTER = 'url';

const API_VERSION = 'apiVersion';
const CORE_COST = 'cpuPrice';
const RAM_COST = 'memoryGbPrice';
const HDD_COST = 'diskGbPrice';
const ACCESS_KEY = 'accessKey';
const REGION = 'region';
const KEYSECRET = 'secretKey';
const URL = 'URL';
const KEYSTONE_AUTH_URL = 'keystoneAuthUrl';
const DOMAIN = 'domain';
const NATIVE_REGION_NAME = 'nativeRegionName';
const SERVER = 'server';
const PORT = 'port';
const HTTPS = 'useHttps';

const OPENSTACK_URL_REGEX =
  /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?|^((http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])?/;

const PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$%*?\-()+^_{|}~.:;/\\\[\]])[A-Za-z\d@$%*?\-()+^_{|}~.:;/\\\[\]]{8,}$/;

export {
  API_VERSION,
  USERNAME,
  PASSWORD_FIELD,
  CLOUDNAME,
  NAME,
  TENANTID,
  CLIENTID,
  SECRETKEY,
  SUBSCRIPTIONS,
  VCENTER,
  CORE_COST,
  RAM_COST,
  HDD_COST,
  ACCESS_KEY,
  REGION,
  KEYSECRET,
  URL,
  KEYSTONE_AUTH_URL,
  NATIVE_REGION_NAME,
  SERVER,
  PORT,
  HTTPS,
  OPENSTACK_URL_REGEX,
  PASSWORD_REGEX,
  DOMAIN,
};
