import React from 'react';

import cn from 'classnames';

import styles from './Card.module.scss';

type CardProps = {
  className?: string;
};

type Props = Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  keyof CardProps
> &
  CardProps;

const Card: React.FC<Props> = ({ children, className, ...restProps }) => {
  return (
    <article
      className={cn(className, styles.card)}
      {...restProps}
    >
      {children}
    </article>
  );
};

export { Card };
