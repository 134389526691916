import { CSSProperties, ForwardedRef, forwardRef, ReactNode, useMemo } from 'react';
import ReactSelect, { GroupBase, Props as SelectProps, StylesConfig } from 'react-select';
import { SelectComponents } from 'react-select/dist/declarations/src/components';

import cn from 'classnames/bind';
import { IconTypes, Tooltip } from '@kit/components';
import { Icon } from '@kit/components/Icon';
import { Loader } from '@kit/components/Loader';
import { Control } from '@kit/components/Select/Control';
import { MultiValue } from '@kit/components/Select/MultiValue';
import { NoOptionsMessage } from '@kit/components/Select/NoOptionsMessage';
import { Option } from '@kit/components/Select/Option';
import { ValueContainer } from '@kit/components/Select/ValueContainer';

import { CloudTypes, Size } from '@src/@types';

import moduleStyles from './Select.module.scss';

type OptionCustomize = {
  description?: string;
  avatar?: string;
  icon?: IconTypes;
  cloudType?: CloudTypes;
  disabled?: boolean;
};

type SelectOptionWithoutChildren<T, M = string> = {
  label: string | number;
  value: T;
  meta?: M;
  customize?: OptionCustomize;
};

type OptionCustomizeWithChildren = {
  description?: string;
  avatar?: string;
  icon?: IconTypes;
  cloudType?: CloudTypes;
  childrenOptions?: SelectOptionWithoutChildren<unknown>[];
  disabled?: boolean;
};

type SelectOption<T, M = string> = {
  label: string | number;
  value: T;
  meta?: M;
  customize?: OptionCustomizeWithChildren;
};

type SelectGroupOption<T, M = string> = {
  label?: string | number;
  options: SelectOption<T, M>[];
  meta?: M;
};

type CustomProps<T> = {
  description?: ReactNode;
  descriptionOnTop?: boolean;
  size?: Extract<Size, 'sm' | 'md' | 'lg'>;
  errorText?: string;
  isError?: boolean;
  hover?: boolean;
  caption?: ReactNode;
  options: SelectOption<T>[] | SelectGroupOption<T>[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (value: any) => void;
  hideFocusedPlaceholder?: boolean;
  withoutBorder?: boolean;
  containerClassName?: string;
  noOptionsText?: string;
  onContainerClick?: (event: React.MouseEvent) => void;
  hint?: string;
  menuStyles?: CSSProperties;
  portal?: boolean | HTMLElement;
  maxMenuHeight?: number;
  adaptiveOff?: boolean;
  compareById?: boolean;
};

type Props<T = string> = CustomProps<T> & Omit<SelectProps, keyof CustomProps<T>>;

const cx = cn.bind(moduleStyles);

const DEFAULT_PORTAL_ROOT = document.querySelector('body');

const SelectInner = <T,>(
  {
    size = 'lg',
    caption,
    hint,
    description,
    descriptionOnTop,
    isError,
    errorText,
    className,
    hover,
    components,
    styles,
    withoutBorder,
    containerClassName,
    hideSelectedOptions = false,
    backspaceRemovesValue = false,
    noOptionsText,
    isMulti,
    menuStyles,
    portal,
    isClearable = false,
    isDisabled = false,
    hideFocusedPlaceholder = true,
    onContainerClick,
    options,
    maxMenuHeight,
    adaptiveOff = true, //todo: изменить на false когда будут готовы все адаптивы
    compareById = false,
    value,
    onChange,
    ...restProps
  }: Props<T>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref?: ForwardedRef<any>,
) => {
  const newOptions = useMemo(
    () => options?.map((item) => ({ ...item, label: item?.label || '-' })),
    [options],
  );
  const customStyles: StylesConfig = useMemo(
    () => ({
      menu: (provided) => ({
        ...provided,
        ...menuStyles,
        borderRadius: 12,
        border: `1px solid ${moduleStyles.gray20}`,
        boxShadow: moduleStyles.shadow,
        marginTop: 0,
        paddingTop: 8,
        paddingBottom: 8,
        paddingRight: 4,
      }),
      menuList: (provided) => ({
        ...provided,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 12,
        paddingRight: 8,
        maxHeight: maxMenuHeight ?? 215,
      }),
      multiValueRemove: () => ({ display: 'none' }),
      indicatorSeparator: () => ({ display: 'none' }),
      input: (provided) => ({
        ...provided,
        margin: 0,
        padding: 0,
      }),
      placeholder: (provided, state) => ({
        ...provided,
        color: moduleStyles.gray70,
        display: state.isFocused && hideFocusedPlaceholder ? 'none' : undefined,
      }),
      menuPortal: (provided) => ({
        ...provided,
        zIndex: 1000,
      }),
      valueContainer: (provided) => ({
        ...provided,
        display: 'flex',
      }),
      ...styles,
    }),
    [hideFocusedPlaceholder, styles],
  );

  const selectedValue = useMemo(() => {
    if (compareById) {
      if (isMulti || Array.isArray(value)) {
        return (options as SelectOption<T>[]).filter((option) => {
          return (value as T[]).find((id) => option.value === id);
        });
      }
      return (options as SelectOption<T>[]).find((item) => item.value === value);
    }

    return value;
  }, [compareById, options, value, isMulti]);

  const innerComponents: Partial<SelectComponents<unknown, boolean, GroupBase<unknown>>> = useMemo(
    () => ({
      DropdownIndicator: () => (
        <Icon
          type="chevron-down"
          size="md"
          className={cx('icon', { isDisabled })}
        />
      ),
      Option: ({ children, ...props }) => <Option {...props}>{children}</Option>,
      Control: ({ children, ...props }) => (
        <Control
          {...props}
          isDisabled={isDisabled}
          size={size}
          withoutBorder={withoutBorder}
          isError={isError}
          hover={hover}
          adaptiveOff={adaptiveOff}
        >
          {children}
        </Control>
      ),
      NoOptionsMessage: ({ children, ...props }) => (
        <NoOptionsMessage
          {...props}
          text={noOptionsText}
        >
          {children}
        </NoOptionsMessage>
      ),
      ValueContainer: ({ children, ...props }) => (
        <ValueContainer
          {...props}
          isDisabled={isDisabled}
          size={size}
          adaptiveOff={adaptiveOff}
        >
          {children}
        </ValueContainer>
      ),
      LoadingIndicator: () => <Loader className={cx('icon', 'loader', { isDisabled })} />,
      MultiValue: ({ children, ...props }) => <MultiValue {...props}>{children}</MultiValue>,
      ...components,
    }),
    [isDisabled, noOptionsText, size, withoutBorder, isError, hover, components],
  );

  const onSelect = (value: SelectOption<T> | SelectOption<T>[]) => {
    let val: SelectOption<T> | SelectOption<T>[] | T | T[] = value;

    if (compareById) {
      if (Array.isArray(value)) {
        val = value.map(({ value }) => value);
      } else {
        val = value?.value;
      }
    }

    onChange?.(val);
  };

  const descriptionNode = description && (
    <div className={cx('description', { top: descriptionOnTop })}>{description}</div>
  );

  return (
    <div className={cx(containerClassName, 'container')}>
      {caption && (
        <div className={cx({ caption: true, isDisabled }, 'captionTooltip')}>
          {caption}
          {hint && (
            <Tooltip
              text={hint}
              place="top"
            >
              <Icon
                type="question-mark-circle"
                size="md"
                className={cx('icon', 'hint')}
              />
            </Tooltip>
          )}
        </div>
      )}

      {descriptionOnTop && descriptionNode}

      <div onClick={onContainerClick}>
        <ReactSelect
          value={selectedValue}
          options={newOptions}
          ref={ref}
          styles={customStyles}
          className={cx('select', { adaptiveOn: !adaptiveOff }, className)}
          isDisabled={isDisabled}
          components={innerComponents}
          hideSelectedOptions={hideSelectedOptions}
          backspaceRemovesValue={backspaceRemovesValue}
          isClearable={isClearable}
          isMulti={isMulti}
          menuPlacement="auto"
          menuPortalTarget={typeof portal === 'boolean' ? DEFAULT_PORTAL_ROOT : portal}
          onChange={onSelect}
          {...restProps}
        />
      </div>

      {errorText && <div className={cx({ error: true })}>{errorText}</div>}
      {!descriptionOnTop && descriptionNode}
    </div>
  );
};

const Select = forwardRef(SelectInner) as <T>(
  props: Props<T> & { ref?: ForwardedRef<unknown> },
) => ReturnType<typeof SelectInner>;

export { Select };
export type { SelectOption, SelectGroupOption, Props as SelectProps, OptionCustomize };
