import React from 'react';

import cn from 'classnames/bind';

import styles from './ModalFooter.module.scss';

type Props = {
  withoutBorder?: boolean;
  className?: string;
};

const cx = cn.bind(styles);

const ModalFooter: React.FC<Props> = ({ children, withoutBorder, className }) => {
  return <footer className={cx('footer', { withoutBorder }, className)}>{children}</footer>;
};

export { ModalFooter };
