import React, { PropsWithChildren } from 'react';

import './ErrorBoundary.module.scss';
import { AUTH_URL } from '@common/api/Auth/AuthApi';

import { GeneralAppErrorMessage } from '@src/kit';
import { RestService, StorageService } from '@src/common';
import { AUTH_HEADER, LANG_ASTROKITE_KEY, LANG_CLOUDMASTER_KEY } from '@src/constants';
import { appNavigate } from '@src/utils';
import { Brand, Language } from '@src/@types';

import {
  BUTTON_VARIANTS,
  COMMENT_VARIANTS,
  ErrorType,
  ICON_VARIANTS,
  TITLE_VARIANTS,
} from './variants';

const restService = RestService.getInstance();
const storageService = StorageService.getInstance();

type State = {
  type: ErrorType;
  hasError: boolean;
};

type Props = PropsWithChildren<{
  showError?: boolean;
  authUrl?: string;
  brand?: Brand;
  authStorageKey?: string;
  excludeErrorUrls?: string[];
}>;

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: props.showError || false,
      type: ErrorType.GENERAL,
    };
  }

  static getDerivedStateFromError() {
    return {
      type: ErrorType.GENERAL,
      hasError: true,
    };
  }

  componentDidMount() {
    restService.addInterceptors((res) => {
      if (res.status === 502) {
        const excludedUrl =
          res.config?.url && this.props.excludeErrorUrls?.includes(res.config.url);

        if (excludedUrl) {
          return;
        }

        this.setState({
          type: ErrorType.NGINGX,
          hasError: true,
        });
      }

      if (res.status === 401 && !res.config?.url?.includes(AUTH_URL)) {
        restService.removeDefaultHeader(AUTH_HEADER);
        storageService.removeItem(this.props.authStorageKey || AUTH_HEADER);
        appNavigate(this.props.authUrl || '/auth');
      }
    });
  }

  onReload = () => window.location.reload();

  render() {
    const { brand, children } = this.props;
    const lang: Language =
      storageService.getItem(brand === 'cloudmaster' ? LANG_CLOUDMASTER_KEY : LANG_ASTROKITE_KEY) ||
      'en-US';

    if (this.state.hasError) {
      return (
        <GeneralAppErrorMessage
          title={TITLE_VARIANTS[this.state.type][lang]}
          comment={COMMENT_VARIANTS[this.state.type][lang]}
          buttonText={BUTTON_VARIANTS[this.state.type][lang]}
          icon={ICON_VARIANTS[this.state.type]}
          onButtonClick={this.onReload}
        />
      );
    }

    return <>{children}</>;
  }
}

export { ErrorBoundary };
