import { saveToStorage } from '@common/components/AppToolbar/components/Bookmarks/Bookmarks';
import { BOOKMARKS_KEY, SYSTEMS } from '@common/components/AppToolbar/constants/common';

import { StorageService, AppSystem } from '@src/common';

const useTabSequence = () => {
  const storage = StorageService.getInstance();

  const setTabSequence = (currentProject: AppSystem) => {
    const bookmarks =
      storage.getItem<AppSystem[]>(BOOKMARKS_KEY) || SYSTEMS.map((item) => item.system);

    if (bookmarks[0] !== currentProject) {
      bookmarks.unshift(currentProject);
    }

    saveToStorage(Array.from(new Set(bookmarks)));
  };

  return {
    setTabSequence,
  };
};

export { useTabSequence };
