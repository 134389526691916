import React from 'react';

import cn from 'classnames/bind';
import { Tooltip, TooltipContentItem, TooltipProps } from '@kit/components';
import { TextShorter } from '@kit/components/TextShorter';

import styles from './MultiValueBlock.module.scss';

type MultiValueBlockProps = Partial<
  Pick<TooltipProps, 'color' | 'position' | 'place' | 'tooltipItemsLimit' | 'moreItemsRender'>
> & {
  tooltipValues?: TooltipContentItem[];
};

type Props = MultiValueBlockProps & {
  values: TooltipContentItem[];
  className?: string;
  hasTooltip?: boolean;
};

const cx = cn.bind(styles);

//TODO: should render item for available block width
const MultiValueBlock: React.FC<Props> = ({
  values,
  className,
  hasTooltip = true,
  tooltipValues = values,
  children,
  ...tooltipProps
}) => {
  const restItemsCount = values.length - 1;
  const [visibleItem] = values;
  const isCounterVisible = restItemsCount > 0;
  const tooltipItems = tooltipValues.slice(1);

  const text = tooltipItems.map((item, i) => (
    <p
      className={cx('toolTipMultiValuesText')}
      key={i}
    >
      {item}
    </p>
  ));

  return (
    <div className={cx('multiValue', className)}>
      {children}
      <TextShorter
        tooltip
        className={cx('text')}
      >
        {visibleItem}
      </TextShorter>

      {isCounterVisible && (
        <Tooltip
          disabled={!hasTooltip}
          place="bottom"
          text={text}
          {...tooltipProps}
        >
          <span className={cx('counter', { clickable: hasTooltip })}>+{restItemsCount}</span>
        </Tooltip>
      )}
    </div>
  );
};

export { MultiValueBlock };
export type { MultiValueBlockProps };
