import { useCallback, useEffect, useRef, useState } from 'react';

import { Portal } from '@kit/components';

import { MIN_AVAILABLE_SCREEN_WIDTH } from '@src/constants';

import { MobilePlugView } from './MobilePlug/MobilePlug.view';

const body = document.body;

const useMobilePlug = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const savedOverflow = useRef(body.style.overflowY);

  const handleResize = useCallback(() => {
    const newVisible = window.innerWidth < MIN_AVAILABLE_SCREEN_WIDTH;

    if (newVisible !== visible && newVisible) {
      savedOverflow.current = body.style.overflowY;
    }

    setVisible(newVisible);
  }, [visible]);

  useEffect(() => {
    if (visible) {
      body.style.overflowY = 'hidden';
    } else {
      body.style.overflowY = savedOverflow.current;
    }

    return () => {
      body.style.overflowY = savedOverflow.current;
    };
  }, [visible]);

  useEffect(() => {
    handleResize();

    window.removeEventListener('resize', handleResize);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  if (visible) {
    return (
      <Portal overlay>
        <MobilePlugView />
      </Portal>
    );
  }

  return null;
};

export { useMobilePlug };
