import React from 'react';
import { components, OptionProps } from 'react-select';

import cn from 'classnames/bind';
import { SelectOption } from '@kit/components';
import { BaseOptionWithIcon } from '@kit/components/Select/BaseOptionWithIcon';

import styles from './Select.module.scss';

const cx = cn.bind(styles);

type Props = {
  isShortened?: boolean;
  tooltip?: boolean;
  className?: string;
  getIconTemplate: (data: SelectOption<unknown>) => React.ReactNode;
} & OptionProps;

const OptionWithIcon: React.FC<Props> = ({
  getIconTemplate,
  className,
  isShortened,
  tooltip,
  ...props
}) => {
  const { data, isFocused, isSelected } = props;

  const _data = data as SelectOption<unknown>;

  const label = _data.label as string;

  return (
    <div className={cx('optionContainer', className)}>
      <components.Option
        {...props}
        className={cx(className, 'option', { isSelected, isFocused })}
      >
        <BaseOptionWithIcon
          isShortened={isShortened}
          tooltip={tooltip}
          label={label}
        >
          {getIconTemplate(_data)}
        </BaseOptionWithIcon>
      </components.Option>
    </div>
  );
};

export { OptionWithIcon };
