import React from 'react';

import cn from 'classnames/bind';

import styles from './Title.module.scss';

export type TitleProps = {
  className?: string;
  size?: 'xl' | 'lg' | 'md' | 'sm' | 'xs';
};

export const H1: React.FC<TitleProps> = ({ className, size = 'xl', children }) => {
  const cx = cn.bind(styles);
  return <h1 className={cx(className, 'heading', size)}>{children}</h1>;
};
