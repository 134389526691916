import { FC } from 'react';

import { bindStyles } from '@src/utils';
import { Icon } from '@src/kit';
import { useAppTranslation } from '@src/common';

import styles from './DocsManual.module.scss';

const cx = bindStyles(styles);

type DocsManualProps = {
  linkToManual?: string;
};

const DocsManual: FC<DocsManualProps> = ({ linkToManual }) => {
  const { t } = useAppTranslation();
  return (
    <a
      href={linkToManual}
      target="_blank"
      rel="noreferrer"
      className={cx('manual-wrapper')}
    >
      <Icon
        className={cx('manual-icon')}
        type="help-book"
      />
      <div className={cx('manual-text')}>{t('settings.conClouds.header.link.text')}</div>
    </a>
  );
};

export { DocsManual };
