import React from 'react';

import { Button } from '@kit/components';

import { bindStyles } from '@src/utils';

import AstronautSvg from '@assets/images/Astronaut.svg';
import MoreStarsSvg from '@assets/images/MoreStars.svg';
import BlueStarsSvg from '@assets/images/BlueStars.svg';
import GrayStarsSvg from '@assets/images/GrayStars.svg';

import styles from './TourWidget.module.scss';

type TourWidgetProps = {
  title: string;
  text: string;
  buttonText: string;
  onStart: () => void;
};

const cx = bindStyles(styles);

const TourWidget: React.FC<TourWidgetProps> = ({ onStart, text, buttonText, title }) => {
  return (
    <div className={cx('wrapper')}>
      <div className={cx('astronaut')}>
        <AstronautSvg />
      </div>
      <div className={cx('moreStars')}>
        <MoreStarsSvg />
      </div>
      <div className={cx('blueStars')}>
        <BlueStarsSvg />
      </div>
      <div className={cx('grayStars')}>
        <GrayStarsSvg />
      </div>
      <div className={cx('content')}>
        <div className={cx('title')}>{title}</div>
        <div className={cx('text')}>{text}</div>
        <Button
          onClick={onStart}
          text={buttonText}
          size="md"
          className={cx('button')}
        />
      </div>
    </div>
  );
};

export { TourWidget };
export type { TourWidgetProps };
