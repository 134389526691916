import { createContext, useContext } from 'react';

import { UserInfoDTO } from '@common/api';

type AuthContextState = {
  isAuth: boolean;
  isLoading: boolean;
  authStorageKey: string;
  checkToken: () => Promise<void>;
  login: (username: string, password: string) => Promise<void>;
  logout: () => void;
  toAuth: () => void;
  userInfo?: UserInfoDTO;
};

const AuthContext = createContext({} as AuthContextState);

const useAuth = () => useContext(AuthContext);

export { AuthContext, useAuth };
