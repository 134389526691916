import { useCallback } from 'react';

import { PermissionParams, useUserPermissions } from '@src/common';

type ReturnType = (params: PermissionParams) => (...callbacks: Array<() => unknown>) => boolean;

const useAccessControl = (): ReturnType => {
  const permissions = useUserPermissions();

  const onCheck = useCallback(
    ({ subject, action, operation }: PermissionParams) =>
      (...callbacks: Array<() => unknown>) => {
        const subjectGranted = Boolean(subject && permissions?.[action].includes(subject));
        const operationGranted = Boolean(operation && permissions?.[action].includes(operation));

        if (subjectGranted || operationGranted) {
          callbacks.forEach((fn) => fn());
          return true;
        }

        return false;
      },
    [permissions],
  );

  return onCheck;
};

export { useAccessControl };
export type { ReturnType as ReturnTypeUseAccessControl };
