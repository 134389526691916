import React from 'react';

import { Brand } from '@src/@types';

import InferitFullLogo from '@assets/images/InferitFullLogo.svg';
import LogoAstrokite from '@assets/images/LogoAstrokite.svg';
import AstrokiteText from '@assets/images/Astrokite.svg';

import styles from './LoadingPage.module.scss';

type Props = {
  brand?: Brand;
  text?: string;
};

const IMAGES = {
  cloudmaster: {
    logo: <InferitFullLogo />,
    text: null,
  },
  astrokite: {
    logo: <LogoAstrokite />,
    text: <AstrokiteText />,
  },
};

const LoadingPage: React.FC<Props> = ({ text, brand = 'cloudmaster' }) => {
  const image = IMAGES[brand];

  return (
    <div className={styles.loadingPageContainer}>
      <div className={styles.background}>
        <svg
          viewBox="0 0 1090 1043"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_f_4279_53250)">
            <path
              d="M545 8L989.271 264.5V777.5L545 1034L100.729 777.5V264.5L545 8Z"
              fill="#445FE8"
              fillOpacity="0.03"
            />
          </g>
          <g filter="url(#filter1_f_4279_53250)">
            <path
              d="M544.5 190L831.587 355.75V687.25L544.5 853L257.413 687.25V355.75L544.5 190Z"
              fill="#445FE8"
              fillOpacity="0.03"
            />
          </g>
          <path
            d="M544.5 348L694.755 434.75V608.25L544.5 695L394.245 608.25V434.75L544.5 348Z"
            fill="white"
            fillOpacity="0.36"
          />
          <defs>
            <filter
              id="filter0_f_4279_53250"
              x="0.729004"
              y="-92"
              width="1088.54"
              height="1226"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood
                floodOpacity="0"
                result="BackgroundImageFix"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="50"
                result="effect1_foregroundBlur_4279_53250"
              />
            </filter>
            <filter
              id="filter1_f_4279_53250"
              x="217.413"
              y="150"
              width="654.175"
              height="743"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood
                floodOpacity="0"
                result="BackgroundImageFix"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="20"
                result="effect1_foregroundBlur_4279_53250"
              />
            </filter>
          </defs>
        </svg>
      </div>
      <div className={styles.contentContainer}>
        {brand !== 'cloudmaster' ? (
          <>
            <div className={styles.logoContent}>{image.logo}</div>
            <div className={styles.logoContentTitle}>{image.text}</div>
          </>
        ) : (
          <div className={styles.fullLogoContent}>{image.logo}</div>
        )}
        <p className={styles.logoText}>{text}</p>
      </div>
    </div>
  );
};

export { LoadingPage };
