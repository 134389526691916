import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { StorageService } from '@src/common';
import { mapToString } from '@src/utils';
import { SelectOption } from '@src/kit';

const storage = StorageService.getInstance();

const useSaveFilters = <T extends object>(key: string, onlySave?: boolean) => {
  const [params, setParams] = useSearchParams(window.location.search);
  const [savedFilters, setSavedFilters] = useState<T | null>(storage.getItem<T>(key));

  const setSearchParams = (values: Partial<T>) => {
    const entries = Object.entries(values) as Array<[string, string[] | string]>;
    const par = {} as Record<string, string | string[]>;

    const formattedParams = entries.reduce((result, [key, value]) => {
      if (Array.isArray(value)) {
        if (value.length) {
          result[key] = value.join(',');
        }
      } else {
        result[key] = mapToString(value);
      }
      return result;
    }, par);

    setParams(formattedParams);
  };

  const saveToStorage = (values: T) => {
    setSavedFilters(values);
    storage.setItem(key, values);
  };

  const getMappedValues = (values: Record<keyof T, SelectOption<string | number>[]>) => {
    if (savedFilters) {
      return Object.entries(savedFilters).reduce((res, [key, value]) => {
        res[key as keyof T] = value
          .map((it: string) =>
            values[key as keyof T].find((option) => String(option.value) === String(it)),
          )
          .filter(Boolean);

        return res;
      }, {} as Record<keyof T, SelectOption<string | number>[]>);
    }
  };

  const saveFilters = (values: T) => {
    saveToStorage(values);
    setSearchParams(values);
  };

  const getAllParams = () => {
    const entries = params.entries();
    const values: Record<string, string[]> = {};

    for (const [key, value] of entries) {
      values[key] = value.split(',').filter(Boolean);
    }

    return values as T;
  };

  const reset = () => {
    setSavedFilters(null);
    setSearchParams({});
    storage.removeItem(key);
  };

  useEffect(() => {
    const params = getAllParams();

    if (Object.keys(params).length) {
      saveToStorage(params);
    } else {
      const values = storage.getItem(key) as unknown as T;
      if (values) {
        if (!onlySave) {
          saveFilters(values);
        } else {
          saveToStorage(values);
        }
      }
    }
  }, []);

  return {
    savedFilters,
    saveFilters,
    getMappedValues,
    reset,
    saveToStorage,
  };
};

export { useSaveFilters };
