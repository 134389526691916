import { useState } from 'react';

import { CmTableSort } from '@common/components/CMTable/types';

import { SortDirections } from '@src/@types';

type UseCMTableSortReturn<D> = {
  sort: CmTableSort<D> | null;
  onSort: (id: string, order?: SortDirections) => void;
};

const useCMTableSort = <D>(initialSort?: CmTableSort<D>): UseCMTableSortReturn<D> => {
  const [sort, setSort] = useState<CmTableSort<D> | null>(initialSort || null);

  const onSort = (id: string, order?: SortDirections) => {
    setSort((prev) => {
      if (prev) {
        return {
          column: id as keyof D,
          order: order || prev.order === 'asc' ? 'desc' : 'asc',
        };
      }

      return {
        column: id as keyof D,
        order: order || 'asc',
      };
    });
  };

  return {
    sort,
    onSort,
  };
};

export { useCMTableSort };
export type { UseCMTableSortReturn };
