import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import React from 'react';

import { RadioButton } from '@kit/components/RadioButtonGroup/RadioButton';

type Classes = {
  group?: string;
  groupItem?: string;
};

type RadioButtonGroupOption = {
  value: string;
  label: string;
  isDisabled?: boolean;
  isError?: boolean;
  errorText?: string;
};

type RadioButtonGroupProps<T extends FieldValues> = {
  control?: Control<T>;
  name: Path<T>;
  options: RadioButtonGroupOption[];
  isDisabled?: boolean;
  selectedValue?: RadioButtonGroupOption['value'];
  classes?: Partial<Classes>;
  onChange?: (value: RadioButtonGroupOption, event: React.ChangeEvent<HTMLInputElement>) => void;
};

const RadioButtonGroup = <T extends FieldValues>({
  name,
  options,
  control,
  isDisabled,
  selectedValue,
  classes,
  onChange,
}: RadioButtonGroupProps<T>) => {
  const handleChange =
    (option: RadioButtonGroupOption) => (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(option, event);
    };

  if (!control)
    return (
      <div className={classes?.group}>
        {options.map((opt) => (
          <RadioButton
            className={classes?.groupItem}
            key={opt.label}
            value={opt.value}
            isSelected={selectedValue === opt.value}
            name={name}
            label={opt.label}
            isDisabled={opt.isDisabled || isDisabled}
            isError={opt.isError}
            errorText={opt.errorText}
            onChange={handleChange(opt)}
          />
        ))}
      </div>
    );
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <div className={classes?.group}>
            {options.map((opt) => (
              <RadioButton
                className={classes?.groupItem}
                value={opt.value}
                isSelected={field.value === opt.value}
                name={name}
                label={opt.label}
                key={opt.label}
                onChange={field.onChange}
                onBlur={field.onBlur}
                isDisabled={opt.isDisabled || isDisabled}
                isError={opt.isError}
                errorText={opt.errorText}
              />
            ))}
          </div>
        );
      }}
    />
  );
};

export { RadioButtonGroup };
export type { RadioButtonGroupOption, RadioButtonGroupProps };
