import { FC, SyntheticEvent } from 'react';

import { OptionContainer } from '@kit/components/CmSelect/OptionContainer';

import { useAppTranslation } from '@src/common';
import { CmSelectComponents, CmSelectValue, SelectOption, TextShorter } from '@src/kit';
import { bindStyles } from '@src/utils';

import styles from './Options.modules.scss';

const cx = bindStyles(styles);

type Props = {
  value?: CmSelectValue;
  options: SelectOption<unknown>[];
  isSelected: (option: SelectOption<unknown>) => boolean;
  onChange: (values: CmSelectValue, event?: SyntheticEvent) => void;
  onClose: () => void;
  multi?: boolean;
  withoutCheckmark?: boolean;
  searchable?: boolean;
  component?: CmSelectComponents['optionContainer'];
  noOptionsText?: string;
  refSelection?: HTMLDivElement;
  maxMenuHeight?: number;
};

const Options: FC<Props> = ({
  value,
  options,
  isSelected,
  onChange,
  onClose,
  multi,
  withoutCheckmark,
  searchable,
  component,
  noOptionsText,
  refSelection,
  maxMenuHeight,
}) => {
  const { t } = useAppTranslation();

  if (options.length === 0) {
    return (
      <TextShorter className={cx('no-options')}>{noOptionsText || t('NoOptions')}</TextShorter>
    );
  }

  return (
    <ul className={cx('options')}>
      {options.map((option) => (
        <OptionContainer
          key={option.value as string}
          selectedValue={value}
          option={option}
          isSelected={isSelected}
          onChange={onChange}
          onClose={onClose}
          multi={multi}
          withoutCheckmark={withoutCheckmark}
          searchable={searchable}
          component={component}
          noOptionsText={noOptionsText}
          selectionContainer={refSelection}
          maxMenuHeight={maxMenuHeight}
        />
      ))}
    </ul>
  );
};

export { Options };
