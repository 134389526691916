import React from 'react';

import { Form, FormProps } from '@common/components/Forms';

import { Button, FilterFormContainer, FilterWrapper, Icon } from '@src/kit';
import { useAppTranslation } from '@src/common';
import { bindStyles } from '@src/utils';

import styles from './FilterPanel.module.scss';

type Props<F> = FormProps<F> & {
  isOpen: boolean;
  children: React.ReactNode;
  onReset: () => void;
};

const cx = bindStyles(styles);

const FilterPanel = <F,>({ isOpen, onReset, children, ...formProps }: Props<F>) => {
  const { t } = useAppTranslation();

  return (
    <Form {...formProps}>
      <FilterWrapper isOpen={isOpen}>
        <FilterFormContainer
          controls={
            <>
              <Button
                key="reset"
                type="reset"
                variant="outline"
                text={t('Clear')}
                size="md"
                onClick={onReset}
              >
                <Icon
                  type="undo"
                  className={cx('resetIcon')}
                />
              </Button>
              <Button
                type="submit"
                key="apply"
                text={t('Apply')}
                size="md"
              />
            </>
          }
        >
          {children}
        </FilterFormContainer>
      </FilterWrapper>
    </Form>
  );
};

export { FilterPanel };
