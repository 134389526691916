import React, { useMemo } from 'react';

import {
  AzureRequestSubscriptionDTO,
  AzureSubscription,
} from '@common/components/AppToolbar/components/CloudConnect/types';

import { CLOUDNAME } from '@src/constants/formConstans';
import { FormInput, required, useAppTranslation } from '@src/common';
import { Tab, TabContext, TabList } from '@src/kit';
import { bindStyles } from '@src/utils';

import styles from './FormAzure.module.scss';
import { CloudConnectContent } from '../../CloudConnectContent';
import { FormCreateApplication } from './FormCreateApplication';
import { FormEnterpriseAgreement } from './FormEnterpriseAgreement';
import { AzureCreationType } from './FormAzure';

const cx = bindStyles(styles);

type Props = {
  fetchListSubscriptions: (data: AzureRequestSubscriptionDTO) => void;
  list: AzureSubscription[];
  isLoading: boolean;
  activeTab: AzureCreationType;
  onChangeTab: (value: string) => void;
};

const FormAzureView: React.FC<Props> = ({
  fetchListSubscriptions,
  list,
  isLoading,
  onChangeTab,
  activeTab,
}) => {
  const { t } = useAppTranslation();

  const renderedForm = useMemo(() => {
    switch (activeTab) {
      case AzureCreationType.CREATE_APPLICATION:
        return (
          <FormCreateApplication
            fetchListSubscriptions={fetchListSubscriptions}
            list={list}
            isLoading={isLoading}
          />
        );
      case AzureCreationType.ENTERPRISE_AGREEMENT:
        return <FormEnterpriseAgreement />;
    }
  }, [activeTab, fetchListSubscriptions, list, isLoading]);

  return (
    <div className={styles.modal}>
      <div className={styles.modalContainer}>
        <div className={styles.modalLeft}>
          <div className={styles.formWrap}>
            <CloudConnectContent>
              <div>
                <div className={styles.connectionBlock}>
                  <FormInput
                    name={CLOUDNAME}
                    rules={{ ...required('FieldRequired') }}
                    caption={t('cloudName')}
                    placeholder={t('createNameCloud')}
                  />
                </div>
                <div className={styles.connectionBlock}>
                  <TabContext value={activeTab}>
                    <TabList
                      tabListClassName={cx('tab-list')}
                      variant="underlined"
                      size="lg"
                      onChange={onChangeTab}
                    >
                      <Tab
                        label={t('cos.cloudConnection.azure.tab.createApplication')}
                        value={AzureCreationType.CREATE_APPLICATION}
                      />
                      <Tab
                        label={t('cos.cloudConnection.azure.tab.enterpriseAgreement')}
                        value={AzureCreationType.ENTERPRISE_AGREEMENT}
                      />
                    </TabList>
                  </TabContext>
                </div>
                {renderedForm}
              </div>
            </CloudConnectContent>
          </div>
        </div>
      </div>
    </div>
  );
};

export { FormAzureView };
