import { useEffect, useMemo, useState } from 'react';

import { NotificationColor } from '@common/api';
import { NotificationDTO, NotificationId } from '@common/components';
import {
  fetchNotificationsList,
  markAllAsRead,
  markNotificationAsRead,
} from '@common/components/AppToolbar/api/requests';
import { COLORS_NOTIFICATIONS_CARD } from '@common/components/AppToolbar/components/Notifications/notifications-params';

import { SelectOption } from '@src/@types';
import { NotificationOptionType, useAppTranslation } from '@src/common';
import { NotificationCardPayload } from '@src/kit';

const DEFAULT_PAGE = 0;
const DEFAULT_PAGE_SIZE = 10;

const useNotificationsEasy = (
  isOpen: boolean,
  onClose: () => void,
  refreshCount: (all?: boolean, markAsRead?: boolean) => void,
  defaultValue: SelectOption<NotificationOptionType>,
) => {
  const { t } = useAppTranslation();
  const [readIds, setReadIds] = useState<NotificationId[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [page, setPage] = useState<number>(0);
  const [notifications, setNotifications] = useState<NotificationDTO[]>([]);
  const [currentFilter, setCurrentFilter] =
    useState<SelectOption<NotificationOptionType>>(defaultValue);

  const currentFilterValue = useMemo(() => currentFilter?.value, [currentFilter]);

  const fetch = async () => {
    try {
      setError(false);
      setLoading(true);
      const status = currentFilter?.value;

      const list = await fetchNotificationsList({
        page,
        pageSize: DEFAULT_PAGE_SIZE,
        isViewed: status !== 'NEW',
      });

      if (list.data.length < DEFAULT_PAGE_SIZE) {
        setHasMore(false);
      }
      if (currentFilter?.value === 'READ') {
        const filteredList = list.data.filter((notification) => notification.showed);
        setNotifications((prev) => [...prev, ...filteredList]);
      } else {
        setNotifications((prev) => [...prev, ...list.data]);
      }
    } catch (err) {
      setError(true);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      void fetch();
    }
  }, [page, currentFilter]);

  const destroy = () => {
    setReadIds([]);
    setLoading(false);
    setHasMore(true);
    setPage(0);
    setNotifications([]);
    setCurrentFilter(defaultValue);
  };

  useEffect(() => {
    if (!isOpen) {
      destroy();
    }

    return destroy;
  }, [isOpen]);

  const loadMoreNotification = () => setPage(page + 1);

  const reset = () => {
    setPage(DEFAULT_PAGE);
    setNotifications([]);
    setHasMore(true);
  };

  const onChangeFilter = (option: SelectOption<NotificationOptionType>) => {
    reset();
    setCurrentFilter(option);
  };

  const onAllRead = async () => {
    try {
      await markAllAsRead();
      setReadIds([]);
      setNotifications((prev) => prev.map((item) => ({ ...item, showed: true })));
      refreshCount(true);
    } catch (err) {
      console.error(err);
    }
  };

  const markAsUnread = (id: NotificationId) => {
    setReadIds((prev) => prev.filter((item) => item !== id));
    setNotifications((prev) =>
      prev.map((item) => (String(item.id) === String(id) ? { ...item, showed: false } : item)),
    );
    refreshCount();
  };

  const markAsRead = (id: NotificationId) => {
    setReadIds((prev) => [...prev, id]);
    setNotifications((prev) =>
      prev.map((item) => (String(item.id) === String(id) ? { ...item, showed: true } : item)),
    );
    refreshCount(false, true);
    void markNotificationAsRead([id]);
  };

  const notificationCards: NotificationCardPayload[] = useMemo(
    () =>
      notifications.map((notification) => {
        const { id, showed, notificationStatus, params } = notification;
        const onActionClick = () => (showed ? markAsUnread(id) : markAsRead(id));
        const onLinkClick = () => {
          !showed && markAsRead(id);
          onClose();
        };
        const tooltipText = showed ? t('MarkAsUnread') : t('MarkAsRead');
        const color = COLORS_NOTIFICATIONS_CARD[notificationStatus as NotificationOptionType];

        return {
          ...notification,
          author: t('System'),
          onActionClick,
          tooltipText: tooltipText,
          linkButtonText: t('GoTo'),
          onLinkClick,
          color: color as NotificationColor | undefined,
          date: '',
          taskId: params.taskId,
        };
      }),
    [t, notifications],
  );

  const hasUnRead = useMemo(() => notifications.some((item) => !item.showed), [notifications]);

  return {
    readIds,
    hasMore,
    onAllRead,
    notifications,
    loading,
    error,
    onChangeFilter,
    loadMoreNotification,
    notificationCards,
    hasUnRead,
    currentFilterValue,
    currentFilter,
  };
};

export { useNotificationsEasy };
