import { CloudTypes, CurrencyTypes, Mb, SelectOption, UUID } from '@src/@types';
import { StorageProfile } from '@src/common';

enum Stages {
  'connectedClouds',
  'providerChoice',
  'providerForm',
  'connectResult',
  'cloudEdit',
}

type ConnectedCloudStatus =
  | 'ACTIVE'
  | 'STOPPED'
  | 'UPDATE_ERROR'
  | 'INVALID_CREDENTIAL'
  | 'WAITING_REPORT';

type ConnectedCloudsDataDTO = {
  cloudType: string;
  demo: boolean;
  name: string;
  createdAt: string;
  updatedAt: string;
  status: ConnectedCloudStatus;
  currency: CurrencyTypes;
  id?: string;
};

type ChangeStageData = {
  stage: Stages;
  title?: string;
};

type VCloudDirectorFetchProfilesDTO = {
  vcloudUrl: string;
  username: string;
  password: string;
  tenantAlias: string;
};

type OpenstackFetchProfilesDTO = {
  nativeRegionName: string;
  keystoneAuthUrl: string;
  username: string;
  password: string;
};

type VSphereFetchProfilesDTO = {
  username: string;
  password: string;
  url: string;
};

type FetchProfilesResponse = {
  storageProfiles: string[];
};

type VCloudDirectorStorageProfile = {
  key: string;
  value: string;
  maxSize: string;
};

type VCloudDirectorFormDTO = {
  name: string;
  vcloudUrl: string;
  username: string;
  password: string;
  tenantAlias: string;
  cpuPrice: string;
  memoryGbPrice: string;
  diskGbPrice: string;
  storageProfiles?: VCloudDirectorStorageProfile[];
};

type VCloudDirectorDTOFormNew = {
  name: string;
  vcloudUrl: string;
  username: string;
  password: string;
  tenantAlias: string;
};

type ProviderItemType = {
  title: string;
  provider: CloudTypes;
  disabled?: boolean;
  version?: string;
};

type AWSRegion = {
  name: string;
  code: string;
};

type AWSRequestDTO = {
  accessKey: string;
  cloudName: string;
  region: string;
  secretKey: string;
};

type AWSConnectionFormDTO = Omit<AWSRequestDTO, 'region'> & {
  region: SelectOption<string>;
};

type AWSUpdateRequestDTO = {
  accessKey: string;
  cloudName: string;
  region: string;
  secretKey?: string;
};
type AWSFormDTO = {
  cloudId: number;
  cloudName: string;
  accessKey: string;
  region: string;
};
type AWSUpdateFormDTO = Omit<AWSUpdateRequestDTO, 'region'> & {
  region: SelectOption<string>;
};

type AzureSubscription = {
  name: string;
  uuid: UUID;
};

type AzureResourceGroup = {
  uuid: UUID;
  name: string;
};

type AzureConnectionDTO = {
  clientId: string;
  clientSecret: string;
  cloudName: string;
  subscriptions: AzureSubscription[];
  tenantId: string;
};

type AzureEnterpriseConnectionDTO = {
  cloudName: string;
  enrolmentId: string;
  bearer: string;
};
type AzureEditDTO = AzureInfo & {
  cloudName: string;
  enrolmentId: string;
  bearer: string;
  clientSecret: string;
  subscriptions: string[];
};

type AzureUpdateDTO = {
  clientId: string;
  clientSecret?: string;
  cloudName: string;
  tenantId?: string;
};
type AzureConnectionFormDTO = Omit<AzureConnectionDTO, 'subscriptions'> &
  AzureEnterpriseConnectionDTO & {
    subscriptions: SelectOption<string>[];
  };

type AzureRequestSubscriptionDTO = {
  clientId: string;
  clientSecret: string;
  tenantId: string;
};

type AzureSubscriptionsDTO = {
  subscriptions: AzureSubscription[];
};

type VSphereDTO = {
  name: string;
  url: string;
  apiVersion: string;
  username: string;
  password: string;
  cpuPrice: number;
  memoryGbPrice: number;
  diskGbPrice: number;
  storageProfiles?: StorageProfile[];
};

type AzureInfo = {
  cloudId: string;
  cloudName: string;
  tenantId: string;
  clientId: string;
  enrolmentId: string;
};

type VSphereInfo = {
  cloudName: string;
  cpuPrice: number;
  memoryGbPrice: number;
  diskGbPrice: number;
  storageProfiles: StorageProfile[];
};

type VSphereFormDTO = {
  name: string;
  url: string;
  username: string;
  password: string;
  cpuPrice: number;
  memoryGbPrice: number;
  diskGbPrice: number;
  storageProfiles?: VCloudDirectorStorageProfile[];
};

type VSphereEditForm = {
  cloudName: string;
  cpuPrice: number;
  memoryGbPrice: number;
  diskGbPrice: number;
  storageProfiles?: VCloudDirectorStorageProfile[];
};

type OpenStackDTO = {
  name: string;
  keystoneAuthUrl: string;
  nativeRegionName: string;
  username: string;
  password: string;
  cpuPrice: number;
  memoryGbPrice: number;
  diskGbPrice: number;
  storageProfiles?: StorageProfile[];
};

type OpenStackInfo = {
  name: string;
  cpuPrice: number;
  memoryGbPrice: number;
  diskGbPrice: number;
  storageProfiles: StorageProfile[];
};

type OpenStackFormDTO = {
  name: string;
  cloudName: string;
  keystoneAuthUrl: string;
  nativeRegionName: string;
  username: string;
  password: string;
  cpuPrice: number;
  memoryGbPrice: number;
  diskGbPrice: number;
  domain?: string;
  storageProfiles?: VCloudDirectorStorageProfile[];
};

type ClassificationDiskDTO = {
  name: string;
  maxSize: Mb | null;
  price: string;
};

type HyperVDTO = {
  name: string;
  server: string;
  username: string;
  password: string;
  useHttps: boolean;
  port: number;
  diskClassifications: ClassificationDiskDTO[];
};

type FormDiskClassification = Omit<ClassificationDiskDTO, 'maxSize'> & {
  maxSize: string;
};

type FormDataHyperV = Omit<HyperVDTO, 'useHttps' | 'diskClassifications'> & {
  useHttps: 'used' | 'notUsed';
  diskClassifications: FormDiskClassification[];
};

export { Stages };
export type {
  ConnectedCloudsDataDTO,
  ConnectedCloudStatus,
  ProviderItemType,
  AWSRegion,
  AWSRequestDTO,
  AWSConnectionFormDTO,
  AzureConnectionDTO,
  AzureConnectionFormDTO,
  AzureRequestSubscriptionDTO,
  AzureSubscription,
  AzureSubscriptionsDTO,
  AzureResourceGroup,
  VSphereDTO,
  OpenStackDTO,
  HyperVDTO,
  ChangeStageData,
  VCloudDirectorFetchProfilesDTO,
  FetchProfilesResponse,
  VCloudDirectorStorageProfile,
  VCloudDirectorFormDTO,
  ClassificationDiskDTO,
  FormDataHyperV,
  OpenStackFormDTO,
  VSphereFormDTO,
  OpenstackFetchProfilesDTO,
  FormDiskClassification,
  VSphereFetchProfilesDTO,
  AzureEnterpriseConnectionDTO,
  AWSUpdateRequestDTO,
  AWSUpdateFormDTO,
  AWSFormDTO,
  AzureInfo,
  AzureUpdateDTO,
  AzureEditDTO,
  VSphereInfo,
  VSphereEditForm,
  OpenStackInfo,
  VCloudDirectorDTOFormNew,
};
