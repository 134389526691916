import { FC, useEffect, useState } from 'react';

import { EventDetailHeader } from '@common/components/EventDetail/EventDetailHeader';
import { EventDetailDTO, TaskId } from '@common/api/Notification/models';
import { NotificationApi } from '@common/api';
import { EventDetailFooter } from '@common/components/EventDetail/EventDetailFooter';
import { AxiosError } from 'axios';

import { Avatar, CloudItem, CMModal, DashValue, ModalProps, TextShorter } from '@src/kit';
import { bindStyles, formFullName, getDateTimeStringFormat } from '@src/utils';
import { useAppTranslation, useToast } from '@src/common';
import { CloudTypes } from '@src/@types';

import styles from './EventDetail.module.scss';

type EventDetailProps = Omit<ModalProps, 'children'> & {
  eventDetailId: TaskId;
  onClose: () => void;
};

const cx = bindStyles(styles);

const EventDetail: FC<EventDetailProps> = (props: EventDetailProps) => {
  const { eventDetailId, onClose } = props;
  const { t } = useAppTranslation();

  const [eventDetail, setEventDetail] = useState<EventDetailDTO>();

  const toast = useToast();

  const getTaskDetail = async (eventDetailId: TaskId) => {
    try {
      const res = await NotificationApi.getTaskDetail(eventDetailId);
      setEventDetail(res.data.result);
    } catch (error) {
      const { message, code } = error as AxiosError;
      const unknownMessage = message.split('.').pop()?.toUpperCase() === 'UNKNOWN';
      if (unknownMessage) {
        toast.error({
          text: t('cld.error.unknown.message', {
            code,
          }),
        });
      } else {
        toast.error({ text: t(message) });
      }
    }
  };

  const eventDate = eventDetail?.createdAt
    ? getDateTimeStringFormat(eventDetail?.createdAt || '')
    : undefined;

  useEffect(() => {
    void getTaskDetail(eventDetailId);
  }, [eventDetailId]);

  return (
    <CMModal
      {...props}
      closeOnDocumentClick={false}
      closeOnEscape={false}
      headerJSX={
        <EventDetailHeader
          onClose={onClose}
          id={eventDetailId}
        />
      }
      footerJSX={<EventDetailFooter onClose={onClose} />}
      contentClassName={cx('contentWrapper')}
      open
    >
      <div className={cx('detailInfo', 'detailBlock')}>
        <div className={cx('row')}>
          <span className={cx('rowTitle')}>{t('cld.eventDetail.date')}</span>
          <span>
            <DashValue
              condition={Boolean(eventDate)}
            >{`${eventDate?.dateFormatted} / ${eventDate?.timeFormatted}`}</DashValue>
          </span>
        </div>
        <div className={cx('row')}>
          <span className={cx('rowTitle')}>{t('cld.eventDetail.user')}</span>
          <span className={cx('userBlock')}>
            <Avatar
              firstName={eventDetail?.user?.firstName}
              lastName={eventDetail?.user?.lastName}
            />
            <DashValue condition={Boolean(eventDetail?.user?.lastName)}>
              <TextShorter>
                {formFullName(eventDetail?.user?.firstName, eventDetail?.user?.lastName, true)}
              </TextShorter>
            </DashValue>
          </span>
        </div>
        <div className={cx('row')}>
          <span className={cx('rowTitle')}>{t('cld.eventDetail.connect')}</span>
          <span>
            <DashValue condition={Boolean(eventDetail?.cloud?.cloudType)}>
              <CloudItem
                cloudType={eventDetail?.cloud?.cloudType as CloudTypes}
                cloudName={eventDetail?.cloud?.name}
                className={cx('cloudItemWrapper')}
              />
            </DashValue>
          </span>
        </div>
        <div className={cx('row')}>
          <span className={cx('rowTitle')}>{t('cld.eventDetail.typeEvent')}</span>
          <span>
            <DashValue condition={Boolean(eventDetail?.template?.code)}>
              {t(eventDetail?.template?.code as string, { ...eventDetail?.template?.params })}
            </DashValue>
          </span>
        </div>
      </div>
      <div className={cx('detailDescription', 'detailBlock')}>
        <div className={cx('descriptionTitle')}>{t('cld.eventDetail.descriptionError')}</div>
        <div className={cx('descriptionInfo')}>
          <DashValue condition={Boolean(eventDetail?.taskError)}>
            {eventDetail?.taskError}
          </DashValue>
        </div>
      </div>
    </CMModal>
  );
};

export { EventDetail };
