import { FC, useEffect, useMemo } from 'react';

import { EmptyPlug } from '@common/components/AppToolbar/components/Notifications/EmptyPlug';
import { OPTIONS } from '@common/components/AppToolbar/components/Notifications/notifications-params';
import { useNotificationsEasy } from '@common/components/AppToolbar/components/Notifications/NotificationsEasy/useNotificationsEasy';
import { NotificationsListView } from '@common/components/AppToolbar/components/Notifications/NotificationsList/NotificationsList.view';
import { HeaderSideModal } from '@kit/components/HeaderSideModal';
import { TaskId } from '@common/api/Notification/models';

import { NotificationOptionType, useAppTranslation } from '@src/common';
import { Icon, Select, Tooltip } from '@src/kit';
import { bindStyles } from '@src/utils';

import styles from './NotificationsEasy.module.scss';

const cx = bindStyles(styles);

type Props = {
  onClose: () => void;
  onOpen: () => void;
  refreshCount: () => void;
  isOpen: boolean;
  trigger: JSX.Element | ((isOpen: boolean) => JSX.Element);
  handleEventDetail: (id: TaskId) => void;
  isOpenedEventDetail: boolean;
};

const PLUG_TEXT: Partial<Record<NotificationOptionType, string>> = {
  ALL: 'common.notifications.noNotifications',
  NEW: 'common.notifications.noNewNotifications',
  READ: 'common.notifications.noReadNotifications',
};

const NotificationsEasy: FC<Props> = ({
  onClose,
  refreshCount,
  isOpen,
  trigger,
  onOpen,
  handleEventDetail,
  isOpenedEventDetail,
}) => {
  const { t } = useAppTranslation();

  const filteredOptions = useMemo(
    () => OPTIONS.map((item) => ({ ...item, label: t(item.label as string) })),
    [t],
  );

  const {
    onChangeFilter,
    onAllRead,
    loading,
    error,
    loadMoreNotification,
    notificationCards,
    hasMore,
    hasUnRead,
    currentFilterValue,
    currentFilter,
  } = useNotificationsEasy(isOpen, onClose, refreshCount, filteredOptions[0]);

  useEffect(() => {
    if (isOpen) {
      onChangeFilter({ ...currentFilter, label: t(currentFilter.meta as string) as string });
    }
  }, [t, isOpen]);

  return (
    <HeaderSideModal
      open={isOpen}
      onOpen={onOpen}
      closeOnDocumentClick={!isOpenedEventDetail}
      closeOnEscape={!isOpenedEventDetail}
      onClose={onClose}
      trigger={trigger}
      classes={{
        wrapper: cx('modalWrap'),
        inner: cx('modalInner'),
      }}
      modalHeader={
        <div className={cx('popupHeader')}>
          <div className={cx('headerContainer')}>
            <h3 className={cx('title')}>{t('popupNotifications')}</h3>
            <div
              className={cx('controllersContainer', {
                controllersContainerHidden: '',
              })}
            >
              <div className={cx('controls')}>
                <Select
                  value={currentFilter}
                  onChange={onChangeFilter}
                  isSearchable={false}
                  menuStyles={{ minWidth: 150 }}
                  options={filteredOptions}
                  size="md"
                  placeholder="Выберите option"
                  noOptionsText="noOptionsText"
                  withoutBorder
                />
                <div
                  className={cx('icon', 'eye', { eye_read: !hasUnRead })}
                  onClick={hasUnRead ? onAllRead : undefined}
                >
                  <Tooltip
                    text={
                      t(hasUnRead ? 'MarkAllAsRead' : 'common.notifications.allReaded') as string
                    }
                  >
                    <Icon type="eye-check" />
                  </Tooltip>
                </div>
                <div
                  className={cx('icon', 'close')}
                  onClick={onClose}
                >
                  <Icon type="close" />
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    >
      <div className={cx('content')}>
        <div className={cx('container')}>
          {notificationCards.length === 0 && !loading && (
            <EmptyPlug
              error={error}
              text={t(error ? 'Data loading error' : (PLUG_TEXT[currentFilterValue] as string))}
            />
          )}
          <NotificationsListView
            loading={loading}
            error={error}
            notificationCards={notificationCards}
            loadMore={loadMoreNotification}
            canLoadMore={hasMore}
            handleEventDetail={handleEventDetail}
          />
        </div>
      </div>
    </HeaderSideModal>
  );
};

export { NotificationsEasy };
