import React from 'react';

import cn from 'classnames/bind';

import { TitleProps } from './H1';
import styles from './Title.module.scss';

export const H2: React.FC<TitleProps> = ({ className, size = 'lg', children }) => {
  const cx = cn.bind(styles);
  return <h2 className={cx(className, 'heading', size)}>{children}</h2>;
};
