import React from 'react';
import { components, OptionProps } from 'react-select';

import cn from 'classnames/bind';
import { TextShorter } from '@kit/components';

import styles from './Select.module.scss';

type Props = OptionProps;

const cx = cn.bind(styles);

const Option: React.FC<Props> = ({ children, className, ...props }) => {
  const { isSelected, isFocused } = props;

  return (
    <div className={cx('optionContainer')}>
      <components.Option
        {...props}
        className={cx(className, 'option', { isSelected, isFocused })}
      >
        <TextShorter tooltip>{children}</TextShorter>
      </components.Option>
    </div>
  );
};

export { Option };
