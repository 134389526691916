import { OptionProps } from 'react-select';

import { FormSelect, FormSelectProps } from '@src/common';
import { CheckboxOption, SearchMenuList } from '@src/kit';
import {useMemo} from "react";

type Props<T> = FormSelectProps<T>;

const FilterSelect = <T,>(props: Props<T>) => {
  const {
    descriptionOnTop = true,
    isMulti = true,
    size = 'md',
    isSearchable = false,
    components,
    isDisabled,
  } = props;

  const componentsData = useMemo(
    () => ({
      Option: ({ children, ...props }: OptionProps) => (
        <CheckboxOption
          {...props}
          isDisabled={isDisabled || false}
          checkboxSize="md"
        >
          {children}
        </CheckboxOption>
      ),
      MenuList: SearchMenuList,
      ...components,
    }),
    [components, isDisabled],
  );

  return (
    <FormSelect
      {...props}
      isDisabled={isDisabled}
      isSearchable={isSearchable}
      size={size}
      descriptionOnTop={descriptionOnTop}
      isMulti={isMulti}
      components={componentsData}
    />
  );
};

export { FilterSelect };
