import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  FormDataHyperV,
  FormDiskClassification,
  HyperVDTO,
  Stages,
} from '@common/components/AppToolbar/components/CloudConnect/types';
import { connectHyperV } from '@common/components/AppToolbar/api/cloud-connections';
import { ConnectionStep } from '@common/components/AppToolbar/components/CloudConnect/Forms/FormHyperV/ConnectionStep';
import { StorageSettingsStep } from '@common/components/AppToolbar/components/CloudConnect/Forms/FormHyperV/StorageSettingsStep';

import { CLOUD_NAME_DICTIONARY } from '@src/constants';
import {
  Form,
  useAnalytics,
  useAppTranslation,
  useCloudConnectContext,
  useToast,
} from '@src/common';
import { Gb } from '@src/@types';

import { gbToMb } from '@utils/common';

const defaultClassificationValues: FormDiskClassification = {
  name: '',
  price: '',
  maxSize: '',
};

const defaultValues: FormDataHyperV = {
  port: 5986,
  useHttps: 'used',
  name: '',
  password: '',
  server: '',
  username: '',
  diskClassifications: [defaultClassificationValues],
};

const FormHyperV: React.FC = () => {
  const toast = useToast();
  const { t } = useAppTranslation();
  const form = useForm<FormDataHyperV>({
    defaultValues,
    mode: 'onSubmit',
  });
  const { logSuccessEvent, logErrorEvent } = useAnalytics();

  const [step, setStep] = useState<0 | 1>(0);

  const { selectStage, changeAgreeButtonSettings, changeDisagreeButtonSettings } =
    useCloudConnectContext();

  const onBackHandler = () => {
    selectStage({
      stage: Stages.providerChoice,
    });
    changeAgreeButtonSettings({
      text: t('Connect'),
    });
  };

  const createNewConnect = async (dto: FormDataHyperV) => {
    try {
      const { useHttps, diskClassifications, ...other } = dto;

      const form: HyperVDTO = {
        useHttps: useHttps === 'used',
        diskClassifications: diskClassifications.map((disk) => ({
          ...disk,
          maxSize: disk.maxSize ? gbToMb(Number(disk.maxSize) as Gb) : null,
        })),
        ...other,
      };

      const response = await connectHyperV(form);
      selectStage({
        stage: Stages.connectResult,
      });
      if (!response.data.success) {
        throw new Error(response.data.errorMessage);
      }
      toast.success({
        text: `${t('CloudConnectedSuccessfully', { cloud: CLOUD_NAME_DICTIONARY.HyperV })}`,
      });
      logSuccessEvent('Created cloud connection', { cloudType: CLOUD_NAME_DICTIONARY.HyperV });
    } catch (error) {
      toast.error({
        text: `${t(`FailedConnectCloud`, { cloud: CLOUD_NAME_DICTIONARY.HyperV })}`,
        hasClose: true,
        btnText: t('LearnMore'),
      });
      logErrorEvent('Created cloud connection', { cloudType: CLOUD_NAME_DICTIONARY.HyperV });
    }
  };

  const onNext = () => setStep(1);

  useEffect(() => {
    if (step === 0) {
      changeAgreeButtonSettings({
        text: t('Next'),
        onClick: form.handleSubmit(onNext),
      });
      changeDisagreeButtonSettings({
        onClick: onBackHandler,
      });
      return;
    }

    changeAgreeButtonSettings({
      text: t('Connect'),
      onClick: form.handleSubmit(createNewConnect),
    });
  }, [t, step]);

  return (
    <Form
      mode="onSubmit"
      formMethods={form}
      defaultValues={defaultValues}
    >
      {step === 0 ? <ConnectionStep /> : <StorageSettingsStep />}
    </Form>
  );
};

export { FormHyperV, defaultClassificationValues };
