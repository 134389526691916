import React from 'react';

import { AppSection } from '@kit/components';
import { Section } from '@kit/components/ReferencePopup/components/Section';

import { bindStyles } from '@src/utils';

import styles from '../ReferencePopup.module.scss';

type Props = {
  title: string;
  sections: AppSection[];
  onNavigate: (item: string) => void;
};

const cx = bindStyles(styles);

const SectionsList: React.FC<Props> = ({ title, sections, onNavigate }) => (
  <>
    {sections ? (
      <>
        <p className={cx('subtitle')}>{title}:</p>

        <div className={cx('sections')}>
          {sections.map((item) => (
            <Section
              key={item.name}
              item={item}
              onSelect={onNavigate}
            />
          ))}
        </div>
      </>
    ) : null}
  </>
);

export { SectionsList };
