import React from 'react';

import styles from './ModalText.module.scss';

type Props = {
  text?: string;
  size?: string;
};

const ModalText: React.FC<Props> = ({ text, children }) => {
  return (
    <span className={styles.span}>
      <p className={styles.text}>{text}</p>
      {children}
    </span>
  );
};

export { ModalText };
