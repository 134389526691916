import React, { useMemo } from 'react';

import * as dateFns from 'date-fns';

import {
  Button,
  Calendar,
  CalendarValue,
  Disclaimer,
  FastDatesProps,
  Icon,
  IntervalProps,
  Popup,
} from '@src/kit';
import { useAppTranslation, useDialog } from '@src/common';
import { bindStyles } from '@src/utils';

import styles from './CalendarBlock.modules.scss';

const cx = bindStyles(styles);

type Props = IntervalProps &
  FastDatesProps & {
    value: CalendarValue;
    currentValue: CalendarValue;
    onChangeValue: (value: CalendarValue) => void;
    setCurrentValue: (value: CalendarValue) => void;
    onReset: () => void;
    maxDate?: Date;
    minDate?: Date;
    disabled?: boolean;
  };

const CalendarBlock: React.FC<Props> = (props) => {
  const { isOpened, openPopup, closePopup } = useDialog();
  const { t } = useAppTranslation();
  const onOpenPopupHandler = () => {
    openPopup();
  };

  const inputValue = dateFns.format(
    (Array.isArray(props.value) ? props.value[0] : props.value) || new Date(),
    'dd.MM.yy',
  );

  const onSubmit = (value: CalendarValue) => {
    props.onChangeValue(value);
    closePopup();
  };

  const maxDateMONTH = useMemo(() => {
    return new Date();
  }, []);

  return (
    <div className={cx('container')}>
      <div className={cx('controls')}>
        <div className={cx('text')}>{t('cos.settings.tariffAndPrices.editDate.text')}</div>
        <Button
          active={isOpened}
          size="md"
          text={(inputValue && inputValue.split('-')[0]) || 'Начало с'}
          onClick={onOpenPopupHandler}
          variant="outline"
          textClassName={!inputValue ? cx('placeholder') : undefined}
          className={cx('button')}
          disabled={props.disabled}
        >
          <Icon type="calendarLight" />
        </Button>
        <Popup
          className={cx('popup')}
          open={isOpened}
          onClose={closePopup}
          repositionOnResize
          position="bottom left"
          closeOnDocumentClick
        >
          <Calendar
            {...props}
            onChangeValue={onSubmit}
            withFastDates
            maxDate={maxDateMONTH}
          />
        </Popup>
      </div>

      <Disclaimer
        text={t('cos.settings.tariffAndPrices.disclaimer.date')}
        variant="warning"
        className={cx('disclaimer')}
      />
    </div>
  );
};

export { CalendarBlock };
