import React from 'react';
import { components, OptionProps } from 'react-select';

import cn from 'classnames/bind';
import { SelectOption } from '@kit/components/Select/Select';

import styles from './Select.module.scss';

type Props = OptionProps;

const cx = cn.bind(styles);

const ValueWithLabel: React.FC<Props> = ({ ...props }) => {
  const { data, isFocused, isSelected } = props;
  const value = (data as SelectOption<unknown>).value as string;
  const label = (data as SelectOption<unknown>).label as string;

  return (
    <div className={cx('optionContainer')}>
      <components.Option
        {...props}
        className={cx('option', { isSelected, isFocused })}
      >
        <div className={styles.containerOptions}>
          <span>{label}</span>
          <span>{value}</span>
        </div>
      </components.Option>
    </div>
  );
};

export { ValueWithLabel };
