import AddSvg from '@assets/icons/add.svg';
import ArchitectSvg from '@assets/icons/architect.svg';
import ArchiveSvg from '@assets/icons/archive.svg';
import ArrowDownSvg from '@assets/icons/arrow-down.svg';
import ArrowLeftSvg from '@assets/icons/arrow-left.svg';
import ArrowNarrowDownSvg from '@assets/icons/arrow-narrow-down.svg';
import ArrowNarrowLeftSvg from '@assets/icons/arrow-narrow-left.svg';
import ArrowNarrowRightSvg from '@assets/icons/arrow-narrow-right.svg';
import ArrowNarrowUpSvg from '@assets/icons/arrow-narrow-up.svg';
import ArrowRightSvg from '@assets/icons/arrow-right.svg';
import ArrowUpSvg from '@assets/icons/arrow-up.svg';
import BackupRestoreSvg from '@assets/icons/backup-restore.svg';
import BarChartAltSvg from '@assets/icons/bar-chart-alt.svg';
import PersonPlus from '@assets/icons/person-plus.svg';
import BarChartSvg from '@assets/icons/bar-chart.svg';
import CalendarLightSvg from '@assets/icons/calendar-light.svg';
import CalendarSvg from '@assets/icons/calendar.svg';
import CallSvg from '@assets/icons/call.svg';
import CaretSvg from '@assets/icons/caret.svg';
import Check from '@assets/icons/check.svg';
import CheckBold from '@assets/icons/check-bold.svg';
import CheckboxUncheckSvg from '@assets/icons/checkbox-uncheck.svg';
import CheckboxSvg from '@assets/icons/checkbox.svg';
import ChevronDownSvg from '@assets/icons/chevron-down.svg';
import ChevronLeftSvg from '@assets/icons/chevron-left.svg';
import CircleCheckSvg from '@assets/icons/circle-check.svg';
import CircleFilledCheckSvg from '@assets/icons/circle-filled-check.svg';
import CircleHelpSvg from '@assets/icons/circle-help.svg';
import CircleInformationFilledSvg from '@assets/icons/circle-information-filled.svg';
import CircleInformationSvg from '@assets/icons/circle-information.svg';
import CircleWarningSvg from '@assets/icons/circle-warning.svg';
import ClipboardListSvg from '@assets/icons/clipboard-list.svg';
import ClockSvg from '@assets/icons/clock.svg';
import CloseSvg from '@assets/icons/close.svg';
import CloudUploadSvg from '@assets/icons/cloud-upload.svg';
import ClouderSvg from '@assets/icons/clouder.svg';
import CogSettingsSvg from '@assets/icons/cog-settings.svg';
import CompassSvg from '@assets/icons/compass.svg';
import CopySvg from '@assets/icons/copy.svg';
import CosterSvg from '@assets/icons/coster.svg';
import DashboardSvg from '@assets/icons/dashboard.svg';
import DeleteAltSvg from '@assets/icons/delete-alt.svg';
import DeleteSvg from '@assets/icons/delete.svg';
import DocumentDownload from '@assets/icons/document-download.svg';
import DocumentSvg from '@assets/icons/document.svg';
import DownloadSvg from '@assets/icons/download.svg';
import EditAltSvg from '@assets/icons/edit-alt.svg';
import EditSvg from '@assets/icons/edit.svg';
import EmailSvg from '@assets/icons/email.svg';
import EmptyCalendarSvg from '@assets/icons/empty-calendar.svg';
import EmptyDocumentSvg from '@assets/icons/empty-document.svg';
import EyeSvg from '@assets/icons/eye.svg';
import FavoriteSvg from '@assets/icons/favorite.svg';
import FilterAltSvg from '@assets/icons/filter-alt.svg';
import FilterSvg from '@assets/icons/filter.svg';
import FolderStrokeSvg from '@assets/icons/folder-stroke.svg';
import FolderSvg from '@assets/icons/folder.svg';
import GridSvg from '@assets/icons/grid.svg';
import HddSvg from '@assets/icons/hdd.svg';
import HomeSvg from '@assets/icons/home.svg';
import IdCardSvg from '@assets/icons/id-card.svg';
import IndeterminateCheckbox from '@assets/icons/indeterminate-checkbox.svg';
import LineChartSVG from '@assets/icons/line-chart.svg';
import LoadPdfSVG from '@assets/icons/load-pdf.svg';
import LocationMarkSvg from '@assets/icons/location-mark.svg';
import LoginSvg from '@assets/icons/login.svg';
import LogoutSvg from '@assets/icons/logout.svg';
import MessageSvg from '@assets/icons/message.svg';
import MinimizeSvg from '@assets/icons/minimize.svg';
import MoveFolderSvg from '@assets/icons/move-folder.svg';
import NetworkSvg from '@assets/icons/network.svg';
import NetworkOnSvg from '@assets/icons/network-on.svg';
import NetworkOffSvg from '@assets/icons/network-off.svg';
import NotificationSvg from '@assets/icons/notification.svg';
import OptionsHorizontalSvg from '@assets/icons/options-horizontal.svg';
import OptionsVertical from '@assets/icons/options-vertical.svg';
import OpenNewWindow from '@assets/icons/open-new-window.svg';
import PauseSvg from '@assets/icons/pause.svg';
import PanSvg from '@assets/icons/pan.svg';
import PdfSvg from '@assets/icons/pdf.svg';
import PhotoCameraSvg from '@assets/icons/photo-camera.svg';
import PlaySvg from '@assets/icons/play.svg';
import PlusSvg from '@assets/icons/plus.svg';
import QuestionMarkCircle from '@assets/icons/question-mark-circle.svg';
import RefreshSvg from '@assets/icons/refresh.svg';
import SearchSvg from '@assets/icons/search.svg';
import SettingsSvg from '@assets/icons/settings.svg';
import SpinnerSvg from '@assets/icons/spinner.svg';
import SpinnerProgressSvg from '@assets/icons/spinner-progress.svg';
import StarFilledSvg from '@assets/icons/star-filled.svg';
import StarHalfFilledSvg from '@assets/icons/star-half-filled.svg';
import TelegramSvg from '@assets/icons/telegram.svg';
import TwoRowsSvg from '@assets/icons/two-rows.svg';
import UndoSvg from '@assets/icons/undo.svg';
import Upload from '@assets/icons/upload.svg';
import UsersSvg from '@assets/icons/users.svg';
import VkSvg from '@assets/icons/vk.svg';
import WarningCircleSvg from '@assets/icons/warning-circle.svg';
import WarningSvg from '@assets/icons/warning.svg';
import XlsSvg from '@assets/icons/xls.svg';
import EyeOff from '@assets/icons/eye-off.svg';
import Update from '@assets/icons/update.svg';
import Coins from '@assets/icons/coins.svg';
import CircleErrorSvg from '@assets/icons/circle-error.svg';
import UserSvg from '@assets/icons/user.svg';
import GGCheck from '@assets/icons/gg_check.svg';
import ComputerSvg from '@assets/icons/computer.svg';
import EyeCheck from '@assets/icons/eye-check.svg';
import StopSvg from '@assets/icons/stop.svg';
import CheckCircleFill from '@assets/icons/check-circle-fill.svg';
import ExclamationFill from '@assets/icons/exclamation-fill.svg';
import InformationCircleFill from '@assets/icons/information-circle-fill.svg';
import Lock from '@assets/icons/lock.svg';
import ReorderAlt from '@assets/icons/reorder-alt.svg';
import GenericSorting from '@assets/icons/generic-sorting.svg';
import OfficeBuilding from '@assets/icons/office-building.svg';
import CloudSvg from '@assets/icons/cloud.svg';
import ActiveDirectory from '@assets/icons/active-directory.svg';
import LayersSvg from '@assets/icons/layers.svg';
import WindowsOS from '@assets/icons/windows.svg';
import LinuxOS from '@assets/icons/linux.svg';
import CustomOS from '@assets/icons/custom-os.svg';
import Security from '@assets/icons/Security.svg';
import ClipboardCheck from '@assets/icons/clipboard-check.svg';
import Performance from '@assets/icons/Performance.svg';
import CloudSearch from '@assets/icons/cloud-search.svg';
import Inbox from '@assets/icons/inbox.svg';
import NumberSortASC from '@assets/icons/number-sort-asc.svg';
import NumberSortDESC from '@assets/icons/number-sort-desc.svg';
import AZSortASC from '@assets/icons/az-sort-asc.svg';
import AZSortDESC from '@assets/icons/az-sort-desc.svg';
import Connected from '@assets/icons/connected.svg';
import Disconnected from '@assets/icons/disconnected.svg';
import List from '@assets/icons/list.svg';
import Chevron from '@assets/icons/chevron.svg';
import Idea from '@assets/icons/idea.svg';
import Console from '@assets/icons/console.svg';
import Restart from '@assets/icons/restart.svg';
import Book from '@assets/icons/book.svg';
import HelpBook from '@assets/icons/help-book.svg';
import Database from '@assets/icons/database.svg';
import IDTag from '@assets/icons/tag.svg';
import CPUIcon from '@assets/icons/cpu-icon.svg';
import RAMIcon from '@assets/icons/ram-icon.svg';

const dictionary = {
  pdf: PdfSvg,
  xls: XlsSvg,
  'photo-camera': PhotoCameraSvg,
  'circle-filled-check': CircleFilledCheckSvg,
  'delete-alt': DeleteAltSvg,
  'edit-alt': EditAltSvg,
  telegram: TelegramSvg,
  vk: VkSvg,
  'person-plus': PersonPlus,
  call: CallSvg,
  email: EmailSvg,
  add: AddSvg,
  architect: ArchitectSvg,
  archive: ArchiveSvg,
  'arrow-down': ArrowDownSvg,
  'arrow-left': ArrowLeftSvg,
  'arrow-narrow-down': ArrowNarrowDownSvg,
  'arrow-narrow-left': ArrowNarrowLeftSvg,
  'arrow-narrow-right': ArrowNarrowRightSvg,
  'arrow-narrow-up': ArrowNarrowUpSvg,
  'arrow-right': ArrowRightSvg,
  'arrow-up': ArrowUpSvg,
  'backup-restore': BackupRestoreSvg,
  'bar-chart-alt': BarChartAltSvg,
  'load-pdf': LoadPdfSVG,
  'bar-chart': BarChartSvg,
  calendar: CalendarSvg,
  caret: CaretSvg,
  check: Check,
  'check-bold': CheckBold,
  checkbox: CheckboxSvg,
  'checkbox-uncheck': CheckboxUncheckSvg,
  'chevron-down': ChevronDownSvg,
  'chevron-left': ChevronLeftSvg,
  'circle-check': CircleCheckSvg,
  'circle-help': CircleHelpSvg,
  'circle-information-filled': CircleInformationFilledSvg,
  'circle-warning': CircleWarningSvg,
  'circle-information': CircleInformationSvg,
  'clipboard-list': ClipboardListSvg,
  clock: ClockSvg,
  close: CloseSvg,
  'cloud-upload': CloudUploadSvg,
  clouder: ClouderSvg,
  'cog-settings': CogSettingsSvg,
  compass: CompassSvg,
  copy: CopySvg,
  coster: CosterSvg,
  dashboard: DashboardSvg,
  delete: DeleteSvg,
  document: DocumentSvg,
  'empty-document': EmptyDocumentSvg,
  'empty-calendar': EmptyCalendarSvg,
  favorite: FavoriteSvg,
  'filter-alt': FilterAltSvg,
  filter: FilterSvg,
  folder: FolderSvg,
  grid: GridSvg,
  hdd: HddSvg,
  home: HomeSvg,
  'id-card': IdCardSvg,
  'indeterminate-checkbox': IndeterminateCheckbox,
  'line-chart': LineChartSVG,
  'location-mark': LocationMarkSvg,
  login: LoginSvg,
  logout: LogoutSvg,
  'move-folder': MoveFolderSvg,
  notification: NotificationSvg,
  'options-horizontal': OptionsHorizontalSvg,
  pan: PanSvg,
  pause: PauseSvg,
  play: PlaySvg,
  plus: PlusSvg,
  'question-mark-circle': QuestionMarkCircle,
  search: SearchSvg,
  settings: SettingsSvg,
  spinner: SpinnerSvg,
  'spinner-progress': SpinnerProgressSvg,
  'star-filled': StarFilledSvg,
  'star-half-filled': StarHalfFilledSvg,
  warning: WarningSvg,
  'two-rows': TwoRowsSvg,
  undo: UndoSvg,
  users: UsersSvg,
  none: null,
  refresh: RefreshSvg,
  'options-vertical': OptionsVertical,
  'open-new-window': OpenNewWindow,
  'folder-stroke': FolderStrokeSvg,
  eye: EyeSvg,
  download: DownloadSvg,
  calendarLight: CalendarLightSvg,
  edit: EditSvg,
  warningCircle: WarningCircleSvg,
  message: MessageSvg,
  minimize: MinimizeSvg,
  upload: Upload,
  'document-download': DocumentDownload,
  network: NetworkSvg,
  'network-on': NetworkOnSvg,
  'network-off': NetworkOffSvg,
  'eye-off': EyeOff,
  update: Update,
  coins: Coins,
  'circle-error': CircleErrorSvg,
  user: UserSvg,
  ggCheck: GGCheck,
  computer: ComputerSvg,
  'eye-check': EyeCheck,
  stop: StopSvg,
  checkCircleFill: CheckCircleFill,
  exclamationFill: ExclamationFill,
  informationCircleFill: InformationCircleFill,
  lock: Lock,
  reorderAlt: ReorderAlt,
  genericSorting: GenericSorting,
  officeBuilding: OfficeBuilding,
  security: Security,
  performance: Performance,
  clipboardCheck: ClipboardCheck,
  cloud: CloudSvg,
  'active-directory': ActiveDirectory,
  layers: LayersSvg,
  'windows-os': WindowsOS,
  'linux-os': LinuxOS,
  'custom-os': CustomOS,
  cloudSearch: CloudSearch,
  inbox: Inbox,
  numberSortASC: NumberSortASC,
  numberSortDESC: NumberSortDESC,
  AZSortASC: AZSortASC,
  AZSortDESC: AZSortDESC,
  connected: Connected,
  disconnected: Disconnected,
  list: List,
  chevron: Chevron,
  idea: Idea,
  console: Console,
  restart: Restart,
  book: Book,
  database: Database,
  'help-book': HelpBook,
  'id-tag': IDTag,
  'cpu-icon': CPUIcon,
  'ram-icon': RAMIcon,
};

type IconTypes = keyof typeof dictionary;

export { dictionary };
export type { IconTypes };
