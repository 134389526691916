import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

import cn from 'classnames/bind';

import styles from './AppLink.module.scss';

export type AppLinkProps = NavLinkProps & {
  underline?: boolean;
  mailto?: string;
  disabled?: boolean;
  external?: boolean;
};

const cx = cn.bind(styles);

const AppLink: React.FC<AppLinkProps> = ({
  to,
  disabled,
  external,
  className,
  children,
  underline,
  mailto,
  onClick,
  ...restProps
}) => {
  const getClassNames: NavLinkProps['className'] = ({ isActive }) => {
    const classNameFromProp = typeof className === 'function' ? className({ isActive }) : className;
    return cx('link', classNameFromProp, {
      underline,
      disabled,
    });
  };

  const onLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (mailto) {
      window.location = mailto as unknown as Location;
      event.preventDefault();
    }
    onClick && onClick(event);
  };

  return external ? (
    <a
      href={to as string}
      className={getClassNames({ isActive: false })}
      rel="noreferrer"
      onClick={onLinkClick}
    >
      {children}
    </a>
  ) : (
    <NavLink
      to={to}
      className={getClassNames}
      {...restProps}
      onClick={onLinkClick}
    >
      {children}
    </NavLink>
  );
};

export { AppLink };
