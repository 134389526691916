import React, { useMemo } from 'react';

import { Permissions } from './types';
import { AccessControlContext } from './AccessControlContext';

type Props = {
  permissions: Permissions | null;
};

const initialState: Permissions = {
  create: [],
  read: [],
  update: [],
  delete: [],
  list: [],
  operation: [],
  modules: [],
};

const AccessProvider: React.FC<Props> = ({ children, permissions }) => {
  const value = useMemo(() => {
    if (permissions) {
      return permissions;
    }

    return initialState;
  }, [permissions]);

  return <AccessControlContext.Provider value={value}>{children}</AccessControlContext.Provider>;
};

export { AccessProvider };
