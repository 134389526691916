import { i18n } from 'i18next';
import i18next from '@common/i18n';

import { Language } from '@src/@types';

import { II18nService, Resource } from './II18nService';

class I18nService implements II18nService {
  private i18n: i18n;
  private static instance: I18nService;

  private constructor(i18n: i18n) {
    this.i18n = i18n;
  }

  static getInstance(): I18nService {
    if (!I18nService.instance) {
      I18nService.instance = new I18nService(i18next);
    }

    return I18nService.instance;
  }

  getCurrentLanguage(): Language {
    return this.i18n.language as Language;
  }

  changeLanguage(value: Language) {
    this.i18n.changeLanguage(value);
  }

  addResource(language: Language, namespace: string, resource: Resource) {
    this.i18n.addResourceBundle(language, namespace, resource);
    this.changeLanguage(language);
  }

  hasResource(language: Language, namespace: string): boolean {
    return this.i18n.hasResourceBundle(language, namespace);
  }
}

export { I18nService };
