import { CloudTypes } from '@src/@types';

import AWS from '@assets/images/providers/AWS.svg';
import Azure from '@assets/images/providers/Azure.svg';
import GCP from '@assets/images/providers/GoogleCloudPlatform.svg';
import HyperV from '@assets/images/providers/HyperV.svg';
import Nutanix from '@assets/images/providers/Nutanix.svg';
import OpenStack from '@assets/images/providers/OpenStack.svg';
import VKCloudSolutions from '@assets/images/providers/VKCloudSolutions.svg';
import VMware from '@assets/images/providers/VMware.svg';
import VSphere from '@assets/images/providers/VSphere.svg';
import YandexCloud from '@assets/images/providers/YandexCloud.svg';
import NonCloud from '@assets/images/providers/NonCloud.svg';

const CLOUD_NAME_DICTIONARY: Record<CloudTypes, string> = {
  AWS: 'AWS',
  Azure: 'Azure',
  GCP: 'Google Cloud Platform',
  HyperV: 'Hyper-V',
  Nutanix: 'Nutanix',
  OpenStack: 'OpenStack',
  vkCloudSolutions: 'VK Cloud Solutions',
  vCloudDirector: 'VMware Cloud Director',
  vSphere: 'vSphere',
  Yandex: 'Yandex.Cloud',
  NONCLOUD: 'Noncloud',
};

const CLOUD_ICON_DICTIONARY: Record<CloudTypes, React.ReactElement> = {
  AWS: <AWS />,
  Azure: <Azure />,
  GCP: <GCP />,
  HyperV: <HyperV />,
  Nutanix: <Nutanix />,
  OpenStack: <OpenStack />,
  vkCloudSolutions: <VKCloudSolutions />,
  vCloudDirector: <VMware />,
  vSphere: <VSphere />,
  Yandex: <YandexCloud />,
  NONCLOUD: <NonCloud />,
};

export { CLOUD_NAME_DICTIONARY, CLOUD_ICON_DICTIONARY };
