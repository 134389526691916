type TariffResource = {
  resourceUuid: string;
  article: string | null;
  articleName: string | null;
  skuUuid: string | null;
  resourceType: string;
  price: number | string | null;
  tariff: TariffResourceTypes;
  comment: string | null;
};

enum TariffResourceTypes {
  MONTH = 'MONTH',
  HOUR = 'HOUR',
  MIXED = 'MIXED',
}

enum TariffTypeVariants {
  PAYG = 'PAYG',
  MIXED = 'MIXED',
  FIXED = 'FIXED',
}

enum TariffBillingTypeVariants {
  PAYG = 'PAYG',
  FIXED = 'FIXED',
}

type Tariff = {
  uuid: string;
  cloudId: number;
  vdcUuid: string;
  vdcName: string;
  tariffType: TariffTypeVariants;
  name: string;
  tariff: TariffResourceTypes;
  resources: TariffResource[];
};

type FixRate = {
  itemUuid: string;
  itemName: string;
  price: number;
  quantity: number;
  tariff: TariffResourceTypes;
};

type TariffsResponse = {
  endAt?: string;
  startAt?: string;
  tariffPrices: Tariff[];
  fixRates: FixRate[];
};

type TariffPriceDTO = {
  cloudId: number;
  name: string | null;
  tariffResources: TariffPriceResourceDTO[];
  tariffType: TariffTypeVariants;
  uuid: null | string;
  vdcName: string;
  vdcUuid: null | string;
};

type TariffPriceResourceDTO = {
  quotas: null | TariffPriceResourceQuotaDTO[];
  resourceType: string;
  resourceUuid: string;
  skuName: null | string;
  skuUuid: null | string;
  uuid: null | string;
};

type TariffPriceResourceQuotaDTO = {
  quotaFrom: number | null;
  quotaTo: number | null;
  rate: number;
  billingType: TariffBillingTypeVariants;
};

type TariffTypeDTO = {
  code: string;
  tariffType: string;
};

type TariffPricesResponse = {
  endAt?: string;
  startAt?: string;
  tariffPrices: TariffPriceDTO[];
  fixRates: FixRate[] | null;
};

export type {
  Tariff,
  TariffResource,
  TariffsResponse,
  FixRate,
  TariffTypeDTO,
  TariffPriceResourceDTO,
  TariffPriceDTO,
  TariffPriceResourceQuotaDTO,
  TariffPricesResponse,
};
export { TariffResourceTypes, TariffTypeVariants, TariffBillingTypeVariants };
