type UserInfoDTO = {
  uuid?: string;
  name?: string;
  surname?: string;
  company?: string;
  login?: string;
  tel?: string | number;
  role?: string;
  avatar?: string;
};

export enum CustomerSettingsModule {
  COSTER = 'COSTER',
  CLOUDER = 'CLOUDER',
}

export enum CustomerSettingsType {
  UI = 'UI',
}

type CustomerSettingsDTO = {
  section: string;
  module: CustomerSettingsModule;
  type: CustomerSettingsType;
  available: boolean;
};

export type { UserInfoDTO, CustomerSettingsDTO };
