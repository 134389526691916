import { createContext, useContext } from 'react';

import { ConnectedCloudsDataDTO } from '@common/components/AppToolbar/components/CloudConnect/types';

type CloudsContextState = {
  isNewConnection: boolean;
  editableCloud: ConnectedCloudsDataDTO | null;
  changeEditableCloud: (cloud: ConnectedCloudsDataDTO | null) => void;
  openConnection: () => void;
  closeConnection: () => void;
  open: boolean;
  onOpen: (open: boolean) => void;
  cloudEdit: boolean;
  setCloudEdit: (cloudEdit: boolean) => void;
  onNavigate?: (to: 'tariffs') => void;
};

const CloudsContext = createContext({} as CloudsContextState);

const useCloudsContext = () => useContext(CloudsContext);

export { CloudsContext, useCloudsContext };
