import React from 'react';

import cn from 'classnames';

import styles from './Menu.module.scss';

type Props = React.ComponentPropsWithRef<'menu'>;

const Menu: React.FC<Props> = ({ children, className, ...restProps }) => {
  const menuClassNames = cn(styles.menu, className);

  return (
    <menu
      className={menuClassNames}
      {...restProps}
    >
      {children}
    </menu>
  );
};

export { Menu };
