import React from 'react';
import toast from 'react-hot-toast';
import { Toast } from 'react-hot-toast/dist/core/types';

import cn from 'classnames';
import { Button } from '@kit/components/Button';
import { Icon } from '@kit/components/Icon';

import styles from '../Toast.module.scss';
import { ToastText } from '../ToastText';
import { ToastTitle } from '../ToastTitle';
import stylesError from './ToastError.module.scss';

type Props = {
  text?: string;
  titleText?: string;
  hasClose?: boolean;
  btnText?: string;
  onRetry?: () => void;
  toastItem?: Toast;
  size?: 'md' | 'lg';
};

const ToastError: React.FC<Props> = ({
  text,
  titleText,
  hasClose,
  btnText,
  size = 'md',
  onRetry = () => null,
  toastItem,
}) => {
  const onClose = () => toast.dismiss(toastItem?.id);
  const cls = cn(styles.toastContainer, styles[size], stylesError.toastErrorContainer);
  return (
    <div className={cls}>
      <Icon
        size="xl"
        type="warning"
        className={stylesError.colorIcon}
      />
      {(titleText || text) && (
        <div className={styles.toastContainerText}>
          {titleText && <ToastTitle title={titleText} />}
          <ToastText text={text} />
        </div>
      )}
      {btnText && (
        <Button
          text={btnText}
          size="sm"
          variant="outline"
          onClick={onRetry}
          className={stylesError.btn}
        />
      )}
      {hasClose && (
        <Icon
          size="md"
          type="close"
          onClick={onClose}
          data-testid="toastErrorCloseIcon"
        />
      )}
    </div>
  );
};

export { ToastError };
export type { Props as ToastErrorProps };
