import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

import { AxiosError } from 'axios';
import {
  connectVSphere,
  getVSphereInfo,
  updateVSphere,
} from '@common/components/AppToolbar/api/cloud-connections';
import { useNewConnectionContext } from '@common/components/Clouds/components/NewConnection/NewConnectionContext';
import { StorageSettingsStep } from '@common/components/Clouds/components/NewConnection/Forms/VSphereConnection/StorageSettingsStep';
import { VSphereDTO } from '@common/api/VSphere/models';
import { ConnectionSteps } from '@common/components/Clouds/components';
import {
  StatusInfo,
  StatusInfoVariant,
} from '@common/components/Clouds/components/NewConnection/StatusInfo';
import { CloudEditLoading } from '@common/components/AppToolbar/components/CloudConnect/CloudEdit/CloudEditLoading';
import { useCloudsContext } from '@common/providers/CloudsProvider';
import { VSphereInfo } from '@common/components/AppToolbar/components/CloudConnect/types';

import {
  Form,
  StorageProfile,
  useAnalytics,
  useAppTranslation,
  useFormApiError,
  useToast,
} from '@src/common';
import { bindStyles, ErrorUtils, gbToMb, mbToGb } from '@src/utils';
import { Button } from '@src/kit';
import { CloudTypes, Gb } from '@src/@types';
import { CLOUD_NAME_DICTIONARY } from '@src/constants';

import { ConnectionStep } from './ConnectionStep';
import styles from './VSphereConnection.module.scss';

const cx = bindStyles(styles);

const VSphereConnection: FC = () => {
  const {
    onBack,
    onNext,
    currentStep,
    statusInfoSettings,
    selectedProvider,
    changeStatusInfoSettings,
  } = useNewConnectionContext();
  const { cloudEdit, editableCloud } = useCloudsContext();
  const [initLoading, setInitLoading] = useState(true);
  const toast = useToast();
  const { t } = useAppTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { logSuccessEvent, logErrorEvent } = useAnalytics();

  const form = useForm<VSphereDTO>({
    mode: 'onBlur',
    defaultValues: {
      apiVersion: { label: '6.0', value: '6.0' },
      storageProfiles: [],
    },
  });

  const { apiErrors, handleError } = useFormApiError();

  const createNewConnect = async (DTO: VSphereDTO) => {
    try {
      setIsLoading(true);
      changeStatusInfoSettings({
        variant: StatusInfoVariant.LOADING,
        cloudName: DTO.name,
      });
      const storageProfiles: StorageProfile[] =
        DTO.storageProfiles?.map((item) => ({
          name: item.key,
          price: item.value,
          maxSize: item.maxSize.length ? gbToMb(Number(item.maxSize) as Gb) : undefined,
        })) || [];
      const apiVersion = DTO.apiVersion.value;
      await connectVSphere({ ...DTO, apiVersion, storageProfiles });
      logSuccessEvent('Created cloud connection', { cloud: CLOUD_NAME_DICTIONARY.vSphere });
      changeStatusInfoSettings({
        variant: StatusInfoVariant.SUCCESS,
        cloudName: DTO.name,
      });
    } catch (error) {
      changeStatusInfoSettings({
        variant: StatusInfoVariant.ERROR,
        cloudName: DTO.name,
      });
      const errorMsg = ErrorUtils.handleApiError(error);
      toast.error({ text: errorMsg });
      handleError(error as AxiosError);
      logErrorEvent('Created cloud connection', {
        cloud: CLOUD_NAME_DICTIONARY.vSphere,
        message: errorMsg,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onEditSubmit = useCallback(
    async (dto: VSphereDTO) => {
      try {
        if (editableCloud?.id) {
          setIsLoading(true);
          changeStatusInfoSettings({
            variant: StatusInfoVariant.LOADING,
            cloudName: dto.name,
          });
          const storageProfiles: StorageProfile[] =
            dto.storageProfiles?.map((item) => {
              return {
                name: item.key,
                price: String(item.value),
              };
            }) || [];

          const data: VSphereInfo = {
            cloudName: dto.name,
            storageProfiles,
            cpuPrice: dto.cpuPrice,
            diskGbPrice: dto.diskGbPrice,
            memoryGbPrice: dto.memoryGbPrice,
          };

          await updateVSphere(editableCloud.id, data);
          changeStatusInfoSettings({
            variant: StatusInfoVariant.SUCCESS,
            cloudName: dto.name,
          });
          toast.success({ text: t('cos.connect.cloud.edit.success') });
          logSuccessEvent('Edited cloud connection', {
            id: editableCloud?.id,
            cloudType: CLOUD_NAME_DICTIONARY.vSphere,
          });
        }
      } catch (e) {
        handleError(e as AxiosError);
        logErrorEvent('Edited cloud connection', {
          id: editableCloud?.id,
          cloudType: CLOUD_NAME_DICTIONARY.vSphere,
        });
        changeStatusInfoSettings({
          variant: StatusInfoVariant.ERROR,
          cloudName: dto.name,
        });
      } finally {
        setIsLoading(false);
      }
    },
    [editableCloud],
  );

  const onSubmit = useCallback(() => {
    if (currentStep === ConnectionSteps.SETTINGS) {
      form.handleSubmit(() => {
        onNext();
      })();
    } else if (currentStep === ConnectionSteps.POLICY) {
      if (cloudEdit) {
        onNext();
        form.handleSubmit(onEditSubmit)();
      } else {
        onNext();
        form.handleSubmit(createNewConnect)();
      }
    }
  }, [currentStep, cloudEdit]);

  const values = form.watch();

  const isDirty = useMemo(() => {
    const { username, password, url, name } = values;
    return !username || !password || !name || !url;
  }, [values]);

  const { append } = useFieldArray({
    control: form.control,
    name: 'storageProfiles',
  });

  const renderedSteps = useMemo(() => {
    switch (currentStep) {
      case ConnectionSteps.SETTINGS:
        return <ConnectionStep />;
      case ConnectionSteps.POLICY:
        return <StorageSettingsStep />;
    }
  }, [currentStep]);

  const fetchCloudInfo = useCallback(async () => {
    if (editableCloud?.id) {
      try {
        setInitLoading(true);
        const response = await getVSphereInfo(editableCloud.id);
        form.setValue('name', response.data.cloudName);
        form.setValue('cpuPrice', response.data.cpuPrice);
        form.setValue('memoryGbPrice', response.data.memoryGbPrice);
        form.setValue('diskGbPrice', response.data.diskGbPrice);
        if (response.data.storageProfiles) {
          response.data.storageProfiles.forEach((item) => {
            if (item) {
              append(
                {
                  value: String(item.price),
                  key: item.name,
                  maxSize: item.maxSize ? String(mbToGb(item.maxSize)) : undefined,
                },
                {
                  shouldFocus: false,
                },
              );
            }
          });
        }
      } catch (e) {
        handleError(e as AxiosError);
      } finally {
        setInitLoading(false);
      }
    }
  }, [editableCloud]);

  useEffect(() => {
    if (cloudEdit) {
      fetchCloudInfo();
    }
  }, [cloudEdit]);

  const buttonLoading = () => {
    if (cloudEdit) {
      return isLoading || initLoading;
    } else {
      return isLoading;
    }
  };

  const buttonText = useMemo(() => {
    if (currentStep === ConnectionSteps.SETTINGS) {
      return t('clouds.connection.button.next');
    } else if (currentStep === ConnectionSteps.POLICY && cloudEdit) {
      return t('cos.cloudConnect.edit.save');
    } else {
      return t('clouds.connection.button.connect');
    }
  }, [cloudEdit, currentStep]);

  return (
    <Form<VSphereDTO>
      formMethods={form}
      onSubmit={onSubmit}
      apiErrors={apiErrors}
      className={cx('form')}
    >
      {currentStep === ConnectionSteps.STATUS ? (
        <StatusInfo
          cloudType={selectedProvider as CloudTypes}
          variant={statusInfoSettings.variant}
          cloudName={statusInfoSettings.cloudName}
        />
      ) : (
        <>
          {initLoading && cloudEdit ? (
            <CloudEditLoading />
          ) : (
            <div className={cx('inputs')}>{renderedSteps}</div>
          )}
          <div className={cx('buttons')}>
            <Button
              text={t('clouds.connection.button.back')}
              variant="outline"
              onClick={onBack}
            />
            <Button
              text={buttonText}
              type="submit"
              isLoading={buttonLoading()}
              disabled={!cloudEdit && isDirty}
            />
          </div>
        </>
      )}
    </Form>
  );
};

export { VSphereConnection };
