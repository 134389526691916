import React from 'react';

import { Icon } from '@kit/components';

import { bindStyles } from '@src/utils';

import styles from '../hooks/ScreenConstructor.module.scss';
const cx = bindStyles(styles);

type Props = {
  mode?: 'warn' | 'text';
};

const InfoBlock: React.FC<Props> = ({ mode = 'text', children }) => (
  <div className={cx(`info-wrap_${mode}`)}>
    <div className={cx('info')}>
      <Icon
        className={cx('info__icon')}
        type={mode === 'text' ? 'circle-information-filled' : 'warning'}
      />
      <span>{children}</span>
    </div>
  </div>
);

export { InfoBlock };
