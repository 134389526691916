import toast from 'react-hot-toast';

import {
  ToastSuccess,
  ToastError,
  ToastWarning,
  ToastSuccessProps,
  ToastErrorProps,
  ToastWarningProps,
  ToastLoadingProps,
  ToastLoading,
} from '@kit/components/Toast';

type ReturnValue = {
  success: (props: ToastSuccessProps) => void;
  error: (props: ToastErrorProps) => void;
  warning: (props: ToastWarningProps) => void;
  loading: (props: ToastLoadingProps) => void;
};

const useToast = (): ReturnValue => {
  const success = (props: ToastSuccessProps) => {
    toast.custom((tItem) => (
      <ToastSuccess
        toastItem={tItem}
        {...props}
      />
    ));
  };

  const error = (props: ToastErrorProps) => {
    toast.custom((tItem) => (
      <ToastError
        toastItem={tItem}
        {...props}
      />
    ));
  };

  const warning = (props: ToastWarningProps) => {
    toast.custom((tItem) => (
      <ToastWarning
        toastItem={tItem}
        {...props}
      />
    ));
  };

  const loading = (props: ToastLoadingProps) => {
    toast.custom((tItem) => (
      <ToastLoading
        toastItem={tItem}
        {...props}
      />
    ));
  };

  return { success, error, warning, loading };
};

export { useToast };
