import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { FieldArray } from '@kit/components/FieldArray';
import {
  addActiveDirectorySettings,
  changeActiveDirectorySettings,
} from '@common/components/AppToolbar/api/requests';
import { AxiosError } from 'axios';

import {
  ActiveDirectoryDTO,
  Form,
  FormInput,
  required,
  urlPattern,
  useFormApiError,
  useToast,
  useTranslationPrefix,
} from '@src/common';
import { bindStyles } from '@src/utils';
import { Button } from '@src/kit';

import styles from './EditConnectionAD.module.scss';

const cx = bindStyles(styles);

type ActiveDirectoryFormDTO = {
  adUrl: string;
  clientId: string;
  clientSecret?: string;
  domains: { domain: string }[];
};

type EditConnectionADProps = {
  activeDirectoryInfo?: ActiveDirectoryFormDTO | null;
  isEditMode: boolean;
  changeMode: (reFetch?: boolean) => void;
  isLoadingFetch: boolean;
};

const EditConnectionAD: FC<EditConnectionADProps> = ({
  activeDirectoryInfo,
  isEditMode,
  changeMode,
  isLoadingFetch,
}) => {
  const { t, tPrefix } = useTranslationPrefix('cos.activeDirectory.form.');
  const toast = useToast();
  const { apiErrors, handleError } = useFormApiError();
  const [isLoading, setLoading] = useState<boolean>(false);

  const form = useForm<ActiveDirectoryFormDTO>({
    mode: 'onBlur',
    defaultValues: {
      adUrl: '',
      clientId: '',
      clientSecret: '',
      domains: [],
    },
  });

  useEffect(() => {
    if (activeDirectoryInfo) {
      form.reset(activeDirectoryInfo);
    }
  }, [activeDirectoryInfo]);

  const resetForm = () => {
    form.reset();
  };

  const onSubmit = async (formValues: ActiveDirectoryFormDTO) => {
    try {
      setLoading(true);

      const dto: ActiveDirectoryDTO = {
        adUrl: formValues.adUrl,
        clientId: formValues.clientId,
        clientSecret: formValues.clientSecret ? formValues.clientSecret : undefined,
        domains: formValues.domains.map((item) => item.domain),
      };

      !isEditMode
        ? await addActiveDirectorySettings(dto)
        : await changeActiveDirectorySettings(dto);

      toast.success({
        text: t(tPrefix('success')),
      });
      changeMode(true);
    } catch (error) {
      handleError(error as AxiosError);
    } finally {
      setLoading(false);
    }
  };

  const renderFields = (index: number, prefix: string) => (
    <>
      <FormInput
        name={`${prefix}.${index}.domain`}
        size="md"
        rules={required('FieldRequired')}
        placeholder={t(tPrefix('fieldArrayBlock.domain.placeholder'))}
        disabled={isLoadingFetch}
      />
    </>
  );

  return (
    <div className={cx('formWrapper')}>
      <Form<ActiveDirectoryFormDTO>
        formMethods={form}
        onSubmit={onSubmit}
        apiErrors={apiErrors}
      >
        <div className={cx('form')}>
          <FormInput
            className={cx('form__input', 'form__input', 'fade-in-1')}
            name="adUrl"
            rules={{ ...required('FieldRequired'), ...urlPattern('InputFormatError') }}
            caption={t(tPrefix('adUrl.caption'))}
            placeholder={`https:${t(tPrefix('adUrl.placeholder'))}`}
            disabled={isLoadingFetch}
          />
          <div className={cx('rowContainer')}>
            <FormInput
              className={cx('form__input', 'form__input_half', 'fade-in-2')}
              name="clientId"
              rules={required('FieldRequired')}
              caption={t(tPrefix('clientId.caption'))}
              placeholder={t(tPrefix('clientId.placeholder'))}
              hint={t(tPrefix('clientId.hint'))}
              disabled={isLoadingFetch}
            />
            <FormInput
              className={cx('form__input', 'form__input_half', 'fade-in-5')}
              name="clientSecret"
              rules={!isEditMode ? required('FieldRequired') : undefined}
              caption={t(tPrefix('clientSecret.caption'))}
              placeholder={
                !isEditMode
                  ? t(tPrefix('clientSecret.placeholder'))
                  : t(tPrefix('edit.clientSecret.placeholder'))
              }
              hint={t(tPrefix('clientSecret.hint'))}
              disabled={isLoadingFetch}
            />
          </div>
          <div className={cx('fieldArrayBlock')}>
            <p className={cx('fieldArrayBlockTitle')}>{t(tPrefix('fieldArrayBlock.title'))}</p>
            <div>
              <FieldArray
                name="domains"
                renderFields={renderFields}
                required
                appendText={t(tPrefix('fieldArrayBlock.appendText'))}
              />
            </div>
          </div>
          <div className={cx('buttonsWrapper')}>
            <Button
              text={t('Cancel')}
              size="md"
              variant="outline"
              disabled={isLoading}
              onClick={() => (isEditMode ? changeMode() : resetForm())}
            />
            <Button
              text={t('Save')}
              size="md"
              type="submit"
              disabled={isLoading}
            />
          </div>
        </div>
      </Form>
    </div>
  );
};

export { EditConnectionAD };
export type { ActiveDirectoryFormDTO };
