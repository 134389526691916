import React from 'react';

import cn from 'classnames/bind';
import { Card } from '@kit/components/Card';

import styles from './FilterWrapper.module.scss';

const cx = cn.bind(styles);

type Props = {
  isOpen?: boolean;
};

const FilterWrapper: React.FC<Props> = ({ children, isOpen }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <Card
      data-testid="filterContent"
      className={cx('filter')}
    >
      {children}
    </Card>
  );
};

export { FilterWrapper };
