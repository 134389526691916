import { useCallback, useState } from 'react';

import { SearchFilterField } from '@common/components/FilterPanel/SearchFilterField';

import { useAppTranslation } from '@src/common';

type Params = {
  onSubmitSearch: (value: string) => void;
};

const useSearchField = ({ onSubmitSearch }: Params) => {
  const { t } = useAppTranslation();

  const [value, setValue] = useState<string>('');

  const reset = (value = '') => setValue(value);

  const onSearch = useCallback(
    (resetValue?: boolean) => {
      if (resetValue) {
        reset();
        return;
      }
      onSubmitSearch(value);
    },
    [value, setValue],
  );

  const SearchField = (
    <SearchFilterField
      value={value}
      placeholder={t('Search')}
      onSearch={onSearch}
      onChange={setValue}
    />
  );

  return {
    SearchField,
    reset,
    value,
  };
};

export { useSearchField };
