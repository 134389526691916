import { FC } from 'react';

import { Button, Icon } from '@src/kit';
import { useAppTranslation } from '@src/common';
import { bindStyles } from '@src/utils';

import styles from './AddRowButton.module.scss';

type Props = {
  disabled?: boolean;
  onAdd: () => void;
};

const cx = bindStyles(styles);

const AddRowButton: FC<Props> = ({ onAdd, disabled }) => {
  const { t } = useAppTranslation();

  return (
    <Button
      className={cx('button')}
      variant="text"
      onClick={onAdd}
      disabled={disabled}
      text={t('cos.settings.tariffprices.configPlanModal.addQuota.button.text')}
    >
      <Icon
        className={cx('icon')}
        type="add"
        size="sm"
      />
    </Button>
  );
};

export { AddRowButton };
