import { useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import { FieldError } from 'react-hook-form/dist/types/errors';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';
import { MenuProps } from 'react-select';

import { MenuWithControls, SelectOption, SelectProps, Select } from '@src/kit';
import { useAppTranslation, usePopupControls } from '@src/common';
import { UUID } from '@src/@types';

import { getByPath } from '@utils/common';
import { ErrorUtils } from '@utils/ErrorUtils';

type Props<T> = Omit<SelectProps<T>, keyof ControllerRenderProps | 'noOptionsText'> & {
  noOptionsText?: string;
  name: string;
  rules?: RegisterOptions;
  value?: SelectOption<T> | null;
  noSearchInput?: boolean;
  onChange?: SelectProps<T>['onChange'];
  currentSelection?: string;
  withoutControls?: boolean;
  defaultOpenBehavior?: boolean;
};

const FormSelect = <T,>({
  name,
  rules,
  value,
  isMulti,
  isDisabled,
  onChange,
  noOptionsText,
  isError,
  closeMenuOnScroll,
  defaultOpenBehavior = false,
  currentSelection,
  components,
  options,
  withoutControls,
  closeMenuOnSelect = true,
  ...selectProps
}: Props<T>) => {
  const { formState, control } = useFormContext();
  const { t } = useAppTranslation();

  const { isOpened, openPopup, closePopup } = usePopupControls();

  const onOpen = () =>
    (!closeMenuOnSelect || !isOpened) && !defaultOpenBehavior && !isDisabled && openPopup();
  const onClose = () => isOpened && closePopup();

  useEffect(() => {
    if (defaultOpenBehavior) {
      return;
    }

    document.addEventListener('mousedown', onClose);
    closeMenuOnScroll && document.addEventListener('scroll', onClose);

    return () => {
      document.removeEventListener('mousedown', onClose);
      document.removeEventListener('scroll', onClose);
    };
  }, [isOpened, closeMenuOnScroll, defaultOpenBehavior]);

  useEffect(() => {
    onClose();
  }, [currentSelection]);

  const error = getByPath<FieldError>(formState.errors, name);
  const hasError = !value && (!!error || isError);
  const errorMsg = value ? undefined : ErrorUtils.handleFormError(error?.message, t).join('. ');

  const componentsData = useMemo(
    () => ({
      ...components,
      ...(isMulti && !withoutControls && options.length >= 7
        ? {
            Menu: ({ children, ...props }: MenuProps) => (
              <MenuWithControls
                {...props}
                clearText={t('cos.select.reset.all')}
                selectAllText={t('SelectAll')}
              >
                {children}
              </MenuWithControls>
            ),
          }
        : {}),
    }),
    [components, isMulti, options.length],
  );

  if (value === undefined) {
    return (
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => {
          const onChangeHandler = (value: SelectOption<T> | UUID) => {
            field.onChange(value);
            onChange?.(value);
            closeMenuOnSelect && onClose();
          };

          return (
            <Select
              {...field}
              {...selectProps}
              components={componentsData}
              options={options}
              onChange={onChangeHandler}
              errorText={errorMsg}
              isDisabled={isDisabled}
              isMulti={isMulti}
              isError={hasError}
              menuIsOpen={defaultOpenBehavior ? undefined : isOpened}
              onContainerClick={onOpen}
              noOptionsText={noOptionsText || t('NoOptions')}
              closeMenuOnSelect={!isMulti}
            />
          );
        }}
      />
    );
  }

  return (
    <Select
      {...selectProps}
      components={componentsData}
      options={options}
      value={value}
      onChange={onChange}
      errorText={errorMsg}
      isDisabled={isDisabled}
      isMulti={isMulti}
      isError={hasError}
      menuIsOpen={defaultOpenBehavior ? undefined : isOpened}
      onContainerClick={onOpen}
      noOptionsText={noOptionsText || t('NoOptions')}
      closeMenuOnSelect={!isMulti}
    />
  );
};

export { FormSelect };
export type { Props as FormSelectProps };
