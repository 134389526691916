import { FC } from 'react';

import { useNotificationsLoading } from '@common/components/AppToolbar/components/Notifications/NotificationsLoading/useNotificationsLoading';
import { NotificationsListView } from '@common/components/AppToolbar/components/Notifications/NotificationsList/NotificationsList.view';
import { HeaderSideModal } from '@kit/components/HeaderSideModal';
import { EmptyPlug } from '@common/components/AppToolbar/components/Notifications/EmptyPlug';
import { TaskId } from '@common/api/Notification/models';

import { useAppTranslation } from '@src/common';
import { bindStyles } from '@src/utils';
import { Icon } from '@src/kit';

import styles from './NotificationsLoading.module.scss';

const cx = bindStyles(styles);

type Props = {
  onClose: () => void;
  onOpen: () => void;
  isOpen: boolean;
  trigger: JSX.Element | ((isOpen: boolean) => JSX.Element);
  handleEventDetail: (id: TaskId) => void;
};

const NotificationsLoading: FC<Props> = ({
  onClose,
  isOpen,
  trigger,
  onOpen,
  handleEventDetail,
}) => {
  const { t } = useAppTranslation();

  const { loading, notificationCards, loadMoreNotification, hasMore } =
    useNotificationsLoading(isOpen);

  return (
    <HeaderSideModal
      open={isOpen}
      closeOnDocumentClick
      trigger={trigger}
      onClose={onClose}
      onOpen={onOpen}
      classes={{
        wrapper: cx('modalWrap'),
        inner: cx('modalInner'),
      }}
      modalHeader={
        <div className={cx('popupHeader')}>
          <div className={cx('headerContainer')}>
            <h3 className={cx('title')}>{t('BackgroundOperations')}</h3>
            <Icon
              type="close"
              className={cx('icon')}
              onClick={onClose}
            />
          </div>
        </div>
      }
    >
      <div className={cx('content')}>
        <div className={cx('container')}>
          {notificationCards.length === 0 && !loading && (
            <EmptyPlug text={t('common.notifications.process.notFound') as string} />
          )}
          <NotificationsListView
            loading={loading}
            notificationCards={notificationCards}
            loadMore={loadMoreNotification}
            canLoadMore={hasMore}
            handleEventDetail={handleEventDetail}
          />
        </div>
      </div>
    </HeaderSideModal>
  );
};

export { NotificationsLoading };
