import React from 'react';

import { useAppTranslation } from '@src/common';

import styles from './ProvidersHeader.module.scss';

type Props = {
  buttonsOptions: Record<string, string>;
  buttonSelected: string;
  setButtonSelected: (arg: string) => void;
};

const ProvidersHeader: React.FC<Props> = () => {
  const { t } = useAppTranslation();

  return (
    <div className={styles.container}>
      <h3 className={styles.header}>{t('ChooseAProvider')}</h3>
    </div>
  );
};

export { ProvidersHeader };
