import React, { ReactNode } from 'react';

import { Tooltip, Icon, TooltipClasses } from '@kit/components';

import { bindStyles } from '@src/utils';

import styles from './CollectDataCell.module.scss';

const cx = bindStyles(styles);

type CollectDataCellProps = {
  tooltip: string;
  value: string | number;
  children?: ReactNode;
};

const TT_CLASSES: TooltipClasses = {
  tooltip: cx('icon-cell__tooltip'),
};

const CollectDataCell: React.FC<CollectDataCellProps> = ({ value, tooltip, children }) => {
  if (Number(value) < 0) {
    return (
      <span className={cx('icon-cell')}>
        <Tooltip
          classes={TT_CLASSES}
          text={tooltip}
          place="top"
        >
          <Icon type="clock" />
        </Tooltip>
      </span>
    );
  }

  return <>{children || value}</>;
};

export { CollectDataCell };

export type { CollectDataCellProps };
