import { FC, useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

import {
  TariffBillingTypeVariants,
  TariffPriceResourceQuotaDTO,
} from '@common/components/TariffAndPricesComponent/api/models';
import { MixedTariffPriceEdit } from '@common/components/TariffAndPricesComponent/components/MixedTariffPriceEdit';

import { Button, Disclaimer, SideModal } from '@src/kit';
import { Form, useAppTranslation } from '@src/common';
import { bindStyles } from '@src/utils';

import { AddRowButton } from './AddRowButton';
import { Header } from './Header';
import styles from './ConfigurationTariffPlanModal.module.scss';

const cx = bindStyles(styles);

type Props = {
  vdcName: string;
  resourceType: string;
  onClose: () => void;
  initValues?: TariffPriceResourceQuotaDTO[] | null;
  onReady: (quotas: TariffPriceResourceQuotaDTO[]) => void;
};

type FormDTO = {
  fixQuotas: TariffPriceResourceQuotaDTO[];
  factQuotas: TariffPriceResourceQuotaDTO[];
};
const ConfigurationTariffPlanModal: FC<Props> = ({
  onClose,
  vdcName,
  resourceType,
  onReady,
  initValues,
}) => {
  const { t } = useAppTranslation();
  const form = useForm<FormDTO>({
    defaultValues: {
      fixQuotas:
        initValues?.filter((it) => it.billingType === TariffBillingTypeVariants.FIXED) || [],
      factQuotas:
        initValues?.filter((it) => it.billingType === TariffBillingTypeVariants.PAYG) || [],
    },
  });
  const factQuotas = useFieldArray({
    control: form.control,
    name: 'factQuotas',
  });
  const fixQuotas = useFieldArray({
    control: form.control,
    name: 'fixQuotas',
  });

  const values = form.watch();

  const appendFactQuota = () => {
    const lastQuota = values.factQuotas[values.factQuotas.length - 1];
    const lastFact = values.factQuotas.length ? lastQuota.quotaTo || 0 : 0;
    const lastFix = values.fixQuotas.length
      ? values.fixQuotas[values.fixQuotas.length - 1].quotaTo || 0
      : 0;
    const maxValue = Math.max(lastFix, lastFact);

    if (lastQuota && Math.sign(Number(lastQuota.quotaTo) - Number(lastQuota.quotaFrom)) === -1) {
      form.setError(`factQuotas.${values.factQuotas.length - 1}.quotaTo`, {
        message: t('cos.tariff.mixed.price.error'),
      });
      return;
    }

    factQuotas.append({
      quotaFrom: maxValue,
      quotaTo: null,
      billingType: TariffBillingTypeVariants.PAYG,
      rate: 0,
    });
  };

  const appendFixQuota = () => {
    const lastFact = values.factQuotas.length
      ? values.factQuotas[values.factQuotas.length - 1].quotaTo || 0
      : 0;
    const lastFix = values.fixQuotas.length
      ? values.fixQuotas[values.fixQuotas.length - 1].quotaTo || 0
      : 0;
    const maxValue = Math.max(lastFix, lastFact);

    fixQuotas.append({
      quotaFrom: maxValue,
      quotaTo: null,
      billingType: TariffBillingTypeVariants.FIXED,
      rate: 0,
    });
  };

  const handleRemoveFixQuota = (index: number) => {
    const currentQuota = values.fixQuotas[index];
    const nextFixQuota = values.fixQuotas[index + 1];

    if (nextFixQuota) {
      form.setValue(`fixQuotas.${index + 1}.quotaFrom`, currentQuota.quotaFrom);
    } else {
      const nextFactQuota = values.factQuotas[0];

      if (nextFactQuota) {
        form.setValue(`factQuotas.0.quotaFrom`, currentQuota.quotaFrom);
      }
    }

    fixQuotas.remove(index);
  };

  const handleRemoveFactQuota = (index: number) => {
    const currentQuota = values.factQuotas[index];
    const nextFactQuota = values.factQuotas[index + 1];

    if (nextFactQuota) {
      form.setValue(`factQuotas.${index + 1}.quotaFrom`, currentQuota.quotaFrom);
    }

    factQuotas.remove(index);
  };

  const renderedFixQuotas = useMemo(() => {
    return fixQuotas.fields.map((it, i) => (
      <MixedTariffPriceEdit
        key={it.id}
        nameKey="fixQuotas"
        index={i}
        remove={() => handleRemoveFixQuota(i)}
      />
    ));
  }, [fixQuotas.fields, values]);

  const renderedFactQuotas = useMemo(() => {
    return factQuotas.fields.map((it, i) => (
      <MixedTariffPriceEdit
        key={it.id}
        nameKey="factQuotas"
        index={i}
        remove={() => handleRemoveFactQuota(i)}
      />
    ));
  }, [factQuotas.fields, values]);

  const disabledAddFix = useMemo(() => {
    if (values.factQuotas.length) {
      return true;
    }

    return !!(values.fixQuotas.length && !values.fixQuotas[values.fixQuotas.length - 1].quotaTo);
  }, [values]);

  const disabledAddFact = useMemo(() => {
    return (
      !!(values.fixQuotas.length && !values.fixQuotas[values.fixQuotas.length - 1].quotaTo) ||
      !!(values.factQuotas.length && !values.factQuotas[values.factQuotas.length - 1].quotaTo)
    );
  }, [values]);

  const handleReady = () => {
    const quotas = [...values.fixQuotas, ...values.factQuotas].map((it) => ({
      ...it,
      rate: Number(it.rate),
      quotaTo: it.quotaTo && Number(it.quotaTo),
    }));
    let hasError = false;

    quotas.forEach((quota, index) => {
      if (quota.quotaTo && quota.quotaFrom && quota.quotaFrom >= quota.quotaTo) {
        hasError = true;
        const isPAYG = quota.billingType === TariffBillingTypeVariants.PAYG;
        form.setError(
          `${isPAYG ? 'factQuotas' : 'fixQuotas'}.${
            isPAYG ? index - values.factQuotas.length : index
          }.quotaTo`,
          {
            message: t('cos.tariff.mixed.price.error'),
          },
        );
      }
    });

    if (!hasError) {
      onReady(quotas);
      onClose();
    }
  };

  return (
    <SideModal
      open
      onClose={onClose}
      modalHeader={
        <Header
          vdcName={vdcName}
          resourceType={resourceType}
          onClose={onClose}
        />
      }
      modalFooter={
        <div className={cx('footer')}>
          <Button
            variant="outline"
            text={t('Cancel')}
            onClick={onClose}
          />
          <Button
            text={t('Ready')}
            onClick={handleReady}
          />
        </div>
      }
      closeOnDocumentClick={false}
      className={cx('modal')}
    >
      <Form formMethods={form}>
        <div className={cx('wrapper')}>
          <Disclaimer text={t('cos.settings.tariffprices.configPlanModal.disclaimer.first')} />
          <div className={cx('section')}>
            <div className={cx('sectionTitle')}>
              {t('cos.settings.tariffprices.configPlanModal.fixed.title')}
            </div>
            {renderedFixQuotas}
            <AddRowButton
              disabled={disabledAddFix}
              onAdd={appendFixQuota}
            />
          </div>
          <div className={cx('section')}>
            <div className={cx('sectionTitle')}>
              {t('cos.settings.tariffprices.configPlanModal.facted.title')}
            </div>
            <Disclaimer text={t('cos.settings.tariffprices.configPlanModal.disclaimer.fact')} />
            {renderedFactQuotas}
            <AddRowButton
              onAdd={appendFactQuota}
              disabled={disabledAddFact}
            />
          </div>
        </div>
      </Form>
    </SideModal>
  );
};

export { ConfigurationTariffPlanModal };
