import { RestService } from '@src/common';

import {
  Tariff,
  TariffPriceDTO,
  TariffPricesResponse,
  TariffsResponse,
  TariffTypeDTO,
} from './models';

const restService = RestService.getInstance();

export const fetchTariffsAndPrices = async (cloudId: string) => {
  const url = `/settings/api/v2/tariff/${cloudId}/tariffprice`;
  return restService.GET<void, TariffsResponse>(url);
};

export const fetchSaveTariffsAndPrices = async (dto: TariffsResponse, cloudId: string) => {
  const url = `/settings/api/v2/tariff/${cloudId}/tariffprice`;
  return restService.POST<TariffsResponse, Tariff[]>(url, { data: dto });
};

export const fetchSaveTariffsAndPricesUsed = async (dto: TariffsResponse, cloudId: string) => {
  const url = `/settings/api/v2/tariff/${cloudId}/tariffprice/recalc`;
  return restService.POST<TariffsResponse, Tariff[]>(url, { data: dto });
};

export const fetchTariffsTypes = async () => {
  const url = `/settings/api/v2/price/types`;
  return restService.GET<void, TariffTypeDTO[]>(url);
};

export const fetchTariffsAndPricesNew = async (cloudId: string) => {
  const url = `/settings/api/v2/price/${cloudId}`;
  return restService.GET<void, TariffPricesResponse>(url);
};

export const fetchSaveTariffsAndPricesUsedNew = async (
  dto: TariffPricesResponse,
  cloudId: string,
) => {
  const url = `/settings/api/v2/tariff/${cloudId}/tariffprice/recalc`;
  return restService.POST<TariffPricesResponse, TariffPriceDTO[]>(url, { data: dto });
};

export const fetchSaveTariffsAndPricesNew = async (dto: TariffPricesResponse) => {
  const url = `/settings/api/v2/price`;
  return restService.POST<TariffPricesResponse, TariffPriceDTO[]>(url, { data: dto });
};
