import { FC } from 'react';

import { Loader } from '@kit/components/Loader';

import { Icon } from '@src/kit/components/Icon';
import { bindStyles } from '@src/utils';

import styles from './StatusFetchBar.module.scss';

const cx = bindStyles(styles);

type IFetchBarStatus = 'pending' | 'resolved' | 'rejected';

type StatusFetchBarProps = {
  status: IFetchBarStatus;
  text: string;
};

type IStateStatus = {
  [key in IFetchBarStatus]: JSX.Element;
};

const ICONS: IStateStatus = {
  pending: <Loader className={cx('pendingIcon')} />,
  rejected: (
    <Icon
      type="circle-error"
      className={cx('rejectedIcon')}
    />
  ),
  resolved: (
    <Icon
      type="circle-check"
      className={cx('resolveIcon')}
    />
  ),
};

const StatusFetchBar: FC<StatusFetchBarProps> = ({ status, text }) => {
  return (
    <div className={cx(`statusBarWrapper`, status)}>
      {ICONS[status]}
      <p className={cx('statusBarText')}>{text}</p>
    </div>
  );
};

export { StatusFetchBar };

export type { IFetchBarStatus, StatusFetchBarProps };
