import React from 'react';

import { AppSection } from '@kit/components/ReferencePopup/@types/types';

import { bindStyles } from '@src/utils';

import styles from '../ReferencePopup.module.scss';

const cx = bindStyles(styles);

type Props = {
  item: AppSection;
  onSelect: (item: string) => void;
};

const Section: React.FC<Props> = ({ item, onSelect }) => {
  const select = () => onSelect(item.name);

  return (
    <div
      className={cx('sectionsItem')}
      onClick={select}
    >
      <div className={cx('sectionsItemInner')}>
        <span className={cx('sectionsItemIcon')}>{item.icon}</span>
        <span>{item.name}</span>
      </div>
    </div>
  );
};

export { Section };
