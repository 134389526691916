import { FC } from 'react';

import { Icon, IconTypes, Tooltip } from '@src/kit';
import { bindStyles } from '@src/utils';

import styles from './CloudCard.module.scss';

type Props = {
  icon: IconTypes;
  value: string;
  tooltipText?: string;
};

const cx = bindStyles(styles);

const InfoItem: FC<Props> = ({ icon, value, tooltipText }) => {
  if (tooltipText) {
    return (
      <Tooltip
        text={tooltipText}
        place="top"
      >
        <div className={cx('infoItem')}>
          <div className={cx('infoIcon')}>
            <Icon
              type={icon}
              size="sm"
            />
          </div>
          {value}
        </div>
      </Tooltip>
    );
  }

  return (
    <div className={cx('infoItem')}>
      <div className={cx('infoIcon')}>
        <Icon
          type={icon}
          size="sm"
        />
      </div>
      {value}
    </div>
  );
};

export { InfoItem };
