import React from 'react';

import cn from 'classnames/bind';
import { Button } from '@kit/components/Button';
import { RowId } from '@kit/components/Table';

import styles from './Operation.module.scss';

type TableOperation = {
  disabled?: boolean;
  id: React.Key;
  icon?: React.ReactNode;
  action: () => void;
  text: string;
  alignRight?: boolean;
};

type Props = {
  operations: TableOperation[];
  selectedRowIds?: RowId[];
  operationsClassName?: string;
};

const cx = cn.bind(styles);

const Operations: React.FC<Props> = ({ operations, operationsClassName }) => {
  return (
    <div className={cx('operations', operationsClassName)}>
      {operations.map(({ id, icon, text, action, alignRight }) => (
        <Button
          className={cx('operationItem', { right: alignRight })}
          key={id}
          variant="text"
          size="md"
          text={text}
          onClick={action}
        >
          {icon ?? null}
        </Button>
      ))}
    </div>
  );
};

export { Operations };
export type { TableOperation, Props as OperationProps };
