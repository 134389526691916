import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';

import { Textarea, TextareaProps } from '@src/kit';
import { ErrorUtils } from '@src/utils';
import { useAppTranslation } from '@src/common';

type Props = Omit<TextareaProps, keyof ControllerRenderProps> & {
  name: string;
  rules?: RegisterOptions;
};

const FormTextarea: React.FC<Props> = ({ name, rules, ...inputProps }) => {
  const {
    formState: { errors },
    control,
  } = useFormContext();
  const { t } = useAppTranslation();

  const isError = !!errors[name];
  const errorMsg = ErrorUtils.handleFormError(errors[name]?.message, t).join('. ');

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      render={({ field }) => {
        const onBlur = () => {
          if (typeof field.value === 'string') {
            field.onChange(field.value.trim());
          }
        };

        return (
          <Textarea
            {...field}
            {...inputProps}
            isError={isError}
            errorText={errorMsg}
            onBlur={onBlur}
          />
        );
      }}
    />
  );
};

export { FormTextarea };
