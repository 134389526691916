import { MultiValueProps, OptionProps, SingleValueProps } from 'react-select';
import React, { useMemo } from 'react';

import { FormSelect, FormSelectProps, useAppTranslation } from '@src/common';
import { bindStyles } from '@src/utils';
import {
  Avatar,
  CheckboxOptionsWithIcon,
  NoInputSearchMenuList,
  OptionWithIcon,
  SearchMenuList,
  SelectOption,
  TextShorter,
  Tooltip,
  User,
} from '@src/kit';

import styles from './UserSelect.module.scss';

type Props<T> = FormSelectProps<T>;

const cx = bindStyles(styles);

const checkboxOptions = ({ children, ...props }: OptionProps) => (
  <CheckboxOptionsWithIcon
    {...props}
    getIconTemplate={(data) => (
      <Avatar
        firstName={data.label.toString()}
        userId={data.value as string}
      />
    )}
  >
    <TextShorter tooltip>{children}</TextShorter>
  </CheckboxOptionsWithIcon>
);

const defaultOptions = ({ children, ...props }: OptionProps) => (
  <OptionWithIcon
    {...props}
    isShortened
    tooltip
    getIconTemplate={(data) => (
      <Avatar
        fullName={data.label.toString()}
        userId={data.value as string}
      />
    )}
  >
    {children}
  </OptionWithIcon>
);

const UserSelect = <T,>(props: Props<T>) => {
  const { descriptionOnTop = true, isMulti = true, size = 'md', closeMenuOnScroll = true } = props;

  const { t } = useAppTranslation();

  const componentsData = useMemo(
    () => ({
      Option: isMulti ? checkboxOptions : defaultOptions,
      MultiValue: (props: MultiValueProps) => {
        if (props.index > 0) {
          return null;
        }

        const [first, ...other] = props.getValue() as SelectOption<string>[];

        const createTooltip = () => {
          let otherUsers = [...other];
          const total = otherUsers.length;
          if (otherUsers.length > 4) {
            otherUsers = otherUsers.splice(0, 4);
          }
          const remainder = total - otherUsers.length;

          return (
            <div className={cx('tt')}>
              {otherUsers.map(({ value, label }) => (
                <User
                  withBorder={false}
                  className={cx('user-select')}
                  classNameText={cx('user-name')}
                  key={value}
                  uuid={value}
                  name={label as string}
                  size="md"
                  tooltip={false}
                />
              ))}
              {remainder > 0 && (
                <div className={cx('tt-footer')}>
                  <span>
                    ...{t('more')} {remainder}
                  </span>
                </div>
              )}
            </div>
          );
        };

        return (
          <div className={cx('multi-user-select')}>
            <User
              withBorder={false}
              size="md"
              name={first?.label as string}
              uuid={first?.value}
            />
            {other.length > 0 && (
              <Tooltip
                place="bottom"
                text={createTooltip()}
              >
                <span className={cx('counter')}>+{other.length}</span>
              </Tooltip>
            )}
          </div>
        );
      },
      SingleValue: (props: SingleValueProps) => {
        const { label, value } = props.data as SelectOption<string>;

        return (
          <User
            withBorder={false}
            size="md"
            name={label as string}
            uuid={value as string}
          />
        );
      },
      MenuList: isMulti ? SearchMenuList : NoInputSearchMenuList,
    }),
    [isMulti],
  );

  return (
    <FormSelect
      {...props}
      closeMenuOnSelect={!isMulti}
      closeMenuOnScroll={closeMenuOnScroll}
      isSearchable={false}
      size={size}
      descriptionOnTop={descriptionOnTop}
      isMulti={isMulti}
      components={componentsData}
    />
  );
};

export { UserSelect };
