import React from 'react';

import {
  ContactData,
  CurrencyData,
  ReferencePopupLocalization,
} from '@kit/components/ReferencePopup/@types/types';
import { Support } from '@kit/components/ReferencePopup/components/Support';
import { TourWidget, TourWidgetProps } from '@kit/components/ReferencePopup/components/TourWidget';
import { Currencies } from '@kit/components/ReferencePopup/components/Currencies';

import { bindStyles } from '@src/utils';

import stylesConstructor from '../hooks/ScreenConstructor.module.scss';
import styles from '../ReferencePopup.module.scss';

const cx = bindStyles(styles);

type Props = {
  currencies?: CurrencyData[];
  localization: ReferencePopupLocalization;
  contacts?: Partial<ContactData>;
  children: React.ReactNode;
  tourWidgetSettings?: TourWidgetProps;
  docsPortal?: React.ReactNode;
  onAbout: () => void;
};

const MainScreen: React.FC<Props> = ({
  localization,
  contacts,
  onAbout,
  tourWidgetSettings,
  currencies,
  children,
  docsPortal,
}) => (
  <div className={cx('mainSection', stylesConstructor.screen)}>
    <div>
      {docsPortal && docsPortal}
      {tourWidgetSettings && <TourWidget {...tourWidgetSettings} />}

      <div
        className={cx('about')}
        onClick={onAbout}
      >
        {children}
      </div>

      {currencies && (
        <Currencies
          title={localization.currenciesTitle}
          currencies={currencies}
        />
      )}
    </div>

    {contacts && (
      <Support
        title={localization.contactsTitle}
        contacts={contacts}
      />
    )}
  </div>
);

export { MainScreen };
