import React from 'react';
import { components, ControlProps } from 'react-select';

import cn from 'classnames/bind';

import { Size } from '@src/@types';

import styles from './Select.module.scss';

type Props = ControlProps & {
  size: Extract<Size, 'sm' | 'md' | 'lg'>;
  isError?: boolean;
  withoutBorder?: boolean;
  hover?: boolean;
  adaptiveOff?: boolean;
};

const cx = cn.bind(styles);

const Control: React.FC<Props> = ({
  children,
  isError,
  size,
  menuIsOpen,
  withoutBorder,
  hover,
  isDisabled,
  adaptiveOff,
  ...props
}) => (
  <components.Control
    {...props}
    isDisabled={isDisabled}
    menuIsOpen={menuIsOpen}
    className={cx('control', { isError, isDisabled, menuIsOpen, withoutBorder }, [size], {
      adaptiveOn: !adaptiveOff,
    })}
  >
    <div className={cx({ hover })} />
    {children}
  </components.Control>
);

export { Control };
