import { FC } from 'react';

import { FormInput, FormTextarea, useAppTranslation } from '@src/common';
import { bindStyles } from '@src/utils';

import styles from '../AzureConnection.module.scss';

const cx = bindStyles(styles);

const FormEnterpriseAgreement: FC = () => {
  const { t } = useAppTranslation();

  return (
    <>
      <div className={cx('connectionBlock')}>
        <FormInput
          name="enrolmentId"
          caption={t('cos.enrolmentId.caption')}
          placeholder={t('cos.enrolmentId.placeholder')}
        />
      </div>
      <div className={cx('connectionBlock')}>
        <FormTextarea
          style={{ height: '100%' }}
          name="bearer"
          caption={t('cos.bearer.caption')}
          placeholder={t('cos.bearer.placeholder')}
        />
      </div>
    </>
  );
};

export { FormEnterpriseAgreement };
