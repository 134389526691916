import React, { PropsWithChildren, useEffect } from 'react';
import { Column, Row, TableCommonProps, useFlexLayout, usePagination, useTable } from 'react-table';

import cn from 'classnames/bind';
import { useHandleClick } from '@common/hooks/useHandleClick';
import { TD } from '@kit/components/Table/TD';
import { TH } from '@kit/components/Table/TH';

import { SortDirections } from '@src/@types';

import styles from './Table.module.scss';

type RowId = string;

type Props<T extends Record<string, unknown>> = {
  columns: Column<T>[];
  data: T[];
  tableClassName?: string;
  headCellClassName?: string;
  rowClassname?: string;
  selectedClassName?: string;
  rowCellClassName?: string;
  footerCellClassName?: string;
  onChangeSort?: (key: string, direction: SortDirections) => void;
  pageSize?: number;
  fullPageTable?: boolean;
  getRowProps?: (row: Row<T>) => TableCommonProps;
  onRowClick?: (row: Row<T>, event?: React.MouseEvent) => void;
  onRowDbClick?: (row: Row<T>, event?: React.MouseEvent) => void;
  selectedRowIds?: RowId[];
  plug?: React.ReactNode | null;
};

const cx = cn.bind(styles);

const Table = <T extends Record<string, unknown>>(props: PropsWithChildren<Props<T>>) => {
  const {
    tableClassName,
    headCellClassName,
    rowCellClassName,
    rowClassname,
    selectedClassName,
    data,
    columns,
    onChangeSort,
    pageSize = 10,
    fullPageTable,
    getRowProps,
    onRowClick,
    onRowDbClick,
    selectedRowIds = [],
    plug,
  } = props;

  const { getTableBodyProps, prepareRow, headerGroups, page, setPageSize } = useTable(
    {
      columns,
      data,
      initialState: { pageSize },
    },
    usePagination,
    useFlexLayout,
  );

  useEffect(() => {
    setPageSize(pageSize);
  }, [pageSize]);

  const onClick = useHandleClick({
    onClick: onRowClick,
    onDbClick: onRowDbClick,
  });

  const tableClassnames = cx('table', tableClassName, { fullPageTable });
  const headCellClassnames = cx('th', headCellClassName);
  const rowCellClassnames = cx('td', rowCellClassName);
  const rowClassnames = cx('tr', rowClassname);
  const selectedClassNames = cx('selected', selectedClassName);

  const scrollDisabled = Boolean(data.length === 0 || plug);

  return (
    <div className={cx('container', { scrollDisabled })}>
      {plug && <div className={cx('plug')}>{plug}</div>}
      <table
        className={tableClassnames}
        role="table"
      >
        <thead>
          {headerGroups.map((group, index) => (
            <tr
              {...group.getHeaderGroupProps()}
              key={`header-${index}`}
              role="rowheader"
            >
              {group.headers.map((column) => {
                if (column.Header === '') {
                  return null;
                }

                return (
                  <TH<T>
                    key={column.id}
                    column={column}
                    className={headCellClassnames}
                    onChangeSort={onChangeSort}
                  >
                    {column.render('Header')}
                  </TH>
                );
              })}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps({})}>
          {data.length > 0 &&
            page.map((row) => {
              prepareRow(row);
              const getRowPropsTable = getRowProps && getRowProps(row);
              const selected = selectedRowIds.includes(row.id);
              const onTrClick = (event: React.MouseEvent) => onClick(row, event);

              return (
                <tr
                  {...row.getRowProps({
                    className: cx(rowClassnames, selected && selectedClassNames, {
                      clickable: onRowClick || onRowDbClick,
                    }),
                    ...getRowPropsTable,
                  })}
                  key={row.id}
                  onClick={onTrClick}
                >
                  {row.cells.map((cell) => (
                    <TD
                      key={cell.column.id}
                      cell={cell}
                      className={rowCellClassnames}
                    >
                      {cell.render('Cell')}
                    </TD>
                  ))}
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export { Table };
export type { Props as TableProps, RowId };
