import React from 'react';

import { usePopupControls } from '@common/hooks/usePopupControls';
import { Icon } from '@kit/components/Icon';
import { Menu } from '@kit/components/Menu';
import { Popup } from '@kit/components/Popup';

import styles from './MenuCell.module.scss';

type Props = {
  className?: string;
  popupClassName?: string;
};

const MenuCell: React.FC<Props> = ({ popupClassName = 'menu-cell', children }) => {
  const { isOpened, openPopup, closePopup } = usePopupControls();

  const trigger = (
    <Icon
      onClick={openPopup}
      type="options-horizontal"
      className={styles.icon}
    />
  );

  return (
    <Popup
      trigger={trigger}
      classNamePrefix={popupClassName}
      open={isOpened}
      className={styles.popup}
    >
      <Menu onClick={closePopup}>{children}</Menu>
    </Popup>
  );
};

export { MenuCell };
