import React from 'react';

import cn from 'classnames';
import { Icon, IconTypes } from '@kit/components/Icon';

import { Size } from '@src/@types';

import styles from './Loader.module.scss';

type Props = {
  size?: Size;
  type?: Extract<IconTypes, 'spinner' | 'spinner-progress'>;
  className?: string;
};

const Loader: React.FC<Props> = ({ size = 'xl', className, type = 'spinner' }) => {
  return (
    <Icon
      data-testid="loader"
      type={type}
      size={size}
      className={cn(styles.loader, className)}
    />
  );
};

export { Loader };
