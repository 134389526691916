import { PropsWithChildren } from 'react';
import { CellProps } from 'react-table';

import { TooltipContentItem } from '@kit/components';
import { MultiValueBlock } from '@kit/components/MultiValueBlock';
import { MultiValueBlockProps } from '@kit/components/MultiValueBlock/MultiValueBlock';

type Props<T extends Record<string, unknown>> = PropsWithChildren<Omit<CellProps<T>, 'value'>> &
  MultiValueBlockProps & {
    className?: string;
    value: TooltipContentItem[];
  };

const MultiValueCell = <T extends Record<string, unknown>>({
  value,
  className,
  ...restProps
}: Props<T>) => {
  const filteredValues = value.filter(Boolean);

  const itemsCount = filteredValues.length;
  const cellContent = itemsCount ? (
    <MultiValueBlock
      hasTooltip
      values={filteredValues}
      {...restProps}
    />
  ) : (
    '-'
  );

  return <div className={className}>{cellContent}</div>;
};

export { MultiValueCell };
