import { useMemo, useState } from 'react';

import { SelectOption } from '@src/kit';

const useSearch = (searchable: boolean, options: SelectOption<unknown>[]) => {
  const [searchValue, setSearchValue] = useState<string>('');

  const filteredOptions = useMemo(() => {
    if (!searchable) {
      return options;
    }
    return options.filter(
      ({ label }) => label.toString().toLowerCase().indexOf(searchValue.toLowerCase()) > -1,
    );
  }, [searchValue, searchable, options]);

  return {
    searchValue,
    onChangeSearchValue: setSearchValue,
    filteredOptions,
  };
};

export { useSearch };
