import { IRestService, RestService } from '@common/services';

import { LocaleResponse } from './models';

class LocaleApi {
  static restService: IRestService = RestService.getInstance();

  static fetchLocale() {
    const url = '/idm/api/locale';
    return this.restService.GET<LocaleResponse>(url);
  }
}

export { LocaleApi };
