import { FC, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

import { ConnectedCloudsDataDTO } from '@common/components/AppToolbar/components/CloudConnect/types';
import { fetchConnectedClouds } from '@common/components/AppToolbar/api/cloud-connections';
import { Tariff, TariffResource } from '@common/components/SettingsProject/api/models';
import {
  fetchSaveTariffsAndPrices,
  fetchTariffsAndPrices,
} from '@common/components/SettingsProject/api/tariffAndPrices';
import { TariffItem } from '@common/components/SettingsProject/contents/TariffAndPrices/components/TariffItem';
import { TableHeader } from '@common/components/SettingsProject/contents/TariffAndPrices/components/TableHeader';
import { AxiosError } from 'axios';

import { bindStyles } from '@src/utils';
import { Form, useAppTranslation, useFormApiError, useToast } from '@src/common';
import { Button, ChipTag, ChipTagVariant, ColorPulsator, H1 } from '@src/kit';
import { CLOUD_ICON_DICTIONARY } from '@src/constants';
import { CloudTypes } from '@src/@types';

import styles from './TariffAndPrices.module.scss';

const cx = bindStyles(styles);

type TariffFormResource = TariffResource & {
  vdcUuid: string;
  validations?: {
    price: boolean;
  };
  index: number;
};

type FormDTO = {
  resources: TariffFormResource[];
};

const TariffAndPrices: FC = () => {
  const { t } = useAppTranslation();
  const toast = useToast();
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCloudsLoading, setIsCloudsLoading] = useState(true);
  const [isTariffsLoading, setIsTariffsLoading] = useState(true);
  const [clouds, setClouds] = useState<ConnectedCloudsDataDTO[]>([]);
  const [selectedCloud, setSelectedCloud] = useState<ConnectedCloudsDataDTO>();
  const [tariffs, setTariffs] = useState<Tariff[]>([]);
  const form = useForm<FormDTO>({
    defaultValues: {
      resources: [],
    },
  });
  const { handleError } = useFormApiError();
  const { fields } = useFieldArray({
    control: form.control,
    name: 'resources',
  });

  const resources = form.watch('resources');

  const getClouds = async () => {
    try {
      setIsCloudsLoading(true);
      const response = await fetchConnectedClouds();
      const vdcClouds = response.data.filter(
        (it) => it.cloudType === 'vCloudDirector' && it.status === 'ACTIVE',
      );
      setClouds(vdcClouds);
      setSelectedCloud(vdcClouds[0]);
    } catch (err) {
      console.error(err);
    } finally {
      setIsCloudsLoading(false);
    }
  };

  const getTariffs = async () => {
    try {
      if (selectedCloud?.id) {
        setIsTariffsLoading(true);
        const response = await fetchTariffsAndPrices(selectedCloud.id);
        setTariffs(response.data);
        form.setValue(
          'resources',
          response.data.reduce(
            (res: TariffFormResource[], it) => [
              ...res,
              ...it.resources.map((resource, i) => ({
                ...resource,
                vdcUuid: it.vdcUuid,
                index: res.length + i,
                validations: {
                  price: resource.price !== null,
                },
              })),
            ],
            [] as TariffFormResource[],
          ),
        );
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsTariffsLoading(false);
    }
  };

  useEffect(() => {
    getClouds();
  }, []);

  useEffect(() => {
    if (selectedCloud?.id) {
      getTariffs();
    }
  }, [selectedCloud?.id]);

  const handleCancel = () => {
    setIsEdit(false);
    form.setValue(
      'resources',
      tariffs.reduce(
        (res: TariffFormResource[], it) => [
          ...res,
          ...it.resources.map((resource, i) => ({
            ...resource,
            vdcUuid: it.vdcUuid,
            index: res.length + i,
          })),
        ],
        [] as TariffFormResource[],
      ),
    );
    form.clearErrors();
  };

  const handleSave = async () => {
    try {
      if (selectedCloud?.id) {
        setIsLoading(true);
        const values = form.getValues();

        if (
          JSON.stringify(
            tariffs.reduce((res: TariffResource[], it) => [...res, ...it.resources], []),
          ) !==
          JSON.stringify(
            values.resources.map(({ vdcUuid, validations, index, ...it }) => ({
              ...it,
              index: null,
            })),
          )
        ) {
          const dto: Tariff[] = tariffs.map((tariff) => {
            return {
              ...tariff,
              resources: values.resources
                .filter((resource) => resource.vdcUuid === tariff.vdcUuid)
                .map((it) => {
                  return {
                    ...it,
                    articleName:
                      it.articleName === null || it.articleName === '' ? null : it.articleName,
                    article: it.article === null || it.article === '' ? null : it.article,
                    price: it.price === null || it.price === '' ? null : Number(it.price),
                  };
                }),
            };
          });
          await fetchSaveTariffsAndPrices(dto, selectedCloud?.id);
          await getTariffs();
          toast.success({ text: t('cos.settings.tariffprices.success') });
        } else {
          toast.success({ text: t('cos.settings.tariffprices.nochanges') });
        }

        setIsEdit(false);
      }
    } catch (e) {
      handleError(e as AxiosError);
      toast.error({ text: t('cost.settings.tariffprices.error') });
    } finally {
      setIsLoading(false);
    }
  };

  const renderedTariffs = useMemo(() => {
    return tariffs.map((it) => (
      <TariffItem
        key={it.vdcUuid}
        tariff={it}
        resources={fields.filter((field) => field.vdcUuid === it.vdcUuid)}
        isEdit={isEdit}
      />
    ));
  }, [tariffs, fields, isEdit, resources]);

  const handleSelectCloud = (cloud: ConnectedCloudsDataDTO) => {
    setSelectedCloud(cloud);
    form.clearErrors();
    form.setValue('resources', []);
  };

  return (
    <div className={cx('wrapper')}>
      <div className={cx('header')}>
        <H1 size="md">{t('cos.settings.tariffAndPrices')}</H1>
        {isCloudsLoading ? (
          <div className={cx('cloudsLoading')}>
            <ColorPulsator />
          </div>
        ) : (
          <div className={cx('clouds')}>
            {clouds.map((it) => (
              <ChipTag
                key={it.id}
                isActive={selectedCloud?.id === it.id}
                disabled={isEdit}
                variant={ChipTagVariant.FILLED_GRAY}
                text={
                  <div className={cx('cloudChip')}>
                    <div className={cx('icon')}>
                      {CLOUD_ICON_DICTIONARY[it.cloudType as CloudTypes]}
                    </div>
                    {it.name}
                  </div>
                }
                onClick={() => setSelectedCloud(it)}
              />
            ))}
          </div>
        )}
      </div>
      <div className={cx('controls')}>
        {isEdit ? (
          <>
            <Button
              text={t('cos.settings.tariffAndPrices.submit')}
              size="md"
              onClick={form.handleSubmit(handleSave)}
              isLoading={isLoading}
              disabled={isLoading}
            />
            <Button
              text={t('cos.settings.tariffAndPrices.cancel')}
              variant="outline"
              onClick={handleCancel}
              disabled={isLoading}
              size="md"
            />
            <div className={cx('editText')}>{t('cos.settings.tariffAndPrices.editText')}</div>
          </>
        ) : (
          <Button
            text={t('cos.settings.tariffAndPrices.edit')}
            onClick={() => setIsEdit(true)}
            disabled={isCloudsLoading || isTariffsLoading}
            isLoading={isCloudsLoading || isTariffsLoading}
            size="md"
          />
        )}
      </div>
      <TableHeader />
      <div className={cx('tariffsWrapper')}>
        <div className={cx('tariffs')}>
          <Form formMethods={form}>{renderedTariffs}</Form>
        </div>
      </div>
    </div>
  );
};

export { TariffAndPrices };
export type { TariffFormResource, FormDTO };
