import { FC } from 'react';

import { useAppTranslation } from '@src/common';
import { bindStyles } from '@src/utils';
import { CMModalHeader, Icon } from '@src/kit';

import styles from './EventDetailHeader.module.scss';

const cx = bindStyles(styles);

type EventDetailHeaderProps = {
  onClose: () => void;
  id: string;
};

const EventDetailHeader: FC<EventDetailHeaderProps> = ({ onClose, id }: EventDetailHeaderProps) => {
  const { t } = useAppTranslation();

  return (
    <CMModalHeader className={cx('wrapper')}>
      <div className={cx('titleWrapper')}>
        <div className={cx('title')}>
          <Icon type="circle-error" />
          <span>{t('StatusError')}</span>
        </div>
        <Icon
          className={cx('close')}
          type="close"
          onClick={onClose}
        />
      </div>
      <div className={cx('info')}>
        <span>ID: </span>
        {id}
      </div>
    </CMModalHeader>
  );
};

export { EventDetailHeader };
