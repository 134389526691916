import React from 'react';

import { NotificationColor, NotificationLink } from '@common/api';
import { TaskId } from '@common/api/Notification/models';

import { CurrencyTypes, Mb, UUID } from '@src/@types';

type NotificationId = number;

type Bookmark = {
  icon: React.ReactNode;
  system: AppSystem;
  label: string;
};

type AppSystem = 'coster' | 'clouder' | 'admin';

type UserDto = {
  createdAt: string | null;
  lastLoginAt: string | null;
  firstName: string;
  lastName: string;
  company?: string;
  login?: string;
  tel?: string;
  roles?: RoleDto[];
  avatar?: string;
  uuid: string;
};

type RoleDto = {
  id: UUID;
  name: string;
};

type ProfileStage = 'info' | 'update';

type BanksTypesDTO = 'CBR' | 'OANDA' | 'MANUAL';

type DictionaryCurrency = {
  cost: number;
  using?: boolean;
};

type CurrenciesSource = {
  [key in BanksTypesDTO]?: {
    updateAt?: string;
    locale_name?: string;
    USD: {
      INR: DictionaryCurrency;
      RUB: DictionaryCurrency;
    };
    INR: {
      USD: DictionaryCurrency;
      RUB: DictionaryCurrency;
    };
    RUB?: {
      USD: DictionaryCurrency;
      INR: DictionaryCurrency;
    };
  };
};

type CurrentRateDTO = {
  source: string;
  receiver: string;
  service?: number | string;
  rate?: number | string;
};

type DeleteCustomRateDTO = {
  source: string;
  receiver: string;
  rate: number;
};

type CurrenciesListItem = {
  code: string;
  name: string;
};

type CurrencyDTO = {
  code: CurrencyTypes;
};

enum CurrencyTab {
  CUSTOM = 'custom',
  PROVIDER = 'provider',
}

enum CurrencyBank {
  OANDA = 'OANDA',
  CBR = 'CBR',
}

type CurrencyPairDTO = {
  source: string;
  receiver: string;
  service?: number | string;
  rate?: number | string;
};

type UserAvatarDTO = {
  data: string;
};

type UserUpdateDTO = Pick<UserDto, 'firstName' | 'lastName' | 'company' | 'tel'> & {
  newPassword?: string;
  oldPassword?: string;
  updatePassword?: boolean;
};

type NotificationTechData = {
  start: string;
  end: string;
  clouder: boolean;
  coster: boolean;
  code: string;
  is_active: boolean;
};

type NotificationsModalType = 'unread' | 'loading';
type NotificationOptionType =
  | 'ALL'
  | 'NEW'
  | 'ERROR'
  | 'SUCCESS'
  | 'IN_PROGRESS'
  | 'WARNING'
  | 'READ';

type NotificationLoadingDTO = {
  id: NotificationId;
  message: string;
  startDateTime: string;
};

type NotificationDTO = {
  id: NotificationId;
  message: string;
  showed: boolean;
  source: string;
  time: string;
  color?: NotificationColor;
  links?: NotificationLink[];
  notificationStatus: NotificationOptionType | null;
  params: {
    taskId?: TaskId;
  };
};

type NotificationFiltersDTO = {
  notificationStatus?: NotificationOptionType | null;
  page?: number;
  pageSize?: number;
  isViewed?: boolean | null;
};

type NotificationFilterLoadingDTO = Omit<NotificationFiltersDTO, 'isViewed' | 'notificationStatus'>;

type YaCloudCreateDTO = {
  cloudName: string;
  accessKey: string;
  secretKey: string;
  bucketName: string;
  bucketCatalogName: string;
  oauthToken: string;
};

type YaCloudEditDTO = {
  cloudName: string;
  accessKey: string;
  secretKey?: string;
  bucketName: string;
  bucketCatalogName: string;
  cloudId: string;
};

type StorageProfileEditDTO = {
  name: string;
  maxSize?: number;
  price: number;
};

type VCloudEditDTO = {
  cloudId: string;
  tenantAlias: string;
  password?: string;
  name: string;
  vcloudUrl: string;
  username: string;
  cpuPrice: number;
  memoryGbPrice: number;
  diskGbPrice: number;
  storageProfiles: StorageProfileEditDTO[];
  forceSavePass?: boolean;
};

type VCloudEditNewDTO = {
  cloudId: string;
  tenantAlias: string;
  password?: string;
  name: string;
  vcloudUrl: string;
  username: string;
};

type YaCloudCridentialsDTO = {
  cloudName: string;
  serviceAccountId: string;
  keyId: string;
  privateKey: string;
};

type YaSubCloudDTO = {
  uuid: string;
  name: string;
  hasServiceAccount: boolean;
};

type YandexCloudServiceAccountDTO = {
  yandexCloudUuid: string;
  serviceAccountId: string;
  authKeyId: string;
  keyAuthAlgorithm: string;
  publicAuthKey: string;
  privateAuthKey: string;
};

type YandexCloudInfoDTO = {
  bucket: string;
  name: string;
  prefix: string;
  staticKey: string;
  secretKey?: string;
};

type ServiceAccountInfoDTO = {
  serviceAccountId: string;
  serviceAccountKeyId: string;
};

type ResourceUsedSizeDto = {
  name: string;
  usedSize: number;
};

type StorageProfile = {
  name: string;
  maxSize?: Mb;
  price: string;
};

type VCloudDirectorCreateDTO = {
  name: string;
  vcloudUrl: string;
  username: string;
  password: string;
  tenantAlias: string;
  cpuPrice: string;
  memoryGbPrice: string;
  diskGbPrice: string;
  storageProfiles?: StorageProfile[];
};

type VCloudDirectorCreateDTONew = {
  name: string;
  vcloudUrl: string;
  username: string;
  password: string;
  tenantAlias: string;
};

type ActiveDirectoryDTO = {
  adUrl: string;
  clientId: string;
  clientSecret?: string;
  domains: string[];
};

export type {
  NotificationId,
  Bookmark,
  AppSystem,
  UserDto,
  ProfileStage,
  BanksTypesDTO,
  CurrenciesSource,
  CurrentRateDTO,
  DeleteCustomRateDTO,
  CurrenciesListItem,
  CurrencyDTO,
  CurrencyPairDTO,
  RoleDto,
  UserAvatarDTO,
  UserUpdateDTO,
  NotificationsModalType,
  NotificationOptionType,
  NotificationLoadingDTO,
  NotificationDTO,
  NotificationFiltersDTO,
  NotificationFilterLoadingDTO,
  YaCloudCreateDTO,
  YaCloudEditDTO,
  YaCloudCridentialsDTO,
  YaSubCloudDTO,
  YandexCloudServiceAccountDTO,
  YandexCloudInfoDTO,
  ServiceAccountInfoDTO,
  VCloudDirectorCreateDTO,
  NotificationTechData,
  StorageProfile,
  VCloudEditDTO,
  StorageProfileEditDTO,
  ActiveDirectoryDTO,
  ResourceUsedSizeDto,
  VCloudDirectorCreateDTONew,
  VCloudEditNewDTO,
};

export { CurrencyTab, CurrencyBank };
