import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

import { AxiosError } from 'axios';
import { ConnectionStep } from '@common/components/AppToolbar/components/CloudConnect/Forms/FormVCloudDirector/ConnectionStep';
import { StorageSettingsStep } from '@common/components/AppToolbar/components/CloudConnect/Forms/FormVCloudDirector/StorageSettingsStep';
import {
  Stages,
  VCloudDirectorFetchProfilesDTO,
  VCloudDirectorFormDTO,
} from '@common/components/AppToolbar/components/CloudConnect/types';
import {
  checkCredentialVCloud,
  connectVcloud,
  fetchProfiles,
} from '@common/components/AppToolbar/api/cloud-connections';
import { CloudConnectContent } from '@common/components/AppToolbar/components/CloudConnect/CloudConnectContent';
import { ProfileItem } from '@common/components/AppToolbar/components/CloudConnect/ProfileItem';

import {
  Form,
  StorageProfile,
  useAnalytics,
  useCloudConnectContext,
  useFormApiError,
  useTranslationPrefix,
} from '@src/common';
import { gbToMb, bindStyles } from '@src/utils';
import { IFetchBarStatus } from '@src/kit';
import { Gb } from '@src/@types';
import { CLOUD_NAME_DICTIONARY } from '@src/constants';

import styles from './FormVCloudDirectror.module.scss';

const cx = bindStyles(styles);

const FormVCloudDirector: FC = () => {
  const { t } = useTranslationPrefix('cos.cloud.connect.cloudDirector.');
  const [step, setStep] = useState<1 | 2>(1);
  const requiredError = useMemo(() => t('FieldRequired'), [t]);
  const [statusFetch, setStatusFetch] = useState<IFetchBarStatus>();
  const { logSuccessEvent, logErrorEvent, logEvent } = useAnalytics();

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const form = useForm<VCloudDirectorFormDTO>({
    mode: 'onBlur',
    defaultValues: {
      storageProfiles: [],
    },
  });
  const { apiErrors, handleError } = useFormApiError();
  const formValues = form.watch();
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'storageProfiles',
  });
  const [isLoading, setIsLoading] = useState(false);

  const { selectStage, changeAgreeButtonSettings, changeDisagreeButtonSettings } =
    useCloudConnectContext();
  const onBackHandler = () => {
    selectStage({
      stage: Stages.providerChoice,
    });
  };

  const addProfile = useCallback(() => {
    append(
      {
        key: '',
        value: '',
        maxSize: '',
      },
      {
        shouldFocus: false,
      },
    );
  }, []);

  const onSubmit = useCallback(async (dto: VCloudDirectorFormDTO) => {
    try {
      setIsSubmitLoading(true);
      const storageProfiles: StorageProfile[] =
        dto.storageProfiles?.map((item) => {
          return {
            name: item.key,
            price: item.value,
            maxSize: item.maxSize.length ? gbToMb(Number(item.maxSize) as Gb) : undefined,
          };
        }) || [];

      await connectVcloud({ ...dto, storageProfiles });
      selectStage({
        stage: Stages.connectResult,
      });
      logSuccessEvent('Created cloud connection', {
        cloudType: CLOUD_NAME_DICTIONARY.vCloudDirector,
      });
    } catch (e) {
      handleError(e as AxiosError);
      logErrorEvent('Created cloud connection', {
        cloudType: CLOUD_NAME_DICTIONARY.vCloudDirector,
      });
    } finally {
      setIsSubmitLoading(false);
    }
  }, []);

  const loadProfiles = useCallback(async () => {
    try {
      setIsLoading(true);
      logEvent('Click Load Profiles', { cloudType: CLOUD_NAME_DICTIONARY.vCloudDirector });

      !formValues.username && form.setError('username', { message: requiredError });
      !formValues.vcloudUrl && form.setError('vcloudUrl', { message: requiredError });
      !formValues.tenantAlias && form.setError('tenantAlias', { message: requiredError });
      !formValues.password && form.setError('password', { message: requiredError });

      if (
        !formValues.username ||
        !formValues.vcloudUrl ||
        !formValues.tenantAlias ||
        !formValues.password
      ) {
        return;
      }

      const dto: VCloudDirectorFetchProfilesDTO = {
        password: formValues.password,
        vcloudUrl: formValues.vcloudUrl,
        tenantAlias: formValues.tenantAlias,
        username: formValues.username,
      };
      const res = await fetchProfiles(dto);
      res.data.storageProfiles.forEach((it) => {
        if (!formValues.storageProfiles?.some((field) => field.key === it)) {
          append({
            key: it,
            value: '',
          });
        }
      });
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setIsLoading(false);
    }
  }, [formValues, requiredError, fields]);

  const nextStep = () => setStep(2);

  const prevStep = () => setStep(1);

  const checkCredential = async (firstFormValues: VCloudDirectorFetchProfilesDTO) => {
    try {
      setStatusFetch('pending');
      const dto: VCloudDirectorFetchProfilesDTO = {
        password: firstFormValues.password,
        vcloudUrl: firstFormValues.vcloudUrl,
        tenantAlias: firstFormValues.tenantAlias,
        username: firstFormValues.username,
      };

      await checkCredentialVCloud(dto);
      await setStatusFetch('resolved');
      await nextStep();
    } catch (e) {
      handleError(e as AxiosError);
      setStatusFetch('rejected');
    }
  };

  const renderedProfiles = useMemo(() => {
    return fields.map((it, index) => (
      <ProfileItem
        key={it.id}
        setValue={form.setValue}
        onDelete={() => remove(index)}
        position={index}
        register={form.register}
        watch={form.watch}
        errors={form.formState.errors}
        clearErrors={form.clearErrors}
        otherValues={formValues.storageProfiles}
      />
    ));
  }, [form.formState.errors, fields, formValues]);

  useEffect(() => {
    if (step === 1) {
      changeDisagreeButtonSettings({
        onClick: onBackHandler,
      });
      changeAgreeButtonSettings({
        onClick: form.handleSubmit(checkCredential),
        isLoading: isSubmitLoading,
        text: t('Next'),
      });
    } else {
      changeDisagreeButtonSettings({
        onClick: prevStep,
      });
      changeAgreeButtonSettings({
        onClick: form.handleSubmit(onSubmit),
        isLoading: isSubmitLoading,
        text: t('Connect'),
      });
    }
  }, [isSubmitLoading, step]);

  return (
    <Form<VCloudDirectorFormDTO>
      formMethods={form}
      onSubmit={onSubmit}
      apiErrors={apiErrors}
    >
      <div className={cx('modalContainer')}>
        <CloudConnectContent>
          <div>
            {step === 1 ? (
              <ConnectionStep status={statusFetch} />
            ) : (
              <StorageSettingsStep
                isLoading={isLoading}
                loadProfiles={loadProfiles}
                renderedProfiles={renderedProfiles}
                addProfile={addProfile}
                fields={fields}
              />
            )}
          </div>
        </CloudConnectContent>
      </div>
    </Form>
  );
};

export { FormVCloudDirector };
