import React, { FC } from 'react';

import { format } from 'date-fns';

import { Button, CMModal, Disclaimer, Icon } from '@src/kit';
import { bindStyles } from '@src/utils';
import { useAppTranslation } from '@src/common';

import styles from './ConfirmModal.module.scss';

const cx = bindStyles(styles);

type Props = {
  date: Date;
  onClose: () => void;
  onConfirm: () => void;
};
const ConfirmModal: FC<Props> = ({ date, onConfirm, onClose }) => {
  const { t } = useAppTranslation();

  return (
    <CMModal
      open
      onClose={onClose}
      headerJSX={
        <div className={cx('header')}>
          <Icon
            className={cx('close')}
            type="close"
            onClick={onClose}
            size="xl"
          />
        </div>
      }
      footerJSX={
        <div className={cx('footer')}>
          <Button
            onClick={onClose}
            text={t('Cancel')}
            variant="outline"
          />
          <Button
            onClick={onConfirm}
            text={t('cos.settings.tariffAndPrices.confirmModal.button.confirm')}
          />
        </div>
      }
    >
      <div className={cx('container')}>
        <div className={cx('icon')}>
          <Icon type="circle-help" />
        </div>
        <div className={cx('title')}>{t('cos.settings.tariffAndPrices.confirmModal.title')}</div>
        <div className={cx('dateInfo')}>
          <div className={cx('textsContainer')}>
            <div className={cx('text')}>{t('cos.settings.tariffAndPrices.confirmModal.text')}</div>
            <div className={cx('text')}>
              {t('cos.settings.tariffAndPrices.confirmModal.text.sub')}
            </div>
          </div>
          <div className={cx('dateContainer')}>
            <Icon
              className={cx('calendarIcon')}
              type="calendarLight"
            />
            <div className={cx('date')}>{format(date, 'dd.MM.yy')}</div>
          </div>
        </div>

        <Disclaimer
          variant="warning"
          text={t('cos.settings.tariffAndPrices.confirmModal.disclaimer')}
        />
      </div>
    </CMModal>
  );
};

export { ConfirmModal };
