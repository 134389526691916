import { FC } from 'react';

import { bindStyles } from '@src/utils';
import { Button } from '@src/kit';
import { useAppTranslation } from '@src/common';

import styles from './EventDetailFooter.module.scss';

const cx = bindStyles(styles);

type EventDetailFooterProps = {
  onClose: () => void;
};

const EventDetailFooter: FC<EventDetailFooterProps> = ({ onClose }: EventDetailFooterProps) => {
  const { t } = useAppTranslation();

  return (
    <div className={cx('footerWrapper')}>
      <Button
        onClick={onClose}
        text={t('Close')}
        variant="outline"
        size="md"
      />
    </div>
  );
};

export { EventDetailFooter };
