import React, { CSSProperties, useMemo } from 'react';

import { H3, Icon, Modal, ModalProps } from '@kit/components';

import { isShowTechNotification, bindStyles } from '@src/utils';
import { Size } from '@src/@types';
import { useAppToolbarContext } from '@src/common';

import { isSafariDetect } from '@utils/isSafariDetect';

import styles from './SideModal.module.scss';

const cx = bindStyles(styles);

type Classes = {
  wrapper: string;
  modal: string;
  header: string;
  footer: string;
  content: string;
  inner: string;
};

type SideModalProps = ModalProps & {
  trigger?: JSX.Element | ((isOpen: boolean) => JSX.Element);
  modalHeader?: React.ReactNode;
  modalFooter?: React.ReactNode;
  classNamePrefix?: string;
  classes?: Partial<Classes>;
  titleText?: string | React.ReactNode;
  open: boolean;
  size?: Extract<Size, 'sm' | 'md' | 'lg'>;
  onClose: () => void;
  className?: string;
  contentStyle?: React.CSSProperties;
  overlayStyle?: React.CSSProperties;
  closeOnDocumentClick?: boolean;
};

const SideModal: React.FC<SideModalProps> = ({
  trigger,
  open,
  modalHeader,
  modalFooter,
  titleText,
  children,
  onClose,
  classes,
  size,
  contentStyle,
  overlayStyle,
  classNamePrefix,
  closeOnDocumentClick,
  className,
  ...rest
}) => {
  const { techNotification, system } = useAppToolbarContext();

  const showTechNotification = useMemo(
    () => isShowTechNotification(techNotification, system),
    [techNotification, system],
  );

  const DEFAULT_OVERLAY_STYLES: CSSProperties = { background: 'rgba(26, 32, 36, 0.2)', top: 0 };
  const DEFAULT_CONTENT_STYLES: CSSProperties = !showTechNotification
    ? { top: '48px', height: 'calc(100% - 48px)' }
    : { top: '81px', height: 'calc(100% - 81px)' };

  const isSafari = isSafariDetect();
  return (
    <Modal
      {...rest}
      trigger={trigger}
      open={open}
      overlayStyle={overlayStyle ? overlayStyle : DEFAULT_OVERLAY_STYLES}
      contentStyle={contentStyle ? contentStyle : DEFAULT_CONTENT_STYLES}
      onClose={onClose}
      closeOnDocumentClick={closeOnDocumentClick}
      classNamePrefix={classNamePrefix || 'side-modal'}
      className={cx(className, 'modalWrap', classes?.wrapper)}
      overlayClassName={cx('overlay')}
    >
      <article className={cx('modal', classes?.modal)}>
        {modalHeader ?? (
          <div className={cx('modalTitle', classes?.header)}>
            <div>{typeof titleText === 'string' ? <H3>{titleText}</H3> : titleText}</div>
            <div>
              <Icon
                type="close"
                className={cx('closeIcon')}
                onClick={onClose}
              />
            </div>
          </div>
        )}
        <div className={cx('modalContent', classes?.content)}>
          <div className={cx('modalContentInner', classes?.inner, { isSafari })}>{children}</div>
        </div>
        {modalFooter && <div className={cx('modalBottom', classes?.footer)}>{modalFooter}</div>}
      </article>
    </Modal>
  );
};

export { SideModal };
export type { SideModalProps, Classes as SideModalClasses };
