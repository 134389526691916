import React, { useContext } from 'react';

import {
  ChangeStageData,
  ConnectedCloudsDataDTO,
  Stages,
} from '@common/components/AppToolbar/components/CloudConnect/types';

import { CloudTypes } from '@src/@types';
import { CMModalButtonSettings } from '@src/kit';

type CloudConnectContextParams = {
  open: boolean;
  stage: Stages | null;
  title?: string;
  editableCloud: ConnectedCloudsDataDTO | null;
  selectedProvider: CloudTypes | null;
  onOpen: () => void;
  onClose: () => void;
  selectStage: (value: ChangeStageData) => void;
  changeDisagreeButtonSettings: (settings: CMModalButtonSettings) => void;
  changeAgreeButtonSettings: (settings: CMModalButtonSettings) => void;
  disagreeButtonSettings: CMModalButtonSettings;
  agreeButtonSettings: CMModalButtonSettings;
  onChangeTitle: (title: string) => void;
  onSelectProvider: (value: CloudTypes) => void;
  setEditableCloud: (cloud: ConnectedCloudsDataDTO | null) => void;
  clouds?: ConnectedCloudsDataDTO[];
};

const CloudConnectContext: React.Context<CloudConnectContextParams> =
  React.createContext<CloudConnectContextParams>({} as CloudConnectContextParams);

const useCloudConnectContext = () => useContext<CloudConnectContextParams>(CloudConnectContext);

export { CloudConnectContext, useCloudConnectContext };
export type { CloudConnectContextParams };
