import { ToggleFilterButton } from '@common/components/FilterPanel/ToggleFilterButton';

import { useAppTranslation, usePopupControls } from '@src/common';

const useToggleButton = (count = 0) => {
  const { isOpened, togglePopup } = usePopupControls();

  const { t } = useAppTranslation();

  const button = (
    <ToggleFilterButton
      count={count}
      active={isOpened}
      onToggle={togglePopup}
    >
      {t('Filter')}
    </ToggleFilterButton>
  );

  return {
    button,
    isOpened,
  };
};

export { useToggleButton };
