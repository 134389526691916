import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { FormUserUpdate } from '@common/components/AppToolbar/components/Profile/SidePanelProfile';
import { FileUploader } from '@common/components/AppToolbar/components/Profile/FileUploader';

import { useAppTranslation, useToast } from '@src/common';

const FileInput: React.FC = () => {
  const { control } = useFormContext<FormUserUpdate>();
  const { t } = useAppTranslation();
  const toast = useToast();

  const getBase64 = (file: Blob) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  return (
    <Controller
      name="avatar"
      control={control}
      render={({ field }) => {
        const onChange = async (event: React.ChangeEvent) => {
          const input = event.target as HTMLInputElement;

          const file = input.files?.[0];

          if (!file) {
            return;
          }

          if (file && file.size < 1048576) {
            const url = await getBase64(file);
            field.onChange(url);
          } else {
            toast.error({ text: t('cos.editUserInfo.statusPopup.sizeError') });
          }
        };

        const onDelete = () => field.onChange(null);

        return (
          <FileUploader
            src={field.value}
            onChange={onChange}
            onDelete={onDelete}
          />
        );
      }}
    />
  );
};

export { FileInput };
