import React from 'react';

import cn from 'classnames/bind';

import styles from './TabList.module.scss';

type TabSize = 'lg' | 'md' | 'sm';
type TabVariant = 'underlined' | 'subtle' | 'checkbox';

type TabListProps = {
  onChange: (newValue: string) => void;
  size?: TabSize;
  variant?: TabVariant;
  tabListClassName?: string;
  tabClassName?: string;
  iconClasses?: string;
};

const TabList: React.FC<TabListProps> = ({
  size = 'lg',
  variant = 'underlined',
  onChange,
  children,
  tabListClassName,
  tabClassName,
  iconClasses,
}) => {
  const cx = cn.bind(styles);

  const handleChange = (e: React.SyntheticEvent) => {
    const element = e.target as HTMLInputElement;
    const id = element.closest('.tab')?.id;
    const idValue = id?.split('-')[1];
    const disabled = element.closest('.tab')?.getAttribute('disabled');
    if (!disabled && idValue) {
      onChange(idValue);
    }
  };

  const classes = cx('tabList', size, tabListClassName);

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        size,
        variant,
        tabClassName,
        iconClasses,
      });
    }
    return child;
  });

  return (
    <div
      className={classes}
      onClick={handleChange}
    >
      {childrenWithProps}
    </div>
  );
};

export { TabList };
export type { TabListProps, TabSize, TabVariant };
