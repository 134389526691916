import { SelectOption } from '@kit/components';
import { CmTableColumn } from '@common/components/CMTable/types';
import { HeadCell } from '@common/components/CMTable/components/HeadCell';
import { HeadCheckbox } from '@common/components/CMTable/components/HeadCheckbox';
import { CheckboxCell } from '@common/components/CMTable/components/CheckboxCell';

const DEFAULT_PAGE_SIZES = [15, 25, 50];

const createPageSizeOptions = (sizes: number[] = DEFAULT_PAGE_SIZES): SelectOption<number>[] =>
  sizes.map((size) => ({
    label: size,
    value: size,
  }));

const formatHeadCell = <D,>(columns: CmTableColumn<D>[]): CmTableColumn<D>[] => {
  return columns.map((col) => ({
    ...col,
    dataIndex: col.key,
    title: col.checkbox ? (
      <HeadCheckbox />
    ) : (
      <HeadCell
        id={col.key}
        sortable={col.sortable}
        tooltip={col.headTooltip}
      >
        {col.title}
      </HeadCell>
    ),
    render: col.checkbox ? (_, record: D) => <CheckboxCell record={record} /> : col.render,
    className: col.checkbox ? 'rc-table-cell-checkbox' : col.className,
  }));
};

export { createPageSizeOptions, formatHeadCell };
