import React from 'react';

import cn from 'classnames/bind';

import styles from './ModalTitle.module.scss';

type Props = {
  title?: string;
  size?: string;
};

const cx = cn.bind(styles);

const ModalTitle: React.FC<Props> = ({ title, size = 'large' }) => {
  const classNames = cx('title', size);

  return <h3 className={classNames}>{title}</h3>;
};

export { ModalTitle };
