import { FC } from 'react';
import { useFieldArray } from 'react-hook-form';

import { FormDataHyperV } from '@common/components/AppToolbar/components/CloudConnect/types';
import { defaultClassificationValues } from '@common/components/AppToolbar/components/CloudConnect/Forms/FormHyperV';

import { bindStyles } from '@src/utils';
import { FormInput, required, useAppFormContext, useAppTranslation } from '@src/common';
import { Button, Icon, WarningMessageBlock } from '@src/kit';

import styles from '../HyperVConnection.module.scss';

const cx = bindStyles(styles);

const StorageSettingsStep: FC = () => {
  const { t } = useAppTranslation();

  const { control } = useAppFormContext<FormDataHyperV>();

  const nameControl = 'diskClassifications';

  const { fields, append, remove } = useFieldArray<FormDataHyperV>({
    control,
    name: nameControl,
  });

  const onAppend = () => {
    append(defaultClassificationValues, { shouldFocus: false });
  };

  const onDelete = (index: number) => () => {
    remove(index);
  };
  return (
    <>
      <div className={cx('connectionBlock')}>
        {fields.length > 0 && (
          <WarningMessageBlock>{t('StorageClassificationDescription')}</WarningMessageBlock>
        )}
      </div>
      <div>
        {fields.map((field, index) => (
          <div
            className={cx('row')}
            key={field.id}
          >
            <div className={cx('row-first')}>
              <FormInput
                classes={{ error: cx('inputError') }}
                size="md"
                name={`${nameControl}.${index}.name`}
                rules={required('FieldRequired')}
                placeholder={t('cld.hyperV.placeholder.name')}
                caption={index === 0 ? t('cld.HyperV.modal.editable.name') : ''}
              />
            </div>
            <div className={cx('row-last')}>
              <FormInput
                classes={{ error: cx('inputError') }}
                size="md"
                name={`${nameControl}.${index}.maxSize`}
                type="number"
                withControls
                decimals={3}
                units={t('UnitGb')}
                caption={index === 0 ? t('cld.HyperV.modal.editable.size') : ''}
                hint={t('cld.HyperV.modal.editable.hint.size')}
                tooltipClass={cx('tootlipSizeContainer')}
              />
            </div>

            <div
              onClick={onDelete(index)}
              className={cx('delete')}
            >
              <Icon type="close" />
            </div>
          </div>
        ))}
      </div>

      <div className={cx('buttonsContainer')}>
        <Button
          variant="text"
          onClick={onAppend}
          size="md"
          text={t('cld.hyperV.btn.text')}
          className={cx('addButton')}
          textClassName={cx('appendButtonText')}
        >
          <div className={cx('buttonContent')}>
            <Icon
              type="close"
              className={cx('plusIcon')}
            />
          </div>
        </Button>
      </div>
    </>
  );
};

export { StorageSettingsStep };
