import React from 'react';

import { FormAWS } from '@common/components/AppToolbar/components/CloudConnect/Forms/FormAWS';
import { FormVSphere } from '@common/components/AppToolbar/components/CloudConnect/Forms/FormVSphere';
import { FormAzure } from '@common/components/AppToolbar/components/CloudConnect/Forms/FormAzure';
import { FormOpenStack } from '@common/components/AppToolbar/components/CloudConnect/Forms/FormOpenStack';
import { FormHyperV } from '@common/components/AppToolbar/components/CloudConnect/Forms/FormHyperV';
import { YandexCloudForm } from '@common/components/AppToolbar/components/CloudConnect/Forms/YandexCloud';
import { FormVCloudDirector } from '@common/components/AppToolbar/components/CloudConnect/Forms/FormVCloudDirector';

import { CloudTypes } from '@src/@types';
import { useCloudConnectContext } from '@src/common';

const CLOUD_FORM_DICTIONARY: Partial<Record<CloudTypes, React.ReactElement>> = {
  AWS: <FormAWS />,
  Azure: <FormAzure />,
  vSphere: <FormVSphere />,
  OpenStack: <FormOpenStack />,
  HyperV: <FormHyperV />,
  Yandex: <YandexCloudForm />,
  vCloudDirector: <FormVCloudDirector />,
};

export const ProviderForm: React.FC = () => {
  const { selectedProvider } = useCloudConnectContext();
  const Component = selectedProvider && CLOUD_FORM_DICTIONARY[selectedProvider];
  return Component ?? null;
};
