import { FC } from 'react';

import { bindStyles } from '@src/utils';

import styles from './CMModalFooter.module.scss';

type Props = {
  className?: string;
};

const cx = bindStyles(styles);

const CMModalFooter: FC<Props> = ({ className, children }) => {
  return <div className={cx(className, 'wrapper')}>{children}</div>;
};

export { CMModalFooter };
