import React, { useEffect } from 'react';

import { bindStyles } from '@src/utils';
import { Icon } from '@src/kit';

import UserPlug from '@assets/images/AvatarDefault.svg';

import styles from './FileUploader.module.scss';

type Props = {
  src?: string;
  onChange: (value: React.ChangeEvent) => void;
  onDelete: () => void;
};

const cx = bindStyles(styles);

const FileUploader: React.FC<Props> = ({ onChange, src, onDelete }) => {
  const ref = React.useRef<HTMLInputElement | null>(null);

  const onClear = () => {
    if (ref.current?.value) {
      ref.current.value = '';
    }
  };

  useEffect(() => {
    return onClear;
  }, []);

  const onOpen = (event: React.SyntheticEvent) => {
    event.preventDefault();
    ref.current?.click();
  };

  const onDeleteAvatar = () => {
    onClear();
    onDelete();
  };

  return (
    <div className={cx('imageBlockContainer')}>
      <button
        type="button"
        disabled={!Boolean(src)}
        className={cx('closeButton')}
        onClick={onDeleteAvatar}
      >
        <Icon
          size="lg"
          type="close"
        />
      </button>
      <label
        htmlFor="file-upload"
        className="label"
      >
        <div
          className={cx('imageContainer')}
          onClick={onOpen}
        >
          {src ? (
            <img
              className={cx('image')}
              src={src}
              alt="user-avatar"
            />
          ) : (
            <div className={cx('avatarPlug')}>
              <img
                src={UserPlug}
                alt=""
              />
            </div>
          )}
          <div className={cx('cameraIconContainer')} />
          <Icon
            className={cx('cameraIcon')}
            type="photo-camera"
          />
        </div>
      </label>
      <input
        ref={ref}
        hidden
        id="file-upload"
        accept="image/png, image/gif, image/jpeg"
        type="file"
        onChange={onChange}
      />
    </div>
  );
};

export { FileUploader };
