import React from 'react';

import { LoadingPage } from '@src/kit';
import { useAppToolbarContext, useAppTranslation } from '@src/common';

const LoadingPageProvider: React.FC = () => {
  const { brand } = useAppToolbarContext();

  const { t } = useAppTranslation();

  return (
    <LoadingPage
      brand={brand}
      text={t('common.loadingPage.text')}
    />
  );
};

export { LoadingPageProvider };
