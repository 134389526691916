import { FC, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { CloudConnectContent } from '@common/components/AppToolbar/components/CloudConnect/CloudConnectContent';

import { bindStyles } from '@src/utils';
import { Disclaimer, SelectOption } from '@src/kit';
import { FormCmSelect, FormInput, required, urlPattern, useAppTranslation } from '@src/common';
import { API_VERSION, NAME, PASSWORD_FIELD, USERNAME, VCENTER } from '@src/constants';

import styles from '../FormVSphere.module.scss';

const cx = bindStyles(styles);

const ConnectionStep: FC = () => {
  const { t } = useAppTranslation();

  const form = useFormContext();

  const apiVersion = form.watch('apiVersion');

  const apiVersionOptions: SelectOption<string>[] = [
    {
      label: '6.0',
      value: '6.0',
    },
    {
      label: '7.0',
      value: '7.0',
    },
  ];

  return (
    <CloudConnectContent>
      <div className={cx('connectionBlock')}>
        <div className={cx('info')}>
          <Disclaimer text={t('VSphereInfoText')} />
        </div>
        <FormInput
          name={NAME}
          rules={{ ...required('FieldRequired') }}
          caption={t('cloudName')}
          placeholder={t('createNameCloud')}
          hint={t('VCenterHintName')}
        />
      </div>
      <div className={cx('connectionBlock')}>
        <FormInput
          name={VCENTER}
          caption={t('VCenter')}
          rules={{ ...required('FieldRequired'), ...urlPattern('InputFormatError') }}
          placeholder={t('EnterValue')}
          hint={t('VCenterApiHint')}
        />
      </div>
      <div className={cx('connectionBlock')}>
        <FormCmSelect
          name={API_VERSION}
          caption={t('apiVersion')}
          rules={{ ...required('FieldRequired') }}
          options={apiVersionOptions}
          value={apiVersion}
        />
      </div>
      <div className={cx('connectionBlock')}>
        <div className={cx('blockContent')}>
          <FormInput
            name={USERNAME}
            caption={t('Login')}
            rules={{ ...required('FieldRequired') }}
            placeholder={t('EnterLogin')}
            hint={t('VCenterLoginHint')}
          />
          <FormInput
            name={PASSWORD_FIELD}
            type="password"
            rules={{ ...required('FieldRequired') }}
            caption={t('Password')}
            placeholder={t('EnterPassword')}
            autoComplete="new-password"
          />
        </div>
      </div>
    </CloudConnectContent>
  );
};

export { ConnectionStep };
