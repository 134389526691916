import React from 'react';

import { Size } from '@src/@types';
import { bindStyles } from '@src/utils';

import styles from './RadioButtonGroup.module.scss';

const cx = bindStyles(styles);

type RadioButtonSize = Extract<Size, 'lg' | 'md' | 'sm'>;

type RadioButtonProps = {
  className?: string;
  errorText?: string;
  isError?: boolean;
  label?: string;
  name?: string;
  isSelected?: boolean;
  onChange?: (value: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  value?: string;
  isDisabled?: boolean;
  size?: RadioButtonSize;
};

const RadioButton = ({
  className,
  isError = false,
  errorText,
  label,
  name,
  isSelected,
  onChange,
  onBlur,
  value,
  isDisabled = false,
}: RadioButtonProps) => (
  <label className={cx('container', { halfinvisible: isDisabled }, className)}>
    <div className={cx('ring', { ringselected: !isError })}>
      <div className={cx('ringinner')}>
        {!isError && isSelected && (
          <div>
            <div className={cx('selected')}>
              <div className={cx('selected-inner')} />
            </div>
          </div>
        )}
        {!isError && !isSelected && (
          <div>
            <div className={cx('nonselected')}>
              <div className={cx('nonselected-inner')} />
            </div>
          </div>
        )}
        {isError && <div className={cx('ringinnererror')} />}
        <input
          type="radio"
          name={name}
          value={value}
          className={cx('invisible')}
          onChange={onChange}
          onBlur={onBlur}
          disabled={isDisabled}
        />
      </div>
    </div>
    {(errorText || label) && (
      <div className={cx('textwitherror')}>
        {label && <span className={cx('text')}>{label}</span>}
        {isError && <span className={cx('errortext')}>{errorText}</span>}
      </div>
    )}
  </label>
);

export { RadioButton };
export type { RadioButtonProps };
