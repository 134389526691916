import { FC, useMemo } from 'react';

import {
  AzureRequestSubscriptionDTO,
  AzureSubscription,
} from '@common/components/AppToolbar/components/CloudConnect/types';
import { AzureCreationType } from '@common/components/Clouds/components/NewConnection/Forms/AzureConnection';
import { FormCreateApplication } from '@common/components/Clouds/components/NewConnection/Forms/AzureConnection/ConnectionStep/FormCreateApplication';
import { FormEnterpriseAgreement } from '@common/components/Clouds/components/NewConnection/Forms/AzureConnection/ConnectionStep/FormEnterpriseAgreement';
import { useCloudsContext } from '@common/providers/CloudsProvider';

import { bindStyles } from '@src/utils';
import { Tab, TabContext, TabList } from '@src/kit';
import { FormInput, required, useAppTranslation } from '@src/common';
import { CLOUDNAME } from '@src/constants';

import styles from '../AzureConnection.module.scss';

const cx = bindStyles(styles);

type Props = {
  fetchListSubscriptions: (data: AzureRequestSubscriptionDTO) => void;
  list: AzureSubscription[];
  isLoading: boolean;
  activeTab: AzureCreationType;
  onChangeTab: (value: string) => void;
};

const ConnectionStep: FC<Props> = ({
  fetchListSubscriptions,
  list,
  isLoading,
  onChangeTab,
  activeTab,
}) => {
  const { t } = useAppTranslation();

  const { cloudEdit } = useCloudsContext();

  const renderedForm = useMemo(() => {
    switch (activeTab) {
      case AzureCreationType.CREATE_APPLICATION:
        return (
          <FormCreateApplication
            fetchListSubscriptions={fetchListSubscriptions}
            list={list}
            isLoading={isLoading}
          />
        );
      case AzureCreationType.ENTERPRISE_AGREEMENT:
        return <FormEnterpriseAgreement />;
    }
  }, [activeTab, fetchListSubscriptions, list, isLoading]);

  const renderedTabs = useMemo(() => {
    if (cloudEdit) {
      if (activeTab === AzureCreationType.CREATE_APPLICATION) {
        return (
          <TabList
            tabListClassName={cx('tab-list')}
            variant="underlined"
            size="lg"
            onChange={onChangeTab}
          >
            <Tab
              label={t('cos.cloudConnection.azure.tab.createApplication')}
              value={AzureCreationType.CREATE_APPLICATION}
            />
          </TabList>
        );
      } else {
        return (
          <TabList
            tabListClassName={cx('tab-list')}
            variant="underlined"
            size="lg"
            onChange={onChangeTab}
          >
            <Tab
              label={t('cos.cloudConnection.azure.tab.enterpriseAgreement')}
              value={AzureCreationType.ENTERPRISE_AGREEMENT}
            />
          </TabList>
        );
      }
    } else {
      return (
        <TabList
          tabListClassName={cx('tab-list')}
          variant="underlined"
          size="lg"
          onChange={onChangeTab}
        >
          <Tab
            label={t('cos.cloudConnection.azure.tab.createApplication')}
            value={AzureCreationType.CREATE_APPLICATION}
          />
          <Tab
            label={t('cos.cloudConnection.azure.tab.enterpriseAgreement')}
            value={AzureCreationType.ENTERPRISE_AGREEMENT}
          />
        </TabList>
      );
    }
  }, [cloudEdit, t, activeTab]);

  return (
    <div>
      <div className={cx('connectionBlock')}>
        <FormInput
          name={CLOUDNAME}
          rules={{ ...required('FieldRequired') }}
          caption={t('cloudName')}
          placeholder={t('createNameCloud')}
        />
      </div>
      <div className={cx('connectionBlock')}>
        <TabContext value={activeTab}>{renderedTabs}</TabContext>
      </div>
      {renderedForm}
    </div>
  );
};

export { ConnectionStep };
