import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';

import { useAppTranslation } from '@src/common';
import { Input, InputNumber, InputNumberProps, InputProps } from '@src/kit';

import { ErrorUtils } from '@utils/ErrorUtils';

type Props<T = string> = Omit<InputProps & InputNumberProps, keyof ControllerRenderProps> & {
  name: string;
  rules?: RegisterOptions;
  clearable?: boolean;
  convertOut?: (value: number) => T;
  convertIn?: (value: T) => number;
  onChange?: (value: string) => void;
};

const FormInput = <T,>({
  name,
  rules,
  clearable,
  isError,
  type,
  convertOut,
  convertIn,
  onChange,
  ...inputProps
}: Props<T>) => {
  const {
    formState: { errors },
    control,
    setValue,
  } = useFormContext();
  const { t } = useAppTranslation();

  const [base, number, innerName] = name.split('.');

  const clear = clearable ? () => setValue(name, '') : undefined;
  // TODO: поправить обработку ошибок
  const hasError = !!errors[name] || isError || !!errors?.[base]?.[+number]?.[innerName];
  const errorMsg = number
    ? ErrorUtils.handleFormError(errors?.[base]?.[+number]?.[innerName]?.message, t).join('. ')
    : ErrorUtils.handleFormError(errors[name]?.message, t).join('. ');

  const InputComponent = type === 'number' ? InputNumber : Input;

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      render={({ field }) => {
        const handleChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
          const convertedValue = convertOut && value ? convertOut(+value) : value;
          if (onChange) {
            onChange(value);
          } else {
            field.onChange(convertedValue);
          }
        };

        const onBlur = () => {
          if (typeof field.value === 'string') {
            field.onChange(field.value.trim());
          }
        };

        const convertedValue = convertIn && field.value ? convertIn(field.value) : field.value;
        return (
          <InputComponent
            {...field}
            type={type}
            isError={hasError}
            onClear={clear}
            errorText={t(errorMsg)}
            onChange={handleChange}
            onBlur={onBlur}
            value={convertedValue}
            {...inputProps}
          />
        );
      }}
    />
  );
};

export { FormInput };
export type { Props as FormInputProps };
