import { FC, useMemo } from 'react';
import { FieldArrayWithId } from 'react-hook-form';

import {
  TariffPriceDTO,
  TariffResourceTypes,
  TariffTypeVariants,
} from '@common/components/TariffAndPricesComponent/api/models';
import { TableItem } from '@common/components/TariffAndPricesComponent/components/TableItem';

import { bindStyles } from '@src/utils';
import { FormCmSelect, FormDTO, useAppFormContext, useAppTranslation } from '@src/common';
import { CmSelectValue, Disclaimer, Icon, SelectOption } from '@src/kit';

import styles from './TariffItem.module.scss';

type Props = {
  tariff: TariffPriceDTO;
  resources: FieldArrayWithId<FormDTO, 'resources', 'id'>[];
  tariffTypes: SelectOption<string>[];
  index: number;
  isEdit?: boolean;
};

const cx = bindStyles(styles);

const TARIFF_TYPE_MAP: Record<TariffTypeVariants, TariffResourceTypes> = {
  [TariffTypeVariants.MIXED]: TariffResourceTypes.MIXED,
  [TariffTypeVariants.FIXED]: TariffResourceTypes.MONTH,
  [TariffTypeVariants.PAYG]: TariffResourceTypes.HOUR,
};

const TariffItem: FC<Props> = ({ tariff, resources, isEdit, tariffTypes, index }) => {
  const { t } = useAppTranslation();
  const { watch, setValue, clearErrors } = useAppFormContext();

  const tariffTypeName = `tariffs.${index}.tariffType`;
  const tariffType = watch(tariffTypeName);

  const tariffTypeSelectValue = useMemo(() => {
    return tariffTypes.find((it) => it.value === tariffType);
  }, [tariffType, tariffTypes]);

  const isOrganization = tariff.vdcName === 'Organization';

  const handleChangeTariffType = (value: CmSelectValue) => {
    if (value && !Array.isArray(value)) {
      setValue(tariffTypeName, value.value);
      clearErrors();
    }
  };

  return (
    <div className={cx('wrapper')}>
      <div className={cx('tariffBlock', { isOrganization })}>
        <div className={cx('header', { isOrganization })}>
          <div className={cx('title', { isOrganization })}>
            <div className={cx('icon', { isOrganization })}>
              <Icon
                type={isOrganization ? 'star-filled' : 'folder'}
                size="lg"
              />
            </div>
            {isOrganization ? t(tariff.vdcName) : tariff.vdcName}
          </div>
          {isEdit ? (
            <FormCmSelect
              name={tariffTypeName}
              value={tariffTypeSelectValue}
              options={tariffTypes}
              onChange={handleChangeTariffType}
              caption={t('cos.settings.tariffprices.billingType.caption')}
            />
          ) : (
            <div className={cx('badge')}>{tariffTypeSelectValue?.label}</div>
          )}
        </div>
        {isOrganization && (
          <Disclaimer
            className={cx('disclaimer')}
            text={
              <div className={cx('disclaimer-text')}>
                <div>{t('cos.settings.tariffprices.disclaimer.info')}</div>
                <div>{t('cos.settings.tariffprices.disclaimer.edit')}</div>
              </div>
            }
          />
        )}
      </div>
      <div className={cx('resources')}>
        {resources.map((it, i) => (
          <TableItem
            key={it.id}
            i={i}
            vdcName={tariff.vdcName}
            tariffType={TARIFF_TYPE_MAP[tariffType as TariffTypeVariants]}
            resource={it}
            isEdit={isEdit}
          />
        ))}
      </div>
    </div>
  );
};

export { TariffItem, TARIFF_TYPE_MAP };
