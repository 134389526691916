import { createContext, useContext } from 'react';

import { CustomerSettingsModule, CustomerSettingsType } from '@common/api';

type CustomerSettingsContextType = {
  hasActiveSetting: (
    module: CustomerSettingsModule,
    section: string,
    type?: CustomerSettingsType,
  ) => boolean;
  isLoading: boolean;
};

const CustomerSettingsContext = createContext({} as CustomerSettingsContextType);

const useCustomerSettingsContext = () => useContext(CustomerSettingsContext);

export { CustomerSettingsContext, useCustomerSettingsContext };
