import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

import {
  Stages,
  VSphereEditForm,
  VSphereInfo,
} from '@common/components/AppToolbar/components/CloudConnect/types';
import { getVSphereInfo, updateVSphere } from '@common/components/AppToolbar/api/cloud-connections';
import { AxiosError } from 'axios';
import { ProfileItem } from '@common/components/AppToolbar/components/CloudConnect/ProfileItem';
import { CloudConnectContent } from '@common/components/AppToolbar/components/CloudConnect/CloudConnectContent';
import { CloudEditLoading } from '@common/components/AppToolbar/components/CloudConnect/CloudEdit/CloudEditLoading';

import { bindStyles, mbToGb } from '@src/utils';
import {
  Form,
  FormInput,
  required,
  StorageProfile,
  useAnalytics,
  useCloudConnectContext,
  useCurrencyContext,
  useFormApiError,
  useToast,
  useTranslationPrefix,
} from '@src/common';
import { Button, Icon } from '@src/kit';
import {
  CLOUD_NAME_DICTIONARY,
  CLOUDNAME,
  CORE_COST,
  HDD_COST,
  PASSWORD_FIELD,
  RAM_COST,
  USERNAME,
  VCENTER,
} from '@src/constants';

import styles from './VSphereEdit.module.scss';

const cx = bindStyles(styles);

const VSphereEdit: FC = () => {
  const [isInitLoading, setIsInitLoading] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const { logSuccessEvent, logErrorEvent } = useAnalytics();
  const toast = useToast();

  const form = useForm<VSphereEditForm>({
    mode: 'onBlur',
    defaultValues: {
      storageProfiles: [],
    },
  });
  const formValues = form.watch();
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'storageProfiles',
  });
  const {
    selectStage,
    changeAgreeButtonSettings,
    changeDisagreeButtonSettings,
    onChangeTitle,
    editableCloud,
  } = useCloudConnectContext();

  const onBackHandler = () => {
    selectStage({
      stage: Stages.connectedClouds,
    });
  };
  const { t, tPrefix } = useTranslationPrefix('cos.cloud.connect.cloudDirector.');
  const { customerCurrency, currenciesList } = useCurrencyContext();
  const suffix = currenciesList.find((it) => it.code === customerCurrency)?.name;
  const { apiErrors, handleError } = useFormApiError();

  const onSubmit = useCallback(
    async (dto: VSphereEditForm) => {
      try {
        if (editableCloud?.id) {
          setIsSubmitLoading(true);
          const storageProfiles: StorageProfile[] =
            dto.storageProfiles?.map((item) => {
              return {
                name: item.key,
                price: String(item.value),
              };
            }) || [];

          const data: VSphereInfo = {
            cloudName: dto.cloudName,
            storageProfiles,
            cpuPrice: dto.cpuPrice,
            diskGbPrice: dto.diskGbPrice,
            memoryGbPrice: dto.memoryGbPrice,
          };

          await updateVSphere(editableCloud.id, data);

          toast.success({ text: t('cos.connect.cloud.edit.success') });
          selectStage({
            stage: Stages.connectedClouds,
          });
          logSuccessEvent('Edited cloud connection', {
            id: editableCloud?.id,
            cloudType: CLOUD_NAME_DICTIONARY.vSphere,
          });
        }
      } catch (e) {
        handleError(e as AxiosError);
        logErrorEvent('Edited cloud connection', {
          id: editableCloud?.id,
          cloudType: CLOUD_NAME_DICTIONARY.vSphere,
        });
      } finally {
        setIsSubmitLoading(false);
      }
    },
    [editableCloud],
  );

  const addProfile = useCallback(() => {
    append(
      {
        key: '',
        value: '',
        maxSize: '',
      },
      {
        shouldFocus: false,
      },
    );
  }, []);

  const fetchCloudInfo = useCallback(async () => {
    if (editableCloud?.id) {
      try {
        setIsInitLoading(true);
        const response = await getVSphereInfo(editableCloud.id);
        form.setValue('cloudName', response.data.cloudName);
        form.setValue('cpuPrice', response.data.cpuPrice);
        form.setValue('memoryGbPrice', response.data.memoryGbPrice);
        form.setValue('diskGbPrice', response.data.diskGbPrice);
        if (response.data.storageProfiles) {
          response.data.storageProfiles.forEach((item) => {
            if (item) {
              append(
                {
                  value: String(item.price),
                  key: item.name,
                  maxSize: item.maxSize ? String(mbToGb(item.maxSize)) : undefined,
                },
                {
                  shouldFocus: false,
                },
              );
            }
          });
        }
      } catch (e) {
        handleError(e as AxiosError);
      } finally {
        setIsInitLoading(false);
      }
    }
  }, [editableCloud]);

  useEffect(() => {
    fetchCloudInfo();
  }, []);

  const renderedProfiles = useMemo(() => {
    return fields.map((it, index) => (
      <ProfileItem
        key={it.id}
        setValue={form.setValue}
        onDelete={() => remove(index)}
        position={index}
        register={form.register}
        watch={form.watch}
        errors={form.formState.errors}
        clearErrors={form.clearErrors}
        otherValues={formValues.storageProfiles}
        disableMaxSize
        disableName
      />
    ));
  }, [form.formState.errors, fields, formValues]);

  useEffect(() => {
    changeDisagreeButtonSettings({
      onClick: onBackHandler,
    });
    changeAgreeButtonSettings({
      onClick: form.handleSubmit(onSubmit),
      isLoading: isSubmitLoading,
      text: tPrefix('button.edit'),
    });
    onChangeTitle(t('cos.cloudConnect.edit.azure.title'));
  }, [isSubmitLoading, onSubmit]);

  if (isInitLoading) {
    return <CloudEditLoading />;
  }

  return (
    <Form<VSphereEditForm>
      formMethods={form}
      onSubmit={() => {}}
      apiErrors={apiErrors}
    >
      <div className={cx('modalContainer')}>
        <CloudConnectContent>
          <div>
            <div className={cx('connectionBlock')}>
              <FormInput
                name={CLOUDNAME}
                rules={{ ...required('FieldRequired') }}
                caption={t('cloudName')}
                placeholder={t('createNameCloud')}
                hint={t('VCenterHintName')}
              />
            </div>
            <div className={cx('connectionBlock')}>
              <FormInput
                name={VCENTER}
                caption={t('VCenter')}
                // rules={{ ...required('FieldRequired'), ...urlPattern('InputFormatError') }}
                placeholder={t('EnterValue')}
                hint={t('VCenterApiHint')}
                disabled
              />
            </div>
            <div className={cx('connectionBlock')}>
              <div className={cx('blockContent')}>
                <FormInput
                  name={USERNAME}
                  caption={t('Login')}
                  // rules={{ ...required('FieldRequired'), ...emailPattern('InvalidLogin') }}
                  placeholder={t('EnterLogin')}
                  hint={t('VCenterLoginHint')}
                  disabled
                />
                <FormInput
                  name={PASSWORD_FIELD}
                  type="password"
                  // rules={{ ...required('FieldRequired') }}
                  caption={t('Password')}
                  placeholder={t('EnterPassword')}
                  autoComplete="new-password"
                  disabled
                />
              </div>
            </div>
            <div className={cx('connectionBlock')}>
              <div className={cx('blockTitle')}>{tPrefix('PricingPolicySetup')}</div>
              <div className={cx('blockContent')}>
                <FormInput
                  type="number"
                  decimals={2}
                  name={CORE_COST}
                  caption={t('CpuCost')}
                  rules={{ ...required('FieldRequired') }}
                  placeholder={t('CostInCurrency', { suffix })}
                  suffix={suffix}
                  hint={t('CpuCostHint')}
                />

                <FormInput
                  type="number"
                  decimals={2}
                  name={RAM_COST}
                  caption={t('RamCost')}
                  rules={{ ...required('FieldRequired') }}
                  placeholder={t('CostInCurrency', { suffix })}
                  suffix={suffix}
                  hint={t('RamCostHint')}
                />

                <FormInput
                  type="number"
                  decimals={2}
                  name={HDD_COST}
                  caption={t('HddCost')}
                  rules={{ ...required('FieldRequired') }}
                  placeholder={t('CostInCurrency', { suffix })}
                  suffix={suffix}
                  hint={t('HddCostHint')}
                />
              </div>
            </div>

            <div className={cx('blockTitle', { withBorder: !fields.length })}>
              {tPrefix('ProfilesTitle')}
            </div>
            {Boolean(fields.length) && <div className={cx('items')}>{renderedProfiles}</div>}

            <div className={cx('buttonsContainer')}>
              <Button
                variant="text"
                textClassName={cx('appendButtonText')}
                text={tPrefix('add.profile')}
                className={cx('addButton')}
                onClick={addProfile}
                disabled
              >
                <div className={cx('buttonContent')}>
                  <Icon
                    type="close"
                    className={cx('plusIcon')}
                  />
                </div>
              </Button>
              <Button
                variant="outline"
                text={tPrefix('load.profiles')}
                disabled
                /*disabled={isFormLoading}*/
              />
            </div>
          </div>
        </CloudConnectContent>
      </div>
    </Form>
  );
};

export { VSphereEdit };
