import React from 'react';

import { Connections } from './Connections';
import { CloudConnectContent } from '../CloudConnectContent';

const ConnectedClouds: React.FC = () => (
  <CloudConnectContent>
    <Connections />
  </CloudConnectContent>
);

export { ConnectedClouds };
