import { FC, useCallback, useEffect, useState } from 'react';

import { fetchActiveDirectorySettings } from '@common/components/AppToolbar/api/requests';
import { AxiosError } from 'axios';
import { ViewConnectionAD } from '@common/components/SettingsProject/contents/ActiveDirectory/ViewConnectionAD';

import { H1 } from '@src/kit';
import { bindStyles } from '@src/utils';
import { useFormApiError, useTranslationPrefix } from '@src/common';

import { ActiveDirectoryFormDTO, EditConnectionAD } from './EditConnection';
import styles from './ActiveDirectory.module.scss';

const cx = bindStyles(styles);

const ActiveDirectory: FC = () => {
  const { t, tPrefix } = useTranslationPrefix('cos.activeDirectory.');
  const [isEditMode, setEditMode] = useState<boolean>(false);
  const [isFormReady, setFormReady] = useState<boolean>(false);
  const [activeDirectoryInfo, setActiveDirectoryInfo] = useState<ActiveDirectoryFormDTO | null>(
    null,
  );
  const [mode, setMode] = useState<'view' | 'edit'>();
  const { handleError } = useFormApiError();

  const changeMode = async (reFetch?: boolean) => {
    if (mode === 'edit') {
      reFetch && (await fetchActiveDirectory());
      await setMode('view');
    } else {
      setMode('edit');
    }
  };

  const fetchActiveDirectory = useCallback(async () => {
    try {
      const response = await fetchActiveDirectorySettings();
      if (response.data.adUrl || response.data.clientId) {
        setEditMode(true);
        setActiveDirectoryInfo({
          adUrl: response.data.adUrl,
          clientId: response.data.clientId,
          clientSecret: response.data.clientSecret,
          domains: response.data.domains.map((item) => {
            return {
              domain: item,
            };
          }),
        });
      }
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setFormReady(true);
    }
  }, []);

  useEffect(() => {
    fetchActiveDirectory();
  }, []);

  useEffect(() => {
    if (activeDirectoryInfo && isFormReady) {
      setMode('view');
    }
    if (!activeDirectoryInfo && isFormReady) {
      setMode('edit');
    }
  }, [activeDirectoryInfo, isFormReady]);

  return (
    <>
      <div className={cx('headerContent')}>
        <H1 size="md">{t(tPrefix('title'))}</H1>
      </div>
      {mode === 'edit' && (
        <EditConnectionAD
          activeDirectoryInfo={activeDirectoryInfo}
          isEditMode={isEditMode}
          changeMode={changeMode}
          isLoadingFetch={!isFormReady}
        />
      )}
      {mode === 'view' && (
        <ViewConnectionAD
          activeDirectoryInfo={activeDirectoryInfo}
          changeMode={changeMode}
        />
      )}
    </>
  );
};

export { ActiveDirectory };
