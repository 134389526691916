import { useState } from 'react';

import { SelectOption } from '@kit/components';
import { createPageSizeOptions } from '@common/components/CMTable/hooks/utils';

import { CmTablePaginationLocale } from '@src/common';

type Params = {
  locale?: CmTablePaginationLocale;
  pageSizeVariants?: number[];
};

const useCMTablePagination = (params?: Params) => {
  const pageSizeOptions = createPageSizeOptions(params?.pageSizeVariants);

  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(pageSizeOptions[0].value);

  const totalPages = Math.ceil(total / size);

  const pageNumOptions: SelectOption<number>[] = new Array(totalPages).fill('').map((_, i) => ({
    value: i,
    label: i + 1,
  }));

  const onChangeSize = (value: number) => {
    setSize(value);
    setPage(0);
  };

  return {
    page,
    size,
    total,
    locale: params?.locale,
    setPage,
    setTotal,
    pageNumOptions,
    pageSizeOptions,
    totalPages,
    onChangeSize,
  };
};

export { useCMTablePagination };
