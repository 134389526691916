import { useEffect } from 'react';

import { useAppTranslation, usePreviousState } from '@src/common';

const useCallbackAfterLangChanged = (...fetchFn: Array<() => unknown>) => {
  const { i18n } = useAppTranslation();

  const previousLanguage = usePreviousState(i18n.language);

  useEffect(() => {
    if (!previousLanguage || previousLanguage === i18n.language) {
      return;
    }

    fetchFn.forEach((fn) => fn());
  }, [i18n.language]);
};

export { useCallbackAfterLangChanged };
