import React, { useEffect, useState } from 'react';

import { fetchTechNotification } from '@common/components/AppToolbar/api/requests';
import { AxiosError } from 'axios';

import { Brand } from '@src/@types';
import {
  AccessProvider,
  AppSystem,
  AppToolbarContext,
  CloudConnectProvider,
  NotificationTechData,
  Permissions,
  StorageService,
  useAuth,
  useI18n,
  UserDto,
  useToast,
} from '@src/common';
import { USER_INFO_KEY } from '@src/constants';

import * as toolbarRequests from '../../api/requests';

type Props = {
  children: React.ReactNode;
  brand: Brand;
  system?: AppSystem;
  supportEmail: string;
};

const INTERVAL = 30_000;

const AppToolbarProvider: React.FC<Props> = ({ children, brand, supportEmail, system }) => {
  const [user, setUser] = useState<UserDto | null>(null);
  const [permissions, setPermissions] = useState<Permissions | null>(null);
  const [techNotification, setTechNotification] = useState<NotificationTechData>();
  const toast = useToast();

  const { logout } = useAuth();

  const { currentLanguage, onChangeLanguage } = useI18n(brand);

  const fetchData = async () => {
    try {
      const permissions = await toolbarRequests.fetchUserPermissions();

      setPermissions(permissions);

      const [user, avatar] = await Promise.all([
        toolbarRequests.fetchUserInfo(),
        toolbarRequests.fetchUserAvatar(),
      ]);

      const info = avatar
        ? {
            ...user,
            avatar,
          }
        : user;

      setUser(info);
      StorageService.getInstance().setItem(USER_INFO_KEY, info);
    } catch (e) {
      toast.error({ text: (e as AxiosError).message });
    }
  };

  const fetchTech = async () => {
    try {
      const res = await fetchTechNotification();
      setTechNotification(res.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchData();
    fetchTech();
    const interval = setInterval(fetchTech, INTERVAL);
    return () => clearInterval(interval);
  }, []);

  const onChangeUser = (user: Partial<UserDto>) => {
    setUser((prev) => (prev ? { ...prev, ...user } : prev));
  };

  return (
    <AppToolbarContext.Provider
      value={{
        user,
        language: currentLanguage,
        onChangeLang: onChangeLanguage,
        onChangeUser,
        onLogout: logout,
        techNotification,
        brand,
        supportEmail,
        system,
      }}
    >
      <CloudConnectProvider>
        {permissions && <AccessProvider permissions={permissions}>{children}</AccessProvider>}
      </CloudConnectProvider>
    </AppToolbarContext.Provider>
  );
};

export { AppToolbarProvider };
