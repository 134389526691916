import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { FC, useMemo } from 'react';

import { IntervalVariants } from '@kit/components/Calendar/Container';
import { EN_MONTHS } from '@kit/components/DatePicker/utils/constants';

import { useAppTranslation } from '@src/common';
import { bindStyles } from '@src/utils';
import { Button, Icon } from '@src/kit';

import styles from './Header.module.scss';

type Props = ReactDatePickerCustomHeaderProps & {
  interval?: IntervalVariants;
};

const cx = bindStyles(styles);

const Header: FC<Props> = ({
  date,
  monthDate,
  decreaseMonth,
  increaseMonth,
  increaseYear,
  decreaseYear,
  customHeaderCount,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  interval = IntervalVariants.DAY,
}) => {
  const { t } = useAppTranslation();

  if (interval === IntervalVariants.MONTH) {
    return (
      <div className={cx('yearsWrapper')}>
        <Button
          className={cx('button')}
          variant="outline"
          onClick={decreaseYear}
        >
          <Icon
            className={cx('arrowLeft')}
            type="chevron-left"
          />
        </Button>
        <div className={cx('years')}>
          <div
            className={cx('yearsItem')}
            onClick={decreaseYear}
          >
            {date.getFullYear() - 1}
          </div>
          <div className={cx('yearsItem', 'active')}>{date.getFullYear()}</div>
        </div>
        <Button
          className={cx('button')}
          variant="outline"
          onClick={increaseYear}
        >
          <Icon
            className={cx('arrowRight')}
            type="chevron-left"
          />
        </Button>
      </div>
    );
  }

  return (
    <div className={cx('monthWrapper', { isLeft: customHeaderCount === 0 })}>
      {customHeaderCount === 0 && (
        <Button
          className={cx('button')}
          variant="outline"
          onClick={decreaseMonth}
        >
          <Icon
            className={cx('arrowLeft')}
            type="chevron-left"
          />
        </Button>
      )}
      <div className={cx('monthTextContainer')}>
        <div className={cx('monthText')}>{t(`month.${monthDate.getMonth() + 1}`)}</div>
        <div className={cx('monthText')}>{monthDate.getFullYear()}</div>
      </div>
      {customHeaderCount === 1 && (
        <Button
          className={cx('button')}
          variant="outline"
          onClick={increaseMonth}
        >
          <Icon
            className={cx('arrowRight')}
            type="chevron-left"
          />
        </Button>
      )}
    </div>
  );
};

export { Header };
