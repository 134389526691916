import React, { useLayoutEffect } from 'react';

import { RestService } from '@src/common';

type Props = {
  baseURL?: string;
};

const EnvProvider: React.FC<Props> = ({ baseURL, children }) => {
  useLayoutEffect(() => {
    if (baseURL) RestService.getInstance().addBaseURL(baseURL);
  }, []);

  return <>{children}</>;
};

EnvProvider.displayName = 'EnvProvider';

export { EnvProvider };
