import React from 'react';

import { ProfileView } from '@common/components/AppToolbar/components/Profile/ProfileView';
import { SidePanelProfile } from '@common/components/AppToolbar/components/Profile/SidePanelProfile';

import { UserDto } from '@src/common';
import { bindStyles } from '@src/utils';

import { usePopupAppToolbar } from '@hooks/usePopupAppToolbar';

import styles from './Profile.module.scss';

const cx = bindStyles(styles);

type Props = {
  user: UserDto | null;
  logout: () => void;
  showTechNotification: boolean;
};

const Profile: React.FC<Props> = ({ user, logout, showTechNotification }) => {
  const { isOpened: sideOpen, openPopup: openSide, closePopup: closeSide } = usePopupAppToolbar();

  if (!user) {
    return null;
  }

  return (
    <div className={cx('inner')}>
      <ProfileView
        user={user}
        onOpenDetail={openSide}
        showTechNotification={showTechNotification}
      />
      {sideOpen && (
        <SidePanelProfile
          user={user}
          onClose={closeSide}
          onLogout={logout}
        />
      )}
    </div>
  );
};

export { Profile };
