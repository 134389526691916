import React, { useMemo } from 'react';

import { Button, CmSelect, CmSelectValue, Icon } from '@kit/components';
import { useCMTableContext } from '@common/components/CMTable/context/CMTable.context';

import { bindStyles } from '@src/utils';

import styles from './Pagination.module.scss';

const cx = bindStyles(styles);

interface IProps {
  className?: string;
}

const Pagination: React.FC<IProps> = ({ className }) => {
  const { pagination } = useCMTableContext();

  if (!pagination) {
    return null;
  }

  const { page, size, total, pageSizeOptions, pageNumOptions, totalPages, setPage, onChangeSize } =
    pagination;

  const isFirstPage = page === 0;
  const isLastPage = page + 1 === totalPages;

  const startRange = size * page + 1;
  const endRange = isLastPage ? total : size * (page + 1);

  const activeSizeOption = useMemo(() => {
    return pageSizeOptions.find((val) => val?.value === size) || { value: 0, label: 1 };
  }, [size, pageSizeOptions]);

  const activePageOption = useMemo(() => {
    return pageNumOptions.find((val) => val?.value === page);
  }, [page, pageNumOptions]);

  const changeSize = (option: CmSelectValue) => {
    if (!Array.isArray(option)) {
      onChangeSize(option?.value as number);
    }
  };

  const changePage = (option: CmSelectValue) => {
    if (!Array.isArray(option)) {
      setPage(option?.value as number);
    }
  };

  const onNext = () => {
    if (!isLastPage) {
      setPage(page + 1);
    }
  };

  const onPrev = () => {
    if (!isFirstPage) {
      setPage(page - 1);
    }
  };

  return (
    <div className={cx(className, 'pagination', 'rc-table-pagination')}>
      <div className={cx('root')}>
        <div className={cx('items')}>
          <CmSelect
            size="sm"
            options={pageSizeOptions}
            value={activeSizeOption}
            onChange={changeSize}
            className={cx('control')}
            noOptionsText=""
            withoutCheckmark
          />
          <span className={cx('paginationText')}>{pagination.locale?.rowPerPage}</span>
        </div>
        <div className={cx('total')}>
          <span className={cx('paginationText')}>{`${startRange}–${endRange} ${pagination.locale?.from} ${total} ${
            pagination.locale?.recordsTable ? pagination.locale?.recordsTable : ''
          }`}</span>
        </div>
        <div className={cx('pages')}>
          <div className={cx('selectWrapper')}>
            <CmSelect
              size="sm"
              disabled={pageNumOptions.length < 2}
              options={pageNumOptions}
              value={activePageOption}
              onChange={changePage}
              className={cx('control')}
              noOptionsText=""
              withoutCheckmark
            />
            <span className={cx('paginationText')}>{`${pagination.locale?.from} ${totalPages} ${
              pagination.locale?.totalPages ? pagination.locale?.totalPages : ''
            }`}</span>
          </div>
          <div className={cx('pages__btns')}>
            <Button
              variant="outline"
              size="sm"
              onClick={onPrev}
              disabled={totalPages < 2 || isFirstPage}
              className={cx('pages__btns__item')}
            >
              <div className={cx('icon')}>
                <Icon
                  type="arrow-left"
                  size="md"
                />
              </div>
            </Button>

            <Button
              variant="outline"
              size="sm"
              onClick={onNext}
              disabled={totalPages < 2 || isLastPage}
              className={cx('pages__btns__item')}
            >
              <div className={cx('icon')}>
                <Icon
                  type="arrow-right"
                  size="md"
                />
              </div>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Pagination };
