import React, { useCallback, useMemo, useRef, useState } from 'react';

import Table from 'rc-table';
import { Key, RenderExpandIconProps, TableSticky } from 'rc-table/lib/interface';
import { ExpandableConfig } from 'rc-table/es/interface';
import { Button, Icon } from '@kit/components';
import { CmTableColumn } from '@common/components/CMTable/types';
import { formatHeadCell } from '@common/components/CMTable/hooks/utils';
import { useCMTableContext } from '@common/components/CMTable/context/CMTable.context';
import { Pagination } from '@common/components/CMTable/components/Pagination';
import { Operations } from '@common/components/CMTable/components/Operations';
import TableLoaderFrame from '@kit/components/Table/Loader/TableLoaderFrame';

import { bindStyles } from '@src/utils';

import styles from './CMTable.module.scss';

const cx = bindStyles(styles);

type Plugs = {
  loading?: React.ReactNode;
  emptyData?: React.ReactNode;
};

type Classes = {
  expandIcon: string;
  expandButton: string;
  pagination: string;
};

type Props<D> = {
  plugs?: Plugs;
  classes?: Partial<Classes>;
  sticky?: TableSticky | boolean;
  columns: CmTableColumn<D>[];
  loadingFramesCount?: number;
  rowKey: (item: D) => string | number;
  expandable?: ExpandableConfig<D>;
};

const HEADER_HEIGHT = 47;
const OPERATIONS_HEIGHT = 50;

const CMTable = <D,>(props: Props<D>) => {
  const { columns, rowKey, sticky, plugs, classes, expandable, loadingFramesCount } = props;

  const refOperationsContainer = useRef<HTMLDivElement | null>(null);

  const [expandedItems, setExpandedItems] = useState<Key[]>([]);

  const { data, selection, loading } = useCMTableContext();

  const formattedColumns = useMemo(() => formatHeadCell(columns), [columns]);

  const rowClassName = useCallback(
    (record) => {
      if (selection?.selectedMultiRows?.includes(record)) {
        return 'rc-table-row-active';
      }

      if (expandedItems.includes(rowKey(record))) {
        return 'rc-table-row-expand';
      }

      if (
        expandable &&
        expandable.expandedRowKeys &&
        expandable.expandedRowKeys.includes(rowKey(record))
      ) {
        return 'rc-table-row-expand';
      }

      if (!selection || !selection?.selectedRow) {
        return '';
      }

      return rowKey(selection.selectedRow as D) === rowKey(record) ? 'rc-table-row-active' : '';
    },
    [selection?.selectedRow, selection?.selectedMultiRows, rowKey, expandedItems, expandable],
  );

  const onExpandedRowsChange = useCallback((expandedKeys: Key[]) => {
    setExpandedItems(expandedKeys);
  }, []);

  const onRowClick = (record: D) => {
    if (selection?.selectedRow) {
      const isSame = selection ? rowKey(selection.selectedRow as D) === rowKey(record) : false;
      selection?.onSelectRow(isSame ? null : record);
    } else {
      selection?.onSelectRow(record);
    }
  };

  const operationsVisible = useMemo(() => {
    if (selection?.multi) {
      return selection.selectedMultiRows.length > 0 && selection?.operations?.length;
    }
    return selection?.selectedRow && selection?.operations?.length;
  }, [selection]);

  const onRow = useCallback(
    (record: D) => ({
      onClick: selection ? onRowClick.bind(null, record) : undefined,
    }),
    [selection, rowKey],
  );

  const stickySettings: boolean | TableSticky = useMemo(() => {
    let offset = 0;

    if (typeof sticky === 'boolean') {
      offset = HEADER_HEIGHT;
    } else {
      offset = sticky?.offsetHeader || 0;
    }

    if (!operationsVisible) {
      return {
        offsetHeader: offset - 1,
      };
    }

    return {
      offsetHeader: OPERATIONS_HEIGHT - 1,
    };
  }, [operationsVisible, sticky]);

  const expandIcon = useCallback((props: RenderExpandIconProps<D>) => {
    if (!props.expandable) {
      return null;
    }

    const onClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.stopPropagation();
      props.onExpand(props.record, event);
    };

    return (
      <Button
        variant="text"
        onClick={onClick}
        active={props.expanded}
        className={cx('expand-button', classes?.expandButton)}
      >
        <div
          className={cx(
            'expand-icon',
            { 'expand-icon_active': props.expanded },
            classes?.expandIcon,
          )}
        >
          <Icon
            size="xl"
            type="chevron-down"
          />
        </div>
      </Button>
    );
  }, []);

  const activePlug = useMemo(() => {
    if (loading) {
      return plugs?.loading || <TableLoaderFrame count={loadingFramesCount} />;
    }

    if (data.length === 0 && plugs?.emptyData) {
      return plugs?.emptyData;
    }

    return null;
  }, [plugs, loading, data, loadingFramesCount]);

  const rootClasses = [
    'card',
    { card_selection: Boolean(selection) },
    { plug_active: Boolean(activePlug) },
  ];

  return (
    <div className={cx(rootClasses)}>
      <div
        className={cx('operations')}
        ref={refOperationsContainer}
      >
        {operationsVisible ? <Operations /> : null}
      </div>

      {activePlug ? (
        <div className={cx('plug')}>{activePlug}</div>
      ) : (
        <>
          <Table<D>
            sticky={stickySettings}
            columns={formattedColumns}
            rowKey={rowKey}
            data={data as unknown as readonly D[]}
            rowClassName={selection || expandable ? rowClassName : undefined}
            expandIcon={expandIcon}
            expandable={expandable}
            emptyText={null}
            onRow={onRow}
            onExpandedRowsChange={onExpandedRowsChange}
          />
          {data.length > 0 && <Pagination className={classes?.pagination} />}
        </>
      )}
    </div>
  );
};

export { CMTable };
export type { Props as CmTableProps, Plugs as CmTablePlugs, Classes as CmTableClasses };
