import { FC } from 'react';

import { bindStyles } from '@src/utils';
import { useAppTranslation } from '@src/common';
import { Icon } from '@src/kit';

import styles from './Header.module.scss';

const cx = bindStyles(styles);

type Props = {
  vdcName: string;
  resourceType: string;
  onClose: () => void;
};

const Header: FC<Props> = ({ resourceType, vdcName, onClose }) => {
  const { t } = useAppTranslation();

  return (
    <div className={cx('wrapper')}>
      <div className={cx('header')}>
        <div className={cx('title')}>
          {t('cos.tariffAndPrices.resource.configuration.modal.title')}
        </div>
        <Icon
          className={cx('closeIcon')}
          type="close"
          onClick={onClose}
        />
      </div>
      <div className={cx('breadcrumbs')}>
        <div className={cx('breadcrumbsSection')}>
          <div>{t('cos.tariffAndPrices.resource.configuration.modal.breadcrumbs')}</div>
          <Icon
            className={cx('breadcrumbsIcon', 'arrowIcon')}
            type="chevron"
          />
        </div>
        <div className={cx('breadcrumbsSection')}>
          <div className={cx('sectionContainer')}>
            <Icon
              className={cx('breadcrumbsIcon')}
              type="folder"
            />
            {vdcName}
          </div>
          <Icon
            className={cx('breadcrumbsIcon', 'arrowIcon')}
            type="chevron"
          />
        </div>
        <div className={cx('breadcrumbsSection', { isLast: true })}>{resourceType}</div>
      </div>
    </div>
  );
};

export { Header };
