import React from 'react';

import { bindStyles } from '@src/utils';
import { Size } from '@src/@types';
import { Icon } from '@src/kit';

import styles from './FieldArrayItem.module.scss';

const cx = bindStyles(styles);

type Props = {
  onDelete?: () => void;
  className?: string;
  size: Size;
};

const FieldArrayItem: React.FC<Props> = ({ children, onDelete, size }) => {
  return (
    <li className={cx('listItem', size)}>
      <div className={cx('content')}>{children}</div>
      <Icon
        type="close"
        onClick={onDelete}
        className={cx('deleteIcon', { hidden: !Boolean(onDelete) })}
      />
    </li>
  );
};

export { FieldArrayItem };
