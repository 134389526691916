import {
  ApiPageResponse,
  UserCreateDTO,
  UsersFilterSearchDTO,
  UsersRolesDTO,
  UserUpdateDTO,
  UserViewDTO,
} from '@common/components/UserRoles/types';

import { COUNT, RANGE, SORT } from '@src/constants';
import { IRestService, RestService } from '@src/common';
import { serializeObject } from '@src/utils';

const restService: IRestService = RestService.getInstance();

const fetchUsersList = (dto: UsersFilterSearchDTO) => {
  const url = '/idm/api/user/list';
  const headers = {
    [RANGE]: dto.page,
    [SORT]: dto.sort,
    [COUNT]: dto.pageSize,
  };

  return restService.GET<ApiPageResponse<UserViewDTO>>(url, {
    headers: serializeObject(headers),
    params: {
      name: dto.name,
    },
  });
};

const fetchUsersRoles = (type: 'CLOUDER' | 'COSTER') => {
  const url = '/idm/role/list';
  return restService.POST<string, UsersRolesDTO[]>(url, {
    data: type,
    headers: { 'Content-Type': 'text/plain' },
  });
};

const fetchUpdateUser = (payload: UserUpdateDTO) => {
  const { uuid, ...rest } = payload;
  const url = `/idm/api/user/${uuid}`;
  return restService.POST(url, { data: rest });
};

const fetchUserPassGenerate = (uuid: string) => {
  const url = `/idm/api/user/update-password/${uuid}`;
  return restService.POST(url);
};

const fetchDeleteUser = (id: string) => {
  const url = `/idm/api/user/${id}`;
  return restService.DELETE(url);
};

const fetchUserData = (uuid: string) => {
  const url = `/idm/api/user/${uuid}`;
  return restService.GET<UserViewDTO>(url);
};

const fetchUserCreate = (payload: UserCreateDTO) => {
  const url = '/idm/api/user';
  return restService.POST(url, { data: payload });
};

export {
  fetchUsersList,
  fetchUsersRoles,
  fetchUpdateUser,
  fetchUserPassGenerate,
  fetchDeleteUser,
  fetchUserData,
  fetchUserCreate,
};
