import { LAST_PAGE_LINK_KEY } from '@common/components/AppToolbar/constants/common';

import { StorageService } from '@src/common';
import { appNavigate } from '@src/utils';

type fullPage = {
  project: string;
  page: string;
};

const storage = StorageService.getInstance();

const useMemoPageLink = () => {
  const getLastPage =
    (nextProject: string, currentProject?: string) => (event?: React.SyntheticEvent) => {
      if (!currentProject || currentProject === nextProject) {
        return;
      }

      const lastSavedPage: fullPage = JSON.parse(storage.getItem(LAST_PAGE_LINK_KEY) ?? '{}');

      storage.setItem(
        LAST_PAGE_LINK_KEY,
        JSON.stringify({ project: currentProject, page: window.location.pathname }),
      );

      if (lastSavedPage?.project === nextProject) {
        event?.preventDefault();
        appNavigate(lastSavedPage.page);
      }
    };

  return {
    getLastPage,
  };
};

export { useMemoPageLink };
