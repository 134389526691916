import React from 'react';

import { Translate, useAppTranslation, useCloudConnectContext } from '@src/common';
import { Dialog } from '@src/kit';
import { CloudTypes } from '@src/@types';

const SUCCESS_TEXT_KEYS = {
  AWS: 'ModalConnectTextAWS',
  Yandex: 'common.cloudConnect.text.success.yandex',
} as Record<CloudTypes, string | undefined>;

const ConnectionSuccess: React.FC = () => {
  const { t } = useAppTranslation();
  const { selectedProvider, onClose } = useCloudConnectContext();

  const actions = {
    ok: {
      text: t('DialogOk'),
    },
  };

  if (!selectedProvider) {
    return null;
  }

  const text = SUCCESS_TEXT_KEYS[selectedProvider] || 'common.cloudConnect.text.success.common';

  return (
    <Dialog
      closeHandler={onClose}
      title={t('EstablishedСonnection', { selectedProvider })}
      type="notification"
      actions={actions}
      onOk={onClose}
    >
      <Translate
        i18nKey={text}
        components={{
          br: <br />,
        }}
      />
    </Dialog>
  );
};

export { ConnectionSuccess };
