import React from 'react';
import { components, OptionProps } from 'react-select';

import cn from 'classnames/bind';
import { Checkbox, SelectOption } from '@kit/components';
import { TextShorter } from '@kit/components/TextShorter';

import { Size } from '@src/@types';

import styles from './Select.module.scss';

type Props = OptionProps & {
  tooltip?: boolean;
  checkboxSize?: Extract<Size, 'lg' | 'md' | 'sm'>;
  hasTooltip?: boolean;
  getIconTemplate: (data: SelectOption<unknown>) => React.ReactNode;
};

const cx = cn.bind(styles);

const CheckboxOptionsWithIcon: React.FC<Props> = ({
  tooltip,
  className,
  checkboxSize = 'md',
  getIconTemplate,
  ...props
}) => {
  const { isSelected, isFocused } = props;
  const data = props.data as SelectOption<unknown>;
  const label = data.label as string;

  return (
    <div className={cx('optionContainer')}>
      <components.Option
        {...props}
        className={cx(className, 'option', { isFocused })}
      >
        <Checkbox
          disabled={props.isDisabled}
          checked={isSelected}
          size={checkboxSize}
          containerReverse
        >
          <div className={cx('optionWithIcon')}>
            <div className={cx('icon')}>{getIconTemplate(data)}</div>
            <TextShorter tooltip={tooltip}>{label}</TextShorter>
          </div>
        </Checkbox>
      </components.Option>
    </div>
  );
};

export { CheckboxOptionsWithIcon };
