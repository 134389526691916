import { useCallback } from 'react';

import { useAppTranslation } from '@src/common';

type TPrefixFunction = (key: string, params?: Record<string, unknown>) => string;

const useTranslationPrefix = (prefix: string) => {
  const { t, i18n } = useAppTranslation();

  const tPrefix: TPrefixFunction = useCallback((key: string, params?: Record<string, unknown>) => {
    return t(prefix + key, params);
  }, []);

  return {
    t,
    i18n,
    tPrefix,
  };
};

export { useTranslationPrefix };
export type { TPrefixFunction };
