import { useEffect, useMemo, useState } from 'react';

import { fetchLoadingNotificationsList } from '@common/components/AppToolbar/api/requests';
import { COLORS_NOTIFICATIONS_CARD } from '@common/components/AppToolbar/components/Notifications/notifications-params';

import { NotificationColor, NotificationLoadingDTO } from '@src/common';
import { NotificationCardPayload } from '@src/kit';

const DEFAULT_PAGE_SIZE = 10;

const useNotificationsLoading = (isOpen: boolean) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [page, setPage] = useState<number>(0);
  const [notifications, setNotifications] = useState<NotificationLoadingDTO[]>([]);

  const fetch = async () => {
    try {
      setLoading(true);

      const list = await fetchLoadingNotificationsList({
        page,
        pageSize: DEFAULT_PAGE_SIZE,
      });

      if (list.data.length < DEFAULT_PAGE_SIZE) {
        setHasMore(false);
      }

      setNotifications((prev) => [...prev, ...list.data]);
    } catch (err) {
      console.error(err);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      void fetch();
    }
  }, [page, isOpen]);

  const destroy = () => {
    setLoading(false);
    setHasMore(true);
    setPage(0);
    setNotifications([]);
  };

  useEffect(() => {
    if (!isOpen) {
      destroy();
    }

    return destroy;
  }, [isOpen]);

  const loadMoreNotification = () => setPage(page + 1);

  const notificationCards: NotificationCardPayload[] = useMemo(
    () =>
      notifications.map((notification) => ({
        ...notification,
        showed: true,
        source: '',
        date: notification.startDateTime,
        time: '',
        color: COLORS_NOTIFICATIONS_CARD['IN_PROGRESS'] as NotificationColor,
      })),
    [notifications],
  );

  return {
    hasMore,
    notifications,
    loading,
    loadMoreNotification,
    notificationCards,
  };
};

export { useNotificationsLoading };
