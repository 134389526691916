import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
  ACCEPT_LANGUAGE,
  AUTH_HEADER,
  LANG_ASTROKITE_KEY,
  LANG_CLOUDMASTER_KEY,
} from '@src/constants';
import { StorageService } from '@src/common';

type Props = {
  stand: string;
  tags?: string[];
};

const createBaseApi = ({ tags, stand }: Props) => {
  const baseQuery = fetchBaseQuery({
    baseUrl: '/data',
    prepareHeaders: (headers) => {
      const langKey = stand.includes('astrokite') ? LANG_ASTROKITE_KEY : LANG_CLOUDMASTER_KEY;
      const authToken = StorageService.getInstance().getItem(AUTH_HEADER);
      const lang = StorageService.getInstance().getItem(langKey);

      if (authToken) {
        headers.set(AUTH_HEADER, authToken);
      }

      if (lang) {
        headers.set(ACCEPT_LANGUAGE, lang);
      }

      return headers;
    },
  });

  return createApi({
    baseQuery,
    reducerPath: 'api',
    endpoints: () => ({}),
    tagTypes: tags,
  });
};

export { createBaseApi };
