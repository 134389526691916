import React, { useCallback } from 'react';

import { useToolbarLocalization } from '@common/components/AppToolbar/hooks/useToolbarLocalization';
import { ProfilePopupInner } from '@common/components/AppToolbar/components/Profile/ProfilePopupInner/ProfilePopupInner';

import { useAuth, UserDto } from '@src/common';
import { bindStyles } from '@src/utils';
import { Avatar, Modal, Popup } from '@src/kit';

import { usePopupAppToolbar } from '@hooks/usePopupAppToolbar';

import styles from './ProfileView.module.scss';

const cx = bindStyles(styles);

type Props = {
  user: UserDto;
  onOpenDetail: () => void;
  showTechNotification: boolean;
};

const ProfileView: React.FC<Props> = ({ user, onOpenDetail, showTechNotification }) => {
  const { isOpened, openPopup, closePopup } = usePopupAppToolbar();
  const { logout } = useAuth();

  const { profile } = useToolbarLocalization();

  const openProfile = useCallback(async () => {
    closePopup();
    onOpenDetail();
  }, []);

  const name = `${user.lastName || ''} ${user.firstName || ''}`;

  return (
    <Modal
      trigger={
        <div>
          <Avatar
            src={user.avatar || ''}
            className={cx('icon', { isOpened })}
            userId={user.uuid}
            lastName={user.lastName}
            firstName={user.firstName}
          />
        </div>
      }
      position="bottom right"
      hiddenOverlay
      className={cx(
        'popup',
        showTechNotification ? 'tech-popup-profile-content' : 'popup-profile-content',
      )}
      overlayClassName={cx(
        showTechNotification ? 'tech-popup-setting-overlay' : 'popup-setting-overlay',
      )}
      contentStyle={{ borderTop: '1px solid #f6f8f9', padding: 0 }}
      open={isOpened}
      onOpen={openPopup}
      onClose={closePopup}
    >
      <ProfilePopupInner
        name={name}
        company={user.company || ''}
        login={user?.login || ''}
        openProfile={openProfile}
        localization={profile}
        logout={logout}
      />
    </Modal>
  );
};

export { ProfileView };
