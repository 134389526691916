import { FC, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

import { ConnectedCloudsDataDTO } from '@common/components/AppToolbar/components/CloudConnect/types';
import { fetchConnectedClouds } from '@common/components/AppToolbar/api/cloud-connections';
import { AxiosError } from 'axios';
import {
  FixRate,
  TariffBillingTypeVariants,
  TariffPriceDTO,
  TariffPriceResourceDTO,
  TariffPriceResourceQuotaDTO,
  TariffResourceTypes,
  TariffTypeVariants,
} from '@common/components/TariffAndPricesComponent/api/models';
import {
  fetchSaveTariffsAndPricesNew,
  fetchSaveTariffsAndPricesUsedNew,
  fetchTariffsAndPricesNew,
  fetchTariffsTypes,
} from '@common/components/TariffAndPricesComponent/api/tariffAndPrices';
import { TariffItem } from '@common/components/TariffAndPricesComponent/components/TariffItem';
import { TableHeader } from '@common/components/TariffAndPricesComponent/components/TableHeader';
import { FixRateTableItem } from '@common/components/TariffAndPricesComponent/components/FixRateTableItem';
import { AddRowButton } from '@common/components/TariffAndPricesComponent/components/AddRowButton';
import * as dateFns from 'date-fns';
import { CalendarBlock } from '@common/components/TariffAndPricesComponent/components/CalendarInput';
import { ConfirmModal } from '@common/components/TariffAndPricesComponent/components/ConfirmModal';

import { bindStyles, formatDateZ, getDocsLink, mapToOption } from '@src/utils';
import {
  Form,
  useAppToolbarContext,
  useAppTranslation,
  useFormApiError,
  usePopupControls,
  useToast,
} from '@src/common';
import {
  Button,
  CalendarValue,
  ChipTag,
  ChipTagVariant,
  ColorPulsator,
  DocsManual,
  H1,
  Icon,
  Plug,
  SelectOption,
  Tab,
  TabContext,
  TabList,
  useCalendar,
} from '@src/kit';
import { CLOUD_ICON_DICTIONARY } from '@src/constants';
import { CloudTypes } from '@src/@types';

import styles from './TariffAndPrices.module.scss';

const cx = bindStyles(styles);

type TariffFormResource = TariffPriceResourceDTO & {
  vdcUuid: string;
  validations?: {
    price: boolean;
  };
  index: number;
  price: number | null;
};

type FormDTO = {
  resources: TariffFormResource[];
  tariffs: Omit<TariffPriceDTO, 'tariffResources'>[];
  rates: FixRate[];
};

enum TariffPricesTabs {
  ORGANIZATION = 'organization',
  FIX_RATES = 'fix_rates',
}

const INITIAL_FAST_DATE = mapToOption(
  new Date(),
  'cos.settings.tariffprices.calendar.fastDate.today',
  'today',
);

const TariffAndPrices: FC = () => {
  const { t } = useAppTranslation();
  const toast = useToast();
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCloudsLoading, setIsCloudsLoading] = useState(true);
  const [isTariffsLoading, setIsTariffsLoading] = useState(true);
  const [clouds, setClouds] = useState<ConnectedCloudsDataDTO[]>([]);
  const [selectedCloud, setSelectedCloud] = useState<ConnectedCloudsDataDTO>();
  const [tariffs, setTariffs] = useState<TariffPriceDTO[]>([]);
  const [fixRates, setFixRates] = useState<FixRate[]>([]);
  const datePopup = usePopupControls();
  const [activeTab, setActiveTab] = useState<TariffPricesTabs>(TariffPricesTabs.ORGANIZATION);
  const { brand } = useAppToolbarContext();
  const urlParams = new URLSearchParams(window.location.search);
  const [tariffTypes, setTariffTypes] = useState<SelectOption<string>[]>([]);

  const tariffsId = urlParams.get('id');

  const FAST_DATES: SelectOption<CalendarValue>[] = useMemo(() => {
    if (selectedCloud) {
      const [day, month, year] = selectedCloud.createdAt.split('.');
      const creationDate = new Date();
      creationDate.setDate(+day);
      creationDate.setMonth(+month - 1);
      creationDate.setFullYear(+year);

      return [
        INITIAL_FAST_DATE,
        mapToOption(
          creationDate,
          'cos.settings.tariffprices.calendar.fastDate.creationDay',
          'creationDay',
        ),
      ];
    }

    return [];
  }, [selectedCloud]);

  const calendar = useCalendar({
    initialPeriod: INITIAL_FAST_DATE.value,
    initialFastDate: INITIAL_FAST_DATE,
  });
  const form = useForm<FormDTO>({
    defaultValues: {
      resources: [],
      rates: [],
      tariffs: [],
    },
  });
  const { handleError, apiErrors } = useFormApiError();
  const { fields } = useFieldArray({
    control: form.control,
    name: 'resources',
  });

  const {
    fields: fixRatesFields,
    remove,
    append,
  } = useFieldArray({
    control: form.control,
    name: 'rates',
  });

  const { fields: tariffsFields } = useFieldArray({
    control: form.control,
    name: 'tariffs',
  });

  const resources = form.watch('resources');

  const getClouds = async () => {
    try {
      setIsCloudsLoading(true);
      const response = await fetchConnectedClouds();
      const vdcClouds = response.data.filter(
        (it) => it.cloudType === 'vCloudDirector' && it.status === 'ACTIVE',
      );
      const vdcCloud =
        tariffsId !== null ? vdcClouds.find((it) => String(it.id) === tariffsId) : vdcClouds[0];
      setClouds(vdcClouds);
      setSelectedCloud(vdcCloud);
    } catch (err) {
      console.error(err);
    } finally {
      setIsCloudsLoading(false);
    }
  };

  const getTariffTypes = async () => {
    try {
      const response = await fetchTariffsTypes();
      setTariffTypes(response.data.map((it) => mapToOption(it.tariffType, t(it.code) as string)));
    } catch (e) {
      console.error(e);
    }
  };

  const getTariffs = async () => {
    try {
      if (selectedCloud?.id) {
        setIsTariffsLoading(true);
        const response = await fetchTariffsAndPricesNew(selectedCloud.id);
        setTariffs(response.data.tariffPrices);
        setFixRates(response.data.fixRates || []);
        form.setValue(
          'resources',
          response.data.tariffPrices.reduce(
            (res: TariffFormResource[], it) => [
              ...res,
              ...it.tariffResources.map(
                (resource, i) =>
                  ({
                    ...resource,
                    vdcUuid: it.vdcUuid,
                    index: res.length + i,
                    price: null,
                    validations: {
                      price: resource.quotas !== null,
                    },
                  } as TariffFormResource),
              ),
            ],
            [] as TariffFormResource[],
          ),
        );
        form.setValue('rates', response.data.fixRates || []);
        form.setValue(
          'tariffs',
          response.data.tariffPrices.map(({ tariffResources, ...it }) => it),
        );
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsTariffsLoading(false);
    }
  };

  useEffect(() => {
    getClouds();
    getTariffTypes();
  }, []);

  useEffect(() => {
    if (selectedCloud?.id) {
      getTariffs();
    }
  }, [selectedCloud?.id]);

  const handleCancel = () => {
    setIsEdit(false);
    if (activeTab === TariffPricesTabs.ORGANIZATION) {
      form.setValue(
        'resources',
        tariffs.reduce(
          (res: TariffFormResource[], it) => [
            ...res,
            ...it.tariffResources.map(
              (resource, i) =>
                ({
                  ...resource,
                  vdcUuid: it.vdcUuid,
                  index: res.length + i,
                  price: null,
                } as TariffFormResource),
            ),
          ],
          [] as TariffFormResource[],
        ),
      );
      form.setValue(
        'tariffs',
        tariffs.map(({ tariffResources, ...it }) => it),
      );
      onReset();
    }

    if (activeTab === TariffPricesTabs.FIX_RATES) {
      form.setValue('rates', fixRates);
    }

    form.clearErrors();
  };

  const handleSave = async () => {
    try {
      if (selectedCloud?.id) {
        setIsLoading(true);
        const now = dateFns.format(new Date(), 'dd.MM.yy');
        const calendarValue = dateFns.format(calendar.period as Date, 'dd.MM.yy');

        if (!datePopup.isOpened && now !== calendarValue) {
          datePopup.openPopup();
          return;
        }

        if (datePopup.isOpened) {
          datePopup.closePopup();
        }
        const values = form.getValues();
        const mappedRates = values.rates.map((it) => ({
          ...it,
          quantity: Number(it.quantity),
          price: Number(it.price),
        }));
        const mappedTariffs: TariffPriceDTO[] = tariffs.map((tariff) => {
          const tariffType = values.tariffs.find((it) => it.vdcUuid === tariff.vdcUuid)?.tariffType;

          return {
            ...tariff,
            tariffType: tariffType,
            tariffResources: values.resources
              .filter((resource) => resource.vdcUuid === tariff.vdcUuid)
              .map((it) => {
                let quotas: TariffPriceResourceQuotaDTO[] | null = [];

                if (tariffType === TariffTypeVariants.MIXED) {
                  quotas = it.quotas;
                } else {
                  if (it.price === null || String(it.price) === '') {
                    quotas = null;
                  } else {
                    quotas = [
                      {
                        quotaFrom: null,
                        quotaTo: null,
                        rate: Number(it.price),
                        billingType: tariffType as unknown as TariffBillingTypeVariants,
                      },
                    ];
                  }
                }

                return {
                  ...it,
                  skuName: it.skuName === null || it.skuName === '' ? null : it.skuName,
                  quotas: quotas,
                };
              }),
          } as TariffPriceDTO;
        });

        if (now !== calendarValue) {
          await fetchSaveTariffsAndPricesUsedNew(
            {
              startAt: formatDateZ(dateFns.formatISO(dateFns.startOfDay(calendar.period as Date))),
              endAt: formatDateZ(dateFns.formatISO(new Date())),
              tariffPrices: mappedTariffs,
              fixRates: mappedRates,
            },
            selectedCloud?.id,
          );
          toast.loading({ text: t('cos.settings.tariffprices.toast.recalc') });
        }

        await fetchSaveTariffsAndPricesNew({
          tariffPrices: mappedTariffs,
          fixRates: mappedRates,
        });
        await getTariffs();
        toast.success({ text: t('cos.settings.tariffprices.success') });
        form.clearErrors();

        setIsEdit(false);
      }
    } catch (e) {
      handleError(e as AxiosError);
      toast.error({ text: t('cost.settings.tariffprices.error') });
    } finally {
      setIsLoading(false);
    }
  };

  const onChangeTab = (tab: string) => {
    setActiveTab(tab as TariffPricesTabs);
  };

  const renderedTariffs = useMemo(() => {
    if (activeTab === TariffPricesTabs.FIX_RATES) {
      return (
        <>
          {fixRatesFields.map((rate, index) => {
            return (
              <FixRateTableItem
                key={rate.id}
                i={index}
                rate={rate}
                isEdit={isEdit}
                onRemove={() => {
                  remove(index);
                }}
              />
            );
          })}
          {isEdit && (
            <AddRowButton
              onAdd={() =>
                append({
                  tariff: TariffResourceTypes.MONTH,
                  itemName: '',
                })
              }
            />
          )}
        </>
      );
    }

    return tariffs.map((it, index) => (
      <TariffItem
        key={it.vdcUuid}
        tariff={it}
        index={index}
        tariffTypes={tariffTypes}
        resources={fields.filter((field) => field.vdcUuid === it.vdcUuid)}
        isEdit={isEdit}
      />
    ));
  }, [tariffs, fields, isEdit, resources, activeTab, fixRatesFields, fixRates]);

  const renderedControls = useMemo(() => {
    const now = new Date();
    const buttons = isEdit ? (
      <div>
        <div className={cx('controls')}>
          <Button
            text={t('cos.settings.tariffAndPrices.submit')}
            size="md"
            onClick={form.handleSubmit(handleSave)}
            isLoading={isLoading}
            disabled={isLoading}
          />
          <Button
            text={t('cos.settings.tariffAndPrices.cancel')}
            variant="outline"
            onClick={handleCancel}
            disabled={isLoading}
            size="md"
          />
          <div className={cx('editText')}>{t('cos.settings.tariffAndPrices.editText')}</div>
        </div>
      </div>
    ) : (
      <div className={cx('controls', 'noEdit')}>
        <Button
          text={t('cos.settings.tariffAndPrices.edit')}
          onClick={() => setIsEdit(true)}
          disabled={isCloudsLoading || isTariffsLoading}
          isLoading={isCloudsLoading || isTariffsLoading}
          size="md"
        />
        {/* TODO Вернуть когда будет сделана доработка с бэка */}
        {/*<div className={cx('usedContainer')}>*/}
        {/*  <Icon*/}
        {/*    type="database"*/}
        {/*    size="md"*/}
        {/*    className={cx('databaseIcon')}*/}
        {/*  />*/}
        {/*  <div className={cx('usedText')}>*/}
        {/*    <span>{t('cos.settings.tariffAndPrices.usedDate')}</span>*/}
        {/*    <span>{dateFns.format(calendar.period as Date, 'dd.MM.yy')}</span>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    );

    if (activeTab === TariffPricesTabs.FIX_RATES) {
      if (fixRatesFields.length || isEdit) {
        return buttons;
      }
    } else {
      return buttons;
    }
  }, [
    isLoading,
    fixRatesFields,
    isEdit,
    activeTab,
    isCloudsLoading,
    isTariffsLoading,
    t,
    calendar.period,
    datePopup.isOpened,
  ]);

  const onReset = () => {
    calendar.onChangeValue(INITIAL_FAST_DATE.value);
    calendar.onChangeFastDay(INITIAL_FAST_DATE);
    calendar.onChangeCurrentValue(INITIAL_FAST_DATE.value);
  };

  const renderedPlug = useMemo(() => {
    if (isTariffsLoading) {
      return (
        <Plug
          imageType="rocket"
          text={t('cos.settings.tariffAndPrices.plug.text')}
          className={cx('wrapper-plug')}
        />
      );
    }

    if (activeTab === TariffPricesTabs.ORGANIZATION && !tariffs.length) {
      return (
        <Plug
          imageType="rocket"
          text={t('cos.settings.tariffAndPrices.plug.text')}
          className={cx('wrapper-plug')}
        />
      );
    }
    if (activeTab === TariffPricesTabs.FIX_RATES) {
      if (!fixRatesFields.length && !isEdit) {
        return (
          <Plug
            imageType="cloud"
            text={
              <div className={cx('plug')}>
                <div className={cx('plugTexts')}>
                  <div>{t('cos.settings.tariffAndPrices.plug.empty.text.top')}</div>
                  <div>{t('cos.settings.tariffAndPrices.plug.empty.text.bottom')}</div>
                </div>
                <Button
                  text={t('cos.settings.tariffAndPrices.plug.empty.button')}
                  onClick={() => {
                    setIsEdit(true);
                    append({
                      tariff: TariffResourceTypes.MONTH,
                      itemName: '',
                    });
                  }}
                  disabled={isCloudsLoading || isTariffsLoading}
                  isLoading={isCloudsLoading || isTariffsLoading}
                  size="md"
                >
                  <Icon type="plus" />
                </Button>
              </div>
            }
            className={cx('wrapper-plug')}
          />
        );
      }
    }
  }, [isTariffsLoading, fixRatesFields, t, isEdit, isCloudsLoading, tariffs, activeTab]);

  return (
    <div className={cx('wrapper', { isFixRates: activeTab === TariffPricesTabs.FIX_RATES })}>
      <div className={cx('page-header')}>
        <div className={cx('wrapper-header')}>
          <H1 size="lg">{t('cos.settings.tariffAndPrices')}</H1>
          <DocsManual linkToManual={getDocsLink(brand, '/pricing/')} />
        </div>
        {isCloudsLoading ? (
          <div className={cx('cloudsLoading')}>
            <ColorPulsator />
          </div>
        ) : (
          <>
            <div className={cx('clouds')}>
              {clouds.map((it) => (
                <ChipTag
                  key={it.id}
                  isActive={selectedCloud?.id === it.id}
                  disabled={isEdit}
                  variant={ChipTagVariant.FILLED_GRAY}
                  text={
                    <div className={cx('cloudChip')}>
                      <div className={cx('icon')}>
                        {CLOUD_ICON_DICTIONARY[it.cloudType as CloudTypes]}
                      </div>
                      {it.name}
                    </div>
                  }
                  onClick={() => setSelectedCloud(it)}
                />
              ))}
            </div>
          </>
        )}
      </div>

      <div className={cx('wrapper-content')}>
        <div className={cx('header')}>
          <div className={cx('tabs')}>
            <TabContext value={activeTab}>
              <TabList
                tabListClassName={cx('tab-list')}
                variant="underlined"
                size="lg"
                onChange={onChangeTab}
              >
                {Object.values(TariffPricesTabs).map((tab) => (
                  <Tab
                    key={tab}
                    value={tab}
                    disabled={(isEdit && tab !== activeTab) || isCloudsLoading}
                    label={t(`cos.settings.tariffAndPrices.${tab}.label`)}
                  />
                ))}
              </TabList>
            </TabContext>
          </div>
        </div>
        {renderedControls}
        {isEdit && activeTab === TariffPricesTabs.ORGANIZATION && (
          <CalendarBlock
            value={calendar.period}
            onChangeValue={calendar.onChangeValue}
            disabled={isLoading}
            intervals={calendar.intervals}
            interval={calendar.currentInterval}
            onChangeInterval={calendar.onChangeInterval}
            fastDates={FAST_DATES}
            activeFastDate={calendar.fastDate}
            onClickFastDate={calendar.onChangeFastDay}
            onReset={onReset}
            currentValue={calendar.currentPeriod}
            setCurrentValue={calendar.onChangeCurrentValue}
          />
        )}
        <TableHeader
          isFixRates={activeTab === TariffPricesTabs.FIX_RATES}
          hidden={activeTab === TariffPricesTabs.FIX_RATES && !fixRatesFields.length && !isEdit}
        />
        <div className={cx('tariffsWrapper')}>
          {renderedPlug || (
            <div className={cx('tariffs')}>
              <Form
                formMethods={form}
                apiErrors={apiErrors}
              >
                {renderedTariffs}
              </Form>
            </div>
          )}
        </div>
      </div>
      {datePopup.isOpened && (
        <ConfirmModal
          date={calendar.period as Date}
          onClose={datePopup.closePopup}
          onConfirm={form.handleSubmit(handleSave)}
        />
      )}
    </div>
  );
};

export { TariffAndPrices };
export type { TariffFormResource, FormDTO };
