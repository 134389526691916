import React from 'react';

import { useAppTranslation } from '@src/common';
import { bindStyles } from '@src/utils';
import { Loader, Tooltip } from '@src/kit';

import styles from './SpinnerBlock.module.scss';

type Props = {
  count: number;
};

const cx = bindStyles(styles);
const NOTIFICATIONS_COUNT = '+99';

const SpinnerBlock: React.FC<Props> = ({ count }) => {
  const { t } = useAppTranslation();

  return (
    <div>
      <Tooltip text={t('OperationsInProgress') as string}>
        <div className={cx('loaderBlock')}>
          <Loader
            size="lg"
            type="spinner-progress"
            className={cx('loader')}
          />
          <span className={cx('textLoader')}>{count > 99 ? NOTIFICATIONS_COUNT : count}</span>
        </div>
      </Tooltip>
    </div>
  );
};

export { SpinnerBlock };
